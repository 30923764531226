import React, { Component } from 'react';
import Presentation from "./Presentation";
import { DataContext } from '../../../../contexts/data'

class Container extends Component {

    state = {
        reqDate: '',
       
    }
    
    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    static contextType = DataContext

    componentDidMount = () => {
        const { invoiceNumber, paymentFilterList } = this.props
        let date = new Date(), dateFinal = []
    if (paymentFilterList !== undefined) {
      let list = []
      paymentFilterList && paymentFilterList.forEach((paymentDoc) => {
        list.push(paymentDoc.paymentDetails.filter((details) => details.invoiceNumber === invoiceNumber)[0])
      })
     
      for (let index = 0; index < list.length; index++) {
        if(list[index]) {
          if(typeof(list[index]) === 'object') {
            
            list[index].hasOwnProperty('paymentDate') && dateFinal.push(list[index].paymentDate)
          }
        }
      }
      let reqDate = dateFinal.length && dateFinal.sort((a, b) => new Date(b) - new Date(a))[0]
      return reqDate === 0 ? this.handleChange("reqDate","--") :  this.handleChange("reqDate",reqDate)
    }

    else {
      return "--"
    }
    }
    

    render() {
        return (
            <div>
                <Presentation
                    {...this.props}
                    {...this.state}
                    handleChange={this.handleChange}
                />
            </div>
        );
    }
}

export default Container;