import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
import {
  ADD_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  SET_EMAIL_TEMPLATES,
  SET_EMAIL_CONTENT
} from '../../../../contexts/types'

class Container extends Component {
  state = {
    content: '',
    name: '',
    helperText: [],
  }

  static contextType = DataContext

  componentDidMount = () => {
    const [state] = this.context
    if (state.email_content.updating)
      this.setState({
        content: state.email_content.content,
        name: state.email_content.name,
      })
    else this.setState({ content: state.email_content.defaultContent })
  }

  handleContent = (data) => {
    this.setState({ content: data })
    // let str = data.toString()
    // let newArray = new Array()
    // let arr = str.split(' ')
    // arr.forEach((element) => {
    //   var tmpStr = element.match('~(.*)~')
    //   if (tmpStr !== null) newArray.push(tmpStr[1])
    // })
    // this.setState({
    //   helperText: newArray.filter(
    //     (str) => !configuration.draggableLabels.includes(str)
    //   ),
    // })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCreate = (e) => {
    e.preventDefault()
    const { content, type, name } = this.state
    const dispatch = this.context[1]
    dispatch({
      type: ADD_EMAIL_TEMPLATE,
      payload: {
        content,
        name,
      },
    })
  }

  handleUpdate = (e) => {
    e.preventDefault()
    const [state, dispatch] = this.context
    const { content, name } = this.state
    dispatch({
      type: UPDATE_EMAIL_TEMPLATE,
      payload: {
        content: content || state.email_content.content,
        name: name || state.email_content.name,
      },
    })
  }

  handleDelete = () => {
    const [state, dispatch] = this.context
    dispatch({
      type: DELETE_EMAIL_TEMPLATE,
      payload: {
        id: state.email_content ? state.email_content.id: '',
      },
      response: this.response,
    })
  }
  response = () => {
    this.cancelUpdate()
  }

  cancelUpdate = () => {
    const [state, dispatch] = this.context
    dispatch({
      type: SET_EMAIL_CONTENT,
      payload: {
        id: '',
        name: '',
        defaultContent: state.email_content.defaultContent,
        content: state.email_content.defaultContent,
        updating: false,
      },
    })

    this.setState({
      content: state.email_content.defaultContent,
      name: '',
    })
  }

  componentWillUnmount = () => {
    this.setState({
      content: '',
      name: '',
    })
  }

  render() {
    return (
      <div>
        <Presentation
          {...this.state}
          handleContent={this.handleContent}
          handleChange={this.handleChange}
          handleCreate={this.handleCreate}
          cancelUpdate={this.cancelUpdate}
          handleUpdate={this.handleUpdate}
          handleDelete={this.handleDelete}
        />
      </div>
    )
  }
}

export default Container
