import React, { useEffect } from 'react'
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  makeStyles,
  Button,
  FormControlLabel,
  FormGroup,
  TextareaAutosize,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Validations from '../../../../generalcomponents/validation'
import IOSSwitch from '../../../../generalcomponents/iosSwitch'
import PropTypes from 'prop-types'
import ChipInput from 'material-ui-chip-input'
//import SweetAlert from 'react-bootstrap-sweetalert'

const useStyles = makeStyles((theme) => ({}))

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const Presentation = (props) => {
  const {
    value,
    frequency,
    OT,
    POnumber,
    pointOfContactMailId,
    attachFlairExpense,
    attachFlairTimesheets,
    bcc,
    billingAddress,
    cc,
    frequencyStartDate,
    pointOfContactName,
    pointOfContactPhoneNo,
    to,
    handleDelete,
    handleAdd,
    fillableSections,
    handleTab,
    handleChange,
    handleCheck,
    handleSave,
    handleDateChange,
    helperText,
    index,
  } = props
  const classes = useStyles()
  const handleFrequencyStartDate = (date) => {
    handleDateChange('frequencyStartDate', date)
  }
  const validate = new Validations()

  const handleOT = (event) => {
    if (!event.target.checked) {
      var r = window.confirm(
        'Are you sure?\nOT Payrate will be disabled and OT billing will not be included in the invoice'
      )
      if (r === true) {
        handleCheck(event)
      }
      return r
    }
    handleCheck(event)
  }
  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <Tabs value={value} onChange={handleTab} variant='scrollable'>
          <Tab label='Invoice Details' />
          <Tab label='Billing and Notifications Contact' />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                select
                label='Frequnecy'
                size='small'
                required
                value={frequency}
                style={{ marginTop: '3px' }}
                name='frequency'
                onChange={handleChange}
                fullWidth
                required
              >
                {invoiceFrequencies
                  .slice(index < 0 ? 0 : index)
                  .map((option) => (
                    <MenuItem
                      key={option}
                      value={invoiceFrequencies.indexOf(option).toString()}
                    >
                      {option}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  required='required'
                  id='date-picker-startDate'
                  label='Frequency Start date'
                  format='MM/dd/yyyy'
                  value={frequencyStartDate ? frequencyStartDate : null}
                  onChange={handleFrequencyStartDate}
                  name='frequencyStartDate'
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={8}>
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch />}
                  checked={attachFlairTimesheets}
                  name='attachFlairTimesheets'
                  onChange={handleCheck}
                  label='Flair Timesheet Attachment'
                />
              </FormGroup>
            </Grid>
            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch />}
                  checked={POnumber}
                  name='POnumber'
                  onChange={handleCheck}
                  label='PO Number'
                />
              </FormGroup>
            </Grid>
            <Grid item xs={8}>
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch />}
                  onChange={handleCheck}
                  name='attachFlairExpense'
                  checked={attachFlairExpense}
                  label='Flair Expense Attachment'
                />
              </FormGroup>
            </Grid>
            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch />}
                  checked={OT}
                  name='OT'
                  onChange={(event) => handleOT(event)}
                  label='Invoice OT'
                />
              </FormGroup>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                size='small'
                required
                variant='outlined'
                label='Contact Name'
                value={pointOfContactName}
                helperText={
                  pointOfContactName.length
                    ? validate.checkName(pointOfContactName)
                      ? ''
                      : 'Enter valid name'
                    : ''
                }
                name='pointOfContactName'
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size='small'
                required
                variant='outlined'
                label='Contact Phone Number'
                value={pointOfContactPhoneNo}
                name='pointOfContactPhoneNo'
                onChange={handleChange}
                helperText={
                  pointOfContactPhoneNo.length
                    ? validate.checkNumber(pointOfContactPhoneNo)
                      ? ''
                      : 'Enter valid phone number'
                    : ''
                }
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size='small'
                required
                variant='outlined'
                label='Contact mail ID'
                value={pointOfContactMailId}
                helperText={
                  pointOfContactMailId.length
                    ? validate.checkEmail(pointOfContactMailId)
                      ? ''
                      : 'Enter valid email'
                    : ''
                }
                name='pointOfContactMailId'
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ChipInput
                value={to}
                onAdd={(chip) => handleAdd('to', chip)}
                onDelete={(_, index) => handleDelete('to', index)}
                helperText={helperText.to}
                allowDuplicates={false}
                label='To *'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ChipInput
                value={cc}
                onAdd={(chip) => handleAdd('cc', chip)}
                onDelete={(_, index) => handleDelete('cc', index)}
                helperText={helperText.cc}
                allowDuplicates={false}
                label='CC'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ChipInput
                value={bcc}
                onAdd={(chip) => handleAdd('bcc', chip)}
                onDelete={(_, index) => handleDelete('bcc', index)}
                helperText={helperText.bcc}
                allowDuplicates={false}
                label='BCC'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                required
                placeholder='Billing Address'
                value={billingAddress}
                name='billingAddress'
                onChange={handleChange}
                rowsMin={5}
                style={{ marginTop: '10px', width: '100%' }}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <br />
        {fillableSections.includes('invoices') ? (
          frequency.trim().length &&
          billingAddress.trim() &&
          validate.checkEmail(pointOfContactMailId) &&
          frequencyStartDate.trim().length &&
          validate.checkName(pointOfContactName) &&
          validate.checkNumber(pointOfContactPhoneNo) &&
          to ? (
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={(e) => handleSave('create')}
            >
              Done
            </Button>
          ) : (
            <Button
              type='button'
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled
            >
              Done
            </Button>
          )
        ) : frequency.trim().length &&
          billingAddress.trim() &&
          validate.checkEmail(pointOfContactMailId) &&
          bcc &&
          cc &&
          frequencyStartDate.trim().length &&
          validate.checkName(pointOfContactName) &&
          validate.checkNumber(pointOfContactPhoneNo) &&
          to ? (
          <Button
            type='submit'
            variant='contained'
            color='secondary'
            className={classes.submit}
            onClick={(e) => handleSave('update')}
          >
            Update
          </Button>
        ) : (
          <Button
            type='button'
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled
          >
            Update
          </Button>
        )}
        {alert}
      </form>
    </div>
  )
}

export default Presentation

const invoiceFrequencies = ['Weekly', 'Biweekly', 'Semi-Monthly', 'Monthly']
