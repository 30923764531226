import React from 'react';
import Presentation from './Presentation'


class PrintID extends React.Component {
    pdfExportComponent;
    image;

    render() {
        return (
        <Presentation 
            {...this.props}
            pdfExportComponent = {this.pdfExportComponent}
            image = {this.image}
        />
        );
    }
}

export default PrintID