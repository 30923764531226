import React, { Component } from 'react'
import {storage} from '../../../../firebase_config'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
import Validations from '../../../generalcomponents/validation'
class Container extends Component {

    constructor(props){
        super(props)
        this.validate = new Validations()
    }

    state = {
        type: "",
        description: "",
        req_doc: "",
        isUploading : false
    }

    static contextType = DataContext
 

    componentDidMount = () => {
        this.clearValues()
    }

    handleChange2 = e => {
        console.log(e.target.files[0])
        if (e.target.files[0]) {
            const file = e.target.files[0];
            this.setState(() => ({ file }));
            this.fileUpload1(file)
        }
    }

    fileUpload1 = (file) => {
        const [state] = this.context
        const employeeCompanyID = state.employee_collection.companyID
        const fileName = [employeeCompanyID, '_LT_', this.state.type.replace(/ /g,'').toLowerCase(), '_', new Date().toISOString(), '.', file.name.split('.')[1]].join('')
        const filePath = `EmployeeLetter/${employeeCompanyID}/${this.state.type.replace(/ /g,'').toLowerCase()}/${fileName}`
        this.setState({isUploading:true})
        return this.validate.uploadToStorage(file, filePath, fileName, 'file')
        .then(url => {
            this.setState({ req_doc : url })
            this.setState({isUploading:false})
        })
        .catch(err => {
            this.setState({isUploading:false})
            console.log(err)
        })
    }

        handleChange = (key, value) => {
            this.setState({
                [key]: value
            })
        }

        handleNewRequest = (universityDetails ) => {
            // const { type, description, req_doc } = this.state
            console.log(this.state)
            // const dispatch = this.context[1]
            // dispatch({
            //     type : EMPLOYEE_REQUEST_LETTER,
            //     payload : {
            //         DocType : type,
            //         description : description,
            //         attachment:{
            //             publicURL : req_doc,
            //             sourcePath : ''
            //         },
            //         additionalDetails : {
            //             universityDetails : universityDetails
            //         }
            //     }
            // })

            // this.clearValues()
        }

        clearValues = () => {
            this.setState({
                type: "",
                description: "",
                req_doc: "",
            })
        }

    


        render() {
            const { type, description } = this.state
            if(type!=="" && description!=="") this.props.requestDetailsVerified(true,this.state)
            else this.props.requestDetailsVerified(false,this.state)  
            return (
                <div>
                    <Presentation
                        {...this.state}
                        {...this.props}
                        handleChange={this.handleChange}
                        clearValues={this.clearValues}
                        handleChange2={this.handleChange2}
                        handleNewRequest={this.handleNewRequest}
                    />
                </div>
            )
        }
    }

    export default Container
