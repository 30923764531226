import React, { useContext } from 'react'
import {
  Grid,
  makeStyles,
  Typography,
  Paper,
  InputBase,
  Tooltip,
  Fab,
  Divider,
  IconButton,
  Avatar,
  withStyles,
  Button,
} from '@material-ui/core'
import { DataContext } from '../../../contexts/data'
import {
  Search,
  Add,
  Settings,
  NavigateNext,
  NavigateBefore,
  ThumbDown,
  ThumbUp,
  Email,
} from '@material-ui/icons'
import { FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Validations from '../../generalcomponents/validation'

export default function Presentation(props) {
  const [state] = useContext(DataContext)
  const {
    uniqCategorieNames,
    Categories,
    handleChange,
    search,
    matches,
    handleNext,
    handlePrevious,
    initial,
    final,
    data,
    archived,
  } = props
  const styles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      height: '100%',
    },
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      verticalAlign: 'center',
      top: theme.spacing(2),
    },
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    searchIcon: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(2),
      flex: 1,
      display: 'flex',
    },
  }))
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip)
  const classes = styles()
  var temp = 'new'
  const validation = new Validations()
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={11}>
          <div>
            <Paper component='form'>
              <Grid container>
                <Grid item xs={12} className={classes.root}>
                  <InputBase
                    className={classes.input}
                    placeholder='Enter your idea or search items here ...'
                    value={search}
                    name='search'
                    autoComplete='off'
                    onChange={handleChange}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') event.preventDefault()
                    }}
                  />
                  <IconButton
                    className={classes.iconButton}
                    aria-label='search'
                  >
                    <Search />
                  </IconButton>
                  <Divider className={classes.divider} orientation='vertical' />
                  <Link
                    to={
                      '/console/wiki/' + (!search ? temp : search) + '/create'
                    }
                    style={{ color: 'white' }}
                  >
                    <IconButton color='primary' className={classes.iconButton}>
                      <Tooltip title='Add a new one'>
                        <Add />
                      </Tooltip>
                    </IconButton>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    {matches &&
                      matches
                        .sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        )
                        .slice(initial, final)
                        .map((ele) => (
                          <div>
                            <Divider />
                            <Link to={'/console/wiki/' + ele.id}>
                              <div className={classes.searchIcon}>
                                <Search style={{ padding: '3px' }} />{' '}
                                <Typography
                                  id='li'
                                  style={{ marginLeft: '5px' }}
                                >
                                  {ele.title}
                                </Typography>
                                <br />
                              </div>
                            </Link>
                          </div>
                        ))}
                    <div>
                      <Divider />
                      {matches.length > 5 ? (
                        <div className='d-flex justify-content-between'>
                          {initial <= 0 ? null : (
                            <IconButton onClick={handlePrevious}>
                              <NavigateBefore />
                            </IconButton>
                          )}
                          {final >= matches.length ? null : (
                            <IconButton onClick={handleNext}>
                              <NavigateNext />
                            </IconButton>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
        {state.access_modules.includes('wiki-manager') ? (
          <Grid item sm={1}>
            <Tooltip title='Wiki Settings'>
              <Link to={'/console/wiki/settings'} style={{ color: 'white' }}>
                <Fab size='medium' className='ml-2' color='secondary'>
                  <Settings />
                </Fab>
              </Link>
            </Tooltip>
          </Grid>
        ) : null}

        <Grid item xs={6} sm={3}>
          <Paper className={classes.paper}>
            <Typography
              variant='h4'
              className='text-center'
              color='textPrimary'
            >
              Recently Added
            </Typography>
            {data.length ? (
              data
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .slice(0, 5)
                .map((ele) => (
                  <div>
                    <Divider className='mt-3' />
                    <div className='d-flex'>
                      <div>
                        <Tooltip title={validation.emailToName(ele.createdBy)}>
                          <Avatar
                            className={classes.small}
                            src={validation.getImage(ele.createdBy)}
                          />
                        </Tooltip>
                      </div>
                      <div className='ml-3'>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                className='text-center'
                                color='inherit'
                              >
                                {ele.title}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <Link
                            to={'/console/wiki/' + ele.id}
                            style={{ marginLeft: '4px' }}
                          >
                            {ele.title.trim().length > 35 ? (
                              <Typography variant='body1'>
                                {ele.title.trim().substring(0, 35) + '...'}
                                <br />
                              </Typography>
                            ) : (
                              <Typography variant='body1'>
                                {ele.title.trim()}
                                <br />
                              </Typography>
                            )}
                          </Link>
                        </HtmlTooltip>
                        <Typography
                          className='d-flex'
                          variant='caption'
                          color='textSecondary'
                        >
                          CreatedOn:{' '}
                          <em className='mr-1'>
                            {validation.dateAndTimeFormatter(ele.createdAt)}
                          </em>
                          <div className='d-flex mr-1'>
                            <FaRegThumbsUp size={18} />
                            <p className='ml-1'>{ele.upVotes.length}</p>
                          </div>
                          <div className='d-flex'>
                            <FaRegThumbsDown size={18} />
                            <p className='ml-1'>{ele.downVotes.length}</p>
                          </div>
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <Typography className='text-center mt-3'>
                No Articles to display
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.paper}>
            <Typography
              variant='h4'
              className='text-center'
              color='textPrimary'
            >
              General
            </Typography>
            {uniqCategorieNames.includes(
              '815616c0-e573-4383-a724-57eb19088299'
            ) ? (
              Categories[
                uniqCategorieNames.indexOf(
                  '815616c0-e573-4383-a724-57eb19088299'
                )
              ]
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .slice(0, 5)
                .map((ele) => (
                  <div>
                    <Divider className='mt-3' />
                    <div className='d-flex'>
                      <div>
                        <Tooltip title={validation.emailToName(ele.createdBy)}>
                          <Avatar
                            className={classes.small}
                            src={validation.getImage(ele.createdBy)}
                          />
                        </Tooltip>
                      </div>
                      <div className='ml-3'>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                className='text-center'
                                color='inherit'
                              >
                                {ele.title}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <Link
                            to={'/console/wiki/' + ele.id}
                            style={{ marginLeft: '4px' }}
                          >
                            {ele.title.trim().length > 35 ? (
                              <Typography variant='body1'>
                                {ele.title.trim().substring(0, 35) + '...'}
                                <br />
                              </Typography>
                            ) : (
                              <Typography variant='body1'>
                                {ele.title.trim()}
                                <br />
                              </Typography>
                            )}
                          </Link>
                        </HtmlTooltip>
                        <Typography
                          className='d-flex'
                          variant='caption'
                          color='textSecondary'
                        >
                          CreatedOn:{' '}
                          <em className='mr-1'>
                            {validation.dateAndTimeFormatter(ele.createdAt)}
                          </em>
                          <div className='d-flex mr-1'>
                            <FaRegThumbsUp size={18} />
                            <p className='ml-1'>{ele.upVotes.length}</p>
                          </div>
                          <div className='d-flex'>
                            <FaRegThumbsDown size={18} />
                            <p className='ml-1'>{ele.downVotes.length}</p>
                          </div>
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <Typography className='text-center mt-3'>
                No Articles to display
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.paper}>
            <Typography
              variant='h4'
              className='text-center'
              color='textPrimary'
            >
              Knowledge
            </Typography>
            {uniqCategorieNames.includes(
              'ab110f11-ee6a-4fa9-89dc-70250b61f8f8'
            ) ? (
              Categories[
                uniqCategorieNames.indexOf(
                  'ab110f11-ee6a-4fa9-89dc-70250b61f8f8'
                )
              ]
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .slice(0, 5)
                .map((ele) => (
                  <div>
                    <Divider className='mt-3' />
                    <div className='d-flex'>
                      <div>
                        <Tooltip title={validation.emailToName(ele.createdBy)}>
                          <Avatar
                            className={classes.small}
                            src={validation.getImage(ele.createdBy)}
                          />
                        </Tooltip>
                      </div>
                      <div className='ml-3'>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                className='text-center'
                                color='inherit'
                              >
                                {ele.title}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <Link
                            to={'/console/wiki/' + ele.id}
                            style={{ marginLeft: '4px' }}
                          >
                            {ele.title.trim().length > 35 ? (
                              <Typography variant='body1'>
                                {ele.title.trim().substring(0, 35) + '...'}
                                <br />
                              </Typography>
                            ) : (
                              <Typography variant='body1'>
                                {ele.title.trim()}
                                <br />
                              </Typography>
                            )}
                          </Link>
                        </HtmlTooltip>
                        <Typography
                          className='d-flex'
                          variant='caption'
                          color='textSecondary'
                        >
                          CreatedOn:{' '}
                          <em className='mr-1'>
                            {validation.dateAndTimeFormatter(ele.createdAt)}
                          </em>
                          <div className='d-flex mr-1'>
                            <FaRegThumbsUp size={18} />
                            <p className='ml-1'>{ele.upVotes.length}</p>
                          </div>
                          <div className='d-flex'>
                            <FaRegThumbsDown size={18} />
                            <p className='ml-1'>{ele.downVotes.length}</p>
                          </div>
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <Typography className='text-center mt-3'>
                No Articles to display
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.paper}>
            <Typography
              variant='h4'
              className='text-center'
              color='textPrimary'
            >
              Archived
            </Typography>
            {archived.length ? (
              archived
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .slice(0, 5)
                .map((ele) => (
                  <div>
                    <Divider className='mt-3' />
                    <div className='d-flex'>
                      <div>
                        <Tooltip title={validation.emailToName(ele.createdBy)}>
                          <Avatar
                            className={classes.small}
                            src={validation.getImage(ele.createdBy)}
                          />
                        </Tooltip>
                      </div>
                      <div className='ml-3'>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                className='text-center'
                                color='inherit'
                              >
                                {ele.title}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <Link
                            to={'/console/wiki/' + ele.id}
                            style={{ marginLeft: '4px' }}
                          >
                            {ele.title.trim().length > 35 ? (
                              <Typography variant='body1'>
                                {ele.title.trim().substring(0, 35) + '...'}
                                <br />
                              </Typography>
                            ) : (
                              <Typography variant='body1'>
                                {ele.title.trim()}
                                <br />
                              </Typography>
                            )}
                          </Link>
                        </HtmlTooltip>
                        <Typography
                          className='d-flex'
                          variant='caption'
                          color='textSecondary'
                        >
                          CreatedOn:{' '}
                          <em className='mr-1'>
                            {validation.dateAndTimeFormatter(ele.createdAt)}
                          </em>
                          <div className='d-flex mr-1'>
                            <FaRegThumbsUp size={18} />
                            <p className='ml-1'>{ele.upVotes.length}</p>
                          </div>
                          <div className='d-flex'>
                            <FaRegThumbsDown size={18} />
                            <p className='ml-1'>{ele.downVotes.length}</p>
                          </div>
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <Typography className='text-center mt-3'>
                No Articles to display
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className='text-center'>
            <Link to={'/console/wiki/more'}>
              <Button color='primary' size='medium' variant='contained'>
                More Articles
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
