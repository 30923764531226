import React, { useContext } from 'react';
import AddNewPlacement from "../components/TimeSheets/Placements/AddNewPlacement/Index";
import { DataContext } from "../contexts/data";
import CircularSpinner from '../components/generalcomponents/circularSpinner';

function PlacementEditPage(props) {
    const [state] = useContext(DataContext)
    if(state.hasOwnProperty('placements_list')) {
        return (
            <div>
                <AddNewPlacement
                    {...props.match.params}
                    isEdit={true}
                />
            </div>
        );
    }
    return <CircularSpinner />
}

export default PlacementEditPage;