import React from 'react'
import CreateForm from '../CreateForm'
import { Button } from "@material-ui/core"
import RangeSelector from "../RangeSelector"
import { Accordion } from "@material-ui/core"
import SelectTimesheets from "../SelectTimesheets"
import OtherInfo from "../OtherInformation"
import SelectExpenses from "../SelectExpenses"
function Presentation(props) {
    const { handleChange, onCreateInvoice, onUpdateInvoice, invoiceId } = props
    return (
        <div className="row" >
            <div className={props.invoiceBy === "client" ? "col-sm-9" : "col-12"} >
                <CreateForm 
                    {...props}
                />
            </div>
            {
                props.invoiceBy === "client" ? 
                <div  className="col-sm-3">
                    {/* <RangeSelector 
                        {...props}
                    /> */}
                    <SelectTimesheets {...props} />
                    <SelectExpenses {...props} />
                    <OtherInfo invoiceSettings={props.invoiceSettings} placementID={props.placementID} />
                </div>
                :
                null
            }
            
            <div  className={props.invoiceBy === "client" ? "col-9 mt-3 mb-2 text-center" : "col-12 mt-3 mb-2 text-center"}>
                {
                    invoiceId === "newinvoice" ? 
                        <Button onClick={onCreateInvoice} variant="contained" color="primary">
                            Create Invoice
                        </Button>
                    :
                        <Button onClick={onUpdateInvoice} variant="contained" color="secondary">
                            Update Invoice
                        </Button>    
                }
                
            </div>
        </div>
    )
}

export default Presentation
