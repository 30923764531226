import React, { useContext } from 'react'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { TextField, MenuItem, Button, Typography } from '@material-ui/core'
import { DataContext } from '../../../../contexts/data'
import { configuration } from '../../../../configuration'
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

export default function Presentation(props) {
    const { handleContent, handleChange, handleCreate, cancelUpdate, handleUpdate, name, type, handleDelete, helperText, content } = props
    const [state] = useContext(DataContext)
    const isUpdating = state.hasOwnProperty('letter_content') && state.letter_content.updating

    return (
        <div>
            <form onSubmit={isUpdating ? handleUpdate : handleCreate} >
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                    <TextField
                        label="Enter template name"
                        onChange={handleChange}
                        size="small"
                        value={name}
                        variant="outlined"
                        name='name'
                        required
                    />
                    <TextField
                        select
                        label="Choose Type"
                        style={{ width: 250 }}
                        value = {type}
                        name='type'
                        required
                        onChange={handleChange}
                    >
                        <MenuItem value='' >None</MenuItem>
                        {configuration.letterTypes.map((option) => (
                            <MenuItem key={option.replace(/ /g, '').toLowerCase()} value={option.replace(/ /g, '').toLowerCase()}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                {helperText.length ? <Typography color="error">Check these labels with reference labels</Typography> : null}
                <div>{helperText.map(item =>
                    <div>
                        <Typography color="error"><li>{item}</li></Typography>
                    </div>
                )
                }</div>
                <SunEditor
                    onChange={handleContent}
                    setContents={content}
                    setOptions={{
                        "buttonList": [
                            [
                                "undo",
                                "redo",
                                "font",
                                "fontSize",
                                "formatBlock",
                                "paragraphStyle",
                                "blockquote",
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                                "fontColor",
                                "hiliteColor",
                                "textStyle",
                                "removeFormat",
                                "outdent",
                                "indent",
                                "align",
                                "horizontalRule",
                                "list",
                                "lineHeight",
                                "table",
                                "link",
                                "image",
                                "video",
                                "fullScreen",
                                "showBlocks",
                                "codeView",
                                "preview",
                                "print",
                                "save",
                                "template"
                            ],

                        ],

                    }}


                />
                <div className="text-center mt-4">
                    {
                        isUpdating ?
                            <span>
                                {
                                    helperText.length ?
                                        <Button
                                            variant="contained"
                                            className="flairbtn"
                                            disabled
                                            type='button'
                                        >
                                            Update letter
                                        </Button> :
                                        <Button
                                            variant="contained"
                                            className="flairbtn"
                                            type='submit'
                                        >
                                            Update letter
                                        </Button>
                                }
                                {' '}
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={cancelUpdate}
                                >
                                    Cancel
                                </Button>
                            </span>
                            :
                            helperText.length ?
                                <Button variant="contained" type="button" color="primary" disabled>
                                    Create new template
                                </Button> :
                                <Button variant="contained" type="submit" color="primary" onClick={handleCreate}>
                                    Create new template
                            </Button>
                    }

                </div>
                {
                    isUpdating ?
                        <div className="text-right" >
                            <Tooltip title="Delete this template">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type='button'
                                    onClick={handleDelete}
                                >
                                    <DeleteIcon />
                                </Button>
                            </Tooltip>
                        </div>
                        :
                        null

                }
            </form>
            <Typography className="mt-2"><h4><u>Note:</u></h4> Use the listed labels with '~Label~ ' format. (Use space after the Label)</Typography>
        </div>
    )
}