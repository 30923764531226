import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LanguageIcon from '@material-ui/icons/Language';
import { FiExternalLink } from 'react-icons/fi'
import EditClientDetails from "../EditClientDetails"


const useStyles = makeStyles((theme) =>
  createStyles({
    large: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  }),
);
function Presentation(props) {
    const { client } = props
    const classes = useStyles()
    return (
        <div class="custom-card row" >
            <div className="d-flex col-10">
                <div className="d-flex m-2">
                    <div>
                        <Avatar src="" className={classes.large} />
                    </div>
                    <div className="ml-2" >
                        <h3>{client.businessName} </h3>
                        <div className="text-dark d-flex" >
                            <span><PhoneIcon fontSize="small" />{ client.contactNumber } </span>&nbsp;&nbsp;&nbsp;
                            <span><MailOutlineIcon fontSize="small" /> { client.email } </span>
                        </div>
                        <div className="text-dark d-flex" >
                            <span><LanguageIcon fontSize="small" /> <a style={{textDecoration:'underline'}}  target="_blank" href={"https://"+client.website} >{ client.website }<FiExternalLink size={14}/> </a>  </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-2" >
                <EditClientDetails 
                    client={client}
                />
            </div>
        </div>
    )
}

export default Presentation
