import React, {useContext} from 'react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import ToolTip from '@material-ui/core/Tooltip'
import Backdrop from '../generalcomponents/backDrop'
// icons
import { FaHistory,FaCalendarAlt,FaTasks,FaEdit,FaUsers,FaEnvelopeOpenText } from 'react-icons/fa'
// import { Tune } from '@material-ui/icons'
import { IoMdChatboxes, IoMdAlarm } from 'react-icons/io'
import { TiDocumentText,TiUserAdd } from 'react-icons/ti'
import { AiOutlineSafetyCertificate } from 'react-icons/ai'
import { MdBusinessCenter } from 'react-icons/md'
import ReceiptIcon from '@material-ui/icons/Receipt';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { RiGroup2Line } from 'react-icons/ri'
// general imports
import Main from '../../Main'
import { Link } from 'react-router-dom'
import { BrowserRouter } from 'react-router-dom'
import DataLoader from '../DataLoader'
import { DataContext } from '../../contexts/data'
import UpdatePasssword from '../AuthComponents/UpdatePassword'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { configuration } from '../../configuration'
import { GiPayMoney, GiTakeMyMoney } from "react-icons/gi"

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'linear-gradient(45deg, #280071 10%, #c42053 90%)',
    boxShadow: '0 3px 3px 2px rgb(229,230,230)',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color:'white'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    fontSize:'15px',
    // overflowX : 'overlay'
  overflowX : 'overlay',

   // zoom:"80%"
  },
  align:{
    marginLeft:'auto',
  },
  menuItem:{
    marginLeft:'8px'
  }
}));





export default function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openIcon = Boolean(anchorEl);

  const { onSignOut } = props

  const handleMenu = event => {
    setAnchorEl(event.currentTarget); 
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [ state ] = useContext(DataContext)

  let show = false

  // if(state.access_modules.includes("employees-manager") && state.access_modules.includes("task-management") && Object.keys(state).includes("employees_collection") && Object.keys(state).includes("projectList")){
  //   show = true
  // }else if(state.access_modules.includes("employees-manager") && Object.keys(state).includes("employees_collection")){
  //   show = true
  // }else if(state.access_modules.includes("task-management") && Object.keys(state).includes("projectList")){
  //   show = true
  // }else show = true

  if(state.employee.role === "user" && state.hasOwnProperty("names") && state.hasOwnProperty("employee_collection"))
    show = true
  else if(state.employee.role === "admin" && state.hasOwnProperty("names"))
    show = true  

   let generalServices = [
    {
        text:'Task management',
        icon:<FaTasks size={18} />,
        link:'/console/projects',
        moduleName:'task-management'
    },
    {
      text:'Timesheets list',
      icon:<IoMdAlarm size={18} />,
      link:'/console/timesheetslist',
      moduleName : 'timesheets'
    },
    {
      text:'Expenses list',
      icon:<GiPayMoney size={18} />,
      link:'/console/expenses/list',
      moduleName : 'timesheets'
    },
    {
      text: 'Invoices',
      icon:<ReceiptIcon size={18}/>,
      link: '/console/invoiceslist',
      moduleName: 'timesheets'
    },
    {
      text: 'Deductions',
      icon: <GiTakeMyMoney size={18} />,
      link: '/console/deductionslist',
      moduleName: 'accounts-manager'
    },
    {
      text:'Payrolls',
      icon:<AttachMoneyIcon size={18} />,
      link:'/console/payrolls',
      moduleName: 'accounts-manager'
    },
    {
      text:'Wiki',
      icon:<TiDocumentText size={18} />,
      link:'/console/wiki',
      moduleName : 'wiki'
    },
    {
      text:'Discussions',
      icon:<IoMdChatboxes size={18} />,
      link:'/console/discussions',
      moduleName : 'discussions'
    },
    {
      text:'Invite employee',
      icon:<TiUserAdd size={18} />,
      link:'/console/inviteemployee',
      moduleName : 'console-customization'
    },
    
    {
      text:'Employee list',
      icon:<FaUsers size={18} />,
      link:'/console/employeelist',
      moduleName : 'employees-manager'
    },
    {
      text:'ESS Requests',
      icon:<FaEnvelopeOpenText size={18}/>,
      link:'/console/myrequests',
      moduleName: 'employee-self-services'
    },
    {
      text:'Letter requests',
      icon:<AiOutlineSafetyCertificate size={18} />,
      link:'/console/letterrequests',
      moduleName : 'employee-self-services-manager'
    },
    {
      text:'Placements',
      icon:<MdBusinessCenter size={18} />,
      link:'/console/placements',
      moduleName : 'timesheets-manager'
    },
    {
      text:'Clients list',
      icon:<RiGroup2Line size={20}/>,
      link:'/console/clientslist',
      moduleName : 'console-customization'
    },
    {
      text:'Create template',
      icon:<FaEdit size={18} />,
      link:'/console/createtemplate',
      moduleName: 'console-customization'
    },
    {
      text:'History',
      icon:<FaHistory size={18} />,
      link:'/console/history',
      moduleName: 'common-module'
    },
  ];
  

    


  return (
    <div className={classes.root}>
      <BrowserRouter>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <img src={configuration.logo} alt="" height="60" />
          <div className={classes.align}>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar alt="Remy Sharp" src={localStorage.getItem("imageURL")} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                open={openIcon}
                onClose={handleClose}
              >
                {localStorage.getItem('role') !== "admin" && <Link  style={{color:'black'}}  to="/console/profile"><MenuItem onClick={handleClose}>Profile</MenuItem></Link> }
                <Link to="/console" style={{color:'black'}} ><MenuItem onClick={handleClose}>Console</MenuItem></Link>
                <MenuItem onClick={handleClose}><span className="text-capital" >{localStorage.getItem('role')}</span></MenuItem>
                <MenuItem  style={{color:'black'}}  onClick={onSignOut} >Sign out</MenuItem>
                <MenuItem   style={{color:'black'}} onClick={handleClose}><UpdatePasssword /></MenuItem>
              </Menu>
            </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
      <List>
          {generalServices.map((item) => {
              if(state.access_modules.includes(item.moduleName) || state.access_modules.includes('console-customization') || item.moduleName === 'common-module')
              return(
                <Link  to={item.link} button="true" key={item.text}> 
                    <ListItem className={classes.menuItem}>
                      <ToolTip title={item.text} placement="right-end" arrow>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                      </ToolTip>
                    <ListItemText primary={item.text} />
                    </ListItem>
                </Link>
              )
              return null
          }
        )}
      </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <DataLoader/>
        {
          show ? 
          <div>
            <Backdrop openBackdrop={false} />
            <Main/>
          </div>
          :
          <Backdrop openBackdrop={true} />
        }
        
      </main>
      </BrowserRouter>

    </div>
  );
}
