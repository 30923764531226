import React, { Component } from 'react'
import Presentation from './Presentation'
import fire from '../../../firebase_config'
import {storage} from '../../../firebase_config';
import Prompt from '../../../components/generalcomponents/snackBars'
import { render } from 'react-dom';
import { DataContext } from '../../../contexts/data'

class Container extends Component {
    state = {
        profile:{},
        image:null,
        progress:0,
        url:'',
        imageURL:"",
       email:""
        
    }
    static contextType = DataContext
    componentDidMount=()=>{
      
    }

    componentWillReceiveProps = (nextProps) => {
      this.setState({profile:nextProps.profile })
    }
    
    handleChange = (e,email) => {
        console.log(e.target.files[0])
        console.log(email)
        // this.setState({email:email})
        if (e.target.files[0]) {
         const image = e.target.files[0];
        this.setState(() => ({image}));
        this.ImgUpload(image,email)
      }
      else{

      }
    }
    ImgUpload = (image,email) =>{
        var size=5000000
       console.log(email)
         console.log(image.type)
               if(image.type==='image/png' || image.type==='image/jpeg' || image.type==='image/jpg'){               
            if(image.size>size){
                render(
                    <Prompt open={true} content="Image size should be less than 5MB" severity="error" />,
                    document.getElementById('notifications-box')
                )
        this.setState({image:null})
        } 
    else{   
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on('state_changed', 
        (snapshot) => {
          // progrss function ....
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 150);
          console.log(progress)
          this.setState({progress:150});
        
        }, 
        (error) => {
             // error function ....
          console.log(error);
        }, 
      () => {
          // complete function ....
          storage.ref('images').child(image.name).getDownloadURL().then(url => {
              console.log(url);
              this.setState({url});
              
              fire.firestore().collection("Users").doc(email).update({
                            imageURL:url
                        })
                        this.setState({image:null})
                        this.setState({progress:0})
          })
      });
    }
      }
      else {
        render(
            <Prompt open={true} content="You Are Allowed To Choose Images with jpeg,png,jpg Format" severity="error" />,
            document.getElementById('notifications-box')
        )
        this.setState({image:null}) 
      }}

  
    
    render() {
        
            return (
            <div>
                    <Presentation
                    {...this.state}
                    {...this.props}
                    handleChange={this.handleChange}
                    progress={this.state.progress}
                    />
                
            </div>
        )
    }
}

export default Container
