import React, { Component } from 'react'
import Presentation from './Presentation'
import readXlsxFile from 'read-excel-file'
import { DataContext } from '../../../../contexts/data'
import { START_EXCEL_INVITE } from '../../../../contexts/types'
import firebase from '../../../../firebase_config'
import Axios from 'axios'

export class Container extends Component {

    static contextType = DataContext

    handleFileChange = (e) => {
        const [ state, dispatch ] = this.context
        readXlsxFile(e.target.files[0]).then((rows) => {
            // `rows` is an array of rows
            // each row being an array of cells. 
            console.log(rows)
            const expression = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            let emails = []
            rows.filter((v,i) => i!==0).map(row => {
                let emailCheck = expression.test(row[2]) 
                if(emailCheck && !state.employees_collection.filter(employee => employee.status !== "Inactive").map(user => user.useremail).includes(row[2].toString())){
                    console.log(state.activeEmployees.map(user => user.useremail))
                    emails.push({
                        firstname:row[0].toString(),
                        lastname : row[1].toString(),
                        email : row[2].toString().trim(),
                        phonenumber : row[3].toString(),
                        dob : isNaN(Date.parse(row[4].toISOString())) ? "Invalid date" : row[4].toISOString(),
                        branch : row[5].toString(),
                        isInvited : false,
                        isInviting : true,
                        comment : '',
                        validEmail : true,
                        shalliInvite : true
                    })
                }
                else if(emailCheck && state.employees_collection.filter(employee => employee.status !== "Inactive").map(user => user.useremail).includes(row[2].toString())){
                    emails.push({
                        firstname:row[0].toString(),
                        lastname : row[1].toString(),
                        email : row[2].toString().trim(),
                        phonenumber : row[3].toString(),
                        dob : isNaN(Date.parse(row[4].toISOString())) ? "Invalid date" : row[4].toISOString(),
                        branch : row[5].toString(),
                        isInvited : false,
                        isInviting : false,
                        comment : 'Already registered',
                        validEmail : true,
                        shalliInvite : false
                    })
                }
                else{
                    emails.push({
                        firstname:row[0].toString(),
                        lastname : row[1].toString(),
                        email : row[2].toString().trim(),
                        phonenumber : row[3].toString(),
                        dob : isNaN(Date.parse(row[4].toISOString())) ? "Invalid date" : row[4].toISOString(), 
                        branch : row[5].toString(),
                        isInvited : false,
                        isInviting : false,
                        comment : 'Invalid email format',
                        validEmail : false,
                        shalliInvite : false
                    })
                }  
            })
            return emails
        }).then(emails => {
            dispatch({
                type : START_EXCEL_INVITE,
                payload : emails.filter(email => email.shalliInvite === false)
            })

            emails.filter(item => item.shalliInvite === true).forEach(row => {
                const email = row.email
                const dbRef = firebase.firestore().collection('ExcelInvite').doc(email)
                dbRef.set(row)
            })

        })
    }


    startInvite = () => {
        const [state, dispatch] = this.context
        let emails = state.excel_invite
        state.excel_invite.forEach(async(item) => {
            let email = item.email
                console.log(email)
                let res = await Axios.post('/invitethroughtoken',
                                {
                                    uid:email,
                                    type:"inviteUser",
                                    category : 'employeeInvitation',
                                    privilege : 'invite-employee',
                                    moduleName : 'console-customization'
                                })
                                console.log(res.data)
                if(res.data.status){
                    emails.forEach((v,i) => {
                        if(v.email === email){
                            emails[i]['isInviting'] = false
                            emails[i]['isInvited'] = true
                            emails[i]['comment'] = 'Invited'
                        }
                    })
                    dispatch({
                        type : START_EXCEL_INVITE,
                        payload : emails
                    })
                }
                else{
                    if(res.data.code === "already-registered"){
                        emails.forEach((v,i) => {
                            if(v.email === email){
                                emails[i]['isInviting'] = false
                                emails[i]['isInvited'] = false
                                emails[i]['comment'] = 'Already registered!'
                            }
                        })
                    }
                    else{
                        emails.forEach((v,i) => {
                            if(v.email === email){
                                emails[i]['isInviting'] = false
                                emails[i]['isInvited'] = false
                                emails[i]['comment'] = 'Unknown error'
                            }
                        })
                    }
                    
                    dispatch({
                        type : START_EXCEL_INVITE,
                        payload : emails
                    })
                } 
        })
    }
    render() {
        return (
            <div>
                <Presentation 
                    handleFileChange = {this.handleFileChange}
                    handleFiles = {this.handleFiles}
                    startInvite = {this.startInvite}
                />
            </div>
        )
    }
}

export default Container
