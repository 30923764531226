import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import parser from 'html-react-parser'
import PageTemplate from '../generalcomponents/pageTemplate'
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { PDFExport } from '@progress/kendo-react-pdf';
import { DataContext } from '../../contexts/data'
// import { savePDF } from '@progress/kendo-react-pdf';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',

    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    new: {
        float: 'right'
    }
}));

function Presentation(props) {
    const [state] = useContext(DataContext)
    const style = {
        background : {
             backgroundImage: `url(${state.company_config.images.watermark})`,
             backgroundSize: '70% 25%',
             backgroundRepeat: 'no-repeat',
             backgroundPosition: 'center',
        },
        content: {
            height: '100%',
            width: '100%',
        }
    }
    const handleClose = () => {
        setOpen(false);

    };
    const Header = () => {
        return (
            <div>
                <header style={{ position: "absolute", top: "1px", left: "1px" }}>


                </header>




                <footer
                    style={{ position: "absolute", bottom: "-16px", left: "1px" }}>

                </footer>

            </div>
        )
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    let { pdfExportComponent, htmlContent, btnContent, headerAndFooter, iconBtn, isString } = props
    // if(typeof(htmlContent) === 'string')
    return (
        <div>
            {
                iconBtn ?
                    <IconButton onClick={handleClickOpen} color="primary">{btnContent}</IconButton>
                    :
                    <Button className="mt-3 mb-3" onClick={handleClickOpen} variant="contained" fullWidth color="primary">{btnContent}</Button>
            }
            <Dialog open={open} onClose={handleClose}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <PDFExport
                            paperSize="A4"
                            margin="2cm"
                            ref={(component) => pdfExportComponent = component}
                            pageTemplate={headerAndFooter ? PageTemplate : Header}
                        >
                             <div id="taskpdfid" style={style.background}>
                                <div style={style.content}> 
                                    {
                                        isString ? parser(htmlContent) : htmlContent
                                    }
                                </div>    
                            </div> 
                        </PDFExport>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => { pdfExportComponent.save(); }}
                            className={classes.submit}
                        >
                            Download
                        </Button>
                    </Paper>
                </main>

            </Dialog>

        </div>
    )
}

export default Presentation