import React, { useContext, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import { DataContext } from '../../../../contexts/data'
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import { configuration } from '../../../../configuration'
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TablePagination from '@material-ui/core/TablePagination';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import SubtasksTable from "../taskcomponents/SubtasksTable";
import NewTask from '../taskcomponents/NewTask'
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Validation from '../../../generalcomponents/validation'
import Badge from '@material-ui/core/Badge';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },



}));
const useStyles = makeStyles({
  root1: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
const customStyles = {
  employeeExist: {
    color: '#4183c4',
    textTransform: 'capitalize'
  },
  employeeNonExist: {
    color: '#626466',
    textTransform: 'capitalize',
  }
}
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});
function Row(props) {
  const { row, taskList, labels } = props;
  console.log(row)
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const [state] = useContext(DataContext)
  const taskId = row.id
  const projectId = state.project.id
  let rows = []
  state.taskList.forEach(task => {
    if (task.taskId === taskId)
      rows.push(task)
  })
  const formatter = (date) => {
    let final = ""
    try {
      final = Intl.DateTimeFormat(configuration["date-code"], configuration.dateformat).format(new Date(date))
    } catch (error) {
      console.log(error)
      final = date
    }
    return final
  }
  const validate = {
    emailToName: (uid) => {
      try {
        return state.names.filter(item => item.uid === uid)[0].name
      } catch (error) {
        console.log(error)
        return uid
      }
    }
  }
  dayjs.extend(relativeTime)

  const diff = dayjs()

  const getDueBy = (enddate, status) => {
    console.log(new Date() - new Date(enddate))
    if (status === 'Completed' || status === 'Closed')
      return false
    return new Date() - new Date(enddate) >= 0 ? true : false
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          {
            (rows.length > 0 && row.category === "task") ?
              (<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>) : ""
          }
        </TableCell>
        <TableCell component="th" scope="row">
          <Chip size="small" label={state.project.cid + " - " + row.cid} />
        </TableCell>
        {
          labels === true ?
            <TableCell component="th" scope="row">
              {
                row.labels.length === 0 ? ""
                  :
                  row.labels.map(element => <span><Chip size="small" style={{ backgroundColor: `${element.colorCode}`, color: "white", marginBottom: '2px' }} label={element.name} /><br /></span>)}
            </TableCell> : ""}
        <TableCell ><div style={{ display: "flex" }} >
          <span>
            {
              row.category === "task" ?
                <Link to={"/console/projects/" + state.project.id + "/" + row.id} ><Button type="a" style={{ textTransform: "capitalize", color: "#4183c4" }} size="small">{row.title}</Button></Link>
                :
                <Link to={"/console/projects/" + state.project.id + "/" + row.taskId + "/" + row.id} ><Button type="a" style={{ textTransform: "capitalize", color: "#4183c4" }} size="small">{row.title}</Button></Link>
            }
            <br />
            {
              row.type === "Task" ?
                <Chip size="small" style={{ backgroundColor: "#17a2b8", color: "white" }} label="Task" />
                :
                <Chip size="small" style={{ backgroundColor: "#db2828", color: "white" }} label="Bug" />
            }&nbsp;&nbsp;&nbsp;
                                  <em>{row.status}</em>&nbsp;&nbsp;&nbsp;{formatter(row.startdate)}&nbsp;&nbsp;&nbsp;{formatter(row.enddate)}
          </span>
        </div></TableCell>
        <TableCell >{row.createdByName}</TableCell>
        <TableCell >{getDueBy(row.enddate, row.status) ? diff.from(row.enddate, true) : '---'} </TableCell>
        <TableCell align="right" >{row.priority}</TableCell>
        <TableCell align="right" >{row.assignee.map(employee => <span><Link to={"/console/employeelist/" + employee} ><Button type="a" style={state.projectList.filter(project => project.id === state.project.id)[0].Users.map(user => user.uid).includes(employee) ? customStyles.employeeExist : customStyles.employeeNonExist} size="small">{validate.emailToName(employee)}</Button></Link><br /></span>)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <SubtasksTable taskId={taskId}
                projectId={props.projectId}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
export default function Presentation(props) {
  const [state] = useContext(DataContext)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterBy, setFilter] = useState("")
  const classes = useStyles();


  let taskList = props.taskList
  let data = []
  let labels = state.project.useLabels
  let projectMembers = state.project.Users.map(user => user.uid)
  let allAssignees = []
  let temp = []
  const validate = {
    emailToName: (uid) => {
      try {
        return state.names.filter(item => item.uid === uid)[0].name
      } catch (error) {
        console.log(error)
        return uid
      }
    }
  }
 
  if (state.hasOwnProperty("taskList")) {
    state.taskList.forEach(task => {
      temp.push(...task.assignee)
    })
    temp = [...new Set(temp)]
    if (state.employee.role === 'user')
      projectMembers = temp
    projectMembers.forEach(member => {
      let count = 0
      state.taskList.forEach(task => {
        if (task.assignee.includes(member)) {
          count++
        }
      })
      allAssignees.push({
        uid: member,
        count: count
      })
    })
  }
  const Validate = new Validation()
  console.log(filterBy)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChange = (key, input, list) => {
    console.log(input, list)

    function escapeRegex(string) {
      return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    input = escapeRegex(input.toLowerCase())
    //  console.log(hasWhiteSpace(input))
    let matches = list.filter(state => {
      const regex = new RegExp(`[A-Za-z.\s]*${input}[A-Za-z.\s]*`);
      return regex.test(state.title.toLowerCase())
    });
    let matches1 = list.filter(state => {
      const regex = new RegExp(`[A-Za-z.\s]*${input}[A-Za-z.\s]*`);
      return regex.test(state.category.toLowerCase())
    });
    let matches2 = list.filter(state => {
      const regex = new RegExp(`[A-Za-z.\s]*${input}[A-Za-z.\s]*`);
      return regex.test(state.priority.toLowerCase())
    });
    let matches3 = list.filter(state => {
      const regex = new RegExp(`[A-Za-z.\s]*${input}[A-Za-z.\s]*`);
      return regex.test(state.cid)
    });
    let matches4 = list.filter(state => {
      const regex = new RegExp(`[A-Za-z.\s]*${input}[A-Za-z.\s]*`);
      return regex.test(state.assignee)
    });
    let matches5 = list.filter(state => {
      const regex = new RegExp(`[A-Za-z.\s]*${input}[A-Za-z.\s]*`);
      return regex.test(state.createdByName.toLowerCase())
    });
    let match = [...matches1, ...matches, ...matches2, ...matches3, ...matches4, ...matches5]
    let dataListArray = match.reduce((newArray, item) => {
      if (newArray.includes(item)) {
        return newArray
      } else {
        return [...newArray, item]
      }
    }, [])
   

    console.log( dataListArray)

  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log(taskList)

  const projectLabels = state.projectList.filter(item => item.id === state.project.id)[0].labels
  taskList.length && taskList.forEach(task => {
    data.push({
      title: task.title,
      id: task.id,
      cid: task.cid,
      startdate: task.startdate,
      enddate: task.enddate,
      status: task.status,
      assignee: task.assignee,
      createdByName: validate.emailToName(task.createdBy),
      type: task.type,
      labels: projectLabels.filter(item => task.hasOwnProperty('labels') && task.labels.includes(item.id)),
      createdBy: task.createdBy,
      projectTaskId: state.project.cid + " - " + task.cid,
      category: task.category,
      priority: task.priority,
      taskId: task.category === "subtask" ? task.taskId : ""
    })
  })

  console.log(data)

  console.log(taskList)
  return (
    <div>
      <main >
        <Grid container spacing={0}>
          <Grid xs={12}>
            <Card className={classes.root1}>

              <CardContent>


                <div >

                  <NewTask projectId={state.project.id} text="New task" category="task" />&nbsp;&nbsp;&nbsp;&nbsp;


       
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />&nbsp;&nbsp;&nbsp;&nbsp;



        </div>
                <Grid>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell  >TaskId</TableCell>
                        {
                          labels === true ?
                            <TableCell  >Labels</TableCell> : ""}
                        <TableCell >Title</TableCell>
                        <TableCell>createdBy</TableCell>
                        <TableCell>Due By</TableCell>
                        <TableCell align="right">Priority</TableCell>
                        <TableCell align="right" >Assignee</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : data).map((row) => (
                          <Row key={row.name} row={row}
                            taskList={taskList}
                            labels={labels} />
                        ))}
                    </TableBody>
                  </Table>
                </Grid>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </main>
    </div>

  );
}