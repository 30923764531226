import React, { useContext } from 'react'
import CreateExpenses from '../components/TimeSheets/Expenses/CreateExpenses/Index'
import { DataContext } from "../contexts/data"
import CircularSpinner from "../components/generalcomponents/circularSpinner"
function CreateExpensePage(props) {
  const [state] = useContext(DataContext)
  if(state.hasOwnProperty("expenses_list"))
    return (
      <div>
        <CreateExpenses {...props} />
      </div>
    )
  return <CircularSpinner />  
}

export default CreateExpensePage
