
import React, { Component } from 'react'
import Presentation from './Presentation'
import {storage} from '../../../firebase_config'
import { DataContext } from '../../../contexts/data'
import { UPDATE_PROFILE } from '../../../contexts/types'
import Validations from '../../generalcomponents/validation'
class Container extends Component {

    constructor(props){
        super(props)
        this.validate = new Validations()
    }

    state={
        workauthData:[],
        work_type:"",
        work_number:"",
        work_issue:"",
        work_exp:"",
        work_doc:"",
        editSection:[],
        fileName : '',
        isUploading : false
      
    }

    static contextType = DataContext


    componentDidMount=()=>{
        let { workauthData } = this.props
        console.log(workauthData)
        if(workauthData)
        for(let i=0;i<workauthData.length;i++){
            if(workauthData.length === 0)
                continue
            workauthData[i]['id']=i+1
        }
        this.setState({
            workauthData
        })
        this.props.handleUpdateProfile("workauth",workauthData)
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.workauthData !== this.props.workauthData){
            this.setState({
                workauthData:nextProps.workauthData
            })
            this.props.handleUpdateProfile("workauth",nextProps.workauthData)
        }
    }

    handleDateChange = (key, value) => {
        if(!isNaN(Date.parse(value))) 
        this.setState({
            [key]:new Date(value).toISOString()
        })
    }

    handleChange = (e) => {
        if(e.target.name === "work_type" && e.target.value === "USC"){
            this.setState({
                work_type:e.target.value,
                work_number:"",
                work_issue:"",
                work_exp:""
            })
        }
        else{
            this.setState({
                [e.target.name]:e.target.value
            })
        }

        console.log(e.target.value)
    }

    handleAdd = (e) =>{
        e.preventDefault()
        const dispatch = this.context[1]
        let data = this.state.workauthData
        const appendData={
            work_type:this.state.work_type,
            work_number:this.state.work_number,
            work_exp:this.state.work_exp,
            work_issue:this.state.work_issue,
            work_doc:this.state.work_doc,
            id:data.length+1
        }
        data = [...data,appendData]
        let finalData = {}
        finalData['workauth'] = data
        dispatch({
            type : UPDATE_PROFILE,
            payload : {
                email:this.props.email,
                updateValue : finalData
            }
        })
        // this.props.handleUpdateProfile("workauth",data)
        this.setState({
            workauthData:data,
            work_type:"",
            work_number:"",
            work_issue:"",
            work_exp:"",
            work_doc:""
        })
    }

    handleDelete = (index) =>{
        const dispatch = this.context[1]
        let arr = this.state.workauthData
        arr.splice(index,1)
        let finalData = {}
        finalData['workauth'] = arr
        dispatch({
            type : UPDATE_PROFILE,
            payload : {
                email:this.props.email,
                updateValue : finalData
            }
        })
        // this.props.handleUpdateProfile("workauth",arr)
        this.setState({workauthData:arr})
    }

    handleEdit = (item) =>{
        console.log(item)
        this.setState({editSection:item})
        this.setState({
            work_type:item.work_type,
            work_number:item.work_number,
            work_issue:item.work_issue,
            work_exp:item.work_exp,
            work_doc:item.work_doc
        })
    }

    handleExisting = (index,item) =>{
        const dispatch = this.context[1]
        let arr = this.state.workauthData
        let changedItem={
            work_type:this.state.work_type  ,
            work_number:this.state.work_number ,
            work_exp:this.state.work_exp ,
            work_issue:this.state.work_issue ,
            work_doc:this.state.work_doc 
        }
        arr[index] = changedItem    
        let finalData = {}
        finalData['workauth'] = arr
        dispatch({
            type : UPDATE_PROFILE,
            payload : {
                email:this.props.email,
                updateValue : finalData
            }
        })
        // this.props.handleUpdateProfile("workauth",arr)
        this.setState({
            workauthData:arr,
            work_type:"",
            work_number:"",
            work_issue:"",
            work_exp:"",
            work_doc:""
        })
    }

    clearValues=()=>{
        this.setState({
            work_type:"",
            work_number:"",
            work_issue:"",
            work_exp:"",
            work_doc:""
        })
    }

    handleChange2 = e => {
        console.log(e.target.files[0])
      if (e.target.files[0]) {
        const file = e.target.files[0];
        this.setState(() => ({file}));
        this.fileUpload1(file)
      }
    }

    fileUpload1 = (file) =>{
        const employeeCompanyID = this.props.profile.companyID
        const fileName = [employeeCompanyID, '_WA_', this.state.work_type, '_', new Date().toISOString(), '.', file.name.split('.')[1]].join('')
        const filePath = `EmployeeInfo/${employeeCompanyID}/WorkAuthorization/${fileName}`
        this.setState({isUploading:true})
        return this.validate.uploadToStorage(file, filePath, fileName, 'file')
        .then(url => {
            this.setState({ work_doc : url })
            this.setState({isUploading:false})
        })
        .catch(err => {
            this.setState({isUploading:false})
            console.log(err)
        })
    }
    
    render() {
        return (
            <div>
                {
                    this.state.workauthData ? 
                        <Presentation
                        {...this.state}
                        handleAdd={this.handleAdd}
                        handleChange={this.handleChange}
                        onOpenModal={this.onOpenModal}
                        onCloseModal={this.onCloseModal}
                        handleDelete={this.handleDelete}
                        handleEdit={this.handleEdit}
                        handleExisting={this.handleExisting}
                        isEditing={this.props.isEditing}
                        clearValues={this.clearValues}
                        handleChange2={this.handleChange2}
                        upload={this.state.upload}
                        handleDateChange={this.handleDateChange}
                        
                        />
                     :
                     <p>Loading ...</p>   
                }
                
            </div>
        )
    }
}

export default Container