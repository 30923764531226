import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import {
  CREATE_WIKI_PAGE,
  SET_WIKI_CATEGORY,
  SET_WIKI_PAGES,
} from '../../../contexts/types'
import { CircularProgress } from '@material-ui/core'
import Axios from 'axios'
import { Redirect } from 'react-router-dom'
import Validations from '../../generalcomponents/validation'

class Container extends Component {
  constructor() {
    super()
    this.validate = new Validations()
    this.state = {
      content: '',
      title: '',
      type: '',
      isVisible: false,
      helperText: '',
      redirect: false,
      attachment: [],
      isUploading: false,
      progress: 0,
    }
  }

  static contextType = DataContext

  handleContent = (data) => {
    this.setState({
      content: data,
    })
  }

  getCategories = () => {
    let temp = this.props.search
    if (temp === 'new') {
      this.setState({
        isVisible: true,
        title: '',
      })
    } else {
      this.setState({
        isVisible: true,
        title: this.props.search,
      })
    }
  }

  componentDidMount = async () => {
    const [state, dispatch] = this.context
    if (
      !state.hasOwnProperty('wikiCategories') ||
      !state.hasOwnProperty('wikiPages')
    ) {
      let res = await Axios.get('wiki/getCategories')
      dispatch({
        type: SET_WIKI_CATEGORY,
        payload: res.data.response,
      })
      this.getCategories()
      let response = await Axios.get('/getPagesInWiki')
      dispatch({
        type: SET_WIKI_PAGES,
        payload: response.data.response,
      })
    } else {
      this.getCategories()
    }
  }

  clearValues = () => {
    this.setState({
      content: '',
      title: '',
      type: '',
    })
  }

  handleNewArticle = () => {
    const { content, title, type, attachment } = this.state
    const dispatch = this.context[1]
    dispatch({
      type: CREATE_WIKI_PAGE,
      wikiAddPageCallBack: this.wikiAddPageCallBack,
      payload: {
        topicName: type,
        title: title.trim(),
        articleContent: content,
        attachments: attachment
      },
    })
  }

  wikiAddPageCallBack = async () => {
    const dispatch = this.context[1]
    let response = await Axios.get('/getPagesInWiki')
    dispatch({
      type: SET_WIKI_PAGES,
      payload: response.data.response,
    })
    this.setState({ redirect: true })
    this.clearValues()
  }

  handleAutoComplete = (e, v) => {
    this.setState({ [e]: v })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
    const [state] = this.context
    let titles = state.wikiPages.map((ele) => {
      return ele.title.replace(/\s/g, '').toLowerCase()
    })
    if (e.target.name === 'title') {
      let value = e.target.value
      if (titles.includes(value.replace(/\s/g, '').toLowerCase())) {
        let helperText = value.trim() ? 'Title already exists' : ''
        this.setState({ helperText })
      } else {
        this.setState({ helperText: '' })
      }
    }
  }

  componentWillUnmount = () => {
    this.clearValues()
  }

  handleChange2 = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      this.setState(() => ({ file }))
      this.fileUpload1(file, this.state.documentType)
    }
  }

  fileUpload1 = async (file, type) => {
    const fileName = file.name
    const filePath = `Wiki/Documents/${fileName}`
    this.setState({ isUploading: true })
    try {
      const url = await this.validate.uploadToStorage(
        file,
        filePath,
        fileName,
        'file'
      )
      this.setState((prevState) => ({
        attachment: prevState.attachment.concat({ name: file.name, url: url}),
      }))
      this.setState({ isUploading: false })
    } catch (err) {
      this.setState({ isUploading: false })
      console.log(err)
    }
  }

  handleDeleteAttachment = (index) => {
    const { attachment } = this.state
    let updated_array = []
    updated_array = attachment
    updated_array.splice(index, 1)
    this.setState({
      attachment: updated_array,
    })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/console/wiki'} />
    }
    if (this.state.isVisible) {
      return (
        <div>
          <Presentation
            {...this.state}
            handleContent={this.handleContent}
            handleChange={this.handleChange}
            handleNewArticle={this.handleNewArticle}
            handleAutoComplete={this.handleAutoComplete}
            handleDeleteAttachment={this.handleDeleteAttachment}
            handleChange2={this.handleChange2}
          />
        </div>
      )
    } else {
      return (
        <div style={{ marginTop: '15%', alignContent: 'center' }}>
          <div className='text-center'>
            <CircularProgress color='primary' />
          </div>
        </div>
      )
    }
  }
}

export default Container
