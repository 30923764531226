import React, { Component } from 'react'
import Presentation from "./Presentation"
import { DataContext } from '../../../../../contexts/data'
import { UPDATE_PLACEMENT_SETTINGS } from '../../../../../contexts/types'
export class Container extends Component {
    constructor(){
        super()
        this.state = {
            projectEndDate: '',
            placementDocId: ''
        }
    }

    static contextType = DataContext
    componentWillReceiveProps = (nextProps) => {
        const { placementID } = nextProps
        if(this.props.placementID !== placementID && placementID !== ""){
            const [state] = this.context
            const placement = state.placements_list.filter(item => item.placementID === placementID)[0]
            this.setState({
                projectEndDate: placement.projectEndDate,
                placementDocId: placement.id
            })
        }
    }
    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    onUpdate = () => {
        const dispatch = this.context[1]
        const { projectEndDate, placementDocId } = this.state
        dispatch({
            type: UPDATE_PLACEMENT_SETTINGS,
            payload:{
                projectEndDate: projectEndDate,
                docId: placementDocId,
                sectionName: 'general'
            }
        })
    }
    render() {
        return (
                <Presentation 
                    handleChange={this.handleChange}
                    onUpdate={this.onUpdate}
                    placementID={this.props.placementID}
                    {...this.state}
                    invoiceSettings={this.props.invoiceSettings}
                />
        )
    }
}

export default Container
