import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from '../../../contexts/data'
import EmployeeSort from '../EmployeeSort'


const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      display:'flex'
    },
    paper: {
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    new : {
      backgroundColor:"black",
      color:"white"
    }
  }));
function Presentation(props) {
  
  const [ state ] = useContext(DataContext)
  
  const classes = useStyles();
  let allAssignees = []
  if(state.hasOwnProperty("taskList"))
    state.taskList.forEach(task => {
        allAssignees.push(...task.assignee)
    })
  allAssignees = [ ...new Set(allAssignees)]  

    return (
        <div>
            <main className={classes.layout}>
                <div style={{width:"100%"}} >
                    <EmployeeSort />
                </div>
            </main>
        </div>
    )
}

export default Presentation
