import React from 'react';
import More from '../components/Wiki/More';
import { Breadcrumbs } from '@material-ui/core'
import { Link } from 'react-router-dom'


export default function WikiMorePage(params) {
    return (
        <div>
            <div>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to='/'>Home</Link>
                    <Link to="/console/wiki">Wiki</Link>
                    <Link to='/console/wiki/more'>More</Link>
                </Breadcrumbs>
            </div>
            <div>
                <More />
            </div>
        </div>
    )
}