import React, { Component } from 'react'
import { storage } from '../../../../firebase_config'
import Presentation from './Presentation'
import { ADD_AUTHORISED_SIGNATURE } from '../../../../contexts/types'
import { DataContext } from '../../../../contexts/data'

class Container extends Component {
    state = {
        uid: "",
        imageURL: ""
    }

    static contextType = DataContext

    componentDidMount = () => {
        this.clearValues()
    }

    handleChange2 = e => {
        console.log(e.target.files[0])
        if (e.target.files[0]) {
            const file = e.target.files[0];
            this.setState(() => ({ file }));
            this.fileUpload1(file)
        }
    }

    fileUpload1 = (file) => {
        var size = 10000000
        console.log(file.type)
        if (file.size > size) {
            alert("File size should be less than 10MB")
            this.setState({ file: null })
        }
        else {
            const uploadTask = storage.ref(`files/${file.name}`).put(file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    console.log(uploadTask)
                    let upload = false
                    const progress1 = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.setState({ progress1 });
                    console.log(progress1)
                    // this.setState({upload:false})
                    console.log(upload)

                },
                (error) => {
                    // error function ....
                    console.log(error);
                },
                () => {
                    // complete function ....
                    storage.ref('files').child(file.name).getDownloadURL().then(url => {
                        console.log(url);
                        this.setState({ imageURL: url });
                        this.setState({ documentURL: url, upload: true });
                        this.setState({ file: null })
                        this.setState({ progress1: 100 })
                        this.setState({ uploading: true })

                    })
                });
        }
    }

    handleChange = (input) => (e) => {
        this.setState({
            [input]: e.target.value
        })
    }

    handleNewRequest = (e) => {
        e.preventDefault();
        const { uid, imageURL } = this.state
        const dispatch = this.context[1]
        dispatch({
            type: ADD_AUTHORISED_SIGNATURE,
            payload: {
                imageURL: imageURL,
                uid: uid
            }
        })
        this.clearValues()
    }

    clearValues = () => {
        this.setState({
            uid: "",
            imageURL: ""
        })
    }

    componentWillUnmount = () => {
        this.setState({
            uid: "",
            imageURL: ""
        })
    }



    render() {
        return (
            <div>
                <Presentation
                    {...this.state}
                    handleChange={this.handleChange}
                    clearValues={this.clearValues}
                    handleChange2={this.handleChange2}
                    handleNewRequest={this.handleNewRequest}
                />
            </div>
        )
    }
}

export default Container
