import React, { useContext, useState, useEffect } from 'react'
import {
  TextField,
  MenuItem,
  Grid,
  makeStyles,
  Button,
  TextareaAutosize,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Validations from '../../../../generalcomponents/validation'
import NumberFormat from 'react-number-format'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DataContext } from '../../../../../contexts/data'

const useStyles = makeStyles((theme) => ({}))

const Presentation = (props) => {
  const classes = useStyles()
  const {
    comments,
    billableClient,
    jobTitle,
    handleChange,
    handleSave,
    handleDateChange,
    startDate,
    endDate,
    projectEndDate,
    docId,
    netTerms,
    client,
  } = props
  const validate = new Validations()

  const handleStartDateChange = (date) => {
    handleDateChange('startDate', date)
  }

  const handleEndDateChange = (date) => {
    handleDateChange('endDate', date)
  }
  const handleProjectEndDateChange = (date) => {
    handleDateChange('projectEndDate', date)
  }

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        decimalScale={2}
        format='##'
        allowNegative={false}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        isNumericString
      />
    )
  }

  const [state] = useContext(DataContext)

  let options = state.hasOwnProperty('client_list') ? state.client_list : []
  const [reqClient, setReqClient] = useState({})
  useEffect(() => {
    setReqClient(options.filter((client) => client.id === billableClient)[0])
  }, [client])

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Autocomplete
              fullWidth
              options={options}
              onChange={(_, value) => {
                if (value) {
                  handleChange('billableClient', value.id)
                }
              }}
              value={reqClient}
              autoHighlight
              getOptionLabel={(option) => option.businessDisplayName}
              renderOption={(option) => (
                <React.Fragment>{option.businessDisplayName}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Billable Client *'
                  size='small'
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              label='Job Title'
              size='small'
              fullWidth
              variant='outlined'
              helperText={jobTitle.length ? '' : 'Enter valid job title'}
              value={jobTitle}
              onChange={(event) => handleChange('jobTitle', event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              size='small'
              required
              fullWidth
              label='Net Terms'
              name='netTerms'
              value={netTerms}
              onChange={(event) => handleChange('netTerms', event.target.value)}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <KeyboardDatePicker
                    required
                    fullWidth
                    size='small'
                    id='date-picker-startdate'
                    label='Start date'
                    format='MM/dd/yyyy'
                    value={startDate ? startDate : null}
                    onChange={handleStartDateChange}
                    name='startDate'
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <KeyboardDatePicker
                    required
                    size='small'
                    fullWidth
                    minDate={startDate}
                    id='date-picker-startdate'
                    label='End date'
                    format='MM/dd/yyyy'
                    value={endDate ? endDate : null}
                    onChange={handleEndDateChange}
                    name='endDate'
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <KeyboardDatePicker
                    size='small'
                    required
                    fullWidth
                    minDate={startDate}
                    value={
                      projectEndDate ? projectEndDate : endDate ? endDate : null
                    }
                    id='date-picker-enddate'
                    label='Project End date'
                    format='MM/dd/yyyy'
                    onChange={handleProjectEndDateChange}
                    name={'projectEndDate'}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              placeholder='Comment'
              marginTop={3}
              rowsMin={5}
              style={{ marginTop: '10px', width: '100%' }}
              value={comments}
              onChange={(event) => handleChange('comments', event.target.value)}
            />
          </Grid>
        </Grid>
        <br />
        {!docId ? (
          validate.checkDateDiff(startDate, projectEndDate) &&
          validate.checkDateDiff(startDate, endDate) &&
          billableClient &&
          jobTitle.trim().length &&
          validate.checkName(jobTitle) ? (
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className='text-center'
              onClick={(e) => handleSave('create')}
            >
              Done
            </Button>
          ) : (
            <Button
              type='button'
              variant='contained'
              color='primary'
              className='text-center'
              disabled
            >
              Done
            </Button>
          )
        ) : validate.checkDateDiff(startDate, projectEndDate) &&
          validate.checkDateDiff(startDate, endDate) &&
          billableClient &&
          jobTitle.trim().length &&
          validate.checkName(jobTitle) ? (
          <Button
            type='submit'
            variant='contained'
            color='secondary'
            className='text-center'
            onClick={(e) => handleSave('update')}
          >
            Update
          </Button>
        ) : (
          <Button
            type='button'
            variant='contained'
            color='primary'
            className='text-center'
            disabled
          >
            Update
          </Button>
        )}
      </form>
    </div>
  )
}

export default Presentation

const billableClientOptions = ['Onsite', 'Remote']
