export const SET_CUSTOM_KEY = 'SET_CUSTOM_KEY'

export const CHECK_LOGIN = 'CHECK_LOGIN'
export const CHECK_AUTH = 'CHECK_AUTH'
export const SIGN_OUT = 'SIGN_OUT'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const SET_COMPANY_CONFIG = 'SET_COMPANY_CONFIG'
export const UPDATE_COMPANY_CONFIG = 'UPDATE_COMPANY_CONFIG'
export const SET_MODULES = 'SET_MODULES'
export const OFFICER_RESETS_PASSWORD = 'OFFICER_RESETS_PASSWORD'
export const EMPLOYEE_RESETS_TEMP_PASSWORD = 'EMPLOYEE_RESETS_TEMP_PASSWORD'

export const HISTORY = 'HISTORY'
export const FORM_DATA = 'FORM_DATA'

export const EMPLOYEE_COLLECTION = 'EMPLOYEE_COLLECTION'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const SET_EMPLOYEES_COLLECTION = 'SET_EMPLOYEES_COLLECTION'
export const SET_ACTIVE_EMPLOYEES = 'SET_ACTIVE_EMPLOYEES'
export const PROMOTE_EMPLOYEE = 'PROMOTE_EMPLOYEE'
export const DEMOTE_EMPLOYEE = 'DEMOTE_EMPLOYEE'
export const SUSPEND_EMPLOYEE = 'SUSPEND_EMPLOYEE'
export const ENABLE_EMPLOYEE = 'ENABLE_EMPLOYEE'
export const EMPLOYEE_NAMES = 'EMPLOYEE_NAMES'
export const START_EXCEL_INVITE = 'START_EXCEL_INVITE'

export const NEW_PROJECT = 'NEW_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const SET_PROJECTS = 'SET_PROJECTS'
export const SET_PROJECT = 'SET_PROJECT'
export const UPDATE_ACCESS_LEVELS = 'UPDATE_ACCESS_LEVELS'
export const ADD_MEMBER = 'ADD_MEMBER'
export const REMOVE_MEMBER = 'REMOVE_MEMBER'
export const NEW_TASK = 'NEW_TASK'
export const UPDATE_TASK = 'UPDATE_TASK'
export const NEW_SUB_TASK = 'NEW_SUB_TASK'
export const SET_TASKS = 'GET_TASKS'
export const SET_TASK = 'SET_TASK'
export const SET_LOADED_TASK = 'SET_LOADED_TASK'
export const COMMENT = 'COMMENT'
export const UPDATE_COMMENT = 'UPDATE_COMMENT'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const LINK_TASKS = 'LINK_TASKS'
export const UNLINK_TASKS = 'UNLINK_TASKS'
export const CREATE_NEW_LABEL = 'CREATE_NEW_LABEL'
export const UPDATE_LABEL = 'UPDATE_LABEL'
export const DELETE_LABEL = 'DELETE_LABEL'

export const SET_COUNTRY_API = 'SET_COUNTRY_API'
export const COUNTRY_API_DATA = 'COUNTRY_API_DATA'

export const SET_LETTER_TEMPLATES = 'SET_LETTER_TEMPLATES'
export const ADD_LETTER_TEMPLATE = 'ADD_LETTER_TEMPLATE'
export const UPDATE_LETTER_TEMPLATE = 'UPDATE_LETTER_TEMPLATE'
export const DELETE_LETTER_TEMPLATE = 'DELETE_LETTER_TEMPLATE'
export const EMPLOYEE_REQUEST_LETTER = 'EMPLOYEE_REQUEST_LETTER'
export const OFFICER_ISSUE_LETTER = 'OFFICER_ISSUE_LETTER'
export const OFFICER_REJECT_LETTER = 'OFFICER_REJECT_LETTER'
export const SET_LETTER_CONTENT = 'SET_LETTER_CONTENT'
export const ADD_AUTHORISED_SIGNATURE = 'ADD_AUTHORISED_SIGNATURE'
export const DELETE_AUTHORISED_SIGNATURE = 'DELETE_AUTHORISED_SIGNATURE'
export const SET_AUTHORIZED_SIGNATURES = 'SET_AUTHORIZED_SIGNATURES'
export const SET_REQ_LETTER_CONTENT = 'SET_REQ_LETTER_CONTENT'
export const GET_SELECTED_LETTER_CONTENT = 'GET_SELECTED_LETTER_CONTENT'
export const SET_REQUESTED_LETTERS = 'SET_REQUESTED_LETTERS'
export const SET_LETTERS = 'SET_LETTERS'
export const SET_SIGNATURES = 'SET_SIGNATURES'

export const SET_EMAIL_CONTENT = 'SET_EMAIL_CONTENT'
export const SET_EMAIL_TEMPLATES = 'SET_EMAIL_TEMPLATES'
export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE'
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE'
export const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE'

export const SET_WIKI_PAGES = 'SET_WIKI_PAGES'
export const CREATE_WIKI_PAGE = 'CREATE_WIKI_PAGE'
export const UPDATE_WIKI_PAGE = 'UPDATE_WIKI_PAGE'
export const SET_WIKI_HISTORY = 'SET_WIKI_HISTORY'
export const DELETE_WIKI_PAGE = 'DELETE_WIKI_PAGE'
export const ADD_WIKI_CATEGORY = 'ADD_WIKI_CATEGORY'
export const DELETE_WIKI_CATEGORY = 'DELETE_WIKI_CATEGORY'
export const UPDATE_WIKI_CATEGORY = 'UPDATE_WIKI_CATEGORY'
export const SET_WIKI_CATEGORY = 'SET_WIKI_CATEGORY'
export const FOLLOW_UNFOLLOW_WIKI_ARTICLE = 'FOLLOW_UNFOLLOW_WIKI_ARTICLE'
export const VOTE_FOR_WIKI_ARTICLE = 'VOTE_FOR_WIKI_ARTICLE'
export const NEW_COMMENT_ON_WIKI = 'NEW_COMMENT_ON_WIKI'
export const UPDATE_OR_DELETE_WIKI_COMMENT = 'UPDATE_OR_DELETE_WIKI_COMMENTs'

export const PLACEMENT_SETTINGS_ACTIONS = 'PLACEMENT_SETTINGS_ACTIONS'
export const TEMPORARY_NEW_PLACEMENT_DATA = 'TEMPORARY_NEW_PLACEMENT_DATA'
export const CREATE_GENERAL_PLACEMENT_SETTINGS =
  'CREATE_GENERAL_PLACEMENT_SETTINGS'
export const CREATE_PAYMENTS_SETTINGS = 'CREATE_PAYMENTS_SETTINGS'
export const CREATE_WORKLOCATION_SETTINGS = 'CREATE_WORKLOCATION_SETTINGS'
export const CREATE_TIMESHEETS_SETTINGS = 'CREATE_TIMESHEETS_SETTINGS'
export const CREATE_TIMESHEETS_DOCUMENTS = 'CREATE_TIMESHEETS_DOCUMENTS'
export const CREATE_CLIENTDETAILS_SETTINGS = 'CREATE_CLIENTDETAILS_SETTINGS'
export const UPDATE_PLACEMENT_SETTINGS = 'UPDATE_PLACEMENT_SETTINGS'
export const CREATE_INVOICE = 'CREATE_INVOICE'
export const COMPLETE_TIMESHEETS_PROCESS = 'COMPLETE_TIMESHEETS_PROCESS'
export const EMPLOYEE_SUBMIT_TIMESHEET = 'EMPLOYEE_SUBMIT_TIMESHEET'
export const CLIENT_CURD = 'CLIENT_CURD'
export const TEMPORARY_NEW_CLIENT_DATA = 'TEMPORARY_NEW_CLIENT_DATA'
export const EMPLOYEE_TIMESHEET_ACTIONS = 'EMPLOYEE_TIMESHEET_ACTIONS'
export const MANAGER_TIMESHEET_ACTIONS = 'MANAGER_TIMESHEET_ACTIONS'
export const CLIENT_UPDATE_ACTIONS = 'CLIENT_UPDATE_ACTIONS'
export const CLIENT_CONTACT_ACTIONS = 'CLIENT_CONTACT_ACTIONS'
export const CLIENT_LOCATION_ACTIONS = 'CLIENT_LOCATION_ACTIONS'
export const INVOICE_ACTIONS = 'INVOICE_ACTIONS'
export const PAYMENT_ACTIONS = 'PAYMENT_ACTIONS'
export const EXPENSE_ACTIONS = 'EXPENSE_ACTIONS'
export const DEDUCTIONS_ACTIONS = 'DEDUCTIONS_ACTIONS'
export const PAYROLL_ACTIONS = 'PAYROLL_ACTIONS'
