import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../../../../contexts/data';
import { Checkbox, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, Typography, Badge } from "@material-ui/core"
import { getDaysInMonth, differenceInCalendarDays, eachDayOfInterval, isWithinInterval, addDays } from "date-fns"
import Validations from '../../../../generalcomponents/validation'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InvoiceTreeView from "../InvoiceTreeView"

function SetInvoiceRange(range, timesheets, handleChange, selectedSheets, invoiceSettings, placementID){
    console.log("wonderland"+ range)
    switch(range){
        // bi weekly
        case 2: return FilterByBiWeekly(timesheets, handleChange, selectedSheets, invoiceSettings);
        // semi-montly
        case 3: return FilterBySemiMonthlyOrMonthly(range, timesheets, handleChange, selectedSheets, invoiceSettings, placementID)
        // monthly
        case 4: return FilterBySemiMonthlyOrMonthly(range, timesheets, handleChange, selectedSheets, invoiceSettings, placementID)

        default: return <div>Invalid range</div>
    }
}



function FilterByBiWeekly(timesheets, handleChange, selectedSheets, invoiceSettings){
    const validate = new Validations()
    // const additionalTimesheets = []

    // set range for two timesheets
    console.log(timesheets.sort((a, b) => a.endDate - b.endDate))
    const sortedSheets = timesheets.sort((a, b) => a.endDate - b.endDate)
    let invoiceDateRange = ""
    if(sortedSheets.length > 1)
        invoiceDateRange = validate.dateFormatter(sortedSheets[0].startDate) + "-" + validate.dateFormatter(sortedSheets[1].endDate)
    else if(sortedSheets.length === 1)    
        invoiceDateRange = validate.dateFormatter(sortedSheets[0].startDate) + "-" + validate.dateFormatter(sortedSheets[0].endDate)
    // to check 14 days or not
    const [right, left] = invoiceDateRange.split("-")
    const days = differenceInCalendarDays(new Date(left), new Date(right))
    alert(days)
    // if(days < 14){
    //     console.log("wtf")
    //     const daysToBeAdded = 14 - days;
    //     let daysInTimesheets = 0;
    //     const arr = [...sortedSheets.slice(1)]
    //     // based on no. of days the timesheets should be choosen
    //     let i = 0;
    //     while(daysInTimesheets < daysToBeAdded){
    //         const daysInIteratingSheet = differenceInCalendarDays(new Date(arr[i].endDate), new Date(arr[i].startDate))
    //         if(daysInIteratingSheet + daysInTimesheets <= daysToBeAdded){
    //             additionalTimesheets.push(arr[i])
    //         }else if(daysToBeAdded - daysInTimesheets <= daysInIteratingSheet){
    //             const parsedSheet = JSON.parse(JSON.stringify(arr[i]))
    //             parsedSheet['workdetails']['standardTime'].filter((v, index) => index <= daysToBeAdded - daysInTimesheets)
    //             parsedSheet['workdetails']['OTtime'].filter((v, index) => index <= daysToBeAdded - daysInTimesheets)
    //             additionalTimesheets.push(parsedSheet)
    //         }
    //     }
    // }

    // const finalArr = sortedSheets.sort((a, b) => b.endDate - a.endDate)
    // invoiceDateRange = validate.dateFormatter(finalArr[0].startDate) + "-" + validate.dateFormatter(finalArr.reverse()[0].endDate)
    return (
        <InvoiceTreeView selectedSheets={selectedSheets} invoiceDateRange={invoiceDateRange} handleChange={handleChange} timesheets={sortedSheets.slice(0, 2)} />
    )
}

function FilterBySemiMonthlyOrMonthly(range, timesheets, handleChange, selectedSheets, invoiceSettings, placementID){
    const validate = new Validations()
    const selectedDate = timesheets.sort((a, b) => b.endDate - a.endDate)[0].endDate
    const year = new Date(selectedDate).getFullYear()
    const month = new Date(selectedDate).getMonth()
    const days = getDaysInMonth(year, month)
    const selectedDay = new Date(selectedDate).getDate()
    const [state] = useContext(DataContext)
    let placement={}
    placement = state.placements_list.filter(item => item.placementID === placementID)[0]
        
    let finalSheets = []
    let invoiceDateRange = ""
    if(range === 3){
        // semi-monthly
        //   return selectedDay <= 15 ? [new Date(year, month, 1), new Date(year, month, 15)] : [new Date(year, month, 16), new Date(year, month, days)]
        if(selectedDay <= 15){
            invoiceDateRange = validate.dateFormatter(new Date(year, month, 1)) + "-" + validate.dateFormatter(new Date(year, month, 15))
            finalSheets = FilterTimesheetsInTheDateRange(new Date(year, month, 1), new Date(year, month, 15), timesheets)
        }else {
            invoiceDateRange = validate.dateFormatter(new Date(year, month, 16)) + "-" + validate.dateFormatter(new Date(year, month, days))
            finalSheets = FilterTimesheetsInTheDateRange(new Date(year, month, 16), new Date(year, month, days), timesheets)
        }
    }
    else if(range === 4){
        // monthly 
        let placeRanges = []
        const eachDay = eachDayOfInterval({
            start: new Date(invoiceSettings.frequencyStartDate),
            end: new Date() < new Date(placement.endDate) ? new Date() : new Date(placement.endDate)
        })
        
        let initialDate = invoiceSettings.frequencyStartDate
        for(let i = 0; i < eachDay.length; i++){ 
            // const checkDayExistInterval = isWithinInterval(new Date(eachDay[i]), {
            //     start: state.invoices_list.filter(item => item.empCode === empCode && item.placementID === placementID && item.clientId === clientId).reduce((initial, item) => new Date(initial) > new Date(item.startDate) ? new Date(initial) : new Date(item.startDate), new Date(item.startDate)),
            //     end: state.invoices_list.filter(item => item.empCode === empCode && item.placementID === placementID && item.clientId === clientId).reduce((initial, item) => new Date(initial) > new Date(item.endDate) ? new Date(initial) : new Date(item.endDate), new Date(item.endDate))
            // })
            if(new Date(eachDay[i]).getMonth() !== new Date(eachDay[i+1]).getMonth()){
                placeRanges.push({
                    startDate: new Date(initialDate).setHours(0, 0, 0, 0),
                    endDate: new Date(eachDay[i]).setHours(0, 0, 0, 0)
                })
                initialDate = new Date(eachDay[i + 1])
            }
        }
        return placeRanges.map(item => {
            invoiceDateRange = validate.dateFormatter(new Date(item.startDate)) + "-" + validate.dateFormatter(new Date(item.endDate))
            finalSheets =  timesheets
            // finalSheets = FilterTimesheetsInTheDateRange(new Date(item.startDate), new Date(item.endDate), timesheets)

            return <InvoiceTreeView selectedSheets={selectedSheets} invoiceDateRange={invoiceDateRange} handleChange={handleChange} timesheets={[...finalSheets].sort((a, b) => a.endDate - b.endDate)} />
        })
    //   return  [new Date(year, month, 1), new Date(year, month, days)]
    }

    return <InvoiceTreeView selectedSheets={selectedSheets} invoiceDateRange={invoiceDateRange} handleChange={handleChange} timesheets={[...finalSheets].sort((a, b) => a.endDate - b.endDate)} />

  }

  
function FilterTimesheetsInTheDateRange(startDate, endDate, timesheets){
    const filteredSheets = []
    timesheets.forEach(item => {
        // eachDayOfInterval({start: new Date(item.startDate), end: new Date(item.endDate)}).forEach((date, index) => {
        //     const isInRange = isWithinInterval(new Date(date), {
        //         start: new Date(startDate),
        //         end: new Date(endDate)
        //     })
        //     if(isInRange) {
        //         const parsedSheet = JSON.parse(JSON.stringify(item))
        //         console.log(parsedSheet['workdetails']['standardTime'][index], index)
        //         workdetails['standardTime'][index] = parsedSheet['workdetails']['standardTime'][index]
        //         workdetails['OTtime'][index] = parsedSheet['workdetails']['OTtime'][index]
        //     }
        // })
        const invoiceStartDate = new Date(startDate)
        const invoiceEndDate = new Date(endDate)
        const timesheetStartDate = new Date(item.startDate).setHours(0, 0, 0, 0)
        const timesheetEndDate = new Date(item.endDate).setHours(0, 0, 0, 0)
        const checkStartRange = timesheetStartDate >= invoiceStartDate && timesheetStartDate <= invoiceEndDate
        const checkEndRange = timesheetEndDate >= invoiceStartDate && timesheetEndDate <= invoiceEndDate
        let sheet = JSON.parse(JSON.stringify(item))
        if(checkStartRange && checkEndRange){
            filteredSheets.push(sheet)
        }else if(checkStartRange){
            const daysToEnd = differenceInCalendarDays(invoiceEndDate, timesheetStartDate)
            console.log(daysToEnd)
            sheet['endDate'] = addDays(new Date(timesheetStartDate), daysToEnd).toISOString()
            sheet['workdetails']['standardTime'] = sheet['workdetails']['standardTime'].filter((v, i) => i <= daysToEnd)
            sheet['workdetails']['OTtime'] = sheet['workdetails']['OTtime'].filter((v, i) => i <= daysToEnd)
            filteredSheets.push(sheet)
        }else if(checkEndRange){
            const daysToStart = differenceInCalendarDays(timesheetEndDate, invoiceStartDate)
            console.log(daysToStart)
            sheet['startDate'] = addDays(new Date(timesheetEndDate), -daysToStart).toISOString()
            sheet['workdetails']['standardTime'] = sheet['workdetails']['standardTime'].reverse().filter((v, i) => i <= daysToStart).reverse()
            sheet['workdetails']['OTtime'] = sheet['workdetails']['OTtime'].reverse().filter((v, i) => i <= daysToStart).reverse()
            filteredSheets.push(sheet)
        }
        // if(eachDayOfInterval({start: new Date(item.startDate), end: new Date(item.endDate)}).every((date) => {
        //     const isInRange = isWithinInterval(new Date(date), {
        //         start: new Date(startDate),
        //         end: new Date(endDate)
        //     })
        //     return isInRange
        // }))
    })
    return filteredSheets
}  



function Presentation(props){
    const { placementID, clientId, selectedExpenses, handleChange, invoiceSettings, dbExpenses } = props
    const [state] = useContext(DataContext)
    const validate = new Validations()
    const expenseList = [...state.expenses_list.filter(item => item.placementID === placementID && item.clientId === clientId && item.isApproved === true && item.isInvoiceGenerated === false), ...dbExpenses]
    return (
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography>Expense sheets</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div className="d-flex flex-column" >
                    
                    {
                        expenseList.map(item => {
                            return(
                                <FormControlLabel
                                    checked={selectedExpenses.map(item => item.id).includes(item.id)}
                                    control={<Checkbox onChange={(e) => {
                                    if(e.target.checked) handleChange("selectedExpenses", [...selectedExpenses, item])
                                    else handleChange("selectedExpenses", [...selectedExpenses].filter(expense => expense.id !== item.id))
                                    }} />}
                                    label={validate.dateFormatter(item.departureDate) + "-" + validate.dateFormatter(item.returnDate)}
                                />
                            )
                            
                        })
                    }
                    </div>
                </AccordionDetails>
            </Accordion>
    )
}

export default Presentation
