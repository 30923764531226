import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { DataContext } from '../../../contexts/data'
import EmployeeList from '../EmployeesList'
import EmployeeOptions from '../EmployeeOptions'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}


export default function Presentation(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [ state ] = useContext(DataContext)
  let employeelist = []
    if(state.hasOwnProperty("employees_collection")){
    employeelist = state.employees_collection
  }

  const inviteFunction = (selected) => {
    const employee = new EmployeeList()
    // EmployeeList.prototype.onInviteAgain(selected)
    employee.onInviteAgain(selected)
  }


  console.log(employeelist)
  return (
    <div style={{backgroundColor:'#fff'}}>
        {/* <EmployeeOptions onInviteAgain={inviteFunction}  selected={props.selected} />  */}
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            
            <Tab label={<Badge color="primary" children="All" max="10000" badgeContent={employeelist.length} />} {...a11yProps(0)} />
            <Tab label={<Badge color="primary" children="Active" max="10000" badgeContent={employeelist.filter(employee => employee.status === "Active").length} />}  {...a11yProps(1)} />
            <Tab label={<Badge color="primary"  children="Inactive" max="10000" badgeContent={employeelist.filter(employee => employee.status === "Inactive").length} />} {...a11yProps(2)} />
            <Tab label={<Badge color="error"  children="Suspended" max="10000" badgeContent={employeelist.filter(employee => employee.status === "Suspended").length} />} {...a11yProps(3)} />
            <Tab label={<Badge color="primary" children="Selected" max="10000" badgeContent={props.selected.length} />} {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
            <EmployeeList all={true} handleSelectAll={props.handleSelectAll} selected={props.selected} handleSelect={props.handleSelect} employeelist={employeelist}  />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EmployeeList  active={true} handleSelectAll={props.handleSelectAll}  selected={props.selected} handleSelect={props.handleSelect} employeelist={employeelist.filter(employee => employee.status === "Active")} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EmployeeList inactive={true}  handleSelectAll={props.handleSelectAll}  selected={props.selected} handleSelect={props.handleSelect} employeelist={employeelist.filter(employee => employee.status === "Inactive")} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <EmployeeList suspended={true}  handleSelectAll={props.handleSelectAll}  selected={props.selected} handleSelect={props.handleSelect} employeelist={employeelist.filter(employee => employee.status === "Suspended")} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <EmployeeList  handleSelectAll={props.handleSelectAll} selected={props.selected} handleSelect={props.handleSelect} employeelist={employeelist.filter(employee => props.selected.includes(employee.useremail))} />
        </TabPanel>
        </div>
  );
}
