import React from 'react';
import MaterialTable from 'material-table';
import { Grid } from '@material-ui/core'
import AddSignature from '../AddSignature'
import { DataContext } from '../../../../contexts/data'
import Validations from '../../../generalcomponents/validation'
import { Delete } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

export default function Presentation(props) {

    const { handleDelete } = props
    const [state] = React.useContext(DataContext)
    const validate = new Validations()
    const data = state.auth_signatures.map(signature => {
        return {
            authorizationsignatory: validate.emailToName(signature.uid),
            designation: state.names.filter(item => item.uid === signature.uid)[0].jobtitle,
            eSigndo: signature.signature.publicURL,
            signatureId:signature.id,
            signatureUID:signature.uid
        }
    })

    const columns = [
        { title: 'Authorization Signatory', field: 'authorizationsignatory' },
        { title: 'Designation', field: 'designation' },
        { title: 'e-Sign do', field: 'eSigndo', render: (row) => <img alt='' src={row.eSigndo} height={20} /> },
        { title: '', field: 'icon', render:(row) => <IconButton onClick={() => handleDelete(row.signatureId, row.signatureUID)} color="secondary" >
                                                        <Delete />
                                                    </IconButton> 
        }
    
    ]
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <AddSignature />
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        title=""
                        columns={columns}
                        data={data}
                        options={{
                            paginationType: "stepped",
                            pageSize: 10
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

