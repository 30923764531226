import React from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';


function Presentation(props){
  const {handleResetPass} = props
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
   const handleok =()=>{
    setOpen(false);
    handleResetPass()
   }   
    const handleClose = () => {
      setOpen(false);
    };
   
    


    return(
    <div className="App">
        
    <div>
       <Grid container>
              <Grid item xs>
                <span  onClick={handleClickOpen} style={{color:'#c42053', fontSize: '14px', fontWeight:'bold'}} className="c-pointer" id="for-pass">Forgot Password?</span>
              </Grid>
            </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogContent>
          <DialogContentText>
          Enter your user account's verified email address and we will send you a password reset link.
          </DialogContentText>
          <TextField
            autoFocus
            onChange={props.handleChange}
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleok} color="primary">
           OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
      

    </div>

    )
}

export default Presentation