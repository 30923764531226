import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data';
import Axios from 'axios'
import firebase from '../../../firebase_config'
import {PAYROLL_ACTIONS} from "../../../contexts/types"
class Container extends Component {
    state = {
        docId: '',
        companyID: '',
        dealPay: '',
        excludeDeductions: [],
        payBonus: '',
        insurance: [],
        isBench: false,
        fixedPay: [],
        fixedBenchPayroll: [],
        dealPeriod: [],
        stopPayroll: false,


    }

    static contextType = DataContext

    handleChange = (key, value) => {

        this.setState({
            [key]: value
        })
    }
    payrollActionsCallback = () => {

    }
    onUpdate = () => {
        const dispatch = this.context[1]
        const { 
            dealPay,
            excludeDeductions,
            payBonus,
            insurance,
            isBench,
            fixedPay,
            fixedBenchPayroll,
            dealPeriod,
            stopPayroll,
            companyID
        } = this.state
        let payload = {
            dealPay:dealPay,
            payBonus:payBonus,
            excludeDeduction:excludeDeductions,
            insurance:insurance,
            isBench:isBench,
            fixedPay:fixedPay,
            fixedBenchPayroll:fixedBenchPayroll,
            dealPeriod:dealPeriod,
            stopPayroll:stopPayroll
        }
    
        dispatch({
            type: PAYROLL_ACTIONS,
            payload: payload,
            actionType: "update",
            companyID:companyID,
            payrollActionsCallback: this.payrollActionsCallback,
        })
    }

    handleCheck = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        })
    }


    componentDidMount = () => {
        const { id } = this.props
        const [state] = this.context
        const profile = state.employees_collection.filter(profile => profile.useremail === id || profile.companyID === id)[0]
        this.setState({
            companyID: profile.companyID
        })
        firebase.firestore().collection("Payrolls").doc(profile.companyID).collection("PayrollSettings").doc("settings").get().then(snap => {
            const settings = snap.data()
            console.log(settings)
            this.setState({
                dealPay: settings.dealPay,
                payBonus: settings.payBonus,
                excludeDeductions: settings.excludeDeduction,
                insurance: settings.insurance,
                isBench: settings.isBench,
                fixedPay: settings.fixedPay,
                fixedBenchPayroll: settings.fixedBenchPayroll,
                dealPeriod: settings.dealPeriod,
                stopPayroll: settings.stopPayroll


            })
        })

    }
    render() {
        return (
            <div>
                <Presentation
                    {...this.props}
                    {...this.state}
                    handleChange={this.handleChange}
                    onUpdate={this.onUpdate}
                    handleCheck={this.handleCheck}
                />
            </div>
        )
    }
}

export default Container
