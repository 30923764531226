import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
import { SET_PROJECT, SET_TASKS, SET_TASK, SET_LOADED_TASK } from '../../../../contexts/types'
import firebase from '../../../../firebase_config'
import Alert from '@material-ui/lab/Alert';

class Container extends Component {
    state = {
        projectId:"",
        taskId:"",
        comments : []
    }
    static contextType = DataContext

    componentDidMount(){
        console.log(this.props)
        const projectId = this.props.projectId
        const taskId = this.props.taskId
        firebase.firestore().collection("Projects/"+projectId+"/Tasks/"+taskId+"/Comments")
        .orderBy('createdAt','asc')
        .where("isExist","==",true)
        .onSnapshot(snap => {
            let comments = snap.docs.map(doc => doc.data())
            this.setState({comments})
        })
        this.setState({projectId, taskId})
        this.getData(projectId, taskId)
    }

    getData =(projectId, taskId) => {
        const [ state, dispatch ] = this.context
        dispatch({
            type:SET_PROJECT,
            payload:projectId
        })

        if(state.hasOwnProperty("taskList")){
            if(state.taskList.length){
                dispatch({
                    type:SET_LOADED_TASK,
                    payload:taskId
                })
            }
        }
        else{
        firebase.firestore().collection("Projects/"+projectId+"/Tasks")
        .where("isExist","==",true)
        .onSnapshot(snap => {
            let data = snap.docs.map(doc => doc.data())

            dispatch({
                type:SET_TASKS,
                payload:data
            })
            
            dispatch({
                type:SET_TASK,
                payload:data.filter(task => task.id === taskId)[0]
            })
        })
        }
    }


    componentWillUnmount = () => {
        const dispatch = this.context[1]
        this.setState({
            comments:[],
            projectId:"",
            taskId:""
        })

        dispatch({
            type:SET_TASK,
            payload:{}
        })
    }

    render() {
        const [ state ] = this.context
        console.log(state)
        let content = ""
        try {
                    content =  (
                        <div>
                            {
                                state.hasOwnProperty('task') && Object.keys(state.task).length && Array.isArray(state.task.assignee) && Array.isArray(state.project.Users) ?
                                        <Presentation 
                                            projectId = {this.props.projectId}
                                            taskId = {this.props.taskId}
                                            comments = {this.state.comments}
                                            {...this.props}
                                        />
                                        :
                                <p>Task loading ...</p>
                            }
                        </div>
                    )
                            
        } catch (error) {
            console.log(error)
            content =  <Alert className="text-center" variant="filled" severity="info">Please check connection</Alert>        
        }
        
        return content

    }
}

export default Container
