import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import CustomSpinner from '../../generalcomponents/circularSpinner'
import { MANAGER_TIMESHEET_ACTIONS, SET_CUSTOM_KEY } from '../../../contexts/types'
import firebase from '../../../firebase_config'
import { Placement } from '../../../contexts/shared/Timesheets/Placements'
export class Container extends Component {
    state = {
        placements : [],
        isLoading : true,
        description: '',
        timesheetSettings: []
    }
    static contextType = DataContext

    componentDidMount = () => {
        this.handleCheckTimesheetApproval()
    }

    managerTimesheetActionCallback = (type) => {
        console.log(type)
    }

    approveTimesheet = (timesheetId, placementID) => {
        const dispatch = this.context[1]
        dispatch({
            type : MANAGER_TIMESHEET_ACTIONS,
            payload : {
                timesheetId,
                placementID
            },
            actionType : 'approve',
            managerTimesheetActionCallback: this.managerTimesheetActionCallback
        })
    }


    rejectTimesheet = (timesheetId, placementID) => {
        const dispatch = this.context[1]
        dispatch({
            type : MANAGER_TIMESHEET_ACTIONS,
            payload : {
                timesheetId,
                description: this.state.description,
                placementID
            },
            actionType : 'reject',
            managerTimesheetActionCallback: this.managerTimesheetActionCallback
        })
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleCheckTimesheetApproval = async() => {
        const [state] = this.context
        const list = state.timesheets_list
        const placementIds = [...new Set(list.map(item => item.placementID))]
        const promises = []
        placementIds.forEach(async(placementID) => {
            const id = state.placements_list.filter(item => item.placementID === placementID)[0].id
            const placementObj = new Placement(id)
            const promise =  placementObj.getSettings("Timesheets")
            promises.push(promise)
        })

        return Promise.all(promises).then((details) => {
            console.log(details)
            this.setState({
                timesheetSettings: details.map((detail, index) => {
                    return{ 
                        approvalBy:  detail.approvalBy,
                        placementID: placementIds[index]
                    }
                })
            })
            return 
        })
        
    }

    render() {
        const [state] = this.context
        if(state.hasOwnProperty("timesheets_list"))
            return (
                <div>
                    <Presentation 
                        {...this.state}
                        {...this.props}
                        empID={this.props.id}
                        handleChange={this.handleChange}
                        approveTimesheet = {this.approveTimesheet}
                        rejectTimesheet = {this.rejectTimesheet}
                    />
                </div>
            )
        return <CustomSpinner />
    }
}

export default Container
