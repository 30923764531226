import React, { useContext } from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' //Import Sun Editor's CSS File
import {
  TextField,
  Button,
  makeStyles,
  Grid,
  Paper,
  Typography,
  IconButton,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { DataContext } from '../../../contexts/data'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { GoFileSymlinkFile } from 'react-icons/go'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => ({
  layout: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
}))

export default function Presentation(props) {
  const {
    handleContent,
    handleChange,
    title,
    category,
    content,
    handleUpdate,
    helperText,
    handleAutoComplete,
    handleChange2,
    handleDeleteAttachment,
    isUploading,
    attachment,
  } = props
  const classes = useStyles()
  const [state] = useContext(DataContext)
  let CategorieNames =
    state.hasOwnProperty('wikiCategories') &&
    state.wikiCategories.map((ele) => {
      return ele.name
    })
  return (
    <Grid container>
      <Grid item xs={12} sm={3}></Grid>
      <Grid item xs={12} sm={6}>
        <div>
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <Typography component='h1' variant='h4' align='center'>
                Edit Article
              </Typography>
              <React.Fragment>
                <Typography variant='h6' gutterBottom></Typography>
                <form>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        label='Enter Article name'
                        onChange={handleChange}
                        size='small'
                        autoComplete='off'
                        value={title}
                        helperText={helperText}
                        name='title'
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        options={CategorieNames}
                        onChange={(_, value) => {
                          if (value) {
                            handleAutoComplete('category', value)
                          }
                        }}
                        autoHighlight
                        value={CategorieNames[CategorieNames.indexOf(category)]}
                        getOptionLabel={(option) => option}
                        renderOption={(option) => (
                          <React.Fragment>{option}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Choose Category'
                            size='small'
                            required
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SunEditor
                        onChange={handleContent}
                        setContents={content}
                        setOptions={{
                          buttonList: [
                            [
                              'undo',
                              'redo',
                              'font',
                              'fontSize',
                              'formatBlock',
                              'paragraphStyle',
                              'blockquote',
                              'bold',
                              'underline',
                              'italic',
                              'strike',
                              'subscript',
                              'superscript',
                              'fontColor',
                              'hiliteColor',
                              'textStyle',
                              'removeFormat',
                              'outdent',
                              'indent',
                              'align',
                              'horizontalRule',
                              'list',
                              'lineHeight',
                              'table',
                              'link',
                              'image',
                              'video',
                              'fullScreen',
                              'showBlocks',
                              'codeView',
                              'preview',
                              'print',
                              'save',
                              'template',
                            ],
                          ],
                          height: 200,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        className={classes.input}
                        id='contained-button-file-wafile'
                        multiple
                        type='file'
                        onChange={handleChange2}
                      />
                      <label htmlFor='contained-button-file-wafile'>
                        <Button
                          variant='contained'
                          color='primary'
                          component='span'
                        >
                          <span>
                            <AttachFileIcon fontSize='small' color='inherit' />{' '}
                            Attachment
                          </span>
                        </Button>
                      </label>
                      {attachment.map((doc, index) => (
                        <div className='d-flex justify-content-between'>
                          <p>
                            {doc !== '' ? (
                              <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={doc.url}
                              >
                                <GoFileSymlinkFile size={22} /> {doc.name}
                              </a>
                            ) : (
                              <p>No file choosen</p>
                            )}{' '}
                          </p>
                          <IconButton
                            onClick={() => handleDeleteAttachment(index)}
                          >
                            <DeleteIcon fontSize='small' />
                          </IconButton>
                        </div>
                      ))}
                      {isUploading ? <p>Uploading please wait...</p> : ''}
                    </Grid>
                  </Grid>
                </form>
                <br />
                <div className='text-center mt-4'>
                  {title.trim() &&
                  category.trim() &&
                  content.trim() &&
                  !helperText.length ? (
                    <Button
                      variant='contained'
                      color='primary'
                      className='flairbtn'
                      onClick={handleUpdate}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button variant='contained' color='primary' disabled>
                      Update
                    </Button>
                  )}{' '}
                  <Link to={'/console/wiki'}>
                    <Button variant='contained' color='secondary'>
                      Cancel
                    </Button>
                  </Link>
                </div>
              </React.Fragment>
            </Paper>
          </main>
        </div>
      </Grid>
      <Grid item xs={12} sm={2}></Grid>
    </Grid>
  )
}
