import React, { useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Button,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import IOSSwitch from '../../../../generalcomponents/iosSwitch'
import { DataContext } from '../../../../../contexts/data'
import Validations from '../../../../generalcomponents/validation'

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
}))

const Presentation = (props) => {
  const classes = useStyles()
  const {
    timesheetCycle,
    startDay,
    approvalBy,
    handleChange,
    handleSave,
    handleCheck,
    attachMandatory,
    enableTask,
    makeMandatory,
    linkToProject,
    approvals,
    fillableSections,
    handleIndex,
    type,
  } = props

  const [state] = React.useContext(DataContext)
  let projectList = state.projectList
  let names = state.names
  let supervisors = []
  approvals.forEach((employee) => {
    names.forEach((name) => {
      if (name.uid === employee.email) {
        supervisors.push(name)
      }
    })
  })
  console.log(startDay)
  const [reqProject, setReqProject] = useState({})
  const [reqEmployee, setReqEmployee] = useState({})
  useEffect(() => {
    setReqProject(
      projectList.filter((project) => project.id === linkToProject)[0]
    )
    setReqEmployee(
      supervisors.filter((employee) => employee.uid === approvalBy)[0]
    )
    console.log(linkToProject, approvalBy)
  }, [linkToProject, approvalBy])
  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              select
              label='Timesheet Cycle'
              size='small'
              value={timesheetCycle}
              fullWidth
              required
              onChange={(event) =>
                handleChange('timesheetCycle', event.target.value)
              }
            >
              {tsCycle.map((option) => (
                <MenuItem
                  key={option}
                  value={tsCycle.indexOf(option).toString()}
                >
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              label='Week start day'
              size='small'
              value={startDay}
              fullWidth
              required
              onChange={(event) => handleChange('startDay', event.target.value)}
            >
              {weekStartDays.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch />}
                checked={attachMandatory}
                onChange={handleCheck}
                name='attachMandatory'
                label='Timesheet Attachment'
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={supervisors}
              onChange={(_, value) => {
                if (value) {
                  handleChange('approvalBy', value.uid)
                }
              }}
              value={reqEmployee}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <React.Fragment>{option.name}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Approval by *'
                  size='small'
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              fullWidth
              options={projectList}
              onChange={(_, value) => {
                if (value) {
                  handleChange('linkToProject', value.id)
                }
              }}
              value={reqProject}
              autoHighlight
              getOptionLabel={(option) => option.title}
              renderOption={(option) => (
                <React.Fragment>{option.title}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Project *'
                  size='small'
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch />}
                checked={enableTask}
                name='enableTask'
                onChange={handleCheck}
                label='Enable Task'
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch />}
                checked={makeMandatory}
                onChange={handleCheck}
                name='makeMandatory'
                label='Make Manditory'
              />
            </FormGroup>
          </Grid>
        </Grid>
        <br />
        {fillableSections.includes('timesheets') ? (
          timesheetCycle && startDay && approvalBy && linkToProject ? (
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={(e) => handleSave('create')}
            >
              Done
            </Button>
          ) : (
            <Button
              type='button'
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled
            >
              Done
            </Button>
          )
        ) : timesheetCycle && startDay && approvalBy && linkToProject ? (
          <Button
            type='submit'
            variant='contained'
            color='secondary'
            className={classes.submit}
            onClick={(e) => handleSave('update')}
          >
            Update
          </Button>
        ) : (
          <Button
            type='button'
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled
          >
            Update
          </Button>
        )}
      </form>
    </div>
  )
}

export default Presentation

const tsCycle = ['Daily', 'Weekly', 'Biweekly', 'Semi-Monthly', 'Monthly']

const weekStartDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
