import React, { Component } from 'react'
import { FORM_DATA } from '../../../../contexts/types'
import { DataContext } from '../../../../contexts/data'
import firebase from '../../../../firebase_config'
import Presentation from './Presentation'
export class Container extends Component {
    static contextType = DataContext
    componentDidMount = () => {
        const dispatch= this.context[1]
        firebase.firestore().collection("InvitationFormBuilder")
        .orderBy("index","asc")
        .get()
        .then(snap => {
            dispatch({
                type : FORM_DATA,
                payload : snap.docs.map(doc => doc.data())
            })
            console.log(snap.docs.map(doc => doc.data()))
        })
    }
    render() {
        const [state] = this.context
        return (
            <div>
                {
                    state.hasOwnProperty('invitation_template')&& state.invitation_template.length ? 
                        <Presentation />
                        :
                        <p className="text-center" >Loading template</p>
                }
            </div>
        )
    }
}

export default Container
