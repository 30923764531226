import React, { useContext } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { Visibility } from '@material-ui/icons'
import { Tooltip, ButtonGroup, Button, IconButton } from '@material-ui/core'
import Validations from '../../../../generalcomponents/validation'
import { ReactPreviewPDF } from '../../PreviewPDF/Presentation'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { Link } from 'react-router-dom'
import VoidInvoice from '../VoidInvoice'
import MailInvitation from '../MailInvitation'
import { DataContext } from '../../../../../contexts/data'
import Checkbox from '@material-ui/core/Checkbox'
import CurrencyFormat from 'react-currency-format'
import TextField from '@material-ui/core/TextField'
import PaymentsDiscount from '../../PaymentsDiscount'
import MailIcon from '@material-ui/icons/Mail'
import CreateIcon from '@material-ui/icons/Create'
import PaymentOpenBalance from '../../PaymentOpenBalance'
import PaymentLatestDate from '../../PaymentLatestDate'
import Paper from '@material-ui/core/Paper'
import {
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
  DataTypeProvider,
} from '@devexpress/dx-react-grid'
import {
  DragDropProvider,
  Grid,
  GroupingPanel,
  PagingPanel,
  Table,
  TableFilterRow,
  TableGroupRow,
  TableHeaderRow,
  Toolbar,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { FaFilter } from 'react-icons/fa'


export default function Presentation(props) {
  const {
    invoicesList,
    isPayment,
    grandTotal,
    discount,
    handleCheckBox,
    handleChange,
    paymentHandleChange,
    paymentFilterList,
    openBalance,
    paymentAfterDiscount,
    invoicedDiscount,
  } = props
  const [state] = useContext(DataContext)
  const [] = React.useState([])
  const validate = new Validations()
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(!open)
  }

  const emailToName = (companyID) => {
    try {
      return state.names.filter((item) => item.companyID === companyID)[0].name
    } catch (error) {
      return companyID
    }
  }
  let paymentFilterList1 = []
  const paymentList = state.payments_list
  if (isPayment) {
    paymentFilterList1 = paymentFilterList
  } else {
    paymentFilterList1 = paymentList
  }
  let data = []
  invoicesList &&
    invoicesList.forEach((invoice, index) => {
      data.push({
        POnumber: invoice.POnumber,
        index: index,
        email: state.names.filter(
          (item) => item.companyID === invoice.empCode
        )[0].uid,
        name: validate.emailToName(
          state.names.filter((item) => item.companyID === invoice.empCode)[0]
            .uid
        ),
        imageURL: validate.getImage(invoice.empCode),
        discountDetails: invoice.discountDetails,
        invoicedAmount: invoice.isApproved
          ? invoice.approvedDetails.invoicedAmount
          : 0,
        receivedAmount: parseFloat(invoice.subTotal).toFixed(2),
        receivedDate: '--',
        transactionId: invoice.isApproved
          ? invoice.approvedDetails.transactionId
          : '',
        transactionURL: invoice.isApproved
          ? invoice.approvedDetails.transactionURL
          : '',
        billingAddress: invoice.billingAddress,
        clientId: invoice.clientId,
        openBalance1: (
          parseFloat(invoice.subTotal) -
          parseFloat(openBalance(invoice.invoiceNumber, paymentFilterList1))
        ).toFixed(2),
        discountDetails: invoice.discountDetails,
        empCode: invoice.empCode,
        endDate: invoice.endDate,
        paymentBalance: invoice.paymentBalance,
        invoiceDate: validate.dateFormatter(invoice.invoiceDate),
        invoiceDueDate: validate.dateFormatter(invoice.invoiceDueDate),
        invoiceNumber: invoice.invoiceNumber,
        invoiceRecipeints: invoice.invoiceRecipeints,
        isApproved: invoice.isApproved,
        isExist: invoice.isExist,
        isVoid: invoice.isVoid,
        notesDetails: invoice.notesDetails,
        notesTotal: invoice.notesTotal,
        placementID: invoice.placementID,
        shippedDate: validate.dateFormatter(invoice.shippedDate),
        startDate: invoice.startDate,
        timesheets: invoice.timesheets,
        id: invoice.id,
        invoiceBy: invoice.invoiceBy,
        netTerms: invoice.netTerms,
        isChecked: invoice.isChecked,
        grandTotal: grandTotal[index],
        discount: discount[index],
        paymentDiscount: invoice.paymentDiscount,
        outbox: invoice.outbox,
        invoicePDF: invoice.hasOwnProperty('invoiceDetails')
          ? invoice.invoiceDetails.invoicePDF
          : 'No Doc',
        subTotal: paymentAfterDiscount(
          invoice.subTotal,
          invoice.discountDetails
        ),
        paymentDiscountAmount: invoice.paymentDiscountAmount,
        paymentAfterDiscount: invoice.subTotal,
        invoicedDiscount: parseFloat(
          invoicedDiscount(invoice.discountDetails, invoice.subTotal)
        ).toFixed(2),
      })
    })
  console.log(data)
  const columns = [
    {
      title: (
        <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
      ),
      name: 'checkBox',
    },
    {
      title: (
        <span>
          Invoice no. &nbsp;
          <FaFilter size={15} onClick={handleClickOpen} title='Filter' />
        </span>
      ),
      name: 'invoiceNumber',
    },
    {
      title: (
        <span>
          PC &nbsp;
          <FaFilter size={15} onClick={handleClickOpen} title='Filter' />
        </span>
      ),
      name: 'placementID',
    },
    {
      title: (
        <span>
          Employee &nbsp;
          <FaFilter size={15} onClick={handleClickOpen} title='Filter' />
        </span>
      ),
      name: 'empCode',
      getCellValue: (row) => emailToName(row.empCode),
    },
    {
      title: (
        <span>
          InvoicedDate &nbsp;
          <FaFilter size={15} onClick={handleClickOpen} title='Filter' />
        </span>
      ),
      name: 'invoiceDate',
    },
    {
      title: (
        <span>
          Due Date &nbsp;
          <FaFilter size={15} onClick={handleClickOpen} title='Filter' />
        </span>
      ),
      name: 'invoiceDueDate',
    },

    {
      title: (
        <span>
          InvoicedAmount &nbsp;
          <FaFilter size={15} onClick={handleClickOpen} title='Filter' />
        </span>
      ),
      name: 'invoicedAmount',
    },
    {
      title: (
        <span>
          ReceivedAmount
          <FaFilter size={15} onClick={handleClickOpen} title='Filter' />
        </span>
      ),
      name: 'receivedAmount',
    },
    {
      title: (
        <span>
          Latest payment Date &nbsp;
          <FaFilter size={15} onClick={handleClickOpen} title='Filter' />
        </span>
      ),
      name: 'receivedDate',
    },
    {
      title: (
        <span>
          OpenBalance &nbsp;
          <FaFilter size={15} onClick={handleClickOpen} title='Filter' />
        </span>
      ),
      name: 'openBalance',
    },
    {
      title: (
        <span>
          Payment &nbsp;
          <FaFilter size={15} onClick={handleClickOpen} title='Filter' />
        </span>
      ),
      name: 'payment',
    },
    {
      title: (
        <span>
          Actions &nbsp;
          <FaFilter size={15} onClick={handleClickOpen} title='Filter' />
        </span>
      ),
      name: 'actions',
    },
  ]

  const [CheckBox] = React.useState(['checkBox'])
  const [InvoiceNumber] = React.useState(['invoiceNumber'])
  const [EmployeeCode] = React.useState(['empCode'])
  const [TotalAmount] = React.useState(['totalAmount'])
  const [DiscountAmount] = React.useState(['discountAmount'])
  const [InvoicedAmount] = React.useState(['invoicedAmount'])
  const [ReceivedAmount] = React.useState(['receivedAmount'])
  const [ReceivedDate] = React.useState(['receivedDate'])
  const [OpenBalance] = React.useState(['openBalance'])
  const [Payment] = React.useState(['payment'])
  const [Actions] = React.useState(['actions'])

  const [defaultHiddenColumnNames] = React.useState([''])

  const CheckBoxProvider = (props) => (
    <DataTypeProvider formatterComponent={CheckBoxFormatter} {...props} />
  )

  const CheckBoxFormatter = (props) => {
    let rowData = props.row
    console.log(rowData)
    return (
      <Checkbox
        onChange={(e) => {
          {
            handleCheckBox(e, rowData)
          }
        }}
        checked={rowData.isChecked}
        inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
      />
    )
  }

  const InvoiceNumberProvider = (props) => (
    <DataTypeProvider formatterComponent={InvoiceNumberFormatter} {...props} />
  )

  const InvoiceNumberFormatter = (props) => {
    let rowData = props.row

    return (
      <div>
        {!rowData.outbox.to.length ? (
          <span>{rowData.invoiceNumber}</span>
        ) : (
          <span>
            <MailIcon fontSize='small' color='inherit' />{' '}
            {rowData.invoiceNumber}
          </span>
        )}
      </div>
    )
  }
  const EmployeeCodeProvider = (props) => (
    <DataTypeProvider formatterComponent={EmployeeCodeFormatter} {...props} />
  )

  const EmployeeCodeFormatter = (props) => {
    let rowData = props.row

    return (
      <div className='d-flex'>
        <Avatar src={rowData.imageURL} />
        <div className='ml-2 d-flex flex-column'>
          <span>
            <Link to={'/console/employeelist/' + rowData.empCode}>
              {emailToName(rowData.empCode)}
            </Link>
          </span>
          <span>{rowData.empCode}</span>
        </div>
      </div>
    )
  }
  const TotalAmountProvider = (props) => (
    <DataTypeProvider formatterComponent={TotalAmountFormatter} {...props} />
  )

  const TotalAmountFormatter = (props) => {
    let rowData = props.row

    return (
      <div>
        <CurrencyFormat
          value={rowData.paymentAfterDiscount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          renderText={(value) => value}
        />
      </div>
    )
  }
  const DiscountAmountProvider = (props) => (
    <DataTypeProvider formatterComponent={DiscountAmountFormatter} {...props} />
  )

  const DiscountAmountFormatter = (props) => {
    let rowData = props.row

    return (
      <div>
        <CurrencyFormat
          value={rowData.invoicedDiscount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          renderText={(value) => value}
        />
      </div>
    )
  }
  const InvoicedAmountProvider = (props) => (
    <DataTypeProvider formatterComponent={InvoicedAmountFormatter} {...props} />
  )

  const InvoicedAmountFormatter = (props) => {
    let rowData = props.row

    return (
      <div>
        <CurrencyFormat
          value={rowData.subTotal}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          renderText={(value) => value}
        />
      </div>
    )
  }
  const ReceivedAmountProvider = (props) => (
    <DataTypeProvider formatterComponent={ReceivedAmountFormatter} {...props} />
  )

  const ReceivedAmountFormatter = (props) => {
    let rowData = props.row

    return (
      <div>
        <PaymentOpenBalance
          isOpenBalance={false}
          isReceivedAmount={true}
          invoiceNumber={rowData.invoiceNumber}
          paymentFilterList={paymentFilterList1}
          subTotal={rowData.subTotal}
        />
      </div>
    )
  }
  const ReceivedDateProvider = (props) => (
    <DataTypeProvider formatterComponent={ReceivedDateFormatter} {...props} />
  )

  const ReceivedDateFormatter = (props) => {
    let rowData = props.row

    return (
      <div>
        <PaymentLatestDate
          invoiceNumber={rowData.invoiceNumber}
          paymentFilterList={paymentFilterList1}
        />
      </div>
    )
  }
  const OpenBalanceProvider = (props) => (
    <DataTypeProvider formatterComponent={OpenBalanceFormatter} {...props} />
  )

  const OpenBalanceFormatter = (props) => {
    let rowData = props.row

    return (
      <div>
        <PaymentOpenBalance
          isOpenBalance={true}
          isReceivedAmount={false}
          invoiceNumber={rowData.invoiceNumber}
          paymentFilterList={paymentFilterList1}
          subTotal={rowData.subTotal}
        />
      </div>
    )
  }
  const PaymentProvider = (props) => (
    <DataTypeProvider formatterComponent={PaymentFormatter} {...props} />
  )

  const PaymentFormatter = (props) => {
    let rowData = props.row

    return (
      <div>
        <TextField
          label='Payment'
          size='small'
          fullWidth
          variant='outlined'
          value={rowData.isChecked ? rowData.paymentBalance : ''}
          helperText={
            rowData.openBalance1 <
            parseFloat(rowData.paymentBalance) +
              parseFloat(rowData.paymentDiscountAmount)
              ? 'Enter valid Amount'
              : ''
          }
          onChange={(event) => {
            let row = JSON.stringify(rowData)
            row = JSON.parse(row)
            row['paymentBalance'] = event.target.value
            paymentHandleChange('invoicesList', row)
            paymentHandleChange('paymentAmount', parseInt(event.target.value))
            let list = JSON.stringify(invoicesList)
            list = JSON.parse(list)
            list[rowData.index]['paymentBalance'] = event.target.value
            handleChange('invoicesList', list)
            paymentHandleChange('tableData', list)
            handleChange('amount', event.target.value)
          }}
        />
      </div>
    )
  }
  const ActionsProvider = (props) => (
    <DataTypeProvider formatterComponent={ActionsFormatter} {...props} />
  )

  const ActionsFormatter = (props) => {
    let rowData = props.row

    return (
      <div className='d-flex'>
        {isPayment && rowData.isChecked ? (
          <PaymentsDiscount
            {...rowData}
            data={data}
            rowData={rowData}
            invoicesList={invoicesList}
            handleChange={handleChange}
            paymentHandleChange={paymentHandleChange}
          />
        ) : (
          ''
        )}
        <ReactPreviewPDF
          gsPath={rowData.invoicePDF}
          btnContent={
            <span>
              <Visibility fontSize='default' />
            </span>
          }
          iconBtn={true}
        />

        {
          <a href={rowData.invoicePDF} target='_blank'>
            Doc
          </a>
        }
        {!rowData.outbox.to.length ? (
          <Link to={'/console/invoiceslist/' + rowData.id}>
            <IconButton color='inherit'>
              <CreateIcon />
            </IconButton>
          </Link>
        ) : (
          <VoidInvoice {...rowData} iconBtn={true} />
        )}
        <MailInvitation {...rowData} />
        {/* <div className='mt-1'>
        <IconButton color='inherit'>
          <AddIcon />
        </IconButton>
      </div> */}
      </div>
    )
  }
  let updatedColumns = columns.filter((column) => column.title !== 'Payment')

  return (
    <Paper>
      {!isPayment ? (
        <div
          style={{
            marginTop: 'auto',
            marginLeft: 'auto',
            marginBottom: 'auto',
          }}
        >
          <>
            <Tooltip title='New Invoice'>
              <ButtonGroup
                variant='contained'
                color='primary'
                aria-label='outlined primary button group'
              >
                <Link to='/console/invoiceslist/newinvoice'>
                  <Button color='primary'>
                    <AddCircleOutlineIcon />
                  </Button>
                </Link>
              </ButtonGroup>
            </Tooltip>
          </>
        </div>
      ) : null}
      <Grid rows={data} columns={isPayment ? columns : updatedColumns}>
        {open ? <FilteringState /> : ''}
        <SortingState />
        <SelectionState />
        <GroupingState />
        <PagingState />

        <IntegratedGrouping />
        {open ? <IntegratedFiltering /> : ''}
        <IntegratedSorting />
        <IntegratedPaging />
        <IntegratedSelection />
        <Table />
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
        <CheckBoxProvider for={CheckBox} />
        <InvoiceNumberProvider for={InvoiceNumber} />
        <EmployeeCodeProvider for={EmployeeCode} />
        <TotalAmountProvider for={TotalAmount} />
        <DiscountAmountProvider for={DiscountAmount} />
        <InvoicedAmountProvider for={InvoicedAmount} />
        <ReceivedAmountProvider for={ReceivedAmount} />
        <ReceivedDateProvider for={ReceivedDate} />
        <OpenBalanceProvider for={OpenBalance} />
        <PaymentProvider for={Payment} />
        <ActionsProvider for={Actions} />
        <DragDropProvider />

        <Table />

        <TableHeaderRow showSortingControls={true} />
        {open ? <TableFilterRow showFilterSelector={true} /> : ''}
        <PagingPanel pageSizes={10} />

        <TableGroupRow />
        <Toolbar />
        <GroupingPanel showSortingControls={true} />
      </Grid>
    </Paper>
  )
}
