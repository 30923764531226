import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { DataContext } from '../../../../contexts/data'
import Validations from '../../../generalcomponents/validation'




const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightRegular,
  },
  layout: {
    width: 'auto',
  },
}));

export default function SimpleExpansionPanel(props) {
  const classes = useStyles();
  const [state] = useContext(DataContext)
  const { projectId, handleChange, handleDateChange, handleUpdate, errCount, oldContent, labels, timeline, handleCheck } = props
  let project = {}
  project = state.projectList.filter(project => project.id === projectId)[0]
  const handleStartDateChange = date => {
    handleDateChange("startdate", date)
  };

  const handleEndDateChange = date => {
    handleDateChange("enddate", date)
  };
  let projectExist = false
  if(state.projectList.filter(item => item.id !== projectId).map(item => item.title.toLowerCase()).includes(oldContent.title.toLowerCase())){
    projectExist = true
    // console.log(oldContent, state.projectList.filter(item => item.id !== projectId))
  }
  const validate = new Validations()

  return (
    <div className={classes.root}>
            
              <Typography component="h4" variant="h4" align="center">
                {/* Update */}
              </Typography>
              <React.Fragment>
                <br />
                <form onSubmit={handleUpdate} >
                  <Grid container spacing={3}>
                    <Grid item xs={12} >
                      <TextField
                        required="required"
                        id="projectname"
                        name="title"
                        value={oldContent.title}
                        label="Name"
                        onChange={handleChange}
                        fullWidth
                      />
                      {
                        projectExist ? <p className="text-danger" >This title already exists! choose another title</p> : null
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="grouped-select">Status</InputLabel>
                        <Select value={oldContent.status} name="status" onChange={handleChange} input={<Input id="grouped-select" />}>
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="Open">Open</MenuItem>
                          <MenuItem value="Closed">Closed</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={<Checkbox checked={oldContent.useTimeline} onChange={(event) => handleCheck(event)} name="useTimeline" />}
                        label="Timeline"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={<Checkbox checked={oldContent.useLabels} onChange={(event) => handleCheck(event)} name="useLabels" />}
                        label="Labels"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container spacing={3}>
                          <Grid item  >
                            <KeyboardDatePicker
                              required="required"
                              margin="normal"
                              id="date-picker-startdate"
                              label="Start date"
                              format="MM/dd/yyyy"
                              minDate={oldContent.startdate}
                              value={oldContent.startdate ? oldContent.startdate : null}
                              onChange={handleStartDateChange}
                              name="startdate"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </Grid>
                          <Grid item  >
                            <KeyboardDatePicker
                              defaultValue={project.enddate}
                              margin="normal"
                              id="date-picker-enddate"
                              label="End date"
                              format="MM/dd/yyyy"
                              minDate={oldContent.startdate}
                              value={oldContent.enddate ? oldContent.enddate : null}
                              onChange={handleEndDateChange}
                              name="enddate"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <br />
                    {
                      errCount === 0 && !projectExist && validate.checkDateDiff(oldContent.startdate, oldContent.enddate) ?
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          // onClick={handleClose}
                          className={classes.submit}
                        >
                          Update
                      </Button>
                        :
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled
                        >
                          Update
                      </Button>
                    }

                  </Grid>
                </form>
              </React.Fragment>
            
    </div>
  );
}
