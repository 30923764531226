import React, { useContext, useState, useEffect } from "react";
import SelectForm from "../SelectForm";
import BillingForm from "../BillingForm";
import ServiceSummary from "../ServiceSummary";
import Notes from "../Notes";
import Discount from "../Discount";
import { Button, Divider, Paper } from "@material-ui/core";
import CreateInvoiceImage from "../../../../../assets/create_invoice.png";
function  Presentation(props) {
  const {
    empCode,
    clientId,
    external_name,
    external_address,
    handleChange,
    timesheetsStartDate,
    timesheetsEndDate,
    placementID,
    timesheets,
    subTotal,
    discountDetails,
    invoiceDate,
    invoiceDueDate,
    netTerms,
    POnumber,
    notesDetails,
    invoiceSettings,
    invoiceBy,
    externalAmountName,
    invoiceId,
    selectedExpenses
  } = props;
  return (
    <div>
      <SelectForm 
        handleChange={handleChange} 
        subTotal={subTotal}
        discountDetails={discountDetails}
        empCode={empCode}
        clientId={clientId}
        placementID={placementID}
        invoiceBy={invoiceBy}
        invoiceId={invoiceId}
      />
      <BillingForm
        empCode={empCode}
        clientId={clientId}
        invoiceDate={invoiceDate}
        invoiceDueDate={invoiceDueDate}
        netTerms={netTerms}
        handleChange={handleChange}
        POnumber={POnumber}
        external_address={external_address}
        external_name={external_name}
        placementID={placementID}
        invoiceSettings={invoiceSettings}
        invoiceBy={invoiceBy}
      />
      <ServiceSummary
        startDate={timesheetsStartDate}
        endDate={timesheetsEndDate}
        empCode={empCode}
        clientId={clientId}
        placementID={placementID}
        handleChange={handleChange}
        timesheets={timesheets}
        selectedExpenses={selectedExpenses}
      />
      <Paper className="p-2 mt-1">
        <div className="row">
          <div className="col-sm text-center">
            <img
              src={CreateInvoiceImage}
              width="120"
              className="img-fluid  .d-sm-none .d-md-block"
            />
          </div>
          <div className="col-sm-7">
            <Discount
              placementID={placementID}
              timesheets={timesheets}
              handleChange={handleChange}
              subTotal={subTotal}
              discountDetails={discountDetails}
              invoiceBy={invoiceBy}
              externalAmountName={externalAmountName}
              selectedExpenses={selectedExpenses}
            />
          </div>
        </div>
        <Divider />
        <Notes 
          handleChange={handleChange}
          notesDetails={notesDetails}
          invoiceSettings={invoiceSettings}
        />
      </Paper>
    </div>
  );
}

export default Presentation;
