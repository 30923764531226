import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import ProjectsTable from '../ProjectsTable'
import { DataContext } from '../../../contexts/data'
import NewProject from '../NewProject'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'space-between',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  btn: {
    backgroundColor: '#17a2b8',
    color: 'white',
    margin: '4px',
  },
  flex: {
    display: 'flex',
  },
  projectsarea: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  }
}

function Presentation() {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  let projectList = []
  const [state] = useContext(DataContext)
  if (state.hasOwnProperty('projectList')) projectList = state.projectList
  else projectList = []

  let overdueList = projectList.filter((project) => {
    let currDate = new Date()
    let projectStartdate = new Date(project.startdate)
    if (project.enddate === '') return false
    if (project.status === 'Closed') return false
    let projectEndDate = new Date(project.enddate)
    let projectStartDiff =
      (projectStartdate.getTime() - currDate.getTime()) / (1000 * 3600 * 24)
    let projectEndDiff =
      (projectEndDate.getTime() - currDate.getTime()) / (1000 * 3600 * 24)
    return projectStartDiff < 0 && projectEndDiff < 0
  })

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.projectsarea}>
            <AppBar position='static' color='default'>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor='primary'
                textColor='primary'
                variant='scrollable'
                scrollButtons='auto'
                aria-label='scrollable auto tabs example'
              >
                <Tab
                  max='10000'
                  label={
                    <Badge
                      color='primary'
                      children='All'
                      badgeContent={projectList.length}
                    />
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  max='10000'
                  label={
                    <Badge
                      color='primary'
                      children='Inprogress'
                      badgeContent={
                        projectList.filter(
                          (project) => project.status === 'Open'
                        ).length
                      }
                    />
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  max='10000'
                  label={
                    <Badge
                      color='error'
                      children='Overdue'
                      badgeContent={overdueList.length}
                    />
                  }
                  {...a11yProps(2)}
                />
                <Tab
                  max='10000'
                  label={
                    <Badge
                      color='primary'
                      children='Closed'
                      badgeContent={
                        projectList.filter(
                          (project) => project.status === 'Closed'
                        ).length
                      }
                    />
                  }
                  {...a11yProps(3)}
                />
                <div
                  style={{
                    marginTop: '3px',
                    marginLeft: 'auto',
                  }}
                >
                  <div className={classes.flex}>
                    {/* <PrintPDF handleClick={handleClick}/> */}
                    {state.access_modules.includes('task-management-manager') ||
                    state.access_modules.includes('console-customization') ? (
                      <NewProject />
                    ) : null}
                  </div>
                </div>
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <ProjectsTable projectList={projectList} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ProjectsTable
                projectList={projectList.filter(
                  (project) => project.status === 'Open'
                )}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ProjectsTable projectList={overdueList} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ProjectsTable
                projectList={projectList.filter(
                  (project) => project.status === 'Closed'
                )}
              />
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Presentation
