import React from 'react'
import { DataContext } from '../../../../../../contexts/data'
import { Avatar, Tooltip } from '@material-ui/core'
import Validations from '../../../../../generalcomponents/validation'
import InvoiceLocation from "../InvoiceLocation/Index"

function Presentation(props) {
    const { client } = props
    const [state] = React.useContext(DataContext)
    const validate = new Validations()
    const activeConsultants = state.placements_list.filter(item => item.clientId === client.clientId).map(item => item.empCode)
    const detailsExpanded = activeConsultants.map(empCode => state.names.filter(name => name.companyID === empCode)[0])
    const CardMaker = ({name, value}) => {
        return (
            <div className={"custom-card p-5 text-center col-sm-2 border"} style={cardStyles} >
                <h5>{name}</h5>
                <h2>{value}</h2>
            </div>
        )
    }
    return (
        <div>
            <div className="row" >
                <CardMaker 
                    name="Net terms"
                    value={client.netTerms}
                />
                <CardMaker 
                    name="Job termination notice"
                    value={client.jobTerminationNotice}
                />
                <CardMaker 
                    name="Client type"
                    value={client.category}
                />
                <div className="col-sm-6 custom-card border" >
                    <h2>Active consultants ({detailsExpanded.length}) </h2>
                    <div className="row d-flex" >
                        {
                            detailsExpanded.map(item => (
                                <div className="col" >
                                    <Tooltip title={validate.emailToName(item.uid)} >
                                        <Avatar src={item.photoURL} />
                                    </Tooltip>
                                </div>
                            ))
                        }
                    </div>
                    
                </div>
                <div className="col-sm-6 custom-card border" >
                    <InvoiceLocation 
                        invoiceLocation = {client.invoiceLocation}
                        id={client.id}
                    />
                </div>
            </div>
        </div>
    )
}

export default Presentation


const cardStyles = {
    width: '200px'
}
