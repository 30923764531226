import React, { Component } from 'react'
import Presentation from './Presentation'
import Axios from 'axios'
import { CircularProgress } from '@material-ui/core'
import { DataContext } from '../../../../contexts/data';
import { Animations } from '../../../generalcomponents/skeletonLoader'

export class Container extends Component {
    state={
        ProjectTimeLine : []
    }

    static contextType = DataContext
    componentDidMount = async() => {
       const [state] = this.context
       let result = await Axios.get(`/getProjectTimeLine/${state.project.id}`)
       if(result.data.status)
        this.setState({
            ProjectTimeLine : result.data.response
        })
    }

    render() {
        if(this.state.ProjectTimeLine.length)
            return (
                <Presentation
                    {...this.state}
                />
            )
        return <div>
            <Animations /><br/>
            <Animations /><br/>
            <Animations />
        </div>    
    }
}

export default Container
