import React, { PureComponent } from 'react'
import Presentation from './Presentation'
import firebase from '../../../../../firebase_config'
import { DataContext } from '../../../../../contexts/data'
import {
  PLACEMENT_SETTINGS_ACTIONS,
} from '../../../../../contexts/types'
import Validations from '../../../../generalcomponents/validation'

class Container extends PureComponent {
  constructor(props) {
    super(props)
    this.validate = new Validations()
  }

  state = {
    frequency: '',
    OT: true,
    POnumber: false,
    pointOfContactMailId: '',
    attachFlairExpense: false,
    attachFlairTimesheets: false,
    bcc: [],
    billingAddress: '',
    calculationType: '',
    cc: [],
    frequencyStartDate: '',
    pointOfContactName: '',
    pointOfContactPhoneNo: '',
    to: [],
    profile: {},
    value: 0,
    index: '',
    id: '',
    fillableSections: [],
    helperText: {
      to: '',
      cc: '',
      bcc: '',
    },
  }

  static contextType = DataContext

  handleTab = (e, v) => {
    this.setState({ value: v })
  }

  getData = (id, startDate) => {
    firebase
      .firestore()
      .collection('Placements')
      .doc(id)
      .collection('Invoices')
      .get()
      .then((snap) => {
        let placementData = []
        placementData = snap.docs.map((doc) => doc.data())
        placementData.length &&
          this.setState({
            frequency: (placementData[0].frequency - 1).toString(),
            OT: placementData[0].OT,
            POnumber: placementData[0].POnumber,
            pointOfContactMailId: placementData[0].pointOfContactMailId,
            attachFlairExpense: placementData[0].attachFlairExpense,
            attachFlairTimesheets: placementData[0].attachFlairTimesheets,
            bcc: placementData[0].bcc,
            billingAddress: placementData[0].billingAddress,
            calculationType: placementData[0].calculationType,
            cc: placementData[0].cc,
            frequencyStartDate: startDate,
            pointOfContactName: placementData[0].pointOfContactName,
            pointOfContactPhoneNo: placementData[0].pointOfContactPhoneNo,
            to: placementData[0].to,
          })
      })
  }

  componentDidMount = () => {
    const { req_placement } = this.props
    if (req_placement.id) this.getData(req_placement.id, req_placement.startDate)
  }

  componentWillReceiveProps = (nextProps) => {
    const { req_placement, profile, index } = nextProps
    const { id, startDate, fillableSections } = req_placement
    this.setState({
      profile,
      frequencyStartDate: startDate,
      index: index - 1,
      id,
      fillableSections,
    })
    if (id.length && this.props.req_placement.id !== id) this.getData(id, startDate)
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleAdd = (name, chip) => {
    let data = this.state[name]
    if (this.validate.checkEmail(chip)) {
      data = [...data, chip]
      this.setState({
        helperText: {
          to: '',
          cc: '',
          bcc: '',
        },
      })
    } else {
      this.setState((prevState) => ({
        helperText: {
          ...prevState.helperText,
          [name]: 'Enter valid email',
        },
      }))
    }
    this.setState({ [name]: data })
    console.log('add', data)
  }

  handleDelete = (name, index) => {
    let data = this.state[name]
    data.splice(index, 1)
    this.setState({ [name]: data })
    console.log('delete', data)
  }

  handleCheck = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    })
  }

  handleDateChange = (key, value) => {
    if (!isNaN(Date.parse(value)))
      this.setState({
        [key]: value.toISOString(),
      })
  }

  placementSettingsActionsWiki = (data) => {
    console.log(data)
  }

  handleSave = (type) => {
    if (type === 'create') this.handleCreate()
    else if (type === 'update') this.handleUpdate()
    else console.log('Error')
  }

  handleCreate = () => {
    const dispatch = this.context[1]
    const {
      frequency,
      OT,
      POnumber,
      pointOfContactMailId,
      attachFlairExpense,
      attachFlairTimesheets,
      bcc,
      billingAddress,
      calculationType,
      cc,
      frequencyStartDate,
      pointOfContactName,
      pointOfContactPhoneNo,
      to,
      profile,
    } = this.state
    let payload = {
      frequency: parseInt(frequency) + 1,
      OT: OT,
      POnumber: POnumber,
      pointOfContactMailId: pointOfContactMailId,
      attachFlairExpense: attachFlairExpense,
      attachFlairTimesheets: attachFlairTimesheets,
      bcc: bcc,
      billingAddress: billingAddress,
      calculationType: calculationType,
      cc: cc,
      frequencyStartDate: frequencyStartDate,
      pointOfContactName: pointOfContactName,
      pointOfContactPhoneNo: pointOfContactPhoneNo,
      to: to,
      placementID: this.state.id,
      sectionName: 'invoices'
    }
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
      payload: payload,
      actionType: 'new'
    })
  }

  handleUpdate = () => {
    const dispatch = this.context[1]
    const {
      frequency,
      OT,
      POnumber,
      pointOfContactMailId,
      attachFlairExpense,
      attachFlairTimesheets,
      bcc,
      billingAddress,
      calculationType,
      cc,
      frequencyStartDate,
      pointOfContactName,
      pointOfContactPhoneNo,
      to,
      profile,
      id,
    } = this.state
    let payload = {
      frequency: parseInt(frequency) + 1,
      OT: OT,
      POnumber: POnumber,
      pointOfContactMailId: pointOfContactMailId,
      attachFlairExpense: attachFlairExpense,
      attachFlairTimesheets: attachFlairTimesheets,
      bcc: bcc,
      billingAddress: billingAddress,
      calculationType: calculationType,
      cc: cc,
      frequencyStartDate: frequencyStartDate,
      pointOfContactName: pointOfContactName,
      pointOfContactPhoneNo: pointOfContactPhoneNo,
      to: to,
      placementID: id,
      sectionName: 'invoices',
    }
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      payload: payload,
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
      actionType: 'update'
    })
  }

  hideAlert = () => {
    this.setState({
      alert: false,
    })
  }

  render() {
    //this.props.handleChange('hideOT', this.state.OT)
    return (
      <div>
        <Presentation
          {...this.state}
          handleChange={this.handleChange}
          handleCheck={this.handleCheck}
          handleSave={this.handleSave}
          handleDateChange={this.handleDateChange}
          handleTab={this.handleTab}
          handleDelete={this.handleDelete}
          handleAdd={this.handleAdd}
          handleOT={this.props.handleOT}
        />
      </div>
    )
  }
}

export default Container
