import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import { DEDUCTIONS_ACTIONS } from '../../../contexts/types'
import firebase from '../../../firebase_config'

class Container extends Component {
  state = {
    advanceType: '',
    givendate: '',
    chequeNumber: '',
    amount: '',
    $: '',
    deductPer: '',
    effectivefrom: '',
    notes: '',
    deductions: [],
    update: false,
    index: '',
  }

  static contextType = DataContext

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleDateChange = (key, value) => {
    if (!isNaN(Date.parse(value)))
      this.setState({
        [key]: value.toISOString(),
      })
  }

  handleData = (data) => {
    this.setState({
      notes: data,
    })
  }

  componentDidMount = () => {
    firebase.firestore().collection('Deductions').doc(this.props.id).collection('Docs').get().then(doc => {
      const deductions = doc.docs.map(snap => snap.data())
      this.setState({ deductions })
    })
  }

  handleCreate = () => {
    const dispatch = this.context[1]
    const {
      advanceType,
      givendate,
      chequeNumber,
      amount,
      $,
      deductPer,
      effectivefrom,
      notes,
      deductions,
    } = this.state
    const { id } = this.props
    const payload = {
      advanceType,
      givendate,
      chequeNumber: parseInt(chequeNumber),
      amount: parseInt(amount),
      amountTobeDeducted: parseInt($),
      deductPer,
      effectivefrom,
      notes,
      companyID: id,
    }
    console.log(payload)
    let data = deductions
    data = [...data, payload]
    this.setState({ deductions: data })
    dispatch({
      type: DEDUCTIONS_ACTIONS,
      payload: payload,
      deductionActionsCallback: this.deductionActionsCallback,
      actionType: 'new',
      deductionId: 'new',
    })
  }

  deductionActionsCallback = () => {
    this.clearValues()
  }

  onPressEdit = (rowData) => {
    const {
      advanceType,
      givendate,
      chequeNumber,
      amount,
      $,
      deductPer,
      effectivefrom,
      notes,
      index,
    } = rowData
    this.setState({
      advanceType,
      givendate,
      chequeNumber,
      amount,
      $,
      deductPer,
      effectivefrom,
      notes,
      index,
      update: true,
    })
  }

  handleUpdate = () => {
    const dispatch = this.context[1]
    const {
      advanceType,
      givendate,
      chequeNumber,
      amount,
      $,
      deductPer,
      effectivefrom,
      notes,
      deductions,
      index,
    } = this.state
    const payload = {
      advanceType,
      givendate,
      chequeNumber: parseInt(chequeNumber),
      amount: parseInt(amount),
      amountTobeDeducted: parseInt($),
      deductPer,
      effectivefrom,
      notes,
    }
    console.log(payload)
    let data = deductions
    data[index] = payload
    this.setState({ deductions: data })
    this.clearValues()
    // dispatch({
    //   type: '',
    //   payload: payload
    // })
  }

  handleSave = (type) => {
    if (type === 'new') this.handleCreate()
    else this.handleUpdate()
  }

  clearValues = () => {
    this.setState({
      advanceType: '',
      givendate: '',
      chequeNumber: '',
      amount: '',
      $: '',
      deductPer: '',
      effectivefrom: '',
      notes: '',
      update: false,
    })
  }

  render() {
    return (
      <div>
        <Presentation
          {...this.state}
          listAll={this.props.listAll}
          handleChange={this.handleChange}
          handleDateChange={this.handleDateChange}
          handleData={this.handleData}
          handleSave={this.handleSave}
          onPressEdit={this.onPressEdit}
        />
      </div>
    )
  }
}

export default Container
