import React from 'react'
import { CircularProgress } from '@material-ui/core'

function CircularSpinner(props) {
    return (
        <div style={{marginTop : '230px'}} className="text-center" >
            <CircularProgress />
        </div>
    )
}

export default CircularSpinner
