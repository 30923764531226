import React from 'react'
import { FormGroup ,Label,Input} from 'reactstrap';
import {Button} from 'semantic-ui-react'
import UserForm from './UserForm'
import Axios from 'axios'
import { configuration } from '../../../configuration'


class AuthOne extends React.Component {
    state = {
        isTokenExpired : true,
        isLoading : true
    }

    componentDidMount = async() => {
        const { token, email } = this.props
        console.log('fffff')
        let response = await Axios.get(`${configuration.REST_api}/checktokenexpiry/${token}/${email}`)
        let result = await response.data
        if(result.status === true)
            this.setState({ isTokenExpired : false, isLoading : false })
        else this.setState({ isTokenExpired : true,  isLoading : false })    
    }
    render(){
    const {AuthOneSuccess,levelOneAuth,handleSubmit,email,handleUser,htmlContent,incorrectPass,token}=this.props;
    
    
    return (
        <div>
            
            {
                !this.state.isTokenExpired ? 
                (
                 
                    levelOneAuth?(
                               <p className="text-center p-3 bg-success mt-5 w-75 rounded shadow ml-auto mr-auto text-white">You have registered already!</p>
                               
                           )
                           :
                           (
                               <div>
                                   {  
                                       AuthOneSuccess?(
                                           <UserForm  token={token} email={email} handleSubmit={handleSubmit} htmlContent={htmlContent}/>
                                       )
                                       :
                                       (
                                           <FormGroup className="widthsetter ml-auto mr-auto shadow rounded p-4 mt-5 bg-light">
                                               <Label for="password">Enter the password attached to your invitation mail:</Label>
                                               <Input type="password" name="password" id="levelOneAuthPass" handleUser={handleUser} placeholder="Password" />
                                               {incorrectPass?<p className="text-danger">Incorrect Password</p>:<p></p>}
                                               <Button color="primary" type="submit" onClick={handleUser}>Confirm</Button>
                                           </FormGroup>
                                       )
                                   }
                                   
                                    
                               </div>
                              
                           )
                   )
                   :
                   this.state.isLoading ? <p className='text-center h3 mt-3' >Loading ...</p> :
                   <p className="text-center p-3 bg-warning mt-5 w-75 rounded shadow ml-auto mr-auto text-white">Look's like this link has been expired!</p>
            }
             
            
        </div>
    )
}
}

export default AuthOne
