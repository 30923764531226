import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OrgChart from '@mui-treasury/components/chart/org';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Validations from '../../generalcomponents/validation'

const useStyles = makeStyles(() => ({
    avatar: {
        width: 30,
        height: 30,
    },
    name: {
        fontFamily: 'fira sans',
        fontWeight: 'bold',
        fontSize: 12,
        whiteSpace: 'nowrap',
    },
    position: {
        verticalAlign: 'super',
    },
}));
export default function Presentation(props) {
    const { personal, profile } = props
    const validate = new Validations()
    const styles = useStyles();
    let name = personal.firstname + "" + personal.middlename + "" + personal.lastname
    let position = personal.jobtitle
    let department = personal.department
    let src = profile.imageURL
    let imageURL = validate.getImage(personal.reportingmanager)
    let reportingManager = validate.emailToName(personal.reportingmanager)
    const getData = [
        {
            name: 'Ramu Venigandla',
            position: 'CEO',
            children: [
                {
                    name: 'Marketing / Sales',
                },
                {
                    name: 'Professional Services',
                    children: [
                        {
                            name: `${reportingManager}`,
                            position: '(Supervisor)',
                            src: `${imageURL}`,
                            children: [
                                {
                                    name: 'Java',
                                    children: [
                                        (department === 'Java') && {
                                            name: `${name}`,
                                            position: `${position}`,
                                            src: `${src}`
                                        }
                                    ],
                                },
                                {
                                    name: 'DevOps/Cloud',
                                    children: [
                                        (department === 'DevOps/Cloud') && {
                                            name: `${name}`,
                                            position: `${position}`,
                                            src: `${src}`
                                        } 
                                    ],
                                },
                                {
                                    name: 'Python',
                                    children: [
                                        (department === 'Python') && {
                                            name: `${name}`,
                                            position: `${position}`,
                                            src: `${src}`
                                        }
                                    ],
                                },
                                {
                                    name: 'QA',
                                    children: [
                                        (department === 'QA') && {
                                            name: `${name}`,
                                            position: `${position}`,
                                            src: `${src}`
                                        }
                                    ],
                                },
                                {
                                    name: '.Net',
                                    children: [
                                        (department === '.Net') && {
                                            name: `${name}`,
                                            position: `${position}`,
                                            src: `${src}`
                                        }
                                    ],
                                },
                                {
                                    name: 'Data Science',
                                    children: [
                                        (department === 'Data Science') && {
                                            name: `${name}`,
                                            position: `${position}`,
                                            src: `${src}`
                                        }
                                    ],
                                },
                                {
                                    name: 'Big Data',
                                    children: [
                                        (department === 'Big Data') && {
                                            name: `${name}`,
                                            position: `${position}`,
                                            src: `${src}`
                                        }
                                    ],
                                },
                                {
                                    name: 'CRM',
                                    children: [
                                        (department === 'CRM') && {
                                            name: `${name}`,
                                            position: `${position}`,
                                            src: `${src}`
                                        }
                                    ],
                                },
                                {
                                    name: 'Legal',
                                    children: [
                                        (department === 'Legal') && {
                                            name: `${name}`,
                                            position: `${position}`,
                                            src: `${src}`
                                        }
                                    ],
                                },
                                {
                                    name: 'HR',
                                    children: [
                                        (department === 'HR') && {
                                            name: `${name}`,
                                            position: `${position}`,
                                            src: `${src}`
                                        }
                                    ],
                                },
                                {
                                    name: 'Accounts',
                                    children: [
                                        (department === 'Accounts') && {
                                            name: `${name}`,
                                            position: `${position}`,
                                            src: `${src}`
                                        }
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    name: 'Human Resources',
                },
                {
                    name: 'Legal & Immigration',
                },
                {
                    name: 'Administration & Accounts',
                },
            ],
        }
    ]
    var temp = getData[0].children[1].children[0].children
    temp.forEach(element => {
        if(element.children[0] === false) delete element.children
    })
    return (
        <OrgChart
            spacingX={25}
            treeData={getData}
            renderContent={({ src, name, position }) => (
                <Box align={'center'}>
                    <Avatar className={styles.avatar} src={src} />
                    <Typography className={styles.name} variant={'h6'}>
                        {name}
                    </Typography>
                    <Typography
                        className={styles.position}
                        color={'textSecondary'}
                        variant={'caption'}
                    >
                        {position}
                    </Typography>
                </Box>
            )}
        />
    );
};

