import React, { useContext } from 'react';
import {
    Grid,
    TextField,
    MenuItem,
    makeStyles,
    Button
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataContext } from '../../../../../../contexts/data';
import Validations from '../../../../../generalcomponents/validation'

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}

const Presentation = (props) => {
    const { handleCountryAndState, geoState, gender, representativeName, jobTitle, email, mobile, workPhone, homePhone, line1, line2, country, state_name, city, zip, handleChange, handleSave, states } = props
    const [state] = useContext(DataContext)
    let countryList = state.hasOwnProperty('geo_data') ? state.geo_data.countries : []
    const classes = useStyles()
    const validate = new Validations()
    return (
        <div className="custom-card mb-4">
            <form onSubmit={(e) => handleSave(e)}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <TextField
                            label="Representative Name"
                            name="representativeName"
                            value={representativeName}
                            onChange={handleChange}
                            helperText={representativeName.length ? validate.checkName(representativeName) ? "" : "Enter valid representative name" : ""}
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Job Title"
                            name="jobTitle"
                            value={jobTitle}
                            onChange={handleChange}
                            helperText={jobTitle.length ? validate.checkName(jobTitle) ? "" : "Enter valid job title" : ""}
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            select
                            label="Gender"
                            name="gender"
                            value={gender}
                            onChange={handleChange}
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                        >
                            <MenuItem value=''>None</MenuItem>
                            {genderList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {capitalizeFirstLetter(option)}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                            helperText={email.length ? validate.checkEmail(email) ? "" : "Enter valid email" : ""}
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Mobile"
                            name="mobile"
                            value={mobile}
                            onChange={handleChange}
                            helperText={mobile.length ? validate.checkNumber(mobile) ? "" : "Enter valid phone number" : ""}
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Work Phone"
                            name="workPhone"
                            value={workPhone}
                            onChange={handleChange}
                            helperText={workPhone.length ? validate.checkNumber(workPhone) ? "" : "Enter valid work phone" : ""}
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Home Phone"
                            name="homePhone"
                            value={homePhone}
                            onChange={handleChange}
                            helperText={homePhone.length ? validate.checkNumber(homePhone) ? "" : "Enter valid home phone" : ""}
                            size="small"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Address Line 1"
                            name="line1"
                            value={line1}
                            onChange={handleChange}
                            helperText={line1.trim().length ? "" : "Enter valid address"}
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Address Line 2"
                            name="line2"
                            value={line2}
                            onChange={handleChange}
                            helperText={line1.trim().length ? "" : "Enter valid address"}
                            size="small"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            id="country-select-demo"
                            fullWidth
                            options={countryList}
                            classes={{
                                option: classes.option,
                            }}

                            //inputValue={country ? country : null}
                            onChange={(event, value) => {
                                if (value) {
                                    handleCountryAndState('country', value)
                                    return geoState(value.iso2)
                                }

                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <span>{countryToFlag(option.iso2)}</span>
                                    {option.name} ({option.iso2}) +{option.phone_code}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Country *"
                                    variant="outlined"
                                    size="small"

                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <p className="text-success" >Selected country:{country} </p>
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            id="country-select-demo"
                            fullWidth
                            options={states}

                            classes={{
                                option: classes.option,
                            }}
                            onChange={(event, value) => {
                                if (value) {
                                    handleCountryAndState('state_name', value)
                                    return geoState(value.iso2)
                                }

                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderOption={(option) => (
                                <React.Fragment>
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="State *"
                                    variant="outlined"
                                    size="small"

                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <p className="text-success" >Selected state:{state_name} </p>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="City"
                            name="city"
                            value={city}
                            onChange={handleChange}
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Zip Code"
                            name="zip"
                            value={zip}
                            onChange={handleChange}
                            helperText={zip.length ? validate.checkZip(zip) ? "" : "Enter valid zip code" : ''}
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <br />
                <div className="text-center">
                    {
                        (validate.checkName(representativeName) && gender.trim() && validate.checkName(jobTitle) && validate.checkEmail(email) && validate.checkNumber(mobile) && validate.checkNumber(workPhone) && line1.trim() && country.trim()
                            && state_name.trim() && validate.checkZip(zip) && city.trim()
                        ) ?
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Done
                            </Button> :
                            <Button
                                variant="contained"
                                disabled
                            >
                                Done
                            </Button>
                    }
                </div>
                <br />
            </form>
        </div>
    );
}

export default Presentation;

const genderList = ['male', 'female']