import React, { useState } from 'react'
import {  Collapse } from 'reactstrap'
import AddCircleIcon from "@material-ui/icons/AddCircle"
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';  
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {configuration} from '../../../configuration'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
       KeyboardDatePicker
  } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';  
import Validations from '../../generalcomponents/validation'

const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
    large: {
      position:"absolute",
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin:"1.1em",
    },
    box:{
      borderRadius:"100px",
      height:100,
      width:100,
      margin:"auto",
      marginTop:"4px"
    },
    table: {
      backgroundColor:"#fafafa"
    },
  }));

function Presentation(props) {
        const classes = useStyles();
        const {clearValues, clientname, address, workingEmailid, vendorName, vendorPhone, vendorEmail, fromDate, toDate, handleExisting, isEditing, handleAdd, editSection, handleEdit, handleDelete, employementhistoryData, handleDateChange } = props
        const [isOpen, setIsOpen] = useState(false);
        const [isOpen2, setIsOpen2] = useState(false);
        const formatter = (date) => {
            console.log(date)  
            let final = ""
            try {
                final = Intl.DateTimeFormat(configuration["date-code"],configuration.dateformat).format(new Date(date))
            } catch (error) {
                console.log(error)
                final = date
            }
            return final
        }
        const toggle = () => {
            setIsOpen(!isOpen);
            if(isOpen2){
                setIsOpen2(!isOpen2)
                clearValues()
            }
            else{
                clearValues()
            }
        }
        const handleFromDate = date => {
            if(!isNaN(Date.parse(date))) 
                handleDateChange("fromDate",new Date(date).toISOString())
        };
        const handleEndDate = date => {
            if(!isNaN(Date.parse(date))) 
                handleDateChange("toDate",new Date(date).toISOString())
            else if(date === null)    
                handleDateChange("toDate","")
        };
        const toggle2 = () =>{ 
            setIsOpen2(!isOpen2)
            if(isOpen){
                setIsOpen(!isOpen)
                clearValues()
            }
            else{
                clearValues()
            }
        }
        if(!isEditing){
            if(isOpen)
                setIsOpen(!isOpen)
            if(isOpen2)
                setIsOpen2(!isOpen2)    
        }

        


        const validate = new Validations()
    return (
           
            <div className="user-emergencycontact mt-3">
                        <div className="user-employementhistory mt-3">
                            <h3><u><b>Employment History:</b></u></h3>
                            <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="15%" align="left">Client</TableCell>
                                    <TableCell width="30%" align="left">Vendor</TableCell>
                                    <TableCell width="23%" align="left">From Date</TableCell>
                                    <TableCell width="18%" align="left">To Date</TableCell>
                                    {
                                        isEditing?
                                        <TableCell  align="right"  >
                                            <Fab 
                                                variant="contained" 
                                                color="primary"
                                                size="small"
                                                onClick={toggle}
                                            >
                                            <AddCircleIcon />
                                            </Fab>
                                        </TableCell>
                                        :
                                        null
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                
                                {employementhistoryData.map(item=>{
                                      return (
                                        <TableRow key={item.client} >
                                        <TableCell align="left"><b>{item.client}</b><br/>{item.clientaddress}<br/>{item.yourworkingmailid}</TableCell>
                                        {
                                            item.vendorname === "" ? 
                                            <TableCell align="left"><b>N/A</b></TableCell>
                                            :
                                            <TableCell align="left"><b>{item.vendorname}</b><br/>{item.vendorphone}<br/>{item.vendoremail}</TableCell>
                                        }
                                        
                                        <TableCell align="left">{item.from === "" ? item.from : formatter(item.from)}</TableCell>
                                        <TableCell align="left">{item.to === "" ? "Present" : formatter(item.to)}</TableCell>
                                        {
                                            isEditing?
                                            <TableCell align="right" >
                                                <Fab 
                                                    variant="contained" 
                                                    className="flairbtn" 
                                                    size="small"
                                                    onClick={()=>{toggle2();handleEdit(item)}}
                                                >
                                                    <EditIcon />
                                                </Fab>&nbsp;
                                                {employementhistoryData.length>1?<Fab 
                                                    variant="contained" 
                                                    size="small"
                                                    color="secondary"
                                                    onClick={()=>handleDelete(employementhistoryData.indexOf(item))}
                                                >
                                                    <DeleteIcon />
                                                </Fab>
                                                :null}
                                            </TableCell>
                                            :
                                            null
                                        }
                                        </TableRow>
                                        )
                                    })}
                            </TableBody>
                            </Table>
                        </TableContainer>
                        </div>
                            <div id="add_row_for_employment">
                                <Collapse isOpen={isOpen}>
                                    <form onSubmit={(e)=>{
                                        e.preventDefault()
                                        if(validate.checkName(clientname)&&address.trim().length&&!isNaN(Date.parse(fromDate))&&validate.checkDateDiff(fromDate, toDate ? toDate : new Date().toISOString())){
                                            toggle();
                                            handleAdd(e)
                                        }
                                        }}>
                                        <div className="m-3">
                                            <Paper style={{padding:'15px'}} >
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Client name"
                                                            name="clientname"
                                                            value={clientname}
                                                            variant="outlined"
                                                            helperText={clientname.length ? validate.checkName(clientname) ? "" : "Enter valid name":""}
                                                            size="small"
                                                            required
                                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Client address"
                                                            name="address"
                                                            value={address}
                                                            variant="outlined"
                                                            size="small"
                                                            required
                                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Client Working Email"
                                                            name="workingEmailid"
                                                            type="email"
                                                            value={workingEmailid}
                                                            helperText={workingEmailid.length ? validate.checkEmail(workingEmailid) ? "" : "Enter valid email id":""}
                                                            variant="outlined"
                                                            size="small"
                                                        
                                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                label="From date"
                                                                format="MM/dd/yyyy"
                                                                value={fromDate ? fromDate : null}
                                                                maxDate={new Date()}
                                                                onChange={handleFromDate}
                                                                name="fromDate"
                                                                required
                                                                KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>  
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                label="To date"
                                                                format="MM/dd/yyyy"
                                                                minDate={fromDate}
                                                                value={toDate ? toDate : null}
                                                                onChange={handleEndDate}
                                                                name="toDate"
                                                                KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>  
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Vendor name"
                                                            name="vendorName"
                                                            value={vendorName}
                                                            helperText={vendorName.length ? validate.checkName(vendorName) ? "" : "Enter valid name":""}
                                                            variant="outlined"
                                                            size="small"
                                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Vendor phone"
                                                            type="number"
                                                            name="vendorPhone"
                                                            value={vendorPhone}
                                                            helperText={vendorPhone.length ? validate.checkNumber(vendorPhone) ? "" : "Enter valid phone number":""}
                                                            variant="outlined"
                                                            size="small"
                                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Vendor email"
                                                            name="vendorEmail"
                                                            type="email"
                                                            value={vendorEmail}
                                                            helperText={vendorEmail.length ? validate.checkEmail(vendorEmail) ? "" : "Enter valid email id":""}
                                                            variant="outlined"
                                                            size="small"
                                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                        />
                                                    </Grid>
                                                </Grid>   
                                                <br/>
                                                <div className="text-center" >
                                                    <Button variant="contained" type="submit"  color="primary">
                                                        Save
                                                    </Button>
                                                    {' '}
                                                    <Button variant="contained" onClick={toggle} color="default">
                                                        Cancel
                                                    </Button>
                                                </div> 
                                                <br/>
                                            </Paper>
                                        </div>
                                        
                                    </form>
                                </Collapse>
                            </div>
                            <div id="edit_row_for_emergency_contact" >
                                <Collapse isOpen={isOpen2}>
                                    <form onSubmit={(e)=>{
                                        e.preventDefault();
                                        if(validate.checkName(clientname)&&address.trim().length&&!isNaN(Date.parse(fromDate))&&validate.checkDateDiff(fromDate, toDate ? toDate : new Date().toISOString())){
                                            toggle2();
                                            handleExisting(employementhistoryData.indexOf(editSection),editSection)
                                        }
                                    }}>
                                        <div className="m-3">
                                            <Paper style={{padding:'15px'}} >
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Client name"
                                                            name="clientname"
                                                            value={clientname}
                                                            variant="outlined"
                                                            helperText={clientname.length ? validate.checkName(clientname) ? "" : "Enter valid name":""}
                                                            size="small"
                                                            required
                                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Client address"
                                                            name="address"
                                                            value={address}
                                                            variant="outlined"
                                                            size="small"
                                                            required
                                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Client Working Email"
                                                            name="workingEmailid"
                                                            type="email"
                                                            value={workingEmailid}
                                                            helperText={workingEmailid.length ? validate.checkEmail(workingEmailid) ? "" : "Enter valid email id":""}
                                                            variant="outlined"
                                                            size="small"
                                                         
                                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                label="From date"
                                                                format="MM/dd/yyyy"
                                                                invalidDateMessage=""
                                                                value={fromDate ? fromDate : null}
                                                                maxDate={new Date()}
                                                                onChange={handleFromDate}
                                                                name="fromDate"
                                                                required
                                                                KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>  
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                label="To date"
                                                                format="MM/dd/yyyy"
                                                                invalidDateMessage=""
                                                                value={toDate ? toDate : null}
                                                                minDate={fromDate}
                                                                onChange={handleEndDate}
                                                                name="toDate"
                                                                KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>  
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Vendor name"
                                                            name="vendorName"
                                                            value={vendorName}
                                                            helperText={vendorName.length ? validate.checkName(vendorName) ? "" : "Enter valid name":""}
                                                            variant="outlined"
                                                            size="small"
                                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Vendor phone"
                                                            type="phone"
                                                            name="vendorPhone"
                                                            value={vendorPhone}
                                                            helperText={vendorPhone.length ? validate.checkNumber(vendorPhone) ? "" : "Enter valid phone number":""}
                                                            variant="outlined"
                                                            size="small"
                                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Vendor email"
                                                            name="vendorEmail"
                                                            type="email"
                                                            value={vendorEmail}
                                                            helperText={vendorEmail.length ? validate.checkEmail(vendorEmail) ? "" : "Enter valid email id":""}
                                                            variant="outlined"
                                                            size="small"
                                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                        />
                                                    </Grid>
                                                </Grid>    
                                                <br/>
                                                <div className="text-center" >
                                                    <Button variant="contained" type="submit"  color="primary">
                                                        Save
                                                    </Button>
                                                    {' '}
                                                    <Button variant="contained" onClick={toggle2} color="default">
                                                        Cancel
                                                    </Button>
                                                </div>
                                                <br/>
                                            </Paper>
                                        </div>
                                        
                                    </form>
                                </Collapse>
                            </div>
                </div>
    )
}

export default Presentation
