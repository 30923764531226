import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { GoFileSymlinkFile } from 'react-icons/go'
import { DataContext } from '../../../../contexts/data'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  new: {
    float: 'right'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Presentation(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { handleChange, clearValues, uid, handleChange2, imageURL, progress1, handleNewRequest } = props

  const [state] = useContext(DataContext)

  const managers = []
  state.names.forEach(employee => {
    if (employee.designation === 'Manager') {
      managers.push(employee)
    }
  })
  
  console.log(managers)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearValues()
  };
  return (
    <div>
      <div className={classes.new}>
        <Button onClick={handleClickOpen} color="primary" variant="contained">
          Add Signature
      </Button>
      </div>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Add new signature
            </Typography>
            <React.Fragment>
              <Typography variant="h6" gutterBottom>

              </Typography>
              <form onSubmit={handleNewRequest}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} >
                    <TextField
                      select
                      id="manager"
                      label="Choose Manager"
                      value={uid}
                      style={{ width: '40%' }}
                      onChange={handleChange('uid')}
                    >
                      {managers.map((option) => (
                        <MenuItem key={option.uid} value={option.uid}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {/* <FileUploader /> */}
                    <TextField
                      label="Attach Image *"
                      type="file"
                      name="imageURL"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      size="small"
                      onChange={handleChange2}
                    />
                    <p>{imageURL !== "" ? <a target="_blank" rel="noopener noreferrer" href={imageURL} ><GoFileSymlinkFile size={22} /></a> : <p>No file choosen</p>} </p>
                    {

                      progress1 < 100 ? <p>Uploading please wait...</p> : ""
                    }
                  </Grid>
                  {
                    (uid) && (progress1 >= 100) ?
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={()=>setOpen(false)}
                        className={classes.submit}
                      >
                        Submit Request
                      </Button> :
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled
                      >
                        Submit Request
                      </Button>
                  }
                </Grid>
              </form>
            </React.Fragment>
          </Paper>
        </main>


      </Dialog>
    </div>
  );
}
