import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
import {
  ADD_WIKI_CATEGORY,
  DELETE_WIKI_CATEGORY,
  SET_WIKI_CATEGORY,
  UPDATE_WIKI_CATEGORY,
} from '../../../../contexts/types'
import { CircularProgress } from '@material-ui/core'
import Axios from 'axios'

class Container extends Component {
  static contextType = DataContext

  getCategories = () => {
    const [state] = this.context
    this.setState({
      CategorieNames: state.wikiCategories,
      categoryLoad: true,
    })
  }

  componentDidMount = async () => {
    const [state, dispatch] = this.context
    if (!state.hasOwnProperty('wikiCategories')) {
      let res = await Axios.get('wiki/getCategories')
      dispatch({
        type: SET_WIKI_CATEGORY,
        payload: res.data.response,
      })
      this.getCategories()
    } else {
      this.getCategories()
    }
  }

  response = async () => {
    const dispatch = this.context[1]
    let res = await Axios.get('wiki/getCategories')
    dispatch({
      type: SET_WIKI_CATEGORY,
      payload: res.data.response,
    })
  }

  wikiDeleteCategoryCallBack = async () => {
    this.response()
  }

  deleteTableContent = (oldData) => {
    const dispatch = this.context[1]
    dispatch({
      type: DELETE_WIKI_CATEGORY,
      wikiDeleteCategoryCallBack: this.wikiDeleteCategoryCallBack,
      payload: {
        categoryId: oldData.id,
      },
    })
  }

  wikiAddCategoryCallBack = async () => {
    const dispatch = this.context[1]
    let res = await Axios.get('wiki/getCategories')
    dispatch({
      type: SET_WIKI_CATEGORY,
      payload: res.data.response,
    })
  }

  addTableContent = (newData) => {
    const dispatch = this.context[1]
    dispatch({
      type: ADD_WIKI_CATEGORY,
      wikiAddCategoryCallBack: this.wikiAddCategoryCallBack,
      payload: {
        categoryName: newData.category,
        description: newData.description ? newData.description : '',
      },
    })
  }

  wikiUpdateCategoryCallback = async () => {
    this.response()
  }

  updateTableContent = (newData, oldData) => {
    const dispatch = this.context[1]
    dispatch({
      type: UPDATE_WIKI_CATEGORY,
      wikiUpdateCategoryCallback: this.wikiUpdateCategoryCallback,
      payload: {
        categoryName: newData.category,
        description: newData.description
          ? newData.description
          : oldData.description
          ? oldData.description
          : '',
        categoryId: oldData.id,
      },
    })
  }

  render() {
    const [state] = this.context
    if (state.hasOwnProperty('wikiCategories')) {
      return (
        <div>
          <Presentation
            addTableContent={this.addTableContent}
            deleteTableContent={this.deleteTableContent}
            updateTableContent={this.updateTableContent}
          />
        </div>
      )
    } else {
      return (
        <div style={{ marginTop: '15%', alignContent: 'center' }}>
          <div className='text-center'>
            <CircularProgress color='primary' />
          </div>
        </div>
      )
    }
  }
}

export default Container
