import React, { Component } from 'react';
import Presentation from "./Presentation";
import { DataContext } from '../../../../../../contexts/data'
import { TEMPORARY_NEW_CLIENT_DATA, SET_COUNTRY_API } from '../../../../../../contexts/types'
import Axios from 'axios'

export default class Container extends Component {
    state = {
        locationsline1: '',
        locationsline2: '',
        locationscountry: '',
        locationsstate_name: '',
        locationscity: '',
        locationszip: '',
        states: [],
    }

    static contextType = DataContext

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
        console.log(event.target.value)
    }

    componentDidMount = () => {
        const dispatch = this.context[1]
        const { item } = this.props
        dispatch({
            type: SET_COUNTRY_API,
            payload: dispatch
        })
        Object.entries(item).forEach(([key, value]) => {
            console.log(key, value)
            this.setState({
                [key]: value
            })
        })
    }

    handleSave = (e) => {
        e.preventDefault()
        const [state, dispatch] = this.context
        const { index } = this.props
        const { locationsline1, locationsline2, locationscountry, locationsstate_name, locationscity, locationszip } = this.state
        let mainArray = ('locations' in state.temporary_client_data ) ? state.temporary_client_data.locations : []
        let payload = {
            locationsline1,
            locationsline2,
            locationscountry,
            locationsstate_name,
            locationscity,
            locationszip,
            id: index
        }
        mainArray[index] = payload
        dispatch({
            type: TEMPORARY_NEW_CLIENT_DATA,
            payload: {
                key: 'locations',
                locations: mainArray
            }
        })
    }

    geoState = async (code) => {
        let states = await Axios.post("/loadstates", { countrycode: code })
        this.setState({ states: states.data })
        console.log(code)
    }

    handleCountryAndState = (name, props) => {
        this.setState({
            [name]: props.name
        })
    }

    render() {
        if(Object.keys(this.state).length) {
            return (
                <div>
                    <Presentation
                        {...this.state}
                        handleChange={this.handleChange}
                        handleSave={this.handleSave}
                        geoState={this.geoState}
                        handleCountryAndState={this.handleCountryAndState}
                    />
                </div>
            );
        }
        return <p>Loading...</p>
    }
}