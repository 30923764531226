import React, { Component } from 'react'
import Presentation from './Presentation'

export class Container extends Component { 

 
    render() {
        return (
            <div>
                <Presentation 
                    {...this.props}
                    isPOnumberReq={this.props.invoiceSettings.POnumber}
                />
            </div>
        )
    }
}

export default Container
