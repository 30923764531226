import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../../contexts/data'
import { PLACEMENT_SETTINGS_ACTIONS } from '../../../../../../contexts/types'
import firebase from '../../../../../../firebase_config'

export default class Container extends Component {
  constructor(...args) {
    super(...args)
    this.state = {
      value: 0,
      deletedTabIds: [],
      tabList: [
        {
          key: 0,
          id: 0,
          client: '',
          clientId: '',
          workLocation: false,
          clientType: '',
          selectAddress: '',
          subContracting: '',
          contingencyinPayment: '',
          prohibitionPeriod: '',
          rightToHire: '',
          liquidatedDamages: '',
          identification: '',
          comments: '',
          selectAddressList: [],
          settingsId: '',
          isExist: true
        },
      ],
      profile: {},
      docId: '',
      fillableSections: [],
      billableClient: '',
    }
  }

  static contextType = DataContext

  addTab = () => {
    const { tabList } = this.state
    let id = tabList[tabList.length - 1].id + 1
    let data = tabList
    let newTab = {
      key: id,
      id: id,
      client: '',
      clientId: '',
      workLocation: false,
      clientType: '',
      selectAddress: '',
      subContracting: '',
      contingencyinPayment: '',
      prohibitionPeriod: '',
      rightToHire: '',
      liquidatedDamages: '',
      identification: '',
      comments: '',
      settingsId: '',
      selectAddressList: [],
      isExist: true
    }
    data = [...data, newTab]
    this.setState({ tabList: data })
  }

  deleteTab = (e) => {
    e.stopPropagation()

    if (this.state.tabList.length === 1) {
      return
    }

    let tabID = parseInt(e.target.id)
    console.log(tabID)
    let tabIDIndex = 0
    let settingsId
    let tabList = this.state.tabList.filter((value, index) => {
      if (value.id === tabID) {
        tabIDIndex = index
        settingsId = value.settingsId
      }
      return value.id !== tabID
    })
    if(settingsId) {
      this.setState((prevState) => ({
        deletedTabIds: prevState.deletedTabIds.concat(settingsId)
      }))
    }
    this.setState(
      (state, props) => {
        let curValue = parseInt(state.value)
        if (curValue === tabID) {
          if (tabIDIndex === 0) {
            curValue = state.tabList[tabIDIndex + 1].id
          } else {
            curValue = state.tabList[tabIDIndex - 1].id
          }
        }
        return {
          value: curValue,
        }
      },
      () => {
        this.setState({
          tabList: tabList,
        })
      }
    )
  }

  getData = (id) => {
    firebase
      .firestore()
      .collection('Placements')
      .doc(id)
      .collection('ClientDetails_Settings')
      .where('isExist', '==', true)
      .onSnapshot((snap) => {
        let placementData = []
        placementData = snap.docs.map((doc) => doc.data())
        if (placementData.length) {
          let firstTab = placementData.filter(
            (placement) => placement.id === 'settings'
          )
          let remainingTabs = placementData.filter(
            (placement) => placement.id !== 'settings'
          )
          let tabList = [...firstTab, ...remainingTabs].map(
            (placement, index) => {
              return {
                ...placement,
                settingsId: placement.id,
                key: index,
                id: index,
                selectAddressList: [],
              }
            }
          )
          this.setState({ tabList })
        }
      })
  }

  componentDidMount = () => {
    const { req_placement } = this.props
    if (req_placement.id) this.getData(req_placement.id)
  }

  componentWillReceiveProps = (nextProps) => {
    const { req_placement, profile } = nextProps
    const { id, fillableSections, billableClient } = req_placement
    this.setState({ profile, docId: id, fillableSections, billableClient })
    if (id.length && this.props.req_placement.id !== id) this.getData(id)
  }

  handleTabChange = (event, value) => {
    this.setState({ value })
  }

  handleFinish = () => {
    const { tabList, profile, docId } = this.state
    const dispatch = this.context[1]
    let data = []
    tabList &&
      tabList.forEach((tab) => {
        data.push({
          client: tab.client,
          clientId: tab.clientId,
          workLocation: tab.workLocation,
          clientType: tab.clientType,
          selectAddress: tab.selectAddress,
          subContracting: tab.subContracting,
          contingencyinPayment: tab.contingencyinPayment,
          prohibitionPeriod: tab.prohibitionPeriod,
          rightToHire: tab.rightToHire,
          liquidatedDamages: tab.liquidatedDamages,
          identification: tab.identification,
          comments: tab.comments,
          settingsId: tab.settingsId,
          isExist: tab.isExist
        })
      })
    const payload = {
      details: data,
      placementID: docId,
      sectionName: 'client-details',
    }
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
      payload: payload,
      actionType: 'new',
    })
  }

  placementSettingsActionsWiki = () => {}

  handleUpdate = () => {
    const { tabList, profile, docId, deletedTabIds } = this.state
    const dispatch = this.context[1]
    let data = []
    tabList &&
      tabList.forEach((tab) => {
        data.push({
          client: tab.client,
          clientId: tab.clientId,
          workLocation: tab.workLocation,
          clientType: tab.clientType,
          selectAddress: tab.selectAddress,
          subContracting: tab.subContracting,
          contingencyinPayment: tab.contingencyinPayment,
          prohibitionPeriod: tab.prohibitionPeriod,
          rightToHire: tab.rightToHire,
          liquidatedDamages: tab.liquidatedDamages,
          identification: tab.identification,
          comments: tab.comments,
          settingsId: tab.settingsId,
          isExist: tab.isExist
        })
      })
    const payload = {
      details: data,
      deletedTabIds: deletedTabIds,
      placementID: docId,
      sectionName: 'client-details',
    }
    console.log(payload)
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
      payload: payload,
      actionType: 'update',
    })
  }

  handleChange = (tabList) => {
    console.log('clients', tabList)
    this.setState({ tabList })
  }

  componentWillUnmount = () => {
    this.setState({
      value: 0,
      tabList: [
        {
          key: 0,
          id: 0,
          client: '',
          clientId: '',
          workLocation: false,
          clientType: '',
          selectAddress: '',
          subContracting: '',
          contingencyinPayment: '',
          prohibitionPeriod: '',
          rightToHire: '',
          liquidatedDamages: '',
          identification: '',
          comments: '',
          selectAddressList: [],
          settingsId: ''
        },
      ],
    })
  }

  render() {
    return (
      <div>
        <Presentation
          {...this.state}
          handleTabChange={this.handleTabChange}
          addTab={this.addTab}
          deleteTab={this.deleteTab}
          handleChange={this.handleChange}
          handleFinish={this.handleFinish}
          handleUpdate={this.handleUpdate}
        />
      </div>
    )
  }
}
