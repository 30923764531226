import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { DataContext } from '../../../contexts/data'
import { FaPen } from "react-icons/fa";
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import CardMedia from "@material-ui/core/CardMedia";
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

const useStyles = makeStyles(() => ({
  root: {
    // width: 400,
    // height: 450,
    margin: "auto"
  },
  content: {
    padding: 20,
    margin: "80px 0 0 0px",
    color: "#fff",
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Arial, Helvetica, sans-serif",
  },
  cardMediaCss: {
    background: 'linear-gradient(45deg, #280071 10%, #c42053 90%)',
    height: 230,
    paddingTop: 20, 
  },
  avatar: {
    width: 120,
    height: 120,
    border: "2px solid #fff",
    margin: "0px 265px 0 auto",
    "& > img": {
      margin: 0
    }
  },
  details: {
    padding: 8,
  },
  editoption: {
    margin: "-120px 0 0 100px"
  }
}));

export default function Presentation(props) {
  let profile = {}
  const {handleChange,progress, individual, id}=props;
   const classes = useStyles();
  const [ state ] = useContext(DataContext)
  if(individual){
    profile = state.employee_collection
  }
  else{
    profile = state.employees_collection.filter(user => user.useremail === id)[0]
  }

    const cardStyles = useStyles();
    
    return (
      <Card className={cardStyles.root}>
        <CardMedia
          className={cardStyles.cardMediaCss}
          // image={profile.imageURL}
        >
        <Avatar alt={profile.personal.firstname} className={cardStyles.avatar} src={profile.imageURL} />
        <div  className={classes.editoption}>          
            {
              progress
              ?
                <p><CircularProgress /></p>
              :
                <Button
                  variant="outline"
                  component="label"
                >
                <FaPen  style={{ color: "#fff"}}/>
                  <input
                  onChange={(e)=>handleChange(e,profile.useremail)}
                    type="file"
                    style={{ display: "none" }}
                  />
                </Button>
            }
        </div>

        <div className={cardStyles.content}>
            {profile.personal.firstname + " " + profile.personal.middlename + " " + profile.personal.lastname}
            <br/>
            {profile.companyID}
        </div>
</CardMedia>
          <CardContent>
            <h2>Contact Information</h2>
            <Divider variant="middle" />
            <div className={cardStyles.details}>
                <EmailOutlinedIcon /> {"   " + profile.useremail}
            </div>
            
            <div className={cardStyles.details}>
               <CallOutlinedIcon /> {"   " + profile.personal.phonenumber}
            </div>

            <div className={cardStyles.details}>
                <HomeOutlinedIcon /> {"   " + profile.mailingaddress.line1 + ", " +( profile.mailingaddress.line2 ?  profile.mailingaddress.line2 + ", " : "") + profile.mailingaddress.city + ", " + profile.mailingaddress.state + ", " + profile.mailingaddress.country + " - " + profile.mailingaddress.zip}
            </div>
          </CardContent>
      </Card>
    );
}

