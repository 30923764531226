import React, { useContext,useState, useEffect  } from 'react';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import {
    Column,
    FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedPaging, IntegratedSelection, IntegratedSorting,
    PagingState, SelectionState, SortingState, DataTypeProvider, DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import {
    DragDropProvider,
    Grid, GroupingPanel, PagingPanel,
    Table, TableFilterRow, TableGroupRow,
    TableHeaderRow, TableSelection, Toolbar, TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import {IoMdPersonAdd} from 'react-icons/io'
import SunEditor from 'suneditor-react'
import Validations from '../../../../generalcomponents/validation'
import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from '../../../../../contexts/data'

import Chip from '@material-ui/core/Chip';

import Checkbox from '@material-ui/core/Checkbox';

import { Link } from 'react-router-dom'
import { GoMailRead } from 'react-icons/go'
import { MdDelete } from 'react-icons/md'
import Button from '@material-ui/core/Button';
import {   Avatar,
    TablePagination,
    IconButton,
    Tooltip,
    Tabs,
    Tab,
    
    Badge,
    AppBar,
    Typography,
    Box,
    
    Fab,
    ButtonGroup, } from "@material-ui/core";

    import EditIcon from '@material-ui/icons/Edit'
    import HighlightOff from '@material-ui/icons/HighlightOff'
    import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
    import PropTypes from 'prop-types'
    import SweetAlert from 'react-bootstrap-sweetalert'
    import parser from 'html-react-parser'
import { FaFilter } from "react-icons/fa";

//const sales = generateRows({ columnValues: globalSalesValues, length: 1000 });
const availableFilterOperations = [
    'equal', 'notEqual',
    'greaterThan', 'greaterThanOrEqual',
    'lessThan', 'lessThanOrEqual', 'contains'
];

const styles = makeStyles(({
    currency: {
        fontWeight: "bold"
    },
    numericInput: {
        fontSize: '14px',
        width: '100%',
    },
}));



 
 
export default function Presentation(props) {
    const { 
        listAll, description, approveExpense, rejectExpense, handleChange, expenseSettings, employeeID,
      info } = props
    const [state] = useContext(DataContext)
    const [value, setValue] = React.useState(0)
    const validate = new Validations()
    const [alert, setAlert] = useState(null)
    const [type, setType] = useState('')
    const [id, setId] = useState('')
   
    const [open,setOpen] = React.useState(false)
    const handleClickOpen = () => {
      
        setOpen(!open)
       
        
    }
   
    
      const columns = [
        {
            title: <span>
            Employee  &nbsp; 
           
      
      <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
      
      
        </span>,
            name: 'empCode',
           
        },
        { title: <span>
          SpentDATE  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>, name: 'spentDate' },
        { title: <span>
          ExpenseType  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>, name: 'expenseType' },
        { title: <span>
          Amount  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>, name: 'amount' },
        { title: <span>
          ExpenseDoc  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>, name: 'expenseDoc',
         
        },
        { title: <span>
          Vendor  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>, name: 'vendor' },
        { title: <span>
          Receipt  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>, name: 'receipt' },
        { title: <span>
          Depature Date  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>, name: 'departureDate' },
        { title: <span>
          Return Date  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>, name: 'returnDate' },
        { title: <span>
          AdditionalDetails  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>, name: 'additionalDetails' },
        { title: <span>
          Comments  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>, name: 'comment' },
        {
            title: <span>
            Actions  &nbsp; 
           
      
      <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
      
      
        </span>,
            name: 'actions',
          
            },
        
    ]

    const [EmployeeID] = React.useState(['empCode']);
    const [ExpenseDoc] = React.useState(['expenseDoc']);
    const [Actions] = React.useState(['actions']);
   

    const [defaultHiddenColumnNames] = React.useState(['']);

    

    
    const EmployeeIDProvider = (props) => (
        <DataTypeProvider
            formatterComponent={EmployeeIDFormatter}
            {...props}
        />
    )
  
    const EmployeeIDFormatter = (props) => {
        let rowData = props.row
        return (
    
            <div className='d-flex'>
                <Avatar src={rowData.imageURL} />
                <div className='d-flex flex-column'>
                    <span>
                    {' '}
                    <Link to={'/console/employeelist/' + rowData.empCode}>
                        {rowData.empCode}
                    </Link>{' '}
                    </span>
                    <span>{rowData.name} </span>
                </div>
                </div>)
    }
    const ExpenseDocProvider = (props) => (
        <DataTypeProvider
            formatterComponent={ExpenseDocFormatter}
            {...props}
        />
    )
    const ExpenseDocFormatter = (props) => {
        let rowData = props.row
        return (
    
            <div>
              {
                rowData.attachmentDetails.publicURL !== "" ? <a rel='noopener noreferrer' target="_blank" href={rowData.attachmentDetails.publicURL} > {rowData.attachmentDetails.sourcePath.slice(rowData.attachmentDetails.sourcePath.lastIndexOf("/") + 1)} </a> : "---"
              }
            </div>)
    }
    const ActionsProvider = (props) => (
        <DataTypeProvider
            formatterComponent={ActionsFormatter}
            {...props}
        />
    )
    const ActionsFormatter = (props) => {
        let rowData = props.row
       
        if (rowData.isApproved)
                return (
                  <div>
                    {checkApprovalAccess(rowData.placementID) ? (
                      <IconButton
                        onClick={() => {
                          setType('reject')
                          setId(rowData.id)
                        //   setPlacementDocID(rowData.placementDocID)
                          showAlert('reject', rowData.id)
                        }}
                        color='secondary'
                      >
                        <HighlightOff />
                      </IconButton>
                    ) : null}
                  </div>
                )
              else if (rowData.isRejected)
                return (
                  <div>
                    {rowData.empCode === state.employee.companyID ? (
                      <Link
                        to={
                          '/console/expenses/submission/' +
                          rowData.id +
                          '/edit'
                        }
                      >
                        <IconButton color='default'>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    ) : checkApprovalAccess(rowData.placementID) ? (
                      <IconButton
                        onClick={() =>
                          showAlert('approve', rowData.id)
                        }
                        color='primary'
                      >
                        <CheckCircleOutline />
                      </IconButton>
                    ) : null}
                  </div>
                )
              else if (!rowData.isApproved && !rowData.isRejected)
                return (
                  <div>
                    {rowData.empCode === state.employee.companyID ? (
                      <Link
                        to={
                          '/console/expenses/submission/' +
                          rowData.id +
                          '/edit'
                        }
                      >
                        <IconButton color='default'>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    ) : checkApprovalAccess(rowData.placementID) ? (
                      <>
                        <IconButton
                          onClick={() =>
                            showAlert('approve', rowData.id)
                          }
                          color='primary'
                        >
                          <CheckCircleOutline />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setType('reject')
                            setId(rowData.id)
                            // setPlacementDocID(rowData.placementDocID)
                            showAlert('reject', rowData.id)
                          }}
                          color='secondary'
                        >
                          <HighlightOff />
                        </IconButton>
                      </>
                    ) : null}
                  </div>
                )
    }
    const hideAlert = () => {
        setAlert(null)
    }

    useEffect(() => {
        if (alert !== null) {
          showAlert(type, id)
        }
    }, [description])


    const checkApprovalAccess = (placementID) => {
      if (state.employee.role === 'admin') return true
      try {
        return expenseSettings.filter((item) => {
            return item.placementID === placementID
          })[0].companyIDs.includes(state.employee.companyID)
        
      } catch (error) {
        console.log(error)
        return false
      }
    }


    const showAlert = (type, id) => {
        console.log("hii",type,id)
        setAlert(
          <div>
            <SweetAlert
              closeOnClickOutside={false}
              allowEscape={false}
              warning
              showCancel={false}
              showConfirm={false}
              title='Are you sure you want to approve this expense?'
              onConfirm={() => {
                if (type === 'reject') rejectExpense(id)
                else approveExpense(id)
              }}
              onCancel={hideAlert}
            >
              <SunEditor
                hide={type !== 'reject'}
                placeholder={
                  type === 'reject' ? 'Description*' : 'Description(optional)'
                }
                value={description}
                onChange={(data) => handleChange('description', data)}
                setOptions={{
                  buttonList: [
                    [
                      'undo',
                      'redo',
                      'formatBlock',
                      'bold',
                      'underline',
                      'italic',
                      'strike',
                      'subscript',
                      'superscript',
                      'fontColor',
                      'hiliteColor',
                      'removeFormat',
                      'outdent',
                      'indent',
                      'align',
                      'horizontalRule',
                      'list',
                      'lineHeight',
                      'table',
                      'link',
                    ],
                  ],
                }}
              />
              <br />
              <div>
                <div>
                  <Button onClick={hideAlert} variant='contained' color='default'>
                    cancel
                  </Button>
                  {'  '}
                  <Button
                    onClick={() => {
                      setAlert(null)
                      type === 'reject'
                        ? rejectExpense(id)
                        : approveExpense(id)
                    }}
                    disabled={type === 'reject' ? description === '' : false}
                    variant='contained'
                    color='primary'
                  >
                    {type === 'reject' ? 'Reject' : 'Approve'}
                  </Button>
                </div>
              </div>
            </SweetAlert>
          </div>
        )
      }
    let rows =[]
    return (
        <Paper>
             <div>{alert}</div>
             <div
                style={{
                  marginTop: 'auto',
                  marginLeft: 'auto',
                  marginBottom: 'auto',
                }}
              >
                <Tooltip title='New Expense'>
                  <ButtonGroup
                    variant='contained'
                    color='primary'
                    aria-label='outlined primary button group'
                  >
                    <Link to='/console/expenses/submission/expense/new'>
                      <Button color='primary' variant='contained'>
                        New Expense
                      </Button>
                    </Link>
                  </ButtonGroup>
                </Tooltip>
              </div>
            <Grid
                rows={info}
                columns={columns}
            >
               { open ?   <FilteringState
                //  defaultFilters={[{ columnName: 'saleDate', value: '2016-02' }]}
                /> : ""}
                <SortingState
                /*  defaultSorting={[
                    { columnName: 'product', direction: 'asc' },
                    { columnName: 'saleDate', direction: 'asc' },
                  ]}*/
                />

                <SelectionState />

                <GroupingState
                //   defaultGrouping={[{ columnName: 'product' }]}
                //  defaultExpandedGroups={['EnviroCare Max']}
                />
                <PagingState />

                <IntegratedGrouping />
                { open ?  <IntegratedFiltering /> :""}
                <IntegratedSorting />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                //columnExtensions={tableColumnExtensions}
                />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
               <EmployeeIDProvider 
              for={EmployeeID} />
              <ExpenseDocProvider
              for={ExpenseDoc}/>
             
             
              <ActionsProvider
               for={Actions}/>
                <DragDropProvider />

                <Table />


                <TableHeaderRow showSortingControls={true} />
                { open ?  <TableFilterRow showFilterSelector={true} /> : "" }
                <PagingPanel pageSizes={10} />

                <TableGroupRow />
                <Toolbar />
                <GroupingPanel showSortingControls={true} />
            </Grid>
        </Paper>
    );
};
