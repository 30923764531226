import React, { Component } from 'react'
import Presentation from './Presentation'
import Validations from '../../../../generalcomponents/validation'

class Container extends Component {
  constructor(props) {
    super(props)
    this.validate = new Validations()
  }
  state = {
    to: [],
    cc: [],
    bcc: [],
    subject: '',
    body: '',
    helperText: {
      to: '',
      cc: '',
      bcc: '',
    },
    attachment: [],
    isUploading: false,
    progress: 0,
  }

  handleClick = (id) => {
    console.log(id)
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentWillReceiveProps = (nextProps) => {
    const { invoicesInfo, invoiceNumber} = nextProps
    if (invoicesInfo) {
      const { to, cc, bcc } = invoicesInfo
      this.setState({ to, cc, bcc })
    }
    if(invoiceNumber) {
      this.setState({ subject: invoiceNumber })
    }
  }

  handleChange2 = (e) => {
    // if (e.target.files[0]) {
    //   const file = e.target.files[0]
    //   this.setState(() => ({ file }))
    //   this.fileUpload1(file, this.state.documentType)
    // }
  }

  //   fileUpload1 = async (file, type) => {
  //     const { req_placement } = this.props
  //     const { empCode, placementID } = req_placement
  //     // companyID_placementID_docType_Timestamp
  //     const fileName = [
  //       empCode,
  //       '_',
  //       placementID,
  //       '_',
  //       type,
  //       '_',
  //       new Date().toISOString(),
  //     ].join('')
  //     const filePath = `Placements/${placementID}/Documents/${fileName}`
  //     this.setState({ isUploading: true })
  //     try {
  //       const url = await this.validate.uploadToStorage(
  //         file,
  //         filePath,
  //         fileName,
  //         'file'
  //       )
  //       this.setState((prevState) => ({
  //         attachment: prevState.attachment.concat(url),
  //       }))
  //       this.setState({ isUploading: false })
  //     } catch (err) {
  //       this.setState({ isUploading: false })
  //       console.log(err)
  //     }
  //   }

  handleAdd = (name, chip) => {
    let data = this.state[name]
    if (this.validate.checkEmail(chip)) {
      data = [...data, chip]
      this.setState({
        helperText: {
          to: '',
          cc: '',
          bcc: '',
        },
      })
    } else {
      this.setState((prevState) => ({
        helperText: {
          ...prevState.helperText,
          [name]: 'Enter valid email',
        },
      }))
    }
    this.setState({ [name]: data })
    console.log('add', data)
  }

  handleDelete = (name, index) => {
    let data = this.state[name]
    data.splice(index, 1)
    this.setState({ [name]: data })
    console.log('delete', data)
  }

  handleData = (data) => {
    this.setState({
      body: data,
    })
  }

  render() {
    return (
      <div>
        <Presentation
          {...this.state}
          {...this.props}
          handleClick={this.handleClick}
          handleAdd={this.handleAdd}
          handleChange={this.handleChange}
          handleDelete={this.handleDelete}
          handleData={this.handleData}
          handleChange2={this.handleChange2}
        />
      </div>
    )
  }
}

export default Container
