import React, { useContext } from 'react'
import MaterialTable from 'material-table';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Validations from '../../generalcomponents/validation'
import {DataContext} from '../../../contexts/data'
import Axios from 'axios'
import { Avatar } from '@material-ui/core';
import { Link } from 'react-dom'

const useStyles = makeStyles(theme => ({
    table: {
      '& tbody>.MuiTableRow-root:hover': {
        background: '#e8e8e8',
      }
    },
  }));

function Presentation(props) {
    const { responseData, handleChange, handleModulesUpdate, showLoader } = props
    const [state] = useContext(DataContext)
    const validate = new Validations()
    const classes = useStyles();
    const columns = [
        { title: 'Employee', field: 'employee',
            render: ({ imageURL, email, empCode, employee }) => <div className="d-flex" >{employee} </div>
        },
        { title: 'Wiki', field: 'wiki',
            render : ({ wiki, index }) => <Checkbox checked={wiki} name='wiki' onChange={(e) => handleChange(e, index)}  />
        },
        { title: 'Timesheets', field: 'timesheets',
            render : ({ timesheets, index }) => <Checkbox checked={timesheets} name='timesheets' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'Immigration', field: 'immigration',
            render : ({ immigration, index }) => <Checkbox checked={immigration} name='immigration' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'Task Management', field: 'taskManagement',
            render : ({ taskManagement, index }) => <Checkbox checked={taskManagement} name='task-management' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'Employee Self Service', field: 'ess',
            render : ({ ess, index }) => <Checkbox checked={ess} name='employee-self-services' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'Discussions', field: 'discussions',
            render : ({ discussions, index }) => <Checkbox checked={discussions} name='discussions' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'Accounts', field: 'accounts',
            render : ({ accounts, index }) => <Checkbox checked={accounts} name='accounts' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'Employees Manager', field: 'employeesManager',
            render : ({ employeesManager, index }) => <Checkbox checked={employeesManager} name='employees-manager' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'Console Customization', field: 'consoleCustomization',
            render : ({ consoleCustomization, index }) => <Checkbox checked={consoleCustomization} name='console-customization' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'Wiki Manager', field: 'wikiManager',
            render : ({ wikiManager, index }) => <Checkbox checked={wikiManager} name='wiki-manager' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'Timesheets Manager', field: 'timesheetsManager',
            render : ({ timesheetsManager, index }) => <Checkbox checked={timesheetsManager} name='timesheets-manager' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'Immigration Manager', field: 'immigrationManager',
            render : ({ immigrationManager, index }) => <Checkbox checked={immigrationManager} name='immigration-manager' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'Accounts Manager', field: 'accountsManager',
            render : ({ accountsManager, index }) => <Checkbox checked={accountsManager} name='accounts-manager' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'Task Management Manager', field: 'taskManagementManager',
            render : ({ taskManagementManager, index }) => <Checkbox checked={taskManagementManager} name='task-management-manager' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'ESS Manager', field: 'essManager',
            render : ({ essManager, index }) => <Checkbox checked={essManager} name='employee-self-services-manager' onChange={(e) => handleChange(e, index)} />
        },
        { title: 'Action', field: 'action',
            render : ({  index }) => <Button onClick={() => handleModulesUpdate(index)} color='primary'>Update</Button>
        },
    ]

    console.log(state.names)

    const data =responseData.map((moduleData, index) => {
        return{
            email : moduleData.email,
            employee : validate.emailToName(moduleData.email),
            imageURL : validate.getImage(moduleData.email),
            empCode : state.names.filter(item => item.uid === moduleData.email)[0].companyID,
            wiki : moduleData.accesibles.includes('wiki'),
            timesheets : moduleData.accesibles.includes('timesheets'),
            immigration : moduleData.accesibles.includes('immigration'),
            taskManagement : moduleData.accesibles.includes('task-management'),
            ess : moduleData.accesibles.includes('employee-self-services'),
            discussions : moduleData.accesibles.includes('discussions'),
            employeesManager : moduleData.accesibles.includes('employees-manager'),
            consoleCustomization : moduleData.accesibles.includes('console-customization'),
            wikiManager : moduleData.accesibles.includes('wiki-manager'),
            timesheetsManager : moduleData.accesibles.includes('timesheets-manager'),
            immigrationManager : moduleData.accesibles.includes('immigration-manager'),
            accountsManager: moduleData.accesibles.includes('accounts-manager'),
            accounts: moduleData.accesibles.includes('accounts'),
            taskManagementManager : moduleData.accesibles.includes('task-management-manager'),
            essManager : moduleData.accesibles.includes('employee-self-services-manager'),
            index : index
        }
    }) 
    return (
        <div className={classes.table} >
            <MaterialTable
                title=""
                data={data}
                isLoading={showLoader}
                columns={columns}
                options={{
                    paginationType: "stepped",
                    pageSize: 10
                }}
            />
        </div>
    )
}

export default Presentation
