import React, { useContext } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip, Fab, Grid, Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { DataContext } from '../../../contexts/data'
import TaskManager from '../Task/TaskManager'
import SettingsTab from '../projectHandleComponents/Settings'
import { Settings, Label } from '@material-ui/icons'
import Alert from '@material-ui/lab/Alert'
import Validations from '../../generalcomponents/validation'
import { Link } from 'react-router-dom'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 224,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'visible',
  },
}))

const useSwipableStyles = makeStyles({
  list: {
    width: 'auto',
  },
  fullList: {
    width: 'auto',
  },
})

export default function Presentation(props) {
  const classes = useStyles()
  const swipableClasses = useSwipableStyles()
  const [value, setValue] = React.useState(0)
  const [swipableState, setSwipableState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setSwipableState({ ...swipableState, [anchor]: open })
  }

  const [state] = useContext(DataContext)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const validate = new Validations()

  let employee = {}
  let access = false
  const id = state.project.id
  let supervisorCount = 0
  try {
    const employees = state.projectList.filter(
      (project) => project.id === id
    )[0].Users
    employee = employees.filter((user) => user.uid === state.employee.email)[0]
    if (employees.map((user) => user.uid).includes(state.employee.email))
      access = true
    else if (
      state.projectList.filter((project) => project.id === id)[0].createdBy ===
      state.employee.email
    )
      access = true
  } catch (error) {
    employee = {}
    access = false
  }
  //  checking supervisor count
  try {
    const employees = state.projectList.filter(
      (project) => project.id === id
    )[0].Users
    employees.forEach((member) => {
      if (validate.checkSupervisor(member.uid)) supervisorCount++
    })
  } catch (error) {}

  console.log(employee)

  const list = (anchor) => (
    <div
      className={clsx(swipableClasses.list, {
        [swipableClasses.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <SettingsTab
        projectId={props.projectId}
        handleComponentUpdate={props.handleComponentUpdate}
      />
    </div>
  )

  if (
    state.employee.role === 'admin' ||
    (access && (employee.create || employee.update || employee.read))
  )
    return (
      <div>
        <div style={{ marginLeft: '10px' }}>
          <Paper>
            <div className='d-flex justify-content-between'>
              <div>
                <h1 style={{ padding: '20px', textTransform: 'uppercase' }}>
                  {state.project.title}{' '}
                </h1>
              </div>
              <div>
                {state.access_modules.includes('task-management-manager') ||
                state.access_modules.includes('console-customization') ? (
                  <Grid item xs={2} style={{ padding: '20px', align: 'right' }}>
                    {['right'].map((anchor) => (
                      <React.Fragment key={anchor}>
                        <Tooltip title='Project Settings'>
                          <Link
                            onClick={toggleDrawer(anchor, true)}
                            style={{ color: 'white' }}
                          >
                            <Fab
                              size='medium'
                              className='ml-2'
                              color='secondary'
                            >
                              <Settings />
                            </Fab>
                          </Link>
                        </Tooltip>

                        <SwipeableDrawer
                          anchor={anchor}
                          open={swipableState[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          onOpen={toggleDrawer(anchor, true)}
                        >
                          {list(anchor)}
                          {/* {
                                    state.project.useTimeline && 
                                      <Timeline />
                                    } */}
                        </SwipeableDrawer>
                      </React.Fragment>
                    ))}
                  </Grid>
                ) : null}
              </div>
            </div>
            {supervisorCount < 2 ? (
              <TaskManager />
            ) : (
              <Alert
                className='text-center'
                variant='filled'
                severity='warning'
              >
                This project contain multiple Supervisors, Please contact
                Admin/Manager.
              </Alert>
            )}
          </Paper>
        </div>
        {/* <div className={classes.root}> 
                {
                  state.access_modules.includes('task-management-manager') || state.access_modules.includes('console-customization')? 
                  <React.Fragment>
                      <SettingsTab projectId={props.projectId} handleComponentUpdate={props.handleComponentUpdate} />
                    {
                      state.project.useTimeline && 
                        <Timeline />
                    }
                  </React.Fragment>
                  :
                    null
                }

          </div> */}
      </div>
    )
  return (
    <Alert className='text-center' variant='filled' severity='error'>
      You are unauthorized to view this content
    </Alert>
  )
}
