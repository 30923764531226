import React from 'react'
import ModuleAccess from '../components/Console/ModuleLevelAccess'
function ModuleAccessPage() {
    return (
        <div>
            <ModuleAccess />
        </div>
    )
}

export default ModuleAccessPage
