import React, { PureComponent } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../contexts/data'
import { PLACEMENT_SETTINGS_ACTIONS } from '../../../../../contexts/types'
import firebase from '../../../../../firebase_config'
import CircularSpinner from '../../../../generalcomponents/circularSpinner'

class Container extends PureComponent {
  state = {
    timesheetCycle: '',
    startDay: '',
    approvalBy: '',
    attachMandatory: false,
    enableTask: false,
    makeMandatory: false,
    linkToProject: '',
    profile: {},
    id: '',
    approvals: [],
    fillableSections: [],
    cycle: [],
    startDate: '',
  }

  static contextType = DataContext

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  handleCheck = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    })
  }

  getTimeSheetManagers = (modules) => {
    let req_modules = []
    modules.length &&
      modules.forEach((employee) => {
        if (
          employee.accesibles.includes('console-customization') ||
          employee.accesibles.includes('timesheets-manager')
        ) {
          req_modules.push(employee)
        }
      })
    this.setState({ approvals: req_modules })
  }

  getData = (id) => {
    firebase
      .firestore()
      .collection('Placements')
      .doc(id)
      .collection('Timesheets')
      .get()
      .then((snap) => {
        let placementData = []
        placementData = snap.docs.map((doc) => doc.data())
        // console.log(placementData[0].cycle[placementData[0].cycle.length - 1])
        if (placementData.length) {
          this.setState({
            cycle: placementData[0].cycle,
            approvalBy: placementData[0].approvalBy,
            attachMandatory: placementData[0].attachMandatory,
            enableTask: placementData[0].enableTask,
            makeMandatory: placementData[0].makeMandatory,
            linkToProject: placementData[0].linkToProject,
          })
          try {
            const reqCycle = placementData[0].cycle.reverse()[0]
            console.log(placementData[0].cycle, reqCycle)
            const { date, range, startDay } = reqCycle
            this.setState({ timesheetCycle: range.toString(), startDay })
            this.props.handleChange('index', range)
          } catch (error) {
            console.error(error)
          }
        }
      })
  }

  componentDidMount = () => {
    const { modules, req_placement } = this.props
    this.getTimeSheetManagers(modules)
    if (req_placement.id) this.getData(req_placement.id)
  }

  componentWillReceiveProps = (nextProps) => {
    const [state] = this.context
    const { req_placement, profile, modules } = nextProps
    const { id, fillableSections, startDate } = req_placement
    this.getTimeSheetManagers(modules)
    this.setState({ profile, id, fillableSections, startDate })
    if (id.length && this.props.req_placement.id !== id ) this.getData(id)
  }

  // cycle = [{ range: , startDay: , date: }]

  placementSettingsActionsWiki = () => {}

  handleCreate = () => {
    const dispatch = this.context[1]
    const {
      timesheetCycle,
      startDay,
      approvalBy,
      attachMandatory,
      enableTask,
      linkToProject,
      makeMandatory,
      profile,
      startDate,
      id,
    } = this.state
    const { handleChange } = this.props
    handleChange('index', timesheetCycle)
    let payload = {
      approvalBy,
      attachMandatory,
      cycle: [
        {
          range: parseInt(timesheetCycle),
          startDay,
          date: startDate,
        },
      ],
      enableTask,
      linkToProject,
      makeMandatory,
      placementID: id,
      sectionName: 'timesheets',
    }
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
      payload: payload,
      actionType: 'new',
    })
  }

  handleUpdate = () => {
    const dispatch = this.context[1]
    const {
      timesheetCycle,
      startDay,
      approvalBy,
      cycle,
      attachMandatory,
      enableTask,
      linkToProject,
      makeMandatory,
      id,
    } = this.state
    console.log(linkToProject, approvalBy)
    const { handleChange } = this.props
    let today = new Date()
    today.setHours(0, 0, 0, 0)
    handleChange('index', timesheetCycle)
    let data = cycle
    data = [
      ...data,
      { range: parseInt(timesheetCycle), startDay, date: today.toISOString() },
    ]
    this.setState({ cycle: data })
    console.log(data)
    let payload = {
      approvalBy,
      attachMandatory,
      cycle: data,
      enableTask,
      linkToProject,
      makeMandatory,
      placementID: id,
      sectionName: 'timesheets',
    }
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      payload: payload,
      actionType: 'update',
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
    })
  }

  handleSave = (type) => {
    if (type === 'create') this.handleCreate()
    else if (type === 'update') this.handleUpdate()
    else console.log('Error')
  }

  render() {
    const [state] = this.context
    const { linkToProject, approvalBy, startDay } = this.state
    console.log(linkToProject, approvalBy, startDay)
    if (state.hasOwnProperty('projectList'))
      return (
        <div>
          <Presentation
            {...this.state}
            handleChange={this.handleChange}
            handleSave={this.handleSave}
            handleCheck={this.handleCheck}
            handleIndex={this.props.handleChange}
          />
        </div>
      )
    return <CircularSpinner />
  }
}

export default Container
