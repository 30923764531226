import React, { useContext } from 'react'
import {
  Tabs,
  Tab,
  Grid,
  IconButton,
  Box,
  Typography,
  Button,
} from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import Close from '@material-ui/icons/Close'
import Form from '../Form'
import PropTypes from 'prop-types'
import { DataContext } from '../../../../../../contexts/data'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function Presentation(props) {
  const {
    handleTabChange,
    tabList,
    deleteTab,
    addTab,
    value,
    handleChange,
    handleFinish,
    docId,
    fillableSections,
    handleUpdate,
    billableClient,
  } = props
  console.log(billableClient)
  const [state] = useContext(DataContext)
  const getNameById = (id) => {
    try {
      return state.client_list.filter((client) => client.id === id)[0]
        .businessDisplayName
    } catch (error) {
      console.log(error)
      return id
    }
  }
  return (
    <div style={{ flex: 1, width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <Tabs value={value} onChange={handleTabChange} variant='scrollable'>
            {tabList.map((tab) => (
              <Tab
                key={tab.key.toString()}
                value={tab.id}
                label={
                  <div>
                    {tab.client ? getNameById(tab.client) : 'Layer ' + tab.id}
                    {tab.key !== 0 ? (
                      <IconButton
                        id={tab.id}
                        onClick={deleteTab}
                        color='inherit'
                      >
                        <Close fontSize='small' />
                      </IconButton>
                    ) : null}
                  </div>
                }
              />
            ))}
          </Tabs>
          {tabList.map((item, index) => (
            <TabPanel value={value} index={item.id}>
              <Form
                key={item.key.toString()}
                item={item}
                index={index}
                tabList={tabList}
                handleChange={handleChange}
                docId={docId}
                billableClient={item.key === 0 ? billableClient : ''}
              />
            </TabPanel>
          ))}
        </Grid>
        <Grid xs={1}>
          <IconButton color='inherit' onClick={addTab}>
            <Add fontSize='small' />
          </IconButton>
        </Grid>
      </Grid>
      <div>
        {fillableSections.includes('client-details') ? (
          <Button onClick={handleFinish} color='primary' variant='contained'>
            Done
          </Button>
        ) : (
          <Button onClick={handleUpdate} color='secondary' variant='contained'>
            Update
          </Button>
        )}
      </div>
    </div>
  )
}

export default Presentation
