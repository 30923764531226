import React, { Component } from 'react'
import Presentation from './Presentation'
class Container extends Component {
    render() {
        return (
            <div>
                <Presentation/>
            </div>
        )
    }
}

export default Container
