import React, { Component } from 'react'
import Presentation from './Presentation'
export class Container extends Component {
    state = {
        profile:{},
        personalFilled:0,
    }

    componentDidMount(){
        const profile = this.props.profile
        this.setState({profile:profile})

       
    }
    render() {
        const profile = this.props.profile
        let totalFields = 0
        totalFields =  46
        console.log(totalFields)
        const percentageCount = {
            personal : 20,
            emergencycontact:20,
            employment:20,
            workauth:20,
            edu:20
        }

        let unFilledFields = 0
        let fillableSectionsTypeObj = ['personal', 'mailingaddress']
        let fillableSectionsTypeArr = ['emergencycontact', 'employmenthistory', 'workauth', 'educationhistory']
        const personalFields = 16
        const mailingFields = 6
        const employmenthistoryFields = 8
        const emergencycontactFields = 3
        const educationhistoryFields = 8
        const workauthFields = 5

        fillableSectionsTypeObj.forEach(item => {
            Object.entries(profile[item]).forEach(([key, value]) => {
                if(key !=="isSupervisor" && !value){
                    console.log(key)
                    unFilledFields ++
                }
            })
        })

        fillableSectionsTypeArr.forEach(item => {
            try {
                if(item.length){
                    Object.entries(profile[item][0]).forEach(([key, value]) => {
                        if(!value){
                            console.log(key)
                            unFilledFields ++
                        }
                    })
                }else{
                    if(item === 'emergencycontact')
                        unFilledFields += emergencycontactFields
                    else if(item === "employmenthistory")
                        unFilledFields += employmenthistoryFields
                    else if(item === "workauth")
                        unFilledFields += workauthFields
                    else if(item === "educationhistory")
                        unFilledFields += educationhistoryFields
                }
            } catch (error) {
                if(item === 'emergencycontact')
                    unFilledFields += emergencycontactFields
                else if(item === "employmenthistory")
                    unFilledFields += employmenthistoryFields
                else if(item === "workauth")
                    unFilledFields += workauthFields
                else if(item === "educationhistory")
                    unFilledFields += educationhistoryFields
            }
            
        })
        
        return (
            <div>
                <Presentation 
                    percentageCount={percentageCount}
                    totalFields = {totalFields}
                    unFilledFields = {unFilledFields}
                />
            </div>
        )
    }
}

export default Container
