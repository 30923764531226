import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../contexts/data'
import { UPDATE_TASK } from '../../../../../contexts/types'
class Container extends Component {
    state = {
        task: {},
        title:"",
        type:"",
        status:"",
        startdate:"",
        enddate:"",
        priority:"",
        labels: [],
        assignee:[],
        description:"",
        projectAssignee : [],
        setReminder : false,
        reminderDate : ""
    }

    static contextType = DataContext

    handleChange = (key, value) => {
        console.log(value)
        if(key === "setReminder" && value === true){
            this.setState({
                status : "Closed",
                setReminder : true
            })
        }
        else if(key === "setReminder" && value === false){
            this.setState({
                status : "",
                setReminder : false
            })
        }
        else if(key === "reminderDate" && !isNaN(Date.parse(value))){
            this.setState({
                reminderDate : new Date(value).toISOString()
            })
        }
        else if(key === "reminderDate" && isNaN(Date.parse(value))){
            this.setState({
                reminderDate : ""
            })
        }
        else if(key !== "reminderDate")
            this.setState({
                [key]:value
            })
    }

    UNSAFE_componentWillMount = () => {
        const [ state ] = this.context
        this.setState({projectAssignee : state.project.Users.map(user => user.uid)})
        const task = state.taskList.filter(task => task.id === this.props.taskId)[0]
        this.handleSetTask(task)
    }


    handleSetTask = (task) => {
        const [ state ] = this.context
        this.setState({task})
        let labels = state.projectList.filter(project => project.id === state.project.id)[0].labels
        let taskLabels = labels.filter(item => task.labels.includes(item.id))
        Object.entries(task).forEach(([key, value]) => {
            if(key === 'labels') {
                this.setState({
                    labels: taskLabels
                })
            } else {
                this.setState({
                    [key] : value
                })
            }
        })
    }



    handleDateChange = (key, value) => {
        if(!isNaN(Date.parse(value))) 
        this.setState({
            [key]:value.toISOString()
        })
    }

    handleUpdate = (e) => {
        e.preventDefault()
        const { title, type, status, startdate, enddate, priority, assignee, description, id ,labels, setReminder, reminderDate} = this.state
        // return console.log(this.state)
        console.log(title, type, startdate)
        const dispatch = this.context[1]
            dispatch({
                type:UPDATE_TASK,
                payload: {
                        title,
                        type,
                        status,
                        startdate,
                        enddate,
                        priority,
                        labels: labels.map(item => item.id),
                        assignee,
                        description,
                        id,
                        setReminder,
                        reminderDate
                    },
                category : this.state.category    
            })

      
    }



    handleDropdown = (key,value) => {
        this.setState({[key] : value})
    }

    componentWillUnmount = () => {
        this.setState({
            title:"",
            type:"",
            status:"",
            startdate:"",
            enddate:"",
            priority:"",
            label:"",
            assignee:[],
            description:"",
            projectAssignee : [],
            isCreated : false,
            setReminder : false,
            reminderDate : ""
        })
    }

    render() {
        let newTask = {}
            newTask = {
            title : this.state.title,
            type : this.state.type,
            status : this.state.status,
            startdate :this.state.startdate ? (new Date(this.state.startdate)).toISOString() : this.state.startdate,
            enddate : this.state.enddate ? (new Date(this.state.enddate)).toISOString() : this.state.enddate,
            priority : this.state.priority,
            assignee : this.state.assignee,
            setReminder: this.state.setReminder,
            reminderDate: this.state.reminderDate
        }
        console.log(newTask)
        let errCount = 0
        Object.entries(newTask).forEach(([key, value]) => {
            if((key === "startdate" && isNaN(Date.parse(value))) || (key === "enddate" && isNaN(Date.parse(value))))
                errCount++;
            else if(key === "assignee" && value.length === 0)
                errCount++;   
            else if(key === "reminderDate" && this.state.setReminder && isNaN(Date.parse(value)))  
                errCount++;   
            else if(typeof(value) === "string" && key !== "reminderDate" && value.trim() === "")
                errCount++;
            console.log(key, value)         
        })
        console.log(errCount)
        const [ state ] = this.context
        const id = state.project.id
        const employees = state.projectList.filter(project => project.id === id)[0].Users
        console.log(employees)
        return (
            <div>
                {
                    state.employee.role === 'admin' || state.employee.role === 'manager' || employees.filter(user => user.uid === state.employee.email)[0].create || employees.filter(user => user.uid === state.employee.email)[0].update ? 
                        <Presentation
                            {...this.state}
                            errCount = {errCount}
                            text={this.props.text}
                            handleUpdate = {this.handleUpdate}
                            handleDateChange = {this.handleDateChange}
                            handleChange = {this.handleChange}
                            handleDropdown = {this.handleDropdown}
                            handleSetTask={this.handleSetTask}
                            {...this.props}

                        />
                    :
                        null
                }
                
            </div>
        )
    }
}

export default Container
