import React from 'react'
import InviteEmployee from '../components/Employee/InviteEmployee/Index'
function InviteEmployeePage() {
    return (
        <div>
            <InviteEmployee />
        </div>
    )
}

export default InviteEmployeePage
