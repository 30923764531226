import React from "react";
import {
  Typography,
  TextField,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  IconButton,
  Tooltip
} from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

export function CalcGrandTotal(discountDetails, subTotal){
  return (discountDetails.reduce((initial, item) => {
            if(item.type === "byValue"){
              return initial - Number(item.value)
            }else if(item.type === "byPercentage" && Number(item.value) > 0) {
              return initial - Number((subTotal * (Number(item.value))/100))
            }else{
              return initial - 0
            }
          }, 0) + subTotal).toFixed(2)
}

function Presentation(props) {
  const { subTotal, handleChange, discountDetails, invoiceBy, externalAmountName } = props;
  console.log(subTotal)
  return (
    <div className="d-flex flex-column">
      <List dense>
        <ListItem>
          <ListItemText>
            {
              invoiceBy === "external" ? 

                  <div className="d-flex flex-row">
                  <div className="mr-1">
                    <TextField
                      style={{ width: "130px" }}
                      size="small"
                      variant="outlined"
                      label="Name"
                      onChange={(e) => handleChange("externalAmountName", e.target.value)}
                      value={externalAmountName}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="mr-1">
                    <TextField
                      style={{ width: "100px" }}
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Amount"
                      value={subTotal}
                      onChange={(e) => {
                        handleChange("subTotal", parseInt(e.target.value))
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                  :
                  <h2 style={{fontWeight: 500}}>Total</h2>
            }
          </ListItemText>
          <ListItemSecondaryAction>
            <h2 style={{fontWeight: 500}}>${subTotal}</h2>
          </ListItemSecondaryAction>
        </ListItem>
        {
          discountDetails.map((item, index) => {
            return(
              <ListItem>
                <ListItemIcon>
                  <div className="d-flex flex-row">
                    <div className="mr-1">
                      <TextField
                        style={{ width: "70px" }}
                        size="small"
                        variant="outlined"
                        value={item["name"]}
                        onChange={(e) => {
                          discountDetails[index]["name"] = e.target.value;
                          handleChange("discountDetails", discountDetails);
                        }}
                        label="Name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="mr-1 mb-1">
                      <TextField
                        style={{ width: "130px" }}
                        size="small"
                        label="Select"
                        variant="outlined"
                        value={item["type"]}
                        onChange={(e) => {
                          discountDetails[index]["type"] = e.target.value;
                          handleChange("discountDetails", discountDetails);
                        }}
                        select
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <MenuItem value="byValue">By Value</MenuItem>
                        <MenuItem value="byPercentage">By Percentage</MenuItem>
                      </TextField>
                    </div>
                    <div className="mr-1">
                      <TextField
                        style={{ width: "70px" }}
                        size="small"
                        type="number"
                        variant="outlined"
                        value={item["value"]}
                        onChange={(e) => {
                          discountDetails[index]["value"] = e.target.value;
                          handleChange("discountDetails", discountDetails);
                        }}
                        label="Discount"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    {
                      discountDetails.length > 1 ?
                      <div className="mr-1" >
                        <Tooltip title="Remove this discount" >
                          <IconButton onClick={() => {
                            handleChange("discountDetails", [...discountDetails].filter((item, i) => index !== i))
                          }} >
                            <RemoveCircleOutlineIcon className="text-danger" />
                          </IconButton>
                        </Tooltip>
                      </div>
                      :
                      null
                    }
                    {
                      index === discountDetails.length - 1 ?
                      <div className="mr-1" >
                        <Tooltip title="Add multiple discounts" >
                          <IconButton disabled={CalcGrandTotal(discountDetails, subTotal) < 0} onClick={() => {
                            handleChange("discountDetails", [...discountDetails, {
                              name: "",
                              value: 0,
                              type: "",
                            }])
                          }} >
                            <AddCircleOutlineIcon color={CalcGrandTotal(discountDetails, subTotal) < 0 ? "default" : "primary"} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      :
                      null
                    }
                    
                  </div>
                </ListItemIcon>
                <ListItemSecondaryAction>
                  <h2 style={{fontWeight: 500}}>
                    $
                    {discountDetails[index]["type"] === "byValue"
                      ? !isNaN(parseInt(discountDetails[index]["value"])) ? parseInt(discountDetails[index]["value"]).toFixed(2) : 0.00
                      : discountDetails[index]["type"] === "byPercentage"
                      ? (subTotal * (discountDetails[index]["value"] / 100)).toFixed(2)
                      : "0.00"}
                  </h2>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })
        }
        
        {/* <ListItem>
                    <ListItemText primary={`Discounts Total`} />
                    <ListItemSecondaryAction>
                        {
                            discountDetails['type'] === "byValue" ?
                                subTotal - discountDetails['value']
                            :
                                discountDetails['type'] === "byPercentage" ?
                                    (subTotal - subTotal*(discountDetails['value']/100)).toFixed(1)
                                :
                                    "0"
                        }
                        $
                    </ListItemSecondaryAction>
                </ListItem> */}
        <ListItem>
          <ListItemText>
            <h2 style={{fontWeight: 500}}>Grand Total</h2>{" "}
          </ListItemText>
          <ListItemSecondaryAction>
            <h2 style={{fontWeight: 500}}>
              ${CalcGrandTotal(discountDetails, subTotal)}
            </h2>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
}

export default Presentation;
