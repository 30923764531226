import React from 'react'
import ClientList from '../components/TimeSheets/Clients/ClientsList'
function ClientListPage() {
    return (
        <div>
            <ClientList />
        </div>
    )
}

export default ClientListPage
