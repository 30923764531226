import React from 'react'
import Alert from '@material-ui/lab/Alert';

function comingSoon(props) {
    return (
        <div>
            <Alert className="text-center" variant="filled" severity="warning"><h3>This Module is coming soon.</h3></Alert>
        </div>
    )
}

export default comingSoon
