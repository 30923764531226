import React, { useContext } from 'react'
import {
  Grid,
  Button,
  TextField,
  makeStyles,
  MenuItem,
  IconButton,
  Typography,
  Box,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import SunEditor from 'suneditor-react'
import Validations from '../../generalcomponents/validation'
import NumberFormat from 'react-number-format'
import MaterialTable from 'material-table'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import parser from 'html-react-parser'
import CurrencyFormat from 'react-currency-format'
import { DataContext } from '../../../contexts/data'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalScale={2}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      isNumericString
    />
  )
}

function CustomCurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      prefix='$'
    />
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(10),
  },
}))

function Presentation(props) {
  const {
    advanceType,
    givendate,
    chequeNumber,
    amount,
    $,
    deductPer,
    effectivefrom,
    notes,
    deductions,
    update,
    listAll,
  } = props
  const {
    handleChange,
    handleDateChange,
    handleData,
    handleSave,
    onPressEdit,
  } = props
  const advanceList = ['Advance', 'Premium', 'Miscellaneous ']
  const deductList = ['Per Paycheque', 'Per Bi-Paycheque']
  const handleGivenDateChange = (date) => {
    handleDateChange('givendate', date)
  }
  const handleEffectiveFromDateChange = (date) => {
    handleDateChange('effectivefrom', date)
  }
  const validate = new Validations()
  const [state] = useContext(DataContext)
  const columns = [
    {
      title: 'Advance',
      field: 'advance',
      render: (rowData) => (
        <span>
          <CurrencyFormat
            value={rowData.advance}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => value}
          />
        </span>
      ),
    },
    {
      title: 'Remaining Balance',
      field: 'remainingBalance',
      render: (rowData) => (
        <span>
          <CurrencyFormat
            value={rowData.remainingBalance}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => value}
          />
        </span>
      ),
    },
    {
      title: 'Cheque Number',
      field: 'chequeNumber',
      render: (rowData) => <span>{rowData.chequeNumber}</span>,
    },
    {
      title: 'Given Date',
      field: 'givendate',
      render: (rowData) => <span>{rowData.givendate}</span>,
    },
    {
      title: 'Deduct Per',
      field: 'deductPer',
      render: (rowData) => <span>{rowData.deductPer}</span>,
    },
    {
      title: 'Amount',
      field: 'amount',
      render: (rowData) => (
        <span>
          <CurrencyFormat
            value={rowData.amount}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => value}
          />
        </span>
      ),
    },
    {
      title: 'Deduct Effective',
      field: 'effectivefrom',
      render: (rowData) => <span>{rowData.effectivefrom}</span>,
    },
    {
      title: 'Notes',
      field: 'notes',
      render: (rowData) => <span>{parser(rowData.notes)}</span>,
    },
    {
      title: 'Actions',
      field: '',
      render: (rowData) => (
        <span>
          <IconButton onClick={() => onPressEdit(rowData)}>
            <EditIcon fontSize='small' color='inherit' />
          </IconButton>
        </span>
      ),
    },
  ]
  let data = []
  deductions &&
    deductions.forEach((deduction, index) => {
      data.push({
        advanceType: deduction.advanceType,
        givendate: validate.dateFormatter(deduction.givendate),
        chequeNumber: deduction.chequeNumber,
        amount: deduction.amountTobeDeducted,
        deductPer: deduction.deductPer,
        effectivefrom: validate.dateFormatter(deduction.effectivefrom),
        notes: deduction.notes,
        index: index,
        advance: deduction.amount,
        remainingBalance: new Date() > new Date(deduction.effectivefrom) ? (parseInt(deduction.amount) - parseInt(deduction.amountTobeDeducted)) : '',
      })
    })
  const classes = useStyles()
  return (
    <div>
      {(state.access_modules.includes('accounts-manager') || state.access_modules.includes('console-customization')) && !listAll ? (
        <div className='custom-card'>
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  select
                  fullWidth
                  label='Advance Type'
                  name='advanceType'
                  value={advanceType}
                  onChange={handleChange}
                  size='small'
                >
                  {advanceList.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label='Cheque Number'
                  name='chequeNumber'
                  value={chequeNumber}
                  onChange={handleChange}
                  variant='outlined'
                  size='small'
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label='Total Amount'
                  name='amount'
                  value={amount}
                  onChange={handleChange}
                  variant='outlined'
                  size='small'
                  InputProps={{
                    inputComponent: CustomCurrencyFormat,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <TextField
                      select
                      fullWidth
                      label='Deduct per'
                      name='deductPer'
                      value={deductPer}
                      onChange={handleChange}
                      size='small'
                    >
                      {deductList.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      label='$'
                      name='$'
                      value={$}
                      onChange={handleChange}
                      variant='standard'
                      size='small'
                      InputProps={{
                        inputComponent: CustomCurrencyFormat,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className='d-flex'>
                    <KeyboardDatePicker
                      required
                      size='small'
                      label='Advance Given date'
                      format='MM/dd/yyyy'
                      value={givendate ? givendate : null}
                      onChange={handleGivenDateChange}
                      name='givendate'
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    <KeyboardDatePicker
                      required
                      style={{ marginLeft: '10px' }}
                      size='small'
                      label='Deduct Effective from'
                      format='MM/dd/yyyy'
                      value={effectivefrom ? effectivefrom : null}
                      onChange={handleEffectiveFromDateChange}
                      name='effectivefrom'
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <SunEditor
                  placeholder='Notes'
                  setContents={notes}
                  onChange={(data) => handleData(data)}
                  setOptions={{
                    buttonList: [
                      [
                        'undo',
                        'redo',
                        'font',
                        'fontSize',
                        'formatBlock',
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'subscript',
                        'superscript',
                        'fontColor',
                        'hiliteColor',
                        'removeFormat',
                        'outdent',
                        'indent',
                        'align',
                        'horizontalRule',
                        'list',
                        'lineHeight',
                        'table',
                        'link',
                        'image',
                        'video',
                        'showBlocks',
                        'codeView',
                      ],
                    ],
                    mode: 'Balloon-always',
                  }}
                />
              </Grid>
            </Grid>
          </form>
          <br />
          <div className='text-center'>
            {update ? (
              <span>
                <Button
                  variant='contained'
                  type='submit'
                  color='secondary'
                  onClick={() => handleSave('update')}
                >
                  Update
                </Button>{' '}
                <Button variant='contained' color='inherit' onClick={() => {}}>
                  Cancel
                </Button>
              </span>
            ) : (
              <span>
                <Button
                  variant='contained'
                  type='submit'
                  color='primary'
                  onClick={() => handleSave('new')}
                >
                  Add Entry
                </Button>{' '}
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => {}}
                >
                  Cancel
                </Button>
              </span>
            )}
          </div>
        </div>
      ) : null}
      {state.access_modules.includes('accounts') || state.access_modules.includes('console-customization') ? (
        <div
          className={
            listAll || !state.access_modules.includes('accounts-manager') || !state.access_modules.includes('console-customization')
              ? ''
              : classes.marginTop
          }
        >
          <MaterialTable
            title=''
            columns={
              !state.access_modules.includes('accounts-manager') || !state.access_modules.includes('console-customization')
                ? columns.filter((column) => column.title !== 'Actions')
                : columns
            }
            data={data}
            options={{
              pageSize: listAll ? 20 : (state.access_modules.includes('accounts-manager') || state.access_modules.includes('console-customization') ? 5 : 10),
              paginationType: 'stepped',
            }}
          />
        </div>
      ) : null}
    </div>
  )
}

export default Presentation
