import React from 'react'
import EmailTemplates from '../components/Templates/EmailTemplates/Index'

function EmailTemplatesPage(props) {
  return (
    <div>
      <EmailTemplates />
    </div>
  )
}

export default EmailTemplatesPage
