import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {DataContext} from '../../../../contexts/data'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import AddNewLabel from '../Index/newLabel'
import InviteDialog from '../../../Employee/InviteEmployee/InviteDialog'
import { textField, phoneField, emailField, selectField, addressFields, fromDateFields, toDateFields, alphaNumFields, zipField, dateField, geoField } from '../../../generalcomponents/formFields'
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
    },
    column: {
      flexBasis: '33.33%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }));
  
function Presentation(props) {
    const classes = useStyles();
    const { handleChange, data, handleCheck, handleNewLabel, handleNewLabelChange, handleNewLabelCancel, handleLabelsSave, geoState } = props
    const [ state ] = useContext(DataContext)
    let formData = data.formData
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <div className={classes.root}>
                        <ExpansionPanel defaultExpanded>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1c-content"
                            >
                            <div>
                                <Typography variant="h4" className={classes.heading}>Customize form</Typography>
                            </div>
                            </ExpansionPanelSummary>
                                <div className="text-center" >
                                    <AddNewLabel 
                                        handleNewLabel={handleNewLabel}
                                        handleNewLabelChange={handleNewLabelChange}
                                        newLabelName={data.newLabelName}
                                        newLabelSection={data.newLabelSection}
                                        handleNewLabelCancel={handleNewLabelCancel}
                                        newLabelType={data.newLabelType}
                                    />
                                </div>
                            <ExpansionPanelDetails className={classes.details}>
                                <div>
                                    {
                                        formData.map(portion => {
                                            return(
                                                <div style={{padding:'6px',margin:'3px'}} >
                                                    <h6><u>{portion.heading}</u></h6>
                                                    <Grid container spacing={3}> 
                                                    <FormGroup row>
                                                    {
                                                        portion['fields'].map(item => {
                                                            if(item.customize)
                                                                return (
                                                                    <Grid xs={12} >
                                                                        <FormControlLabel
                                                                            control={<Checkbox checked={item.isExist} onChange={(e) => handleCheck(item.name,e.target.checked,portion.section,portion.index,)} name={item.name} />}
                                                                            label={item.label}
                                                                        />
                                                                    </Grid>
                                                                )
                                                            return null    
                                                        })
                                                    }
                                                    </FormGroup>
                                                    </Grid>
                                                    <br/><br/>
                                                </div>
                                                
                                            )
                                            
                                        })
                                    }
                                </div>
                            </ExpansionPanelDetails>
                            <Divider />
                            <ExpansionPanelActions>
                            <InviteDialog btnText="Save labels" fullWidth={true} template={true} heading="Your template saved successfully!" text="Invite new employee from the template saved now" handleLabelsSave={handleLabelsSave} />
                            
                            </ExpansionPanelActions>
                        </ExpansionPanel>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                        <Paper style={{padding:'15px'}} >
                        {
                        formData.map(portion => {
                            return(
                                <div>
                                    <h3><u>{portion.heading}</u></h3>
                                    <Grid container spacing={3}>
                                        {
                                            portion['fields'].map(item => {
                                                if(item.isExist){
                                                    if(item.type === "text")
                                                        return textField(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section)
                                                    else if(item.type === "email")
                                                        return emailField(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section)
                                                    else if(item.type === "number")
                                                        return phoneField(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section)
                                                    else if(item.type === "zip")
                                                        return zipField(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section)
                                                    else if(item.type === "select")
                                                        return selectField(data[portion['section']], item.label, item.name, item.required, item.values, handleChange, portion.section)
                                                    else if(item.type === "address")
                                                        return addressFields(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section) 
                                                    else if(item.type === "fromdate")
                                                        return fromDateFields(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section, item.maxDate)
                                                    else if(item.type === "todate")
                                                        return toDateFields(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section, item.minDate)
                                                    else if(item.type === "date")
                                                        return dateField(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section)
                                                    else if(item.type === "alphanum"){
                                                        return alphaNumFields(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section)
                                                    } 
                                                    else if(item.type === "geo"){
                                                        if(portion.section === "mailingaddress" && item.name === "country"){
                                                            const countries = state.hasOwnProperty('geo_data') ? state.geo_data.countries : []
                                                            return geoField(data[portion['section']], item.label, item.name, item.required, countries, geoState, handleChange, portion.section)
                                                        }
                                                        return geoField(data[portion['section']], item.label, item.name, item.required, item.values, handleChange, handleChange, portion.section)
                                                    }
                                                }
                                                return null
                                            })
                                        }
                                    </Grid>
                                    <br/><br/>
                                </div>
                                
                            )
                            
                        })
                    }
                    </Paper>
                </Grid> 
            </Grid>  
        </div>
    )
}

export default Presentation
