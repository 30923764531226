import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AllTemplates from '../AllTemplates'
import CreateTemplate from '../CreateTemplate'
import { Grid } from '@material-ui/core'
import { configuration } from '../../../../configuration'
import { DataContext } from '../../../../contexts/data'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'visible'
  },
  grid: {
    padding: theme.spacing(10)
  },
}));

export default function Presentation(props) {
  const classes = useStyles();
  
  const [ state ] = useContext(DataContext)
  const isUpdating = state.hasOwnProperty('letter_content') && state.letter_content.updating
  const { letters, handleChange, value } = props
  return (
    <div className={classes.root}>
      {
        isUpdating ? null : 
        <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab id="template_panel_0" label="Templates" />
        <Tab id="template_panel_1" label="Create Template" />
        </Tabs>
      }
      <TabPanel value={value} index={0}>
        <AllTemplates letters={letters} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container xs={12} sm={12}>
          <Grid item sm={9}>
            <React.Fragment>
              <CreateTemplate letters={letters} />
            </React.Fragment>
          </Grid>
          <Grid item sm={3} className={classes.grid}>
            <Typography><h4><u>Labels:</u></h4></Typography>
            <Grid xs={12}>
              {
                configuration.draggableLabels.map(item => <Typography>{item}</Typography>)
              }
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
}

