import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { configuration } from '../../../../../configuration'
import { DataContext } from '../../../../../contexts/data'
import { Link } from 'react-router-dom'
import Chip from '@material-ui/core/Chip';
import Validation from '../../../../generalcomponents/validation'
import TaskActions from '../TaskActions'
import parser from 'html-react-parser'
import Labels from '../Labels'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
  root: {
    //minWidth: 300,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    backgroundColor: "#fafafa",
    overflowX: "inherit",
    width: '100%'
  },
  card: {
    backgroundColor: "#fafafa",
    //minWidth: 300,
  },
});


const customStyles = {
  employeeExist: {
    color: '#4183c4',
    textTransform: 'capitalize'
  },
  employeeNonExist: {
    color: '#626466',
    textTransform: 'capitalize',
  }
}

export default function Presentation(props) {
  const classes = useStyles();
  const [state] = useContext(DataContext)
  console.log(props)
  let task = state.taskList.filter(task => task.id === props.taskId)[0]
  let useLabels = state.project.useLabels
  let labels = state.projectList.filter(project => project.id === state.project.id)[0].labels
  console.log(task)
  const formatter = (date) => {
    console.log(date)
    let final = ""
    try {
      final = Intl.DateTimeFormat(configuration["date-code"], configuration.dateformat).format(new Date(date))
    } catch (error) {
      console.log(error)
      final = date
    }
    return final
  }
  const validate = new Validation()
  if (state.hasOwnProperty('taskList') && task !== undefined && Object.keys(task).length)
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item md={12} sm={12} xs={12}>
            <div className="d-flex justify-content-between" >
              <div>
                <h2 >{task.title}</h2>
                <span>
                  <Chip size="small" label={state.project.cid + ' - ' + task.cid} /> &nbsp;
                  {
                    task.type === "Task" ?
                      <Chip size="small" style={{ backgroundColor: "#17a2b8", color: "white" }} label="Task" />
                      :
                      <Chip size="small" style={{ backgroundColor: "#db2828", color: "white" }} label="Bug" />
                  }
                </span>
              </div>
              <div>
                <TaskActions
                  taskId={props.taskId}
                  projectId={props.projectId}
                />
              </div>
            </div>
            <hr />
          </Grid>
          <Grid item md={9} sm={9} xs={9}>
            <TableContainer className={classes.table} component={Paper}>
              <span className="d-flex">
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell width="20%"><b>Start date:</b></TableCell>
                      <TableCell>{formatter(task.startdate)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><b>End date:</b></TableCell>
                      <TableCell>{formatter(task.enddate)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><b>Status:</b></TableCell>
                      <TableCell>{task.status}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><b>Priority:</b></TableCell>
                      <TableCell>{task.priority}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><b>Assignee:</b><br/></TableCell>
                      <TableCell>
                        {task.assignee.map(employee => <span><Link to={"/console/employeelist/" + employee} ><Button type="a" style={state.projectList.filter(project => project.id === state.project.id)[0].Users.map(user => user.uid).includes(employee) ? customStyles.employeeExist : customStyles.employeeNonExist} size="small">{validate.emailToName(employee)}</Button></Link><br /></span>)}
                      </TableCell>
                    </TableRow>
                    <TableRow rowSpan={2}>
                      <TableCell><b>Description:</b></TableCell>
                      <TableCell><div style={{ wordWrap: 'break-word', width: 'fit-content' }} >{parser(task.description)}</div></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
               
              </span>
            </TableContainer>`
          </Grid>
          {/* <Grid item md={2} sm={12} xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <b>Assignee:</b><br/>
                {task.assignee.map(employee => <span><Link to={"/console/employeelist/" + employee} ><Button type="a" style={state.projectList.filter(project => project.id === state.project.id)[0].Users.map(user => user.uid).includes(employee) ? customStyles.employeeExist : customStyles.employeeNonExist} size="small">{validate.emailToName(employee)}</Button></Link><br /></span>)}
              </CardContent>
            </Card>
          </Grid> */}
          <Grid item md={3} sm={3} xs={3}>
            <Card className={classes.card}>
              <CardContent>
                  {
                      useLabels
                        ?
                          <Labels labels={labels} />
                        : 
                          null
                  }
              </CardContent>
            </Card>
            <br/>
            <Card className={classes.card}>
              <CardContent>
                <h3>Project :</h3>
                <h3 style={{fontWeight:'200'}} >{state.project.title} </h3>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );

  return <p>Loading viewer</p>
}
