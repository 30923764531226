import React, { useContext, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import {Card,CardTitle} from 'reactstrap'
import {Link} from 'react-router-dom'
import {Button} from 'semantic-ui-react'
import InviteDialog from '../InviteDialog'
import { DataContext } from '../../../../contexts/data'
import Template from '../../../InvitationTemplate/Template'
import { IoMdCheckmark } from 'react-icons/io'
import { MdClose } from 'react-icons/md'
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ArrowRight from '../../../../assets/arrow_point_to_right.gif'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';  
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Validations from '../../../generalcomponents/validation';
import MaterialTable from 'material-table';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import { START_EXCEL_INVITE } from '../../../../contexts/types';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
const useStylesFacebook = makeStyles({
    root: {
      position: 'relative',
      marginTop : '4px',
      marginRight : '3px'
    },
    top: {
      color: '#eef3fd',
    },
    bottom: {
      color: '#6798e5',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    reverseImage : {
        WebkitTransform : 'scaleX(-1)',
        transform : 'scaleX(-1)'
    }
  });


function FacebookProgress(props) {
    const classes = useStylesFacebook();
  
    return (
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          value={100}
          className={classes.top}
          size={15}
          thickness={4}
          {...props}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.bottom}
          size={15}
          thickness={4}
          {...props}
        />
      </div>
    );
  }



function Presentation(props) {
    const [ show, showTemplate ] = useState(false)
    const [state, dispatch] = useContext(DataContext)
    const { startInvite, showInviteBtn, clearList, inviteeInfo, updateTableContent, deleteTableContent } = props
    const classes = useStylesFacebook();
    const validate = new Validations()
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const columns = [
        { title: 'First name', field: 'firstname' },
        { title: 'Last name', field: 'lastname' },
        { title: 'Email id', field: 'email' },
        { title: 'Phone no', field: 'phonenumber' },
        { title: 'DOB MM/DD/YYYY', field: 'dob', render:(row) => validate.dateFormatter(row.dob) },
        { title: 'Branch', field: 'branch' },
        { title: 'Status', field: 'status',
            render:(item) => <span className='d-flex ' >&nbsp;&nbsp;&nbsp;&nbsp; <span > {item.isInviting ? <FacebookProgress /> : ( item.isInvited ? <IoMdCheckmark className='text-success' size={18} /> : <MdClose className='text-danger' size={18} /> )}</span ></span>
        },
        { title: 'Comment', field: 'comment' },
    ]



    const TableFormatter = (props) => {
        const { tableData } = props
        return(
            <MaterialTable
                title=""
                columns={columns}
                data={tableData}
                options={{
                    exportButton:true,
                    paginationType:"stepped",
                    pageSize:10
                }}

                editable={{
                    onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            {
                                const index = state.excel_invite.indexOf(oldData)
                                updateTableContent(newData, index, oldData)
                            }
                          resolve()
                        }, 1000)
                      }),
                    onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                    setTimeout(() => {
                        {
                            deleteTableContent(oldData)
                        }
                        resolve()
                    }, 1000)
                    }),
                  }}
            />
        )
    }


    const DataSorter = () => {
        return(
            <div>
                <AppBar position="static"  color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        >
                        <Tab label={<Badge color="primary" children="All" badgeContent={[...state.excel_invite, ...inviteeInfo].length} />} {...a11yProps(0)} />
                        <Tab label={<Badge color="primary"  children="To be invited" badgeContent={inviteeInfo.filter(item => !state.activeEmployees.map(user => user.useremail).includes(item.email)).length} />} {...a11yProps(1)} />
                        <Tab label={<Badge color="primary"  children="Already registered" badgeContent={state.excel_invite.filter(item => item.validEmail=== true && item.shalliInvite === false).length} />} {...a11yProps(2)} />
                        <Tab label={<Badge color="error" children="Rejected" badgeContent={state.excel_invite.filter(item => item.validEmail === false).length} />} {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <TableFormatter tableData={[...state.excel_invite, ...inviteeInfo]} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TableFormatter tableData={inviteeInfo.filter(item => item.shalliInvite === true)} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TableFormatter tableData={state.excel_invite.filter(item => item.validEmail=== true && item.shalliInvite === false)} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <TableFormatter tableData={state.excel_invite.filter(item => item.validEmail === false)} />
                </TabPanel>
            </div>
        )
    }
       
    return (
        <div>
            <div>
                <div class="row bg-white p-3 ml-1 mr-1 rounded shadow" >
                    <div className="col" >
                        <div className="d-block" >
                            <Button onClick={() => showTemplate(true)} color="teal" className="ver-btn w-100 ml-auto mr-auto m-1">Old Template</Button>
                            <Link to="/console/createtemplate"><Button className="w-100 m-1 mr-1">Create new template</Button></Link>
                        </div>
                    </div>
                    <div className="col text-center" >
                        <InviteDialog  btnText="Invite" template={false} heading="Invite employee" text="Enter the email address of the new employee" />        
                    </div>
                </div>
            </div>
                <div className=''>
                    {
                        (state.hasOwnProperty('excel_invite') && state.excel_invite.length) || inviteeInfo.length ? 
                            <div className='p-2 rounded shadow rounded mt-2 bg-white' >
                                {
                                    (state.hasOwnProperty('excel_invite') && state.excel_invite.length) || inviteeInfo.length ? 
                                    <div className='d-flex justify-content-between' >
                                        <div></div>
                                        <div>
                                            <Button onClick={clearList} color='google plus'>Clear list</Button>
                                        </div>
                                    </div>
                                    :
                                        null
                                } 
                                <DataSorter />
                                {
                                    value === 1 ?
                                    <div className="text-center" > 
                                        <img src={ArrowRight} height='40' width='50' />
                                        <Button variant='contained' color='secondary' disabled={state.excel_invite.some(item => item.isInviting === false && item.isInvited === true)} onClick={startInvite} >Invite below list</Button>
                                        <img src={ArrowRight} className={classes.reverseImage} height='40' width='50' />
                                    </div>
                                    :
                                    null
                                }
                                
                            </div>
                            :
                            null
                    }
                    
                </div>
                <div className="ml-auto mr-auto w-50" >
                    {
                        show ? <Template registeringEmail="" handleStateSet={(section, portion) => console.log(section,portion)} /> : null
                    }
                </div>
            
        </div>
    )

}

export default Presentation
