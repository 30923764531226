import React, { Component } from 'react'
import Presentation from './Presentation'
import {UNLINK_TASKS} from '../../../../../contexts/types'
import {DataContext} from '../../../../../contexts/data'
class Container extends Component {
    state = {
        listOfTasksToBeUnLinked: []
    }
    static contextType =DataContext
    handleChange = (e, value) => {
        let taskArray = this.state.listOfTasksToBeUnLinked
        if (e.target.checked) {
            taskArray = [...taskArray, value]
        }
        else {
            const index = taskArray.indexOf(value)
            taskArray.splice(index, 1)

        }
        console.log(taskArray)
        this.setState({ listOfTasksToBeUnLinked: taskArray })
    }
    handleClick = (e) => {
        e.preventDefault()
        const [state,dispatch] =this.context
        dispatch(
            {
                type:UNLINK_TASKS,
                payload:{
                    tasksToBeUnlinked :this.state.listOfTasksToBeUnLinked,
                    projectId :state.project.id
                }
            }
        )

    }
    clearValues = () => {
        this.setState({
            listOfTasksToBeUnLinked: ""
        })
    }

    render() {
        return (
            <div>
                <Presentation
                    {...this.state}
                    handleChange={this.handleChange}
                    handleClick={this.handleClick}
                    clearValues={this.clearValues}
                />
            </div>
        )
    }
}

export default Container
