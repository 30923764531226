import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { DataContext } from '../../../../contexts/data'
import TableTree from '../TableTree'
import TasksTable from '../TasksTable'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Presentation(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [ state ] = useContext(DataContext)
  let taskList = []
  let immutableList = []
  if(state.hasOwnProperty("taskList")){
    taskList = state.taskList
    immutableList = state.taskList
  }
  let immutableOverdue = taskList.filter(task => {
    let currDate = new Date()
    let taskStartdate = new Date(task.startdate)
    let taskEndDate = new Date(task.enddate)
    let taskStartDiff = ( taskStartdate - currDate )
    let taskEndDiff = ( taskEndDate - currDate )
    return task.status !== "Closed" && task.status !== "Completed" && taskStartDiff<0 && taskEndDiff<0
})
let overdueList = immutableOverdue


    if(props.filterBy.length === 0){
      taskList = immutableList
      overdueList = immutableOverdue
    }
  else {
  taskList = immutableList.filter(task => props.filterBy.some(employee => task.assignee.indexOf(employee) >=0 ))

  overdueList = immutableOverdue.filter(task => task => props.filterBy.some(employee => task.assignee.indexOf(employee) >=0 ))
}

  console.log(taskList)
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
      <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
           
          <Tab label={<Badge color="primary" max="10000" children="Open" badgeContent={taskList.filter(task => task.status === "Open").length} />}  {...a11yProps(0)} />
          <Tab label={<Badge color="primary" max="10000" children="Inprogress" badgeContent={taskList.filter(task => task.status === "InProgress").length} />} {...a11yProps(1)} />
          <Tab label={<Badge color="error"   max="10000" children="Overdue" badgeContent={overdueList.length} />} {...a11yProps(2)} />
          <Tab label={<Badge color="primary" max="10000" children="Closed" badgeContent={taskList.filter(task => task.status === "Closed" || task.status === "Completed").length} />} {...a11yProps(3)} />
          <Tab label={<Badge color="primary" max="10000" children="Subtasks" badgeContent={taskList.filter(task => task.category === "subtask").length} />} {...a11yProps(4)} />
          <Tab label={<Badge color="primary" max="10000" children="All" badgeContent={taskList.length} />} {...a11yProps(5)} />

        </Tabs>
      </AppBar>
      <TabPanel value={value} index={5}>
        <TableTree taskList={taskList } type="All"/>
      </TabPanel>
      <TabPanel value={value} index={0}>
        <TableTree taskList={taskList.filter(task => task.status === "Open")}type="Open" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableTree taskList={taskList.filter(task => task.status === "InProgress")}type="InProgess" />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TableTree taskList={overdueList} type="All"/>  
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TableTree taskList={taskList.filter(task => task.status === "Closed" || task.status === "Completed")}type="Closed" />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TableTree taskList={taskList.filter(task => task.category === "subtask")}  type="SubTask"/>
      </TabPanel>
    </div>
  );
}