import React from "react";
import EmployeeTimesheet from "../components/TimeSheets/EmployeeTimesheet/Index";
function EmployeeTimesheetPage(props) {
    console.log(props)
  return (
    <div>
      <EmployeeTimesheet
        placementID={props.match.params.placementID}
        timesheetId={props.match.params.timesheetId}
      />
    </div>
  );
}

export default EmployeeTimesheetPage;
