import React, { Component } from 'react';
import Presentation from "./Presentation";
import { DataContext } from '../../../../contexts/data'

class Container extends Component {

    state = {
        amount: 0,
        discount: 0

    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    static contextType = DataContext
    handleDiscount = (type, value, amount) => {
        let data = 0;
        type === 'byValue'
            ? data =
            !isNaN(parseInt(value))
                ? parseInt(value).toFixed(2)
                : 0.0

            : type === 'byPercentage'
                ? data =
                (amount * (value / 100)).toFixed(2)

                : data = 0
        console.log(data)
        return data;

    }
    componentDidMount = () => {
        const { invoiceNumber, paymentFilterList } = this.props
        let amount = 0;
        let discount = 0;

        if (paymentFilterList !== undefined) {
            const list = paymentFilterList.map(item => item.paymentDetails.filter(v => v.invoiceNumber === invoiceNumber))
            for (let index = 0; index < list.length; index++) {
                let amountfinal = list[index].map(item => item.paymentAmount)
                for (let index = 0; index < amountfinal.length; index++) {
                    amount = parseFloat(amount) + parseFloat(amountfinal[index] === null ? 0 : amountfinal[index])
                }
            }
            for (let index = 0; index < list.length; index++) {
                let amountfinal1 = list[index].map(item => item.paymentDiscounts)
                let totalempount = list[index].map(item => item.paymentTotalBalance)
                for (let index = 0; index < amountfinal1.length; index++) {
                    discount = parseFloat(discount) + parseFloat(this.handleDiscount(amountfinal1[index].type, amountfinal1[index].value, totalempount))
                }
                console.log(amountfinal1, discount, invoiceNumber)
            }
            this.handleChange("amount", amount.toFixed(2))
            this.handleChange("discount", discount.toFixed(2))
        }
        else {
            this.handleChange("discount", discount.toFixed(2))
            this.handleChange("amount", amount.toFixed(2))
        }
    }


    render() {
        return (
            <div>
                <Presentation
                    {...this.props}
                    {...this.state}
                    handleChange={this.handleChange}
                />
            </div>
        );
    }
}

export default Container;