import React, { useContext } from 'react'
import {
  Typography,
  Grid,
  Divider,
  makeStyles,
  Tooltip,
  Fab,
  Paper,
  Avatar,
  Chip,
  IconButton,
  withStyles,
  Card,
  CardContent,
} from '@material-ui/core'
import parser from 'html-react-parser'
import { Link } from 'react-router-dom'
import Validations from '../../generalcomponents/validation'
import {
  Edit,
  History,
  RestoreFromTrash,
  Email,
  RestorePage,
} from '@material-ui/icons'
//import Delete from '../Delete'
import { FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa'
import Comments from '../Comments'
import { blue } from '@material-ui/core/colors'
import { DataContext } from '../../../contexts/data'
import { GoFileSymlinkFile } from 'react-icons/go'

export default function Presentation(props) {
  const {
    isHistory,
    handleUpdate,
    handleLikeAndDisLike,
    handleFollow,
    historyData,
  } = props
  const [state] = useContext(DataContext)
  let required =
    state.wikiPages &&
    state.wikiPages.filter((ele) => {
      return ele.id === props.id && ele
    })
  const getArticleHistory = () => {
    state.wikiHistory &&
      state.wikiHistory.forEach((ele) => {
        if (props.id === ele.id) {
          const index = props.index
          let historyFilter = {
            ...ele,
            topicName: ele['topicName'][index],
            articleContent: ele['articleContent'][index],
            updatedBy: ele['updatedBy'][index],
            updatedAt: ele['updatedAt'][index],
            title: ele['title'][index],
          }
        }
      })
  }
  getArticleHistory()
  let data = isHistory ? historyData : required[0]
  const getNameById = (id) => {
    for (let index = 0; index < state.wikiCategories.length; index++) {
      if (state.wikiCategories[index].id === id)
        return state.wikiCategories[index].name
    }
    return 'Archived'
  }

  const validation = new Validations()
  const useStyles = makeStyles((theme) => ({
    grid: {
      padding: theme.spacing(2),
    },
    edit: {
      backgroundColor: '#1cacd7',
      marginBottom: theme.spacing(2),
    },
    history: {
      marginBottom: theme.spacing(2),
    },
    delete: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
    },
    customWidth: {
      maxWidth: 100,
    },
    root: {
      minWidth: 275,
      marginTop: theme.spacing(6),
    },
  }))
  const classes = useStyles()
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip)
  let isArchived = false
  let categories =
    state.wikiCategories &&
    state.wikiCategories.map((ele) => {
      return ele.id
    })
  if (!categories.includes(data.topicName)) {
    isArchived = true
  }
  if (Object.keys(data).length)
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={10}>
            <Paper className={classes.paper}>
              <div className='d-flex'>
                <div>
                  <Tooltip title={validation.emailToName(data.createdBy)}>
                    <Avatar src={validation.getImage(data.createdBy)} />
                  </Tooltip>
                </div>
                <div className='ml-4'>
                  <Typography variant='h6'>{data.title}</Typography>
                  <div>
                    <Typography variant='caption'>
                      UpdatedBy:{' '}
                      <Chip
                        size='small'
                        label={validation.emailToName(data.updatedBy)}
                      />
                    </Typography>
                    <Typography variant='caption' className='ml-2'>
                      UpdatedOn:{' '}
                      {validation.dateAndTimeFormatter(data.updatedAt)}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={classes.grid}>
                {typeof data.articleContent === 'string' ? (
                  <Typography variant='body1'>
                    {parser(data.articleContent)}
                  </Typography>
                ) : null}
              </div>
              {!isHistory ? (
                <React.Fragment>
                  <Divider />
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex'>
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography className='text-center' color='inherit'>
                              I like this
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <IconButton
                          onClick={(e) => handleLikeAndDisLike(e, 'upVotes')}
                        >
                          {data.upVotes.includes(state.employee.email) ? (
                            <FaRegThumbsUp style={{ color: blue[500] }} />
                          ) : (
                            <FaRegThumbsUp />
                          )}
                        </IconButton>
                      </HtmlTooltip>
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography className='text-center' color='inherit'>
                              I dislike this
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <IconButton
                          onClick={(e) => handleLikeAndDisLike(e, 'downVotes')}
                        >
                          {data.downVotes.includes(state.employee.email) ? (
                            <FaRegThumbsDown style={{ color: blue[500] }} />
                          ) : (
                            <FaRegThumbsDown />
                          )}
                        </IconButton>
                      </HtmlTooltip>
                    </div>
                    <div className='mt-2'>
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography className='text-center' color='inherit'>
                              When somebody makes an update here,you will get an
                              email with details
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        {!data.following.includes(state.employee.email) ? (
                          <IconButton
                            size='small'
                            onClick={(e) => handleFollow(e, 'follow')}
                          >
                            <Email />
                            Follow
                          </IconButton>
                        ) : (
                          <IconButton
                            size='small'
                            onClick={(e) => handleFollow(e, 'Unfollow')}
                          >
                            <Email />
                            Unfollow
                          </IconButton>
                        )}
                      </HtmlTooltip>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </Paper>
            {!isHistory ? (
              <Grid item xs={12}>
                <Card className={classes.root}>
                  <CardContent>
                    <h2>Attachments:</h2>
                    {data.hasOwnProperty('attachments') ? data.attachments.map((doc, index) => (
                      <Grid item xs={12} key={index}>
                        <p>
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={doc.url}
                          >
                            <GoFileSymlinkFile size={22} /> {doc.name}
                          </a>
                        </p>
                      </Grid>
                    )) : null}
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {!isHistory ? (
              <Grid item xs={12}>
                <Card className={classes.root}>
                  <CardContent>
                    <h2>Comments:</h2>
                    <Comments id={props.id} />
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='h5' color='textSecondary'>
                  Filed Under
                </Typography>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant='h5'
                  style={{ textTransform: 'capitalize' }}
                >
                  {getNameById(data.topicName)}
                </Typography>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <br />
              </Grid>
              <React.Fragment>
                <Grid item xs={12}>
                  <Typography variant='h5' color='textSecondary'>
                    Created On
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='h5'
                    style={{ textTransform: 'capitalize' }}
                  >
                    {validation.dateFormatter(data.createdAt)}
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h5' color='textSecondary'>
                    Created By
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='h5'
                    style={{ textTransform: 'capitalize' }}
                  >
                    {validation.emailToName(data.createdBy)}
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                  <br />
                </Grid>
              </React.Fragment>
              {!isHistory ? (
                <Grid container>
                  {state.access_modules.includes('wiki-manager') ||
                  state.access_modules.includes('console-customization') ||
                  state.employee.email === data.createdBy ? (
                    <>
                      <Grid item xs={6} sm={3}>
                        <Tooltip title='Edit Article'>
                          <Link
                            to={'/console/wiki/' + data.id + '/edit'}
                            style={{ color: 'white' }}
                          >
                            <Fab size='medium'>
                              <Edit />
                            </Fab>
                          </Link>
                        </Tooltip>
                      </Grid>
                      {state.access_modules.includes('wiki-manager') ||
                      state.access_modules.includes('console-customization') ? (
                        <Grid item xs={6} sm={3}>
                          <Tooltip title='History'>
                            <Link
                              to={'/console/wiki/' + data.id + '/history'}
                              style={{ color: 'white' }}
                            >
                              <Fab size='medium' color='primary'>
                                <History />
                              </Fab>
                            </Link>
                          </Tooltip>
                        </Grid>
                      ) : null}
                    </>
                  ) : null}
                  <Grid item xs={6} sm={3}></Grid>
                  {/* <Grid item xs={12} sm={9}></Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Delete
                                            id={data.id}
                                        />
                                    </Grid> */}
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <Tooltip title='Revert Changes'>
                      <Fab
                        size='medium'
                        color='secondary'
                        onClick={handleUpdate}
                        className={classes.history}
                      >
                        <RestoreFromTrash />
                      </Fab>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  return <p>Loading...</p>
}
