import React, { useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import {
    Column,
    FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedPaging, IntegratedSelection, IntegratedSorting,
    PagingState, SelectionState, SortingState, DataTypeProvider, DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import {
    DragDropProvider,
    Grid, GroupingPanel, PagingPanel,
    Table, TableFilterRow, TableGroupRow,
    TableHeaderRow, TableSelection, Toolbar, TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import { IoMdPersonAdd } from 'react-icons/io'

import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from '../../../contexts/data'

import Chip from '@material-ui/core/Chip';

import Checkbox from '@material-ui/core/Checkbox';

import { Link } from 'react-router-dom'
import { GoMailRead } from 'react-icons/go'
import { MdDelete } from 'react-icons/md'
import Button from '@material-ui/core/Button';
import { Avatar, TablePagination, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import Validations from '../../generalcomponents/validation';

//const sales = generateRows({ columnValues: globalSalesValues, length: 1000 });


export default function Presentation(props) {
    const { placements, isLoading, listAll, companyID } = props
    const [state] = useContext(DataContext)
    const validate = new Validations()

    const columns = [
        {
            title: 'Employee', name: 'empCode',
        },
        {
            title: 'Placement code', name: 'placementID', defaultSort: 'asc',
        },
        {
            title: 'Client', name: 'clientId', defaultSort: 'asc',
        },
        { title: 'Start date', name: 'startDate' },
        { title: 'End date', name: 'endDate' },
        { title: 'Project end date', name: 'projectEndDate' },
        { title: 'Created date', name: 'createdAt' },
        {
            title: 'Created by', name: 'createdBy',
        },
        {
            title: 'Actions', name: '',

        },
    ]
    let sortedData = []
    if (listAll) {
        sortedData = state.placements_list
    } else
        sortedData = state.placements_list.filter(item => item.empCode === companyID)
    let data = sortedData.map(placement => {
        return {
            empCode: placement.empCode,
            imageURL: validate.getImage(placement.empCode),
            email: state.names.filter(item => item.companyID === placement.empCode)[0].uid,
            placementID: placement.placementID,
            clientId: placement.clientId,
            startDate: validate.dateFormatter(placement.startDate),
            endDate: validate.dateFormatter(placement.endDate),
            projectEndDate: validate.dateFormatter(placement.projectEndDate),
            createdAt: validate.dateFormatter(placement.createdAt),
            createdBy: validate.emailToName(placement.createdBy),
            createdByEmail: placement.createdBy,
            status: placement.status,
            draft: placement.draft,
            id: placement.id,
            description: placement.description,
            fillableSections: placement.hasOwnProperty("fillableSections") ? placement.fillableSections : []
        }
    })

    const [pageSizes] = React.useState([5, 10, 15]);
    const [EmpCode] = React.useState(['empCode']);
    const [PlacementID] = React.useState(['placementID'])
    const [ClientId] = React.useState(['clientId']);
    const [CreatedBy] = React.useState(['createdBy']);
    const [Action] = React.useState(['']);

    const EmpCodeProvider = (props) => (
        <DataTypeProvider
            formatterComponent={EmpCodeFormatter}

            {...props}
        />
    )
    const ActionProvider = (props) => (
        <DataTypeProvider
            formatterComponent={ActionFormatter}

            {...props}
        />
    )
    const PlacementIDProvider = (props) => (
        <DataTypeProvider
            formatterComponent={PlacementIDFormatter}

            {...props}
        />
    )
    const ClinetIdProvider = (props) => (
        <DataTypeProvider
            formatterComponent={ClinetdFormatter}

            {...props}
        />
    )
    const CreatedByProvider = (props) => (
        <DataTypeProvider
            formatterComponent={CreatedByFormatter}

            {...props}
        />
    )
    const CreatedByFormatter = (props) => {
        console.log(props, "emp")
        let row = props.row
        console.log(props, "emp", row)
        return (

            <Link to={"/console/employeelist/" + row.createdByEmail} >{row.createdBy}</Link>)
    }
    const ActionFormatter = (props) => {
        console.log(props, "emp")
        let row = props.row
        console.log("emp-1", row)
        return (

            <div className="d-flex" >
                <IconButton>
                    <VisibilityIcon />
                </IconButton>
                <Link to={'/console/placements/' + row.empCode + '/' + row.id}>
                    <IconButton color="secondary" >
                        <CreateIcon />
                    </IconButton>
                </Link>
            </div>
        )
    }
    const PlacementIDFormatter = (props) => {
        console.log(props, "emp")
        let row = props.row
        console.log(props, "emp", row)
        return (

            row.draft === false ?
                <Link to={'/console/placements/' + row.empCode + '/' + row.id}>
                    <Chip className="kk-chip bg-success text-light w-75" label={ row.placementID.toString()} />
                </Link>
                :
                <Link to={'/console/placements/' + row.empCode + '/' + row.id}>
                    <Chip className="kk-chip bg-warning text-light w-75" label={ row.placementID.toString()} />
                </Link>
        )
    }
    const ClinetdFormatter = (props) => {
        console.log(props, "client")
        let row = props.row
        console.log(props, "emp", row)
        return (

            <Chip className="kk-chip bg-dark text-light w-75" label={ row.clientId} />
        )
    }
    const EmpCodeFormatter = (props) => {
        console.log(props, "emp")
        let row = props.row
        console.log(props, "emp", row)
        return (

            <div className="d-flex" >
                <Avatar src={row.imageURL} />
                <div className="d-flex flex-column" >
                    <span>
                        <Link to={"/console/employeelist/" + row.empCode} >
                            {row.empCode}
                        </Link>
                    </span>
                    <span>
                        {row.email}
                    </span>
                </div>
            </div>)
    }
    return (
        <Paper>
            
            <Grid
                rows={data}
                columns={columns}
            >
                <FilteringState
                //  defaultFilters={[{ columnName: 'saleDate', value: '2016-02' }]}
                />
                <SortingState
                /*  defaultSorting={[
                    { columnName: 'product', direction: 'asc' },
                    { columnName: 'saleDate', direction: 'asc' },
                  ]}*/
                />

                <SelectionState />

                <GroupingState
                //   defaultGrouping={[{ columnName: 'product' }]}
                //  defaultExpandedGroups={['EnviroCare Max']}
                />
                <PagingState />

                <IntegratedGrouping />
                <IntegratedFiltering />
                <IntegratedSorting />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                //columnExtensions={tableColumnExtensions}
                />
                <EmpCodeProvider for={EmpCode} />
                <PlacementIDProvider for={PlacementID} />
                <ClinetIdProvider for={ClientId} />
                <CreatedByProvider for={CreatedBy} />
                <ActionProvider for={Action} />

                <DragDropProvider />

                <Table />


                <TableHeaderRow showSortingControls={true} />
                <TableFilterRow showFilterSelector={true} />
                <PagingPanel pageSizes={pageSizes} />

                <TableGroupRow />
                <Toolbar />
                <GroupingPanel showSortingControls={true} />
            </Grid>
        </Paper>
    );
};
