import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import {
  NEW_COMMENT_ON_WIKI,
  UPDATE_OR_DELETE_WIKI_COMMENT,
  SET_WIKI_PAGES,
} from '../../../contexts/types'
import firebase from '../../../firebase_config'
import Axios from 'axios'

class Container extends Component {
  state = {
    comments: [],
    commentText: '',
    commenting: false,
    isEditing: false,
    updateId: '',
  }
  static contextType = DataContext

  handleChange = (data) => {
    this.setState({ commentText: data })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ commenting: true })
    const [state, dispatch] = this.context
    console.log('commented')
    const newComment = {
      wikiId: this.props.id,
      content: this.state.commentText,
    }
    dispatch({
      type: NEW_COMMENT_ON_WIKI,
      payload: newComment,
      response: {
        responseCame: this.responseCame,
        responseNotCame: this.responseNotCame,
      },
    })
  }

  stateSet = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  handleUpdate = (e) => {
    e.preventDefault()
    if (this.state.updateId) {
      this.setState({ commenting: true })
      const [state, dispatch] = this.context
      console.log('commented')
      const updatedComment = {
        wikiId: this.props.id,
        commentId: this.state.updateId,
        actionType: 'update',
        content: this.state.commentText,
      }
      dispatch({
        type: UPDATE_OR_DELETE_WIKI_COMMENT,
        payload: updatedComment,
        response: {
          responseCame: this.responseCame,
          responseNotCame: this.responseNotCame,
        },
      })
    }
  }

  handleDelete = (id) => {
    const dispatch = this.context[1]
    if (window.confirm('Are you sure you want to delete this?')) {
      dispatch({
        type: UPDATE_OR_DELETE_WIKI_COMMENT,
        payload: {
          wikiId: this.props.id,
          commentId: id,
          actionType: 'delete',
        },
        response: {
          responseCame: this.responseCame,
          responseNotCame: this.responseNotCame,
        },
      })

      this.setState({
        commentText: '',
        commenting: false,
        updateId: '',
        isEditing: false,
      })
    }
  }

  responseCame = async (props) => {
    const dispatch = this.context[1]
    let response = await Axios.get('/getPagesInWiki')
    dispatch({
      type: SET_WIKI_PAGES,
      payload: response.data.response,
    })
    this.responseNotCame()
  }

  responseNotCame = (props) => {
    this.setState({
      commentText: '',
      commenting: false,
      updateId: '',
      isEditing: false,
    })
  }

  componentWillMount = () => {
    this.setState({
      commentText: '',
      //commenting : false,
    })
  }

  componentDidMount = () => {
    firebase
      .firestore()
      .collection('Wiki')
      .doc('category')
      .collection('articles')
      .doc(this.props.id)
      .collection('Comments')
      .where('isExist', '==', true)
      .onSnapshot((snap) => {
        const temp = snap.docs.map((doc) => doc.data())
        let comments = temp
          .slice()
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        this.setState({ comments })
      })
  }

  render() {
    return (
      <div>
        <Presentation
          {...this.state}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleUpdate={this.handleUpdate}
          handleDelete={this.handleDelete}
          stateSet={this.stateSet}
        />
      </div>
    )
  }
}

export default Container
