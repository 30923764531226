import React, { useContext } from 'react'
import MaterialTable from 'material-table'
import Validations from '../../generalcomponents/validation'
import { DataContext } from '../../../contexts/data'
import parser from 'html-react-parser'
import { calcTotalTime } from '../../TimeSheets/Invoices/CreateInvoice/ServiceSummary/Presentation'
import PayrollSettings from "../PayrollSettings";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import {

    TextField,
    MenuItem,
    makeStyles,
    Button,
} from '@material-ui/core';
import PayrollList from '../PayrollList'
function Presentation(props) {
    const {
        id,
        listAll,
        Payrolls,
        dealPay,
        excludeDeductions,
        payBonus,
        insurance,
        isBench,
        fixedPay,
        fixedBenchPayroll,
        dealPeriod,
        stopPayroll,
        companyID,
        singleValue,
        selectedDate,
        handleDateChange,
        selectedHalf,
        handleChange
    } = props
    const [state] = useContext(DataContext)
    console.log("hii",Payrolls)
    const validate = new Validations()
   
    let data = []
    Payrolls && Payrolls.forEach((payrolls) => {
        data.push({
            isExist: payrolls.isExist,
            payRate: payrolls.payRate,
            totalWorkingHours: payrolls.totalWorkingHours,
            payBonus: payBonus,
            fixedPay: fixedPay.value,
            insurance: insurance.value,
            gross: payrolls.totalWorkingHours * payrolls.payRate,
            ratio: 160 / payrolls.totalWorkingHours,
            bonus: payrolls.bonus,
            hrs: 160,
            bonus: (payrolls.totalWorkingHours * payrolls.payRate) - fixedPay.value,
            cummulativebonus: 0 + (payrolls.totalWorkingHours * payrolls.payRate) - fixedPay.value,
            totalpay: fixedPay.value - insurance.value
        })
    })

    return (
        <div className="col-lg m-1"
        >

            {
                state.access_modules.includes('accounts-manager') || state.access_modules.includes('console-customization')
                    ?
                    !listAll ?
                        <div style={{ marginLeft: "95%" }} >

                            <PayrollSettings id={id} companyID={companyID} />
                        </div> : null : null
            }
            <Grid item xs={12} >

                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                    <KeyboardDatePicker
                        disableToolbar
                        variant="dialog"
                        format="MM/yyyy"
                        views={["month", "year"]}
                        margin="normal"
                        maxDate={new Date()}
                        id="date-picker-inline"
                        label="Date picker inline"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />


                </MuiPickersUtilsProvider>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <TextField
                    style={{
                        width: 100,
                    }}
                    select
                    label="Select Half"
                    size="small"
                    variant="outlined"
                    value={selectedHalf}
                    onChange={(event) => handleChange("selectedHalf", event.target.value)}
                >

                    <MenuItem value={"1"}>
                        1
                            </MenuItem>
                    <MenuItem value={"2"}>
                        2
                            </MenuItem>

                </TextField>

            </Grid>
            <PayrollList listAll={listAll}/>
        </div>
    );
}








export default Presentation
