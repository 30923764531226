import React, { Component } from 'react'
import fire from '../../../firebase_config'
import Presentation from './Presentation'
import Axios from 'axios'
import Prompt from '../../generalcomponents/snackBars'
import {  render } from 'react-dom';
import { configuration } from '../../../configuration'

class Container extends Component {


      authListener=()=>{
        fire.auth().onAuthStateChanged((user)=>{
          if(user){
          this.props.history.push("/");
          }
        })
        
      }

    state={
        levelOneAuth:false,
        token:"",
        found:0,
        load:1,
        email:"",
        incorrectPass:false,
        AuthOneSuccess:false,
        htmlContent:"",


    }
    componentDidMount=()=>{
        this.authListener()
        const email=this.props.email;
        const token = this.props.token;
        this.setState({
            email:email,
            token:token
        })
        fire.firestore().collection('Users').doc(email).get()
            .then((querySnapshot)=> {
                if(!querySnapshot.exists){  
                    this.setState({levelOneAuth:true})
                    this.setState({found:0})
                    this.setState({load:0})
                }
                else{
                let userExist=querySnapshot.data();
               if(userExist.status === "Inactive"){
                        this.setState({levelOneAuth:false})
                        this.setState({found:1})
                        this.setState({load:0})
               }
                if( this.state.found < 1){
                    this.setState({levelOneAuth:true})
                    this.setState({found:0})
                    this.setState({load:0})
                }
            }
            })
    }

  
    
    LevelAuthOne=()=>{

    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleUser= async(e) =>{
        e.preventDefault();
        const pass=document.getElementById("levelOneAuthPass").value;
        const email=this.state.email;

        const response = await Axios.post(`${configuration.REST_api}/checkotp`,{OTP : pass, email : email})
        const result = await response.data

        if(result.status === true){
            render(
                <div></div>,
                document.getElementById('notifications-box')
            )
            this.setState({AuthOneSuccess:true})
        }
        else{
            render(
                <Prompt open={true} content="Incorrect password" severity="error" />,
                document.getElementById('notifications-box')
            )
            this.setState({AuthOneSuccess:false})  
            this.setState({incorrectPass:true})
        }
    }
    render() {
        
        return (
            <div>
                <Presentation 
                load={this.state.load} 
                levelOneAuth={this.state.levelOneAuth} 
                found={this.state.found} handleChange={this.handleChange} 
                handleSubmit={this.handleSubmit}
                email={this.state.email}
                handleUser={this.handleUser}
                AuthOneSuccess={this.state.AuthOneSuccess}
                htmlContent={this.state.htmlContent}
                incorrectPass={this.state.incorrectPass}
                token={this.state.token}
                />
            </div>
        )
    }
}

export default Container
