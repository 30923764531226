import React from 'react'
import EditIcon from '@material-ui/icons/Edit';
import { TextField, MenuItem, makeStyles, IconButton, Button, Drawer } from '@material-ui/core';
import { FiEdit3 } from 'react-icons/fi'
import { GrMapLocation } from 'react-icons/gr'
import Autocomplete from '@material-ui/lab/Autocomplete';


const useStyles = makeStyles({
    setDrawer:{
        width: '380px'
    }
});



function Presentation(props) {
    const { locationsline1, locationsline2, locationscity, locationsstate_name, locationscountry, locationszip, handleUpdate, handleAdd, handleChange } = props
    const classes = useStyles()
    const [drawerState, setState] = React.useState({
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...drawerState, [anchor]: open });
      };

    return (
        <div>
            <IconButton onClick={toggleDrawer("right", true)}>{props.btnContent} </IconButton>
            <Drawer anchor={"right"} open={drawerState["right"]} onClose={toggleDrawer("right", false)}>
                <div className="m-3" >
                    <div className="text-center" >
                        {
                            props.edit ? 
                                <>
                                    <FiEdit3 fontSize="50px" />
                                    <h3><u>Edit Location</u></h3>
                                </>
                            :
                                <>
                                    <GrMapLocation fontSize="80px" />    
                                    <h3><u>Add Location</u></h3>
                                </>
                        }
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="locationsline1"
                                variant="outlined"
                                value={locationsline1}
                                onChange={handleChange}
                                size="small"
                                label="Line1"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                fullWidth
                                name="locationsline2"
                                variant="outlined"
                                value={locationsline2}
                                onChange={handleChange}
                                size="small"
                                label="Line2"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="locationscity"
                                variant="outlined"
                                value={locationscity}
                                onChange={handleChange}
                                size="small"
                                label="City"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="locationsstate_name"
                                variant="outlined"
                                value={locationsstate_name}
                                onChange={handleChange}
                                size="small"
                                label="State"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="locationscountry"
                                variant="outlined"
                                value={locationscountry}
                                onChange={handleChange}
                                size="small"
                                label="Country"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="locationszip"
                                variant="outlined"
                                value={locationszip}
                                onChange={handleChange}
                                size="small"
                                label="Zip Code"
                            />
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <Button
                            variant="contained"
                            color={props.edit ? "secondary" : "primary"}
                            onClick={props.edit ? handleUpdate : handleAdd}
                        >
                            {props.edit ? "Update" : "Add"}
                        </Button>                 
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default Presentation
