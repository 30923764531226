import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { DataContext } from '../../../../contexts/data'
import MaterialTable from 'material-table'
import Validations from '../../../generalcomponents/validation'

export default function Presentation(props) {
  const [state] = useContext(DataContext)
  let CategorieNames =
    state.hasOwnProperty('wikiCategories') && state.wikiCategories
  let data = []
  const validation = new Validations()
  CategorieNames &&
    CategorieNames.forEach((ele) => {
      data.push({
        category: ele.name,
        description: ele.description,
        id: ele.id,
        createdOn: validation.dateAndTimeFormatter(ele.createdAt),
        createdBy: validation.emailToName(ele.createdBy),
      })
    })
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3),
    },
  }))

  const columns = [
    {
      title: 'Category',
      field: 'category',
      render: (rowData) => <div>{rowData.category}</div>,
    },
    {
      title: 'Description',
      field: 'description',
      render: (rowData) => <div>{rowData.description}</div>,
    },
    {
      title: 'Created On',
      field: 'createdOn',
      render: (rowData) => <div>{rowData.createdOn}</div>,
    },
    {
      title: 'Created By',
      field: 'createdBy',
      render: (rowData) => <div>{rowData.createdBy}</div>,
    },
  ]

  const { deleteTableContent, updateTableContent, addTableContent } = props
  const classes = useStyles()
  return (
    <Grid container className={classes.root} spacing={3}>
      <Grid item xs={12} sm={12}>
        <MaterialTable
          title='Categories'
          columns={columns}
          data={data}
          editable={{
            isEditable: (rowData) => {
              return (
                rowData.category !== 'General' &&
                rowData.category !== 'Knowledge'
              )
            },
            isDeletable: (rowData) => {
              return (
                rowData.category !== 'General' &&
                rowData.category !== 'Knowledge'
              )
            },
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  {
                    addTableContent(newData)
                  }
                  resolve()
                }, 1000)
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  {
                    updateTableContent(newData, oldData)
                  }
                  resolve()
                }, 1000)
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  {
                    deleteTableContent(oldData)
                  }
                  resolve()
                }, 1000)
              }),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1'>
          <h3 style={{ textDecoration: 'underline' }}>Note:</h3>The articles
          will be archived automatically against the deletion of the category
        </Typography>
      </Grid>
    </Grid>
  )
}
