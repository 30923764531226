import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../../../contexts/data'
import CustomSpinner from '../../../../../../generalcomponents/circularSpinner'
import firebase from '../../../../../../../firebase_config'
import { CLIENT_CONTACT_ACTIONS } from '../../../../../../../contexts/types'
export class Container extends Component {
    static contextType = DataContext
    state = {
        isLoading : true,
        contacts: []
    }
    componentDidMount = () => {
        firebase.firestore().collection("ClientDetails").doc(this.props.client.id).collection("Contacts").where("isExist", "==", true).onSnapshot(snap => {
            this.setState({
                contacts: snap.docs.map(doc => doc.data()),
                isLoading: false
            })
        })
    }

    onDeleteContact = (id) => {
        const dispatch = this.context[1]
        dispatch({
            type: CLIENT_CONTACT_ACTIONS,
            payload:{
                clientId: this.props.client.id,
                contactId: id,
            },
            actionType: 'delete'
        })
    }
    render() {
            return (
                <div>
                    <Presentation 
                        {...this.props}
                        {...this.state}
                        onDeleteContact={this.onDeleteContact}
                    />
                </div>
            )
    }
}

export default Container
