import React, { useContext, useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextareaAutosize,
  makeStyles,
  Button,
  Typography,
} from '@material-ui/core'
import { DataContext } from '../../../../../../contexts/data'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

function Presentation(props) {
  const {
    onChange,
    handleCheck,
    handleSave,
    handleChange,
    client,
    workLocation,
    clientType,
    selectAddressList,
    selectAddress,
    subContracting,
    contingencyinPayment,
    prohibitionPeriod,
    rightToHire,
    liquidatedDamages,
    identification,
    comments,
    index,
    billableClient,
  } = props
  console.log(billableClient)
  const [state] = useContext(DataContext)
  let options =
    state.client_list &&
    state.client_list.map((client) => {
      return client
    })
  const [reqClient, setReqClient] = useState({})
  const [reqAddress, setReqAddress] = useState({})
  useEffect(() => {
    setReqClient(options.filter((client) => client.id === props.client)[0])
    setReqAddress(
      selectAddressList.filter((location) => location.id === selectAddress)[0]
    )
  }, [client, selectAddress, selectAddressList])
  const clientTypeList = ['End Client', 'Prime-Contractor', 'Sub-Contractor']
  const classes = useStyles()

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            {!billableClient ? (
              <Autocomplete
                fullWidth
                options={options}
                onChange={(_, value) => {
                  if (value) {
                    onChange('client', value.id)
                  }
                }}
                value={reqClient}
                autoHighlight
                getOptionLabel={(option) => option.businessDisplayName}
                renderOption={(option) => (
                  <React.Fragment>{option.businessDisplayName}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Client *'
                    size='small'
                    fullWidth
                  />
                )}
              />
            ) : (
              <TextField
                label='Client *'
                size='small'
                fullWidth
                value={
                  options.filter((client) => client.id === billableClient)[0]
                    .businessDisplayName
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label='Set as work location'
                name='workLocation'
                value={workLocation}
                onChange={handleCheck}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            {!billableClient ? (
              <TextField
                fullWidth
                select
                label='Client Type'
                value={clientType}
                name='clientType'
                onChange={handleChange}
              >
                {clientTypeList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField
                label='Client *'
                size='small'
                fullWidth
                value={'Billable Client'}
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              id='address-select-demo'
              fullWidth
              options={selectAddressList}
              classes={{
                option: classes.icon,
              }}
              value={reqAddress}
              onChange={(_, value) => {
                if (value) {
                  onChange('selectAddress', value.id)
                }
              }}
              style={{ marginTop: '3px' }}
              autoHighlight
              getOptionLabel={(option) =>
                option.locationsline1 +
                ' ' +
                option.locationsline2 +
                ' ' +
                option.locationscity +
                ' ' +
                option.locationsstate_name +
                ' ' +
                option.locationszip +
                ' ' +
                option.locationscountry
              }
              renderOption={(option) => (
                <React.Fragment>
                  <Grid container alignItems='center'>
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      <span style={{ fontWeight: 700 }}>
                        {option.locationsline1 + ' ' + option.locationsline2}
                      </span>
                      <Typography variant='body2' color='textSecondary'>
                        {option.locationscity +
                          ' ' +
                          option.locationsstate_name +
                          ' ' +
                          option.locationscountry +
                          ' ' +
                          option.locationszip}
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Address *'
                  size='small'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              select
              label='SubContracting'
              value={subContracting}
              name='subContracting'
              onChange={handleChange}
            >
              {[].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              select
              label='Contingency in Payment'
              value={contingencyinPayment}
              name='contingencyinPayment'
              onChange={handleChange}
            >
              {[].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label='Prohibition Period'
              name='prohibitionPeriod'
              size='small'
              value={prohibitionPeriod}
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              select
              label='Right to Hire'
              value={rightToHire}
              name='rightToHire'
              onChange={handleChange}
            >
              {[].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              select
              label='Liquidated Damages'
              value={liquidatedDamages}
              name='liquidatedDamages'
              onChange={handleChange}
            >
              {[].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              select
              label='Identification'
              value={identification}
              name='identification'
              onChange={handleChange}
            >
              {[].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              placeholder='Comments'
              onChange={handleChange}
              size='small'
              variant='outlined'
              name='comments'
              value={comments}
              rowsMin={5}
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
        <br />
        <div className='text-center'>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={
              client.trim() && clientType.trim() && selectAddress ? false : true
            }
            className={classes.submit}
            onClick={(e) => handleSave('create')}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Presentation
