import React, { useState } from 'react'
import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Button,
  IconButton,
  Tabs,
  Tab,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { GoFileSymlinkFile } from 'react-icons/go'
import DeleteIcon from '@material-ui/icons/Delete'
import Validations from '../../../../generalcomponents/validation'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  select: {
    minWidth: '100%',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const Presentation = (props) => {
  const classes = useStyles()
  const {
    documentType,
    status,
    isUploading,
    work_doc,
    handleChange,
    handleSave,
    handleDateChange,
    handleChange2,
    fillableSections,
    handleDelete,
  } = props

  const handleEffectiveDateChange = (date) => {
    setEffectiveDate(date)
    handleDateChange('effectiveDate', date)
  }

  const handleEffectiveUntilDateChange = (date) => {
    setEffectiveUntilDate(date)
    handleDateChange('effectiveUntilDate', date)
  }

  const [effectiveDate, setEffectiveDate] = useState('')
  const [effectiveUntilDate, setEffectiveUntilDate] = useState('')

  const [value, setValue] = React.useState(0)
  const handleTab = (_, newValue) => {
    setValue(newValue)
  }

  const validate = new Validations()

  let data = []
  work_doc &&
    work_doc.forEach((doc, index) => {
      data.push({
        documentName: doc.slice(107, doc.lastIndexOf('_')) + '_' + (index + 1),
        effectiveDate: validate.dateFormatter(props.effectiveDate[index]),
        effectiveUntilDate: validate.dateFormatter(
          props.effectiveUntilDate[index]
        ),
        index: index,
      })
    })

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleTab}
        variant='scrollable'
        scrollButtons='auto'
      >
        <Tab label='Form' />
        <Tab label='Documents' />
      </Tabs>
      <TabPanel value={value} index={0}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.select}>
              <TextField
                select
                label='Document Type'
                size='small'
                value={documentType}
                fullWidth
                required
                onChange={(event) =>
                  handleChange('documentType', event.target.value)
                }
              >
                {documentTypes.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} className={classes.select}>
              <TextField
                select
                label='Status'
                size='small'
                value={status}
                fullWidth
                required
                onChange={(event) => handleChange('status', event.target.value)}
              >
                {statusList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className='d-flex'>
                  <KeyboardDatePicker
                    required
                    size='small'
                    style={{ marginTop: '3px', marginRight: '10px' }}
                    id='date-picker-effectiveDate'
                    label='Start date'
                    format='MM/dd/yyyy'
                    value={effectiveDate ? effectiveDate : null}
                    onChange={handleEffectiveDateChange}
                    name='effectiveDate'
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <div></div>
                  <KeyboardDatePicker
                    required
                    minDate={effectiveDate}
                    //maxDate={state.project.enddate}
                    id='date-picker-effectiveUntilDate'
                    label='End date'
                    format='MM/dd/yyyy'
                    value={effectiveUntilDate ? effectiveUntilDate : null}
                    onChange={handleEffectiveUntilDateChange}
                    name='effectiveUntilDate'
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </Grid>
            {documentType && (
              <Grid item xs={6}>
                <div style={{ marginTop: '6px'}}>
                  <input
                    className={classes.input}
                    id='contained-button-file-wafile'
                    multiple
                    type='file'
                    onChange={handleChange2}
                  />
                  <label htmlFor='contained-button-file-wafile'>
                    <Button
                      variant='contained'
                      color='primary'
                      component='span'
                    >
                      Upload Document
                    </Button>
                  </label>
                </div>
                {isUploading ? <p>Uploading please wait...</p> : ''}
              </Grid>
            )}
          </Grid>
          <br />
          {fillableSections.includes('documents') ? (
            documentType && status && work_doc.length ? (
              <Button
                type='submit'
                variant='contained'
                color='primary'
                onClick={() => handleSave('create')}
              >
                Done
              </Button>
            ) : (
              <Button
                type='button'
                variant='contained'
                color='primary'
                disabled
              >
                Done
              </Button>
            )
          ) : documentType && status && work_doc.length ? (
            <Button
              type='submit'
              variant='contained'
              color='secondary'
              onClick={() => handleSave('update')}
            >
              Update
            </Button>
          ) : (
            <Button type='button' variant='contained' color='primary' disabled>
              Update
            </Button>
          )}
        </form>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Document Name</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.index}>
                  <TableCell component='th' scope='row'>
                    {row.documentName}
                  </TableCell>
                  <TableCell>{row.effectiveDate}</TableCell>
                  <TableCell>{row.effectiveUntilDate}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDelete(row.index)}>
                      <DeleteIcon fontSize='small' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </div>
  )
}

export default Presentation

const documentTypes = ['MSA', 'PO', 'SOW', 'Other']

const statusList = ['Active', 'Inactive']
