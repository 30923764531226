import firebase from "../../../firebase_config"

export class Placement{
    constructor(placementDocId){
        this.getSettings = async(sectionName) =>{
            return firebase.firestore().collection("Placements").doc(placementDocId).collection(sectionName).doc("settings").get()
            .then(snap => {
                return snap.data()
            }).catch(err => console.log(err))
        }
    }
}
