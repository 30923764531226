import React, { useContext } from 'react';
import LoginPage from './pages/LoginPage'
import Navbar from './components/Navbar'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import EmployeeRegisterPage from './pages/EmployeeRegisterPage'
import { DataContext } from './contexts/data'
import { configuration } from './configuration'
import $ from 'jquery'
import { CircularProgress } from '@material-ui/core';
function App() {
  const [state ] = useContext(DataContext)
  const isLoggedIn = state.isLoggedIn
  window.addEventListener('error',(err) => {
    console.log(err)
  })
  // to set favicon icon on the title bar based on configuration file
  $('#flair-favicon').attr('href', configuration.flair_logo)
  $('#flair-head-title').text(configuration.owner)

  
  
  return (
    <div>
      {
          isLoggedIn ? 
            <Navbar/>
          :
          <BrowserRouter>
            <Switch>
              <Route exact path="/console/postform/:token/:email" component={EmployeeRegisterPage}/>
              <Route path="/" component={LoginPage}/>
              <Route path="/login" component={LoginPage}/>
            </Switch>
          </BrowserRouter>
      }
    </div>
    
  );
}

export default App;
