import React, { useContext,useState, useEffect  } from 'react';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import {
    Column,
    FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedPaging, IntegratedSelection, IntegratedSorting,
    PagingState, SelectionState, SortingState, DataTypeProvider, DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import {
    DragDropProvider,
    Grid, GroupingPanel, PagingPanel,
    Table, TableFilterRow, TableGroupRow,
    TableHeaderRow, TableSelection, Toolbar, TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import {IoMdPersonAdd} from 'react-icons/io'
import SunEditor from 'suneditor-react'

import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from '../../../../contexts/data'

import Chip from '@material-ui/core/Chip';

import Checkbox from '@material-ui/core/Checkbox';

import { Link } from 'react-router-dom'
import { GoMailRead } from 'react-icons/go'
import { MdDelete } from 'react-icons/md'
import Button from '@material-ui/core/Button';
import {   Avatar,
    TablePagination,
    IconButton,
    Tooltip,
    Tabs,
    Tab,
    
    Badge,
    AppBar,
    Typography,
    Box,
    
    Fab,
    ButtonGroup, } from "@material-ui/core";

    import EditIcon from '@material-ui/icons/Edit'
    import HighlightOff from '@material-ui/icons/HighlightOff'
    import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
    import PropTypes from 'prop-types'
    import SweetAlert from 'react-bootstrap-sweetalert'
    import parser from 'html-react-parser'
import { FaFilter } from "react-icons/fa";

//const sales = generateRows({ columnValues: globalSalesValues, length: 1000 });
const availableFilterOperations = [
    'equal', 'notEqual',
    'greaterThan', 'greaterThanOrEqual',
    'lessThan', 'lessThanOrEqual', 'contains'
];

const styles = makeStyles(({
    currency: {
        fontWeight: "bold"
    },
    numericInput: {
        fontSize: '14px',
        width: '100%',
    },
}));

const getInputValue = (value) =>
    (value === undefined ? '' : value.toLocaleString());

const getColor = (amount) => {
    if (amount < 3000) {
        return '#F44336';
    }
    if (amount < 5000) {
        return '#FFC107';
    }
    if (amount < 8000) {
        return '#FF5722';
    }
    return '#009688';
};

const CurrencyEditor = (
    ({ onValueChange, classes, value }) => {
        const handleChange = (event) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                onValueChange(undefined);
                return;
            }
            onValueChange(parseInt(targetValue, 10));
        };
        return (
            <Input
                type='number'

                fullWidth={true}
                value={getInputValue(value)}
                inputProps={{
                    min: 0,
                    placeholder: 'Filter...',
                }}
                onChange={handleChange}
            />
        );
    }
);

const clientIdFormatter = (
    (props
    ) => {
        let rowData =props.row
        return (
            <Link to={'/console/clientslist/' + rowData.clientId.toString()}>
            <Chip
              className='bg-dark text-light w-75 c-pointer'
              label={rowData.clientId.toString()}
            />
          </Link>
        )
    });
const durationFormatter = (
    (props) => {
        let rowData=props.row
       return(
        <div>
        <Link
          to={
            '/console/timesheets/submission/' +
            rowData.placementDocID +
            '/' +
            rowData.id
          }
        >
          <Tooltip title={rowData.startDate + '-' + rowData.endDate}>
            <Chip
              className={
                rowData.isRejected
                  ? 'bg-danger text-white w-100'
                  : rowData.isApproved
                  ? 'bg-success text-white w-100'
                  : 'w-100'
              }
              label={rowData.startDate + '-' + rowData.endDate}
            />
          </Tooltip>
        </Link>
      </div>
       )
    }
)
const EmpCodeFormatter = (props) => {
    console.log(props, "emp")
    let row = props.row
    console.log(props, "emp", row)
    return (

        <div className="d-flex" >
            <Avatar src={row.imageURL} />
            <div className="d-flex flex-column" >
                <span>
                    <Link to={"/console/employeelist/" + row.empCode} >
                        {row.empCode}
                    </Link>
                </span>
                <span>
                    {row.email}
                </span>
            </div>
        </div>)
}

const CliendIdprovider =
    (props) => (
        <DataTypeProvider
            formatterComponent={clientIdFormatter}
            {...props}
        />
    );
const DurationProvider = (props) => (
    <DataTypeProvider
        formatterComponent={durationFormatter}
        {...props}
    />
)
const EmployeeProvider = (props) => (
    <DataTypeProvider
        formatterComponent={EmpCodeFormatter}
        {...props}
    />
)



 
 
export default function Presentation(props) {
    const { 
        placements,
        isLoading,
        approveTimesheet,
        rejectTimesheet,
        listAll,
        description,
        handleChange,
        timesheetSettings,
        empID,
      info } = props
    const [state] = useContext(DataContext)
    const [alert, setAlert] = useState(null)
    const [open,setOpen] = React.useState(false)
const handleClickOpen = () => {
  
    setOpen(!open)
   
    
}
  const [id, setId] = useState('')

    const [type, setType] = useState('')
    const [value, setValue] = React.useState(0)
    const [placementDocID, setPlacementDocID] = useState('')
    const handleTab = (event, newValue) => {
        setValue(newValue)
      }
    
      const hideAlert = () => {
        handleChange('description', '')
        setAlert(null)
      }
    
      useEffect(() => {
        if (alert !== null) {
          showAlert(type, id, placementDocID)
        }
      }, [description])
    
     
    const checkApprovalAccess = (placementID) => {
        if (state.employee.role === 'admin') return true
        try {
          return (
            timesheetSettings.filter((item) => {
              return item.placementID === 'PC-' + placementID.toString()
            })[0].approvalBy === state.employee.email
          )
        } catch (error) {
          console.log(error)
          return false
        }
      }
    
    const showAlert = (type, id, placementDocID) => {
        console.log(description)
        setAlert(
          <div>
            <SweetAlert
              closeOnClickOutside={false}
              allowEscape={false}
              warning
              showCancel={false}
              showConfirm={false}
              title='Are you sure you want to approve this timesheet?'
              onConfirm={() => {
                if (type === 'reject') rejectTimesheet(id, placementDocID)
                else approveTimesheet(id, placementDocID)
              }}
              onCancel={hideAlert}
            >
              <SunEditor
                hide={type !== 'reject'}
                placeholder={
                  type === 'reject' ? 'Description*' : 'Description(optional)'
                }
                value={description}
                onChange={(data) => handleChange('description', data)}
                setOptions={{
                  buttonList: [
                    [
                      'undo',
                      'redo',
                      'formatBlock',
                      'bold',
                      'underline',
                      'italic',
                      'strike',
                      'subscript',
                      'superscript',
                      'fontColor',
                      'hiliteColor',
                      'removeFormat',
                      'outdent',
                      'indent',
                      'align',
                      'horizontalRule',
                      'list',
                      'lineHeight',
                      'table',
                      'link',
                    ],
                  ],
                }}
              />
              <br />
              <div>
                <div>
                  <Button onClick={hideAlert} variant='contained' color='default'>
                    cancel
                  </Button>
                  {'  '}
                  <Button
                    onClick={() => {
                      setAlert(null)
                      type === 'reject'
                        ? rejectTimesheet(id, placementDocID)
                        : approveTimesheet(id, placementDocID)
                    }}
                    disabled={type === 'reject' ? description === '' : false}
                    variant='contained'
                    color='primary'
                  >
                    {type === 'reject' ? 'Reject' : 'Approve'}
                  </Button>
                </div>
              </div>
            </SweetAlert>
          </div>
        )
      }
    
    const columns = [
        {
          title: <span>
          Employee  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>,
          name: 'empCode',
         
        },
        {
          title: <span>
         Client ID  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>,
          name: 'clientId',
          getCellValue: row => row.clientId

        },
        {
          title: <span>
          Duration  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>,
          name: 'duration',
          getCellValue: row => row.startDate || row.endDate
         
        },
        { title: <span>
         Hrs &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>, name: 'hrs' },
        { title: <span>
          Attached File  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>, name: 'attachment' },
        { title: <span>
          Comments  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>, name: 'comment' },
        {
          title: <span>
         Actions  &nbsp; 
         
    
    <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
    
    
      </span>,
          name: 'actions',
         
         
        },
      ]
    
    const [defaultHiddenColumnNames] = React.useState(['']);

    const validate = {
        emailToName: (uid) => {
            try {
                return state.names.filter(item => item.uid === uid)[0].name
            } catch (error) {
                console.log(error)
                return uid
            }
        }
    }

    const [pageSizes] = React.useState([5, 10, 15]);
    const [delete1] = React.useState(['delete1']);
    const [clientId] = React.useState(['clientId']);
    const [duration] = React.useState(['duration']);
    const [employeeid] = React.useState(['empCode'])
    const [actions] = React.useState(['actions'])

    const ActionProvider = (props) => (
        <DataTypeProvider
            formatterComponent={ActionFormatter}
            
            {...props}
        />
    )
   
   

    const ActionFormatter = (
        (props) => {
            let rowData=props.row
            console.log(rowData)
            if (rowData.isApproved)
            return (
              <div>
                 
                {rowData.empCode === state.employee.companyID ? null : checkApprovalAccess(rowData.placementID) ? (
                  <IconButton
                    onClick={() => {
                      setType('reject')
                      setId(rowData.id)
                      setPlacementDocID(rowData.placementDocID)
                      showAlert('reject', rowData.id, rowData.placementDocID)
                    }}
                    color='secondary'
                  >
                    <HighlightOff />
                  </IconButton>
                ) : null}
              </div>
            )
          else if (rowData.isRejected)
            return (
              <div>
                {rowData.empCode === state.employee.companyID ? (
                  <Link
                    to={
                      '/console/timesheets/submission/' +
                      rowData.placementDocID +
                      '/' +
                      rowData.id
                    }
                  >
                    <IconButton color='default'>
                      <EditIcon />
                    </IconButton>
                  </Link>
                ) : checkApprovalAccess(rowData.placementID) ? (
                  <IconButton
                    onClick={() =>
                      showAlert('approve', rowData.id, rowData.placementDocID)
                    }
                    color='primary'
                  >
                    <CheckCircleOutline />
                  </IconButton>
                ) : null}
              </div>
            )
          else if (!rowData.isApproved && !rowData.isRejected)
            return (
              <div>
                {rowData.empCode === state.employee.companyID ? (
                  <Link
                    to={
                      '/console/timesheets/submission/' +
                      rowData.placementDocID +
                      '/' +
                      rowData.id
                    }
                  >
                    <IconButton color='default'>
                      <EditIcon />
                    </IconButton>
                  </Link>
                ) : checkApprovalAccess(rowData.placementID) ? (
                  <>
                    <IconButton
                      onClick={() =>
                        showAlert('approve', rowData.id, rowData.placementDocID)
                      }
                      color='primary'
                    >
                      <CheckCircleOutline />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setType('reject')
                        setId(rowData.id)
                        setPlacementDocID(rowData.placementDocID)
                        showAlert('reject', rowData.id, rowData.placementDocID)
                      }}
                      color='secondary'
                    >
                      <HighlightOff />
                    </IconButton>
                  </>
                ) : null}
              </div>
            )
      
        }
    )
    let rows =[]
    return (
        <Paper>
           <div>{alert}</div>
             <div
                style={{
                  marginTop: 'auto',
                  marginLeft: 'auto',
                  marginBottom: 'auto',
                }}
              >
                <Tooltip title='New submission'>
                  <ButtonGroup
                    variant='contained'
                    color='primary'
                    aria-label='outlined primary button group'
                  >
                    <Link to='/console/timesheets/submission/new/timesheet'>
                      <Button color='primary' variant='contained'>
                        New Timesheet
                      </Button>
                    </Link>
                  </ButtonGroup>
                </Tooltip>&nbsp;&nbsp;
                <Tooltip title='New Expense'>
                  <ButtonGroup
                    variant='contained'
                    color='primary'
                    aria-label='outlined primary button group'
                  >
                    <Link to='/console/expenses/submission/expense/new'>
                      <Button color='primary' variant='contained'>
                        New Expense
                      </Button>
                    </Link>
                  </ButtonGroup>
                </Tooltip>
              </div>
            <Grid
                rows={info}
                columns={columns}
            >
              {open ?  <FilteringState 
                //  defaultFilters={[{ columnName: 'saleDate', value: '2016-02' }]}
                /> : "" }
                <SortingState
                /*  defaultSorting={[
                    { columnName: 'product', direction: 'asc' },
                    { columnName: 'saleDate', direction: 'asc' },
                  ]}*/
                />

                <SelectionState />

                <GroupingState
                //   defaultGrouping={[{ columnName: 'product' }]}
                //  defaultExpandedGroups={['EnviroCare Max']}
                />
                <PagingState />

                <IntegratedGrouping />
                {open ?  <IntegratedFiltering /> : "" }
                <IntegratedSorting />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                //columnExtensions={tableColumnExtensions}
                />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
               
                <CliendIdprovider for={clientId} />
                <DurationProvider for={duration} />
                <EmployeeProvider for={employeeid} />
                <ActionProvider for={actions} />
                <DragDropProvider />

                <Table />


                <TableHeaderRow showSortingControls={true} />
                {open ?  <TableFilterRow showFilterSelector={true} /> : "" }
                <PagingPanel pageSizes={pageSizes} />

                <TableGroupRow />
                <Toolbar />
                <GroupingPanel showSortingControls={true} />
            </Grid>
        </Paper>
    );
};
