import React, { Component } from 'react'
import Presentation from './Presentation'
class Container extends Component {
    render() {
        return (
            <div>
                <Presentation
                    {...this.props}
                />
            </div>
        )
    }
}

export default Container
