import React from 'react';
import WikiSettings from '../components/Wiki/WikiSettings/Index';
import { Breadcrumbs } from '@material-ui/core'
import { Link } from 'react-router-dom'

export default function WikiSettingsPage() {
    return (
        <div>
            <div>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to='/'>Home</Link>
                    <Link to="/console/wiki">Wiki</Link>
                    <Link to="/console/wiki/settings">Wikisettings</Link>
                </Breadcrumbs>
            </div>
            <div>
                <WikiSettings />
            </div>
        </div>
    )
}