import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
class Container extends Component {
    state = {
        letter : '',
        today_date : '',
        signature : '',
        description : ''
    }

    static contextType = DataContext


    // handleDateChange = (key, value) => {
    //     if(!isNaN(Date.parse(value))) 
    //     this.setState({
    //         [key]:new Date(value).toISOString()
    //     })
    // }

    // handleChange = (key, value) => {
    //     this.setState({
    //         [key] : value
    //     })
    // }

    // handleGrant = async(e, state, dispatch, reqData) => {
    //     e.preventDefault()
    //     console.log(reqData)
    //     const { letter, today_date, signature, description } = this.state
    //     // const [state, dispatch] = this.context
    //     console.log(this.state)
    //     let employee = await state.employees_collection.filter(employee => employee.useremail === reqData.uid)[0]
    //     let pdfDetails = {
    //         firstname : employee.personal.firstname,
    //         middlename : employee.personal.middlename,
    //         lastname : employee.personal.lastname,
    //         jobtitle : employee.personal.jobtitle,
    //         universityname : reqData.additionalDetails.universityDetails.schoolname,
    //         universityaddress : reqData.additionalDetails.universityDetails.address
    //     }

    //     dispatch({
    //         type : OFFICER_ISSUE_LETTER,
    //         payload : {
    //             offerLetterId : letter,
    //             letterIssueDate : today_date,
    //             authorisedSignature : signature,
    //             requestId : reqData.id,
    //             description : description,
    //             pdfDetails : pdfDetails
    //         }
    //     })

    //     // this.clearValues()
    // }

    

    render() {
        console.log(this.state)
        return (
            <div>
                {
                    Object.keys(this.props.reqData).length ? 
                    <Presentation 
                        {...this.props}
                        {...this.props.info}
                    />
                    :
                    null
                }
                
            </div>
        )
    }
}

export default Container
