import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
import {
  CREATE_NEW_LABEL,
  UPDATE_LABEL,
  DELETE_LABEL,
} from '../../../../contexts/types'
class Container extends Component {
  static contextType = DataContext
  deleteLabel = (e) => {
    const [state, dispatch] = this.context
    dispatch({
      type: DELETE_LABEL,
      payload: {
        projectId: this.props.projectId,
        labelId: e.id,
      },
    })
  }

  updateLabel = (newData, oldData) => {
    const [state, dispatch] = this.context
    dispatch({
      type: UPDATE_LABEL,
      payload: {
        projectId: this.props.projectId,
        labelName: newData.label,
        labelId: newData.id,
        labelColorCode: newData.color,
      },
    })
  }
  createNewLabel = (newData) => {
    const [state, dispatch] = this.context
    dispatch({
      type: CREATE_NEW_LABEL,
      payload: {
        projectId: this.props.projectId,
        labelName: newData.label,
        labelColorCode: newData.color,
      },
    })
  }

  render() {
    console.log(this.props)
    return (
      <div>
        <Presentation
          projectId={this.props.projectId}
          createNewLabel={this.createNewLabel}
          updateLabel={this.updateLabel}
          deleteLabel={this.deleteLabel}
        />
      </div>
    )
  }
}

export default Container
