import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';  
import {  Collapse, Spinner } from 'reactstrap'
import AddCircleIcon from "@material-ui/icons/AddCircle"
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {configuration} from '../../../configuration'
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';  
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import NumberFormat from 'react-number-format';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';  
import Button from '@material-ui/core/Button';
import Validations from '../../generalcomponents/validation'
import {GoFileSymlinkFile} from 'react-icons/go'

const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
    input: {
        display: 'none',
    },
    large: {
      position:"absolute",
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin:"1.1em",
    },
    box:{
      borderRadius:"100px",
      height:100,
      width:100,
      margin:"auto",
      marginTop:"4px"
    },
    table: {
      backgroundColor:"#fafafa"
    },
  }));


  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
        return (
            <NumberFormat
            {...other}
            getInputRef={inputRef}
            decimalScale={2}
            allowNegative={false}
            onValueChange={(values) => {
              onChange({
                target: {
                  name: props.name,
                  value: values.value,
                },
              });
            }}
            isNumericString
          />
        );
    }

  

function Presentation(props) {
        const classes = useStyles();
        const {clearValues, handleExisting,isEditing, handleAdd, editSection, handleDateChange, handleEdit, handleDelete, educationHistoryData, handleChange, handleChange2, educationlevel, field, schoolname, startdate, enddate, address, gpaPer, document,  isUploading } = props
        const [isOpen, setIsOpen] = useState(false);
        const [isOpen2, setIsOpen2] = useState(false);
        const toggle = () => {
            setIsOpen(!isOpen);
            if(isOpen2){
                setIsOpen2(!isOpen2)
                clearValues()
            }
            else{
                clearValues()
            }
        }
        const toggle2 = () =>{ 
            setIsOpen2(!isOpen2)
            if(isOpen){
                setIsOpen(!isOpen)
                clearValues()
            }
            else{
                clearValues()
            }
        }
        if(!isEditing){
            if(isOpen)
                setIsOpen(!isOpen)
            if(isOpen2)
                setIsOpen2(!isOpen2)    
        }

        const formatter = (date) => {
            console.log(date)  
            let final = ""
            try {
                final = Intl.DateTimeFormat(configuration["date-code"],configuration.dateformat).format(new Date(date))
            } catch (error) {
                console.log(error)
                final = date
            }
            return final
        }

        const handleStartDate = date => {
            handleDateChange("startdate",date)
        };
        const handleEndDate = date => {
            handleDateChange("enddate",date)
        };

        const validate = new Validations()



    return (
           
            <div className="user-emergencycontact mt-3">
                         <h3 ><u><b>Education History:</b></u></h3>
                            <TableContainer className={classes.table}  component={Paper}>
                                <Table aria-label="simple table">
                                <TableBody>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="10%" align="left">Education level</TableCell>
                                                <TableCell width="15%" align="left">School name</TableCell>
                                                <TableCell width="10%" align="left">Field of study</TableCell>
                                                <TableCell width="10%" align="left">GPA/Percentage</TableCell>
                                                <TableCell width="12%" align="left">Start date</TableCell>
                                                <TableCell width="10%" align="left">End date</TableCell>
                                                <TableCell width="15%" align="left">Address</TableCell>
                                                <TableCell width="10%" align="left">Document</TableCell>
                                                {
                                                    isEditing?
                                                    <TableCell  align="right"  >
                                                        <Fab 
                                                            variant="contained" 
                                                            color="primary"
                                                            size="small"
                                                            onClick={toggle}
                                                        >
                                                        <AddCircleIcon />
                                                        </Fab>
                                                    </TableCell>
                                                    :
                                                    null
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                educationHistoryData.map(item => {
                                                    return(
                                                        <TableRow>
                                                            <TableCell>{item.educationlevel} </TableCell>
                                                            <TableCell>{item.schoolname} </TableCell>
                                                            <TableCell>{item.field} </TableCell>
                                                            <TableCell>{item.gpaPer} </TableCell>
                                                            <TableCell>{item.startdate === "" ? item.startdate : formatter(item.startdate)} </TableCell>
                                                            <TableCell>{item.enddate === "" ? item.enddate : formatter(item.enddate)}  </TableCell>
                                                            <TableCell>{item.address} </TableCell>
                                                            <TableCell>{item.document === "" ? <span>Document</span> : <a href={item.document} target="_blank" rel="noopener noreferrer"  >Document</a>} </TableCell>
                                                            {
                                                                isEditing?
                                                                <TableCell className="right" >
                                                                    <Fab 
                                                                        variant="contained" 
                                                                        className="flairbtn" 
                                                                        size="small"
                                                                        onClick={()=>{toggle2();handleEdit(item)}}
                                                                    >
                                                                        <EditIcon />
                                                                    </Fab>&nbsp;
                                                                    {educationHistoryData.length>1?<Fab 
                                                                        variant="contained" 
                                                                        size="small"
                                                                        color="secondary"
                                                                        onClick={()=>handleDelete(educationHistoryData.indexOf(item))}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </Fab>
                                                                    :null}
                                                                    </TableCell>
                                                                :
                                                                null
                                                            }
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                </TableBody>
                                </Table>
                            </TableContainer>
                            <div>
                                <Collapse isOpen={isOpen}>
                                    <form onSubmit={(e)=>{
                                        e.preventDefault()
                                        if(educationlevel!==""&&schoolname.trim()!==""&&field.trim()!==""&&validate.checkFloatNumber(gpaPer)&&!isNaN(Date.parse(startdate))&&!isNaN(Date.parse(enddate))&&address.trim()!==""&&document!==""&&validate.checkDateDiff(startdate, enddate)){
                                        toggle();handleAdd(e)
                                        }
                                        }}>
                                        <div className="m-3">
                                            <Paper  style={{padding:'15px'}} >
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                        <FormControl style={{width:'100%'}} >
                                                            <InputLabel id="demo-simple-select-outlined-label">Education level</InputLabel>
                                                            <Select
                                                                fullWidth
                                                                value={educationlevel}
                                                                label="Education level"
                                                                name="educationlevel"
                                                                onChange={handleChange}
                                                                required
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                <MenuItem value="Bachelors">Bachelors</MenuItem>
                                                                <MenuItem value="Masters">Masters</MenuItem>
                                                                <MenuItem value="PHD">PHD</MenuItem>
                                                            </Select>
                                                            <FormHelperText>{ educationlevel === '' ? 'Select education level' : '' } </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="School name"
                                                            name="schoolname"
                                                            value={schoolname}
                                                            variant="outlined"
                                                            size="small"
                                                            required
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Field of study"
                                                            name="field"
                                                            value={field}
                                                            variant="outlined"
                                                            size="small"
                                                            required
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="GPA/Percentage"
                                                            value={gpaPer}
                                                            name="gpaPer"
                                                            size="small"
                                                            placeholder="0.00/00.0"
                                                            variant="outlined"
                                                            onChange={(e) => handleChange(e)}
                                                            InputProps={{
                                                                inputComponent: NumberFormatCustom,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                label="Start date"
                                                                format="MM/dd/yyyy"
                                                                value={startdate ? startdate : null}
                                                                onChange={handleStartDate}
                                                                name="startdate"
                                                                required
                                                                KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>  
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                label="End date"
                                                                format="MM/dd/yyyy"
                                                                value={enddate ? enddate : null}
                                                                minDate={startdate}
                                                                onChange={handleEndDate}
                                                                name="enddate"
                                                                required
                                                                KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>  
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Address"
                                                            name="address"
                                                            value={address}
                                                            variant="outlined"
                                                            size="small"
                                                            required
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div>
                                                        <input
                                                            className={classes.input}
                                                            id="contained-button-file-ehfile"
                                                            multiple
                                                            type="file"
                                                            onChange={handleChange2}
                                                        />
                                                        <label htmlFor="contained-button-file-ehfile">
                                                            <Button variant="contained" color="primary" component="span">
                                                                Upload Document
                                                         </Button>
                                                        </label>
                                                        </div>
                                                        <p>{document !== "" ? <a target="_blank" rel="noopener noreferrer"  href={document} ><GoFileSymlinkFile size={22} /></a> : <p>No file choosen</p>} </p>
                                                        {
                                                            isUploading ?  <p>Uploading please wait...</p> : "" 
                                                        }
                                                    </Grid>
                                                </Grid>    
                                                <br/>
                                                <div className="text-center" style={{margin:'10px'}}>
                                                            {
                                                                isUploading?
                                                                    <Button disabled variant="contained" type="button"  color="primary">
                                                                        Save    
                                                                    </Button>
                                                                    :
                                                                    
                                                                    <Button variant="contained" type="submit"  color="primary">
                                                                        Save    
                                                                    </Button>
                                                            }    
                                                            {' '}
                                                            <Button variant="contained" onClick={toggle} color="default">
                                                                Cancel
                                                            </Button>
                                                </div>
                                                <br/>
                                            </Paper>
                                        </div> 
                                    </form>
                                </Collapse>
                            </div>
                            <div id="edit_row_for_emergency_contact" >
                                <Collapse isOpen={isOpen2}>
                                <form onSubmit={(e)=>{e.preventDefault();
                                    if(educationlevel!==""&&schoolname.trim()!==""&&field.trim()!==""&&validate.checkFloatNumber(gpaPer)&&!isNaN(Date.parse(startdate))&&!isNaN(Date.parse(enddate))&&address.trim()!==""&&document!==""&&validate.checkDateDiff(startdate, enddate)){
                                    toggle2();
                                    handleExisting(educationHistoryData.indexOf(editSection),editSection)
                                }
                                    }}>
                                        <div className="m-3">
                                            <Paper  style={{padding:'15px'}} >
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                    <FormControl  style={{width:'100%'}}  >
                                                        <InputLabel id="demo-simple-select-outlined-label">Education level</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            value={educationlevel}
                                                            label="Education level"
                                                            name="educationlevel"
                                                            onChange={handleChange}
                                                            required
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value="Bachelors">Bachelors</MenuItem>
                                                            <MenuItem value="Masters">Masters</MenuItem>
                                                            <MenuItem value="PHD">PHD</MenuItem>
                                                        </Select>
                                                        <FormHelperText>{ educationlevel === '' ? 'Select education level' : '' } </FormHelperText>
                                                    </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="School name"
                                                            name="schoolname"
                                                            value={schoolname}
                                                            variant="outlined"
                                                            size="small"
                                                            required
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Field of study"
                                                            name="field"
                                                            value={field}
                                                            variant="outlined"
                                                            size="small"
                                                            required
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="GPA/Percentage"
                                                            value={gpaPer}
                                                            name="gpaPer"
                                                            size="small"
                                                            placeholder="0.00/00.0"
                                                            variant="outlined"
                                                            onChange={(e) => handleChange(e)}
                                                            InputProps={{
                                                                inputComponent: NumberFormatCustom,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                label="Start date"
                                                                format="MM/dd/yyyy"
                                                                value={startdate ? startdate : null}
                                                                onChange={handleStartDate}
                                                                name="startdate"
                                                                required
                                                                KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>  
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                label="End date"
                                                                format="MM/dd/yyyy"
                                                                invalidDateMessage=""
                                                                value={enddate ? enddate : null}
                                                                minDate={startdate}
                                                                onChange={handleEndDate}
                                                                name="enddate"
                                                                required
                                                                KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>  
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Address"
                                                            name="address"
                                                            value={address}
                                                            variant="outlined"
                                                            size="small"
                                                            required
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                    <input
                                                        className={classes.input}
                                                        id="contained-button-file-ehfile"
                                                        multiple
                                                        type="file"
                                                        onChange={handleChange2}
                                                        />
                                                        <label htmlFor="contained-button-file-ehfile">
                                                        <Button variant="contained" color="primary" component="span">
                                                            Upload Document
                                                        </Button>
                                                        </label>
                                                        <p>{document !== "" ? <a target="_blank" rel="noopener noreferrer"  href={document} ><GoFileSymlinkFile size={22} /></a> : <p>No file choosen</p>} </p>
                                                        {
                                                                
                                                            isUploading ?  <p>Uploading please wait...</p> : "" 
                                                        }
                                                    </Grid>
                                                </Grid>    
                                                <br/>
                                                <div className="text-center" style={{margin:'10px'}}>
                                                            {
                                                                isUploading?
                                                                    <Button disabled variant="contained" type="button"  color="primary">
                                                                        Save    
                                                                    </Button>
                                                                    :
                                                                    
                                                                    <Button variant="contained" type="submit"  color="primary">
                                                                        Save    
                                                                    </Button>
                                                            }    
                                                            {' '}
                                                            <Button variant="contained" onClick={toggle2} color="default">
                                                                Cancel
                                                            </Button>
                                                </div>
                                                <br/>
                                            </Paper>
                                        </div> 
                                    </form>
                                </Collapse>
                            </div>
                </div>
    )
}

export default Presentation
