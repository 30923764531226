import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data';
import Axios from 'axios'
import firebase from '../../../firebase_config'

import CustomSpinner from '../../generalcomponents/circularSpinner'
class Container extends Component {
    state = {
        docId: '',
        companyID: '',
        Payrolls: [],
        dealPay: '',
        excludeDeductions: false,
        payBonus: '',
        insurance: [],
        isBench: false,
        fixedPay: [],
        fixedBenchPayroll: [],
        dealPeriod: [],
        stopPayroll: false,
        selectedDate:new Date(),
        selectedHalf:1
    }

    static contextType = DataContext

    handleDocId = (id) => {
        console.log(id)
        this.setState({ docId: id })
    }

handleDateChange = (date) => {
    this.setState({
        selectedDate : [date.toISOString()]
    })
}
handleChange = (key,value) => {
    this.setState({
        selectedHalf: value
    })
}
    componentDidMount = () => {
        const { id } = this.props
        const [state] = this.context
        const profile = state.employees_collection.filter(profile => profile.useremail === id || profile.companyID === id)[0]
        this.setState({
            companyID: profile.companyID
        })
        {
            if (this.props.listAll) 
            {
                firebase.firestore().collection("Payrolls").get().then(snap => {
                    const payrolls = snap.docs.map(doc => doc.data())
                    console.log(payrolls)
                    //  this.setState({
                    //      Payrolls: payrolls
                    //  })
                })
            }
             else 
             {
                firebase.firestore().collection("Payrolls").doc(profile.companyID).collection("Docs").get().then(snap => {
                    const payrolls = snap.docs.map(doc => doc.data())
                    console.log(payrolls)
                    this.setState({
                        Payrolls: payrolls
                    })
                })
                firebase.firestore().collection("Payrolls").doc(profile.companyID).collection("PayrollSettings").doc("settings").get().then(snap => {
                    const settings = snap.data()
                    console.log(settings)
                    this.setState({
                        dealPay: settings.dealPay,
                        payBonus: settings.payBonus,
                        excludeDeductions: settings.excludeDeductions,
                        insurance: settings.insurance,
                        isBench: settings.isBench,
                        fixedPay: settings.fixedPay,
                        fixedBenchPayroll: settings.fixedBenchPayroll,
                        dealPeriod: settings.dealPeriod,
                        stopPayroll: settings.stopPayroll


                    })
                })
            }

        }

    }
    render() {
        return (
            <div>
                <Presentation
                    {...this.props}
                    {...this.state}
                    handleDocId={this.handleDocId}
                    handleDateChange={this.handleDateChange}
                    handleChange={this.handleChange}
                />
            </div>
        )
    }
}

export default Container
