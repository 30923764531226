import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { DataContext } from '../../../../../contexts/data'
import Validations from '../../../../generalcomponents/validation'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
}))

function Presentation(props) {
  const { handleEmployees, fillableSections, handleSave, employees, approvals } = props
  const [state] = useContext(DataContext)
  let names = state.names
  let supervisors = []
  approvals.forEach((employee) => {
    names.forEach((name) => {
      if (name.uid === employee.email) {
        supervisors.push(name)
      }
    })
  })
  const validate = new Validations()
  const classes = useStyles()
  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid container>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={supervisors}
              className={classes.formControl}
              getOptionLabel={(option) =>
                validate.emailToName(option.uid)
              }
              value={supervisors.filter((employee) => {
                return employees.includes(employee.companyID)
              })}
              filterSelectedOptions
              onChange={(_, v) =>
                handleEmployees(v.map((item) => item.companyID))
              }
              renderInput={(params) => (
                <TextField fullWidth {...params} label='Add employees' />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {fillableSections.includes('expense-details') ? (
              <Button
                type='submit'
                variant='contained'
                onClick={() => handleSave('new')}
                color='primary'
              >
                Done
              </Button>
            ) : (
              <Button
                type='submit'
                variant='contained'
                onClick={() => handleSave('update')}
                color='secondary'
              >
                Update
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default Presentation
