import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import ActiveMembers from '../ActiveMembers'
import { DataContext } from '../../../contexts/data'
import Validations from '../../generalcomponents/validation'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    background: 'linear-gradient(45deg, #280071 10%, #c42053 90%)',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  new: {
    backgroundColor: "black",
    color: "white"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Presentation(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [state] = useContext(DataContext)
  const [selectedStartDate, setSelectedStartDate] = React.useState('');
  const [selectedEndDate, setSelectedEndDate] = React.useState('');
  const { handleCreate, handleChange, handleDateChange, handleEmployees, errCount, newProject, clearValues, handleCheck, labels } = props
  const handleStartDateChange = date => {
    setSelectedStartDate(date);
    handleDateChange("startdate", date)
  };

  const handleEndDateChange = date => {
    setSelectedEndDate(date);
    handleDateChange("enddate", date)
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedStartDate('')
    setSelectedEndDate('')
    clearValues()
  };
  const projectExist = state.projectList.filter(project => project.title.trim().toLowerCase() === newProject.name.trim().toLowerCase()).length
  const projectIdExist = state.projectList.filter(project => project.cid.trim().toLowerCase() === newProject.projectId.trim().toLowerCase()).length
  function  AvoidSpace(event) {
    var k = event ? event.which : window.event.keyCode;
    if (k == 32) return false;
}

    
    
    
  const validate = new Validations()
  return (
    <div>
      <Tooltip title="New project" aria-label="excel_tooltip">
        <Fab size="small" onClick={handleClickOpen} className={classes.new} aria-label="new">
          <AddIcon />
        </Fab>
      </Tooltip>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              New project
            </Typography>
            <React.Fragment>
              <Typography variant="h6" gutterBottom>

              </Typography>
              <form onSubmit={(e) => {
                handleCreate(e)
                setOpen(false)
                setSelectedStartDate('')
                setSelectedEndDate('')
              }} >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      required
                      id="projectname"
                      name="name"
                      label="Name"
                      onChange={handleChange}
                      fullWidth
                     // onkeypress ={AvoidSpace(event)}
                    />
                    {
                      projectExist ? <p className="text-danger" >This title already exists! choose another title</p> : null
                    }
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      required
                      name="projectId"
                      label="Project ID"
                      helperText={newProject.projectId.length >= 3 && newProject.projectId.length <= 10 ? "" : "Project ID should contain min. 3 and max. 10 characters"}
                      onChange={handleChange}
                      fullWidth
                    />
                    {
                      projectIdExist ? <p className="text-danger" >This project ID already exists! choose another ID</p> : null
                    }

                  </Grid>

                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}  >
                          <KeyboardDatePicker
                            required
                            margin="normal"
                            id="date-picker-startdate"
                            label="Start date"
                            format="MM/dd/yyyy"
                            value={selectedStartDate ? selectedStartDate : null}
                            onChange={handleStartDateChange}
                            name="startdate"
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}  >
                          <KeyboardDatePicker
                            margin="normal"
                            required
                            id="date-picker-enddate"
                            label="End date"
                            format="MM/dd/yyyy"
                            minDate={selectedStartDate}
                            value={selectedEndDate ? selectedEndDate : null}
                            onChange={handleEndDateChange}
                            name="enddate"
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel required htmlFor="grouped-select">Status</InputLabel>
                      <Select name="status" onChange={handleChange} input={<Input id="grouped-select" />}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Open">Open</MenuItem>
                        <MenuItem value="Closed">Closed</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormControlLabel
                        control={<Checkbox checked={newProject.useTimeline} onChange={(event) => handleCheck(event)} name="useTimeline" />}
                        label="Timeline"
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormControlLabel
                        control={<Checkbox checked={newProject.useLabels} onChange={(event) => handleCheck(event)} name="useLabels" />}
                        label="Labels"
                      />
                    </Grid>
                  <Grid item xs={12}>
                    <ActiveMembers inProject={false} handleEmployees={handleEmployees} />
                  </Grid>

                  <br />
                  {
                    errCount === 0 && !projectExist && !projectIdExist && validate.checkDateDiff(selectedStartDate, selectedEndDate) ?
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Create
                      </Button>
                      :
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled
                      >
                        Create
                      </Button>
                  }

                </Grid>
              </form>
            </React.Fragment>
          </Paper>
        </main>


      </Dialog>
    </div>
  );
}
