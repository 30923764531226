import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../contexts/data'
import { EMPLOYEE_COLLECTION, SET_EMPLOYEES_COLLECTION, SET_ACTIVE_EMPLOYEES, SET_PROJECTS, HISTORY, EMPLOYEE_NAMES, SET_LETTERS, SET_REQUESTED_LETTERS, SET_SIGNATURES, SET_CUSTOM_KEY } from '../../contexts/types'
import firebase from '../../firebase_config'
import Axios from 'axios'
import IdleTimer from 'react-idle-timer'

class Container extends Component {

    constructor(props){
        super(props)
        this.idleTimer = null
    }
    static contextType = DataContext
    state = {
        projects:[],
        employees:[],
        isTimedOut:false
    }

    componentDidMount = () => {
        console.log(this.context)
        const [ state, dispatch ] = this.context 
        // let inc = 1
        // inc++
        // setInterval(console.log(),1000)
        this.setState({dispatch:dispatch})   
        //  for admin & manager
        console.log(localStorage.getItem("role"))
       
            //  employee collection
            firebase.firestore().collection("Users")
            .where("isExist","==",true)
            .onSnapshot(employeeSnap => {
                let data = []
                employeeSnap.docs.forEach(doc => {
                    if(doc.data().Role !== "Admin")
                        data.push(doc.data())
                })
                if(state.access_modules.includes('console-customization') || state.access_modules.includes('employees-manager')){
                    dispatch({
                        type:SET_EMPLOYEES_COLLECTION,
                        payload:data
                    })
                    dispatch({
                        type:SET_ACTIVE_EMPLOYEES,
                        payload:data.filter(employee => employee.Role !== "Admin" && employee.status === "Active")
                    })
                }
                dispatch({
                    type : EMPLOYEE_NAMES,
                    payload : employeeSnap.docs.map(doc => {
                        return {
                            uid : doc.data().useremail,
                            name : doc.data().hasOwnProperty('personal') && Object.keys(doc.data().personal).length ? doc.data().personal.firstname + " " + doc.data().personal.middlename + " " + doc.data().personal.lastname : doc.data().useremail ,
                            photoURL : doc.data().imageURL,
                            isSupervisor : doc.data().hasOwnProperty('personal')&& Object.keys(doc.data().personal).length && doc.data().personal.hasOwnProperty('isSupervisor') && doc.data().personal.isSupervisor ? true : false,
                            designation : doc.data().hasOwnProperty('Role') && doc.data().Role,
                            jobtitle : doc.data().hasOwnProperty('personal') ? doc.data().personal.jobtitle : "",
                            status : doc.data().status,
                            companyID : doc.data().companyID,
                            gender: doc.data().hasOwnProperty('personal') ? doc.data().personal.gender : ""
                        }
                    })
                })

            })

        // for all

        firebase.firestore().collection("Projects")
        .where("isExist","==",true)
        .onSnapshot(projectsSnap => {
            let projects = []
            if((state.access_modules.includes('console-customization') || state.access_modules.includes('task-management-manager') || state.employee.role === "admin"))
                projects = projectsSnap.docs.map(doc => doc.data())
            else if(state.access_modules.includes('task-management'))
                projectsSnap.docs.forEach(doc => {
                    if(doc.data().createdBy === localStorage.getItem("ownerUID") || doc.data().Users.map(user => user.uid).includes(localStorage.getItem("ownerUID")))
                        projects.push(doc.data())
                })
                
                //  for projects
            dispatch({
                type:SET_PROJECTS,
                payload:projects
            })
        })

        if(state.access_modules.includes("timesheets-manager") || state.access_modules.includes("console-customization")){
            firebase.firestore().collection("ClientDetails").where("isExist", "==", true).onSnapshot(snap => {
                const clients = snap.docs.map(doc => doc.data())
                dispatch({
                    type:SET_CUSTOM_KEY,
                    payload:clients,
                    key : 'client_list'
                })
            })

            firebase.firestore().collection("Placements").where("isExist", "==", true).onSnapshot(snap => {
                const placements = snap.docs.map(doc => doc.data())
                dispatch({
                    type: SET_CUSTOM_KEY,
                    payload: placements,
                    key : "placements_list"
                })
            })

            firebase.firestore().collection("Invoices").where("isExist", "==", true).onSnapshot(snap => {
                const invoices = snap.docs.map(doc => doc.data())
                console.log(invoices)
                dispatch({
                    type: SET_CUSTOM_KEY,
                    payload: invoices,
                    key: "invoices_list"
                })
            })

            firebase.firestore().collection("Timesheets").onSnapshot(snap => {
                const timesheets = snap.docs.map(doc => doc.data())
                dispatch({
                    type: SET_CUSTOM_KEY,
                    payload: timesheets,
                    key: "timesheets_list"
                })
            })

            firebase.firestore().collection("Expenses").onSnapshot(snap => {
                const expenses = snap.docs.map(doc => doc.data())
                dispatch({
                    type: SET_CUSTOM_KEY,
                    payload: expenses,
                    key: "expenses_list"
                })
            })
            firebase.firestore().collection("Payments").onSnapshot(snap => {
                const payments = snap.docs.map(doc => doc.data())
                dispatch({
                    type: SET_CUSTOM_KEY,
                    payload: payments,
                    key: "payments_list"
                })
            })
            
        }else if(state.access_modules.includes("timesheets")){
            
            firebase.firestore().collection("Placements").where("empCode", "==", state.employee.companyID).where("isExist", "==", true).onSnapshot(snap => {
                const placements = snap.docs.map(doc => doc.data())
                dispatch({
                    type: SET_CUSTOM_KEY,
                    payload: placements,
                    key : "placements_list"
                })
            })

            firebase.firestore().collection("Timesheets").where("empCode", "==", state.employee.companyID).onSnapshot(snap => {
                const timesheets = snap.docs.map(doc => doc.data())
                dispatch({
                    type: SET_CUSTOM_KEY,
                    payload: timesheets,
                    key: "timesheets_list"
                })
            })

            firebase.firestore().collection("Expenses").where("empCode", "==", state.employee.companyID).onSnapshot(snap => {
                const expenses = snap.docs.map(doc => doc.data())
                dispatch({
                    type: SET_CUSTOM_KEY,
                    payload: expenses,
                    key: "expenses_list"
                })
            })

            firebase.firestore().collection("ClientDetails").where("isExist", "==", true).onSnapshot(snap => {
                const clients = snap.docs.map(doc => doc.data())
                dispatch({
                    type:SET_CUSTOM_KEY,
                    payload:clients,
                    key : 'client_list'
                })
            })

        }
        

        // for history

        // firebase.firestore().collection("History")
        // .orderBy("createdAt","desc")
        // .onSnapshot(snap => {
        //     let history = snap.docs.map(doc => doc.data())
        //     const filteredHistory = history.filter(item => item.ActionBy === localStorage.getItem('ownerUID'))
        //     dispatch({
        //         type : HISTORY,
        //         payload : state.employee.role === 'user' ? filteredHistory : history
        //     })
        // })

        firebase.auth().onIdTokenChanged((user) => {
            if(user){
                user.getIdTokenResult().then(tokenResult => {
                    console.log(tokenResult.claims)
                    Axios.defaults.headers.common['Authorization'] = user.uid+' '+tokenResult.token
                })
            }
        })

        firebase.firestore().collection("Resources/Templates/LetterTemplates").where('isActive',"==",true).onSnapshot(snap => {
            let letters = snap.docs.map(doc => doc.data())
            dispatch({
                type : SET_LETTERS,
                payload : letters
            })
        }) 
        
        firebase.firestore().collection("LetterRequests").onSnapshot(snap => {
            let requests = snap.docs.map(doc => doc.data())
            dispatch({
                type : SET_REQUESTED_LETTERS,
                payload : requests
            })
        })

        firebase.firestore().collection("authorisedSignatures").where("isActive","==",true).onSnapshot(snap => {
            let signatures = snap.docs.map(doc => doc.data())
            dispatch({
                type : SET_SIGNATURES,
                payload : signatures
            })
        })

        
        // dispatch({
        //     type : SET_COUNTRY_API,
        //     payload : dispatch
        // })

        // for manager & employee
            firebase.firestore().collection("Users")
            .where("isExist","==",true)
            .where("useremail","==",localStorage.getItem("ownerUID"))
            .limit(1)
            .onSnapshot(snap => {
                console.log(snap.docs[0].data())
                localStorage.setItem("imageURL",snap.docs[0].data().imageURL)
                dispatch({
                    type:EMPLOYEE_COLLECTION,
                    payload:snap.docs[0].data()
                })
            })
    }


    
    
    onAction(e) {
        // console.log('user did something', e)
      } 

    onActive = () => {
      //  console.log('time remaining', this.idleTimer.getRemainingTime())
        // this.setState({isTimedOut: false})
    }

    onIdle = () => {
        console.log('last active', this.idleTimer.getLastActiveTime())
        if(this.state.isTimedOut){
            // localStorage.setItem('imageURL','')
            // localStorage.setItem('role','')
            // localStorage.setItem('email','')
            return this.setState({isTimedOut: true})
            
         }
         return firebase.auth().signOut()
    }
 
    render() {
        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    onIdle={this.onIdle}
                    onActive={this.onActive}
                    onAction={this.onAction}
                    element={document}
                    debounce={250}
                    timeout={900000} 
                />
                <Presentation />
            </div>
        )
    }
}

export default Container
