import React, { Component } from 'react';
import Presentation from "./Presentation";
import { DataContext } from '../../../../../../contexts/data'
import { TEMPORARY_NEW_CLIENT_DATA, SET_COUNTRY_API } from '../../../../../../contexts/types'
import Axios from 'axios'

class Container extends Component {
    state = {
        contacts: [],
        representativeName: '',
        jobTitle: '',
        contactType: '',
        gender: '',
        email: '',
        mobile: '',
        workPhone: '',
        homePhone: '',
        line1: '',
        line2: '',
        country: '',
        state_name: '',
        city: '',
        zip: '',
        states: []
    }

    static contextType = DataContext

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    componentDidMount = async () => {
        const dispatch = this.context[1]
        dispatch({
            type: SET_COUNTRY_API,
            payload: dispatch
        })
        const { representativeName, jobTitle, contactType, gender, email, mobile, workPhone, homePhone, line1, line2, country, state_name, city, zip } = this.state
        if (!this.state.contacts.length) {
            let data = []
            let payload = {
                representativeName,
                jobTitle,
                contactType,
                gender,
                email,
                mobile,
                workPhone,
                homePhone,
                line1,
                line2,
                country,
                state_name,
                city,
                zip,
                id: data.length + 1
            }
            data = [...data, payload]
            this.setState({ contacts: data })
        }
    }

    handleCreate = () => {
        const [state, dispatch] = this.context
        const { representativeName, jobTitle, contactType, gender, email, mobile, workPhone, homePhone, line1, line2, country, state_name, city, zip } = this.state
        let data = ('contacts' in state.temporary_client_data) ? state.temporary_client_data.contacts : this.state.contacts
        let payload = {
            representativeName,
            jobTitle,
            contactType,
            gender,
            email,
            mobile,
            workPhone,
            homePhone,
            line1,
            line2,
            country,
            state_name,
            city,
            zip,
            id: data.length + 1
        }
        data = [...data, payload]
        this.setState({ contacts: data })
        dispatch({
            type: TEMPORARY_NEW_CLIENT_DATA,
            payload: {
                key: 'contacts',
                contacts: data
            }
        })
        this.clearValues()
    }

    geoState = async (code) => {
        let states = await Axios.post("/loadstates", { countrycode: code })
        this.setState({ states: states.data })
    }

    handleCountryAndState = (name, props) => {
        const [state] = this.context
        this.setState({
            [name]: props.name
        })
    }

    clearValues = () => {
        this.setState({
            representativeName: '',
            jobTitle: '',
            contactType: '',
            gender: '',
            email: '',
            mobile: '',
            workPhone: '',
            homePhone: '',
            line1: '',
            line2: '',
            country: '',
            state_name: '',
            city: '',
            zip: '',
        })
    }

    handleSave = (e, name) => {
        const { handleChange } = this.props
        if (name === 'back') {
            handleChange(e, 0)
        }
        else if (name === 'next') {
            handleChange(e, 2)
        }
        else this.handleCreate()

    }

    render() {
        const [state] = this.context
        if (state.hasOwnProperty('temporary_client_data') && state.temporary_client_data.contacts) {
            if (Object.keys(state.temporary_client_data.contacts).length) {
                return (
                    <div>
                        <Presentation
                            {...this.state}
                            handleChange={this.handleChange}
                            handleSave={this.handleSave}
                            geoState={this.geoState}
                            handleCountryAndState={this.handleCountryAndState}
                        />
                    </div>
                );
            }
            return (<p>Loading...</p>)
        }
        return (
            <div>
                <Presentation
                    {...this.state}
                    handleChange={this.handleChange}
                    handleSave={this.handleSave}
                    geoState={this.geoState}
                    handleCountryAndState={this.handleCountryAndState}
                />
            </div>
        );
    }
}

export default Container;