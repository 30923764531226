import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';  
import { DataContext } from '../../../../../contexts/data'
import { Link } from 'react-router-dom'
import Validation from '../../../../generalcomponents/validation'
import { configuration } from '../../../../../configuration'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

const useStyles = makeStyles({
  table: {
    width: "100%",
    backgroundColor:"#fafafa",
    overflowX:"inherit"
  },
});


const customStyles = {
  employeeExist:{
    color : '#4183c4',
    textTransform : 'capitalize'
  },
  employeeNonExist : {
    color : '#626466',
    textTransform : 'capitalize',
  }
}


export default function DenseTable(props) {
  const classes = useStyles();
  let rows = []
  const labels = props.labels
  const [ state ] = useContext(DataContext)
  const taskId = props.taskId
  const projectId = state.project.id
  console.log(taskId)
  state.taskList.forEach(task => {
      if(task.taskId === taskId)
        rows.push(task)
  })

  dayjs.extend(relativeTime)
console.log(rows)
  const diff = dayjs()

  const getDueBy = (enddate, status) => {
    console.log(new Date() - new Date(enddate))
    if(status === 'Completed' || status === 'Closed')
      return false
    return new Date() - new Date(enddate) >=0 ? true : false
  }
  
  const validate = new Validation()
  if(rows.length > 0)
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell width="2%"/>
            <TableCell width="10%" align="left">Task ID</TableCell>
            {
              labels === true 
              ?
                <TableCell width="10%" align="left">Labels</TableCell>
              : ""
            }
            <TableCell align="left">Title</TableCell>
            <TableCell width="10%" align="left">Due by</TableCell>
            <TableCell width="10%" align="left">Priority</TableCell>
            <TableCell width="15%" align="left">CreatedBy</TableCell>
            <TableCell width="15%" align="left">Assignee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.title}>
              <TableCell></TableCell>
              <TableCell align="left"><Chip size="small" label={state.project.cid + " - " + row.cid} /></TableCell>
              { 
                labels === true 
                ?
                  <TableCell component="th" scope="row">
                  {
                    row.labels.length === 0 
                    ?
                      ""
                    :
                      row.labels.map(element => <span><Chip size="small" style={{ backgroundColor: `${element.colorCode}`, color: "white", marginBottom: '2px' }} label={element.name} /><br/></span>)
                  }
                  </TableCell>
                :
                  ""
              }
              <TableCell align="left" component="th" scope="row">
              <Link to={"/console/projects/"+projectId+"/"+row.id} ><Button type="a" style={{textTransform:"capitalize",color:"#4183c4"}} size="small">{row.title}</Button></Link>
              <br/>
              {
                  row.type === "Task"?
                      <Chip size="small" style={{backgroundColor:"#17a2b8", color:"white"}} label="Task" /> 
                  :
                      <Chip size="small" style={{backgroundColor:"#db2828",color:"white"}} label="Bug" /> 
              }&nbsp;&nbsp;&nbsp;
              {
                    row.status === "Open"
                    ?
                        <Chip variant="outlined" size="small" style={{borderColor:"#21ba45", fontWeight:"bold", color:"#21ba45"}} label="Open" /> 
                    : 
                        row.status === "InProgress"
                        ?
                            <Chip variant="outlined" size="small" style={{borderColor:"#f78a14", fontWeight:"bold", color:"#f78a14"}} label="InProgress" /> 
                        :
                            <Chip variant="outlined" size="small" label="Closed" /> 
                }
                &nbsp;&nbsp;&nbsp;{ row.startdate === "" ? row.startdate : Intl.DateTimeFormat(configuration["date-code"],configuration.dateformat).format(new Date(row.startdate)) }&nbsp;&nbsp;&nbsp;{ row.enddate === "" ? row.enddate : Intl.DateTimeFormat(configuration["date-code"],configuration.dateformat).format(new Date(row.enddate)) }
              </TableCell>
              <TableCell align="left">{ getDueBy(row.enddate, row.status) ? diff.from(row.enddate, true) : '---'} </TableCell>
              <TableCell align="left">{row.priority}</TableCell>
              <TableCell align="left">{validate.emailToName(row.createdBy)}</TableCell>
              <TableCell align="left">{row.assignee.map(employee => <span><Link to={"/console/employeelist/"+employee} ><Button type="a" style={state.projectList.filter(project => project.id === state.project.id)[0].Users.map(user => user.uid).includes(employee) ? customStyles.employeeExist : customStyles.employeeNonExist} size="small">{validate.emailToName(employee)}</Button></Link><br/></span>)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return <div></div>
}
