import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../contexts/data'
import {
  PLACEMENT_SETTINGS_ACTIONS
} from '../../../../../contexts/types'

class Container extends Component {
  state = {
    comments: '',
    billableClient: '',
    jobTitle: '',
    startDate: '',
    endDate: '',
    projectEndDate: '',
    profile: {},
    fillableSections: [],
    docId: '',
    netTerms: '',
    client: ''
  }

  static contextType = DataContext

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  getData = (req_placement) => {
    if (Object.keys(req_placement).length) {
      this.setState({
        comments: req_placement.description,
        billableClient: req_placement.billableClient,
        jobTitle: req_placement.jobTitle,
        startDate: req_placement.startDate,
        endDate: req_placement.endDate,
        projectEndDate: req_placement.projectEndDate,
        netTerms: req_placement.netTerms.toString(),
      })
    }
  }

  componentDidMount = () => {
    this.getData(this.props.req_placement)
  }

  componentWillReceiveProps = (nextProps) => {
    const { req_placement, profile } = nextProps
    const { id, fillableSections, client } = req_placement
    this.setState({
      profile,
      fillableSections,
      docId: id,
      client
    })
    if(id.length && this.props.req_placement.id !== id) this.getData(req_placement)
  }

  handleDateChange = (key, value) => {
    if (!isNaN(Date.parse(value))) {
      if (key === 'endDate' && value) {
        this.setState({
          projectEndDate: value,
        })
      }
      this.setState({
        [key]: value.toISOString(),
      })
    }
  }

  placementSettingsActionsWiki = () => {}

  handleCreate = () => {
    const dispatch = this.context[1]
    const {
      comments,
      billableClient,
      profile,
      startDate,
      endDate,
      projectEndDate,
      jobTitle,
      netTerms,
      client
    } = this.state
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
      payload: {
        empCode: profile.companyID,
        billableClient: billableClient,
        jobTitle: jobTitle,
        description: comments,
        startDate: startDate,
        endDate: endDate,
        projectEndDate: projectEndDate,
        client: client,
        netTerms: netTerms ? parseInt(netTerms) : 0,
        sectionName: 'general',
        placementID: 'new'
      },
      actionType: 'new'
    })
  }

  handleUpdate = () => {
    const dispatch = this.context[1]
    const {
      comments,
      billableClient,
      profile,
      startDate,
      endDate,
      projectEndDate,
      jobTitle,
      docId,
      netTerms,
      client
    } = this.state
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
      payload: {
        placementID: docId,
        sectionName: 'general',
        billableClient: billableClient,
        jobTitle: jobTitle,
        description: comments,
        startDate: startDate,
        endDate: endDate,
        projectEndDate: projectEndDate,
        netTerms: netTerms ? parseInt(netTerms) : 0,
        client: client
      },
      actionType: 'update'
    })
  }

  handleSave = (type) => {
    if (type === 'create') this.handleCreate()
    else if (type === 'update') this.handleUpdate()
    else console.log('Error')
  }

  render() {
    return (
      <div>
        <Presentation
          {...this.state}
          handleChange={this.handleChange}
          handleSave={this.handleSave}
          handleDateChange={this.handleDateChange}
        />
      </div>
    )
  }
}

export default Container
