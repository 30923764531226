import React, { useContext } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import { IconButton, Tooltip, ButtonGroup, Button } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ContactCard from '../ContactCard'
import EditContact from '../EditContact'
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';




function Presentation(props) {
    const { clientId, contacts, isLoading, onDeleteContact } = props
    const columns = [
        { title:'Name', field: 'representativeName'},
        { title:'Job title', field: 'jobTitle'},
        { title:'Email', field: 'email'},
        { title:'Phone no', field: 'workPhone'},
        { title: 'Actions', field: '', 
            render: (row) => <div className="d-flex" >
                        <ContactCard key={row.id} client={props.client} contact={row} edit={true} />
                        <EditContact btnContent={<EditIcon />} key={row.id} clientId={props.client.id} contact={row} edit={true} />
                        <IconButton onClick={() => onDeleteContact(row.id)} ><DeleteIcon /></IconButton>
                    </div>  
        }
    ]
    let data = contacts
    console.log(data)
        return (
            <div>
                <MaterialTable 
                    isLoading={isLoading}
                    data={data}
                    columns={columns}
                    title=""
                    components={{
                        Toolbar: tableProps => (
                          <div>
                            <MTableToolbar {...tableProps} />
                            <div style={{marginTop:'-38px',marginLeft:'8px',marginBottom: '10px'}}>
                                <Tooltip title="Add contact" >
                                    <ButtonGroup variant="contained" color="primary" aria-label="outlined primary button group">
                                        <EditContact btnContent={<AddCircleOutlineIcon />} clientId={props.client.id} edit={false} />
                                    </ButtonGroup>
                                </Tooltip>
                            </div>
                          </div>
                        ),
                    }}
                />
            </div>
        )
}

export default Presentation
