import React, { Component } from 'react'
import Presentation from './Presentation'
import firebase from '../../../../firebase_config'

class Container extends Component {
    state = {
        letters: [],
        value: 0
    }

    componentDidMount = () => {
        let letters = []
        firebase.firestore().collection("Resources/Templates/LetterTemplates")
            .where("isActive", "==", true)
            .onSnapshot(snap => {
                letters = snap.docs.map(doc => doc.data())
                console.log(letters)
                this.setState({ letters })
            })
    }

    handleChange = (e, v) => {
        this.setState({ value: v })
    }


    render() {
        return (
            <div>
                <Presentation
                    {...this.state}
                    handleChange={this.handleChange}
                />
            </div>
        )
    }
}

export default Container
