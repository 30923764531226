import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import SendIcon from '@material-ui/icons/Send'
import ChipInput from 'material-ui-chip-input'
import SunEditor from 'suneditor-react'
import { GoFileSymlinkFile } from 'react-icons/go'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
    opacity: 0,
  },
  layout: {
    width: '100%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    width: '100%',
    margin: theme.spacing(0, 1, 1, 1),
    padding: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  input: {
    display: 'none',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function Presentation(props) {
  const {
    handleClick,
    clientId,
    to,
    cc,
    bcc,
    handleAdd,
    handleDelete,
    subject,
    body,
    handleChange,
    handleData,
    helperText,
    isUploading,
    attachment,
    handleDeleteAttachment,
    handleChange2,
  } = props
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div className='mt-1'>
      <IconButton onClick={handleClickOpen}>
        <SendIcon fontSize='small' color='inherit' />
      </IconButton>
      <Dialog
        disableEscapeKeyDown
        disableBackdropClick
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullWidth
        maxWidth='md'
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component='h1' variant='h4' align='center'>
              Compose
            </Typography>
            <React.Fragment>
              <Typography variant='h6' gutterBottom></Typography>
              <form onSubmit={(e) => {}}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <ChipInput
                      value={to}
                      onAdd={(chip) => handleAdd('to', chip)}
                      onDelete={(_, index) => handleDelete('to', index)}
                      allowDuplicates={false}
                      label='To *'
                      fullWidth
                      helperText={helperText.to}
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ChipInput
                      value={cc}
                      onAdd={(chip) => handleAdd('cc', chip)}
                      onDelete={(_, index) => handleDelete('cc', index)}
                      allowDuplicates={false}
                      label='CC'
                      fullWidth
                      helperText={helperText.cc}
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ChipInput
                      value={bcc}
                      onAdd={(chip) => handleAdd('bcc', chip)}
                      onDelete={(_, index) => handleDelete('bcc', index)}
                      allowDuplicates={false}
                      label='BCC'
                      fullWidth
                      helperText={helperText.bcc}
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={subject}
                      onChange={handleChange}
                      name={'subject'}
                      fullWidth
                      size='small'
                      label='Subject'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SunEditor
                      placeholder='Body'
                      setContents={body}
                      onChange={(data) => handleData(data)}
                      setOptions={{
                        buttonList: [
                          [
                            'undo',
                            'redo',
                            'font',
                            'fontSize',
                            'formatBlock',
                            'bold',
                            'underline',
                            'italic',
                            'strike',
                            'subscript',
                            'superscript',
                            'fontColor',
                            'hiliteColor',
                            'removeFormat',
                            'outdent',
                            'indent',
                            'align',
                            'horizontalRule',
                            'list',
                            'lineHeight',
                            'table',
                            'link',
                            'image',
                            'video',
                            'showBlocks',
                            'codeView',
                          ],
                        ],
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <input
                      className={classes.input}
                      id='contained-button-file-wafile'
                      multiple
                      type='file'
                      onChange={handleChange2}
                    />
                    <label htmlFor='contained-button-file-wafile'>
                      <Button
                        variant='contained'
                        color='primary'
                        component='span'
                      >
                        <span>
                          <AttachFileIcon fontSize='small' color='inherit' />{' '}
                          Attachment
                        </span>
                      </Button>
                    </label>
                    {attachment.map((doc, index) => (
                      <div className='d-flex justify-content-between'>
                        <p>
                          {doc !== '' ? (
                            <a
                              target='_blank'
                              rel='noopener noreferrer'
                              href={doc}
                            >
                              <GoFileSymlinkFile size={22} />{' '}
                              {doc.slice(106, doc.lastIndexOf('_')) +
                                '_' +
                                (index + 1)}
                            </a>
                          ) : (
                            <p>No file choosen</p>
                          )}{' '}
                        </p>
                        <IconButton
                          onClick={() => handleDeleteAttachment(index)}
                        >
                          <DeleteIcon fontSize='small' />
                        </IconButton>
                      </div>
                    ))}
                    {isUploading ? <p>Uploading please wait...</p> : ''}
                  </Grid>
                </Grid>
              </form>
              <br />
              <div className='text-center'>
                <Button
                  autoFocus
                  onClick={() => {
                    handleClose()
                    handleClick(clientId)
                  }}
                  color='primary'
                  fullWidth
                  disabled={!to.length}
                  variant='contained'
                >
                  Send
                </Button>
              </div>
            </React.Fragment>
          </Paper>
        </main>
      </Dialog>
    </div>
  )
}

export default Presentation
