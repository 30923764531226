import React, { Component } from 'react';
import Presentation from './Presentation';
import firebase from '../../../../firebase_config'
import { DataContext } from '../../../../contexts/data'
import { SET_AUTHORIZED_SIGNATURES, DELETE_AUTHORISED_SIGNATURE } from '../../../../contexts/types'

class Container extends Component {

    static contextType = DataContext

    handleDelete = (signatureId, signatureUID) => {
        if(!window.confirm("Are you sure you want to delete the signature?"))
            return null
        const dispatch = this.context[1]
        dispatch({
            type : DELETE_AUTHORISED_SIGNATURE,
            payload : {
                signatureId,
                signatureUID
            }
        })
    }

    componentDidMount = () => {
        const dispatch = this.context[1]
        firebase.firestore().collection('authorisedSignatures').where("isActive","==",true)
        .onSnapshot(snap => {
            const signatures = snap.docs.map(doc => doc.data())
            dispatch({
                type: SET_AUTHORIZED_SIGNATURES,
                payload: signatures
            })
        })
    }

    render() {
        const [state] = this.context
        if(state.hasOwnProperty('auth_signatures'))
            return (
                <Presentation
                    handleDelete={this.handleDelete}
                />
            );
        return <p>Loading...</p>
    }
}

export default Container;