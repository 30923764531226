import React, { useContext } from 'react';
import {
    Grid,
    TextField,
    Divider,
    Typography,
    Button,
    MenuItem,
    makeStyles
    //TextareaAutosize
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataContext } from '../../../../../contexts/data';
import Validations from '../../../../generalcomponents/validation'

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            decimalScale={2}
            format="##"
            allowNegative={false}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isNumericString
        />
    );
}

function FederalIdPattern(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            decimalScale={2}
            format="##-######"
            allowNegative={false}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isNumericString
        />
    );
}

const validateFederalId = (id) => {
    return id.trim().length === 8 
}

const Presentation = (props) => {
    const [state] = useContext(DataContext)
    const classes = useStyles()
    let countryList = state.hasOwnProperty('geo_data') ? state.geo_data.countries : []
    const { states, handleCountryAndState, geoState, handleChange, id, handleAdd } = props
    const { businessName, contactNumber, website, jobTerminationNotice, category } = props
    const { businessDisplayName, email, federalId, netTerms, fax } = props
    const { line1, line2, country, state_name, city, zipCode } = props
    const validate = new Validations()
    return (
        <div className="custom-card">
            <form onSubmit={(e) => {
                e.preventDefault()
                handleAdd(e)
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            label="Business Name"
                            helperText={businessName.length ? validate.checkName(businessName) ? "" : "Enter valid Business name":""}
                            name="businessName"
                            value={businessName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            label="Business Display Name"
                            name="businessDisplayName"
                            helperText={businessDisplayName.length ? validate.checkName(businessDisplayName) ? "" : "Enter valid Business display name":""}
                            value={businessDisplayName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            label="Email"
                            name="email"
                            value={email}
                            helperText={email.length ? validate.checkEmail(email) ? "" : "Enter valid email id": ""}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            label="Contact Number"
                            name="contactNumber"
                            helperText={contactNumber.length ? validate.checkNumber(contactNumber) ? "" : "Enter valid contact number":""}
                            value={contactNumber}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            label="Federal ID"
                            placeholder="##-######"
                            name="federalId"
                            value={federalId}
                            //helperText={federalId.length ? validateFederalId(id) ? "" : "Enter valid federalId" : ""}
                            onChange={handleChange}
                            InputProps={{
                                inputComponent: FederalIdPattern,
                            }}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            label="Website"
                            name="website"
                            value={website}
                            helperText={website.length ? validate.checkWebsite(website) ? "" : "Enter valid website" : ""}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            label="Net Terms"
                            name="netTerms"
                            value={netTerms}
                            onChange={handleChange}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            select
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            label="Job Termination Notice"
                            name="jobTerminationNotice"
                            value={jobTerminationNotice}
                            onChange={handleChange}
                        >
                            <MenuItem value={''}>None</MenuItem>
                            {jobTerminationNoticeList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            select
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            label="Client Category"
                            name="category"
                            value={category}
                            onChange={handleChange}
                        >
                            <MenuItem value=''>None</MenuItem>
                            {categoryList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            label="Fax"
                            name="fax"
                            value={fax}
                            helperText={fax.length ? validate.checkNumber(fax) ? "" : "Enter valid fax number" : ""}
                            onChange={handleChange}
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextareaAutosize
                            placeholder="Comment"
                            marginTop={3}
                            rowsMin={5}
                            style={{ marginTop: '10px', width: '100%' }}
                            value={comments}
                            onChange={handleChange}
                        />
                    </Grid> */}
                </Grid>
                <br />
                <Typography variant="h6">Invoice Location</Typography>
                <Divider />
                <br />
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <TextField
                            label='Address Line 1'
                            name='line1'
                            value={line1}
                            variant='outlined'
                            size='small'
                            required
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label='Address Line 2'
                            name='line2'
                            value={line2}
                            variant='outlined'
                            size='small'
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label='City'
                            name='city'
                            value={city}
                            variant='outlined'
                            size='small'
                            required
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            id="country-select-demo"
                            fullWidth
                            options={countryList}
                            classes={{
                                option: classes.option,
                            }}

                            //inputValue={country ? country : null}
                            onChange={(event, value) => {
                                if (value) {
                                    handleCountryAndState('country', value)
                                    return geoState(value.iso2)
                                }

                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <span>{countryToFlag(option.iso2)}</span>
                                    {option.name} ({option.iso2}) +{option.phone_code}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Country *"
                                    variant="outlined"
                                    size="small"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <p className="text-success" >Selected country:{country} </p>
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            id="country-select-demo"
                            fullWidth
                            options={states}

                            classes={{
                                option: classes.option,
                            }}
                            onChange={(event, value) => {
                                if (value) {
                                    handleCountryAndState('state_name', value)
                                }

                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderOption={(option) => (
                                <React.Fragment>
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="State *"
                                    variant="outlined"
                                    size="small"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <p className="text-success" >Selected state:{state_name} </p>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label='Zip code'
                            name='zipCode'
                            value={zipCode}
                            variant='outlined'
                            size='small'
                            required
                            helperText={zipCode.length ? validate.checkZip(zipCode) ? "" : "Enter valid zip code" : ""}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <br />
                {   
                    (validate.checkName(businessName) && validate.checkNumber(contactNumber) && validate.checkWebsite(website) && jobTerminationNotice.trim() && category.trim()
                        && validate.checkName(businessDisplayName) && validate.checkEmail(email) && netTerms.trim() && validateFederalId(federalId) &&
                        line1.trim() && country.trim() && state_name.trim() && city.trim() && validate.checkZip(zipCode)
                    ) ?
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                        >
                            Next
                        </Button> :
                        <Button
                            variant="contained"
                            color="primary"
                            disabled
                        >
                            Next
                        </Button>
                }
            </form>
        </div >
    );
}

export default Presentation;

const jobTerminationNoticeList = ['1-week', '2-weeks', '3-weeks', '4-weeks']

const categoryList = ['End Client', 'Prime-Contractor', 'Sub-Contractor']

const commissionTypeList = []



