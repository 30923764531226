import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
import { UPDATE_PROJECT } from '../../../../contexts/types'

export class Container extends Component {

    state = {
        title:"",
        startdate:"",
        enddate:"",
        status : "",
        firsttitle :"",
        useLabels: false,
        useTimeline: false
    }
    static contextType = DataContext


    componentDidMount = () => {
        const [ state ] = this.context
        console.log('Contxt ==')
        console.log(this.state)
        const project = state.projectList.filter(project => project.id === this.props.projectId)[0]
        this.setState({project})
        
        
        Object.entries(project).forEach(([key, value]) => {
            this.setState({
                [key] : value
            })
            console.log(key+''+value)
        })   
        //console.log('after project in state')
        //console.log(this.state)
        const ft = this.state.title
        this.setState({firsttitle : ft})
        //console.log('firsttitle')
        //console.log(this.state.firsttitle) 
    }

    handleCheck = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        })
    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
        console.log('handle change')
        console.log(e.target.name,e.target.value)
    }

    handleDateChange = (key, value) => {
        if(!isNaN(Date.parse(value))) 
        this.setState({
            [key]:value.toISOString()
        })
    }

    handleDropdown = (key, value) => {
        console.log('dropdown')
        console.log(value)
        this.setState({
            [key]:value
        })
    }

    handleEmployees = (value) => {
        this.setState({employees:value})
    }

    handleUpdate = (e) => {
        e.preventDefault()
        const dispatch = this.context[1]
        const { title, status, startdate, enddate, useLabels, useTimeline } = this.state
        dispatch({
            type : UPDATE_PROJECT,
            payload : {
                title,
                status,
                startdate,
                enddate,
                useLabels,
                useTimeline
            }
        })
    }

    componentWillUnmount = () => {
        this.setState({
            title:"",
            startdate:"",
            enddate:"",
            status : ""
        })
    }
    render() {
        const newProject = {
            title : this.state.title,
            status : this.state.status,
            startdate : this.state.startdate,
            firsttitle : this.state.firsttitle,
            useLabels: this.state.useLabels,
            useTimeline: this.state.useTimeline
        }
        if (this.state.firsttitle == ""){
            this.setState({
                firsttitle:this.state.title
            })
        }
        //console.log('test the newProject')
        //console.log(newProject)
        
        let errCount = 0
        Object.entries(newProject).forEach(([key, value]) => {
            if(key === "startdate" &&  isNaN(Date.parse(value)))
                errCount++;
            else if(value === "")
                errCount++; 
            //console.log('render')   
            //console.log(key, value)    
        })

        const oldContent = {
            ...newProject,
            enddate:this.state.enddate
        }
        return (
            <div>
                <Presentation
                    projectId={this.props.projectId}
                    handleChange = {this.handleChange}
                    handleDateChange = {this.handleDateChange}
                    handleEmployees = {this.handleEmployees}
                    errCount = {errCount}
                    handleUpdate = {this.handleUpdate}
                    handleDropdown = {this.handleDropdown}
                    oldContent={oldContent}
                    handleCheck={this.handleCheck}
                />
            </div>
        )
    }
}

export default Container
