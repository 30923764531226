

import React, { PureComponent } from 'react'
import PayrollsList from '../components/Payrolls/Index'

export class PayrollsPage extends PureComponent {
    render() {
        return (
            <div>
                    <PayrollsList listAll={true} />
            </div>
        )
    }
}

export default PayrollsPage
