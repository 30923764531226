import React, { Component } from 'react'
import Presentation from './Presentation'
import { Placement } from "../../../../../contexts/shared/Timesheets/Placements"
import { DataContext } from '../../../../../contexts/data'

export class Container extends Component {
    constructor(){
        super()
        this.state = {
            invoiceSettings: {}
        }
    }

    static contextType = DataContext

    componentWillReceiveProps = (nextProps) => {
        const placementID = nextProps.placementID
        if(placementID !== this.props.placementID && placementID !== ""){
            const [state] = this.context
            const placementDocId = state.placements_list.filter(item => item.placementID === placementID)[0].id
            const placementObj = new Placement(placementDocId)
            placementObj.getSettings("Invoices").then(settings => {
                this.setState({
                    invoiceSettings: settings
                })
                this.props.handleChange("invoiceSettings", settings)
            })
        }
    }
    
    render() {
        return (
            <div>
                <Presentation 
                    {...this.props}
                    invoiceSettings={this.state.invoiceSettings}
                    handleChange={this.props.handleChange}
                />
            </div>
        )
    }
}

export default Container
