import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
import { ADD_LETTER_TEMPLATE, UPDATE_LETTER_TEMPLATE, SET_LETTER_CONTENT, DELETE_LETTER_TEMPLATE } from '../../../../contexts/types'
import { configuration } from '../../../../configuration'

class Container extends Component {
    state = {
        content: "",
        type: "",
        name: "",
        helperText: []
    }

    static contextType = DataContext

    componentDidMount = () => {
        const [state] = this.context
        if(state.letter_content.updating)
            this.setState({ 
                content : state.letter_content.content,
                name : state.letter_content.name,
                type : state.letter_content.type
             })
        else 
            this.setState({ content : state.letter_content.defaultContent })     
    }


    handleContent = (data) => {
        this.setState({ content: data });
        let str = data.toString()
        console.log(str.split(" "))
        let newArray = new Array()
        let arr = str.split(" ")
        arr.forEach(element => {
            var tmpStr = element.match("~(.*)~");
            if (tmpStr !== null) newArray.push(tmpStr[1])
        });
        this.setState({
            helperText: newArray.filter(str => !configuration.draggableLabels.includes(str))
        })
    }


    handleChange = (e)  =>{
        this.setState({ [e.target.name]: e.target.value })
    }

    handleCreate = (e) => {
        e.preventDefault()
        const {content,type,name} = this.state
        const [ state, dispatch ] = this.context
        dispatch({
            type: ADD_LETTER_TEMPLATE,
            payload: {
                content ,
                type,
                name
            }
        })
    }

     handleUpdate = (e) => {
        e.preventDefault()
        const [state, dispatch] = this.context
        const {content,type,name} = this.state
        dispatch({
            type: UPDATE_LETTER_TEMPLATE,
            payload: {
                content : content || state.letter_content.content,
                type : type || state.letter_content.type,
                name : name || state.letter_content.name
            },
        })
        
    }

    handleDelete = () => {
        const [state, dispatch] = this.context    
        dispatch({
            type : DELETE_LETTER_TEMPLATE,
            payload: {
                id : state.letter_content.id
            },
            response : this.response
        })    
    }
    response = () => {
        this.cancelUpdate()
    }

    cancelUpdate = () => {
        const [state, dispatch] = this.context
        dispatch({
            type: SET_LETTER_CONTENT,
            payload: {
                id: '',
                name: '',
                type: '',
                defaultContent: state.letter_content.defaultContent,
                content: state.letter_content.defaultContent,
                updating: false
            }
        })

        this.setState({ 
            content : state.letter_content.defaultContent,
            type : '',
            name : ''
         }) 
    }

    componentWillUnmount = () => {
        this.setState({
            content: "",
            type: "",
            name: "",
        })
    }


    render() {
        return (
            <div>
                <Presentation
                    {...this.state}
                    handleContent={this.handleContent}
                    handleChange={this.handleChange}
                    handleCreate={this.handleCreate}
                    cancelUpdate={this.cancelUpdate}
                    handleUpdate={this.handleUpdate}
                    handleDelete={this.handleDelete}
                />
            </div>
        )
    }
}

export default Container
