import React, { useContext } from 'react'
import { FaRegFileExcel } from 'react-icons/fa'
import { DataContext } from '../../../../contexts/data'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStylesFacebook = makeStyles({
    root: {
      position: 'relative',
      marginTop : '4px',
      marginRight : '3px'
    },
    top: {
      color: '#eef3fd',
    },
    bottom: {
      color: '#6798e5',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
  });

function FacebookProgress(props) {
    const classes = useStylesFacebook();
  
    return (
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          value={100}
          className={classes.top}
          size={15}
          thickness={4}
          {...props}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.bottom}
          size={15}
          thickness={4}
          {...props}
        />
      </div>
    );
  }

function Presentation(props) {
    const { handleFileChange, startInvite } = props
    const [ state ] = useContext(DataContext)
    const classes = useStylesFacebook();

    return (
        <div>
            <div>
                <label className='rounded border p-2 c-pointer' htmlFor="contained-button-file-excel-invite">
                    <FaRegFileExcel size={22} /> <b>Invite multiple</b> 
                </label>
                <input
                    type='file'
                    variant='outlined'
                    className='d-none'
                    id='contained-button-file-excel-invite'
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={handleFileChange}
                    size='small'
                />
            </div>
        </div>
    )
}

export default Presentation
