import React, { PureComponent } from 'react'
import Presentation from './Presentation'
import firebase from '../../../firebase_config'
import { DataContext } from '../../../contexts/data'
import { SET_PROJECTS } from '../../../contexts/types'
class Container extends PureComponent {
    state = {
        projects:[]
    }

    static contextType = DataContext
    
    render() {
        return (
            <div>
                <Presentation
                {...this.state}
                />
            </div>
        )
    }
}

export default Container
