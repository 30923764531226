import React, { useContext } from 'react';
import MaterialTable from 'material-table';
import { AppBar, Tabs, Tab, Badge, Typography, Box, Grid, } from '@material-ui/core'
import PropTypes from 'prop-types'
import { configuration } from '../../../../configuration'
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';  
import Validations from '../../../generalcomponents/validation'
import EmployeeStepper from '../EmployeeStepper'
import ManagerStepper from '../ManagerStepper'
import RejectLetter from '../RejectLetter'
import parser from 'html-react-parser'
import PreviewPDF from '../../../PreviewPDF'
import {AiOutlineFileProtect} from 'react-icons/ai'
import { DataContext } from '../../../../contexts/data'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}


export default function Presentation(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { myReq } = props
    const [state] = useContext(DataContext)
    const validate = new Validations()
    const formatter = (date) => {
        let final = ""
        try {
            final = Intl.DateTimeFormat(configuration["date-code"],configuration.dateformat).format(new Date(date))
        } catch (error) {
            console.log(error)
            final = date
        }
        return final
    }

    let requests = []
    let letters = []
    let signatures = []
    try {
        requests = myReq ? state.requestedLetters.filter(item => item.employeeid === state.employee.email) : state.requestedLetters
        letters = state.letters
        signatures = state.authorizedSignatures
    } catch (error) {
        requests = []
        letters = []
        signatures = []
    }

    let columns = []
    console.log(myReq)
    if(myReq === true)
        columns = [
            { title: 'Request ID', field: 'requestid', defaultSort : 'desc' },
            { title: 'Subject', field: 'subject' },
            { title: 'Description', field: 'description',  render:(row) => parser(row.description)  },
            { title: 'Timestamp', field: 'timestamp' },
            { title : 'Issued by/Rejected by', field : 'issuedBy'},
            { title : 'Comment' , field: 'comment',
                render:(row) => {
                    return parser(row.comment)
                }
            },
            {title : 'Status', field : 'status',
            render : (row) => <span>
            {
                        row.status === "Issued" ? 
                        <Chip style={{width:"75%", backgroundColor:"#21ba45",color:"white"}} label="Issued" /> 
                        :
                        (
                            row.status === "Rejected" ? 
                            <Chip style={{width:"75%", backgroundColor:"#db2828",color:"white"}} label="Rejected" /> 
                            :
                            <Chip style={{width:"75%", backgroundColor:"orange",color:"white"}} label="Pending" />    
                        )
                    }
                    
            </span> 
         },
            { title: 'Action', field: 'action',
                render : (row) => <span>
                        {
                            row.status === "Issued" ? 
                            <PreviewPDF 
                                headerAndFooter = {true}
                                htmlContent={row.docContent}
                                btnContent = {<AiOutlineFileProtect size={18} />} 
                                iconBtn = {true}
                                isString={true}
                            />
                            :
                            (
                                row.status === "Rejected" ? 
                                <p>Re-request</p>
                                :
                                null
                            )
                        }
                        
                </span> 
            },
        ]
    else if(myReq === false)
        columns = [
            { title: 'Request ID', field: 'requestid', defaultSort : 'desc' },
            { title: 'Employee', field: 'employeename',
                render: (row) => <span><Link to={"/console/employeelist/"+row.uid} style={{textTransform:"capitalize",color:"#4183c4"}}  >{row.employeename}</Link><br/></span>
            },
            { title: 'Subject', field: 'subject' },
            { title: 'Description', field: 'description', render:(row) => parser(row.description) },
            { title: 'Timestamp', field: 'timestamp' },
            { title : 'Issued by/Rejected by', field : 'issuedBy'},
            { title : 'Comment' , field: 'comment', 
            render:(row) => {
                return parser(row.comment)
            }
        },
            {title : 'Status', field : 'status',
            render : (row) => <span>
            {
                        row.status === "Issued" ? 
                        <Chip style={{width:"75%", backgroundColor:"#21ba45",color:"white"}} label="Issued" /> 
                        :
                        (
                            row.status === "Rejected" ? 
                            <Chip style={{width:"75%", backgroundColor:"#db2828",color:"white"}} label="Rejected" /> 
                            :
                            <Chip style={{width:"75%", backgroundColor:"orange",color:"white"}} label="Pending" />    
                        )
                    }
                    
            </span> 
         },
            { title: 'Action', field: 'status',
                render : (row) => <span>
                        {
                            row.status === "Issued" ? 
                            <PreviewPDF 
                                headerAndFooter = {true}
                                htmlContent={row.docContent}
                                btnContent = {<AiOutlineFileProtect size={18} />} 
                                iconBtn = {true}
                                isString={true}
                            />
                            :
                            (
                                row.status === "Rejected" ? 
                                <p>Reopen</p>
                                :
                                <span className="d-flex" >
                                    <ManagerStepper reqData={row} signatures={signatures} letters={letters} />
                                    <RejectLetter reqId={row.requestid} id={row.id} /> 
                                </span>
                            )
                        }
                        
                </span> 
            },
        ]



    const reqContent = requests.map(request => {
        return{
            requestid : "R"+"0".repeat(6 - request.requestId.toString().length) + request.requestId.toString(),
            subject : configuration.letterTypes.filter(letter => letter.replace(/ /g,'').toLowerCase() === request.type)[0],
            description : request.description,
            timestamp : formatter(request.timestamp),
            status : request.isIssued === "rejected" ? "Rejected" : ( request.isIssued ? "Issued" : "Pending"),
            id : request.id,
            employeename : validate.emailToName(request.employeeid),
            uid : request.employeeid,
            docContent :request.isIssued === true ? request.offered.docContent : '',
            additionalDetails : request.additionalDetails,
            issuedBy : request.hasOwnProperty('issuedBy') ? validate.emailToName(request.issuedBy) : '',
            comment : request.hasOwnProperty('offered') ? request.offered.description : '',
            req_doc : request.attachment.publicURL
        }
    })    


    const CustomTable = (props) => {
        return(
            <MaterialTable
                title=""
                columns={columns}
                data={props.data}
                options={{
                    paginationType: "stepped",
                    pageSize: 10
                }}
            />
        )
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {
                        myReq === true ? <EmployeeStepper /> : null
                    }
                    
                </Grid>
                <Grid item xs={12}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            
                            <Tab label={<Badge color="primary" children="All" badgeContent={reqContent.length} />} {...a11yProps(0)} />
                            <Tab label={<Badge color="primary" children="Pending" badgeContent={reqContent.filter(req => req.status === 'Pending').length} />} {...a11yProps(1)} />
                            <Tab label={<Badge color="error" children="Rejected" badgeContent={reqContent.filter(req => req.status === 'Rejected').length} />} {...a11yProps(2)} />
                            <Tab label={<Badge color="primary" children="Issued" badgeContent={reqContent.filter(req => req.status === 'Issued').length} />} {...a11yProps(3)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <CustomTable data={reqContent} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <CustomTable data={reqContent.filter(req => req.status === 'Pending')}  />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <CustomTable data={reqContent.filter(req => req.status === 'Rejected')}  />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <CustomTable  data={reqContent.filter(req => req.status === 'Issued')} />
                    </TabPanel>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
            </Grid>
        </div>
    )
}

