import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import {  UPDATE_PROFILE } from '../../../contexts/types'
class Container extends Component {
    state = {
        profile:{},
        emergencycontactData:[],
        employementhistoryData:[],
        workauthData:[],
        personal:{},
        mailingaddress:{}
    }

    static contextType = DataContext

    componentDidMount = () => {
        const [ state ] = this.context
        this.setState({
            profile:state.employee_collection,
            emergencycontactData:state.employee_collection.emergencycontact,
            employementhistoryData:state.employee_collection.employmenthistory,
            workauthData:state.employee_collection.workauth,
            educationhistory : state.employee_collection.hasOwnProperty('educationhistory') ? state.employee_collection.educationhistory : [],
            personal:state.employee_collection.personal,
            mailingaddress:state.employee_collection.mailingaddress
        })
    }

    handleUpdateProfile = (key,data) =>{
        if(key === "emergency")
            this.setState({emergencycontactData:data})
        else if(key === "employment")
            this.setState({employementhistoryData:data})
        else if(key === "workauth")
            this.setState({workauthData:data})
        else if(key === "educationhistory")
            this.setState({educationhistory:data}) 
        else if(key.category === "employee" && key.employee === true){
            console.log(data)
            const personal={
                firstname:data.firstname,
                lastname:data.lastname,
                middlename:data.middlename,
                emailid:data.emailid,
                phonenumber:data.phonenumber,
                gender:data.gender,
                maritalstatus:data.maritalstatus,
            }
            const mailingaddress = {
                city : data.city,
                country : data.country,
                line1 : data.line1,
                line2 : data.line2,
                state : data.state,
                zip : data.zip
            } 
            console.log(personal, mailingaddress)
            this.setState({
                personal:{
                    ...this.state.personal,
                    ...personal
                },
                mailingaddress
            })
        }
        else if(key.category === "manager" && key.employee === false){
            console.log(data)
            const personal={
                branch:data.branch,
                employeestatus:data.employeestatus,
                jobtitle:data.jobtitle,
                reportingmanager:data.reportingmanager,
                department:data.department,
                dateofjoining:data.dateofjoining,
                category:data.category
            }
            this.setState({
                personal:{
                    ...this.state.personal,
                    ...personal
                },
            })
        }

        console.log(data)        
    }

    handleUpdate = () => {
        this.setState({isEditing:false})
        this.setState({isUpdating:true})
        console.log(this.state.emergencycontactData,this.state.employementhistoryData,this.state.workauthData)
        const dispatch = this.context[1]
        dispatch({
            type:UPDATE_PROFILE,
            payload:{
                uid:localStorage.getItem("ownerUID"),
                email:this.state.profile.useremail,
                emergencycontact:this.state.emergencycontactData,
                employmenthistory:this.state.employementhistoryData,
                workauth:this.state.workauthData,
                educationhistory:this.state.educationhistory,
                personal:this.state.personal,
                mailingaddress:this.state.mailingaddress
            }
        })

    }

    componentWillReceiveProps = (nextProps) => {
        const [state] = this.context
        console.log(state.employee_collection)
    }

    render() {
        if(!Object.keys(this.state.profile).length)
            return(
                <p>Loading profile</p>
            )
        return (
            <div>
                <Presentation
                {...this.state}
                handleUpdateProfile={this.handleUpdateProfile}
                handleUpdate = {this.handleUpdate}
                stateSet = {this.stateSet}
                />
            </div>
        )
    }
}

export default Container
