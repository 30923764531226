import React from 'react'
import EditIcon from '@material-ui/icons/Edit';
import { TextField, MenuItem, makeStyles, Button, Drawer } from '@material-ui/core';
import { FiEdit3 } from 'react-icons/fi'
import { FcBusinessContact } from 'react-icons/fc'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { StatisticValue } from 'semantic-ui-react';


const useStyles = makeStyles({
    setDrawer:{
        width: '380px'
    }
});


function Presentation(props) {
    const { handleChange, onUpdate, businessDisplayName, businessName, category, contactNumber, email, fax, federalId, jobTerminationNotice, netTerms, status, website } = props
    const classes = useStyles()
    // const [state] =  React.useContext(DataContext)
    const [drawerState, setState] = React.useState({
        right: false,
      });
    
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...drawerState, [anchor]: open });
    };

    const renderableFields = [
        {
            value: businessName,
            name: 'businessName',
            required: true,
            fieldName: 'Business Name'
        },
        {
            value: businessDisplayName,
            name: 'businessDisplayName',
            required: true,
            fieldName: 'Business Display Name'
        },
        {
            value: category,
            name: 'category',
            required: true,
            fieldName: 'Category'
        },
        {
            value: contactNumber,
            name: 'contactNumber',
            required: true,
            fieldName: 'Contact Number'
        },
        {
            value: email,
            name: 'email',
            required: true,
            fieldName: 'Email'
        },
        {
            value: fax,
            name: 'fax',
            required: true,
            fieldName: 'Fax'
        },
        {
            value: federalId,
            name: 'federalId',
            required: true,
            fieldName: 'Federal ID'
        },
        {
            value: jobTerminationNotice,
            name: 'jobTerminationNotice',
            required: true,
            fieldName: 'Job Termination Notice'
        },
        {
            value: netTerms,
            name: 'netTerms',
            required: true,
            fieldName: 'Net Terms'
        },
        {
            value: status,
            name: 'status',
            required: true,
            fieldName: 'Status'
        },
        {
            value: website,
            name: 'website',
            required: true,
            fieldName: 'Website'
        },
    ]

    return (
        <div>
            <Button onClick={toggleDrawer("right", true)}><EditIcon /> </Button>
            <Drawer anchor={"right"} open={drawerState["right"]} onClose={toggleDrawer("right", false)}>
                <div className="m-3" >
                    <div className="text-center" >
                        <FiEdit3 fontSize="50px" />
                        <h3><u>Edit Client details</u></h3>
                    </div>
                    <div className="row mt-4">
                        {
                            renderableFields.map(({value, name, required, fieldName}) => {
                                return(
                                    <div className="col-sm-6 mt-2" >
                                        <TextField 
                                            required={required}
                                            fullWidth
                                            name={name}
                                            onChange={handleChange}
                                            value={value}
                                            variant="outlined"
                                            size="small"
                                            label={fieldName}
                                        />
                                    </div>
                                )
                            })
                        }
                        {/* <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="businessName"
                                onChange={handleChange}
                                value={businessName}
                                variant="outlined"
                                size="small"
                                label="Business Name"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="businessDisplayName"
                                onChange={handleChange}
                                value={businessDisplayName}
                                variant="outlined"
                                size="small"
                                label="Business Display Name"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="category"
                                onChange={handleChange}
                                value={category}
                                variant="outlined"
                                size="small"
                                label="Category"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="contactNumber"
                                onChange={handleChange}
                                value={contactNumber}
                                variant="outlined"
                                size="small"
                                label="Contact Number"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="email"
                                onChange={handleChange}
                                value={email}
                                variant="outlined"
                                size="small"
                                label="Email"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="fax"
                                onChange={handleChange}
                                value={fax}
                                variant="outlined"
                                size="small"
                                label="Fax"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="federalId"
                                onChange={handleChange}
                                value={federalId}
                                variant="outlined"
                                size="small"
                                label="Federal ID"
                            />
                        </div> */}
                    </div>
                    <div className="text-center mt-3">
                        <Button
                            variant="contained"
                            color={"secondary"}
                            onClick={onUpdate}
                        >
                            Update
                        </Button>                 
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default Presentation
