import React, { useContext, useEffect } from 'react'
import ProjectHandle from '../components/Project/ProjectHandle'
import { DataContext } from '../contexts/data'
import { SET_PROJECT } from '../contexts/types'
import Unauthorized from '../components/generalcomponents/unAuth'
function ProjectHandlePage(props) {
    console.log(props)
    const [ state, dispatch ] = useContext(DataContext)
    let length
    try {
        length = state.projectList.length
    } catch (error) {
        length = 0
    }
    useEffect(() => {
        if(length > 0)
            dispatch({
                type:SET_PROJECT,
                payload:props.match.params.projectId
            })
    }, [length, props.match.params.projectId])
    let content = ""
    try {
        if(state.hasOwnProperty('project') && Object.keys(state.project).length && Array.isArray(state.project.Users) )
         return  content = (
                <div>
                    <ProjectHandle
                        projectId={state.project.id}
                    />
                </div>
            
            )
         else content = <p>Loading project ...</p>   
    } catch (error) {
        console.log(error)
        return content =  <Unauthorized />
    }
   return content
}

export default ProjectHandlePage
