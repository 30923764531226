import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../contexts/data'
import { PLACEMENT_SETTINGS_ACTIONS } from '../../../../../contexts/types'
import firebase from '../../../../../firebase_config'

class Container extends Component {
  state = {
    employees: [],
    profile: {},
    id: '',
    fillableSections: [],
  }

  static contextType = DataContext

  handleEmployees = (value) => {
    this.setState({ employees: value })
  }

  getData = (id) => {
    firebase
      .firestore()
      .collection('Placements')
      .doc(id)
      .collection('RecruitmentDetails')
      .onSnapshot((snap) => {
        let placementData = []
        placementData = snap.docs.map((doc) => doc.data())
        placementData.length &&
          this.setState({
            id: id,
            employees: placementData[0].companyIDs,
          })
      })
  }

  componentDidMount = () => {
    const { req_placement } = this.props
    if (req_placement.id) this.getData(req_placement.id)
  }

  componentWillReceiveProps = (nextProps) => {
    const { req_placement, profile } = nextProps
    const { id, startDate, endDate, fillableSections } = req_placement
    this.setState({ profile, id, fillableSections })
    if (id.length && this.props.req_placement.id !== id) this.getData(id)
  }

  placementSettingsActionsWiki = () => {}

  handleCreate = () => {
    const dispatch = this.context[1]
    const { id, employees } = this.state
    let payload = {
      companyIDs: employees,
      sectionName: 'recruitment-details',
      placementID: id,
    }
    console.log(payload)
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      payload: payload,
      actionType: 'new',
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
    })
  }

  handleUpdate = () => {
    const dispatch = this.context[1]
    const { id, employees } = this.state
    let payload = {
      companyIDs: employees,
      sectionName: 'recruitment-details',
      placementID: id,
    }
    console.log(payload)
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      payload: payload,
      actionType: 'update',
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
    })
  }

  handleSave = (type) => {
    if (type === 'new') this.handleCreate()
    else if (type === 'update') this.handleUpdate()
  }

  render() {
    return (
      <div>
        <Presentation
          {...this.state}
          handleEmployees={this.handleEmployees}
          handleSave={this.handleSave}
        />
      </div>
    )
  }
}

export default Container
