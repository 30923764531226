import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';  
import {  Collapse } from 'reactstrap'
import AddCircleIcon from "@material-ui/icons/AddCircle"
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Fab from '@material-ui/core/Fab';  
import Validations from '../../generalcomponents/validation'
import Button from '@material-ui/core/Button';
const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
    large: {
      position:"absolute",
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin:"1.1em",
    },
    box:{
      borderRadius:"100px",
      height:100,
      width:100,
      margin:"auto",
      marginTop:"4px"
    },
    table: {
      backgroundColor:"#fafafa"
    },
  }));
  

function Presentation(props) {
        const classes = useStyles();
        const {clearValues, name, emailid, phone, handleExisting, isEditing, handleAdd, editSection, handleEdit, handleDelete, emergencycontactData} = props
        const [isOpen, setIsOpen] = useState(false);
        const [isOpen2, setIsOpen2] = useState(false);
        const toggle = () => {
            setIsOpen(!isOpen);
            if(isOpen2){
                setIsOpen2(!isOpen2)
                clearValues()
            }
            else{
                clearValues()
            }
        }
        const toggle2 = () =>{ 
            setIsOpen2(!isOpen2)
            if(isOpen){
                setIsOpen(!isOpen)
                clearValues()
            }
            else{
                clearValues()
            }
        }
        if(!isEditing){
            if(isOpen)
                setIsOpen(!isOpen)
            if(isOpen2)
                setIsOpen2(!isOpen2)    
        }

    const validate = new Validations()
        
    return (
           
            <div className="user-emergencycontact mt-3">
                <h3 className=""><u>Emergency Contact:</u></h3>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell width="40%" align="left">Name</TableCell>
                            <TableCell width="40%" align="left">Phone</TableCell>
                            <TableCell width="45%" align="left">Email </TableCell>
                            {
                                isEditing?
                                <TableCell  align="right"  >
                                    <Fab 
                                        variant="contained" 
                                        color="primary"
                                        size="small"
                                        onClick={toggle}
                                    >
                                    <AddCircleIcon />
                                    </Fab>
                                </TableCell>
                                :
                                null
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        
                        {emergencycontactData.map(item=>{
                                return (
                                <TableRow key={item.name} >
                                <TableCell align="left">{item.name}</TableCell>
                                <TableCell>{item.phone}</TableCell>
                                <TableCell>{item.emailid}</TableCell>
                                {
                                        isEditing?
                                        <TableCell align="right" >
                                            <Fab 
                                                variant="contained" 
                                                className="flairbtn" 
                                                size="small"
                                                onClick={()=>{toggle2();handleEdit(item)}}
                                            >
                                                <EditIcon />
                                            </Fab>&nbsp;
                                            {emergencycontactData.length>1?<Fab 
                                                variant="contained" 
                                                size="small"
                                                color="secondary"
                                                onClick={()=>handleDelete(emergencycontactData.indexOf(item))}
                                            >
                                                <DeleteIcon />
                                            </Fab>
                                            :null}
                                        </TableCell>
                                        :
                                        null
                                    }
                                </TableRow>
                                )
                            })}
                    </TableBody>
                    </Table>
                </TableContainer>
                    <div id="add_row_for_emergency_contact">
                        <Collapse isOpen={isOpen}>
                            <form onSubmit={(e)=>{
                                e.preventDefault()
                                if(validate.checkName(name)&&validate.checkNumber(phone)){
                                    toggle();handleAdd(e)}
                                }}>
                                <div className="m-3">
                                    <Paper  style={{padding:'15px'}} >
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Name"
                                                    name="name"
                                                    value={name}
                                                    variant="outlined"
                                                    helperText={name.length ? validate.checkName(name) ? "" : "Enter valid name":""}
                                                    size="small"
                                                    required
                                                    onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Phone"
                                                    name="phone"
                                                    type="number"
                                                    id="outlined-size-small"
                                                    value={phone}
                                                    variant="outlined"
                                                    helperText={phone.length ? validate.checkNumber(phone) ? "" : "Enter valid phone number":""}
                                                    size="small"
                                                    required
                                                    onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Email"
                                                    name="email"
                                                    type="email"
                                                    id="outlined-size-small"
                                                    helperText={emailid.length ? validate.checkEmail(emailid) ? "" : "Enter valid email id": ""}
                                                    value={emailid}
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                />
                                            </Grid>
                                        </Grid> 
                                        <br/>
                                        <div className="text-center" >
                                            <Button variant="contained" type="submit"  color="primary">
                                                Save
                                            </Button>
                                            {' '}
                                            <Button variant="contained" onClick={toggle} color="default">
                                                Cancel
                                            </Button>
                                        </div> 
                                        <br/>   
                                    </Paper>
                                </div>
                            </form>
                        </Collapse>
                    </div>
                    <div id="edit_row_for_emergency_contact" >
                        <Collapse style={{padding:'15px'}} isOpen={isOpen2}>
                            <form onSubmit={(e)=>{
                                e.preventDefault();
                                if(validate.checkName(name)&&validate.checkNumber(phone)){
                                    toggle2();
                                    handleExisting(emergencycontactData.indexOf(editSection),editSection)
                                }

                                }}>
                                <div className="m-3">
                                    <Paper style={{padding:'15px'}} >
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Name"
                                                    name="name"
                                                    id="outlined-size-small"
                                                    value={name}
                                                    helperText={name.length ? validate.checkName(name) ? "" : "Enter valid name":""}
                                                    variant="outlined"
                                                    size="small"
                                                    required
                                                    onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Phone"
                                                    name="phone"
                                                    type="number"
                                                    helperText={phone.length ? validate.checkNumber(phone) ? "" : "Enter valid phone number":""}
                                                    id="outlined-size-small"
                                                    value={phone}
                                                    variant="outlined"
                                                    size="small"
                                                    required
                                                    onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Email"
                                                    name="emailid"
                                                    type="email"
                                                    id="outlined-size-small"
                                                    value={emailid}
                                                    helperText={emailid.length ? validate.checkEmail(emailid) ? "" : "Enter valid email id": ""}
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>    
                                        <br/>
                                        <div className="text-center" >
                                            <Button variant="contained" type="submit"  color="primary">
                                                Save
                                            </Button>
                                            {' '}
                                            <Button variant="contained" onClick={toggle2} color="default">
                                                Cancel
                                            </Button>
                                        </div> 
                                        <br/>
                                    </Paper>
                                </div>
                            </form>
                        </Collapse>
                    </div>
                </div>
    )
}

export default Presentation
