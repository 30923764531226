import React, { Component } from 'react'
import Presentation from "./Presentation"
import { DataContext } from '../../../../../contexts/data'
import firebase from "../../../../../firebase_config"
import { addDays } from "date-fns"
export class Container extends Component {
    constructor(){
        super()
        this.state={
            startDate:"",
            endDate : "",
            standardTimeArr : [],
            OTArr : [],
            cycle:0,
            timesheetsList: []
        }   
    }

    static contextType = DataContext

    componentDidMount = () => {
        const { placementID } = this.props
        const [state] = this.context
        const placement = state.placements_list.filter(item => item.placementID === placementID)[0]
        firebase.firestore().collection("Placements").doc(placement.id).collection("Timesheets").get().then(snap => {
            let settings = {}
                settings = snap.docs[0].data()
            this.setState({
                cycle: settings.cycle,
            })
        })

        this.grabTimesheetData()
    }

    grabTimesheetData = () => {
        const timesheet = this.props.timesheet
        const [state] = this.context
        this.setState({
            startDate: timesheet.startDate,
            endDate: timesheet.endDate,
            standardTimeArr: timesheet.workdetails.standardTime.map((item, index) => {
                return {
                    date: addDays(new Date(timesheet.startDate), index).toISOString(),
                    value: item
                }
            }),
            OTArr: timesheet.workdetails.OTtime.map((item, index) => {
                return {
                    date: addDays(new Date(timesheet.startDate), index).toISOString(),
                    value: item
                }
            }),
        })
    }

    
    // handleTime = (type, index, value) => {
    //     const { handleChange, timesheet, timesheets } = this.props
    //     if(type === "standard"){
    //         let arr = JSON.parse(JSON.stringify(timesheets)) 
    //         let timesheetIndex = 0
    //         let sheet = arr.filter((item, i) => {
    //             if(item.id === timesheet.id){
    //                 timesheetIndex = i
    //                 return true
    //             }
    //             return false
    //         })[0]
    //         sheet['workdetails']['standardTime'][index] = value
    //         arr[timesheetIndex] = sheet
    //         this.setState({
    //             timesheetsList: arr
    //         })
    //     }else if(type === "ot"){
    //         const arr = this.state.OTArr
    //         arr[index].value = value
    //         this.setState({OTArr:arr})
    //     }
    // }

    handleTime = (type, index, value) => {
        if(type === "standard"){
            const arr = this.state.standardTimeArr
            arr[index].value = value
            this.setState({standardTimeArr:arr})
        }else if(type === "ot"){
            const arr = this.state.OTArr
            arr[index].value = value
            this.setState({OTArr:arr})
        }
    }

    handleSave = () => {
        const { handleChange, timesheets, timesheet } = this.props
        timesheets.forEach((item, index) => {
            if(item.id === timesheet.id){
                const customSheet = JSON.parse(JSON.stringify(item))
                customSheet['workdetails']['standardTime'] = this.state.standardTimeArr.map(item => item.value)
                customSheet['workdetails']['OTtime'] = this.state.OTArr.map(item => item.value)
                timesheets[index] = customSheet
                handleChange("timesheets", timesheets)
            }
        })
    }

    render() {
        return (
            <div>
                <Presentation 
                    {...this.state}
                    {...this.props}
                    handleSave={this.handleSave}
                    handleTime={this.handleTime}
                />
            </div>
        )
    }
}

export default Container
