import React from 'react';
import Grid from '@material-ui/core/Grid';
import EditTask from '../EditTask'


export default function Presentation(props) {
//   function handleClick(){
//   $("#Export_to_pdf_tasksinformation").trigger("click");
// }


  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
            <EditTask
              taskId = {props.taskId}
              projectId = {props.projectId}
            />
        </Grid>
        {/* <Grid item xs={12}>
        <Button 
            fullWidth
            variant="contained" 
            className="flairbtn text-none" 
            startIcon={<FaRegFilePdf size={22} />} 
            onClick={handleClick}
        >
            PDF
        </Button>
        </Grid> */}
        </Grid>
    </div>
    
  );
}
