import React, { useContext } from 'react'
import ListofProjects from '../components/Project/ListofProjects'
import { DataContext } from '../contexts/data'

const ProfileListPage = () => {
    const [state] = useContext(DataContext)
    if(state.hasOwnProperty("projectList"))
    return (
            <ListofProjects/>
    )
    return <h3>Loading ...</h3>
}

export default ProfileListPage
