import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
import { ADD_MEMBER } from '../../../../contexts/types'
import $ from 'jquery'
class Container extends Component {
    state = {
        employees : [],
        isCountExceeded : false
    }
    static contextType = DataContext

    handleEmployees = (value) => {
        this.setState({["employees"]:value})
    }

    onAddMembers = () => {
        const employees = this.state.employees
        const [state, dispatch ] = this.context
        this.setState({employees:[]})
        $("#add-employee-into-project").attr('value',[])
        dispatch({
            type : ADD_MEMBER,
            payload : {
                data :{
                    employees : employees,
                    projectId : state.project.id
                },
                dispatch : dispatch,
                isAddedConfirmed : this.isAddedConfirmed
            }
        })
    }
    // to check whether memebers added to database successfully
  
    render() {
        console.log("task rendered")
        return (
            <div>
                <Presentation 
                    {...this.state}
                    {...this.props}
                    handleEmployees={this.handleEmployees}
                    onAddMembers = {this.onAddMembers}
                />
            </div>
        )
    }
}

export default Container
