import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import { PROMOTE_EMPLOYEE, DEMOTE_EMPLOYEE, SUSPEND_EMPLOYEE, ENABLE_EMPLOYEE} from '../../../contexts/types'
class Container extends Component {
    state = {
        processing : false
    }

    static contextType = DataContext

    onResponse = () => {
        this.setState({processing:false})
    }

    onPromote = () => {
        const dispatch = this.context[1]
        this.setState({processing:true})
        dispatch({
            type : PROMOTE_EMPLOYEE,
            payload : this.props.profile.useremail,
            onResponse : this.onResponse
        })
    }

    onDemote = () => {
        const dispatch = this.context[1]
        this.setState({processing:true})
        dispatch({
            type : DEMOTE_EMPLOYEE,
            payload : this.props.profile.useremail,
            onResponse : this.onResponse
        })
    }

    onSuspend = () => {
        const dispatch = this.context[1]
        this.setState({processing:true})
        dispatch({
            type : SUSPEND_EMPLOYEE,
            payload : this.props.profile.useremail,
            onResponse : this.onResponse
        })
    }

    onEnable = () => {
        const dispatch = this.context[1]
        this.setState({processing:true})
        dispatch({
            type : ENABLE_EMPLOYEE,
            payload : this.props.profile.useremail,
            onResponse : this.onResponse
        })
    }


    render() {
        return (
            <div>
                <Presentation 
                    {...this.state}
                    id={this.props.id}
                    onPromote = {this.onPromote}
                    onDemote = {this.onDemote}
                    onSuspend = {this.onSuspend}
                    onEnable = {this.onEnable}
                    profile = {this.props.profile}
                />
            </div>
        )
    }
}

export default Container
