import React, { Component } from 'react'
import Presentation from './Presentation'
import { render } from 'react-dom'
import axios from 'axios'
import Prompt from '../../../generalcomponents/snackBars'
import HoldPrompt from '../../../generalcomponents/progressSnackBars'
import Validations from '../../../generalcomponents/validation'
class Container extends Component {
    state = {
        email : "",
        isSending : false,
        firstname : "",
        lastname : "",
        phonenumber : "",
        email : "",
        dob : "",
        branch : ""
    }

    handleChange = (key, value) => {
        const validate = new Validations()
        if(key === "email")
            this.setState({
                [key]:value.toLowerCase()
            })
        else if(key === "dob" && !isNaN(Date.parse(value)))
            this.setState({
                [key]:value.toISOString()
            })
        else if(key === "branch")
            this.setState({
                [key] : value
            })
        else if(key !== "dob")
            this.setState({
                [key]:validate.nameFormatterToUpperCase(value)
            })
    }


    handleClick=(e)=>{
        const expression = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const { email, firstname, lastname, dob, branch, phonenumber } = this.state
        console.log(email)
        let emailCheck = expression.test(email) 
        console.log(emailCheck)
        if(!emailCheck)
            return  render(
                        <Prompt open={true} content="Invalid email format" severity="error" />,
                        document.getElementById('notifications-box')
                    )
        render(
            <HoldPrompt open={true} content="Invitation mail is being sent..." severity="info" />,
            document.getElementById('notifications-hold-box')
        ) 
        this.setState({isSending:true})
        axios.post("/invitethroughtoken",{ 
           uid:email,
           type:"inviteUser",
           category : 'employeeInvitation',
           privilege : 'invite-employee',
           moduleName : 'console-customization',
           additionalDetails : {
               firstname,
               lastname,
               phonenumber,
               branch,
               dob
           }
        })
        .then(res => {
            console.log(res)
            this.setState({isSending:false})
            render(
                <div></div>,
                document.getElementById('notifications-hold-box')
            ) 
            if(res.data.status){
            
            render(
                <Prompt open={true} content={email+" has been invited successfully"} severity="success" />,
                document.getElementById('notifications-box')
            )}
            else{
                if(res.data.code === "already-registered"){
                    render(
                        <Prompt open={true} content="Employee already exists!" severity="error" />,
                        document.getElementById('notifications-box')
                    ) 
                }
                else{
                    render(
                        <Prompt open={true} content="Something went wrong!" severity="error" />,
                        document.getElementById('notifications-box')
                    ) 
                }
            }    
           

        }).catch(err => {
            console.log(err)
            this.setState({isSending:false})
            render(
                <div></div>,
                document.getElementById('notifications-hold-box')
            ) 
            render(
                <Prompt open={true} content="Something went wrong!" severity="error" />,
                document.getElementById('notifications-box')
            ) 
        })
    }
    render() {
        return (
            <div>
                <Presentation 
                    {...this.props}
                    {...this.state}
                    handleClick={this.handleClick}
                    handleChange = {this.handleChange}
                />
            </div>
        )
    }
}

export default Container
