import React from 'react'
// import SideDrawer from '../../../../../../generalcomponents/sideDrawer'
import EditIcon from '@material-ui/icons/Edit';
import { TextField, MenuItem, makeStyles, Button, Drawer } from '@material-ui/core';
import { FiEdit3 } from 'react-icons/fi'
import { FcBusinessContact } from 'react-icons/fc'
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { DataContext } from '../../../../../../../contexts/data';
import CountryAndState from "../../../../../../generalcomponents/countryAndState"


const useStyles = makeStyles({
    setDrawer:{
        width: '380px'
    }
});


function Presentation(props) {
    const { line1, line2, city, state_name, zipCode, country, handleChange, onUpdate } = props
    const classes = useStyles()
    // const [state] =  React.useContext(DataContext)
    const [drawerState, setState] = React.useState({
        right: false,
      });
    
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...drawerState, [anchor]: open });
    };

    return (
        <div>
            <Button onClick={toggleDrawer("right", true)}><EditIcon /> </Button>
            <Drawer anchor={"right"} open={drawerState["right"]} onClose={toggleDrawer("right", false)}>
                <div className="m-3" >
                    <div className="text-center" >
                        <FiEdit3 fontSize="50px" />
                        <h3><u>Edit Invoice Location</u></h3>
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="line1"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                value={line1}
                                variant="outlined"
                                size="small"
                                label="Line1"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="line2"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                value={line2}
                                variant="outlined"
                                size="small"
                                label="Line2"
                            />
                        </div>
                        {/* <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="country"
                                onChange={handleChange}
                                value={country}
                                variant="outlined"
                                size="small"
                                label="Country"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="state"
                                onChange={handleChange}
                                value={state}
                                variant="outlined"
                                size="small"
                                label="State"
                            />
                        </div> */}
                        <div className="col-sm-12" >
                            <CountryAndState country={country} state_name={state_name} spacing={2} handleChange={handleChange}  />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="city"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                value={city}
                                variant="outlined"
                                size="small"
                                label="City"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="zipCode"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                value={zipCode}
                                variant="outlined"
                                size="small"
                                label="Zip code"
                            />
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <Button
                            variant="contained"
                            color={"secondary"}
                            disabled={[line1, line2, city, state_name, zipCode, country].some(item => item === "")}
                            onClick={onUpdate}
                        >
                            Update
                        </Button>                 
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default Presentation
