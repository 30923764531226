import React, { useContext } from 'react'
import Avatar from '@material-ui/core/Avatar';
import { Timeline, TimelineEvent } from "react-event-timeline";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { DataContext } from '../../../../contexts/data';
import Validations from '../../../generalcomponents/validation';
import { Link } from 'react-router-dom'
import { Grid } from 'semantic-ui-react';



function formatSubject(item){ 
    const validate = new Validations()
    switch(item.type){
      case "createProject":return <span> <Link to={"/console/projects/"+item.ActionBy}>{validate.emailToName(item.ActionBy)}</Link> created this project. </span>

      case "addMemberToProject":return <span><Link to={"/console/employeelist/"+item.ActionBy}>{validate.emailToName(item.ActionBy)}</Link> added <Link to={"/console/employeelist/"+item.subject.uid}>{validate.emailToName(item.subject.uid)}</Link> to this project.</span>
                            
      case "deleteProjectMember":return <span><Link to={"/console/employeelist/"+item.ActionBy}>{validate.emailToName(item.ActionBy)}</Link> removed <Link to={"/console/employeelist/"+item.subject.uid}>{validate.emailToName(item.subject.uid)}</Link> from this project.</span>
                              
      case "updateAccessLevels":return <span><Link to={"/console/employeelist/"+item.ActionBy}>{validate.emailToName(item.ActionBy)}</Link> updated the project access levels of <Link to={"/console/employeelist/"+item.subject.uid}>{validate.emailToName(item.subject.uid)}</Link>.</span>
                             
      case "updateProject":return <span><Link to={"/console/employeelist/"+item.ActionBy}>{validate.emailToName(item.ActionBy)}</Link> updated the project details.</span>
                           
      default : return <span>-----------------</span>
                                         
    }
}

function formatTitle(item){
    const validate = new Validations()
    switch(item.type){
        case "createProject":return <span> {validate.emailToName(item.ActionBy)}</span>
  
        case "addMemberToProject":return <span> {validate.emailToName(item.ActionBy)}</span>
                              
        case "deleteProjectMember":return <span> {validate.emailToName(item.ActionBy)}</span>
                                
        case "updateAccessLevels":return <span>{validate.emailToName(item.ActionBy)}</span>
                               
        case "updateProject":return <span> {validate.emailToName(item.ActionBy)}</span>
                             
        default : return <span>-----------------</span>
                                           
      }
}

function formatIcon(item){
    const validate = new Validations()
    switch(item.type){
        case "createProject":return <AccountBalanceIcon fontSize='medium' />
  
        case "addMemberToProject":return <PersonAddIcon fontSize='medium' />
                              
        case "deleteProjectMember":return <PersonAddDisabledIcon fontSize='medium' />
                                
        case "updateAccessLevels":return <VpnKeyIcon fontSize='medium' />
                               
        case "updateProject":return <AccountBalanceIcon fontSize='medium' />
                             
        default : return <PauseCircleFilledIcon fontSize='medium' />
                                           
      }
}


function Presentation(props) {
    const { ProjectTimeLine } = props
    const [state] = useContext(DataContext)
    const validate = new Validations()
    
    return (
        <div>
            <Timeline style={{width:'100%'}} >
                {
                    ProjectTimeLine.map(item => {
                        return(
                            <TimelineEvent
                                style={{padding:'10px', color: "#000", fontSize:'14px'}}
                                // title={formatTitle(item)}
                                createdAt={validate.dateAndTimeFormatter(item.createdAt)}
                                icon = {formatIcon(item)}
                            >
                                { formatSubject(item) }
                            </TimelineEvent>
                        )
                    })
                }
            </Timeline>
            
        </div>
    )
}

export default Presentation
