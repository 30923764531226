import React, { Component } from 'react'
import Presentation from './Presentation'
import firebase from '../../../../firebase_config'

class Container extends Component {
  state = {
    emails: [],
    value: 0,
    status: false
  }

  componentDidMount = () => {
    let emails = []
    firebase
      .firestore()
      .collection('Resources/Templates/EmailTemplates')
      .where('isActive', '==', true)
      .onSnapshot((snap) => {
        emails = snap.docs.map((doc) => doc.data())
        console.log(emails)
        this.setState({ emails, status: true })
      })
  }

  handleChange = (_, v) => {
    this.setState({ value: v })
  }

  render() {
    if(this.state.status) {
      return (
        <div>
          <Presentation {...this.state} handleChange={this.handleChange} />
        </div>
      )
    }
    return <p>Loading...</p>
  }
}

export default Container
