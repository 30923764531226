import React from 'react'
import EmployeeEditables from '../EmployeeEditables'
import ManagerEditables from '../ManagerEditables'
function Presentation(props) {
  
   
    return (
        <div>
            <h3 ><u>Personal Information:</u></h3>
            <div>
                <EmployeeEditables  
                    {...props}
                />
            </div>
            <div>
                <ManagerEditables 
                     {...props}
                />
            </div>
        </div>
    )
}

export default Presentation
