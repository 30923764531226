import React, { createContext, useReducer } from 'react'
import firebase from '../firebase_config'
import Axios from 'axios'
import Prompt from '../components/generalcomponents/snackBars'
import HoldPrompt from '../components/generalcomponents/progressSnackBars'
import Backdrop from '../components/generalcomponents/backDrop'
import { configuration } from '../configuration'
// import { reducer } from '../reducers'
// import {  }
import { render } from 'react-dom'

// types
import {
  CHECK_LOGIN,
  CHECK_AUTH,
  LOGIN_LOADER,
  SIGN_OUT,
  EMPLOYEE_COLLECTION,
  UPDATE_PROFILE,
  SET_EMPLOYEES_COLLECTION,
  SET_ACTIVE_EMPLOYEES,
  SET_PROJECTS,
  SET_PROJECT,
  SET_TASKS,
  SET_TASK,
  SET_LOADED_TASK,
  NEW_PROJECT,
  NEW_TASK,
  NEW_SUB_TASK,
  UPDATE_ACCESS_LEVELS,
  REMOVE_MEMBER,
  ADD_MEMBER,
  PROMOTE_EMPLOYEE,
  DEMOTE_EMPLOYEE,
  SUSPEND_EMPLOYEE,
  ENABLE_EMPLOYEE,
  HISTORY,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  UPDATE_TASK,
  COMMENT,
  UPDATE_PROJECT,
  EMPLOYEE_NAMES,
  FORM_DATA,
  DELETE_COMMENT,
  UPDATE_COMMENT,
  SET_COUNTRY_API,
  COUNTRY_API_DATA,
  ADD_LETTER_TEMPLATE,
  UPDATE_LETTER_TEMPLATE,
  EMPLOYEE_REQUEST_LETTER,
  OFFICER_ISSUE_LETTER,
  OFFICER_REJECT_LETTER,
  SET_LETTER_CONTENT,
  DELETE_LETTER_TEMPLATE,
  ADD_AUTHORISED_SIGNATURE,
  SET_AUTHORIZED_SIGNATURES,
  SET_LETTER_TEMPLATES,
  DELETE_AUTHORISED_SIGNATURE,
  SET_COMPANY_CONFIG,
  UPDATE_COMPANY_CONFIG,
  GET_SELECTED_LETTER_CONTENT,
  START_EXCEL_INVITE,
  SET_REQUESTED_LETTERS,
  SET_LETTERS,
  SET_SIGNATURES,
  SET_MODULES,
  SET_WIKI_PAGES,
  SET_WIKI_HISTORY,
  CREATE_WIKI_PAGE,
  UPDATE_WIKI_PAGE,
  DELETE_WIKI_PAGE,
  ADD_WIKI_CATEGORY,
  SET_WIKI_CATEGORY,
  DELETE_WIKI_CATEGORY,
  UPDATE_WIKI_CATEGORY,
  LINK_TASKS,
  UNLINK_TASKS,
  OFFICER_RESETS_PASSWORD,
  EMPLOYEE_RESETS_TEMP_PASSWORD,
  FOLLOW_UNFOLLOW_WIKI_ARTICLE,
  VOTE_FOR_WIKI_ARTICLE,
  NEW_COMMENT_ON_WIKI,
  UPDATE_OR_DELETE_WIKI_COMMENT,
  CREATE_NEW_LABEL,
  UPDATE_LABEL,
  DELETE_LABEL,
  TEMPORARY_NEW_PLACEMENT_DATA,
  CREATE_GENERAL_PLACEMENT_SETTINGS,
  CREATE_PAYMENTS_SETTINGS,
  CREATE_TIMESHEETS_DOCUMENTS,
  CREATE_TIMESHEETS_SETTINGS,
  CREATE_INVOICE,
  CREATE_WORKLOCATION_SETTINGS,
  CLIENT_CURD,
  EMPLOYEE_SUBMIT_TIMESHEET,
  SET_CUSTOM_KEY,
  TEMPORARY_NEW_CLIENT_DATA,
  CREATE_CLIENTDETAILS_SETTINGS,
  EMPLOYEE_TIMESHEET_ACTIONS,
  MANAGER_TIMESHEET_ACTIONS,
  CLIENT_CONTACT_ACTIONS,
  CLIENT_LOCATION_ACTIONS,
  CLIENT_UPDATE_ACTIONS,
  INVOICE_ACTIONS,
  UPDATE_PLACEMENT_SETTINGS,
  PAYMENT_ACTIONS,
  PLACEMENT_SETTINGS_ACTIONS,
  EXPENSE_ACTIONS,
  DEDUCTIONS_ACTIONS,
  PAYROLL_ACTIONS,
  SET_EMAIL_CONTENT,
  SET_EMAIL_TEMPLATES,
  ADD_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
} from './types'
import { wait } from '@testing-library/react'
export const DataContext = createContext()

const initialState = {
  isLoggedIn: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case CHECK_LOGIN:
      checkLogin(action.payload, state)
      return {
        ...state,
      }
    case CHECK_AUTH:
      if (action.payload.isLoggedIn)
        return {
          ...state,
          isLoggedIn: action.payload.isLoggedIn,
          employee: action.payload.employee,
        }
      else
        return {
          ...state,
          isLoggedIn: action.payload.isLoggedIn,
        }
    case SIGN_OUT:
      return signOut(action, state) ? initialState : state

    case SET_MODULES:
      return {
        ...state,
        access_modules: action.payload,
      }

    case SET_CUSTOM_KEY:
      return {
        ...state,
        [action.key]: action.payload,
      }

    case SET_COMPANY_CONFIG:
      return {
        ...state,
        company_config: action.payload,
      }

    case UPDATE_COMPANY_CONFIG:
      updateCompanyConfig(state, action.payload)
      return state

    case HISTORY:
      return {
        ...state,
        history: action.payload,
      }

    case FORM_DATA:
      return {
        ...state,
        invitation_template: action.payload,
      }
    case CHANGE_PASSWORD:
      changePassword(state, action.payload)
      return state

    case RESET_PASSWORD:
      resetPassword(state, action.payload)
      return state

    case OFFICER_RESETS_PASSWORD:
      officerResetsPassword(state, action.payload)
      return state

    case EMPLOYEE_RESETS_TEMP_PASSWORD:
      employeeResetsTempPassword(state, action.payload)
      return state

    case EMPLOYEE_COLLECTION:
      return {
        ...state,
        employee_collection: action.payload,
      }

    case UPDATE_PROFILE:
      updateProfile(action.payload, state)
      return {
        ...state,
      }

    case SET_EMPLOYEES_COLLECTION:
      return {
        ...state,
        employees_collection: action.payload,
      }

    case SET_ACTIVE_EMPLOYEES:
      return {
        ...state,
        activeEmployees: action.payload,
      }

    case EMPLOYEE_NAMES:
      return {
        ...state,
        names: action.payload,
      }
    case START_EXCEL_INVITE:
      return {
        ...state,
        excel_invite: action.payload,
      }

    case PROMOTE_EMPLOYEE:
      promoteEmployee(state, action.payload, action.onResponse)
      return state
    case DEMOTE_EMPLOYEE:
      demoteEmployee(state, action.payload, action.onResponse)
      return state
    case SUSPEND_EMPLOYEE:
      suspendEmployee(state, action.payload, action.onResponse)
      return state
    case ENABLE_EMPLOYEE:
      enableEmployee(state, action.payload, action.onResponse)
      return state

    case NEW_PROJECT:
      newProject(action.payload)
      return {
        ...state,
      }

    case UPDATE_PROJECT:
      updateProject(state, action.payload)
      return state

    case SET_PROJECTS:
      return {
        ...state,
        projectList: action.payload,
      }
    case SET_PROJECT:
      console.log(state)
      return {
        ...state,
        project:
          action.payload === ''
            ? {}
            : state.projectList.filter(
                (project) => project.id === action.payload
              )[0],
      }
    case SET_TASKS:
      return {
        ...state,
        taskList: action.payload,
      }

    case SET_TASK:
      console.log(action.payload)
      return {
        ...state,
        task: action.payload,
      }

    case SET_LOADED_TASK:
      return {
        ...state,
        task: state.taskList.filter((task) => task.id === action.payload)[0],
      }

    case LINK_TASKS:
      linkTasks(state, action.payload)
      return state

    case UNLINK_TASKS:
      unlinkTasks(state, action.payload)
      return state

    case NEW_TASK:
      newTask(state, action.payload)
      return state

    case UPDATE_TASK:
      updateTask(state, action.payload, action.category)
      return state

    case NEW_SUB_TASK:
      subTask(state, action.payload)
      return state
    case COMMENT:
      commentOn(state, action.payload, action.response)
      return state
    case DELETE_COMMENT:
      deleteComment(state, action.payload)
      return state
    case UPDATE_COMMENT:
      updateComment(state, action.payload, action.response)
      return state
    case UPDATE_ACCESS_LEVELS:
      updateAccessLevels(state, action.payload)
      return state

    case REMOVE_MEMBER:
      removeMember(state, action.payload)
      return state
    case ADD_MEMBER:
      addMember(state, action.payload)
      return state
    case SET_COUNTRY_API:
      setCountryApi(state, action.payload)
      return state
    case COUNTRY_API_DATA:
      return {
        geo_data: {
          countries: action.payload,
        },
        ...state,
      }
    case SET_LETTER_CONTENT:
      return {
        ...state,
        letter_content: {
          id: action.payload.id,
          content: action.payload.content,
          defaultContent: action.payload.defaultContent,
          updating: action.payload.updating,
          name: action.payload.name,
          type: action.payload.type,
        },
      }
    case ADD_LETTER_TEMPLATE:
      addTemplate(state, action.payload)
      return state
    case UPDATE_LETTER_TEMPLATE:
      updateTemplate(state, action.payload, action.response)
      return state
    case DELETE_LETTER_TEMPLATE:
      deleteLetterTemplate(state, action.payload, action.response)
      return state
    case EMPLOYEE_REQUEST_LETTER:
      employeeRequestOfferLetter(state, action.payload)
      return state
    case OFFICER_ISSUE_LETTER:
      officerVerifyEmployeeRequestOffer(state, action.payload)
      return state
    case OFFICER_REJECT_LETTER:
      rejectrequest(state, action.payload)
      return state
    case ADD_AUTHORISED_SIGNATURE:
      addAuthorisedSignature(state, action.payload)
      return state
    case SET_AUTHORIZED_SIGNATURES:
      return {
        ...state,
        auth_signatures: action.payload,
      }
    case SET_LETTER_TEMPLATES:
      return {
        ...state,
        templates: {
          lettertemplates: action.payload,
        },
      }
    case DELETE_AUTHORISED_SIGNATURE:
      deactivateAuthorisedSignature(state, action.payload)
      return state

    case GET_SELECTED_LETTER_CONTENT:
      getSelectedLetterContent(state, action.payload, action.response)
      return state

    case SET_REQUESTED_LETTERS:
      return {
        ...state,
        requestedLetters: action.payload,
      }

    case SET_LETTERS:
      return {
        ...state,
        letters: action.payload,
      }

    case SET_SIGNATURES:
      return {
        ...state,
        authorizedSignatures: action.payload,
      }

    case SET_WIKI_PAGES:
      return {
        ...state,
        wikiPages: action.payload,
      }

    case SET_WIKI_HISTORY:
      return {
        ...state,
        wikiHistory: action.payload,
      }

    case SET_WIKI_CATEGORY:
      return {
        ...state,
        wikiCategories: action.payload,
      }

    case ADD_WIKI_CATEGORY:
      addWikiCategory(state, action.payload, action.wikiAddCategoryCallBack)
      return state

    case DELETE_WIKI_CATEGORY:
      deleteWikiCategory(
        state,
        action.payload,
        action.wikiDeleteCategoryCallBack
      )
      return state

    case CREATE_WIKI_PAGE:
      createWikiPage(state, action.payload, action.wikiAddPageCallBack)
      return state

    case UPDATE_WIKI_PAGE:
      updateWikiPage(state, action.payload, action.wikiUpdatePageCallBack)
      return state

    case DELETE_WIKI_PAGE:
      deleteWikiPage(state, action.payload, action.wikiDeleteCallBack)
      return state

    case FOLLOW_UNFOLLOW_WIKI_ARTICLE:
      followOrUnfollowWikiArticle(
        state,
        action.payload,
        action.followOrUnfollowCallback
      )
      return state

    case VOTE_FOR_WIKI_ARTICLE:
      voteForWikiArticle(state, action.payload, action.voteCallback)
      return state
    case NEW_COMMENT_ON_WIKI:
      newCommentOnWiki(state, action.payload, action.response)
      return state

    case UPDATE_OR_DELETE_WIKI_COMMENT:
      updateOrDeleteWikiComment(state, action.payload, action.response)
      return state

    case UPDATE_WIKI_CATEGORY:
      updateWikiCategory(
        state,
        action.payload,
        action.wikiUpdateCategoryCallback
      )
      return state

    case CREATE_NEW_LABEL:
      createNewLabel(state, action.payload, action.newLabelCallBack)
      return state

    case UPDATE_LABEL:
      updateLabel(state, action.payload, action.updateLabelCallBack)
      return state

    case DELETE_LABEL:
      deleteLabel(state, action.payload, action.deleteLabelCallBack)

    case TEMPORARY_NEW_PLACEMENT_DATA:
      return {
        ...state,
        temporary_placement_data: state.hasOwnProperty(
          'temporary_placement_data'
        )
          ? {
              ...state.temporary_placement_data,
              [action.payload.key]: action.payload[action.payload.key],
            }
          : { [action.payload.key]: action.payload[action.payload.key] },
      }

    case CREATE_GENERAL_PLACEMENT_SETTINGS:
      createGeneralPlacement(
        state,
        action.payload,
        action.createPlacementCallback
      )
      return state

    case CREATE_PAYMENTS_SETTINGS:
      createPayments(state, action.payload, action.createPaymentsCallback)
      return state

    case CREATE_INVOICE:
      createInvoice(state, action.payload, action.createInvoiceCallback)
      return state

    case CREATE_TIMESHEETS_SETTINGS:
      createTimesheets(state, action.payload, action.createInvoiceCallback)
      return state

    case CREATE_WORKLOCATION_SETTINGS:
      createWorkLocation(
        state,
        action.payload,
        action.createWorkLocationCallback
      )
      return state

    case CREATE_TIMESHEETS_DOCUMENTS:
      createDocument(state, action.payload, action.createDocumentCallback)
      return state

    case CLIENT_CURD:
      clientCURD(
        state,
        action.payload,
        action.clientCURDCallback,
        action.actionType
      )
      return state

    case TEMPORARY_NEW_CLIENT_DATA:
      return {
        ...state,
        temporary_client_data: state.hasOwnProperty('temporary_client_data')
          ? {
              ...state.temporary_client_data,
              [action.payload.key]: action.payload[action.payload.key],
            }
          : { [action.payload.key]: action.payload[action.payload.key] },
      }

    case CREATE_CLIENTDETAILS_SETTINGS:
      createClientDetailsSettings(
        state,
        action.payload,
        action.createClientDetailsCallback
      )
      return state

    case EMPLOYEE_TIMESHEET_ACTIONS:
      employeeTimesheetActions(
        state,
        action.payload,
        action.actionType,
        action.employeeSubmitCallback
      )
      return state

    case MANAGER_TIMESHEET_ACTIONS:
      managerTimesheetActions(
        state,
        action.payload,
        action.actionType,
        action.managerTimesheetActionCallback
      )
      return state
    case CLIENT_LOCATION_ACTIONS:
      clientLocationActions(state, action.payload, action.actionType)
      return state

    case CLIENT_CONTACT_ACTIONS:
      clientContactActions(state, action.payload, action.actionType)
      return state

    // case COMPLETE_TIMESHEETS_PROCESS : completeTimesheetsProcess(state, action.payload)
    //         return state

    case INVOICE_ACTIONS:
      invoiceActions(
        state,
        action.payload,
        action.actionType,
        action.invoiceId,
        action.invoiceCallback
      )
      return state

    case CLIENT_UPDATE_ACTIONS:
      clientUpdateActions(state, action.payload)
      return state

    case UPDATE_PLACEMENT_SETTINGS:
      updatePlacementSettings(state, action.payload)
      return state

    case PAYMENT_ACTIONS:
      paymentActions(state, action.payload, action.actionType)
      return state

    case PLACEMENT_SETTINGS_ACTIONS:
      placementSettingsActions(
        state,
        action.payload,
        action.actionType,
        action.placementSettingsActionsWiki
      )
      return state

    case EXPENSE_ACTIONS:
      expenseActions(
        state,
        action.payload,
        action.actionType,
        action.expenseId,
        action.expenseActionsCallback
      )
      return state

    case DEDUCTIONS_ACTIONS:
      deductionsActions(
        state,
        action.payload,
        action.actionType,
        action.deductionId,
        action.deductionActionsCallback
      )
      return state

    case PAYROLL_ACTIONS:
      payrollActions(
        state,
        action.payload,
        action.actionType,
        action.companyID,
        action.payrollActionsCallback
      )
      return state
    case SET_EMAIL_CONTENT:
      return {
        ...state,
        email_content: {
          id: action.payload.id,
          content: action.payload.content,
          defaultContent: action.payload.defaultContent,
          updating: action.payload.updating,
          name: action.payload.name,
        },
      }
    case SET_EMAIL_TEMPLATES:
      return {
        ...state,
        templates: {
          lettertemplates: action.payload,
        },
      }
    case ADD_EMAIL_TEMPLATE:
      addEmailTemplate(state, action.payload)
      return state
    case UPDATE_EMAIL_TEMPLATE:
      updateEmailTemplate(state, action.payload, action.response)
      return state
    case DELETE_EMAIL_TEMPLATE:
      deleteEmailTemplate(state, action.payload, action.response)
      return state
    default:
      return state
  }
}

function DataContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState)

  // attaching middleware

  return (
    <div>
      <DataContext.Provider value={[state, dispatch]}>
        {props.children}
      </DataContext.Provider>
    </div>
  )
}

export default DataContextProvider

export const waitingMsg = (content) => {
  return render(
    <HoldPrompt open={true} content={content} severity='info' />,
    document.getElementById('notifications-hold-box')
  )
}

export const stopWaitMsg = () => {
  return render(<div></div>, document.getElementById('notifications-hold-box'))
}

export const successMsg = (content) => {
  return render(
    <Prompt open={true} content={content} severity='success' />,
    document.getElementById('notifications-box')
  )
}

export const errorMsg = (content) => {
  return render(
    <Prompt open={true} content={content} severity='error' />,
    document.getElementById('notifications-box')
  )
}

// to login
const checkLogin = (cred, state) => {
  console.log('checkLogin')
  //  need to check more keen which returns undefined
  return firebase
    .auth()
    .signInWithEmailAndPassword(cred.email, cred.password)
    .then((user) => {
      if (user) {
        return true
      }
    })
    .catch((err) => {
      console.log(err)
      if (
        err.code === 'auth/wrong-password' ||
        err.code === 'auth/user-not-found'
      )
        alert('Incorrect username or password.')
      else alert(err.message)
      return false
    })
}

// to sign out
const signOut = (action, state) => {
  console.log('signOut')
  localStorage.setItem('imageURL', '')
  localStorage.setItem('role', '')
  localStorage.setItem('email', '')
  return firebase
    .auth()
    .signOut()
    .then(() => {
      return true
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}

const updateCompanyConfig = async (state, data) => {
  /* payload : {
        ...previous details,
        invoiceDetails:{
            invoiceNumberFormat:{
                ---
            },
            invoiceAutoGenerate: boolean,
            invoiceAutoSend: boolean
        },
    }*/
  waitingMsg('Updating company details')
  const response = await Axios.post('/updateCompanyCustomization', data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('Updated company details successfully!')
  } else {
    errorMsg('Something went wrong!')
  }
}

const setCountryApi = async (state, dispatch) => {
  console.log('setting countries')
  let response = {}
  if (!state.isLoggedIn)
    response = await Axios.get(`${configuration.REST_api}/loadcountries`)
  else response = await Axios.get(`/loadcountries`)
  let result = response.data
  dispatch({
    type: COUNTRY_API_DATA,
    payload: result,
  })
}

const changePassword = (state, newPass) => {
  render(
    <HoldPrompt
      open={true}
      content='Password is changing...'
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  let user = firebase.auth().currentUser
  user
    .updatePassword(newPass)
    .then(() => {
      render(<div></div>, document.getElementById('notifications-hold-box'))
      render(
        <Prompt
          open={true}
          content='Password changed successfully!'
          severity='success'
        />,
        document.getElementById('notifications-box')
      )
    })
    .catch((err) => {
      console.log(err)
      render(<div></div>, document.getElementById('notifications-hold-box'))
      render(
        <Prompt
          open={true}
          content='Please re-login to change password'
          severity='info'
        />,
        document.getElementById('notifications-box')
      )
    })
}

const resetPassword = (state, email) => {
  var actionCodeSettings = {
    url: configuration.web_url,
    handleCodeInApp: false,
  }
  firebase
    .auth()
    .sendPasswordResetEmail(email, actionCodeSettings)
    .then((res) => {
      console.log(res)

      render(
        <Prompt
          open={true}
          content='Check your inbox for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.'
          severity='success'
        />,
        document.getElementById('notifications-box')
      )
    })
    .catch((err) => {
      console.log(err)
      if (err.code === 'auth/user-not-found') {
        render(
          <Prompt
            open={true}
            content='No record of your id in veridic solutions'
            severity='error'
          />,
          document.getElementById('notifications-box')
        )
      } else
        render(
          <Prompt open={true} content='Please try again' severity='error' />,
          document.getElementById('notifications-box')
        )
    })
}

const officerResetsPassword = async (state, data) => {
  // payload : {
  //     employeeUID : 'email of the employee',
  //     password : 'new password'
  // }
  waitingMsg('Password is changing...')
  const response = await Axios.post(`/officerResetsPassword`, { ...data })
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('Password changed successfully!')
  } else {
    errorMsg(result.error)
  }
}

const employeeResetsTempPassword = async (state, data) => {
  // payload : {
  //     password : 'new password'
  // }
  waitingMsg('Password is changing...')
  const response = await Axios.post(`/employeeResetsTempPassword`, { ...data })
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('Password changed successfully!')
    window.location.reload()
  } else {
    errorMsg(result.error)
  }
}

// to update profile

const updateProfile = async (data, state) => {
  console.log('updateProfile')
  render(
    <HoldPrompt
      open={true}
      content='Profile is being updated!'
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/updateprofile', data)
  const result = await response.data
  render(<div></div>, document.getElementById('notifications-hold-box'))
  console.log(result)
  if (result.status)
    render(
      <Prompt
        open={true}
        content='Profile updated successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  else
    render(
      <Prompt open={true} content='Something went wrong!' severity='error' />,
      document.getElementById('notifications-box')
    )
}

// new project

const newProject = async (data) => {
  // payload : {
  //     title : 'name of the project',
  //     status : 'status of the project',
  //     startdate : 'startdate',
  //     enddate : 'enddate',
  //     useLabels : 'true or false',<-- new field
  //     useTimeline : 'true or false',<-- new field
  //     cid : 'cid',
  //     Users : 'arrays of employee',
  // }
  console.log('newProject')
  render(
    <HoldPrompt
      open={true}
      content='Project is being created'
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/project', {
    ...data.newProject,
    category: 'taskManager',
    privilege: 'new-project',
    moduleName: 'task-management-manager',
  })

  const result = await response.data
  render(<div></div>, document.getElementById('notifications-hold-box'))
  console.log(result)
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='New project created successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
    data.isProjectCreated(true)
  } else {
    render(
      <Prompt open={true} content={result.error} severity='error' />,
      document.getElementById('notifications-box')
    )
    data.isProjectCreated(false)
  }
}

const commentOn = async (state, comment, callback) => {
  console.log('commentOn')
  let createdByName = ''
  try {
    createdByName = state.names.filter(
      (name) => name.uid === comment.createdBy
    )[0].name
  } catch (error) {
    createdByName = comment.createdBy
  }
  const response = await Axios.post('/newcomment', {
    ...comment,
    createdByName,
  })
  const result = await response.data
  if (result.status) {
    callback()
  } else {
    render(
      <Prompt open={true} content='Failed to comment!' severity='error' />,
      document.getElementById('notifications-box')
    )
    callback()
  }
}

const deleteComment = async (state, commentDetails) => {
  console.log('deleteComment')
  const response = await Axios.post('/deleteComment', { ...commentDetails })
  const result = await response.data
  if (!result.status) {
    render(
      <Prompt
        open={true}
        content='Failed to delete comment!'
        severity='error'
      />,
      document.getElementById('notifications-box')
    )
  }
}

const updateComment = async (state, comment, callback) => {
  console.log('updateComment')
  let createdByName = ''
  try {
    createdByName = state.names.filter(
      (name) => name.uid === comment.createdBy
    )[0].name
  } catch (error) {
    createdByName = comment.createdBy
  }
  const response = await Axios.post('/updateComment', {
    ...comment,
    createdByName,
  })
  const result = await response.data
  if (result.status) {
    callback()
  } else {
    render(
      <Prompt
        open={true}
        content='Failed to update comment!'
        severity='error'
      />,
      document.getElementById('notifications-box')
    )
    callback()
  }
}

const promoteEmployee = async (state, id, onResponse) => {
  console.log('promoteEmployee')
  render(
    <HoldPrompt
      open={true}
      content='Employee is promoting...'
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  const email = id
  const response = await Axios.post('/promoteuser', {
    email,
    category: 'employees',
    privilege: 'promote-employee',
    moduleName: 'employees-manager',
  })
  const result = await response.data
  render(<div></div>, document.getElementById('notifications-hold-box'))
  onResponse()
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='Employee promoted successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  } else {
    render(
      <Prompt open={true} content='Something went wrong!' severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const demoteEmployee = async (state, id, onResponse) => {
  console.log('demoteEmployee')
  const email = id
  render(
    <HoldPrompt
      open={true}
      content='Employee is demoting...'
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/demoteuser', {
    email,
    category: 'employees',
    privilege: 'demote-employee',
    moduleName: 'employees-manager',
  })
  const result = await response.data
  render(<div></div>, document.getElementById('notifications-hold-box'))
  onResponse()
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='Employee demoted successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  } else {
    render(
      <Prompt open={true} content='Something went wrong!' severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const suspendEmployee = async (state, id, onResponse) => {
  console.log('suspendEmployee')
  const email = id
  render(
    <HoldPrompt
      open={true}
      content='Employee is suspending...'
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/disableuser', {
    email,
    category: 'employees',
    privilege: 'disable-employee',
    moduleName: 'employees-manager',
  })
  const result = await response.data
  render(<div></div>, document.getElementById('notifications-hold-box'))
  onResponse()
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='Employee suspended successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  } else {
    render(
      <Prompt open={true} content='Something went wrong!' severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const enableEmployee = async (state, id, onResponse) => {
  console.log('enableEmployee')
  const email = id
  render(
    <HoldPrompt
      open={true}
      content='Employee is enabling...'
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/enableuser', {
    email,
    category: 'employees',
    privilege: 'enable-employee',
    moduleName: 'employees-manager',
  })
  const result = await response.data
  render(<div></div>, document.getElementById('notifications-hold-box'))
  onResponse()
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='Employee enabled successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  } else {
    render(
      <Prompt open={true} content='Something went wrong!' severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const loadProjects = (dispatch) => {
  let projects = []
  firebase
    .firestore()
    .collection('Projects')
    .where('isExist', '==', true)
    .get()
    .then((snap) => {
      if (localStorage.getItem('role') !== 'admin')
        snap.docs.forEach((doc) => {
          console.log(
            doc
              .data()
              .Users.map((user) => user.uid)
              .includes(localStorage.getItem('ownerUID'))
          )
          if (
            doc.data().createdBy === localStorage.getItem('ownerUID') ||
            doc
              .data()
              .Users.map((user) => user.uid)
              .includes(localStorage.getItem('ownerUID'))
          )
            projects.push(doc.data())
        })
      else projects = snap.docs.map((doc) => doc.data())
      dispatch({
        type: SET_PROJECTS,
        payload: projects,
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

const newTask = async (state, newTaskInfo) => {
  // payload : {
  //     title : 'title of the task',
  //     type : 'type of the task',
  //     status : 'status of the task',
  //     startdate : 'startdate',
  //     enddate : 'enddate',
  //     priority : 'priority of the task',
  //     assignee : 'array of assignee',
  //     description : 'description',
  //     label : 'label of the project',<-- new one
  //     id : 'id of the project'
  // }
  console.log('newTask')
  const projectName = state.project.title
  render(
    <HoldPrompt open={true} content='Task is being created' severity='info' />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/task', {
    ...newTaskInfo.data,
    category: 'task',
    privilege: 'new-task',
    projectName,
  })
  const result = await response.data
  console.log(result)
  render(<div></div>, document.getElementById('notifications-hold-box'))
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='New task created successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
    newTaskInfo.isCreated(true)
  } else {
    render(
      <Prompt open={true} content={result.error} severity='error' />,
      document.getElementById('notifications-box')
    )
    newTaskInfo.isCreated(false)
  }
}

const subTask = async (state, newSubtask) => {
  // payload : {
  //     title : 'title of the task',
  //     type : 'type of the task',
  //     status : 'status of the task',
  //     startdate : 'startdate',
  //     enddate : 'enddate',
  //     priority : 'priority of the task',
  //     assignee : 'array of assignee',
  //     description : 'description',
  //     label : 'label of the project',<-- new one
  //     id : 'id of the project',
  //     projectId : 'id of the project',
  //     taskId : 'id of the parent task',
  // }
  console.log('subTask')
  const projectName = state.project.title
  render(
    <HoldPrompt
      open={true}
      content='Subtask is being created'
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/task', {
    ...newSubtask.data,
    category: 'subtask',
    privilege: 'new-task',
    projectName,
  })
  const result = await response.data
  console.log(result)
  render(<div></div>, document.getElementById('notifications-hold-box'))
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='New subtask created successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
    newSubtask.isCreated(true)
  } else {
    render(
      <Prompt open={true} content='Something went wrong!' severity='error' />,
      document.getElementById('notifications-box')
    )
    newSubtask.isCreated(false)
  }
}

const updateTask = async (state, taskEdit, category) => {
  // payload : {
  //     title : 'title of the task',
  //     type : 'type of the task',
  //     status : 'status of the task',
  //     startdate : 'startdate',
  //     enddate : 'enddate',
  //     priority : 'priority of the task',
  //     assignee : 'array of assignee',
  //     description : 'description',
  //     label : 'label of the project',<-- new one
  // }
  console.log('updateTask')
  console.log(taskEdit)
  const taskId = taskEdit.id
  const projectId = state.project.id
  render(
    <HoldPrompt
      open={true}
      content={category + ' is being updated'}
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/updatetask', {
    taskEdit,
    category: 'taskManager',
    privilege: 'update-task',
    projectId,
    taskId,
  })
  const result = await response.data
  render(<div></div>, document.getElementById('notifications-hold-box'))

  if (result.status) {
    render(
      <Prompt
        open={true}
        content='Task updated successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  } else {
    render(
      <Prompt open={true} content='Something went wrong!' severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const updateProject = async (state, updatedProject) => {
  // payload : {
  //     title : 'name of the project',
  //     status : 'status of the project',
  //     startdate : 'startdate',
  //     enddate : 'enddate',
  //     useLabels : 'true or false',<-- new field
  //     useTimeline : 'true or false',<-- new field
  // }
  render(
    <HoldPrompt
      open={true}
      content='Project is being updated'
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/updateproject', {
    ...updatedProject,
    category: 'taskManager',
    privilege: 'update-project',
    projectId: state.project.id,
    moduleName: 'task-management-manager',
  })
  const result = await response.data
  render(<div></div>, document.getElementById('notifications-hold-box'))

  if (result.status) {
    render(
      <Prompt
        open={true}
        content='Project updated successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  } else {
    render(
      <Prompt open={true} content='Something went wrong!' severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const updateAccessLevels = async (state, action) => {
  const projectName = state.project.title

  render(
    <HoldPrompt open={true} content='Updating access levels' severity='info' />,
    document.getElementById('notifications-hold-box')
  )
  render(
    <Backdrop openBackdrop={true} />,
    document.getElementById('backdrop-loading')
  )
  let data = action.data
  console.log(data)
  const response = await Axios.post('/updateProjectAccessLevels', {
    ...data,
    projectName,
    category: 'employees',
    privilege: 'update-project-access-levels',
    moduleName: 'task-management-manager',
  })
  console.log(response)
  const result = await response.data
  render(
    <Backdrop openBackdrop={false} />,
    document.getElementById('backdrop-loading')
  )
  render(<div></div>, document.getElementById('notifications-hold-box'))
  console.log(result)
  if (result.status) {
    render(
      <Prompt
        open={true}
        content={'Updated project access levels for ' + data.uid}
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  } else {
    render(
      <Prompt open={true} content='Something went wrong!' severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const removeMember = async (state, action) => {
  const projectName = state.project.title
  const dispatch = action.dispatch
  render(
    <HoldPrompt
      open={true}
      content={'Removing ' + action.data.employeeUID}
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  render(
    <Backdrop openBackdrop={true} />,
    document.getElementById('backdrop-loading')
  )
  const data = action.data
  const response = await Axios.post(`projects/${data.projectId}/removeMember`, {
    ...data,
    projectName,
    category: 'taskManager',
    privilege: 'delete-project-members',
    moduleName: 'task-management-manager',
  })
  const result = await response.data
  render(<div></div>, document.getElementById('notifications-hold-box'))
  render(
    <Backdrop openBackdrop={false} />,
    document.getElementById('backdrop-loading')
  )
  if (result.status) {
    loadProjects(dispatch)
    render(
      <Prompt
        open={true}
        content={'Removed ' + data.employeeUID + ' from ' + projectName}
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  } else {
    console.log(result)
    render(
      <Prompt open={true} content='Something went wrong!' severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const addMember = async (state, action) => {
  const { projectId, employees } = action.data
  const projectName = state.project.title
  const dispatch = action.dispatch
  console.log(employees)
  employees.map(async (employeeUID) => {
    render(
      <HoldPrompt
        open={true}
        content={'Adding ' + employeeUID}
        severity='info'
      />,
      document.getElementById('notifications-hold-box')
    )
    render(
      <Backdrop openBackdrop={true} />,
      document.getElementById('backdrop-loading')
    )
    let response = await Axios.post(`/projects/${projectId}/addmember`, {
      employeeUID,
      projectName,
      category: 'taskManager',
      privilege: 'add-project-members',
      moduleName: 'task-management-manager',
    })
    let result = await response.data
    console.log(result)
    render(<div></div>, document.getElementById('notifications-hold-box'))
    render(
      <Backdrop openBackdrop={false} />,
      document.getElementById('backdrop-loading')
    )
    if (result.status) {
      loadProjects(dispatch)
      render(
        <Prompt
          open={true}
          content={'Added ' + employeeUID + ' in ' + projectName}
          severity='success'
        />,
        document.getElementById('notifications-box')
      )
    } else {
      render(
        <Prompt open={true} content='Something went wrong!' severity='error' />,
        document.getElementById('notifications-box')
      )
    }
  })
}

const createNewLabel = async (state, data, callback) => {
  // payload : {
  //     projectId : "id of the project",
  //     labelName : "name of the label",
  //     labelColorCode : "color code i.e for example :'#0e8a16'"
  // }
  waitingMsg('Creating new label...')
  const response = await Axios.post(`/createNewLabel`, data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    // callback()
  } else {
    errorMsg(result.error)
  }
}

const updateLabel = async (state, data, callback) => {
  // payload : {
  //     projectId : "id of the project",
  //     labelName : "name of the label",
  //     labelId : "id of the label"
  //     labelColorCode : "color code i.e for example :'#0e8a16'"
  // }
  waitingMsg('Updating the label...')
  const response = await Axios.post(`/updateLabel`, data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    // callback()
  } else {
    errorMsg(result.error)
  }
}

const deleteLabel = async (state, data, callback) => {
  // payload : {
  //     projectId : "id of the project",
  //     labelId : "id of the label"
  // }
  const { projectId, labelId } = data
  waitingMsg('Deleting the label...')
  const response = await Axios.get(`/${projectId}/deleteLabel/${labelId}`)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    // callback()
  } else {
    errorMsg(result.error)
  }
}

// templates

const addTemplate = async (state, data) => {
  // payload : {
  //     type : 'type of the letter',
  //     content : 'content of the letter',
  //     name : 'name of the letter'
  // }
  render(
    <HoldPrompt
      open={true}
      content='Template is being created'
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/addlettertemplate', {
    ...data,
    moduleName: 'console-customization',
  })
  const result = await response.data
  console.log(result)
  render(<div></div>, document.getElementById('notifications-hold-box'))
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='New template added successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  } else {
    render(
      <Prompt open={true} content={result.error} severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const updateTemplate = async (state, data, callback) => {
  // payload : {
  //     type : 'type of the letter',
  //     content : 'content of the letter',
  //     name : 'name of the letter',
  //     id : ''
  // }
  render(
    <HoldPrompt
      open={true}
      content='Template is being updated'
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/updatelettertemplate', {
    ...data,
    id: state.letter_content.id,
    moduleName: 'console-customization',
  })
  const result = await response.data
  console.log(result)
  render(<div></div>, document.getElementById('notifications-hold-box'))
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='Template updated successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
    callback()
  } else {
    render(
      <Prompt open={true} content={result.error} severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const deleteLetterTemplate = async (state, data, callback) => {
  // payload : {
  //     id : 'id of the letter'
  // }
  render(
    <HoldPrompt
      open={true}
      content='Template is being deleted'
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/deletelettertemplate', {
    ...data,
    moduleName: 'console-customization',
  })
  const result = await response.data
  console.log(result)
  render(<div></div>, document.getElementById('notifications-hold-box'))
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='Template deleted successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
    callback()
  } else {
    render(
      <Prompt open={true} content={result.error} severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const employeeRequestOfferLetter = async (state, data) => {
  // payload : {
  //     DocType : 'type of the letter',
  //     attachment : {
  //         sourcePath : 'path of the doc',
  //         publicURL : 'url of the image',
  //     },
  //     description : 'description regarding the request',
  //     additionalDetails : 'add. details like university details etc., in object type'
  // }
  render(
    <HoldPrompt open={true} content='Request is processing' severity='info' />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/employeeRequestOfferLetter', data)
  const result = await response.data
  console.log(result)
  render(<div></div>, document.getElementById('notifications-hold-box'))
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='Your request has been placed successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  } else {
    render(
      <Prompt open={true} content={result.error} severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const officerVerifyEmployeeRequestOffer = async (state, data) => {
  // payload : {
  //     offerLetterId : 'the id of the letter which is going to be issued',
  //     letterIssueDate : 'issue date of the letter',
  //     authorisedSignatureId : 'auth signature id',
  //     requestId : 'letter request id '
  // }
  render(
    <HoldPrompt open={true} content='Issuing the letter' severity='info' />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/officerVerifyEmployeeRequestOffer', data)
  const result = await response.data
  console.log(result)
  render(<div></div>, document.getElementById('notifications-hold-box'))
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='Letter has been issued successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  } else {
    render(
      <Prompt open={true} content={result.error} severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const rejectrequest = async (state, data) => {
  // payload : {
  //     imageURL : 'provide the image url of the signature',
  //     uid : 'email of the person'
  // }
  render(
    <HoldPrompt open={true} content='Rejecting the letter' severity='info' />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/rejectrequest', data)
  const result = await response.data
  console.log(result)
  render(<div></div>, document.getElementById('notifications-hold-box'))
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='Letter has been rejected successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  } else {
    render(
      <Prompt open={true} content='Something went wrong!' severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const addAuthorisedSignature = async (state, data) => {
  // payload : {
  //     imageURL : 'provide the image url of the signature',
  //     uid : 'email of the person'
  // }
  render(
    <HoldPrompt
      open={true}
      content='Adding authorised signature'
      severity='info'
    />,
    document.getElementById('notifications-hold-box')
  )
  const response = await Axios.post('/addAuthorisedSignature', data)
  const result = await response.data
  console.log(result)
  render(<div></div>, document.getElementById('notifications-hold-box'))
  if (result.status) {
    render(
      <Prompt
        open={true}
        content='Authorised signature has been added successfully!'
        severity='success'
      />,
      document.getElementById('notifications-box')
    )
  } else {
    render(
      <Prompt open={true} content={result.error} severity='error' />,
      document.getElementById('notifications-box')
    )
  }
}

const deactivateAuthorisedSignature = async (state, data) => {
  // payload : {
  //     signatureUID : 'uid of the person',
  //     signatureId : 'id of the signature'
  // }
  waitingMsg('Deleting signature')
  const response = await Axios.post('/deactivateAuthorisedSignature', data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('Signatured deleted successfully!')
  } else {
    errorMsg('Something went wrong!')
  }
}

const getSelectedLetterContent = async (state, data, callback) => {
  console.log('getSelectedLetterContent')
  // payload : {
  //     letterId : 'id of the selected letter',
  // }
  const response = await Axios.post('/getSelectedLetterContent', data)
  const result = await response.data
  console.log(result)
  if (result.status) {
    callback(true, result.response)
  } else {
    callback(false, '')
    errorMsg('Failed to load pdf!')
  }
}

const createWikiPage = async (state, data, callback) => {
  // payload : {
  //     topicName : 'name of the topic',
  //     title : 'title of the article',
  //     articleContent : 'html content of the article',
  // }
  waitingMsg('Creating wiki page')
  const response = await Axios.post('/createPageInWiki', { ...data })
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('Wiki page created successfully!')
    callback()
  } else {
    errorMsg(result.error)
  }
}

const updateWikiPage = async (state, data, callback) => {
  // payload : {
  //     wikiId : 'id of the page',
  //     topicName : 'name of the topic',
  //     title : 'title of the article',
  //     articleContent : 'html content of the article'
  // }
  waitingMsg('Updating wiki page')
  const response = await Axios.post('/updatePageInWiki', {
    ...data,
    moduleName: 'wiki-manager',
  })
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('Updated page created successfully!')
    callback()
  } else {
    errorMsg(result.error)
  }
}

const deleteWikiPage = async (state, data, callback) => {
  // payload : {
  //     wikiId : 'id of the page',
  // }
  waitingMsg('Deleting wiki page')
  const response = await Axios.get('/deletePageInWiki/' + data.wikiId)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('Deleted page successfully!')
  } else {
    errorMsg(result.error)
  }
}

const addWikiCategory = async (state, data, callback) => {
  // payload : {
  //     categoryName : 'name of the category',
  //     description : 'description about the new category'
  // }
  waitingMsg('Adding new category')
  const response = await Axios.post('/wiki/addCategory', {
    ...data,
    moduleName: 'wiki-manager',
  })
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('New category added successfully!')
    callback()
  } else {
    errorMsg(result.error)
  }
}

const deleteWikiCategory = async (state, data, callback) => {
  // payload : {
  //     categoryId : 'id of the category'
  // }
  waitingMsg('Deleting category')
  const response = await Axios.get(`/wiki/deleteCategory/${data.categoryId}`)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('Category deleted successfully!')
    callback()
  } else {
    errorMsg(result.error)
  }
}

const updateWikiCategory = async (state, data, callback) => {
  // payload : {
  //     categoryName : 'name of the category',
  //     description : 'if updated send updated description else send old description',
  //     categoryId : 'id of the category'
  // }
  waitingMsg('Updating category')
  const response = await Axios.post('/wiki/updateCategory', {
    ...data,
    moduleName: 'wiki-manager',
  })
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('Category updated successfully!')
    callback()
  } else {
    errorMsg(result.error)
  }
}

const linkTasks = async (state, data) => {
  // payload : {
  //     parentTaskId : 'id of the current viewing parent task',
  //     tasksToBeLinked : 'array of task ids to be linked',
  //     projectId : 'id of the project'
  // }
  waitingMsg('Linking the tasks...')
  const response = await Axios.post('/linkTasks', { ...data })
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('Tasks linked successfully!')
  } else {
    errorMsg(result.error)
  }
}

const unlinkTasks = async (state, data) => {
  // payload : {
  //     tasksToBeUnlinked : 'array of the ids to be unlinked',
  //     projectId : 'id of the project'
  // }
  waitingMsg('Unlinking the tasks...')
  const response = await Axios.post('/unlinkTasks', { ...data })
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('Tasks unlinked successfully!')
  } else {
    errorMsg(result.error)
  }
}

const followOrUnfollowWikiArticle = async (state, data, callback) => {
  // payload : {
  //     wikiId : 'array of the ids to be unlinked',
  //     index : 'index of the article',
  //     type : "to follow ---> 'follow' or to unfollow ---> 'unfollow' "
  // }

  const { wikiId, index, type } = data
  waitingMsg('Following request processing...')
  const response = await Axios.get(
    `/followOrUnfollowWikiArticle/${wikiId}/${index}/${type}`
  )
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback()
  } else {
    errorMsg(result.error)
  }
}

const voteForWikiArticle = async (state, data, callback) => {
  // payload : {
  //     wikiId : 'array of the ids to be unlinked',
  //     index : 'index of the article',
  //     type : "to follow ---> 'follow' or to unfollow ---> 'unfollow' "
  // }

  const { wikiId, index, type } = data
  waitingMsg('Voting request processing...')
  const response = await Axios.get(
    `/voteForWikiArticle/${wikiId}/${index}/${type}`
  )
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback()
  } else {
    errorMsg(result.error)
  }
}

const newCommentOnWiki = async (state, data, callback) => {
  // payload : {
  //     wikiId : 'id of the article',
  //     content : 'html content of the new comment'
  // }
  const response = await Axios.post(`/commentOnWikiArticle`, data)
  const result = await response.data
  console.log(result)
  if (result.status) {
    callback.responseCame()
  } else {
    callback.responseNotCame()
    errorMsg(result.error)
  }
}

const updateOrDeleteWikiComment = async (state, data, callback) => {
  // payload : {
  //     wikiId : 'id of the article',
  //     commentId : 'id of the comment',
  //     actionType : "if you want to delete then give 'delete' as value or if you want to update then give 'update' as value",
  //     content : 'if you want to update then provide this key with html content else no need of providing the key'
  // }
  const response = await Axios.post(`/deleteOrUpdateOnArticle`, data)
  const result = await response.data
  console.log(result)
  if (result.status) {
    callback.responseCame()
  } else {
    callback.responseNotCame()
    errorMsg(result.error)
  }
}

const createGeneralPlacement = async (state, data, callback) => {
  // payload : {
  //     "empCode" : "employee company id i.e ex:VER000001",
  //     "billableClient" : "",
  //     "clientId" : "eg:CLT-003",
  //     "description" : "",
  //     "startDate" : "ISO string",
  //     "endDate" : "ISO string",
  //     "projectEndDate" : "ISO string"
  // }
  waitingMsg('Creating...')
  const response = await Axios.post(`/newPlacement`, data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback()
  } else {
    callback()
    errorMsg(result.error)
  }
}

const createPayments = async (state, data, callback) => {
  // payload : {
  //     "OTbillingRate" : "",
  //     "OTpayRate" : "",
  //     "billingRate" : "",
  //     "billingType" : "",
  //     "effectiveDate" : "",
  //     "effectiveUntil" : "",
  //     "employeePayRate" : "",
  //     "payType" : "",
  //     "purchaseOrderFile" : "",
  //     "purchaseOrderNumber" : "",
  //     "placementID" : "P2hfHnYXZy0U8CKsZ9S3",
  //     "empCode" : "VER000003"
  // }
  waitingMsg('Creating...')
  const response = await Axios.post(`/createPayments`, data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback()
  } else {
    callback()
    errorMsg(result.error)
  }
}

const createInvoice = async (state, data, callback) => {
  // payload : {
  //     "frequency" : "",
  //     "OT" : "",
  //     "POnumber" : "",
  //     "pointOfContactMailId" : "",
  //     "attachFlairExpense" : "",
  //     "attachFlairTimesheets" : "",
  //     "bcc" : "",
  //     "billingAddress" : "",
  //     "calculationType" : "",
  //     "cc" : "",
  //     "frequencyStartDate" : "",
  //     "pointOfContactName" : "",
  //     "pointOfContactPhoneNo" : "",
  //     "to" : "",
  //     "placementID" : "eg:P2hfHnYXZy0U8CKsZ9S3",
  //     "empCode" : "VER000003"
  // }
  waitingMsg('Creating...')
  const response = await Axios.post(`/createInvoice`, data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback()
  } else {
    callback()
    errorMsg(result.error)
  }
}

const createWorkLocation = async (state, data, callback) => {
  // payload : {
  //     "amendmentRequired" : true,
  //     "city" : "",
  //     "country" : "",
  //     "email" : "",
  //     "line1" : "",
  //     "line2" : "",
  //     "phonenumber" : "",
  //     "state" : "",
  //     "zip" : "",
  //     "type" : "",
  //     "placementID" : "P2hfHnYXZy0U8CKsZ9S3",
  //     "empCode" : "VER000003"
  // }
  waitingMsg('Creating...')
  const response = await Axios.post(`/createWorkLocation`, data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback()
  } else {
    callback()
    errorMsg(result.error)
  }
}

const createTimesheets = async (state, data, callback) => {
  // payload : {
  //  "approvalBy" : "",
  // 	"attachMandatory" : "",
  // 	"cycle" : "",
  // 	"enableTask" : "",
  // 	"linkToProject" : "",
  // 	"makeMandatory" : "",
  // 	"startdate" : ""
  // }
  waitingMsg('Creating...')
  const response = await Axios.post(`/createTimeSheets`, data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback()
  } else {
    callback()
    errorMsg(result.error)
  }
}

const createClientDetailsSettings = async (state, data, callback) => {
  waitingMsg('Creating...')
  const response = await Axios.post(`/createClientDetailsSettings`, data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback()
  } else {
    callback()
    errorMsg(result.error)
  }
}

const createDocument = async (state, data, callback) => {
  // payload : {
  //  "actions" : "",
  // 	"document" : "",
  // 	"status" : "",
  // 	"type" : """"
  // }
  waitingMsg('Creating...')
  const response = await Axios.post(`/createDocument`, data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback()
  } else {
    callback()
    errorMsg(result.error)
  }
}

const clientCURD = async (state, data, callback, type) => {
  // payload : {
  //     "businessName" : "",
  //     "contactNumber" : "",
  //     "website" : "",
  //     "jobTerminationNotice" : "",
  //     "category" : "",
  //     "businessDisplayName" : "",
  //     "email" : "",
  //     "federalId" : "",
  //     "netTerms" : "",
  //     "fax" : "",
  //     "permanentPlacement":{
  //         "netTerms" : "",
  //         "commissionType" : "",
  //         "commission" : ""
  //     },
  //     "invoiceLocation" : {
  //         "line1" : "",
  //         "line2" : "",
  //         "city" : "",
  //         "state" : "",
  //         "country" : "",
  //         "zipCode" : ""
  //     },
  //     "locations" : [],
  //     "comments" : ""
  // }

  // actionType : " create or delete or update "
  if (type === 'create') waitingMsg('Creating new client ...')
  else if (type === 'delete') waitingMsg('Removing the client ...')

  const response = await Axios.post(`/client/${type}`, data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback()
  } else {
    errorMsg(result.error)
  }
}

const employeeTimesheetActions = async (state, data, actionType, callback) => {
  // actionType : " create or delete or update "
  if (actionType === 'submit') waitingMsg('Submitting timesheet ...')
  else if (actionType === 'update') waitingMsg('Updating the timesheet')

  const response = await Axios.post(
    `/timesheets/employeetimesheetactions/${actionType}`,
    { ...data }
  )
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback()
  } else {
    errorMsg(result.error)
  }
}

const managerTimesheetActions = async (state, data, actionType, callback) => {
  // actionType : " create or delete or update "
  if (actionType === 'approve') waitingMsg('Approving the timesheet ...')
  else if (actionType === 'reject') waitingMsg('Rejecting the timesheet ...')
  console.log(data)
  const response = await Axios.post(
    `/timesheets/managertimesheetaction/${actionType}`,
    data
  )
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    // callback(actionType)
  } else {
    errorMsg(result.error)
  }
}

const clientLocationActions = async (state, data, actionType) => {
  // actionType : " new or edit or delete "
  if (actionType === 'new') waitingMsg('Creating the location ...')
  else if (actionType === 'edit') waitingMsg('Updating the location ...')
  else if (actionType === 'delete') waitingMsg('Deleting the location ...')
  console.log(data)
  const response = await Axios.post(
    `/timesheets/clientLocations/${actionType}`,
    data
  )
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
  } else {
    errorMsg(result.error)
  }
}

const clientContactActions = async (state, data, actionType) => {
  // actionType : " new or edit or delete "
  console.log(actionType)
  if (actionType === 'new') waitingMsg('Creating the contact ...')
  else if (actionType === 'edit') waitingMsg('Updating the contact ...')
  else if (actionType === 'delete') waitingMsg('Deleting the contact ...')
  console.log(data)
  const response = await Axios.post(
    `/timesheets/clientContacts/${actionType}`,
    data
  )
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
  } else {
    errorMsg(result.error)
  }
}

const invoiceActions = async (state, data, actionType, invoiceId, callback) => {
  if (actionType === 'new') waitingMsg('Creating the invoice ...')
  else if (actionType === 'edit') waitingMsg('Updating the invoice ...')
  else if (actionType === 'delete') waitingMsg('Deleting the invoice ...')
  console.log(invoiceId)
  const response = await Axios.post(
    `/timesheets/invoices/${actionType}/${invoiceId}`,
    data
  )
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback(actionType)
  } else {
    errorMsg(result.error)
  }
}

const clientUpdateActions = async (state, data) => {
  waitingMsg('Updating the client details')
  const response = await Axios.post(`/client/details/update`, data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
  } else {
    errorMsg(result.error)
  }
}

const updatePlacementSettings = async (state, data) => {
  const sectionName = data.sectionName
  const id = data.docId
  // payload: {
  //     docId: 'placement doc id',
  //     sectionName: 'general || payments || documents || worklocation || timesheet || clientdetails || invoices',
  //     ...otherUpdatedDetails
  // }
  waitingMsg(`Updating the ${sectionName} settings`)
  const response = await Axios.post(`/placement/update/${id}`, data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
  } else {
    errorMsg(result.error)
  }
}

const paymentActions = async (state, data, actionType) => {
  /*
    payload:{
        paymentDate: 'ISO String',
        paymentType: '',
        referenceNumber: '',
        referenceNumber: '',
        paymentOpenBalance: '',    
        paymentDiscounts: '',
        paymentAmount: '',
        invoicedPayments: '',
        paymentDocument: '',
        clientId: '',
    }
    */
  if (actionType === 'new') waitingMsg('Creating the payment ...')
  else if (actionType === 'edit') waitingMsg('Updating the payment ...')
  else if (actionType === 'delete') waitingMsg('Deleting the payment ...')

  const response = await Axios.post(`/payments/${actionType}`, data)
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
  } else {
    errorMsg(result.error)
  }
}

const placementSettingsActions = async (state, data, actionType, callback) => {
  /*
        payload:{
            ...all other detailed keys,
            sectionName: 'general' || 'payments' || 'invoices' || 'worklocation' || 'documents' || 'timesheets' || 'client-details' || 'recruitment-details' || 'expense-details',
            placementID: for creation( if it is new general settings ) ? "give the placementID as 'new' " : 'give the placementID as the doc ID ',
        },
        actionType: 'new' || 'update'
    */
  console.log(data.sectionName)
  if (actionType === 'new' && data.sectionName === 'general')
    waitingMsg('Initiating the placement ...')
  else if (actionType === 'new')
    waitingMsg(`Creating the ${data.sectionName} settings...`)
  else if (actionType === 'update')
    waitingMsg(`Updating the ${data.sectionName} settings...`)

  const response = await Axios.post(
    `/placement/${actionType}/${data.sectionName}/${data.placementID}`,
    data
  )
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback()
  } else {
    errorMsg(result.error)
  }
}

const expenseActions = async (state, data, actionType, expenseId, callback) => {
  /*
        payload:{
            ...all other detailed keys,
        },
        actionType: 'new' || 'update',
        expenseId: if creating new ? 'expense': docId
    */
  if (actionType === 'new') waitingMsg('Creating new expense ...')
  else if (actionType === 'update') waitingMsg(`Updating the expense ...`)
  else if (actionType === 'approve') waitingMsg(`Approving the expense ...`)
  else if (actionType === 'reject') waitingMsg(`Approving the expense ...`)
  const response = await Axios.post(
    `/expenses/${actionType}/${expenseId}`,
    data
  )
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg(result.message)
    callback()
  } else {
    errorMsg(result.error)
  }
}

const deductionsActions = async (
  state,
  data,
  actionType,
  deductionId,
  callback
) => {
  /*
        payload:{
            ...all other detailed keys,
        },
        actionType: 'new' || 'update',
        deductionId: if creating new ? 'deduction': docId
    */
  if (actionType === 'new') waitingMsg('Adding new deduction ...')
  else if (actionType === 'update') waitingMsg(`Updating the deduction ...`)
  try {
    const response = await Axios.post(
      `/deductions/${actionType}/${deductionId}`,
      data
    )
    const result = await response.data
    stopWaitMsg()
    successMsg(result.message)
    callback()
  } catch (error) {
    console.log(error)
    stopWaitMsg()
    errorMsg(error.message)
  }
}

const payrollActions = async (state, data, actionType, companyID, callback) => {
  /*
        payload:{
            ...all other detailed keys,
        },
        actionType: 'new' || 'update',
        companyID: docId
    */
  if (actionType === 'new') waitingMsg('Adding new deduction ...')
  else if (actionType === 'update') waitingMsg(`Updating the deduction ...`)
  try {
    const response = await Axios.post(
      `/payrolls/${actionType}/${companyID}`,
      data
    )
    const result = await response.data
    stopWaitMsg()
    successMsg(result.message)
    callback()
  } catch (error) {
    console.log(error)
    stopWaitMsg()
    errorMsg(error.message)
  }
}

const addEmailTemplate = async (state, data) => {
  // payload : {
  //     type : 'type of the letter',
  //     content : 'content of the letter',
  //     name : 'name of the letter'
  // }
  waitingMsg('Template is being created')
  const response = await Axios.post('/addemailtemplate', {
    ...data,
    moduleName: 'console-customization',
  })
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('New template added successfully!')
  } else {
    errorMsg(result.error)
  }
}

const updateEmailTemplate = async (state, data, callback) => {
  // payload : {
  //     type : 'type of the letter',
  //     content : 'content of the letter',
  //     name : 'name of the letter',
  //     id : ''
  // }
  waitingMsg('Template is being updated')
  const response = await Axios.post('/updateemailtemplate', {
    ...data,
    id: state.email_content.id,
    moduleName: 'console-customization',
  })
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('Template updated successfully!')
    callback()
  } else {
    errorMsg(result.error)
  }
}

const deleteEmailTemplate = async (state, data, callback) => {
  // payload : {
  //     id : 'id of the letter'
  // }
  waitingMsg('Template is being deleted')
  const response = await Axios.post('/deleteemailtemplate', {
    ...data,
    moduleName: 'console-customization',
  })
  const result = await response.data
  console.log(result)
  stopWaitMsg()
  if (result.status) {
    successMsg('Template deleted successfully!')
    callback()
  } else {
    errorMsg(result.error)
  }
}
