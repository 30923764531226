import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import  {GoLock} from 'react-icons/go'

export default function Presentation(props) {
  const {handleConfirmPass,handleSetNewPass,handleSetPass,helperEight, newPass, confirmPass, email}=props
  const [open, setOpen] = React.useState(false);

  const handleClickOpenit = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false)
  };
  const handleCloseit = () => {
    setOpen(false);
  };
  const handlechangepass=()=>{
    handleSetNewPass();
      handleClose()
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpenit}>
        <GoLock className="c-pointer" size={18} />
      </Button>
      <Dialog open={open} onClose={handleCloseit} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reset the Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the below fields
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            onChange={handleSetPass}
          />
          <TextField
            
            margin="dense"
            id="confirmpassword"
            label="Confirm Password"
            type="password"
            fullWidth
            onChange={handleConfirmPass}
          />
          {newPass !== confirmPass ?<span className="helperText text-danger">Passwords doesn't match</span>:null} 
          <h6 style={{fontSize:"small"}}>Make sure it's {helperEight?<span className="text-danger"> at least 8 characters </span>:<span className="text-success"> at least 8 characters</span>}</h6>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseit} color="primary">
            Cancel
          </Button>
          
          {newPass.length>7 && newPass===confirmPass ? <Button color="primary" variant="contained" onClick={handlechangepass}>Reset</Button> : <Button variant="contained" color="primary" disabled>Reset</Button>  } {' '}
          {console.log('Password test')}
          {console.log(newPass,confirmPass, email)}
        </DialogActions>
      </Dialog>
    </div>
  );
}
