import React from 'react'
import { Tabs, Tab, Typography, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import ClientDetails from '../ClientDetails'
import Contacts from '../Contacts/Index'
import Locations from '../Locations/Index'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function Presentation(props) {
    const { client } = props
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <div>
            <Tabs
                value={value}
                className="custom-card mt-2"
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="secondary"
                aria-label="icon label tabs example"
            >
                <Tab label="Details" />
                <Tab label="Contacts" />
                <Tab label="Locations" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <ClientDetails client={client} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Contacts client={client} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Locations client={client} />
            </TabPanel>      
        </div>
    )
}

export default Presentation
