import React, { useContext, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Button,
    MenuItem
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { IconButton } from "@material-ui/core"
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{ opacity: 0 }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
function Presentation(props) {

    const [open, setOpen] = React.useState(false);
    const { handleChange, rowData, invoicesList, paymentHandleChange, data, handleEvent, discount, handleSave, handleCancel, discountDetails,handleDiscount } = props
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSave1 = () => {
        handleSave();
        setOpen(false)
    };
    const handleCancel1 = () => {
        handleCancel();
        setOpen(false)
    };
    return (
        <div className="mt-1">
            <IconButton color="inherit" onClick={handleClickOpen}>
                <AddIcon />
            </IconButton>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleCancel1}>
                    Disount
                </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        style={{ width: "70px" }}
                        size="small"
                        variant="outlined"
                        value={discountDetails["name"]}
                        onChange={(e) => {
                            discountDetails["name"] = e.target.value;
                            handleEvent("discountDetails", discountDetails);
                        }}
                        label="Name"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />&nbsp;&nbsp;


                <TextField
                        style={{ width: "130px" }}
                        size="small"
                        label="Select"
                        variant="outlined"
                        value={discountDetails["type"]}
                        onChange={(e) => {
                            discountDetails["type"] = e.target.value;
                            handleEvent("discountDetails", discountDetails);
                        }}
                        select
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                        <MenuItem value="byValue">By Value</MenuItem>
                        <MenuItem value="byPercentage">By Percentage</MenuItem>
                    </TextField>&nbsp;&nbsp;


                <TextField
                        style={{ width: "70px" }}
                        size="small"
                        type="number"
                        variant="outlined"
                        value={discountDetails["value"]}
                        helperText={
                            rowData.openBalance1 < parseFloat(rowData.paymentBalance) + parseFloat(handleDiscount(discountDetails["type"],discountDetails["value"],rowData.subTotal)) ?
                                'Enter valid Amount' : ""
                        }
                        onChange={(e) => {
                            discountDetails["value"] = e.target.value;
                            handleEvent("discountDetails", discountDetails);
                        }}
                        label="Discount"
                        InputLabelProps={{
                            shrink: true,
                        }} /><br /><br />
                    <Grid xs={12}>
                        {
                             rowData.openBalance1 < parseFloat(rowData.paymentBalance) + parseFloat(handleDiscount(discountDetails["type"],discountDetails["value"],rowData.subTotal)) ?
                        
                        <Button variant="contained" color="primary" component="span" disabled onClick={handleSave1}
                        >
                            Done
                                  </Button> :   <Button variant="contained" color="primary" component="span" onClick={handleSave1}
                        >
                            Done
                                  </Button>}
                                &nbsp;&nbsp;&nbsp;
                            <Button variant="contained" color="secondary" component="span" onClick={handleCancel1} >
                            Cancel
                            </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Presentation;