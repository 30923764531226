import React, { useContext } from 'react'
import { FaRegFilePdf } from 'react-icons/fa'
import { configuration } from '../../../configuration'
import { DataContext } from '../../../contexts/data'
import PreviewPDF from '../../PreviewPDF'

function Presentation(props) {
    const [state] = useContext(DataContext)
    let { individual, id } = props

    let personal = ""
    let companyID = ""
    let imageURL = ""
    if (individual) {
        personal = state.employee_collection.personal
        companyID = state.employee_collection.companyID
        imageURL = state.employee_collection.imageURL
    }
    else {
        let profile = state.employees_collection.filter(user => user.useremail === id)[0]
        personal = profile.personal
        companyID = profile.companyID
        imageURL = profile.imageURL
    }
    const content = (
        <div id="taskpdfid" style={{ marginTop: "25px"}}>

            <div className="border border-dark p-3 rounded">

                <div className="text-center">
                    <div className="">
                        <span className="mr-auto">
                            <img alt="" src={configuration.logo} width="150px" height="80px" className="set-center" /><br /><br />
                            <img alt="" className="image set-center"
                                ref={(image) => image}
                                width="50px"
                                height="50px"
                                src={imageURL}
                            />
                        </span>
                    </div>
                    <br />
                    <div>
                        <span><h4>{personal.firstname + " " + personal.middlename + " " + personal.lastname}</h4></span>
                    </div>
                    <br />
                    <div>
                        <span><b>Employee ID &nbsp;: &nbsp;{companyID}</b></span>
                    </div>
                </div>
            </div>

        </div>
    )
    return (
        <PreviewPDF
            headerAndFooter={false}
            htmlContent={content}
            btnContent={<span><FaRegFilePdf size={18} /> Print ID</span>}
            iconBtn={false}
            isString={false}
        />
    )
}

export default Presentation
