import React, { PureComponent } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../../contexts/data'
import firebase from '../../../../../../firebase_config'

class Container extends PureComponent {
  state = {
    client: '',
    clientId: '',
    workLocation: false,
    clientType: '',
    selectAddress: '',
    subContracting: '',
    contingencyinPayment: '',
    prohibitionPeriod: '',
    rightToHire: '',
    liquidatedDamages: '',
    identification: '',
    comments: '',
    selectAddressList: [],
    billableClient: '',
    settingsId: '',
    isExist: true
  }

  static contextType = DataContext

  getLocations = (value) => {
    const [state] = this.context
    firebase
      .firestore()
      .collection('ClientDetails')
      .doc(value)
      .collection('Locations')
      .get()
      .then((snap) => {
        const locations = snap.docs.map((item) => item.data())
        this.setState({
          selectAddressList: locations,
        })
      })
    let required =
      state.client_list &&
      state.client_list.filter((item) => {
        return item.id === value
      })
    try {
      this.setState({
        clientId: required[0].clientId,
      })
    } catch (error) {
      console.error(error)
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentDidMount = () => {
    const { item, billableClient } = this.props
    this.setState({ billableClient })
    Object.entries(item).forEach(([key, value]) => {
      this.setState({
        [key]: value,
      })
      if(key === 'client') {
        if(value) this.getLocations(value)
      }
    })
    if(billableClient) this.getLocations(billableClient)
  }

  componentWillReceiveProps = (nextProps) => {
    const { item, billableClient } = nextProps
    this.setState({ billableClient })
    Object.entries(item).forEach(([key, value]) => {
      this.setState({
        [key]: value,
      })
      if(key === 'client') {
        if(value) this.getLocations(value)
      }
    })
    if(billableClient) {
      this.getLocations(billableClient)
      this.onChange('client', billableClient)
      this.onChange('clientType', 'Billable Client')
    }
  }

  handleCheck = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    })
  }

  handleCreate = () => {
    const {
      client,
      clientId,
      workLocation,
      clientType,
      selectAddress,
      subContracting,
      contingencyinPayment,
      prohibitionPeriod,
      rightToHire,
      liquidatedDamages,
      identification,
      comments,
      settingsId,
      isExist
    } = this.state
    const { handleChange, tabList, index } = this.props
    let payload = {
      key: index,
      id: index,
      client,
      clientId,
      workLocation,
      clientType,
      selectAddress,
      subContracting,
      contingencyinPayment,
      prohibitionPeriod,
      rightToHire,
      liquidatedDamages,
      identification,
      comments,
      settingsId,
      isExist
    }

    let data = tabList
    data[index] = payload
    handleChange(data)
  }

  handleSave = (type) => {
    if (type === 'create') this.handleCreate()
    else if (type === 'update') this.handleUpdate()
    else console.log('Error')
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    })
    if(key === 'client') {
      if(value) this.getLocations(value)
    }
  }

  render() {
    return (
      <div>
        <Presentation
          {...this.state}
          index={this.props.index}
          handleChange={this.handleChange}
          handleSave={this.handleSave}
          handleCheck={this.handleCheck}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

export default Container
