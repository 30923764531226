import React, { useContext } from 'react'
import MaterialTable from 'material-table'
import Validations from '../../generalcomponents/validation'
import { DataContext } from '../../../contexts/data'
import parser from 'html-react-parser'
import { calcTotalTime } from '../../TimeSheets/Invoices/CreateInvoice/ServiceSummary/Presentation'
import PayrollSettings from "../PayrollSettings";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import {

    TextField,
    MenuItem,
    makeStyles,
    Button,
} from '@material-ui/core';
function Presentation(props) {
    const {
        id,
        listAll,
        Payrolls,
        dealPay,
        excludeDeductions,
        payBonus,
        insurance,
        isBench,
        fixedPay,
        fixedBenchPayroll,
        dealPeriod,
        stopPayroll,
        companyID,
        singleValue,
        selectedDate,
        handleDateChange,
        selectedHalf,
        handleChange,
        email
    } = props




    const [state] = useContext(DataContext)
    console.log(Payrolls)
    const validate = new Validations()
    const columns = [
        listAll ? { title: 'Name', field: '' } : { title: 'Duration', field: '' },
        { title: 'Working Hours/Total Working Hours', field: "ratio" },
        { title: 'Hrs', field: 'totalWorkingHours' },
        { title: 'Pay Rate', field: 'payRate' },
        { title: 'Gross', field: 'grossSalary' },
        { title: ' Fixed Pay', field: 'fixedPay' },
        { title: 'Bonus', field: 'bonus' },
        { title: 'Cummulative Bonus', field: 'cumulativeBonus' },
        { title: 'Pay Bonus', field: 'paybonus' },
        { title: 'Expenses', field: 'expenseAmount' },
        { title: 'Deductions', field: 'deductionAmount' },
        { title: 'Cummulative Deduction', field: 'cumulativeDeduction' },
        { title: 'Insurance', field: 'insurance' },
        { title: 'Total Pay', field: 'netPay' },
        { title: 'Deal Pending', field: 'dealpending',
         },
        {
            title: "Actions", field: "actions",
            render: (rowData) => (
                <div className="d-flex" >
                    {
                        state.access_modules.includes('accounts-manager') || state.access_modules.includes('console-customization')
                            ?
                            !listAll ?
                                <div >
                                    <PayrollSettings id={id} companyID={companyID} />
                                </div> : null : null
                    }
                </div>
            ),
        }
    ]

    let data = []
    Payrolls && Payrolls.forEach((payrolls) => {
        data.push({
            OTpayRate:payrolls.OTpayRate,
            totalWorkingHours:payrolls.totalWorkingHours,
            payRate:payrolls.payRate,
            netPay:payrolls.netPay,
            isExist:payrolls.isExist,
            insurance:payrolls.insurance,
            id:payrolls.id,
            grossSalary:payrolls.grossSalary,
            expenseAmount:payrolls.expenseAmount,
            cumulativeDeduction:payrolls.cumulativeDeduction,
            cumulativeBonus:payrolls.cumulativeBonus,
            bonus:payrolls.bonus,
            createdAt:payrolls.createdAt,
            dealPending:payrolls.dealPending,
            deductionAmount:payrolls.deductionAmount,
            fixedPay:0,
            payBonus: 0  ,
            ratio:1

            
        })
    })
    let updatedColumns = columns.filter(column => column.title !== '')

    return (
        <div className="col-lg m-1"
        >

            {
                state.access_modules.includes('accounts-manager') || state.access_modules.includes('console-customization')
                    ?
                    !listAll ?
                        <div style={{ marginLeft: "95%" }} >

                            <PayrollSettings id={id} companyID={companyID} email={email} />
                        </div> : null : null
            }

            <MaterialTable
                title=""
                columns={listAll ? columns : updatedColumns}
                data={data}
                options={{
                    exportButton: true,
                    paginationType: "stepped",
                    pageSize: 20, 
                        
                     
                }}
            />
        </div>
    );
}








export default Presentation
