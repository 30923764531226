import React, { useEffect } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  addDays,
  closestTo,
  getDaysInMonth,
  getWeek,
  isWithinInterval,
  differenceInDays
} from "date-fns";
import { useState } from "react";
import { DateRange } from "react-date-range";

function RangePicker(props) {
  let {
    handleChange,
    cycle,
    startDay,
    placementStartDate,
    handleTableChange,
    projectEndDate,
  } = props;

  const dayIndex = getDayIndex(startDay);
  const [dateState, setState] = React.useState({
    selection1: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection1",
    },
  });

  const rangeSelector = (currDate) => {
    const currDateIndex = currDate.getDay();
    // const cycle = [
    //   {
    //     date: placementStartDate,
    //     range: 1,
    //     startDay: 'Thursday'
    //   },
    //   {
    //     date: '2020-06-27T20:03:28.322Z',
    //     range: 4,
    //     startDay: 'Friday'
    //   }
    // ]
    let customStart, customEnd, index = 0, variableSetRange;
    try {
      index = selectOneCycle(cycle, new Date(currDate).setHours(0, 0, 0, 0))
      console.log(index)
    } catch (error) {
      console.log(error)
      index = 0
    }
    // if(range === 0){
    //   variableSetRange = setRangeForDaily()
    // }
    [customStart, customEnd] = filterRangeBy(
      setRange(new Date(currDate).setHours(0, 0, 0, 0), cycle[index].range, cycle[index].startDay, cycle, index),
      [new Date(placementStartDate).setHours(0, 0, 0, 0), new Date(projectEndDate).setHours(0, 0, 0, 0)]
    );
    console.log("case6-1", customStart, customEnd)
    setState({
      selection1: {
        startDate: new Date(customStart),
        endDate: new Date(customEnd),
        key: "selection1",
      },
    });
    console.log("case6-2")

    handleTableChange(
      new Date(customStart).setHours(0, 0, 0, 0),
      new Date(customEnd).setHours(0, 0, 0, 0)
    );
    console.log("case6-3")
  };

  const handleDateChange = (currDate) => {
    rangeSelector(currDate);
  };

  useEffect(() => {
    // rangeSelector(new Date());
    // return () => {
    //   cleanup
    // }
  }, [startDay]);

  return (
    <DateRange
      className="shadow"
      editableDateInputs={false}
      minDate={
        placementStartDate !== "" ? new Date(placementStartDate) : new Date()
      }
      maxDate={
        new Date(projectEndDate !== "" ? new Date(projectEndDate) : new Date())
      }
      onChange={(item) => {
        // handleChange("date", item)
        setState({ ...dateState, ...item });
        handleDateChange(new Date(item.selection1.startDate));
      }}
      ranges={[dateState.selection1]}
    />
  );
}

export default RangePicker;

function selectOneCycle(cycle, selectedDate) {
  let index = null;
  for (let i = 0; i < cycle.length - 1; i++) {
    // console.log(new Date(selectedDate).setHours(0, 0, 0, 0), new Date(cycle[i].date).setHours(0, 0, 0, 0), new Date(cycle[i + 1].date).setHours(0, 0, 0, 0))
    const inRange = isWithinInterval(new Date(selectedDate).setHours(0, 0, 0, 0), {
      start: new Date(cycle[i].date).setHours(0, 0, 0, 0),
      end: new Date(cycle[i + 1].date).setHours(0, 0, 0, 0),
    });
    if (inRange) {
      index = i;
      break;
    }
  }
  // if no index is found it indicates that selected date might be greater than all of the dates in the array
  if(index === null)
    index = cycle.length - 1
  return index
}

function setRange(selectedDate, range, startDay, cycle, index) {
  // const days = getDaysInMonth(selectedDate)
  console.log(arguments)
  const days = getDaysRange(range);
  let customStart = "";
  let customEnd = "";
  const daysRange = getWeek(selectedDate, [
    { weekStartOn: new Date(selectedDate).getDay() },
  ]);
  //  getting the start day index
  const startDayIndex = getDayIndex(startDay);
  // getting the selected date index
  const selectedDateIndex = new Date(selectedDate).getDay();
  // console.log(startDayIndex, selectedDateIndex)
  // To get the date ranges for calendar month
  if([3, 4].includes(range)){ 
    console.log("case1-1")
    const [monthStart, monthEnd] = exceptionalRange(range, selectedDate)
    console.log("case1-2")
    const [start, end] = [...checkCycleChange(monthStart, monthEnd, cycle, index, selectedDate )]
    console.log(start, end)
    return [start, end]
  }
  else if (selectedDateIndex >= startDayIndex) {
    console.log("case2")
    customStart = addDays(selectedDate, -(selectedDateIndex - startDayIndex));
    console.log("case2-1")
  } else if (selectedDateIndex < startDayIndex) {
    console.log("case3")
    customStart = addDays(
      selectedDate,
      -(days + 1 - (startDayIndex - selectedDateIndex))
    );
  }
  console.log("case4-1")
  customEnd = addDays(customStart, days);
  console.log("case4-2")
  let [start, end] = [...checkCycleChange(customStart, customEnd, cycle, index, selectedDate )]
  console.log("case4-3")
  return [start, end];
}

function checkCycleChange(customStart, customEnd, cycle, index, selectedDate){
  let cycleDate
  // console.log(customStart, customEnd, cycle[index + 1])
  try {
    cycleDate = new Date(cycle[index+1].date)
  } catch (error) {
    cycleDate = new Date(cycle[index].date)
  }
  // 1. If the end date of the range crosses the next cycle date then the end date should be limited up to next cycle date.
  // 2. If the selected date is after the end date then the dates should be selected from day after end date and should be selected upto cycle range
  console.log(customStart, customEnd)
  if(new Date(customEnd).setHours(0, 0, 0, 0) > new Date(cycleDate).setHours(0, 0, 0, 0) && new Date(customStart).setHours(0, 0, 0, 0) < new Date(cycleDate).setHours(0, 0, 0, 0)){
    console.log("1st statement")
    customEnd = addDays(new Date(cycleDate).setHours(0, 0, 0, 0), -2)
  }
  else if(new Date(customEnd) < new Date(selectedDate)){
    console.log("2nd statement")
    return checkCycleChange(addDays(customEnd, 1), addDays(customEnd, getDaysRange(cycle[index].range)), cycle, index, selectedDate )
  }
  console.log(customStart, customEnd)  
  return [customStart, customEnd]   
}

function filterRangeBy(dateRange, placementBoundaryDates) {
  // 1. Remove the dates which are before placement starting.
  // 2. Remove the dates which are after placement ending.
  console.log("case5-1")
  let [customStart, customEnd] = [...dateRange];
  const [placementStartDate, projectEndDate] = [...placementBoundaryDates];
  if (new Date(customStart) < new Date(placementStartDate))
    customStart = placementStartDate;
  if (new Date(customEnd) > new Date(projectEndDate))
    customEnd = projectEndDate;

  // 3.
  console.log("case5-2")
  return [customStart, customEnd];
}

function exceptionalRange(range, selectedDate){
  
  const year = new Date(selectedDate).getFullYear()
  const month = new Date(selectedDate).getMonth()
  const days = getDaysInMonth(year, month)
  const selectedDay = new Date(selectedDate).getDate()
  if(range === 3){
    // semi-monthly
    return selectedDay <= 15 ? [new Date(year, month, 1), new Date(year, month, 15)] : [new Date(year, month, 16), new Date(year, month, days)]
  }
  else if(range === 4){
    // monthly 
    const daysToBeAdded = days - selectedDay
    console.log(daysToBeAdded)
    return [new Date(year, month, 1), new Date(year, month, days)]
  }
}

function getDayIndex(day) {
  switch (day) {
    case "Sunday":
      return 0;
    case "Monday":
      return 1;
    case "Tuesday":
      return 2;
    case "Wednesday":
      return 3;
    case "Thursday":
      return 4;
    case "Friday":
      return 5;
    case "Saturday":
      return 6;
    default:
      return null;
  }
}

function getDaysRange(range) {
  switch (range) {
    case 0:
      return 0;
    case 1:
      return 6;
    case 2:
      return 13;
    case 3:
      return 14;
    case 4:
      return 29;
    default:
      return 0;
  }
}


function setRangeForDaily(selectedDate, startDay, cycle, index){
  return new Date(selectedDate)
}

function setRangeForWeekly(selectedDate, startDay, range, cycle, index){
  let customStart, customEnd;
  //  getting the start day index
  const startDayIndex = getDayIndex(startDay);
  const days = getDaysRange(range);
  // getting the selected date index
  const selectedDateIndex = new Date(selectedDate).getDay();
  if (selectedDateIndex >= startDayIndex) {
    customStart = addDays(selectedDate, -(selectedDateIndex - startDayIndex));
  } else if (selectedDateIndex < startDayIndex) {
    customStart = addDays(
      selectedDate,
      -(days + 1 - (startDayIndex - selectedDateIndex))
    );
  }

  customEnd = addDays(customStart, days);

}