import React, { useContext } from 'react';
import {
    Grid,
    TextField,
    makeStyles,
    Button
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataContext } from '../../../../../../contexts/data';
import Validations from '../../../../../generalcomponents/validation'

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}

const Presentation = (props) => {
    const { states, handleCountryAndState, geoState, locationsline1, locationsline2, locationscountry, locationsstate_name, locationscity, locationszip, locations, handleChange, handleSave } = props
    const [state] = useContext(DataContext)
    const classes = useStyles()
    let countryList = state.hasOwnProperty('geo_data') ? state.geo_data.countries : []
    const validate = new Validations()
    return (
        <div className="custom-card mb-4">
            <form onSubmit={(e) => handleSave(e)}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <TextField
                            label='Address Line 1'
                            name='locationsline1'
                            value={locationsline1}
                            variant='outlined'
                            size='small'
                            required
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label='Address Line 2'
                            name='locationsline2'
                            value={locationsline2}
                            variant='outlined'
                            size='small'
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label='City'
                            name='locationscity'
                            value={locationscity}
                            variant='outlined'
                            size='small'
                            required
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            id="country-select-demo"
                            fullWidth
                            options={countryList}
                            classes={{
                                option: classes.option,
                            }}

                            //inputValue={country ? country : null}
                            onChange={(event, value) => {
                                if (value) {
                                    handleCountryAndState('locationscountry', value)
                                    return geoState(value.iso2)
                                }

                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <span>{countryToFlag(option.iso2)}</span>
                                    {option.name} ({option.iso2}) +{option.phone_code}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Country *"
                                    variant="outlined"
                                    size="small"
                                    
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <p className="text-success" >Selected country:{locationscountry} </p>
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            id="country-select-demo"
                            fullWidth
                            options={states}

                            classes={{
                                option: classes.option,
                            }}
                            onChange={(event, value) => {
                                if (value) {
                                    handleCountryAndState('locationsstate_name', value)
                                    return geoState(value.iso2)
                                }

                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderOption={(option) => (
                                <React.Fragment>
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="State *"
                                    variant="outlined"
                                    size="small"
                                    
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <p className="text-success" >Selected state:{locationsstate_name} </p>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label='Zip code'
                            name='locationszip'
                            value={locationszip}
                            helperText={locationszip.length ? validate.checkZip(locationszip) ? '' : "Enter valid zip code" : ""}
                            variant='outlined'
                            size='small'
                            required
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <br />
                <div className="text-center">

                    {(locationsline1.trim() && locationscountry.trim() && locationscity.trim() && locationsstate_name.trim() && validate.checkZip(locationszip)) ?
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Done
                    </Button> :
                        <Button
                            variant="contained"
                            color="primary"
                            disabled
                        >
                            Done
                    </Button>}
                </div>
            </form>
        </div>
    );
}

export default Presentation;