import React, { Component } from 'react'
import Presentation from './Presentation'
export class Container extends Component {
    
    render() {
        return (
            <div>
                <Presentation 
                    {...this.props}
                    info={{
                        placementID: this.props.placementID,
                        empCode: this.props.empCode,
                        clientId: this.props.clientId,
                        invoiceBy: this.props.invoiceBy
                    }}
                />
            </div>
        )
    }
}

export default Container
