

import React, { PureComponent } from 'react'
import MyRequests from '../components/Templates/MyRequests/Index'

export class MyRequestsPage extends PureComponent {
    render() {
        return (
            <div>
                    <MyRequests myReq={false} />
            </div>
        )
    }
}

export default MyRequestsPage
