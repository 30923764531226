import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Fab, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { GoFileSymlinkFile } from 'react-icons/go';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  new: {
    float: 'right'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Presentation(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { handleChange, client, address, phone, mailId, progress1, handleChange2, req_logo, eVerifyNo, einNo, handleSubmit, accountsMailId, hrMailId, watermark_image, payableTo, prefix, seperator, incrementValue,autoSend,autoGenerate} = props

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className={classes.new}>
        <Tooltip title="Edit">
          <Fab onClick={handleClickOpen} color="primary" size="medium">
            <Edit />
          </Fab>
        </Tooltip>
      </div>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Edit Details
            </Typography>
            <React.Fragment>
              <Typography variant="h6" gutterBottom>

              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      id="client"
                      label="Client"
                      required
                      variant="outlined"
                      size="small"
                      name="client"
                      value={client}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      id="eVerifyNo"
                      label="E-VerifyNumber"
                      required
                      variant="outlined"
                      size="small"
                      name="eVerifyNo"
                      value={eVerifyNo}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      id="einNo"
                      label="EIN-Number"
                      required
                      variant="outlined"
                      size="small"
                      name="einNo"
                      value={einNo}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      id="address"
                      label="Address"
                      required
                      variant="outlined"
                      size="small"
                      name="address"
                      value={address}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      id="phone"
                      label="Phone"
                      required
                      variant="outlined"
                      size="small"
                      name="phone"
                      value={phone}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      id="mailId"
                      label="Mail-Id"
                      required
                      variant="outlined"
                      size="small"
                      name="mailId"
                      value={mailId}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      id="hrMailId"
                      label="HRMail-ID"
                      required
                      variant="outlined"
                      size="small"
                      name="hrMailId"
                      value={hrMailId}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      id="accountsMailId"
                      label="AccountsMail-Id"
                      required
                      variant="outlined"
                      size="small"
                      name="accountsMailId"
                      value={accountsMailId}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <FileUploader /> */}
                    <TextField
                      label="Logo"
                      type="file"
                      name="req_logo"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleChange2(e, "req_logo")}
                    />
                    <p>{req_logo !== "" ? <a target="_blank" rel="noopener noreferrer" href={req_logo} ><GoFileSymlinkFile size={22} /></a> : <p>No file choosen</p>} </p>
                    {

                      progress1 < 100 ? <p>Uploading please wait...</p> : ""
                    }
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <FileUploader /> */}
                    <TextField
                      label="Watermark image"
                      type="file"
                      name="watermark_image"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleChange2(e, "watermark_image")}
                    />
                    <p>{watermark_image !== "" ? <a target="_blank" rel="noopener noreferrer" href={watermark_image} ><GoFileSymlinkFile size={22} /></a> : <p>No file choosen</p>} </p>
                    {

                      progress1 < 100 ? <p>Uploading please wait...</p> : ""
                    }
                  </Grid>
                  <Grid item xs={12} sm={6}  >
                    <TextField
                      id="payableTo"
                      label="Payable To"
                      required
                      variant="outlined"
                      size="small"
                      name="payableTo"
                      value={payableTo}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} >
                    <TextField
                      id="Invoice Prefix"
                      label="Invoice Prefix"
                      required
                      variant="outlined"
                      size="small"
                      name="prefix"
                      value={prefix}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      id="seperator"
                      label="Seperator"
                      required
                      variant="outlined"
                      size="small"
                      name="seperator"
                      value={seperator}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      id="incrementValue"
                      label="Begin From"
                      required
                      variant="outlined"
                      size="small"
                      name="incrementValue"
                      value={incrementValue}
                      onChange={handleChange}
                    />
                  </Grid>
                 
                    <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                        <FormControlLabel
                        value="autoSend"
                        control={<Switch  name="autoSend" checked={autoSend} onChange={handleChange} />}
                        label="Auto-Send"
                        labelPlacement="start"
                      />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormControlLabel
                       value="autoGenerate"
                       control={<Switch color="secondary" name="autoGenerate" checked={autoGenerate} onChange={handleChange}/>}
                       label="Auto-generate"
                       labelPlacement="start"
                       
                      />
                       </FormGroup>
                    </FormControl>
                  
                  <Grid>

                  </Grid>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(false)}
                    className={classes.submit}
                  >
                    Submit
                    </Button>
                </Grid>
              </form>
            </React.Fragment>
          </Paper>
        </main>


      </Dialog>
    </div >
  );
}
