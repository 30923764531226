import React, { Component } from 'react'
import Presentation from './Presentation'
import { storage } from '../../../firebase_config'
import { DataContext } from '../../../contexts/data'
import { UPDATE_COMPANY_CONFIG } from '../../../contexts/types'


class Container extends Component {
    state = {
        client: "",
        address: "",
        phone: "",
        mailId: "",
        hrMailId: "",
        accountsMailId: "",
        req_logo: "",
        eVerifyNo: "",
        einNo: "",
        PDF_footer: "",
        PDF_header: "",
        watermark_image: "",
        payableTo: "",
        incrementValue: "",
        prefix: "",
        seperator: "",
        autoSend: "",
        autoGenerate: ""
    }

    static contextType = DataContext

    componentDidMount = () => {
        const [state] = this.context
        console.log(state.company_config)
        this.setState({
            client: state.company_config.companyname,
            address: state.company_config.contactdetails.address,
            phone: state.company_config.contactdetails.phoneno,
            mailId: state.company_config.contactdetails.mailId,
            accountsMailId: state.company_config.contactdetails.accountsMailId,
            hrMailId: state.company_config.contactdetails.hrMailId,
            req_logo: state.company_config.images.companylogo,
            PDF_footer: state.company_config.images.PDF_footer,
            PDF_header: state.company_config.images.PDF_header,
            eVerifyNo: state.company_config.contactdetails.eVerifyNo,
            einNo: state.company_config.contactdetails.einNo,
            watermark_image: state.company_config.images.watermark,
            incrementValue: state.company_config.invoiceDetails.invoiceNumberFormat.beginFrom,
            prefix: state.company_config.invoiceDetails.invoiceNumberFormat.invoicePrefix,
            seperator: state.company_config.invoiceDetails.invoiceNumberFormat.seperator,
            autoSend:state.company_config.invoiceDetails.invoiceAutoSend,
            autoGenerate:state.company_config.invoiceDetails.invoiceAutoGenerate,
            payableTo:state.company_config.invoiceDetails.payableTo
        })
    }
   

    handleChange = (e) => {
        if (e.target.name === "autoSend" || e.target.name === "autoGenerate")
            this.setState({
                [e.target.name]: e.target.checked
            })

        else
            this.setState({
                [e.target.name]: e.target.value
            })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { client, address, phone, mailId, req_logo, eVerifyNo, einNo, PDF_footer, PDF_header, hrMailId, accountsMailId, seperator, incrementValue, prefix,autoSend,autoGenerate ,payableTo} = this.state
        const dispatch = this.context[1]
console.log(this.state)
        dispatch({
            type: UPDATE_COMPANY_CONFIG,
            payload: {
                companyname: client,
                contactdetails: {
                    address: address,
                    eVerifyNo: eVerifyNo,
                    einNo: einNo,
                    mailId: mailId,
                    phoneno: phone,
                    hrMailId: hrMailId,
                    accountsMailId: accountsMailId
                },
                images: {
                    companylogo: req_logo,
                    PDF_footer: PDF_footer,
                    PDF_header: PDF_header
                },
               invoiceDetails :{
                invoiceNumberFormat: {
                    seperator: seperator,
                    beginFrom: incrementValue,
                    invoicePrefix: prefix
                },
                invoiceAutoGenerate:autoGenerate ,
            invoiceAutoSend: autoSend ,
            payableTo:payableTo
               }
            }
        })
    }

    handleChange2 = (e, name) => {
        console.log(e.target.files[0])
        if (e.target.files[0]) {
            const file = e.target.files[0];
            this.setState(() => ({ file }));
            this.fileUpload1(file, name)
        }
    }

    fileUpload1 = (file, name) => {
        var size = 10000000
        console.log(file.type)
        if (file.size > size) {
            alert("File size should be less than 10MB")
            this.setState({ file: null })
        }
        else {
            const uploadTask = storage.ref(`companycustoms/${file.name}`).put(file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    console.log(uploadTask)
                    let upload = false
                    const progress1 = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.setState({ progress1 });
                    console.log(progress1)
                    // this.setState({upload:false})
                    console.log(upload)

                },
                (error) => {
                    // error function ....
                    console.log(error);
                },
                () => {
                    // complete function ....
                    storage.ref('companycustoms').child(file.name).getDownloadURL().then(url => {
                        console.log(url);
                        if (name === "req_logo") {
                            this.setState({ req_logo: url });
                        } else {
                            this.setState({ watermark_image: url });
                        }
                    })
                });
        }
    }


    clearValues = () => {
        this.setState({
            client: "",
            web_url: "",
            address: "",
            phone: "",
            mailId: ""
        })
    }

    componentWillUnmount = () => {
        this.clearValues()
    }


    render() {
        return (
            <div>
                <Presentation
                    {...this.state}
                    handleChange={this.handleChange}
                    handleChange2={this.handleChange2}
                    handleSubmit={this.handleSubmit}
                />
            </div>
        )
    }
}

export default Container
