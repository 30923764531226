import React, { useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import DateFnsUtils from '@date-io/date-fns'
import SunEditor from 'suneditor-react'
import Checkbox from '@material-ui/core/Checkbox'
import { DataContext } from '../../../../../contexts/data'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Validation from '../../../../generalcomponents/validation'
import { addDays } from 'date-fns'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'linear-gradient(45deg, #280071 10%, #c42053 90%)',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  layout: {
    padding: theme.spacing(8),
  },
  paper: {
    padding: theme.spacing(2),
  },
  submit: {},
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
  text: {
    flexGrow: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function Presentation(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [selectedStartDate, setSelectedStartDate] = React.useState('')
  const [selectedEndDate, setSelectedEndDate] = React.useState('')
  const {
    text,
    handleCreate,
    handleChange,
    handleDateChange,
    handleDropdown,
    isCreated,
    errCount,
    clearValues,
    reminderDate,
    setReminder,
    status,
  } = props
  const [state] = useContext(DataContext)
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date)
    handleDateChange('startdate', date)
  }

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date)
    handleDateChange('enddate', date)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedStartDate('')
    setSelectedEndDate('')
    clearValues()
  }
  useEffect(() => {
    if (isCreated) setOpen(false)
  }, [isCreated])
  let labels = state.projectList.filter(
    (project) => project.id === state.project.id
  )[0].labels
  const projectAssignee = state.projectList
    .filter((project) => project.id === state.project.id)[0]
    .Users.map((user) => user.uid)
  const validate = new Validation()
  const checkValidTaskRange = (startdate, enddate) => {
    const initialDateDiff =
      new Date(startdate) - new Date(state.project.startdate)
    const finalDateDiff = new Date(state.project.enddate) - new Date(enddate)
    return initialDateDiff >= 0 && finalDateDiff >= 0
  }
  return (
    <div>
      <Button
        variant='contained'
        className='flairbtn'
        onClick={handleClickOpen}
      >
        {text}
      </Button>
      <Dialog
        fullScreen
        disableEscapeKeyDown
        disableBackdropClick
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <div className='d-flex justify-content-between p-2'>
            <div>
              <IconButton
                color='inherit'
                onClick={handleClose}
                aria-label='close'
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <Typography component='h1' variant='h4' align='center'>
                {text}
              </Typography>
            </div>
            <div></div>
          </div>
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <React.Fragment>
              <Typography variant='h6' gutterBottom></Typography>
              <form
                onSubmit={(e) => {
                  handleCreate(e)
                  handleClose()
                  setSelectedStartDate('')
                  setSelectedEndDate('')
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      required='required'
                      id='taskname'
                      name='name'
                      label='Name'
                      onChange={(e) => handleChange('name', e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor='grouped-select'>
                        Task type
                      </InputLabel>
                      <Select
                        onChange={(e, v) =>
                          handleDropdown('type', v.props.value)
                        }
                        input={<Input id='grouped-select' />}
                      >
                        <MenuItem value=''>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value='Task'>Task</MenuItem>
                        <MenuItem value='Bug'>Bug</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor='grouped-select'>Status</InputLabel>
                      <Select
                        value={status}
                        onChange={(e, v) =>
                          handleDropdown('status', v.props.value)
                        }
                        defaultValue=''
                        input={<Input id='grouped-select' />}
                      >
                        <MenuItem value=''>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value='Open'>Open</MenuItem>
                        <MenuItem value='InProgress'>InProgress</MenuItem>
                        <MenuItem value='Review'>Review</MenuItem>
                        <MenuItem value='Closed'>Closed</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* {
                      !setReminder ?  */}
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <KeyboardDatePicker
                            required='required'
                            style={{ marginTop: '-2px' }}
                            fullWidth
                            minDate={state.project.startdate}
                            maxDate={state.project.enddate}
                            id='date-picker-startdate'
                            label='Start date'
                            format='MM/dd/yyyy'
                            value={selectedStartDate ? selectedStartDate : null}
                            onChange={handleStartDateChange}
                            name='startdate'
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <KeyboardDatePicker
                            style={{ marginTop: '-2px' }}
                            fullWidth
                            maxDate={state.project.enddate}
                            minDate={selectedStartDate}
                            value={selectedEndDate ? selectedEndDate : null}
                            id='date-picker-enddate'
                            label='End date'
                            format='MM/dd/yyyy'
                            onChange={handleEndDateChange}
                            name='enddate'
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  {/* :
                      null
                    } */}

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor='grouped-select'>Priority</InputLabel>
                      <Select
                        onChange={(e, v) =>
                          handleDropdown('priority', v.props.value)
                        }
                        defaultValue=''
                        input={<Input id='grouped-select' />}
                      >
                        <MenuItem value=''>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value='Urgent'>Urgent</MenuItem>
                        <MenuItem value='High'>High</MenuItem>
                        <MenuItem value='Medium'>Medium</MenuItem>
                        <MenuItem value='Low'>Low</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      multiple
                      required='required'
                      id='tags-outlined'
                      options={projectAssignee}
                      getOptionLabel={(option) => validate.emailToName(option)}
                      filterSelectedOptions
                      onChange={(e, v) => handleDropdown('assignee', v)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          label='Add employees'
                        />
                      )}
                    />
                  </Grid>
                  {state.project.useLabels ? (
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        required='required'
                        id='tags-outlined'
                        options={labels}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span
                              className={classes.color}
                              style={{ backgroundColor: option.colorCode }}
                            />
                            <div className={classes.text}>{option.name}</div>
                          </React.Fragment>
                        )}
                        filterSelectedOptions
                        onChange={(e, v) =>
                          handleDropdown(
                            'labels',
                            v.map((item) => item.id)
                          )
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} label='Labels' />
                        )}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) =>
                                handleChange('setReminder', e.target.checked)
                              }
                            />
                          }
                          label='Set reminder'
                        />
                      </Grid>
                      {setReminder ? (
                        <Grid item xs={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              required
                              style={{ marginTop: '-2px' }}
                              fullWidth
                              id='date-picker-startdate'
                              label='Reminder date'
                              format='MM/dd/yyyy'
                              minDate={addDays(new Date(), 1)}
                              helperText={
                                isNaN(Date.parse(reminderDate))
                                  ? 'Select reminder date'
                                  : ''
                              }
                              value={reminderDate ? reminderDate : null}
                              onChange={(date) =>
                                handleChange('reminderDate', date)
                              }
                              name='reminderDate'
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <SunEditor
                      placeholder='Description'
                      onChange={(data) => handleChange('description', data)}
                      setOptions={{
                        buttonList: [
                          [
                            'undo',
                            'redo',
                            'formatBlock',
                            'bold',
                            'underline',
                            'italic',
                            'strike',
                            'subscript',
                            'superscript',
                            'fontColor',
                            'hiliteColor',
                            'removeFormat',
                            'outdent',
                            'indent',
                            'align',
                            'horizontalRule',
                            'list',
                            'lineHeight',
                            'table',
                            'link',
                          ],
                        ],
                        mode: 'Balloon-always',
                      }}
                    />
                  </Grid>
                </Grid>
                <br />
                {console.log(
                  validate.checkDateDiff(selectedStartDate, selectedEndDate)
                )}
                <div className='text-center'>
                  {errCount === 0 &&
                  validate.checkDateDiff(selectedStartDate, selectedEndDate) &&
                  checkValidTaskRange(selectedStartDate, selectedEndDate) ? (
                    <div className='text-center'>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                      >
                        Create
                      </Button>
                    </div>
                  ) : (
                    <div className='text-center'>
                      <Button
                        type='button'
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                        disabled
                      >
                        Create
                      </Button>
                    </div>
                  )}
                </div>
              </form>
            </React.Fragment>
          </Paper>
        </main>
      </Dialog>
    </div>
  )
}
