import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import View from '../View'
import { Grid } from '@material-ui/core'
import { DataContext } from '../../../contexts/data'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'visible',
  },
  grid: {
    padding: theme.spacing(10),
  },
}))

export default function Presentation(props) {
  const classes = useStyles()
  const {
    handleChange,
    value,
    Categories,
    CategorieNames,
    uniqCategorieNames,
    archived,
  } = props
  const [state] = useContext(DataContext)
  const getNameById = (id) => {
    console.log(id)
    for (let index = 0; index < state.wikiCategories.length; index++) {
      if (state.wikiCategories[index].id === id)
        return state.wikiCategories[index].name
    }
    return id
  }
  return (
    <div className={classes.root}>
      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        className={classes.tabs}
      >
        {CategorieNames.map((tab) => (
          <Tab
            label={getNameById(tab)}
            {...a11yProps(CategorieNames.indexOf(tab))}
          />
        ))}
      </Tabs>
      <Grid container xs={12} sm={12}>
        <Grid item xs={12} sm={10}>
          <TabPanel
            value={value}
            index={CategorieNames.indexOf(CategorieNames[value])}
          >
            <View
              article={
                Categories[uniqCategorieNames.indexOf(CategorieNames[value])]
              }
            />
          </TabPanel>
        </Grid>
        <Grid item xs={12} sm={2}></Grid>
      </Grid>
    </div>
  )
}
