import React from 'react'
import Console from '../components/Console/Index'
function LetterTemplatePage() {
    return (
        <div>
            <Console />
        </div>
    )
}

export default LetterTemplatePage
