import React, { Component } from 'react';
import Presentation from "./Presentation";
import { DataContext } from "../../../../../contexts/data";
import { INVOICE_ACTIONS } from "../../../../../contexts/types";

class Container extends Component {

    state = {
        comments: ''
    }

    static contextType = DataContext

    handleVoid = (e, id) => {
        e.preventDefault();
        const dispatch = this.context[1]
        let payload = {
            isVoid: true,
            id: id,
            voidDetails: {
                comments: this.state.comments
            }
        }
        dispatch({
            type: INVOICE_ACTIONS,
            actionType: 'edit',
            invoiceCallback: this.invoiceCallback,
            payload: payload
        })
    }

    handleChange = (data) => {
        this.setState({
            comments: data
        })
    }

    invoiceCallback = () => {}

    render() {
        return (
            <div>
                <Presentation 
                    {...this.props}
                    {...this.state}
                    handleVoid={this.handleVoid}
                    handleChange={this.handleChange}
                />
            </div>
        );
    }
}

export default Container;