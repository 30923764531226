import React, { useContext, useState, useEffect } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import Validations from '../../../generalcomponents/validation'
import { Link } from 'react-router-dom'
import { DataContext } from '../../../../contexts/data'
import parser from 'html-react-parser'
import SweetAlert from 'react-bootstrap-sweetalert'
import SunEditor from 'suneditor-react'
import EditIcon from '@material-ui/icons/Edit'
import HighlightOff from '@material-ui/icons/HighlightOff'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import {
  Avatar,
  TablePagination,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  Chip,
  Badge,
  AppBar,
  Typography,
  Box,
  Button,
  Fab,
  ButtonGroup,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import ExpensesListTable from "./ExpensesListTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  }
}


function Presentation(props) {
  const { listAll, description, approveExpense, rejectExpense, handleChange, expenseSettings, employeeID } = props
  const [state] = useContext(DataContext)
  const [value, setValue] = React.useState(0)
  const validate = new Validations()
  const [alert, setAlert] = useState(null)
  const [type, setType] = useState('')
  const [id, setId] = useState('')

  const handleTab = (event, newValue) => {
    setValue(newValue)
  }

  const hideAlert = () => {
    setAlert(null)
  }

  useEffect(() => {
    if (alert !== null) {
      showAlert(type, id)
    }
  }, [description])


  const checkApprovalAccess = (placementID) => {
    if (state.employee.role === 'admin') return true
    try {
      return expenseSettings.filter((item) => {
        return item.placementID === placementID
      })[0].companyIDs.includes(state.employee.companyID)

    } catch (error) {
      console.log(error)
      return false
    }
  }


  const showAlert = (type, id) => {
    setAlert(
      <div>
        <SweetAlert
          closeOnClickOutside={false}
          allowEscape={false}
          warning
          showCancel={false}
          showConfirm={false}
          title='Are you sure you want to approve this expense?'
          onConfirm={() => {
            if (type === 'reject') rejectExpense(id)
            else approveExpense(id)
          }}
          onCancel={hideAlert}
        >
          <SunEditor
            hide={type !== 'reject'}
            placeholder={
              type === 'reject' ? 'Description*' : 'Description(optional)'
            }
            value={description}
            onChange={(data) => handleChange('description', data)}
            setOptions={{
              buttonList: [
                [
                  'undo',
                  'redo',
                  'formatBlock',
                  'bold',
                  'underline',
                  'italic',
                  'strike',
                  'subscript',
                  'superscript',
                  'fontColor',
                  'hiliteColor',
                  'removeFormat',
                  'outdent',
                  'indent',
                  'align',
                  'horizontalRule',
                  'list',
                  'lineHeight',
                  'table',
                  'link',
                ],
              ],
            }}
          />
          <br />
          <div>
            <div>
              <Button onClick={hideAlert} variant='contained' color='default'>
                cancel
                  </Button>
              {'  '}
              <Button
                onClick={() => {
                  setAlert(null)
                  type === 'reject'
                    ? rejectExpense(id)
                    : approveExpense(id)
                }}
                disabled={type === 'reject' ? description === '' : false}
                variant='contained'
                color='primary'
              >
                {type === 'reject' ? 'Reject' : 'Approve'}
              </Button>
            </div>
          </div>
        </SweetAlert>
      </div>
    )
  }
  const expenses = state.expenses_list

  console.log(employeeID)

  let filteredData = []
  if (listAll) filteredData = expenses
  else
    filteredData = expenses.filter(
      (item) =>
        item.empCode ===
        state.names.filter(
          (item) => item.uid === employeeID || item.companyID === employeeID
        )[0].companyID
    )

  let data = filteredData.map(item => {
    return {
      empCode: item.empCode,
      name: state.names.filter(name => name.companyID === item.empCode)[0].name,
      imageURL: validate.getImage(item.empCode),
      spentDate: validate.dateFormatter(item.spentDate),
      expenseType: item.expenseType,
      amount: "$" + item.amount,
      expenseDoc: item.expenseDoc,
      vendor: item.vendor,
      receipt: item.receipt,
      departureDate: item.hasOwnProperty("departureDate") ? validate.dateFormatter(item.departureDate) : "",
      returnDate: item.hasOwnProperty("returnDate") ? validate.dateFormatter(item.returnDate) : "",
      additionalDetails: parser(item.additionalDetails),
      id: item.id,
      placementID: item.placementID,
      isApproved: item.isApproved,
      isRejected: item.isRejected,
      comment: item.hasOwnProperty("rejectDetails") ? parser(item.rejectDetails.description) : "",
      attachmentDetails: item.attachmentDetails
    }
  })

  const columns = [
    {
      title: 'Employee',
      field: 'empCode',
      render: (rowData) => (
        <div className='d-flex'>
          <Avatar src={rowData.imageURL} />
          <div className='d-flex flex-column'>
            <span>
              {' '}
              <Link to={'/console/employeelist/' + rowData.empCode}>
                {rowData.empCode}
              </Link>{' '}
            </span>
            <span>{rowData.name} </span>
          </div>
        </div>
      ),
    },
    { title: 'Spent date', field: 'spentDate' },
    { title: 'Expense Type', field: 'expenseType' },
    { title: 'Amount', field: 'amount' },
    {
      title: 'Expense Doc', field: 'expenseDoc',
      render: (rowData) => (
        <div>
          {
            rowData.attachmentDetails.publicURL !== "" ? <a rel='noopener noreferrer' target="_blank" href={rowData.attachmentDetails.publicURL} > {rowData.attachmentDetails.sourcePath.slice(rowData.attachmentDetails.sourcePath.lastIndexOf("/") + 1)} </a> : "---"
          }
        </div>
      )
    },
    { title: 'Vendor', field: 'vendor' },
    { title: 'Receipt', field: 'receipt' },
    { title: 'Departure Date', field: 'departureDate' },
    { title: 'Return Date', field: 'returnDate' },
    { title: 'Additional Details', field: 'additionalDetails' },
    { title: 'Comment', field: 'comment' },
    {
      title: 'Actions',
      field: 'actions',
      render: (rowData) => {
        if (rowData.isApproved)
          return (
            <div>
              {checkApprovalAccess(rowData.placementID) ? (
                <IconButton
                  onClick={() => {
                    setType('reject')
                    setId(rowData.id)
                    //   setPlacementDocID(rowData.placementDocID)
                    showAlert('reject', rowData.id)
                  }}
                  color='secondary'
                >
                  <HighlightOff />
                </IconButton>
              ) : null}
            </div>
          )
        else if (rowData.isRejected)
          return (
            <div>
              {rowData.empCode === state.employee.companyID ? (
                <Link
                  to={
                    '/console/expenses/submission/' +
                    rowData.id +
                    '/edit'
                  }
                >
                  <IconButton color='default'>
                    <EditIcon />
                  </IconButton>
                </Link>
              ) : checkApprovalAccess(rowData.placementID) ? (
                <IconButton
                  onClick={() =>
                    showAlert('approve', rowData.id)
                  }
                  color='primary'
                >
                  <CheckCircleOutline />
                </IconButton>
              ) : null}
            </div>
          )
        else if (!rowData.isApproved && !rowData.isRejected)
          return (
            <div>
              {rowData.empCode === state.employee.companyID ? (
                <Link
                  to={
                    '/console/expenses/submission/' +
                    rowData.id +
                    '/edit'
                  }
                >
                  <IconButton color='default'>
                    <EditIcon />
                  </IconButton>
                </Link>
              ) : checkApprovalAccess(rowData.placementID) ? (
                <>
                  <IconButton
                    onClick={() =>
                      showAlert('approve', rowData.id)
                    }
                    color='primary'
                  >
                    <CheckCircleOutline />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setType('reject')
                      setId(rowData.id)
                      // setPlacementDocID(rowData.placementDocID)
                      showAlert('reject', rowData.id)
                    }}
                    color='secondary'
                  >
                    <HighlightOff />
                  </IconButton>
                </>
              ) : null}
            </div>
          )
      },
    },
  ]

  return (
    <div>
      <div>{alert}</div>
      <AppBar position='static' color='default'>
        <Tabs
          value={value}
          onChange={handleTab}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          <Tab
            label={
              <Badge
                color='primary'
                children='Submitted'
                badgeContent={
                  data.filter((item) => !item.isApproved && !item.isRejected)
                    .length
                }
              />
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Badge
                color='primary'
                children='Approved'
                badgeContent={data.filter((item) => item.isApproved).length}
              />
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Badge
                color='error'
                children='Rejected'
                badgeContent={data.filter((item) => item.isRejected).length}
              />
            }
            {...a11yProps(2)}
          />
          {(state.access_modules.includes('timesheets-manager') && listAll) ||
            (state.access_modules.includes('console-customization') &&
              listAll) ? (
              <Tab
                label={
                  <Badge
                    color='error'
                    children='My Expenses'
                    badgeContent={
                      data.filter(
                        (item) => item.empCode === state.employee.companyID
                      ).length
                    }
                  />
                }
                {...a11yProps(3)}
              />
            ) : null}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ExpensesListTable
          columns={columns}
          info={data.filter((item) => !item.isApproved && !item.isRejected)
          }
          handleChange={handleChange}
          rejectExpense={rejectExpense}
          approveExpense={approveExpense}
          description={description}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ExpensesListTable columns={columns} info={data.filter((item) => item.isApproved)}
          handleChange={handleChange}
          rejectExpense={rejectExpense}
          approveExpense={approveExpense}
          description={description} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ExpensesListTable columns={columns} info={data.filter((item) => item.isRejected)}
          handleChange={handleChange}
          rejectExpense={rejectExpense}
          approveExpense={approveExpense}
          description={description} />
      </TabPanel>
      {(state.access_modules.includes('timesheets-manager') && listAll) ||
        (state.access_modules.includes('console-customization') && listAll) ? (
          <TabPanel value={value} index={3}>
            <ExpensesListTable
              columns={columns}
              info={data.filter(
                (item) => item.empCode === state.employee.companyID
              )}
              handleChange={handleChange}
              rejectExpense={rejectExpense}
              approveExpense={approveExpense}
              description={description}
            />
          </TabPanel>
        ) : null}
    </div>
  )
}

export default Presentation


function CustomTable(props) {
  const { info, columns } = props
  return (
    <MaterialTable
      title=''
      data={info}
      columns={columns}
      options={{
        paginationType: 'stepped',
        pageSize: 10,
      }}
      components={{
        Toolbar: (props) => (
          <div>
            <MTableToolbar {...props} />
            <div
              style={{
                marginTop: '-38px',
                marginLeft: '8px',
                marginBottom: '10px',
              }}
            >
              <Tooltip title='New Expense'>
                <ButtonGroup
                  variant='contained'
                  color='primary'
                  aria-label='outlined primary button group'
                >
                  <Link to='/console/expenses/submission/expense/new'>
                    <Button color='primary' variant='contained'>
                      New Expense
                      </Button>
                  </Link>
                </ButtonGroup>
              </Tooltip>
            </div>
          </div>
        ),
      }}
    />
  )
}
