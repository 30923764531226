import React, { Component } from 'react';
import Presentation from "./Presentation";
import { DataContext } from '../../../../../../contexts/data'
import { CLIENT_CURD, SET_COUNTRY_API, Te, TEMPORARY_NEW_CLIENT_DATA } from '../../../../../../contexts/types'
import Axios from 'axios'
import { Redirect } from 'react-router-dom';

class Container extends Component {
    state = {
        locationsline1: '',
        locationsline2: '',
        locationscountry: '',
        locationsstate_name: '',
        locationscity: '',
        locationszip: '',
        weekendProfile: '',
        holidayProfile: '',
        states: [],
        locations: [],
        redirect: false
    }

    static contextType = DataContext

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
        console.log(event.target.value)
    }

    handleStore = () => {
        const [state, dispatch] = this.context
        const { locationsline1, locationsline2, locationscountry, locationsstate_name, locationscity, locationszip } = this.state
        let data = ('locations' in state.temporary_client_data) ? state.temporary_client_data.locations : this.state.locations
        let payload = {
            locationsline1,
            locationsline2,
            locationscountry,
            locationsstate_name,
            locationscity,
            locationszip,
            id: data.length + 1
        }
        data = [...data, payload]
        this.setState({
            locations: data
        })
        dispatch({
            type: TEMPORARY_NEW_CLIENT_DATA,
            payload: {
                key: 'locations',
                locations: data
            }
        })
        this.clearValues()
    }

    handleCreate = () => {
        const [state, dispatch] = this.context
        const businessInformation = state.temporary_client_data.businessInformation
        let contacts = state.temporary_client_data.contacts
        let locations = state.temporary_client_data.locations
        let payload = {
            businessName: businessInformation.businessName,
            contactNumber: businessInformation.contactNumber,
            website: businessInformation.website,
            jobTerminationNotice: businessInformation.jobTerminationNotice,
            category: businessInformation.category,
            businessDisplayName: businessInformation.businessDisplayName,
            email: businessInformation.email,
            federalId: businessInformation.federalId,
            netTerms: businessInformation.netTerms,
            fax: businessInformation.fax,
            permanentPlacement: businessInformation.permanentPlacement,
            invoiceLocation: businessInformation.invoiceLocation,
            contacts: contacts,
            locations: locations,
            comments: ''
        }
        dispatch({
            type: CLIENT_CURD,
            clientCURDCallback: this.clientCURDCallback,
            payload: payload,
            actionType: "create"
        })
    }

    clearValues = () => {
        this.setState({
            locationsline1: '',
            locationsline2: '',
            locationscountry: '',
            locationsstate_name: '',
            locationscity: '',
            locationszip: '',
        })
    }

    clientCURDCallback = () => {
        this.setState({ redirect: true })
    }

    componentDidMount = () => {
        const [state, dispatch] = this.context
        dispatch({
            type: SET_COUNTRY_API,
            payload: dispatch
        })
        const { locationsline1, locationsline2, locationscountry, locationsstate_name, locationscity, locationszip } = this.state
        if (!this.state.locations.length) {
            let data = this.state.locations
            let payload = {
                locationsline1,
                locationsline2,
                locationscountry,
                locationsstate_name,
                locationscity,
                locationszip,
                id: data.length + 1
            }
            data = [...data, payload]
            this.setState({
                locations: data
            })
        }
    }

    geoState = async (code) => {
        let states = await Axios.post("/loadstates", { countrycode: code })
        this.setState({ states: states.data })
        console.log(code)
    }

    handleCountryAndState = (name, props) => {
        this.setState({
            [name]: props.name
        })
    }

    handleSave = (e, name) => {
        const { handleChange } = this.props
        if (name === 'back') {
            handleChange(e, 1)
        }
        else if (name === 'finish') {
            this.handleCreate()
        }
        else this.handleStore()
        
    }
    render() {
        const { redirect } = this.state
        if (redirect)
            return <Redirect to={'/console/clientslist'} />
        return (
            <div>
                <Presentation
                    {...this.state}
                    handleChange={this.handleChange}
                    handleSave={this.handleSave}
                    geoState={this.geoState}
                    handleCountryAndState={this.handleCountryAndState}
                />
            </div>
        );
    }
}

export default Container;