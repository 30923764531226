import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
class ModalExample extends React.Component {
  
state={
  open:false,
  label : ""
}

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.props.handleNewLabelCancel()
  };


render(){
  const {  handleNewLabelChange, handleNewLabel, newLabelType, newLabelName, newLabelSection} = this.props;
  const toggle2=(e)=>{
    e.preventDefault()
    this.setState({open:!this.state.open})
    handleNewLabel()
  }

  return (
    <div>
        <Button 
      variant="contained"
      color="secondary"
      onClick={this.onOpenModal}>
        Create new label
      </Button>
      <Dialog
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        open={this.state.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">New label</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={toggle2} >
                    <FormControl style={{width:'100%'}} >
                        <InputLabel required > Select section</InputLabel>
                            <Select
                                fullWidth
                                label="Select section"
                                name="newLabelSection"
                                required
                                onChange={handleNewLabelChange}
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="personalinformation">Personal Information</MenuItem>
                                <MenuItem value="mailingaddress">Mailing address</MenuItem>
                                <MenuItem value="emergencycontact">Emergency contact</MenuItem>
                                <MenuItem value="employementhistory">Employement history</MenuItem>
                                <MenuItem value="workauthorization">Work authorization</MenuItem>
                            </Select>
                    </FormControl>
                    <br/><br/>
                    <TextField
                    label="Enter Name of the Label"
                    name="newLabelName"
                    fullWidth
                    required
                    variant="outlined"
                    size="small"
                    onChange={handleNewLabelChange}
                    />
                    <br/><br/>
                <FormControl required style={{width:'100%'}} >
                    <InputLabel required > Select Type of the Label</InputLabel>
                        <Select
                            fullWidth
                            label="Select Type of the Label"
                            name="newLabelType"
                            required
                            onChange={handleNewLabelChange}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="text">Text</MenuItem>
                            <MenuItem value="email">Email</MenuItem>
                            <MenuItem value="number">Number</MenuItem>
                            <MenuItem value="date">Date</MenuItem>
                            <MenuItem value="address">Address</MenuItem>
                        </Select>
                </FormControl>
                <br/><br/>
                {
                    newLabelSection.length && newLabelName.trim().length&&newLabelType.length ? 
                        <Button type="submit" variant="contained" color="primary">
                            Create
                        </Button>
                        :
                        <Button type="button" variant="contained" color="default" disabled>
                        Create
                        </Button>
                }
                
                {' '}
                <Button onClick={this.onCloseModal} type="button" variant="contained" color="default">
                  Cancel
                </Button>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
             
        </DialogActions>
      </Dialog>
    </div>
  );
  
}
};

export default ModalExample;
