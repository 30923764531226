import React, { Component } from 'react'
import Presentation from './Presentation'
import CustomSpinner from '../../../generalcomponents/circularSpinner'
import firebase from '../../../../firebase_config'
import { DataContext } from '../../../../contexts/data'
import { addDays } from 'date-fns';
import { EMPLOYEE_TIMESHEET_ACTIONS } from '../../../../contexts/types'
import Validations from '../../../generalcomponents/validation'
import { Redirect } from "react-router-dom"


export class Container extends Component {

    constructor(props){
        super(props)
        this.validate = new Validations()
    }

    //  here placementID refers to doc id
    state = {
        timeSheetArr : ['', '', '', '', '', '', ''],
        placements : [],
        isLoading : true,
        clientInfo : {},
        placementID : '',
        startDate:"",
        endDate : "",
        standardTimeArr : [],
        OTArr : [],
        timesheetDocURL : "",
        isUploading: false,
        attachMandatory:false,
        cycle: [{
            range: 0,
            date: new Date().toISOString(),
            startDay: "Sunday"
          }],
        cycleDates: [],
        startDay: "",
        placementStartDate:"",
        projectEndDate: "",
        clientId : '',
        pcID:'',
        redirect: false
    }

    static contextType = DataContext

    componentDidMount = () => {
        const [state] = this.context

        if(this.props.placementID !== "new" && this.props.timesheetId !== "timesheet"){
            this.grabPlacementData(this.props.placementID).then(() => {
                this.setState({ placementID:this.props.placementID, isLoading : false })
                this.grabTimesheetData(this.props.placementID, this.props.timesheetId)
            })
        }else{
            firebase.firestore().collection("Placements").where("empCode", "==", state.employee.companyID).where("draft", "==", false).get().then(snap => {
                const placementsSnap = snap.docs.map(doc => doc.data())
                this.setState({ placements : placementsSnap, isLoading : false })
            })
        }
    }

    employeeSubmitCallback = () => {
        this.setState({redirect: true})
    }

    handleChange = (key, value) => {
        this.setState({ 
            [key] : value
        })
        if(key === "placementID" && value.length){
            this.grabPlacementData(value)
        }else if(key === "placementID" && !value.length){
            this.setState({
                attachMandatory:false,
                cycle: [],
                startDay: ""
            })
        }

        //  for dates
        const { startDate, endDate } = this.state
    }


    grabPlacementData = (docId) => {
        const [state] = this.context
        return firebase.firestore().collection("Placements").doc(docId).collection("Timesheets").get().then(snap => {
            let settings = {}
                settings = snap.docs[0].data()
            this.setState({
                attachMandatory:settings.attachMandatory,
                cycle: settings.cycle,
                cycleDates: settings.cycleDates,
                startDay: settings.startDay
            })
            
            const placement = state.placements_list.filter(item => item.id === docId)[0]
            this.setState({
                placementStartDate: placement.startDate,
                projectEndDate: placement.endDate,
                clientId : placement.clientId,
                placements: [placement]
            })
        })

    }


    grabTimesheetData = (placementID, timesheetId) => {
        const [state] = this.context
        const data = state.timesheets_list.filter(item => item.id === timesheetId)[0]
        this.setState({
            startDate: data.startDate,
            endDate: data.endDate,
            standardTimeArr: data.workdetails.standardTime.map((item, index) => {
                return {
                    date: addDays(new Date(data.startDate), index).toISOString(),
                    value: item
                }
            }),
            OTArr: data.workdetails.OTtime.map((item, index) => {
                return {
                    date: addDays(new Date(data.startDate), index).toISOString(),
                    value: item
                }
            }),
        })
    }


    handleSubmit = () => {
        const { startDate, endDate, standardTimeArr, OTArr, placementID, timesheetDocURL, clientId } = this.state
        const [state, dispatch] = this.context
        dispatch({
            type : EMPLOYEE_TIMESHEET_ACTIONS,
            payload : {
                startDate : startDate,
                endDate : endDate,
                workdetails : {
                    standardTime: standardTimeArr.map(item => item.value), 
                    OTtime: OTArr.map(item => item.value)
                },
                timesheetDocURL : timesheetDocURL,
                placementID:state.placements_list.filter(item => item.id === placementID)[0].placementID,
                empCode : state.employee.companyID,
                clientId : clientId
            },
            actionType : 'submit',
            employeeSubmitCallback : this.employeeSubmitCallback
        })
    }

    handleUpdate = () => {
        const { startDate, endDate, standardTimeArr, OTArr, placementID, timesheetDocURL } = this.state
        const dispatch = this.context[1]
        dispatch({
            type : EMPLOYEE_TIMESHEET_ACTIONS,
            payload : {
                startDate : startDate,
                endDate : endDate,
                workdetails : {
                    standardTime: standardTimeArr.map(item => item.value), 
                    OTtime: OTArr.map(item => item.value)
                },
                timesheetDocURL : timesheetDocURL,
                placementID: this.props.placementID,
                timesheetId: this.props.timesheetId
            },
            actionType : 'update',
            employeeSubmitCallback : this.employeeSubmitCallback
        })
    }
    handleChange2 = e => {
        if (e.target.files[0]) {
            const file = e.target.files[0];
            this.setState(() => ({ file }));
            this.fileUpload1(file)
        }
    }

    fileUpload1 = (file) => {
        const [state] = this.context
        const employeeCompanyID = state.employee_collection.companyID
        const fileName = [employeeCompanyID, '_TS_', new Date().toISOString(), '.', file.name.split('.')[1]].join('')
        const filePath = `Timesheets/EmployeeSubmitTimesheet/${employeeCompanyID}/${fileName}`
        this.setState({isUploading:true})
        return this.validate.uploadToStorage(file, filePath, fileName, 'file')
        .then(url => {
            this.setState({ timesheetDocURL : url })
            this.setState({isUploading:false})
        })
        .catch(err => {
            this.setState({isUploading:false})
            console.log(err)
        })
    }


    sheetDataIntoArr = (index, standardValue, OTvalue) => {
        let standardTimeArr = [...this.state.standardTimeArr]
        let OTArr = [...this.state.OTArr]
        standardTimeArr[index] = {
            date : standardTimeArr[index].date,
            index : standardTimeArr[index].index,
            value : standardValue
        }
        OTArr[index] = {
            date : OTArr[index].date,
            index : OTArr[index].index,
            value : OTvalue
        }
        this.setState({standardTimeArr:standardTimeArr, OTArr : OTArr})
    }

    handleTime = (type, index, value) => {
        if(type === "standard"){
            const arr = this.state.standardTimeArr
            arr[index].value = value
            this.setState({standardTimeArr:arr})
        }else if(type === "ot"){
            const arr = this.state.OTArr
            arr[index].value = value
            this.setState({OTArr:arr})
        }
    }

    handleTableChange = (start, end) => {
        const newArr = []
        const OTArr = []
        const indexCount = ((end - start)/(1000 * 60 * 60 * 24)).toFixed(0)
        for(let i = 0; i <= indexCount; i++){
            OTArr.push({
                date : addDays(new Date(start), i).toISOString(),
                value : "00:00"
            })
            if(addDays(new Date(start), i).getDay() === 0 || addDays(new Date(start), i).getDay() === 6 || this.validate.dateWithNoTime(addDays(new Date(start), i)) < this.validate.dateWithNoTime(this.state.placementStartDate) || this.validate.dateWithNoTime(addDays(new Date(start), i)) > this.validate.dateWithNoTime(this.state.projectEndDate))
                newArr.push({
                    date : addDays(new Date(start), i).toISOString(),
                    value : "00:00"
                })
            else 
                newArr.push({
                    date : addDays(new Date(start), i).toISOString(),
                    value : "08:00"
                })    
        }

        this.setState({standardTimeArr:newArr, OTArr : OTArr, startDate:start, endDate: end})
    }

    render() {
        if(this.state.redirect)
            return <Redirect to="/console/timesheetslist" />
        if(!this.state.isLoading)
            return (
                <div>
                    {
                        this.state.placements.length  ? 
                            <Presentation  
                                {...this.state}
                                timesheetId={this.props.timesheetId}
                                sheetDataIntoArr={this.sheetDataIntoArr}
                                handleChange={this.handleChange}
                                handleSubmit={this.handleSubmit}
                                handleUpdate={this.handleUpdate}
                                handleChange2={this.handleChange2}
                                handleTime={this.handleTime}
                                handleTableChange={this.handleTableChange}
                                placementParam={this.props.placementID}
                                timesheetParam={this.props.timesheetId}
                            />
                        :
                            <h2 className="text-center" >No active placements are assigned to you!</h2>    
                    }
                    
                </div>
            )
        return <CustomSpinner />  
    }
}

export default Container
