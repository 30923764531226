import  firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"
import "firebase/storage"
const app = firebase.initializeApp({
    apiKey: "AIzaSyA5_DKjbfjt0BSa2QX470OBvjRYY2fcTpc",
    authDomain: "flair-dev-ad836.firebaseapp.com",
    databaseURL: "https://flair-dev-ad836.firebaseio.com",
    projectId: "flair-dev-ad836",
    storageBucket: "flair-dev-ad836.appspot.com",
    messagingSenderId: "1072007046439",
    appId: "1:1072007046439:web:576c53fb6c358a90517502",
    measurementId: "G-0W9EN5KTKK"
  
});
const storage=firebase.storage();
export  { storage,app as default }