import React, { useContext, useState, useEffect } from 'react';
import InvoicesList from "../InvoicesList/Index";
import { Autocomplete } from "@material-ui/lab";
import {
    Grid,
    TextField,
    MenuItem,
    makeStyles,
    Button,
} from '@material-ui/core';
import { GoFileSymlinkFile } from 'react-icons/go'
import { DataContext } from '../../../../contexts/data';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }, input: {
        display: 'none',
    },
}));
function Presentation(props) {
    const classes = useStyles();

    const [state] = useContext(DataContext);
    const clients = state.client_list;
    const paymentList = state.payments_list
    const { invoicesList,
        paymentFilterList,
        handleDateChange,
        handleChange2,
        handleChange,
        refNum,
        paymentMethod,
        isPayment,
        handleSave,
        date,
        paymentAmount,
        list,
        handleCancel,
        payment_Doc,
        isUploading,
        clientId } = props
    const selectedPaymentDate = date;

    const handleChange3 = (event, newValue) => {
        let data = invoicesList.filter(v => v.clientId === newValue)
        let paymentFilterList = paymentList.filter(v => v.clientId === newValue)
        handleChange("clientId", newValue)
        handleChange("list", data)
        handleChange("paymentFilterList", paymentFilterList)
    };

    const handlePaymentDateChange = date => {
        handleDateChange("date", date)
    };
    
    const handleClick = () => {
        handleCancel();
    }

    return (
        <div>
            <div className="col-lg m-1">
                <Autocomplete
                    options={clients}
                    getOptionLabel={(option) => option.businessDisplayName}
                    style={{ width: 200 }}
                    onChange={(e, v) => {
                        if (v) {
                            handleChange3("clientId", v.clientId);

                        }
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small" {...params} label="Select Client" />
                    )}
                />
                <div   >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker

                            style={{ width: 200 }}
                            margin="normal"
                            label="Payment date"
                            value={selectedPaymentDate}
                            onChange={handlePaymentDateChange}
                            format="MM/dd/yyyy"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        className={classes.formControl}
                        style={{ width: 200 }}
                        select
                        label="Payment Method"
                        size="small"
                        value={paymentMethod}
                        onChange={(event) => handleChange("paymentMethod", event.target.value)}
                    >

                        <MenuItem value={"cheque"}>
                            cheque
                            </MenuItem>
                        <MenuItem value={"online"}>
                            online
                            </MenuItem>

                    </TextField>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
             <TextField
                        className={classes.formControl}
                        label="Reference No:"
                        size="small"
                        style={{ width: 200 }}
                        variant="outlined"

                        value={refNum}
                        onChange={(event) => handleChange("refNum", event.target.value)}
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          {
                        refNum.trim().length && paymentMethod ?
                            <div>

                                <input
                                    className={classes.input}
                                    id="contained-button-file-wafile"
                                    multiple
                                    type="file"
                                    onChange={handleChange2}
                                />
                                <label htmlFor="contained-button-file-wafile">
                                    <Button variant="contained" color="primary" component="span">
                                        Upload Document
                            </Button>
                                </label>

                                <p>{payment_Doc.length !== 0 ?
                                    <a target="_blank" rel="noopener noreferrer" href={payment_Doc} >
                                        <GoFileSymlinkFile size={22} />
                                        {payment_Doc.slice(116, payment_Doc.lastIndexOf('_'))
                                        }
                                    </a>
                                    :
                                    <p>No file choosen</p>
                                }
                                </p>
                                {
                                    isUploading ? <p>Uploading please wait...</p> : ""
                                }
                            </div> : ""

                    }

                    {
                        list.length ?
                            <Grid xs={12}>

                                {
                                    refNum.trim().length && paymentMethod && payment_Doc.length ?
                                        <Button variant="contained" color="primary" component="span" onClick={handleSave}>
                                            Save
                            </Button> :
                                        <Button variant="contained" color="primary" disabled component="span" onClick={handleSave}>
                                            Save
                                  </Button>
                                }&nbsp;&nbsp;&nbsp;
                            <Button variant="contained" color="secondary" component="span" onClick={handleClick}>
                                    Cancel
                            </Button>
                            </Grid>
                            : ""
                    }

                </div>
            </div>
            {
                list.length ?
                    <InvoicesList invoicesList={list} isPayment={isPayment} {...props} paymentHandleChange={handleChange} clientId={clientId} paymentFilterList={paymentFilterList} /> : "Please Select Client"
            }
        </div>
    );
}

export default Presentation;