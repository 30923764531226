import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import {render} from 'react-dom'
import Prompt from '../../generalcomponents/snackBars'
import HoldPrompt from '../../generalcomponents/progressSnackBars'
import Axios from 'axios';


class Container extends Component {
    state = {
        employeelist:[]
    }
    static contextType = DataContext

    componentDidMount(){
    }
    

    onInviteAgain = (listOfMails) => {
        listOfMails.forEach(email => {
            const expression = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            console.log(email)
            let emailCheck = expression.test(email) 
            console.log(emailCheck)
            if(!emailCheck)
                return  render(
                            <Prompt open={true} content="Invalid email format" severity="error" />,
                            document.getElementById('notifications-box')
                        )
            render(
                <HoldPrompt open={true} content="Invitation mail is being sent..." severity="info" />,
                document.getElementById('notifications-hold-box')
            ) 
            this.setState({isSending:true})
            Axios.post("/invitethroughtoken",{ 
                ActionBy: localStorage.getItem("email"),
                uid:email,
                type:"inviteAgain",
                category : 'employeeInvitation',
                privilege : 'invite-again',
                moduleName : 'employees-manager'
            })
            .then(res => {
                console.log(res)
                this.setState({isSending:false})
                render(
                    <div></div>,
                    document.getElementById('notifications-hold-box')
                ) 
                if(res.data.status){
                
                render(
                    <Prompt open={true} content={email+" has been invited again successfully"} severity="success" />,
                    document.getElementById('notifications-box')
                )
            }
                else{
                    if(res.data.code === "already-registered"){
                        render(
                            <Prompt open={true} content="Employee already exists!" severity="error" />,
                            document.getElementById('notifications-box')
                        ) 
                    }
                    else{
                        render(
                            <Prompt open={true} content="Something went wrong!" severity="error" />,
                            document.getElementById('notifications-box')
                        ) 
                    }
                }    
            

            }).catch(err => {
                console.log(err)
                this.setState({isSending:false})
                render(
                    <div></div>,
                    document.getElementById('notifications-hold-box')
                ) 
                render(
                    <Prompt open={true} content="Something went wrong!" severity="error" />,
                    document.getElementById('notifications-box')
                ) 
            })
        })
    }


    onDelete = (employee) =>{
        render(
            <HoldPrompt open={true} content="Employee is being deleted..." severity="info" />,
            document.getElementById('notifications-hold-box')
        ) 
        Axios.post("/deleteemployee",{
            employeeUID:employee,
            category : 'employees',
            privilege : 'delete-employee',
            moduleName : 'employees-manager'
        })
        .then(res=>{
            render(
                <div></div>,
                document.getElementById('notifications-hold-box')
            ) 
            if(res.data.status)
            render(
                <Prompt open={true} content={employee+" has been deleted successfully"} severity="success" />,
                document.getElementById('notifications-box')
            )
        })
        .catch(err=>{
            render(
                <div></div>,
                document.getElementById('notifications-hold-box')
            ) 
            if(err.code === "not-suspended")
                render(
                    <Prompt open={true} content="The employee needs to be suspended" severity="error" />,
                    document.getElementById('notifications-box')
                ) 
             else   
             render(
                <Prompt open={true} content="Something went wrong!" severity="error" />,
                document.getElementById('notifications-box')
            ) 

        })
    }


    render() {
        return (
            <div>
                <Presentation 
                    {...this.state}
                    {...this.props}
                    onInviteAgain={this.onInviteAgain}
                    onDelete={this.onDelete}
                />
            </div>
        )
    }
}

export default Container
