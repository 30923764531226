import React, { useContext } from 'react'
import { AppBar, Tabs, Tab, Badge, Typography, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import InvoicesList from '../InvoicesList/Index'
import { DataContext } from '../../../../contexts/data'
import Payments from '../Payments'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  }
}

export default function Presentation(props) {
  const { listAll, id } = props
  const [value, setValue] = React.useState(0)
  let invoicesList = []
  const [state] = useContext(DataContext)
  if (state.hasOwnProperty('invoices_list')) {
    if (listAll) {
      invoicesList = state.invoices_list
    } else {
      invoicesList = state.invoices_list.filter((item) => item.empCode === id)
    }
    invoicesList = invoicesList.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    )
  }
  const handleChange = (_, newValue) => {
    setValue(newValue)
  }
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <AppBar position='static' color='default'>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          <Tab
            label={
              <Badge
                color='error'
                children='Due'
                max='10000'
                badgeContent={
                  invoicesList.filter(
                    (invoice) =>
                      invoice.isVoid === false &&
                      invoice.placementID !== '' &&
                      new Date(invoice.invoiceDueDate) < new Date()
                  ).length
                }
              />
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Badge
                color='primary'
                children='Open'
                max='10000'
                badgeContent={
                  invoicesList.filter(
                    (invoice) =>
                      invoice.placementID !== '' &&
                      invoice.isVoid === false &&
                      new Date(invoice.invoiceDueDate) > new Date()
                  ).length
                }
              />
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Badge
                color='error'
                children='Void'
                max='10000'
                badgeContent={
                  invoicesList.filter(
                    (invoice) =>
                      invoice.isVoid === true && invoice.placementID !== ''
                  ).length
                }
              />
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <Badge
                color='primary'
                children='Paid'
                max='10000'
                badgeContent={
                  invoicesList.filter(
                    (invoice) =>
                      invoice.hasOwnProperty('isPaid') &&
                      invoice.isPaid === true &&
                      invoice.placementID !== ''
                  ).length
                }
              />
            }
            {...a11yProps(3)}
          />
          <Tab
            // label={
            //   <Badge
            //     color='primary'
            //     children='Payments'
            //     max='10000'
            //     badgeContent={
            //       invoicesList.filter((invoice) => invoice.placementID !== '')
            //         .length
            //     }
            //   />
            // }
            label='Payments'
            {...a11yProps(4)}
          />
          <Tab
            label={
              <Badge
                color='primary'
                children='All'
                max='10000'
                badgeContent={
                  invoicesList.filter((invoice) => invoice.placementID !== '')
                    .length
                }
              />
            }
            {...a11yProps(5)}
          />
          <Tab
            label={
              <Badge
                color='primary'
                children='By External'
                max='10000'
                badgeContent={[].length}
              />
            }
            {...a11yProps(6)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <InvoicesList
          invoicesList={invoicesList.filter(
            (invoice) =>
              invoice.isVoid === false &&
              invoice.placementID !== '' &&
              new Date(invoice.invoiceDueDate) < new Date()
          )}
          isPayment={false}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InvoicesList
          invoicesList={invoicesList.filter(
            (invoice) =>
              invoice.placementID !== '' &&
              invoice.isVoid === false &&
              new Date(invoice.invoiceDueDate) > new Date()
          )}
          isPayment={false}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <InvoicesList
          invoicesList={invoicesList.filter(
            (invoice) => invoice.isVoid === true && invoice.placementID !== ''
          )}
          isPayment={false}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <InvoicesList
          invoicesList={invoicesList.filter(
            (invoice) =>
              invoice.hasOwnProperty('isPaid') &&
              invoice.isPaid === true &&
              invoice.placementID !== ''
          )}
          isPayment={false}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Payments
          invoicesList={invoicesList.filter(
            (invoice) => invoice.placementID !== ''
          )}
          isPayment={true}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <InvoicesList
          invoicesList={invoicesList.filter(
            (invoice) => invoice.placementID !== ''
          )}
          isPayment={false}
        />
      </TabPanel>
      <TabPanel value={value} index={6}></TabPanel>
    </div>
  )
}
