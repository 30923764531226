import React, { Component } from 'react'
import firebase from '../../../../firebase_config'
import Presentation from './Presentation'
import {withRouter} from 'react-router-dom'
import { DataContext } from '../../../../contexts/data'
import { START_EXCEL_INVITE } from '../../../../contexts/types'
import Axios from 'axios'
import Validations from '../../../generalcomponents/validation'
import { CircularProgress } from '@material-ui/core'
import CustomProgress from '../../../generalcomponents/circularSpinner'
class Container extends Component {
    state={
        placeHtml:null,
        userMail:"",
        showInviteBtn : true,
        inviteeInfo : []
    }
   
    static contextType = DataContext

   

    handleChange=(e)=>{
        console.log(e.target.value);
        const userMail=e.target.value;
        this.setState({userMail:userMail})
   
    }
    componentDidMount(){
        const [state, dispatch] = this.context
        console.log(this.props,localStorage.getItem("tokenID"))
          firebase.auth().onAuthStateChanged((user)=>{
            if(user){
            
            }
            else{
                this.props.history.push("/login")
            }
          })
        if(!state.hasOwnProperty('excel_invite'))  
            dispatch({
                type : START_EXCEL_INVITE,
                payload : []
            })  

        firebase.firestore().collection("ExcelInvite").onSnapshot(snap => {
            let inviteeInfo = snap.docs.map(doc => doc.data())
            this.setState({inviteeInfo})
            
        })  
    }

    updateTableContent = (newData, id, oldData) => {
        const [state, dispatch] = this.context
        console.log(newData, id)
        const validate = new Validations()
        if(validate.checkEmail(newData.email) && state.employees_collection.filter(employee => employee.status !== "Inactive").map(user => user.useremail).includes(newData.email)){
            newData['validEmail'] = true
            newData['isInviting'] = false
            newData['shalliInvite'] = false
            newData['comment'] = "Already registered"
            let emails = state.excel_invite
            if(state.excel_invite.map(item => item.email).includes(newData.email) && id > -1){
                emails[id] = newData
                dispatch({
                    type : START_EXCEL_INVITE,
                    payload:emails
                })
            }else{
                const dbRef = firebase.firestore().collection('ExcelInvite')
                dbRef.doc(oldData.email).delete()
                emails = [...emails, newData]
                dispatch({
                    type : START_EXCEL_INVITE,
                    payload:emails
                })
            }
        }
        else if(validate.checkEmail(newData.email) && !state.excel_invite.map(item => item.email).includes(newData.email)){
            newData['validEmail'] = true
            newData['isInviting'] = true
            newData['shalliInvite'] = true
            newData['comment'] = ''
            const dbRef = firebase.firestore().collection('ExcelInvite')
            if(id > -1){
                let emails = state.excel_invite
                emails.splice(id, 1)
                dispatch({
                    type : START_EXCEL_INVITE,
                    payload:emails
                })
                dbRef.doc(newData.email).set(newData,{merge:true})
            }else{
                if(oldData.email !== newData.email)
                dbRef.doc(oldData.email).delete().then(() => {
                    dbRef.doc(newData.email).set(newData,{merge:true})
                })
                else dbRef.doc(newData.email).set(newData,{merge:true}) 
            }
              
        }
        else if(validate.checkEmail(newData.email)){
            newData['validEmail'] = true
            newData['isInviting'] = true
            newData['shalliInvite'] = true
            newData['comment'] = ''
            const dbRef = firebase.firestore().collection('ExcelInvite').doc(newData.email)
            dbRef.set(newData,{merge:true})
            let emails = state.excel_invite
            console.log(emails)
            emails.splice(id, 1)
            console.log(emails)
            dispatch({
                type : START_EXCEL_INVITE,
                payload:emails
            })
        }
        else if(!validate.checkEmail(newData.email)){
            console.log("last block")
            newData['validEmail'] = false
            newData['isInviting'] = false
            newData['shalliInvite'] = false
            newData['comment'] = "Invalid email format"
            let emails = state.excel_invite
            const dbRef = firebase.firestore().collection('ExcelInvite')
            if(state.excel_invite.map(item => item.email).includes(newData.email)){
                emails[id] = newData
                dispatch({
                    type : START_EXCEL_INVITE,
                    payload : emails
                })
            }else{
                dbRef.doc(oldData.email).delete()
                emails = [...emails, newData]
                dispatch({
                    type : START_EXCEL_INVITE,
                    payload:emails
                })
            }
        }
    }

    deleteTableContent = (oldData) => {
        const [state, dispatch] = this.context
        let data = state.excel_invite;
        const index = data.indexOf(oldData);
        if(index > -1){
            data.splice(index, 1);
            dispatch({
                type : START_EXCEL_INVITE,
                payload : data
            })
        }
        else{
            const dbRef = firebase.firestore().collection('ExcelInvite')
            dbRef.doc(oldData.email).delete()
        }    
    }

    startInvite = () => {
        const [state, dispatch] = this.context
        let emails = state.excel_invite         
        Axios.get('/inviteThroughExcelSheet').then(res => console.log(res))
        // state.excel_invite.filter(sec => sec.validEmail === true).forEach(async(item) => {
        //     let email = item.email
        //         console.log(email)
        //         let res = await Axios.post('/invitethroughtoken',
        //                         {
        //                             uid:email,
        //                             type:"inviteUser",
        //                             category : 'employeeInvitation',
        //                             privilege : 'invite-employee',
        //                             moduleName : 'console-customization',
        //                             additionalDetails : item
        //                         })
        //                         console.log(res.data)
        //         if(res.data.status){
        //             emails.forEach((v,i) => {
        //                 if(v.email === email){
        //                     emails[i]['isInviting'] = false
        //                     emails[i]['isInvited'] = true
        //                     emails[i]['comment'] = 'Invited'
        //                 }
        //             })
        //             dispatch({
        //                 type : START_EXCEL_INVITE,
        //                 payload : emails
        //             })
        //         }
        //         else{
        //             if(res.data.code === "already-registered"){
        //                 emails.forEach((v,i) => {
        //                     if(v.email === email){
        //                         emails[i]['isInviting'] = false
        //                         emails[i]['isInvited'] = false
        //                         emails[i]['comment'] = 'Already registered!'
        //                     }
        //                 })
        //             }
        //             else{
        //                 emails.forEach((v,i) => {
        //                     if(v.email === email){
        //                         emails[i]['isInviting'] = false
        //                         emails[i]['isInvited'] = false
        //                         emails[i]['comment'] = 'Unknown error'
        //                     }
        //                 })
        //             }
                    
        //             dispatch({
        //                 type : START_EXCEL_INVITE,
        //                 payload : emails
        //             })
        //         } 
        // })
    }

    clearList = () => {
        const dispatch = this.context[1]
        dispatch({
            type : START_EXCEL_INVITE,
            payload : []
        })

        firebase.firestore().collection("ExcelInvite").get().then((snap) => {
            const emails = snap.docs.map(doc => doc.id)
            emails.forEach(email => {
                firebase.firestore().collection("ExcelInvite").doc(email).delete()
            })
        })
    }

    render() {
        const [state, dispatch] = this.context
        return (
            <div>
                {
                    state.hasOwnProperty('excel_invite') ? 
                        <Presentation 
                            {...this.state}  
                            startInvite = {this.startInvite} 
                            adminRole={this.props.adminRole} 
                            placeHtml={this.state.placeHtml} 
                            handleChange={this.handleChange} 
                            handleClick={this.handleClick}
                            clearList = {this.clearList}
                            updateTableContent={this.updateTableContent}
                            deleteTableContent = {this.deleteTableContent}
                        />
                     :
                        <CustomProgress />   
                }
                
            </div>
        )
    }
}

export default withRouter(Container)
