import React from 'react'
import Validations from './validation'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  
const validate = new Validations()

export const textField = (data, label, name, required, handleChange, section) => {
    return (
        <Grid item xs={6}>
            <TextField
            label={label}
            name={name}
            required={required}
            helperText={!data[name].length ? "":(validate.checkName(data[name]) ? "" :<span>Enter valid <span className="text-small" >{label} </span></span> )}
            value={data[name]}
            variant="outlined"
            size="small"
            onChange={(e) => handleChange(name,e.target.value[0] === undefined ? "" : e.target.value[0].toUpperCase() + e.target.value.substring(1).toLowerCase() ,section)}
            />
        </Grid>
    )
}


export const emailField = (data, label, name, required, handleChange, section) => {
    console.log(data)
    return (
        <Grid item xs={6}>
            <TextField
                label={label}
                name={name}
                disabled={section === "personalinformation" && name === "emailid"}
                required={required}
                type="email"
                helperText={data[name].length === 0 ? "":(validate.checkEmail(data[name]) ? "" : "Enter valid email")}
                value={data[name]}
                variant="outlined"
                size="small"
                onChange={(e) => handleChange(name,e.target.value,section)}
                />
        </Grid>
    )
}


export const phoneField = (data ,label, name, required, handleChange, section) => {
    return (
        < Grid item xs={6}>
            <TextField
            label={label}
            name={name}
            required={required}
            type="number"
            helperText={data[name].length === 0 ? "" : (validate.checkNumber(data[name]) ? "" :"Enter max. 10 digits")
        }

            value={data[name]}
            variant="outlined"
            size="small"
            onChange={(e) => handleChange(name,e.target.value,section)}
            />
        </Grid>
    )
}

export const zipField = (data ,label, name, required, handleChange, section) => {
    console.log(data[name])
    return (
        <Grid item xs={6}>
            <TextField
            label={label}
            name={name}
            required={required}
            type="number"
            helperText={data[name].length === 0 ? "":(validate.checkZip(data[name]) ? "" : "Enter valid zip")}
            value={data[name]}
            variant="outlined"
            size="small"
            onChange={(e) => handleChange(name,e.target.value,section)}
            />
        </Grid>
    )
}


export const selectField = (data, label, name, required, values, handleChange, section) => {
    return (
        <Grid item xs={6}>
            <FormControl style={{width:'80%'}} >
            <InputLabel required >{label} </InputLabel>
                <Select
                    fullWidth
                    value={data[name]}
                    label={label}
                    name={name}
                    required={required}
                    onChange={(e) => handleChange(name,e.target.value,section)}
                    >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {
                        values.map(item => <MenuItem value={item} >{item}</MenuItem>)
                    }
                </Select>
           </FormControl>
        </Grid>
    
    )
}


export const geoField = (data, label, name, required, values, geoChange, handleChange, section) => {
    console.log(data)
    return(
        <Grid item xs={6}>
        <Autocomplete

            style={{width:'100%'}}
            required={required}
            id="tags-outlined"
            options={values}
            getOptionLabel={option => {
                if(name === "country")
                    return option.name
                else if(name === "state")
                    return option.name
                else if(name === "city")
                    return option.city    
            }}
            onChange={(e,v) => {
                if(v){
                handleChange(name, v.name, section)   
                if(name === "country")
                    return  geoChange(section, name, v.iso2, v.name) 
                }
            }}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  label={label}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
        />
    </Grid>
    )
}

export const geoFieldCity = (data, label, name, required, values, geoChange, section) => {
    console.log(data)
    return(
        <Grid item xs={6}>
            <Autocomplete

                style={{width:'100%'}}
                required={required}
                id="tags-outlined"
                options={values}
                renderOption = {(option) => (option.name)}
                getOptionLabel={option => (option.name)}
                onChange={(e,v) =>  geoChange(section, name, v.code)}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      label={label}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
            />
        </Grid>
    )
}

export const alphaNumFields = (data, label, name, required, handleChange, section) => {
    return (
        <Grid item xs={6}>
            <TextField
            label={label}
            name={name}
            required={required}
            helperText={data[name].length === 0 ? "":data[name] !== "" ? "" : <span>Enter <span className="text-small" >{label}</span> </span>}
            value={data[name]}
            variant="outlined"
            size="small"
            onChange={(e) => handleChange(name,e.target.value,section)}
            />
        </Grid>
    )
}


export const addressFields = (data, label, name, required, handleChange, section) => {
    return (
        <Grid item xs={6}>
            <TextField
                label={label}
                name={name}
                required={required}
                helperText={data[name].length === 0 ? "":data[name] ? "" : <span>Enter <span className="text-small" >{label}</span> </span>}
                value={data[name]}
                variant="outlined"
                size="small"
                onChange={(e) => handleChange(name,e.target.value,section)}
                />
        </Grid>
    )
}


export const dateField = (data, label, name, required, handleChange, section) => {
    return(
        <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    allowKeyboardControl = {true}
                    margin="normal"
                    label={label}
                    format="MM/dd/yyyy"
                    value={data[name] ? data[name] : null}
                    onChange={(date) => {
                        if(!isNaN(Date.parse(date))) 
                            handleChange(name,new Date(date).toISOString(),section)
                    }
                    }
                    name={name}
                    required={required}
                    KeyboardButtonProps={{
                    'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>  
        </Grid>
    )
}

export const fromDateFields = (data, label, name, required, handleChange, section, boundary) => {
    return(
        <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    allowKeyboardControl = {true}
                    margin="normal"
                    label={label}
                    format="MM/dd/yyyy"
                    value={data[name] ? data[name] : null}
                    onChange={(date) => {
                    if(!isNaN(Date.parse(date))) 
                        handleChange(name,new Date(date).toISOString(),section)
                    }
                }
                    name={name}
                    required={required}
                    KeyboardButtonProps={{
                    'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>  
        </Grid>
    )
}

export const toDateFields = (data, label, name, required, handleChange, section, boundary) => {
    return(
        <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    allowKeyboardControl = {true}
                    margin="normal"
                    label={label}
                    format="MM/dd/yyyy"
                    minDate={data[boundary]}
                    value={data[name] ? data[name] : null}
                    onChange={(date) => {
                        if(!isNaN(Date.parse(date))) 
                            handleChange(name,new Date(date).toISOString(),section)
                        }
                    }
                    name={name}
                    required={required}
                    KeyboardButtonProps={{
                    'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>  
        </Grid>
    )
}

export const fileFields = (data, label, name, required, handleChange2, section) => {
    return (
        <Grid item xs={6}>
            <TextField
                label={label}
                type="file"
                name={name}
                variant="outlined"
                size="small"
                onChange={handleChange2}
            />
        </Grid>
    )
}


export const generalTextField = (label, name, value, handleChange) => {
    return(
        <Grid item xs={6}>
            <TextField
                label={label}
                type="text"
                name={name}
                value={value}
                variant="outlined"
                size="small"
                required
                onChange={(e) => handleChange(name, e.target.value)}
            />
        </Grid>
    )
}

export const generalNameField = (label, name, value, handleChange) => {
    return(
        <Grid item xs={6}>
            <TextField
                label={label}
                type="text"
                name={name}
                value={value}
                variant="outlined"
                helperText={value.length ? validate.checkName(name) ? "" : "Enter valid name":""}
                size="small"
                required
                onChange={(e) => handleChange(name, e.target.value)}
            />
        </Grid>
    )
}



