import React from 'react'
import Login from '../components/Login'

function LoginPage() {
    return (
        <Login/>
    )
}

export default LoginPage
