import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AccessTable from '../AccessTable'
import ActiveMembers from '../../ActiveMembers'
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import UpdateProject from '../UpdateProject'
import { DataContext } from '../../../../contexts/data'
import Validations from '../../../generalcomponents/validation'
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom'
import { Tooltip, Fab } from '@material-ui/core';
import { Settings, Label } from '@material-ui/icons';
import clsx from 'clsx';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import Button from '@material-ui/core/Button';
import Timeline from '../Timeline'
import Labels from "../../Task/Labels";

const useStyles = makeStyles(theme => ({
  paper: {
    
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  layout: {
    width: 800,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  root: {
   
    '& > *': {
      margin: theme.spacing(1),
      
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  
 
}));

function Presentation(props) {
  const classes = useStyles();
  const { handleEmployees, onAddMembers, employees } = props
  const [state] = useContext(DataContext)
  const id = state.project.id
  const projectMembers = state.projectList.filter(project => project.id === id)[0].Users
  console.log(projectMembers)
  let isCountExceeded = false
  const validate = new Validations()

  let supervisorCount = 0
  projectMembers.forEach(employee => {
    if (validate.checkSupervisor(employee.uid))
      supervisorCount++
  })
  employees.forEach(item => {
    if (validate.checkSupervisor(item))
      supervisorCount++
  })
  if (supervisorCount > 1)
    isCountExceeded = true


  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={clsx(classes.root)} >
      <main className={clsx(classes.layout)}>
        <ExpansionPanel
          expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>General settings</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ActiveMembers
              projectMembers={projectMembers.map(employee => employee.uid)}
              inProject={true}
              handleEmployees={handleEmployees}
            />
          </ExpansionPanelDetails>
          <Divider />
          <ExpansionPanelActions>
            <Button onClick={onAddMembers} disabled={employees.length && !isCountExceeded ? false : true} variant="contained">Add selected</Button>
          </ExpansionPanelActions>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.heading}>Members & Permissions</Typography>
            <Typography className={classes.secondaryHeading}>
              Manage Permissions/Members added to this Project.
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <AccessTable />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography className={classes.heading}>Project Details</Typography>
            <Typography className={classes.secondaryHeading}>
              Manage Project Settings
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <UpdateProject projectId={props.projectId} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {
          state.project.useTimeline &&
          <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>Project Timeline</Typography>
              <Typography className={classes.secondaryHeading}></Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Timeline />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        }
        <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>Labels</Typography>
              <Typography className={classes.secondaryHeading}></Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <Labels projectId={state.project.id}/>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
      </main>
    </div>
  )
}

export default Presentation
