import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  setDrawer:{
      width: '380px'
  }
});

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  React.useEffect(() => {
      
  }, [])

console.log(props)
  return (
    <div>
        <Button onClick={toggleDrawer("right", true)}>{props.btnContent}</Button>
        <Drawer anchor={"right"} open={state["right"]} onClose={toggleDrawer("right", false)}>
            <div className="m-3" >
                {props.bodyContent}
            </div>
        </Drawer>
    </div>
  );
}
