import React, { Component } from 'react'
import Presentation from './Presentation'
import firebase from '../../../firebase_config'
import Axios from 'axios'
import {configuration} from '../../../configuration'
import { SET_COUNTRY_API } from '../../../contexts/types'
import { DataContext } from '../../../contexts/data'
export class Container extends Component {
    state={

    }

    static contextType = DataContext

    componentDidMount = async() => {
        const dispatch = this.context[1]
        dispatch({
            type : SET_COUNTRY_API,
            payload : dispatch
        })

        let empInfo = {}
        if(this.props.registeringEmail)
            firebase.firestore().collection('Users').doc(this.props.registeringEmail).get().then(snap => {
                empInfo = snap.data().personal
            })
        else empInfo = {}    
        firebase.firestore().collection("InvitationFormBuilder")
        .orderBy("index","asc")
        .get()
        .then((snap) => {
            let formData = snap.docs.map(doc => doc.data())
            formData.forEach(item => {
                this.setState({
                    [item.section]:{}
                })
            })

            formData.forEach(item => {
                let portion = {...this.state[item.section]}
                item.fields.forEach(section => {
                    if(section.isExist){
                        if(item.section === "personalinformation" && section.name === "emailid")
                            portion[section.name] = this.props.registeringEmail
                        else if(item.section === "personalinformation" && Object.keys(empInfo).includes(section.name))  
                            portion[section.name] = empInfo[section.name]
                        // else if(item.section === "mailingaddress" && section.name === "country")
                        //     portion[section.name] = state.geo_data.countries.map(item => item.name)    
                        else    
                            portion[section.name] = ""
                    }
                })
                this.setState({[item.section]:portion})
            })
            
            this.setState({formData:formData})


        })
    }

    geoState = async(section, name, code, value) => {
        console.log(section, name, code)
        const [ state ] = this.context
        let formData = this.state.formData
        let states = {}
        if(!state.isLoggedIn)
            states = await Axios.post(`${configuration.REST_api}/loadstates`,{countrycode:code})
        else     
            states = await Axios.post(`/loadstates`,{countrycode:code})
        formData.forEach(item => {
            if(item.section === "mailingaddress"){
                item.fields.forEach((field,index) => {
                    if(field.name === "state")
                        item.fields[index]['values'] = states.data
                })
            } 
        })
        console.log(formData)
        this.setState({formData})
    }

    
   


    handleChange = (name,value,section) => {
        console.log(name, value, section)
        let portion = {...this.state[section]}
        // portion[name] = value.split(" ").filter(w => w[0] !== undefined).map(a => a[0].toUpperCase() + a.substring(1)).join(" ")
        portion[name] = value
        console.log(name,value,section)
        this.setState({
            [section]:portion
        })
        this.props.handleStateSet(section, portion)
    }

    render() {
        console.log(this.state)
        return (
            <div>
                {
                    Object.keys(this.state).length && this.state.hasOwnProperty('formData') && this.state.formData.length ? 
                    <Presentation 
                        data={this.state}
                        handleChange = {this.handleChange}
                        handleCheck={this.handleCheck}
                        geoState={this.geoState}
                        {...this.state}
                    />
                    :
                    null
                }

                
            </div>
        )
    }
}

export default Container
