import React  from 'react'
import SubtaskHandler from '../components/Project/Task/SubtaskHandler'

function TaskHandlePage(props) {
    console.log(props)
    return (
            <SubtaskHandler
                projectId={props.match.params.projectId}
                taskId={props.match.params.subtaskId}
            />
        
    )
}

export default TaskHandlePage
