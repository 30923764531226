import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import $ from 'jquery'
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
// general imports
import IDcard from '../IDcard'
import Emergencycontact from '../Emergencycontact'
import EmploymentHistory from '../EmploymentHistory'
import WorkAuthorization from '../WorkAuthorization'
import PersonalInformation from '../PersonalInformation/Index'
import EducationHistory from '../EducationHistory'
// import AlertDialog from '../../generalcomponents/alertDialog'
import PrintPDF from '../PrintPDF'
import OrgChart from '../OrgChart'
import { Link } from 'react-router-dom'
import ProfilePercentage from '../ProfilePercentage'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    fontSize: '14px',
    flexGrow:1
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function Presentation(props) {
  const classes = useStyles();
  const [ isEditing, setEditing ] = useState(false)
  // const [ open, setOpen ] = useState(false)
  const { handleUpdateProfile, emergencycontactData, employementhistoryData, workauthData, profile, educationhistory  } = props

  // const handleAgree = () => {
  //   handleUpdate()
  //   setOpen(false)
  //   setEditing(false)
  // }

  // const handleDisagree = () => {
  //     setOpen(false)
  //     setEditing(false)
  // }

  // const openDialog = () => {
  //   setOpen(true)
  // }

  return (
    <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item md={3} sm={12} xs={12}>
            <IDcard 
              profile = {profile}
              id={profile.useremail}
              individual={true}
            />
            <br/>
            <ProfilePercentage profile={profile} />
          <PrintPDF 
            profile = {profile}
            id={profile.useremail}
            individual={true}
           />
           <Link to={"/console/orgchart/"+profile.useremail} >
              <Button
                fullWidth
                variant="contained"
                className="flairbtn text-none" > OrgChart
              </Button>
            </Link>
          </Grid>
          <Grid item md={9} sm={12} xs={12}>
          <Paper className={classes.paper}>
            {
              isEditing ? 
              <Button variant="contained" onClick={() =>{ 
                $("#editable_section_for_personal_information_manager").slideToggle()
                $("#editable_section_for_personal_information_employee").slideToggle()
                setEditing(!isEditing)
                }} color="secondary">
                Close
              </Button>
                :
                <Button variant="contained" onClick={() => setEditing(!isEditing)} color="primary">
                  Edit
                </Button>
            }
            
            <br/>

            <PersonalInformation individual={true}   email={profile.useremail} profile={profile}  isEditing={isEditing} handleUpdateProfile={handleUpdateProfile}/>
            <Emergencycontact isEditing={isEditing}  email={profile.useremail} handleUpdateProfile={handleUpdateProfile} emergencycontactData={emergencycontactData} />
            <EmploymentHistory isEditing={isEditing} email={profile.useremail} handleUpdateProfile={handleUpdateProfile} employementhistoryData={employementhistoryData} />
            <WorkAuthorization isEditing={isEditing} profile={profile}   email={profile.useremail} handleUpdateProfile={handleUpdateProfile} workauthData={workauthData} />
            <EducationHistory isEditing={isEditing}  profile={profile}  email={profile.useremail} handleUpdateProfile={handleUpdateProfile} educationHistoryData={educationhistory}  />
            </Paper>
          </Grid>
        </Grid>
     
    </div>
  );
}
