import React, { Component } from 'react'
import Presentation from './Presentation'
import { CircularProgress } from '@material-ui/core'
import { DataContext } from '../../../contexts/data'
import {
  SET_WIKI_PAGES,
  SET_WIKI_CATEGORY,
  SET_WIKI_HISTORY,
} from '../../../contexts/types'
import Axios from 'axios'

class Container extends Component {
  state = {
    CategorieNames: [],
    uniqCategorieNames: [],
    Categories: [[]],
    articleLoad: false,
    categoryLoad: false,
    search: '',
    data: [],
    archived: [],
    matches: [],
    initial: 0,
    final: 3,
  }
  static contextType = DataContext

  sortArticles = () => {
    const [state] = this.context
    this.setState({ data: state.wikiPages })
    let CategorieNames = []
    let uniqCategorieNames = []
    state.wikiPages &&
      state.wikiPages.forEach((element) => {
        CategorieNames.push(element.topicName)
      })
    uniqCategorieNames = [...new Set(CategorieNames)].sort()
    var temp = []
    for (let index = 0; index < uniqCategorieNames.length; index++) {
      temp[index] = []
    }
    uniqCategorieNames.forEach((element) => {
      state.wikiPages.forEach((ele) => {
        if (ele.topicName === element) {
          temp[uniqCategorieNames.indexOf(element)].push(ele)
        }
      })
    })
    this.setState({
      uniqCategorieNames: uniqCategorieNames,
      Categories: temp,
      articleLoad: true,
    })
  }

  getArchived = () => {
    const [state] = this.context
    let archived = []
    let categories =
      state.wikiCategories &&
      state.wikiCategories.map((ele) => {
        return ele.id
      })
    state.wikiPages &&
      state.wikiPages.forEach((ele) => {
        if (!categories.includes(ele.topicName)) {
          archived.push(ele)
        }
      })
    this.setState({ archived })
  }

  getCategories = (data) => {
    const [state] = this.context
    this.setState({
      CategorieNames: state.wikiCategories,
      categoryLoad: true,
    })
    this.getArchived()
  }

  wikiAddPageCallBack = async () => {
    const dispatch = this.context[1]
    let response = await Axios.get('/getPagesInWiki')
    dispatch({
      type: SET_WIKI_PAGES,
      payload: response.data.response,
    })
  }

  wikiAddCategoryCallBack = async () => {
    const dispatch = this.context[1]
    let res = await Axios.get('wiki/getCategories')
    dispatch({
      type: SET_WIKI_CATEGORY,
      payload: res.data.response,
    })
  }

  componentDidMount = async () => {
    const [state, dispatch] = this.context
    if (
      !state.hasOwnProperty('wikiCategories') ||
      !state.hasOwnProperty('wikiPages') ||
      !state.hasOwnProperty('wikiHistory')
    ) {
      let response = await Axios.get('/getPagesInWiki')
      dispatch({
        type: SET_WIKI_PAGES,
        wikiAddPageCallBack: this.wikiAddPageCallBack,
        payload: response.data.response,
      })
      this.sortArticles()
      let res = await Axios.get('wiki/getCategories')
      dispatch({
        type: SET_WIKI_CATEGORY,
        wikiAddCategoryCallBack: this.wikiAddCategoryCallBack,
        payload: res.data.response,
      })
      this.getCategories()
      let result = await Axios.get('/getWikiPagesInHistory')
      dispatch({
        type: SET_WIKI_HISTORY,
        payload: result.data.response,
      })
    } else {
      this.sortArticles()
      this.getCategories()
    }
  }

  handleChange = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value,
    })
    this.handleSearch(e.target.value)
  }

  handleNext = () => {
    this.setState({
      initial: this.state.initial + 4,
      final: this.state.final + 4,
    })
  }

  handlePrevious = () => {
    this.setState({
      initial: this.state.initial - 4,
      final: this.state.final - 4,
    })
  }

  matchWords = (input) => {
    function escapeRegex(string) {
      return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    }
    const { data } = this.state
    input = escapeRegex(input.toLowerCase())
    let matches = data.filter((state) => {
      const regex = new RegExp(`[A-Za-z.\s]*${input}[A-Za-z.\s]*`)
      return regex.test(state.title.toLowerCase())
    })
    if (this.state.search.length === 0 || input.length === 0) {
      matches = []
      this.setState({ matches })
    } else {
      this.setState({
        matches: [...new Set(this.state.matches.concat(matches))],
      })
    }
  }

  handleSearch = (input) => {
    function hasWhiteSpace(s) {
      return /\s/g.test(s)
    }
    // if (hasWhiteSpace(input)) {
    //     let keyWord = []
    //     keyWord = input.split(' ');
    //     keyWord = [...new Set(keyWord)]
    //     keyWord.forEach(data => {
    //
    //         this.matchWords(data.trim())
    //     })
    // }
    //this.matchWords(input)
    function escapeRegex(string) {
      return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    }
    const { data } = this.state
    input = escapeRegex(input.toLowerCase())
    let matches = data.filter((state) => {
      const regex = new RegExp(`[A-Za-z.\s]*${input}[A-Za-z.\s]*`)
      return regex.test(state.title.toLowerCase())
    })
    if (input.length === 0) {
      matches = []
    }
    this.setState({ matches })
  }

  render() {
    const [state] = this.context
    if (
      state.hasOwnProperty('wikiPages') &&
      state.hasOwnProperty('wikiCategories')
    ) {
      return (
        <div>
          <Presentation
            {...this.state}
            handleChange={this.handleChange}
            handleSearch={this.handleSearch}
            handleNext={this.handleNext}
            handlePrevious={this.handlePrevious}
          />
        </div>
      )
    } else {
      return (
        <div style={{ marginTop: '15%', alignContent: 'center' }}>
          <div className='text-center'>
            <CircularProgress color='primary' />
          </div>
        </div>
      )
    }
  }
}

export default Container
