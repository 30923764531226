import React, { Component } from 'react';
import Presentation from "./Presentation";
import { DataContext } from '../../../../../../contexts/data'
import { TEMPORARY_NEW_CLIENT_DATA, SET_COUNTRY_API } from '../../../../../../contexts/types'
import Axios from 'axios'

export default class Container extends Component {

    state = {
        representativeName: '',
        jobTitle: '',
        contactType: '',
        gender: '',
        email: '',
        mobile: '',
        workPhone: '',
        homePhone: '',
        line1: '',
        line2: '',
        country: '',
        state_name: '',
        city: '',
        zip: '',
        states: []
    }

    static contextType = DataContext

    componentDidMount = () => {
        const dispatch = this.context[1]
        const { item } = this.props
        dispatch({
            type: SET_COUNTRY_API,
            payload: dispatch
        })
        Object.entries(item).forEach(([key, value]) => {
            console.log(key, value)
            this.setState({
                [key]: value
            })
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    geoState = async (code) => {
        let states = await Axios.post("/loadstates", { countrycode: code })
        this.setState({ states: states.data })
        console.log(code)
    }

    handleCountryAndState = (name, props) => {
        const [state] = this.context
        this.setState({
            [name]: props.name
        })
    }

    handleSave = (e) => {
        e.preventDefault()
        const [state, dispatch] = this.context
        const { index } = this.props
        const { representativeName, jobTitle, contactType, gender, email, mobile, workPhone, homePhone, line1, line2, country, state_name, city, zip } = this.state
        let mainArray = ('contacts' in state.temporary_client_data ) ? state.temporary_client_data.contacts : []
        console.log(mainArray)
        let payload = {
            representativeName,
            jobTitle,
            contactType,
            gender,
            email,
            mobile,
            workPhone,
            homePhone,
            line1,
            line2,
            country,
            state_name,
            city,
            zip,
            id: index
        }
        mainArray[index] = payload
        dispatch({
            type: TEMPORARY_NEW_CLIENT_DATA,
            payload: {
                key: 'contacts',
                contacts: mainArray
            }
        })
    }
    render() {
        if (Object.entries(this.state).length)
            return (
                <div>
                    <Presentation
                        {...this.state}
                        handleChange={this.handleChange}
                        handleSave={this.handleSave}
                        geoState={this.geoState}
                        handleCountryAndState={this.handleCountryAndState}
                    />
                </div>
            )
        else
            return <p>Loading..</p>
    }
}