import React from 'react'
import ChangeTempPassword from '../components/AuthComponents/ChangeTempPassword'
function ChangeTempPasswordPage() {
    return (
        <div>
            <ChangeTempPassword />
        </div>
    )
}

export default ChangeTempPasswordPage
