import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { DataContext } from '../../../contexts/data'
export default function Presentation(props) {
  
  const [ state ] = useContext(DataContext)
  const { onPromote, onDemote, onSuspend, onEnable, processing, id } = props
  let profile = {}
  state.employees_collection.forEach((employee) => {
    if(employee.useremail === id || employee.companyID === id)
      profile = employee
  })
  return (
    <div>
      <Grid container spacing={3}>
        {/* <Grid item xs={12}>
            {
               profile.Role === "User" ? 
               !processing ? 
                <Button 
                
                    fullWidth
                    variant="contained" 
                    className="flairbtn text-none" 
                    onClick={onPromote}
                >Promote as manager</Button>
                :
                <Button 
                fullWidth
                
                variant="contained" 
                className="flairbtn text-none"
                disabled
                > . . .</Button>
                :
                !processing ?
                <Button 
                fullWidth
                
                variant="contained" 
                className="flairbtn text-none" 
                onClick={onDemote}
                > Demote this employee</Button>
                :
                <Button 
                
                fullWidth
                variant="contained" 
                className="flairbtn text-none"
                disabled
                > . . .</Button>
            }
            
            
        </Grid> */}
        <Grid item xs={12}>
            {
                profile.status === "Suspended" ? 
                    !processing ? 
                    <Button 
                    
                        fullWidth
                        variant="contained" 
                        className="flairbtn-danger text-none"
                        onClick={onEnable}
                    >Enable this employee </Button>
                    :
                    <Button 
                   
                        fullWidth
                        variant="contained" 
                        className="flairbtn-danger text-none"
                        disabled
                    >. . .</Button>
                :
                    !processing ?
                    <Button 
                    
                    fullWidth
                    variant="contained" 
                    className="flairbtn-danger text-none"
                    onClick={onSuspend}
                    > Suspend this employee</Button>
                    :
                    <Button 
                    
                    fullWidth
                    variant="contained" 
                    className="flairbtn-danger text-none"
                    disabled
                    >. . .</Button>

            }
           
        </Grid>
        </Grid>
    </div>
    
  );
}
