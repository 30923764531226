import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import parser from 'html-react-parser'
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { PDFExport } from '@progress/kendo-react-pdf';
import { DataContext } from '../../../../contexts/data'
// import { savePDF } from '@progress/kendo-react-pdf';
import VoidInvoice from "../InvoicesList/VoidInvoice";
import { Typography } from '@material-ui/core';
import { Document, Page } from 'react-pdf';
import firebase from "../../../../firebase_config"
import { PDFReader } from 'reactjs-pdf-reader'


const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',

    },
    title: {
        marginLeft: theme.spacing(2),
        flexGrow: 1,
        opacity: 0
    },
    layout: {
        width: '100%',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        width: '100%',
        margin: theme.spacing(0, 1, 1, 1),
        padding: theme.spacing(0, 2, 2, 2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    new: {
        float: 'right'
    },
}));

function Presentation(props) {
    const [state] = useContext(DataContext)
    const style = {
        background: {
            //backgroundImage: `url(${state.company_config.images.watermark})`,
            backgroundSize: '70% 25%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        content: {
            height: '100%',
            width: '100%',
        }
    }
    const handleClose = () => {
        setOpen(false);

    };
    const Header = () => {
        return (
            <div>
                <header style={{ position: "absolute", top: "1px", left: "1px" }}>


                </header>




                <footer
                    style={{ position: "absolute", bottom: "-16px", left: "1px" }}>

                </footer>

            </div>
        )
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    let { pdfExportComponent, htmlContent, btnContent, headerAndFooter, iconBtn, isString, fileName, rowData } = props
    // if(typeof(htmlContent) === 'string')
    return (
        <div>
            {
                iconBtn ?
                    <IconButton onClick={handleClickOpen} color="inherit">{btnContent}</IconButton>
                    :
                    <Button className="mt-3 mb-3" onClick={handleClickOpen} variant="contained" fullWidth color="primary">{btnContent}</Button>
            }
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography className={classes.title}>
                            News
                            </Typography>
                        <VoidInvoice {...rowData} iconBtn={false} />
                        <Button
                            type="submit"
                            color="inherit"
                            onClick={() => { pdfExportComponent.save(); }}
                        >
                            Download
                        </Button>
                    </Toolbar>
                </AppBar>
                <main>
                    <Paper className={classes.paper}>
                        <PDFExport
                            paperSize="A4"
                            margin="8mm"
                            scale={0.7}
                            ref={(component) => pdfExportComponent = component}
                            fileName={fileName}
                        >
                            <div id="taskpdfid" style={style.background}>
                                <div style={style.content}>
                                    {
                                        isString ? parser(htmlContent) : htmlContent
                                    }
                                </div>
                            </div>
                        </PDFExport>
                    </Paper>
                </main>

            </Dialog>

        </div>
    )
}

export default Presentation



export function ReactPreviewPDF(props){
    const { gsPath, iconBtn, btnContent } = props
    const [link, setLink] = useState("")
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const getURL = () => {
        const storage = firebase.storage()
        const storageRef = storage.refFromURL(gsPath)
        storageRef.getDownloadURL().then(url => {
            alert(url)
            setLink(url)
            return url
        })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [open, setOpen] = React.useState(false);

        return (
            <div>
                {
                    iconBtn ?
                        <IconButton onClick={() => {
                            handleClickOpen()
                            getURL()
                        }} color="inherit">{btnContent}</IconButton>
                        :
                        <Button className="mt-3 mb-3" onClick={handleClickOpen} variant="contained" fullWidth color="primary">{btnContent}</Button>
                }
                <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                    <AppBar>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography>
                                News
                                </Typography>
                            {/* <VoidInvoice {...rowData} iconBtn={false} /> */}
                            <Button
                                type="submit"
                                color="inherit"
                                // onClick={() => { pdfExportComponent.save(); }}
                            >
                                Download
                            </Button>
                        </Toolbar>
                    </AppBar>
                    {
                        link !== "" ? 
                        <>
                            <PDFReader url={"https://storage.cloud.google.com/flair-dev-ad836.appspot.com/Dummy/CLN-001/PC-2/INV_C001_P2_2020-07-28T16:52:12.903Z.pdf"} />
                        </>: <p>Loading</p>
                    }
                    
                </Dialog>
            
            </div>
        )
}