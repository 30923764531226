import React, { Component } from 'react'
import Presentation from './Presentation'
import { SET_COUNTRY_API } from '../../../../contexts/types'
import { DataContext } from '../../../../contexts/data'
import { UPDATE_PROFILE } from '../../../../contexts/types'
import Axios from 'axios'
import Validations from "../../../generalcomponents/validation";
export class Container extends Component {
    state = {
        profile:{},
        personal:{},
        mailingaddress:{},
        countries:[],
        states:[],
        firstname:"",
        lastname:"",
        middlename:"",
        emailid:"",
        phonenumber:"",
        dob:"",
        city:"",
        country:"",
        line1:"",
        line2:"",
        state:"",
        zip:"",
        gender:"",
        maritalstatus:"",
    }

    static contextType = DataContext


    componentDidMount = () => {
        const dispatch  = this.context[1]
        dispatch({
            type : SET_COUNTRY_API,
            payload : dispatch
        })
        this.setState({profile:this.props.profile})
        this.setState({
            personal:this.props.profile.personal,
            mailingaddress:this.props.profile.mailingaddress
        })
        Object.entries(this.props.profile.personal).forEach(([key, value]) => {
            this.setState({
                [key]:value
            })
        })
        Object.entries(this.props.profile.mailingaddress).forEach(([key, value]) => {
            this.setState({
                [key]:value
            })
        })
    }

    handleChange = (key, value) => {
        const validate = new Validations()
        if(key === "emailid")
            this.setState({
                [key]:value.toLowerCase()
            })
        else if(key === "dob" && !isNaN(Date.parse(value)))
            this.setState({
                [key]:value.toISOString()
            })
        else if(key !== "dob")
            this.setState({
                [key]:validate.nameFormatterToUpperCase(value)
            })
    }
    
    handleDone = (e) => {
        const dispatch = this.context[1]
        const personal = {
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            middlename:this.state.middlename,
            emailid:this.state.emailid,
            employeestatus:this.state.employeestatus,
            phonenumber:this.state.phonenumber,
            gender:this.state.gender,
            maritalstatus:this.state.maritalstatus,
            dob:this.state.dob
        }
        const mailingaddress = {
            city:this.state.city,
            country:this.state.country,
            line1:this.state.line1,
            line2:this.state.line2,
            state:this.state.state,
            zip:this.state.zip,
        }
        this.setState({
            personal,
            mailingaddress
        })

        let finalData = {}
        finalData['personal'] = {
            ...this.state.profile.personal,
            ...personal,
        }

        finalData['mailingaddress'] = {
            ...this.state.profile.mailingaddress,
            ...mailingaddress
        }
        
        dispatch({
            type : UPDATE_PROFILE,
            payload : {
                email:this.props.email,
                updateValue : finalData
            }
        })
        // this.props.handleUpdateProfile({
        //     category:'employee',
        //     employee:true
        // }, {
        //     ...this.state.profile.personal,
        //     ...this.state.profile.mailingaddress,
        //     ...personal,
        //     ...mailingaddress
        // })
    }

    handleCancel = () => {
        Object.entries(this.props.profile.personal).forEach(([key, value]) => {
            this.setState({
                [key]:value
            })
        })
        Object.entries(this.props.profile.mailingaddress).forEach(([key, value]) => {
            this.setState({
                [key]:value
            })
        })
    }


    geoState = async(code) => {
        let states = await Axios.post("/loadstates",{countrycode:code})
        this.setState({states:states.data})
    }
    render() {
        const info = {
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            middlename:this.state.middlename,
            emailid:this.state.emailid,
            phonenumber:this.state.phonenumber,
            gender:this.state.gender,
            maritalstatus:this.state.maritalstatus,
            dob:this.state.dob,
            city:this.state.city,
            country:this.state.country,
            line1:this.state.line1,
            line2:this.state.line2,
            state:this.state.state,
            zip:this.state.zip,
        }
        const nonrequired = ['line2', 'middlename']
        let errCount = 0
        Object.entries(info).forEach(([key, value]) => {
            if(!nonrequired.includes(key)){
                if(value === "")
                    errCount++
            }
        })
        return (
            <div>
                <Presentation 
                    info = {info}
                    {...this.state}
                    profile={this.state.profile}
                    individual={this.props.individual}
                    isEditing={this.props.isEditing}
                    handleChange={this.handleChange}
                    handleDone={this.handleDone}
                    handleCancel={this.handleCancel}
                    errCount={errCount}
                    geoState={this.geoState}
                />
            </div>
        )
    }
}

export default Container
