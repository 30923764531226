import React, { Component } from 'react'
import Presentation from './Presentation'
class Container extends Component {
    state = {
        filterBy:"",
        taskList : [],
        assigneeList : []
    }

    render() {
        return (
            <div>
                <Presentation 
                    {...this.state}
                />
            </div>
        )
    }
}

export default Container
