import React, { useContext, useState, useEffect } from 'react'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Checkbox, FormControlLabel, Badge } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DataContext } from '../../../../../contexts/data';
import { addDays, eachDayOfInterval, isWithinInterval } from 'date-fns'
import parser from 'html-react-parser'
import Validations from '../../../../generalcomponents/validation';
function Presentation(props) {
    const { empCode, placementID, clientId, invoiceFreq, invoiceFreqStartDate, placementEndDate, handleChange } = props
    const [state] = useContext(DataContext)
    const [employee, setEmployee] = useState({})
    const validate = new Validations()
    useEffect(() => {
        setEmployee(state.names.filter(item => item.companyID === empCode)[0])
    }, [empCode, invoiceFreq, invoiceFreqStartDate])

    let placeRanges = []
    const eachDay = eachDayOfInterval({
        start: new Date(invoiceFreqStartDate),
        end: new Date(placementEndDate)
    })
    
    let initialDate = invoiceFreqStartDate
    let currStartIndex = 0
    for(let i = 0; i < eachDay.length; i++){
        // const checkDayExistInterval = isWithinInterval(new Date(eachDay[i]), {
        //     start: state.invoices_list.filter(item => item.empCode === empCode && item.placementID === placementID && item.clientId === clientId).reduce((initial, item) => new Date(initial) > new Date(item.startDate) ? new Date(initial) : new Date(item.startDate), new Date(item.startDate)),
        //     end: state.invoices_list.filter(item => item.empCode === empCode && item.placementID === placementID && item.clientId === clientId).reduce((initial, item) => new Date(initial) > new Date(item.endDate) ? new Date(initial) : new Date(item.endDate), new Date(item.endDate))
        // })
        if(new Date(eachDay[i]).getMonth() !== new Date(eachDay[i+1]).getMonth()){
            placeRanges.push({
                startDate: new Date(initialDate).toISOString(),
                endDate: new Date(eachDay[i]).toISOString()
            })
            initialDate = new Date(eachDay[i + 1])
        }
    }


    return (
        <div>
            {
               typeof(employee) === "object" && Object.keys(employee).length ? <h3>{employee.name}</h3> : ''
            }
            <hr/>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                     <Badge color="primary" badgeContent={placeRanges.length} ><Typography>Timesheets</Typography></Badge>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className="d-flex flex-column" >
                        {
                            placeRanges.map(item => {
                                return (
                                    <div>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(e) => {
                                                if(e.target.checked) handleChange("timesheetsRange", item)
                                                else handleChange("timesheetsRange", {
                                                    startDate: '',
                                                    endDate: ''
                                                })
                                            }} />}
                                            label={validate.dateFormatter(item.startDate) + "-" + validate.dateFormatter(item.endDate)}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}

export default Presentation


function freqToDays(range){
    switch (range) {
        case 0: return 0
        case 1: return 6
        case 2: return 13
        case 3: return 14
        case 4 : return 29
        default: return 0
    }
}