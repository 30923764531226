import React, { Component } from 'react'
import Presentation from './Presentation'
import {LINK_TASKS} from '../../../../../contexts/types'
import {DataContext} from '../../../../../contexts/data'
class Container extends Component {
    state = {
        listOfTasksToBeLinked: []
    }
    static contextType=DataContext
    handleChange = (e, value) => {
        let taskArray = this.state.listOfTasksToBeLinked
        if (e.target.checked) {
            taskArray = [...taskArray, value]
        }
        else {
            const index = taskArray.indexOf(value)
            taskArray.splice(index, 1)

        }
        console.log(taskArray)
        this.setState({ listOfTasksToBeLinked: taskArray })
    }
    handleClick = (e) => {
        e.preventDefault()
        const [state,dispatch] =this.context
        dispatch(
            {
                type:LINK_TASKS,
                payload:{
                    parentTaskId : state.task.id,
                    tasksToBeLinked : this.state.listOfTasksToBeLinked,
                    projectId : state.project.id
                }
            }
        )

    }
    clearValues = () => {
        this.setState({
            listOfTasksToBeLinked: ""
        })
    }

    render() {
        return (
            <div>
                <Presentation
                    {...this.state}
                    {...this.props}
                    handleChange={this.handleChange}
                    handleClick={this.handleClick}
                    clearValues={this.clearValues}
                />
            </div>
        )
    }
}

export default Container
