import React, { useState } from 'react'
import { Collapse, Spinner } from 'reactstrap'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { configuration } from '../../../configuration'
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from "@material-ui/icons/AddCircle"
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Validations from '../../generalcomponents/validation'
import { GoFileSymlinkFile } from 'react-icons/go'
import Typography from '@material-ui/core/Typography';
import { ExpansionPanelSummary } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    input: {
        display: 'none',
    },
    large: {
        position: "absolute",
        width: theme.spacing(7),
        height: theme.spacing(7),
        margin: "1.1em",
    },
    box: {
        borderRadius: "100px",
        height: 100,
        width: 100,
        margin: "auto",
        marginTop: "4px"
    },
    table: {
        backgroundColor: "#fafafa"
    },
}));
function Presentation(props) {
        const classes = useStyles();
        const { clearValues, work_exp, work_type, work_issue,work_doc, progress1,work_number,handleChange2, handleExisting, isEditing, handleAdd, editSection, handleEdit, handleDelete, workauthData, handleChange, handleDateChange, isUploading } = props
        const [isOpen, setIsOpen] = useState(false);
        const [isOpen2, setIsOpen2] = useState(false);
        const toggle = () => {
            setIsOpen(!isOpen);
            if(isOpen2){
                setIsOpen2(!isOpen2)
                clearValues()
            }
            else{
                clearValues()
            }
        }
    const toggle2 = () => {
        setIsOpen2(!isOpen2)
        if (isOpen) {
            setIsOpen(!isOpen)
            clearValues()
        }
        else {
            clearValues()
        }
    }
    if (!isEditing) {
        if (isOpen)
            setIsOpen(!isOpen)
        if (isOpen2)
            setIsOpen2(!isOpen2)
    }
    const formatter = (date) => {
        let final = ""
        try {
            final = Intl.DateTimeFormat(configuration["date-code"], configuration.dateformat).format(new Date(date))
        } catch (error) {
            final = date
        }
        return final
    }

    const handleIssueDate = date => {
        handleDateChange("work_issue", date)
    };
    const handleExpiryDate = date => {
        handleDateChange("work_exp", date)
    };

    const validate = new Validations()

    //var namestr = item.work_doc;
    //var newstr = namestr.replace(/%2F/g,' ');

    return (

        <div className="user-workauth mt-3">
            <h3><u><b>Work Authorization:</b></u></h3>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell width="15%" align="left">Type</TableCell>
                            <TableCell width="25%" align="left">Number</TableCell>
                            <TableCell width="25%" align="left">Document</TableCell>
                            <TableCell width="20%" align="left">Issue date</TableCell>
                            <TableCell width="17%" align="left">Expiry date</TableCell>
                            {
                                isEditing ?
                                    <TableCell align="right"  >
                                        <Fab
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={toggle}
                                        >
                                            <AddCircleIcon />
                                        </Fab>
                                    </TableCell>
                                    :
                                    null
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {workauthData.map(item => {
                            return (
                                <TableRow key={item.work_number} >
                                    <TableCell align="left">{item.work_type}</TableCell>
                                    <TableCell align="left">{item.work_number}</TableCell>
                                    <TableCell align="left">{item.work_doc !== "" ? <a href={item.work_doc} rel="noopener noreferrer" target="_blank" ><div style={{overflow:'hidden', textOverflow:'ellipsis', width:'11rem'}} ><Typography title={item.work_doc} noWrap>{item.work_doc.slice(99)}</Typography></div></a> : <span>Document</span>}</TableCell>
                                    <TableCell align="left">{formatter(item.work_issue)}</TableCell>
                                    <TableCell align="left">{formatter(item.work_exp)}</TableCell>
                                    {
                                        isEditing ?
                                            <TableCell className="right" >
                                                <Fab
                                                    variant="contained"
                                                    className="flairbtn"
                                                    size="small"
                                                    onClick={() => { toggle2(); handleEdit(item) }}
                                                >
                                                    <EditIcon />
                                                </Fab>&nbsp;
                                                    {workauthData.length > 1 ? <Fab
                                                    variant="contained"
                                                    size="small"
                                                    color="secondary"
                                                    onClick={() => handleDelete(workauthData.indexOf(item))}
                                                >
                                                    <DeleteIcon />
                                                </Fab>
                                                    : null
                                                }
                                            </TableCell>
                                            :
                                            null
                                    }
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div>
                <Collapse isOpen={isOpen}>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        if ((work_type === "USC" && work_doc !== "") || (work_type.length && !isNaN(Date.parse(work_issue)) && !isNaN(Date.parse(work_exp)) && validate.checkDateDiff(work_issue, work_exp) && work_doc !== "")) {
                            toggle(); handleAdd(e)
                        }
                    }}>
                        <div className="m-3">
                            <Paper style={{ padding: '15px' }} >
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <FormControl style={{ width: '80%' }} >
                                            <InputLabel required >Work type </InputLabel>
                                            <Select
                                                fullWidth
                                                value={work_type}
                                                label="Work type"
                                                name="work_type"
                                                required
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="CPT" >CPT</MenuItem>
                                                <MenuItem value="OPT" >OPT</MenuItem>
                                                <MenuItem value="OPT-STEM" >OPT-STEM</MenuItem>
                                                <MenuItem value="H1-B" >H1-B</MenuItem>
                                                <MenuItem value="L-1" >L-1</MenuItem>
                                                <MenuItem value="O-1" >O-1</MenuItem>
                                                <MenuItem value="H-4" >H-4</MenuItem>
                                                <MenuItem value="TN" >TN</MenuItem>
                                                <MenuItem value="GC" >GC</MenuItem>
                                                <MenuItem value="USC" >USC</MenuItem>
                                            </Select>
                                            <FormHelperText>{ work_type === '' ? 'Select work type' : '' } </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    {
                                        work_type === "USC"
                                            ?
                                            null
                                            :
                                            <Grid item xs={6}>
                                                <TextField
                                                    label={work_type === "CPT" ? "SEVIS Number" : "Work Number"}
                                                    name="work_number"
                                                    value={work_number}
                                                    disabled={work_type === "USC" ? true : false}
                                                    variant="outlined"
                                                    size="small"
                                                    required
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                    }

                                    {
                                        work_type === "USC"
                                            ?
                                            null
                                            :
                                            <Grid item xs={6}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        label="Issue date"
                                                        format="MM/dd/yyyy"
                                                        invalidDateMessage=""
                                                        disabled={work_type === "USC" ? true : false}
                                                        value={work_issue ? work_issue : null}
                                                        onChange={handleIssueDate}
                                                        name="work_issue"
                                                        required
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                    }

                                    {
                                        work_type === "USC"
                                            ?
                                            null
                                            :
                                            <Grid item xs={6}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        label="Expiry date"
                                                        format="MM/dd/yyyy"
                                                        invalidDateMessage=""
                                                        minDate={work_issue}
                                                        disabled={work_type === "USC" ? true : false}
                                                        value={work_exp ? work_exp : null}
                                                        onChange={handleExpiryDate}
                                                        name="work_exp"
                                                        required
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                    }

                                    <Grid item xs={6}>
                                        {/* <FileUploader /> */}
                                        <input

                                            className={classes.input}
                                            id="contained-button-file-wafile"
                                            multiple
                                            type="file"
                                            onChange={handleChange2}
                                        />
                                        <label htmlFor="contained-button-file-wafile">
                                            <Button variant="contained" color="primary" component="span">
                                                Upload Document
                                         </Button>
                                        </label>
                                        <p>{work_doc !== "" ? <a target="_blank" rel="noopener noreferrer" href={work_doc} ><GoFileSymlinkFile size={22} /></a> : <p>No file choosen</p>} </p>
                                        {
                                            isUploading ?  <p>Uploading please wait...</p> : "" 
                                        }

                                    </Grid>
                                </Grid>
                                <br />
                                <div className="text-center" style={{ margin: '10px' }}>
                                    {
                                        isUploading  ?
                                        <Button disabled variant="contained" type="button" color="primary">
                                            Save
                                        </Button>
                                        :

                                        <Button variant="contained" type="submit" color="primary">
                                            Save
                                        </Button>
                                    }
                                        {' '}
                                        <Button variant="contained" onClick={toggle} color="default">
                                            Cancel
                                        </Button>
                                </div>
                                <br />
                            </Paper>
                        </div>
                    </form>
                </Collapse>
            </div>
            <div>
                <Collapse isOpen={isOpen2}>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        if ((work_type === "USC" && work_doc !== "") || (work_type.length && !isNaN(Date.parse(work_issue)) && !isNaN(Date.parse(work_exp)) && validate.checkDateDiff(work_issue, work_exp) && work_doc !== "")) {
                            toggle2(); handleExisting(workauthData.indexOf(editSection), editSection)
                        }
                    }}>
                        <div className="m-3">
                            <Paper style={{ padding: '15px' }} >
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <FormControl style={{ width: '80%' }} >
                                            <InputLabel required >Work type </InputLabel>
                                            <Select
                                                fullWidth
                                                value={work_type}
                                                label="Work type"
                                                name="work_type"
                                                required
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="CPT" >CPT</MenuItem>
                                                <MenuItem value="OPT" >OPT</MenuItem>
                                                <MenuItem value="OPT-STEM" >OPT-STEM</MenuItem>
                                                <MenuItem value="H1-B" >H1-B</MenuItem>
                                                <MenuItem value="L-1" >L-1</MenuItem>
                                                <MenuItem value="O-1" >O-1</MenuItem>
                                                <MenuItem value="H-4" >H-4</MenuItem>
                                                <MenuItem value="TN" >TN</MenuItem>
                                                <MenuItem value="GC" >GC</MenuItem>
                                                <MenuItem value="USC" >USC</MenuItem>
                                            </Select>
                                            <FormHelperText>{ work_type === '' ? 'Select work type' : '' } </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    {
                                        work_type === "USC"
                                            ?
                                            null
                                            :

                                            <Grid item xs={6}>
                                                <TextField
                                                    label={work_type === "CPT" ? "SEVIS Number" : "Work Number"}
                                                    name="work_number"
                                                    value={work_number}
                                                    variant="outlined"
                                                    size="small"
                                                    required
                                                    onChange={handleChange}
                                                />
                                            </Grid>

                                    }


                                    {
                                        work_type === "USC"
                                            ?
                                            null
                                            :
                                            <Grid item xs={6}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        label="Issue date"
                                                        format="MM/dd/yyyy"
                                                        invalidDateMessage=""
                                                        disabled={work_type === "USC" ? true : false}
                                                        value={work_issue ? work_issue : null}
                                                        onChange={handleIssueDate}
                                                        name="work_issue"
                                                        required
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                    }

                                    {
                                        work_type === "USC"
                                            ?
                                            null
                                            :
                                            <Grid item xs={6}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        label="Expiry date"
                                                        format="MM/dd/yyyy"
                                                        invalidDateMessage=""
                                                        minDate={work_issue}
                                                        disabled={work_type === "USC" ? true : false}
                                                        value={work_exp ? work_exp : null}
                                                        onChange={handleExpiryDate}
                                                        name="work_exp"
                                                        required
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                    }

                                    <Grid item xs={6}>
                                        
                                            <div>
                                            <input

                                            className={classes.input}
                                            id="contained-button-file-wafile"
                                            multiple
                                            type="file"
                                            onChange={handleChange2}
                                        />
                                        <label htmlFor="contained-button-file-wafile">
                                            <Button variant="contained" color="primary" component="span">
                                                Upload Document
                                         </Button>
                                        </label>
                                        </div>
                                        <p>{work_doc !== "" ? <a target="_blank" rel="noopener noreferrer" href={work_doc} ><GoFileSymlinkFile size={22} /></a> : <p>No file choosen</p>} </p>
                                        {
                                            isUploading ?  <p>Uploading please wait...</p> : "" 
                                        }
                                    </Grid>
                                </Grid>
                                <br />
                                <div className="text-center" style={{ margin: '10px' }}>
                                    {
                                        isUploading  ?
                                            <Button disabled variant="contained" type="button" color="primary">
                                                Save
                                            </Button>
                                            :

                                            <Button variant="contained" type="submit" color="primary">
                                                Save
                                            </Button>
                                    }
                                            {' '}
                                            <Button variant="contained" onClick={toggle2} color="default">
                                                Cancel
                                            </Button>
                                </div>
                                <br />
                            </Paper>
                        </div>
                    </form>
                </Collapse>
            </div>
        </div>
    )
}

export default Presentation
