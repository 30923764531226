import React, { Component } from 'react'
import Presentation from './Presentation'
import firebase from '../../../firebase_config'
import DataContext from '../../../contexts/data'
import Axios from 'axios'
import {  render } from 'react-dom';
import HoldPrompt from '../../generalcomponents/progressSnackBars'
import Prompt from '../../generalcomponents/snackBars'

export class Container extends Component {
    state = {
        responseData : [],
        showLoader : true
    }
    static contextType = DataContext

    componentDidMount(){
        this.loadData()
        this.setState({showLoader : true})
    }

    loadData = async() => {
        const response =  await Axios.get('/getModuleAccessOfEmployees')
        const result = await response.data
        console.log(result)
        this.setState({ responseData : result.response, showLoader : false })
    }

    handleChange = (e, actionIndex) => {
        let list = this.state.responseData
        if(e.target.checked){
            let items = []
            const pairs = {
                'wiki-manager' : 'wiki',
                'timesheets-manager' : 'timesheets',
                'immigration-manager' : 'immigration',
                'task-management-manager' : 'task-management',
                'employee-self-services-manager' : 'employee-self-services',
                'accounts-manager': 'employees-manager'
            }
            if(Object.keys(pairs).includes(e.target.name)) items = [pairs[e.target.name], e.target.name]
            else items = [e.target.name]      
            list[actionIndex]['accesibles'] = [... new Set([...list[actionIndex]['accesibles'], ...items])]
        }
        else{
            const index = list[actionIndex]['accesibles'].indexOf(e.target.name)    
            list[actionIndex]['accesibles'].splice(index, 1)  
            const pairs = {
                'wiki' : 'wiki-manager',
                'timesheets' : 'timesheets-manager',
                'immigration' : 'immigration-manager',
                'task-management' : 'task-management-manager',
                'employee-self-services' : 'employee-self-services-manager',
                'employees-manager': 'accounts-manager'
            }
            if(Object.keys(pairs).includes(e.target.name) && list[actionIndex]['accesibles'].indexOf(pairs[e.target.name]) !== -1)
                list[actionIndex]['accesibles'].splice(list[actionIndex]['accesibles'].indexOf(pairs[e.target.name]), 1)  
        }    
        this.setState({ responseData : list })

    }

    handleModulesUpdate = async(index) => {
        render(
            <HoldPrompt open={true} content="Updating modules..." severity="info" />,
            document.getElementById('notifications-hold-box')
        ) 
       let response = await Axios.post('/updatemoduleaccess',{moduleAccess : this.state.responseData[index].accesibles, employeeUID : this.state.responseData[index].email })
       const result = await response.data
       render(
        <div></div>,
        document.getElementById('notifications-hold-box')
        ) 
        if(result.status){
            render(
                 <Prompt open={true} content="Modules updated successfully!" severity="success" />,
                 document.getElementById('notifications-box')
            )
         }
         else   {
             render(
                 <Prompt open={true} content="Something went wrong!" severity="error" />,
                 document.getElementById('notifications-box')
             )    
         } 
       console.log(response.data)
    }

    render() {
        return (
            <div>
                <Presentation 
                    {...this.state}
                    handleChange={this.handleChange}
                    handleModulesUpdate = {this.handleModulesUpdate}
                />
            </div>
        )
    }
}

export default Container
