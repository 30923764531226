import React, { Component } from 'react'
import Presentation from './Presentation'
import firebase from '../../../../../firebase_config'
import { DataContext } from '../../../../../contexts/data'
import { PLACEMENT_SETTINGS_ACTIONS } from '../../../../../contexts/types'
import Validations from '../../../../generalcomponents/validation'

class Container extends Component {
  constructor(props) {
    super(props)
    this.validate = new Validations()
  }

  state = {
    documentType: '',
    status: '',
    isUploading: false,
    work_doc: [],
    id: '',
    progress: 0,
    profile: {},
    fillableSections: [],
    effectiveDate: [],
    effectiveUntilDate: [],
  }

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  static contextType = DataContext

  handleChange2 = (e) => {
    console.log(e.target.files[0])

    if (e.target.files[0]) {
      const file = e.target.files[0]
      this.setState(() => ({ file }))
      this.fileUpload1(file, this.state.documentType)
    }
  }

  fileUpload1 = (file, type) => {
    const { req_placement } = this.props
    const { empCode, placementID } = req_placement
    // companyID_placementID_docType_Timestamp
    const fileName = [
      empCode,
      '_',
      placementID,
      '_',
      type,
      '_',
      new Date().toISOString(),
    ].join('')
    const filePath = `Placements/${placementID}/Documents/${fileName}`
    this.setState({ isUploading: true })
    return this.validate
      .uploadToStorage(file, filePath, fileName, 'file')
      .then((url) => {
        this.setState((prevState) => ({
          work_doc: prevState.work_doc.concat(url),
        }))
        this.setState({ isUploading: false })
      })
      .catch((err) => {
        this.setState({ isUploading: false })
        console.log(err)
      })
  }

  getData = (id) => {
    firebase
      .firestore()
      .collection('Placements')
      .doc(id)
      .collection('Documents')
      .onSnapshot((snap) => {
        let placementData = []
        placementData = snap.docs.map((doc) => doc.data())
        placementData.length &&
          this.setState({
            work_doc: placementData[0].document,
            status: placementData[0].status,
            documentType: placementData[0].type,
            effectiveDate: placementData[0].effectiveDate,
            effectiveUntilDate: placementData[0].effectiveUntilDate,
          })
      })
  }

  componentDidMount = () => {
    const { req_placement } = this.props
    if (req_placement.id) this.getData(req_placement.id)
  }

  componentWillReceiveProps = (nextProps) => {
    const { req_placement, profile } = nextProps
    const { id, fillableSections } = req_placement
    this.setState({ profile, id, fillableSections })
    if (id.length && this.props.req_placement.id !== id) this.getData(id)
  }

  placementSettingsActionsWiki = () => {}

  handleCreate = () => {
    const dispatch = this.context[1]
    const {
      documentType,
      status,
      work_doc,
      id,
      effectiveDate,
      effectiveUntilDate,
    } = this.state
    let payload = {
      type: documentType,
      document: work_doc,
      status: status,
      placementID: id,
      effectiveDate,
      effectiveUntilDate,
      sectionName: 'documents',
    }
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
      payload: payload,
      actionType: 'new',
    })
  }

  handleUpdate = () => {
    const dispatch = this.context[1]
    const {
      documentType,
      status,
      work_doc,
      id,
      effectiveDate,
      effectiveUntilDate,
    } = this.state
    let payload = {
      sectionName: 'documents',
      type: documentType,
      document: work_doc,
      status: status,
      placementID: id,
      effectiveDate,
      effectiveUntilDate,
    }
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
      payload: payload,
      actionType: 'update',
    })
  }

  handleSave = (type) => {
    if (type === 'create') this.handleCreate()
    else if (type === 'update') this.handleUpdate()
    else console.log('Error')
  }

  handleDelete = (index) => {
    console.log(index)
    const { work_doc, effectiveDate, effectiveUntilDate } = this.state
    let updated_array = []
    updated_array = work_doc
    updated_array.splice(index, 1)
    let updated_effectiveDate = effectiveDate
    updated_effectiveDate.splice(index, 1)
    let updated_effectiveUntilDate = effectiveUntilDate
    updated_effectiveUntilDate.splice(index, 1)
    this.setState({
      work_doc: updated_array,
      effectiveDate: updated_effectiveDate,
      effectiveUntilDate: updated_effectiveUntilDate,
    })
  }

  handleDateChange = (key, value) => {
    if (!isNaN(Date.parse(value)))
      this.setState((prevState) => ({
        [key]: prevState[key].concat(value.toISOString()),
      }))
  }

  render() {
    return (
      <div>
        <Presentation
          {...this.state}
          handleChange={this.handleChange}
          handleDateChange={this.handleDateChange}
          handleSave={this.handleSave}
          handleChange2={this.handleChange2}
          handleDelete={this.handleDelete}
        />
      </div>
    )
  }
}

export default Container
