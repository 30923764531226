import React, { Component } from 'react'
import Presentation from "./Presentation"
import { Placement } from "../../../../../contexts/shared/Timesheets/Placements"
import { DataContext } from '../../../../../contexts/data'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export class Container extends Component {
    constructor(){
        super();
        this.state = {
            invoiceSettings: {}
        }
    }
    static contextType = DataContext

    componentDidMount = () => {
        if(this.props.placementID !== "")
        this.grabData(this.props.placementID)
    }

    componentWillReceiveProps = (nextProps) => {
        const { empCode, clientId, placementID } = nextProps
        console.log(placementID, this.props.placementID)
        if(placementID !== this.props.placementID && placementID !== ""){
            this.grabData(placementID)
        }
    }

    grabData = (placementID) => {
        const [state] = this.context
        const placement = state.placements_list.filter(item => item.placementID === placementID)[0]
        const placementObj = new Placement(placement.id)
        placementObj.getSettings("Invoices").then(settings => {
            this.setState({ invoiceSettings:settings  })
            console.log(settings)
        }).catch(err => console.error(err))
    }

    render() {
        const { placementID, clientId, empCode, handleChange, timesheets, range, dbSheets } = this.props
        console.log(this.state.invoiceSettings)
        if(Object.keys(this.state.invoiceSettings).length)
            return (
                <div>
                    <Presentation 
                        placementID={placementID}
                        clientId={clientId}
                        empCode={empCode}
                        handleChange={handleChange}
                        timesheets={timesheets}
                        range={range}
                        invoiceSettings = {this.state.invoiceSettings}
                        dbSheets={dbSheets}
                    />
                </div>
            ) 

        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Timesheets</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    
                </AccordionDetails>
            </Accordion>
        ) 
    }
}

export default Container
