import React from 'react'
import Validations from '../../generalcomponents/validation'
import { makeStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { DataContext } from '../../../contexts/data'
import MaterialTable from 'material-table'

export default function Presentation(props) {
  const { data } = props
  const validation = new Validations()
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }))
  const [state] = React.useContext(DataContext)
  const getNameById = (id) => {
    console.log(id)
    for (let index = 0; index < state.wikiCategories.length; index++) {
      if (state.wikiCategories[index].id === id)
        return state.wikiCategories[index].name
    }
    return 'Archived'
  }
  const classes = useStyles()
  let columns = [
    {
      title: 'Article Title',
      field: 'title',
      render: (rowData) => (
        <span>
          <Link to={'/console/wiki/' + rowData.id + '/' + rowData.index}>
            <Typography variant='body1'>{rowData.title}</Typography>
          </Link>
        </span>
      ),
    },
    {
      title: 'Category',
      field: 'category',
      render: (rowData) => (
        <span>
          <Typography>{rowData.category}</Typography>
        </span>
      ),
    },
    {
      title: 'Updated On',
      field: 'updatedAt',
      render: (rowData) => <span>{rowData.updatedAt}</span>,
    },
    {
      title: 'Updated By',
      field: 'updatedBy',
      render: (rowData) => <span>{rowData.updatedBy}</span>,
    },
  ]
  let rows = []
  data &&
    data.title.forEach((article, index) => {
      rows.push({
        title: article,
        category: getNameById(data.topicName[index]),
        updatedAt: validation.dateAndTimeFormatter(data.updatedAt[index]),
        updatedBy: validation.emailToName(data.updatedBy[index]),
        id: data.id,
        index: index,
      })
    })
  return (
    <div className={classes.paper}>
      <MaterialTable
        title='History'
        columns={columns}
        data={rows}
        options={{
          pageSize: 10,
          paginationType: 'stepped',
        }}
      />
    </div>
  )
}
