import React from 'react'
import { RiBuildingLine } from "react-icons/ri"
import { Typography } from '@material-ui/core'
import Edit from "../Edit"

function Presentation(props) {
    const { line1, line2, city, state, zipCode, country } = props.invoiceLocation
    return (
        <div className="row">
            <div className="col-10" >
                <Typography color="textSecondary" gutterBottom>
                    <RiBuildingLine size={24} />  Invoice Location
                </Typography>
                <Typography>
                {
                    [line1, line2, city, country, state, zipCode].filter(item => item !== "").join(", ")
                }
                </Typography>
            </div>
            <div className="col-2"  >
                <Edit id={props.id} invoiceLocation={props.invoiceLocation} />
            </div>
            
        </div>
    )
}

export default Presentation
