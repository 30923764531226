import React from 'react';

import Validations from '../../../generalcomponents/validation'

function Presentation(props) {
    const { reqDate} = props
  const validate = new Validations()
   {
    return (
        <span>
          { validate.dateFormatter(reqDate)}
        </span>
    );
}


}

export default Presentation;