import React, { useContext } from 'react'
import CreateInvoice from '../components/TimeSheets/Invoices/CreateInvoice/Index'
import { DataContext } from "../contexts/data"
import CustomSpinner from "../components/generalcomponents/circularSpinner"

function CreateInvoicePage(props) {
    const [state] = useContext(DataContext)
    return (
        <div>
            {
                state.hasOwnProperty("client_list") &&
                state.hasOwnProperty("placements_list") &&
                state.hasOwnProperty("timesheets_list") && 
                state.hasOwnProperty("invoices_list") ? 
                    <CreateInvoice { ...props } />
                :
                    <CustomSpinner/>
            }
            
        </div>
    )
}

export default CreateInvoicePage
