import React, { PureComponent } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../contexts/data'
import {
  SET_COUNTRY_API,
  PLACEMENT_SETTINGS_ACTIONS,
} from '../../../../../contexts/types'
import firebase from '../../../../../firebase_config'
import Axios from 'axios'

class Container extends PureComponent {
  state = {
    workLocationType: '',
    workEmail: '',
    workPhoneNumber: '',
    phoneExtension: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state_name: '',
    country: '',
    zipCode: '',
    amendmentRequired: false,
    profile: {},
    states: [],
    id: '',
    fillableSections: [],
  }

  static contextType = DataContext

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  handleChecked = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    })
  }

  getData = (id) => {
    firebase
      .firestore()
      .collection('Placements')
      .doc(id)
      .collection('WorkLocation')
      .get()
      .then((snap) => {
        let placementData = []
        placementData = snap.docs.map((doc) => doc.data())
        placementData.length &&
          this.setState({
            workLocationType: placementData[0].type,
            workEmail: placementData[0].email,
            workPhoneNumber: placementData[0].phonenumber,
            phoneExtension: placementData[0].phoneExtension,
            addressLine1: placementData[0].line1,
            addressLine2: placementData[0].line2,
            city: placementData[0].city,
            state_name: placementData[0].state,
            country: placementData[0].country,
            zipCode: placementData[0].zip,
            amendmentRequired: placementData[0].amendmentRequired,
          })
      })
  }

  componentDidMount = () => {
    const [state, dispatch] = this.context
    const { req_placement } = this.props
    dispatch({
      type: SET_COUNTRY_API,
      payload: dispatch,
    })
    if(req_placement.id) this.getData(req_placement.id)
  }

  componentWillReceiveProps = (nextProps) => {
    const { req_placement, profile } = nextProps
    const { id, fillableSections } = req_placement
    this.setState({ profile, id, fillableSections })
    if (id.length && this.props.req_placement.id !== id) this.getData(id)
  }

  handleCountryAndState = (name, value) => {
    this.setState({
      [name]: value.name,
    })
  }

  geoState = async (code) => {
    let states = await Axios.post('/loadstates', { countrycode: code })
    this.setState({ states: states.data })
  }

  handleCreate = () => {
    const dispatch = this.context[1]
    const {
      amendmentRequired,
      workLocationType,
      workEmail,
      workPhoneNumber,
      addressLine1,
      addressLine2,
      city,
      state_name,
      country,
      zipCode,
      phoneExtension,
    } = this.state
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
      payload: {
        amendmentRequired: amendmentRequired,
        city: city,
        country: country,
        email: workEmail,
        line1: addressLine1,
        line2: addressLine2,
        phonenumber: workPhoneNumber,
        phoneExtension: phoneExtension,
        state: state_name,
        zip: zipCode,
        type: workLocationType,
        placementID: this.state.id,
        sectionName: 'worklocation',
      },
      actionType: 'new',
    })
  }

  handleUpdate = () => {
    const dispatch = this.context[1]
    const {
      phoneExtension,
      amendmentRequired,
      workLocationType,
      workEmail,
      workPhoneNumber,
      addressLine1,
      addressLine2,
      city,
      state_name,
      country,
      zipCode,
      id,
    } = this.state
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      payload: {
        amendmentRequired: amendmentRequired,
        city: city,
        country: country,
        email: workEmail,
        line1: addressLine1,
        line2: addressLine2,
        phonenumber: workPhoneNumber,
        phoneExtension: phoneExtension,
        state: state_name,
        zip: zipCode,
        type: workLocationType,
        placementID: this.state.id,
        sectionName: 'worklocation',
      },
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
      actionType: 'update',
    })
  }

  handleSave = (type) => {
    if (type === 'create') this.handleCreate()
    else if (type === 'update') this.handleUpdate()
    else console.log('Error')
  }

  placementSettingsActionsWiki = () => {}

  render() {
    return (
      <div>
        <Presentation
          {...this.state}
          handleChange={this.handleChange}
          handleSave={this.handleSave}
          handleChecked={this.handleChecked}
          geoState={this.geoState}
          handleCountryAndState={this.handleCountryAndState}
        />
      </div>
    )
  }
}

export default Container
