import React, { Component } from 'react'
import Presentation from './Presentation'
import firebase from '../../firebase_config'
class Container extends Component {
    state = {
        history : [],
        isLoading : true
    }
    componentDidMount = () => {
        firebase.firestore().collection("History").orderBy("createdAt","desc").limit(15).onSnapshot(snap => {
            this.setState({
                history : snap.docs.map(doc => doc.data()),
                isLoading : false
            })
        })
    }
    render() {
        return (
            <div>
                <Presentation 
                    {...this.state}
                />
            </div>
        )
    }
}

export default Container
