import React, { Component } from 'react'
import Presentation from './Presentation'
import Axios from 'axios'
import { CircularProgress } from '@material-ui/core'
import { DataContext } from '../../../../../contexts/data';
import { Animations } from '../../../../generalcomponents/skeletonLoader.js'
export class Container extends Component {
    state={
        taskTimeLine : []
    }

    static contextType = DataContext
    componentDidMount = async() => {
       const [state] = this.context
       let result = await Axios.get(`/getTaskTimeline/${state.project.id}/${state.task.id}`)
       if(result.data.status)
        this.setState({
            taskTimeLine : result.data.response
        })
    }

    render() {
        if(this.state.taskTimeLine.length)
            return (
                <Presentation
                    {...this.state}
                />
            )
        return <div>
            <Animations />   
            <Animations />   
            </div> 
    }
}

export default Container
