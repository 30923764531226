import React, { Component } from 'react'
import Presentation from './Presentation'
import Prompt from '../../generalcomponents/snackBars'
import { DataContext } from '../../../contexts/data'
import { CHANGE_PASSWORD } from '../../../contexts/types'
import {render} from 'react-dom'
class Container extends Component {
    state={
        helperEight:true,
        helperNum:"",
        helperLower:"",
        oldPass:"",
        newPass:"",
        confirmPass:"",
        email:"",
        helperText:false,
    }

    static contextType = DataContext

    componentDidMount=()=>{
        this.setState({email:this.props.email})
    }
    handleOldPass=(e)=>{
        let value=e.target.value;
        this.setState({oldPass:value})

    }

    handleSetPass=(e)=>{
        let value=e.target.value;
        this.setState({newPass:value})
        if(value.length<8){
            return this.setState({helperEight:true})
        }
        this.setState({helperEight:false})
    }

    handleConfirmPass=(e)=>{
        let value=e.target.value;
        this.setState({confirmPass:value})
        if(value.length<8){
            return this.setState({helperEight:true})
        }
        this.setState({helperEight:false})
    }

    handleSetNewPass=()=>{
        let newPass=this.state.newPass;
        let confirmPass=this.state.confirmPass;
        const dispatch = this.context[1]
        if(newPass!==confirmPass){
            return this.setState({helperText:true})
        }
        if(newPass.length<1||confirmPass.length<1){
          return  render(
            <Prompt open={true} content="Invalid entry" severity="success" />,
            document.getElementById('notifications-box')
            )
        }
        dispatch({
            type : CHANGE_PASSWORD,
            payload : newPass,
        })

        this.setState({
            helperEight:true,
            helperNum:"",
            helperLower:"",
            oldPass:"",
            newPass:"",
            confirmPass:"",
            email:"",
            helperText:false,
        })

    }

    render() {
        return (
                <Presentation
                handleSetPass={this.handleSetPass}
                handleOldPass={this.handleOldPass}
                handleConfirmPass={this.handleConfirmPass}
                handleSetNewPass={this.handleSetNewPass}
                {...this.state}
                />
        )
    }
}

export default Container