import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import SweetAlert from "react-bootstrap-sweetalert";
import { Grid, Typography, Button } from '@material-ui/core';
import SunEditor from 'suneditor-react';

function Presentation(props) {
    const { handleVoid, id, handleChange, comments, iconBtn } = props
    const [alert, setAlert] = React.useState(null);
    const hideAlert = () => {
        setAlert(null);
    }
    const successAlert = (e, id) => {
        setAlert(
            <SweetAlert
                warning
                showCancel
                style={{ display: "block" }}
                title="Are you sure?"
                onConfirm={() => {
                    handleVoid(e, id)
                    hideAlert()
                }}
                onCancel={() => hideAlert()}
                confirmBtnText={"Submit"}
                cancelBtnText={"Cancel"}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography>You want to void the invoice</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <SunEditor
                            placeholder="Type your comment here"
                            setContents={comments}
                            onChange={(data) => handleChange(data)}
                            setOptions={{
                                "buttonList": [
                                    [
                                        "undo",
                                        "redo",
                                        "font",
                                        "fontSize",
                                        "formatBlock",
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript",
                                        "fontColor",
                                        "hiliteColor",
                                        "removeFormat",
                                        "outdent",
                                        "indent",
                                        "align",
                                        "horizontalRule",
                                        "list",
                                        "lineHeight",
                                        "table",
                                        "link",
                                        "image",
                                        "video",
                                        "showBlocks",
                                        "codeView"
                                    ]
                                ],
                                mode: 'Balloon-always'
                            }}
                        />
                    </Grid>
                </Grid>
            </SweetAlert>
        );
    }
    return (
        <div>
            {iconBtn ?
                <div className="mt-1">
                    <IconButton color="inherit" onClick={(e) => successAlert(e, id)}>
                        <CancelIcon fontSize="small" />
                    </IconButton>
                </div> :
                <Button color="inherit" onClick={(e) => successAlert(e, id)}>
                    Void
                </Button>
            }
            {alert}
        </div>
    );
}

export default Presentation;