import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../contexts/data'
import { COMMENT, DELETE_COMMENT, UPDATE_COMMENT } from '../../../../../contexts/types'


class Container extends Component {
    state = {
        commentText : '',
        commenting : false,
        isEditing : false,
        updateId : ""
    }
    static contextType = DataContext

    handleChange = (data) => {
        this.setState({commentText : data})
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({commenting:true})
        const [state, dispatch ] = this.context
        console.log('commented')
        const newComment={
            projectId:this.props.projectId,
            taskId:this.props.taskId,
            createdBy:state.employee.email,
            text:this.state.commentText,
        }
        dispatch({
            type : COMMENT,
            payload : newComment,
            response : this.response
        })
    }

    stateSet = (key, value) => {
        this.setState({
            [key] : value
        })
    }

    handleUpdate = (e) => {
        e.preventDefault()
        if(this.state.updateId){
            this.setState({commenting:true})
            const [state, dispatch ] = this.context
            console.log('commented')
            const updatedComment={
                projectId:this.props.projectId,
                createdBy:state.employee.email,
                taskId:this.props.taskId,
                id:this.state.updateId,
                text:this.state.commentText,
            }
            dispatch({
                type : UPDATE_COMMENT,
                payload : updatedComment,
                response : this.response
            })
        }
    }

    handleDelete = (id) => {
        const dispatch = this.context[1]
        console.log(this.props.projectId, this.props.taskId, id)
        if(window.confirm('Are you sure you want to delete this?')){
            dispatch({
                type : DELETE_COMMENT,
                payload : {
                    projectId:this.props.projectId,
                    taskId:this.props.taskId,
                    id:id
                }
            })

            this.setState({
                commentText:'',
                commenting : false,
                updateId:'',
                isEditing:false
            })
        }

    }

    response = () => {
        this.setState({
            commentText:'',
            commenting : false,
            updateId:'',
            isEditing:false
        })
    }

    componentWillMount = () => {
        this.setState({
            commentText:'',
            commenting : false,
        })
    }

    render() {
        return (
            <div>
                <Presentation 
                    {...this.state}
                    comments={this.props.comments}
                    projectId={this.props.projectId}
                    taskId={this.props.taskId}
                    handleChange = {this.handleChange}
                    handleSubmit = {this.handleSubmit}
                    handleUpdate={this.handleUpdate}
                    handleDelete={this.handleDelete}
                    stateSet = {this.stateSet}
                />
            </div>
        )
    }
}

export default Container
