import React, { Component } from 'react';
import { DataContext } from '../../../contexts/data'
import Presentation from './Presentation'

class Container extends Component {

    static contextType = DataContext

    render() {
        const [state] = this.context
        if(state.hasOwnProperty('company_config')) {
            return <Presentation />
        }
        return (<div><p>Loading...</p></div>)
    }
}

export default Container;