import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import { CircularProgress } from '@material-ui/core'
import {
  ADD_WIKI_CATEGORY,
  SET_WIKI_CATEGORY,
  FOLLOW_UNFOLLOW_WIKI_ARTICLE,
  VOTE_FOR_WIKI_ARTICLE,
  SET_WIKI_HISTORY,
  SET_WIKI_PAGES,
  UPDATE_WIKI_PAGE,
} from '../../../contexts/types'
import Axios from 'axios'
import { Redirect } from 'react-router-dom'

class Container extends Component {
  state = {
    historyData: {},
    articleLoad: false,
    index: '',
    redirect: false,
  }
  static contextType = DataContext

  getArticleHistory = () => {
    const [state] = this.context
    state.wikiHistory.forEach((ele) => {
      if (this.props.id === ele.id) {
        const index = this.props.index
        let historyFilter = {
          ...ele,
          topicName: ele['topicName'][index],
          articleContent: ele['articleContent'][index],
          updatedBy: ele['updatedBy'][index],
          updatedAt: ele['updatedAt'][index],
          title: ele['title'][index],
        }
        this.setState({
          historyData: historyFilter,
          articleLoad: true,
        })
        console.log(historyFilter)
      }
    })
    // this.setState({data: History[1]})
  }
  followOrUnfollowCallback = async () => {
    //const dispatch = this.context[1]
    let response = await Axios.get('/getPagesInWiki')
    const dispatch = this.context[1]
    dispatch({
      type: SET_WIKI_PAGES,
      payload: response.data.response,
    })
  }
  voteCallback = async () => {
    const dispatch = this.context[1]
    let response = await Axios.get('/getPagesInWiki')
    //const dispatch = this.context[1]
    dispatch({
      type: SET_WIKI_PAGES,
      payload: response.data.response,
    })
  }

  handleLikeAndDisLike = (e, input) => {
    e.preventDefault()
    const dispatch = this.context[1]
    dispatch({
      type: VOTE_FOR_WIKI_ARTICLE,
      voteCallback: this.voteCallback,
      payload: {
        wikiId: this.props.id,
        index: 0,
        type: input,
      },
    })
  }

  handleFollow = (e, input) => {
    e.preventDefault()
    const dispatch = this.context[1]
    dispatch({
      type: FOLLOW_UNFOLLOW_WIKI_ARTICLE,
      followOrUnfollowCallback: this.followOrUnfollowCallback,
      payload: {
        wikiId: this.props.id,
        index: 0,
        type: input,
      },
    })
  }

  componentDidMount = async () => {
    const [state, dispatch] = this.context
    const { isHistory } = this.props
    if (isHistory) {
      if (!state.hasOwnProperty('wikiHistory')) {
        let result = await Axios.get('/getWikiPagesInHistory')
        dispatch({
          type: SET_WIKI_HISTORY,
          payload: result.data.response,
        })
        this.getArticleHistory()
      } else {
        this.getArticleHistory()
      }
    } else {
      if (
        !state.hasOwnProperty('wikiPages') ||
        !state.hasOwnProperty('wikiCategories')
      ) {
        let response = await Axios.get('/getPagesInWiki')
        const dispatch = this.context[1]
        dispatch({
          type: SET_WIKI_PAGES,
          payload: response.data.response,
        })
        let res = await Axios.get('wiki/getCategories')
        dispatch({
          type: SET_WIKI_CATEGORY,
          payload: res.data.response,
        })
        // this.getArticle()
      } else {
        // this.getArticle()
      }
    }
  }

  handleChange = (e, v) => {
    this.setState({ value: v })
  }

  handleRestore = (e, category) => {
    e.preventDefault()
    const dispatch = this.context[1]
    dispatch({
      type: ADD_WIKI_CATEGORY,
      wikiAddCategoryCallBack: this.wikiAddCategoryCallBack,
      payload: {
        categoryName: category.topicName,
        description: '',
      },
    })
  }
  wikiAddCategoryCallBack = async () => {
    const dispatch = this.context[1]
    let res = await Axios.get('wiki/getCategories')
    dispatch({
      type: SET_WIKI_CATEGORY,
      payload: res.data.response,
    })
    this.setState({ redirect: true })
  }

  getNameById = (id) => {
    console.log(id)
    const [state] = this.context
    for (let index = 0; index < state.wikiCategories.length; index++) {
      if (state.wikiCategories[index].id === id)
        return state.wikiCategories[index].name
    }
    return 'Archived'
  }

  wikiUpdatePageCallBack = async () => {
    const dispatch = this.context[1]
    let response = await Axios.get('/getPagesInWiki')
    dispatch({
      type: SET_WIKI_PAGES,
      payload: response.data.response,
    })
    this.setState({ redirect: true })
  }

  handleUpdate = () => {
    const dispatch = this.context[1]
    dispatch({
      type: UPDATE_WIKI_PAGE,
      wikiUpdatePageCallBack: this.wikiUpdatePageCallBack,
      payload: {
        wikiId: this.props.id,
        topicName: this.state.historyData.topicName,
        title: this.state.historyData.title,
        articleContent: this.state.historyData.articleContent,
      },
    })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/console/wiki' />
    }
    const [state] = this.context
    if (
      state.hasOwnProperty('wikiPages') &&
      state.hasOwnProperty('wikiCategories')
    ) {
      return (
        <div>
          <Presentation
            {...this.state}
            {...this.props}
            handleUpdate={this.handleUpdate}
            handleLikeAndDisLike={this.handleLikeAndDisLike}
            handleFollow={this.handleFollow}
            handleRestore={this.handleRestore}
          />
        </div>
      )
    } else {
      return (
        <div style={{ marginTop: '15%', alignContent: 'center' }}>
          <div className='text-center'>
            <CircularProgress color='primary' />
          </div>
        </div>
      )
    }
  }
}

export default Container
