import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../contexts/data'
import { CHECK_LOGIN, CHECK_AUTH, SET_COMPANY_CONFIG } from '../../contexts/types'
import { withRouter } from 'react-router-dom'
import firebase from '../../firebase_config'
import { configuration } from '../../configuration'
import Axios from 'axios'

class Container extends Component {
    state = {
        email:'',
        password:'',
        showOverlay : true
    }

    static contextType = DataContext

    componentDidMount = () => {
        const dispatch = this.context[1]
        this.setState({ showOverlay : true })
        firebase.auth().onAuthStateChanged(user => {
            if(user) {
                localStorage.setItem("ownerUID",user.uid)
                user.getIdTokenResult().then(tokenResult => {
                    localStorage.setItem("role",tokenResult.claims.role)
                    localStorage.setItem('email', user.uid)
                    Axios.defaults.headers.common['Authorization'] = user.uid+' '+tokenResult.token
                    Axios.defaults.baseURL=configuration.REST_api
                    // to set authentication
                    dispatch({
                        type:CHECK_AUTH,
                        payload:{
                            isLoggedIn:true,
                            employee:{
                                email:user.uid,
                                role:tokenResult.claims.role,
                                companyID: user.displayName,
                            }
                        }
                    })
                })
            }
            else {
                this.props.history.push('/')
                dispatch({
                    type:CHECK_AUTH,
                    payload:{
                        isLoggedIn:false
                    }
                })
            }

            this.setState({ showOverlay : false })
        })

        firebase.firestore().collection("CompanyCustomization").doc("companydetails").onSnapshot(snap => {
            dispatch({
                type : SET_COMPANY_CONFIG,
                payload : snap.data()
            })
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    
  onLogin = (e) => {
    e.preventDefault();
    const dispatch = this.context[1]
    const { email, password } = this.state
    dispatch({
        type:CHECK_LOGIN,
        payload:{
            email,
            password
        }
    })
  }

    
    render() {
        return (
            <div>
                <Presentation
                handleChange={this.handleChange}
                onLogin={this.onLogin}
                email={this.state.email}
                password={this.state.password}
                showOverlay = {this.state.showOverlay}
                />
            </div>
        )
    }
}

export default withRouter(Container)

