import React, { Component } from 'react'
import Presentation from "./Presentation"
import { DataContext } from '../../../../../../contexts/data'
import { CLIENT_UPDATE_ACTIONS } from '../../../../../../contexts/types'
export class Container extends Component {

    constructor(props){
        super(props)
        const { businessDisplayName, businessName, category, contactNumber, email, fax, federalId, jobTerminationNotice, netTerms, status, website } = props.client
        this.state = {
            businessDisplayName,
            businessName,
            category, 
            contactNumber,
            email,
            fax,
            federalId,
            jobTerminationNotice,
            netTerms,
            status,
            website
        }
    }

    static contextType = DataContext

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onUpdate = () => {
        const dispatch = this.context[1]
        dispatch({
            type: CLIENT_UPDATE_ACTIONS,
            payload: {
                ...this.state,
                id: this.props.id
            }
        })
    }
    render() {
        return (
            <div>
                <Presentation 
                    {...this.state}
                    handleChange={this.handleChange}
                    onUpdate={this.onUpdate}
                />
            </div>
        )
    }
}

export default Container
