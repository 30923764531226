import React, { Component } from 'react'
import Presentation from './Presentation'
import firebase from '../../../../firebase_config'
import Axios from 'axios'
import { SET_COUNTRY_API } from '../../../../contexts/types'
import { DataContext } from '../../../../contexts/data'
export class Container extends Component {
    state={
        newLabelSection:"",
        newLabelName:"",
        newLabelType:""
    }

    static contextType = DataContext

    componentDidMount = () => {
        const dispatch  = this.context[1]
        dispatch({
            type : SET_COUNTRY_API,
            payload : dispatch
        })
        this.props.formData.forEach(item => {
            this.setState({
                [item.section]:{}
            })
        })

        this.setState({formData:this.props.formData})

        this.props.formData.forEach(item => {
            let portion = {...this.state[item.section]}
            item.fields.forEach(section => {
                portion[section.name] = ""
            })
            this.setState({[item.section]:portion})
        })
        
    }

    handleCustomize = () => {

    }

    handleChange = (name,value,section) => {
        let portion = {...this.state[section]}
        portion[name] = value
        console.log(name,value,section)
        this.setState({
            [section]:portion
        })
    }

    geoState = async(section, name, code, value) => {
        console.log(section, name, code)
        let formData = this.state.formData
        let states = await Axios.post("/loadstates",{countrycode:code})
        console.log(states.data)
        formData.forEach(item => {
            if(item.section === "mailingaddress"){
                item.fields.forEach((field,index) => {
                    if(field.name === "state")
                        item.fields[index]['values'] = states.data
                })
            } 
        })
        console.log(formData)
        this.setState({formData})
    }

    handleCheck = (name, value, section, index) => {
        let type = this.state.formData
        type[index].fields.forEach((item,i) => {
            if(item.name === name)
                item['isExist'] = !item['isExist']
        })
        this.setState({
            formData:type
        })
    }

    handleChange2 = e => {
        console.log(e.target.files[0])
      if (e.target.files[0]) {
        const file = e.target.files[0];
        this.fileUpload1(file)
      }
    }

    handleNewLabelChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    handleNewLabel = (e) => {
        console.log("entered")
        const section = this.state.newLabelSection
        console.log(this.state.newLabelSection, this.state.newLabelName, this.state.newLabelType)
        const newLabelName = this.state.newLabelName.replace(/ /g,'').toLowerCase()
        let sections = this.state.formData
        sections.forEach((item,index) => {
            if(item.section === section){
                if(item.fields.filter(field => field.name === newLabelName).length === 0){
                    item['fields'].push({
                        customize : true,
                        isExist : true,
                        label : this.state.newLabelName,
                        name : newLabelName,
                        type : this.state.newLabelType,
                        required : true
                    })
                    sections.forEach(item => {
                        let portion = {...this.state[section]}
                        portion[newLabelName] = ""
                        this.setState({[section]:portion})
                    })
                }
                else    
                    alert("A field already exists with the given name, please choose another name.")    
            }
        })

        this.setState({formData:sections})

        this.setState({
            newLabelSection:"",
            newLabelName:"",
            newLabelType:""
        })
    }

    handleNewLabelCancel = () => {
        this.setState({
            newLabelSection:"",
            newLabelName:"",
            newLabelType:""
        })
    }

    handleLabelsSave = () => {
        let iterables = {
            'PersonalInformation' : this.state.formData[0],
            'MailingAddress' :  this.state.formData[1],
            'EmergencyContact' : this.state.formData[2],
            'EmployementHistory' : this.state.formData[3],
            'WorkAuthorization' : this.state.formData[4]
        }                
        Object.entries(iterables).forEach(([key, value]) => {
            firebase.firestore().collection("InvitationFormBuilder")
            .doc(key)
            .update(value)
            .then(() => console.log("updated labels"))
            .catch(err => console.log(err))
        })
    }



    render() {
        console.log(this.state)
        return (
            <div>
                {
                    Object.keys(this.state).length && this.state.hasOwnProperty('formData') && this.state.formData.length ? 
                    <Presentation 
                        data={this.state}
                        handleChange = {this.handleChange}
                        geoState={this.geoState}
                        handleCheck={this.handleCheck}
                        handleNewLabelChange = {this.handleNewLabelChange}
                        handleNewLabel = {this.handleNewLabel}
                        handleNewLabelCancel={this.handleNewLabelCancel}
                        handleLabelsSave = {this.handleLabelsSave}
                        {...this.state}
                    />
                    :
                    null
                }

                
            </div>
        )
    }
}

export default Container
