import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import { SET_PROJECT, SET_TASKS } from '../../../contexts/types'
class Container extends Component {
    static contextType = DataContext
    componentDidMount = () => {
        const dispatch = this.context[1]
        dispatch({
            type:SET_TASKS,
            payload:[]
        })   
        dispatch({
            type : SET_PROJECT,
            payload : ""
        })
    }

    render() {
        return (
            <div>
                <Presentation 
                {...this.props}
                />
            </div>
        )
    }
}

export default Container
