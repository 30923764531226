import React, { useContext } from 'react'
import {
  makeStyles,
  TextField,
  Grid,
  MenuItem,
  Button,
  Chip,
  CircularProgress
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import SunEditor from 'suneditor-react'
import Validations from '../../../../generalcomponents/validation'
import NumberFormat from 'react-number-format'
import { Autocomplete } from "@material-ui/lab";
import { DataContext } from "../../../../../contexts/data"
import { FcCheckmark } from "react-icons/fc"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PlacementDetails from "../PlacementDetails"

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: '100%',
  },
}))

function CustomCurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      prefix='$'
    />
  )
}

function Presentation(props) {
  const {
    spentDate,
    departureDate,
    returnDate,
    placementID,
    description,
    expenseType,
    expenseDoc,
    amount,
    vendor,
    receipt,
    additionalDetails,
    handleChange,
    expenseActionsCallback,
    handleAdd,
    isUploading,
    actionType,
    handleUpdate
  } = props

  const [state] = useContext(DataContext)

  const validate = new Validations()
  const placementList = []
  const expenseTypes = ['Travel', 'Parking', 'Hotel', 'Meals', 'Fuel']
  const placements = state.placements_list.filter(item => item.empCode === state.employee.companyID)
  let reqFields = []
  if(expenseType === "" || ['Travel', 'Parking', 'Hotel'].includes(expenseType)){
    reqFields = ['spentDate', 'expenseType', 'amount', 'receipt', 'description', 'expenseDoc', 'vendor', 'departureDate', 'returnDate']
  }
  else{
    reqFields = ['spentDate', 'expenseType', 'amount', 'receipt', 'description', 'expenseDoc', 'vendor']
  }

  const areReqFilled = reqFields.every(item => props[item] !== "")
  return (
    <div className="custom-card" >
      <div>
        <Autocomplete
          options={placements}
          renderOption={(option) => {
            return (
              <span>
                <Chip
                  style={{
                    backgroundColor: "#f78a14",
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                  size="small"
                  label={option.placementID}
                />
              </span>
            );
          }}
          getOptionLabel={(option) => option.placementID }
          style={{ width: 200 }}
          onChange={(e, v) => {
            if (v) {
              handleChange("placementID", v.placementID);
              handleChange("clientId", v.clientId)
              handleChange("empCode", v.empCode);
            }
          }}
          renderInput={(params) => (
            <TextField required size="small" {...params} label="Select Placement" />
          )}
        />
      </div>
      <div className="mt-2" >
        {
          placementID !== "" ? <PlacementDetails placementID={placementID} /> : null
        }
        
      </div>
      <hr/>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="row">
          <div className="col-sm-2">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className='d-flex'>
                <KeyboardDatePicker
                  required={reqFields.includes("spentDate")}
                  size='small'
                  disabled={placementID === ""}
                  label='Spent Date'
                  format='MM/dd/yyyy'
                  value={spentDate ? spentDate : null}
                  onChange={(date) => handleChange("spentDate", date)}
                  name='spentDate'
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <div className="col-sm-2">
            <TextField
              label='Description'
              required={reqFields.includes("description")}
              size='small'
              value={description}
              fullWidth
              disabled={placementID === ""}
              variant='outlined'
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name='description'
            />
          </div>
          <div className="col-sm-2">
            <TextField
              select
              variant='outlined'
              required={reqFields.includes("expenseType")}
              label='Expense Type'
              size='small'
              value={expenseType}
              fullWidth
              disabled={placementID === ""}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name='expenseType'
            >
              {expenseTypes.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="col-sm-2 d-flex">
              <TextField
                disabled={expenseType === "" || placementID === ""}
                label='File upload'
                size='small'
                required={reqFields.includes("expenseDoc")}
                type="file"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant='outlined'
                onChange={(e) => handleChange(e.target.name, e.target.files[0])}
                name='expenseDoc'
              />
              <span>{ isUploading ? <CircularProgress className="mt-3" size={20} /> : ( !isUploading && expenseDoc !== "" ?  <CheckCircleIcon className="text-success mt-3" fontSize='small' /> : null )  }</span>
          </div>
          <div className="col-sm-2">
            <TextField
              label='Amount'
              size='small'
              value={amount}
              required={reqFields.includes("amount")}
              fullWidth
              disabled={placementID === ""}
              variant='outlined'
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name='amount'
              InputProps={{
                inputComponent: CustomCurrencyFormat,
              }}
            />
          </div>
          
          
        </div>

        <div  className="row" >
          <div className="col-sm-2">
              <TextField
                label='Vendor'
                size='small'
                value={vendor}
                required={reqFields.includes("vendor")}
                fullWidth
                disabled={placementID === ""}
                variant='outlined'
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                name='vendor'
              />
          </div>
          <div className="col-sm-2 mt-2">
            <TextField
              label='Receipt'
              size='small'
              required={reqFields.includes("receipt")}
              value={receipt}
              fullWidth
              disabled={placementID === ""}
              variant='outlined'
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name='receipt'
            />
          </div>
          {
            !['Meals', 'Fuel'].includes(expenseType) ?
              <>
                <div className="col-sm-2">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className='d-flex'>
                    <KeyboardDatePicker
                      required={reqFields.includes("departureDate")}
                      disabled={placementID === ""}
                      size='small'
                      style={{ marginTop: '4px', marginRight: '10px' }}
                      label='Departure Date'
                      format='MM/dd/yyyy'
                      value={departureDate ? departureDate : null}
                      onChange={(date) => handleChange("departureDate", date)}
                      name='departureDate'
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-sm-2">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className='d-flex'>
                    <KeyboardDatePicker
                      required={reqFields.includes("returnDate")}
                      disabled={placementID === ""}
                      minDate={departureDate}
                      label='Return date'
                      format='MM/dd/yyyy'
                      value={returnDate ? returnDate : null}
                      onChange={(date) => handleChange("returnDate", date)}
                      name='returnDate'
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>
            </> : null
          }
        </div>
        <br/>
        <div className="w-100">
          <SunEditor
            placeholder='Additional Details'
            setContents={additionalDetails}
            onChange={(data) => handleChange("additionalDetails", data)}
            setOptions={{
              buttonList: [
                [
                  'undo',
                  'redo',
                  'font',
                  'fontSize',
                  'formatBlock',
                  'bold',
                  'underline',
                  'italic',
                  'strike',
                  'subscript',
                  'superscript',
                  'fontColor',
                  'hiliteColor',
                  'removeFormat',
                  'outdent',
                  'indent',
                  'align',
                  'horizontalRule',
                  'list',
                  'lineHeight',
                  'table',
                  'link',
                  'image',
                  'video',
                  'showBlocks',
                  'codeView',
                ],
              ],
            }}
          />
        </div>
      </form>
      <br />
      <div className="text-center" >
        <Button disabled={isUploading || !areReqFilled} onClick={ actionType === "new" ? handleAdd : handleUpdate} type='submit' color={ actionType === "new" ? 'primary' : 'secondary'} variant='contained'>
          {
            actionType === "new" ? "Add Entry" : "Update"
          } 
        </Button>
        {' '}
        <Button onClick={expenseActionsCallback} color='default' variant='contained'>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default Presentation
