import React, { useContext, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    MenuItem,
    makeStyles,
    Button,
    Drawer
} from '@material-ui/core';
import { DataContext } from '../../../contexts/data'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox'
import Validations from '../../generalcomponents/validation'

import EditIcon from '@material-ui/icons/Edit';
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { Fab } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { FiEdit3 } from 'react-icons/fi'
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }, input: {
        display: 'none',
    },
    
}));
function Presentation(props) {
    const { handleChange,
        dealPay,
        excludeDeductions,
        payBonus,
        insurance,
        isBench,
        fixedPay,
        fixedBenchPayroll,
        dealPeriod,
        stopPayroll,
        id,
        onUpdate,
        handleCheck,
        email

    } = props
    const classes = useStyles()
    // const [state] =  React.useContext(DataContext)
    const [drawerState, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...drawerState, [anchor]: open });
    };

    const validate = new Validations()


    return (
        <div >
            <Button color="secondary" onClick={toggleDrawer("right", true)} size="large">
                <Settings />
            </Button>
            <Drawer anchor={"right"} open={drawerState["right"]} onClose={toggleDrawer("right", false)}>
                <div className="m-3" >
                    <div className="text-center" >
                        <FiEdit3 fontSize="50px" />
                        <h3>{validate.emailToName(email) + "         Payroll-Settings"}</h3>
                    </div>
                    <div >
                        {

                            <div >&nbsp;&nbsp;&nbsp;&nbsp;
                                <TextField
                                    //  required={required}
                                    fullWidth
                                    // name={name}
                                    onChange={(event) => handleChange("dealPay", event.target.value)}
                                    value={dealPay}
                                    variant="outlined"
                                    size="small"
                                    label="Deal Pay"
                                />
                            </div>

                        }
                        <div >&nbsp;&nbsp;&nbsp;
                            <TextField
                                required
                                fullWidth
                                onChange={(event) => {
                                    let row = JSON.stringify(dealPeriod)
                                    row = JSON.parse(row)
                                    row['value'] = event.target.value
                                    handleChange("dealPeriod", row)
                                }}
                                name="dealPeriod"
                                value={dealPeriod.value}
                                variant="outlined"
                                size="small"
                                label="Deal Period"
                            >
                            </TextField>
                        </div>



                        <div >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                                fullWidth
                                select
                                onChange={(event) => {
                                    let row = JSON.stringify(dealPeriod)
                                    row = JSON.parse(row)
                                    row['rangeType'] = event.target.value
                                    handleChange("dealPeriod", row)
                                }}
                                label="Deal Peroid Range"
                                size="small"
                                variant="outlined"
                                defaultValue={dealPeriod.rangeType}
                            >

                                <MenuItem value={"day"}>
                                    Day(s)
                            </MenuItem>
                                <MenuItem value={"month"}>
                                    month(s)
                            </MenuItem>
                                <MenuItem value={"year"}>
                                    year(s)
                            </MenuItem>

                            </TextField>

                        </div>

                        <div  >&nbsp;&nbsp;&nbsp;
                            <TextField
                                //  required={required}
                                fullWidth
                                // name={name}
                                onChange={(event) => {
                                    let row = JSON.stringify(fixedPay)
                                    row = JSON.parse(row)
                                    row['value'] = event.target.value
                                    handleChange("fixedPay", row)
                                }}
                                value={fixedPay.value}
                                variant="outlined"
                                size="small"
                                label={"Fixed Pay" + "/" + fixedPay.rangeType}
                            />
                        </div>


                        <div  >&nbsp;&nbsp;&nbsp;
                            <TextField
                                //  required={required}
                                fullWidth
                                // name={name}
                                onChange={(event) => {
                                    let row = JSON.stringify(insurance)
                                    row = JSON.parse(row)
                                    row['value'] = event.target.value
                                    handleChange("insurance", row)
                                }}
                                value={insurance.value}
                                variant="outlined"
                                size="small"
                                label={"Insurance" + "/" + insurance.rangeType}
                            />
                        </div>

                        <div
                        >&nbsp;&nbsp;&nbsp;
                            <TextField
                                //  required={required}
                                fullWidth
                                // name={name}
                                onChange={(event) => handleChange("payBonus", event.target.value)}
                                value={payBonus}
                                variant="outlined"
                                size="small"
                                label="Pay Bonus"
                            />
                        </div>
                        <div
                        >&nbsp;&nbsp;&nbsp;
                            <FormControlLabel
                                control={<Checkbox checked={excludeDeductions.isEnabled} onChange={(event) => {
                                    let row = JSON.stringify(excludeDeductions)
                                    row = JSON.parse(row)
                                    row['isEnabled'] = event.target.checked
                                    handleChange("excludeDeductions", row)
                                }}
                                    name="excludeDeductions" />
                                }
                                label="Exclude Deductions"
                            />
                        </div>&nbsp;&nbsp;&nbsp;
                        {
                            excludeDeductions.isEnabled ?
                                <div>
                                    <div  >
                                        <TextField
                                            //  required={required}
                                            fullWidth
                                            // name={name}
                                            onChange={(event) => {
                                                let row = JSON.stringify(excludeDeductions)
                                                row = JSON.parse(row)
                                                row['pay'] = event.target.value
                                                handleChange("excludeDeductions",row)
                                            }}
                                            value={excludeDeductions.pay}
                                            variant="outlined"
                                            size="small"
                                            label={"Exclude Deductions By"}
                                        />
                                    </div>
                                    <div  >&nbsp;&nbsp;&nbsp;
                                 <TextField
                                            //  required={required}
                                            fullWidth
                                            // name={name}
                                            onChange={(event) => {
                                                let row = JSON.stringify(excludeDeductions)
                                                row = JSON.parse(row)
                                                row['noOfPayrolls'] = event.target.value
                                                handleChange("excludeDeductions",row)
                                            }}
                                            value={excludeDeductions.noOfPayrolls}
                                            variant="outlined"
                                            size="small"
                                            label={"No: Of Payrolls"}
                                        />
                                    </div>
                                </div>
                                : null
                        }
                        <div >&nbsp;&nbsp;&nbsp;
                            <FormControlLabel
                                control={<Checkbox checked={stopPayroll} onChange={(event) => handleCheck(event)} name="stopPayroll" />}
                                label="Stop Payroll"
                            />
                        </div>
                        <div >&nbsp;&nbsp;&nbsp;
                            <FormControlLabel
                                control={<Checkbox checked={isBench} onChange={(event) => handleCheck(event)} name="isBench" />}
                                label="Mark As Bench"
                            />
                        </div>&nbsp;&nbsp;&nbsp;
                        {
                            isBench ?
                                <div  >
                                    <TextField
                                        //  required={required}
                                        fullWidth
                                        // name={name}
                                        onChange={(event) => {
                                            let row = JSON.stringify(fixedBenchPayroll)
                                            row = JSON.parse(row)
                                            row['value'] = event.target.value
                                            handleChange("fixedBenchPayroll", row)
                                        }}
                                        value={fixedBenchPayroll.value}
                                        variant="outlined"
                                        size="small"
                                        label={"Bench pay" + "/" + fixedBenchPayroll.rangeType}
                                    />
                                </div> : ""
                        }
                    </div>
                    <div className="text-center mt-3">
                        <Button
                            variant="contained"
                            color={"secondary"}
                            onClick={onUpdate}
                        >
                            Update
                        </Button>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default Presentation;