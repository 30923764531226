import React, { useContext } from 'react'
import Profile from '../components/Employee/Profile'
import { DataContext } from '../contexts/data'

const ProfilePage = () => {
    const [ state ] = useContext(DataContext)
    

    return (
        <div>
            {
                state.hasOwnProperty('employee_collection') ? 
                    <Profile employee_collection={state.employee_collection ? state.employee_collection : {}} />
                    :
                    state.employee.role !== "admin" ?
                    <p className="text-center">Your profile is loading...</p>   
                    :
                    null
            }
        </div>
    )
}

export default ProfilePage
