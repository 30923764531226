import React, { useContext } from 'react'
import Avatar from '@material-ui/core/Avatar';
import { Timeline, TimelineEvent } from "react-event-timeline";
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import AddComment from '@material-ui/icons/AddComment';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import SpeakerNotesOff from '@material-ui/icons/SpeakerNotesOff';
import SystemUpdateAlt from '@material-ui/icons/SystemUpdateAlt';
import { DataContext } from '../../../../../contexts/data';
import Validations from '../../../../generalcomponents/validation';
import { Link } from 'react-router-dom'
import { Grid } from 'semantic-ui-react';



function formatSubject(item){ 
    const validate = new Validations()
    switch(item.type){
      case "createTask":return <span> <Link to={"/console/projects/"+item.ActionBy}>{validate.emailToName(item.ActionBy)}</Link> created this task. </span>

      case "updateTask":return <span><Link to={"/console/employeelist/"+item.ActionBy}>{validate.emailToName(item.ActionBy)}</Link> updated this task.</span>
                            
      case "newComment":return <span><Link to={"/console/employeelist/"+item.ActionBy}>{validate.emailToName(item.ActionBy)}</Link> added comment.</span>
                              
      case "updateComment":return <span><Link to={"/console/employeelist/"+item.ActionBy}>{validate.emailToName(item.ActionBy)}</Link> updated comment.</span>
                             
      case "deleteComment":return <span><Link to={"/console/employeelist/"+item.ActionBy}>{validate.emailToName(item.ActionBy)}</Link> deleted the comment.</span>
                           
      case "createSubTask":return <span><Link to={"/console/employeelist/"+item.ActionBy}>{validate.emailToName(item.ActionBy)}</Link> created the nested task.</span>

      default : return <span>-----------------</span>
                                         
    }
}

function formatTitle(item){
    const validate = new Validations()
    switch(item.type){
        case "createTask":return <span> {validate.emailToName(item.ActionBy)}</span>
  
        case "updateTask":return <span> {validate.emailToName(item.ActionBy)}</span>
                              
        case "newComment":return <span> {validate.emailToName(item.ActionBy)}</span>
                                
        case "updateComment":return <span>{validate.emailToName(item.ActionBy)}</span>
                               
        case "deleteComment":return <span> {validate.emailToName(item.ActionBy)}</span>

        case "createSubTask":return <span> {validate.emailToName(item.ActionBy)}</span>
                             
        default : return <span> {validate.emailToName(item.ActionBy)}</span>
                                           
      }
}

function formatIcon(item){
    const validate = new Validations()
    switch(item.type){
        case "createTask":return <PlaylistAdd fontSize='medium' />
  
        case "updateTask":return <PlaylistAddCheck fontSize='medium' />
                              
        case "newComment":return <AddComment fontSize='medium' />
                                
        case "updateComment":return <SystemUpdateAlt fontSize='medium' />
                               
        case "deleteComment":return <SpeakerNotesOff fontSize='medium' />
                             
        default : return <PauseCircleFilledIcon fontSize='medium' />
                                           
      }
}


function Presentation(props) {
    const { taskTimeLine } = props
    const [state] = useContext(DataContext)
    const validate = new Validations()
    
    return (
        <div>
            <Timeline style={{fontSize:'14px', width:'100%'}} >
                {
                    taskTimeLine.map(item => {
                        return(
                            <TimelineEvent
                                style={{width:'100%', padding:'10px'}}
                                // title={formatTitle(item)}
                                createdAt={validate.dateAndTimeFormatter(item.createdAt)}
                                icon = {formatIcon(item)}
                            >
                                { formatSubject(item) }
                            </TimelineEvent>
                        )
                    })
                }
            </Timeline>
            
        </div>
    )
}

export default Presentation
