import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import { UPDATE_PROFILE } from '../../../contexts/types'
import Validations from '../../generalcomponents/validation'

class Container extends Component {

    state={
        emergencycontactData:[],
        name:"",
        phone:"",
        emailid:"",
        editSection:[]
    }

    static contextType = DataContext

    componentDidMount=()=>{
        const { emergencycontactData }= this.props
        if(emergencycontactData)
        for(let i=0;i<emergencycontactData.length;i++){
            if(emergencycontactData.length === 0)
                continue
            emergencycontactData[i]['id']=i+1
        }
        this.setState({
            emergencycontactData
        })
        this.props.handleUpdateProfile("emergency",emergencycontactData)
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.emergencycontactData !== this.props.emergencycontactData){
            this.setState({
                emergencycontactData:nextProps.emergencycontactData
            })
            this.props.handleUpdateProfile("emergency",nextProps.emergencycontactData)
        }
    }

    handleChange = (key, value) => {
        const validate = new Validations()
        if(key === "emailid")
            this.setState({
                [key]:value.toLowerCase()
            })
        else if(key === "dob" && !isNaN(Date.parse(value)))
            this.setState({
                [key]:value.toISOString()
            })
        else if(key !== "dob")
            this.setState({
                [key]:validate.nameFormatterToUpperCase(value)
            })
    }

    handleAdd = (e) =>{
        e.preventDefault()
        const dispatch = this.context[1]
        let data = this.state.emergencycontactData
        const appendData={
            emailid:this.state.emailid,
            name:this.state.name,
            phone:this.state.phone,
            id:data.length+1
        }


        data = [...data,appendData]
        
        let finalData = {}
        finalData['emergencycontact'] = data
        dispatch({
            type : UPDATE_PROFILE,
            payload : {
                email:this.props.email,
                updateValue : finalData
            }
        })
        // this.props.handleUpdateProfile("emergency",data)
        this.setState({
            emergencycontactData:data,
            emailid:"",
            phone:"",
            name:""
        })
    }
    

    handleDelete = (index) =>{
        const dispatch = this.context[1]
        let arr = this.state.emergencycontactData
        arr.splice(index,1)
        let finalData = {}
        finalData['emergencycontact'] = arr
        dispatch({
            type : UPDATE_PROFILE,
            payload : {
                email:this.props.email,
                updateValue : finalData
            }
        })
        // this.props.handleUpdateProfile("emergency",arr)
        this.setState({emergencycontactData:arr})
    }

    handleEdit = (item) =>{
        this.setState({editSection:item})
        this.setState({
            emailid:item.emailid,
            phone:item.phone,
            name:item.name,
        })
    }

    handleExisting = (index,item) =>{
        const dispatch = this.context[1]
        let arr = this.state.emergencycontactData
        let changedItem={
            name:this.state.name  ,
            phone:this.state.phone ,
            emailid:this.state.emailid 
        }
        console.log(arr, changedItem)
        arr[index] = changedItem
        this.setState({
            emergencycontactData:arr,
            emailid:"",
            phone:"",
            name:"fine"
        })    

        let finalData = {}
        finalData['emergencycontact'] = arr
        dispatch({
            type : UPDATE_PROFILE,
            payload : {
                email:this.props.email,
                updateValue : finalData
            }
        })
        // this.props.handleUpdateProfile("emergency",arr)
    }

    clearValues=()=>{
        this.setState({
            emailid:"",
            phone:"",
            name:""
        })
    }

    
    render() {
        console.log(this.state)
        return (
            <div>
                {
                    this.state.emergencycontactData ? 
                    <Presentation
                        {...this.state}
                        handleAdd={this.handleAdd}
                        handleChange={this.handleChange}
                        onOpenModal={this.onOpenModal}
                        onCloseModal={this.onCloseModal}
                        handleDelete={this.handleDelete}
                        handleEdit={this.handleEdit}
                        handleExisting={this.handleExisting}
                        isEditing={this.props.isEditing}
                        clearValues={this.clearValues}
                    />
                    :
                    <p>Loading ...</p>
                }
                
            </div>
        )
    }
}

export default Container
