import React, { Component } from 'react'
import Presentation from "./Presentation"
import { DataContext } from '../../../../../../../contexts/data'
import { CLIENT_LOCATION_ACTIONS } from '../../../../../../../contexts/types'
export class Container extends Component {
    constructor(props){
        super(props)
        const { locationsline1, locationsline2, locationscity, locationsstate_name, locationscountry, locationszip } = props.location
        this.state={
            locationsline1,
            locationsline2,
            locationscity,
            locationsstate_name,
            locationscountry,
            locationszip
        }
    }

    static contextType = DataContext

    handleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    handleUpdate = () => {
        const dispatch = this.context[1]
        dispatch({
            type: CLIENT_LOCATION_ACTIONS,
            payload:{
                ...this.state,
                locationId: this.props.location.id,
                clientId: this.props.clientId
            },
            actionType: 'edit'
        })
    }

    handleAdd = () => {
        const dispatch = this.context[1]
        dispatch({
            type: CLIENT_LOCATION_ACTIONS,
            payload:{
                ...this.state,
                clientId: this.props.clientId
            },
            actionType: 'new'
        })
    }

    render() {
        return (
            <div>
                <Presentation 
                    {...this.state}
                    edit={this.props.edit}
                    btnContent={this.props.btnContent}
                    handleUpdate={this.handleUpdate}
                    handleChange={this.handleChange}
                    handleAdd={this.handleAdd}
                />
            </div>
        )
    }
}

export default Container
