import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'

class Container extends Component {
    state = {
    }

    static contextType = DataContext

    componentDidMount(){
        
    }
    render() {
        return (
            <div>
                <Presentation 
                    handleEmployees={this.props.handleEmployees}
                    inProject = {this.props.inProject}
                    projectMembers = {this.props.projectMembers}
                />
            </div>
        )
    }
}

export default Container
