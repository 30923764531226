import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import { SET_WIKI_CATEGORY, SET_WIKI_PAGES } from '../../../contexts/types'
import { CircularProgress } from '@material-ui/core'
import Axios from 'axios'

class Container extends Component {
  state = {
    value: 0,
    CategorieNames: [],
    uniqCategorieNames: [],
    Categories: [[]],
    articleLoad: false,
    categoryLoad: false,
  }
  static contextType = DataContext

  sortArticles = () => {
    const [state] = this.context
    let CategorieNames = []
    let uniqCategorieNames = []
    let substitute = []
    uniqCategorieNames[0] = '815616c0-e573-4383-a724-57eb19088299'
    uniqCategorieNames[1] = 'ab110f11-ee6a-4fa9-89dc-70250b61f8f8'
    uniqCategorieNames[2] = 'Archived'
    state.wikiPages.forEach((element) => {
      CategorieNames.push(element.topicName)
    })
    substitute = [...new Set(CategorieNames)].sort()
    substitute.forEach((element) => {
      if (
        element !== '815616c0-e573-4383-a724-57eb19088299' &&
        element !== 'ab110f11-ee6a-4fa9-89dc-70250b61f8f8'
      ) {
        uniqCategorieNames.push(element)
      }
    })
    let archived = []
    let categories = state.wikiCategories.map((ele) => {
      return ele.id
    })
    state.wikiPages.forEach((ele) => {
      if (!categories.includes(ele.topicName)) {
        archived.push(ele)
      }
    })
    var temp = []
    for (let index = 0; index < uniqCategorieNames.length; index++) {
      temp[index] = []
    }
    uniqCategorieNames.forEach((element) => {
      state.wikiPages.forEach((ele) => {
        if (ele.topicName === element) {
          temp[uniqCategorieNames.indexOf(element)].push(ele)
        }
      })
    })
    temp[2] = archived
    this.setState({
      uniqCategorieNames: uniqCategorieNames,
      Categories: temp,
      articleLoad: true,
    })
  }

  getCategories = (data) => {
    const [state] = this.context
    let CategorieNames = []
    CategorieNames[0] = '815616c0-e573-4383-a724-57eb19088299'
    CategorieNames[1] = 'ab110f11-ee6a-4fa9-89dc-70250b61f8f8'
    CategorieNames[2] = 'Archived'
    let temp = []
    state.wikiCategories.forEach((element) => {
      temp.push(element.id)
    })
    temp.sort()
    temp.forEach((element) => {
      if (
        element !== '815616c0-e573-4383-a724-57eb19088299' &&
        element !== 'ab110f11-ee6a-4fa9-89dc-70250b61f8f8'
      ) {
        CategorieNames.push(element)
      }
    })
    this.setState({
      CategorieNames: CategorieNames,
      categoryLoad: true,
    })
  }

  componentDidMount = async () => {
    const [state, dispatch] = this.context
    if (
      !state.hasOwnProperty('wikiCategories') ||
      !state.hasOwnProperty('wikiPages')
    ) {
      let response = await Axios.get('/getPagesInWiki')
      dispatch({
        type: SET_WIKI_PAGES,
        payload: response.data.response,
      })
      let res = await Axios.get('wiki/getCategories')
      dispatch({
        type: SET_WIKI_CATEGORY,
        payload: res.data.response,
      })
      this.getCategories()
      this.sortArticles()
    } else {
      this.sortArticles()
      this.getCategories()
    }
  }

  handleChange = (e, v) => {
    this.setState({ value: v })
  }

  render() {
    if (this.state.articleLoad && this.state.categoryLoad) {
      return (
        <div>
          <Presentation {...this.state} handleChange={this.handleChange} />
        </div>
      )
    } else {
      return (
        <div style={{ marginTop: '15%', alignContent: 'center' }}>
          <div className='text-center'>
            <CircularProgress color='primary' />
          </div>
        </div>
      )
    }
  }
}

export default Container
