import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
import { UPDATE_ACCESS_LEVELS, REMOVE_MEMBER } from '../../../../contexts/types'
class Container extends Component {
    state = {
        assignees : [],
        initialAssignee : [],
    }
    static contextType = DataContext

    componentDidMount = () => {
        const [ state ] = this.context
        let id = state.project.id
        console.log(state.projectList.filter(project => project.id === id)[0].Users)
        this.setState({
            assignees:state.projectList.filter(project => project.id === id)[0].Users,
            initialAssignee : state.projectList.filter(project => project.id === id)[0].Users
        })
    }

    handleUpdateLevels = (uid) => {
        console.log(uid)
        let level = this.state.assignees.filter(assignee => uid === assignee.uid)[0]
        const [state, dispatch] = this.context
        dispatch({
            type : UPDATE_ACCESS_LEVELS,
            payload : {
                data : {
                    uid : uid,
                    newAccessLevel : {
                        create : level.create,
                        update : level.update,
                        read : level.read,
                        delete : level.delete,
                        projectId : state.project.id
                    }
                }
            }
        })
    }

    UNSAFE_componentWillReceiveProps = () => {
        const [state] = this.context
        let id = state.project.id
        console.log(state.projectList.filter(project => project.id === id)[0].Users)
        this.setState({
            assignees:state.projectList.filter(project => project.id === id)[0].Users,
            initialAssignee : state.projectList.filter(project => project.id === id)[0].Users
        })
    }
    handleRemove = (uid) => {
        const [ state, dispatch ] = this.context
        dispatch({
            type : REMOVE_MEMBER,
            payload : {
                data : {
                    projectId : state.project.id,
                    employeeUID : uid
                },
                dispatch : dispatch
            }
        })
    }


    handleLevelChange = (uid, permission, key) => {
        console.log(uid, permission)
        const accessLevels = this.state.assignees
        accessLevels.forEach((access, index) => {
            if(access.uid === uid){
                console.log(access)
                accessLevels[index][key] = !permission
            }
        })
        this.setState({assignees : accessLevels})
    }
    render() {
        return (
            <div>
                <Presentation 
                    {...this.state}
                    handleLevelChange = {this.handleLevelChange}
                    handleUpdateLevels={this.handleUpdateLevels}
                    handleRemove={this.handleRemove}
                />
            </div>
        )
    }
}

export default Container
