import React, { Component } from 'react'
import Presentation from './Presentation'
import {
  DataContext,
  successMsg,
  waitingMsg,
  stopWaitMsg,
  errorMsg,
} from '../../../../../contexts/data'
import Axios from 'axios'
import CircularSpinner from '../../../../generalcomponents/circularSpinner'
import { Redirect } from 'react-router-dom'

class Container extends Component {
  state = {
    index: '',
    showLoader: true,
    responseData: [],
    redirect: false,
  }

  static contextType = DataContext

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  componentDidMount() {
    this.loadData()
    this.setState({ showLoader: true })
  }

  loadData = async () => {
    const response = await Axios.get('/getModuleAccessOfEmployees')
    const result = await response.data
    this.setState({ responseData: result.response, showLoader: false })
  }
  
  handleFinish = (docId) => {
    waitingMsg('Placement creating ...')
    Axios.post('/completeTimesheetsProcess', { placementID: docId }).then(
      (res) => {
        stopWaitMsg()
        console.log(res)
        if (res.data.status) {
          successMsg(res.data.message)
          this.setState({ redirect: true })
        } else errorMsg(res.data.error)
      }
    )
  }

  render() {
    const [state] = this.context
    const { redirect } = this.state
    if (redirect) return <Redirect to={'/console/placements'} />
    if (
      state.hasOwnProperty('projectList') &&
      state.hasOwnProperty('client_list') &&
      state.hasOwnProperty('placements_list') &&
      !this.state.showLoader
    )
      return (
        <div>
          <Presentation
            {...this.props}
            {...this.state}
            handleChange={this.handleChange}
            handleFinish={this.handleFinish}
          />
        </div>
      )
    return <CircularSpinner />
  }
}

export default Container
