import React, { Component } from "react";
import Presentation from "./Presentation";
import { DataContext } from "../../../../../contexts/data";
import CustomSpinner from "../../../../generalcomponents/circularSpinner";
import { addDays, differenceInCalendarDays } from "date-fns";
import { INVOICE_ACTIONS } from "../../../../../contexts/types";
import { Redirect } from "react-router-dom"
import { calcTotalAmount, calcTotalTime } from "../ServiceSummary/Presentation";
import { CalcGrandTotal } from "../Discount/Presentation";
import DiscountContainer from "../Discount/Container"

export class Container extends Component {
  static contextType = DataContext;

  
  constructor(props){
    super(props);
    this.initialState = {
        invoiceBy: "",
        clientId: "",
        empCode: "",
        placementID: "",
        external_name: "",
        external_address: "",
        timesheetsStartDate: "",
        timesheetsEndDate: "",
        timesheetsRange: "",
        timesheets: [],
        dbSheets: [],
        discountDetails: [{
          name: "",
          value: 0,
          type: "",
        }],
        externalAmountName: '',
        netTerms: 0,
        POnumber: "",
        invoiceDate: "",
        invoiceDueDate: "",
        subTotal: 0,
        notesDetails: {
          notes: "",
          statementsMemo: "",
          invoiceCategory: "",
          attachment: "",
          payableTo: "",
          additionalInfo: "",
          includeInvoicePDF: false,
        },
        outbox: {
          to: [],
          cc: [],
          bcc: [],
          subject: '',
          body: '',
          attachment: ''
      },
      redirect: false,
      invoiceSettings: {},
      paymentsInfo: {},
      selectedExpenses: [],
      dbExpenses: []
    }
    

    this.state = this.initialState
  }
  

  componentDidMount = () => {
    const { invoiceId } = this.props.match.params
    console.log(this.props)
    if(invoiceId !== "newinvoice"){
      const [state] = this.context
      const invoice = state.invoices_list.filter(item => item.id === invoiceId)[0]
      const unwantedKeys = ['createdAt', 'createdBy', 'updatedAt', 'updatedBy']
      // Object.entries(invoice).filter(item => !unwantedKeys.includes(item.key)).forEach(([key, value]) => {
      //   if(key === "startDate")
      //     initialState["startDate"]= value
      //   else if(key === "endDate")
      //     initialState["endDate"]= value
      //   else  
      //     initialState[key]= value
      // })
      console.log(invoice)
        this.setState({
          invoiceBy: invoice['invoiceBy'],
          clientId: invoice['clientId'],
          empCode: invoice['empCode'],
          placementID: invoice['placementID'],
          external_name: "",
          external_address: "",
          timesheetsStartDate: "",
          timesheetsEndDate: "",
          timesheetsRange: "",
          timesheets: invoice['timesheets'],
          dbSheets: invoice['timesheets'],
          discountDetails: invoice['discountDetails'],
          netTerms: invoice['netTerms'],
          POnumber: invoice['POnumber'],
          invoiceDate: invoice['invoiceDate'],
          invoiceDueDate: invoice['invoiceDueDate'],
          subTotal: invoice['subtotal'],
          notesDetails: invoice['notesDetails'],
          outbox: invoice['outbox'],
          invoiceId: invoice['id'],
          selectedExpenses: invoice['selectedExpenses'],
          dbExpenses: invoice['selectedExpenses'],
          redirect: false
      })
    }
  }

  handleChange = (key, value) => {
    if (key === "timesheetsRange") {
      this.setState({
        timesheetsStartDate: value.startDate,
        timesheetsEndDate: value.endDate,
      });
      if ([value.startDate, value.endDate].includes(""))
        this.setState({
          subTotal: 0,
        });
    } else if (key === "invoiceDate") {
      this.setState({
        invoiceDate: new Date(value).toISOString(),
        invoiceDueDate: addDays(new Date(value), this.state.netTerms),
      });
    } else if (key === "invoiceDueDate") {
      this.setState({
        netTerms: differenceInCalendarDays(
          new Date(value),
          new Date(this.state.invoiceDate)
        ),
        invoiceDueDate: new Date(value).toISOString(),
      });
    } else if (key === "netTerms" && !isNaN(Date.parse(this.state.invoiceDate))) {
        this.setState({
          netTerms: value,
          invoiceDueDate: addDays(new Date(this.state.invoiceDate), value)
        })
    }else if(["invoiceBy", "placementID", "clientId"].includes(key) && this.state[key] !== ""){
      console.log(this.state[key])
      if(key === "invoiceBy")
        this.setState({
          ...this.initialState,
          [key]: value
        })
      else if(key === "clientId")  
        this.setState({
          ...this.initialState,
          invoiceBy: this.state.invoiceBy,
          [key]: value
        })  
      else if(key === "placementID")  
      this.setState({
        ...this.initialState,
        clientId: this.state.clientId,
        invoiceBy: this.state.invoiceBy,
        [key]: value
      })
    }else if(key === "subTotal"){
      this.setState({
        subTotal: Number(value)
      })
    }else if(key === "timesheets"){
      const discountObj = new DiscountContainer()
      this.setState({timesheets: value.sort((a, b) => a.endDate - b.endDate )})
      this.setState({subTotal:discountObj.calculateSubTotal(value, this.state.paymentsInfo) + this.state.selectedExpenses.reduce((initial, item) => initial + Number(item.amount), 0)})
    }else if(key === "selectedExpenses"){
      const discountObj = new DiscountContainer()
      this.setState({selectedExpenses: value.sort((a, b) => a.spentDate - b.spentDate )})
      this.setState({subTotal:discountObj.calculateSubTotal(this.state.timesheets, this.state.paymentsInfo) + value.reduce((initial, item) => initial + Number(item.amount), 0)})
    } else
      this.setState({
        [key]: value,
      });
  };

  onCreateInvoice = () => {
    const {
      invoiceBy,  
      clientId,
      empCode,
      placementID,
      timesheetsStartDate,
      timesheetsEndDate,
      timesheets,
      discountDetails,
      netTerms,
      POnumber,
      invoiceDate,
      invoiceDueDate,
      subTotal,
      notesDetails,
      outbox,
      selectedExpenses
    } = this.state;
    const [state, dispatch] = this.context
    dispatch({
        type: INVOICE_ACTIONS,
        payload: {
            invoiceBy,
            clientId,
            empCode,
            placementID,
            startDate: timesheetsStartDate,
            endDate: timesheetsEndDate,
            timesheets,
            discountDetails,
            netTerms,
            POnumber,
            invoiceDate,
            invoiceDueDate,
            subTotal,
            notesDetails,
            outbox,
            selectedExpenses,
            placementDocId: state.placements_list.filter(item => item.placementID === placementID)[0].id
        },
        actionType: 'new',
        invoiceId: 'invoice',
        invoiceCallback: this.invoiceCallback
    })


  };

  onUpdateInvoice = () => {
    const {
      invoiceBy,  
      clientId,
      empCode,
      placementID,
      timesheetsStartDate,
      timesheetsEndDate,
      timesheets,
      discountDetails,
      netTerms,
      POnumber,
      invoiceDate,
      invoiceDueDate,
      subTotal,
      notesDetails,
      outbox,
      invoiceId,
      selectedExpenses
    } = this.state;
    const dispatch = this.context[1]
    dispatch({
        type: INVOICE_ACTIONS,
        payload: {
            invoiceBy,
            clientId,
            empCode,
            placementID,
            startDate: timesheetsStartDate,
            endDate: timesheetsEndDate,
            timesheets,
            discountDetails,
            netTerms,
            POnumber,
            invoiceDate,
            invoiceDueDate,
            subTotal,
            notesDetails,
            outbox,
            selectedExpenses
        },
        actionType: 'edit',
        invoiceId,
        invoiceCallback: this.invoiceCallback
    })

  }

  invoiceCallback = (actionType) => {
    this.setState({ redirect: true })
  }

  render() {
    const [state] = this.context;
    if(this.state.redirect){
      return <Redirect to="/console/invoiceslist" />
    }
    return (
      <div>
        <Presentation 
          {...this.state} 
          handleChange={this.handleChange} 
          onCreateInvoice={this.onCreateInvoice}
          onUpdateInvoice={this.onUpdateInvoice}
          invoiceId={this.props.match.params.invoiceId}
      />
      </div>
    );
  }
}

export default Container;
