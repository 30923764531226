import React, { useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import {
    Column,
    FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedPaging, IntegratedSelection, IntegratedSorting,
    PagingState, SelectionState, SortingState, DataTypeProvider, DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import {
    DragDropProvider,
    Grid, GroupingPanel, PagingPanel,
    Table, TableFilterRow, TableGroupRow,
    TableHeaderRow, TableSelection, Toolbar, TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import { IoMdPersonAdd } from 'react-icons/io'

import { makeStyles } from '@material-ui/core/styles';


import { Chip, Badge, Tooltip } from '@material-ui/core';


import Checkbox from '@material-ui/core/Checkbox';
import { DataContext } from '../../../../contexts/data'

import { Link } from 'react-router-dom'
import { GoMailRead } from 'react-icons/go'
import { MdDelete } from 'react-icons/md'
import Button from '@material-ui/core/Button';
import { Avatar, TablePagination, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import Validations from '../../../generalcomponents/validation';

//const sales = generateRows({ columnValues: globalSalesValues, length: 1000 });


export default function Presentation(props) {
    const { clients, isLoading } = props
    const [state] = React.useContext(DataContext)
    const getActiveConsultants = (clientId) => {
        const activeConsultants = state.placements_list.filter(item => item.clientId === clientId).map(item => item.empCode)
        return activeConsultants.map(empCode => state.names.filter(name => name.companyID === empCode)[0])
    }
    const validate = new Validations()
    const columns = [
        { title: 'Business name', name: 'businessName' },
        {
            title: 'Client ID', name: 'clientId',
        },
        { title: 'Net terms', name: 'netTerms' },
        { title: 'Termination Notice', name: 'jobTerminationNotice' },
        { title: 'Active consultants', name: 'activeConsultants' },
        { title: 'Actions', name: '' },
    ]

    let data = clients.map(client => {
        return {
            businessName: client.businessName,
            clientId: client.clientId,
            netTerms: client.netTerms,
            jobTerminationNotice: client.jobTerminationNotice,
            status: client.status,
            activeConsultants: getActiveConsultants(client.clientId)
        }
    })


    const [pageSizes] = React.useState([5, 10, 15]);
    const [ClientId] = React.useState(['clientId'])
    const [ActiveConsultants] = React.useState(['activeConsultants'])
    const ClientIdProvider = (props) => (
        <DataTypeProvider
            formatterComponent={ClientIdFormatter}

            {...props}
        />
    )
    const ActiveConsultantsProvider = (props) => (
        <DataTypeProvider
            formatterComponent={ActiveConsultantsFormatter}

            {...props}
        />
    )
    const ClientIdFormatter = (props) => {

        let row = props.row

        return (

            row.status === "Active" ?
                <Link to={"/console/clientslist/" + row.clientId} >
                    <Chip className="bg-success text-light c-pointer" label={row.clientId} />
                </Link> :
                <Chip className="bg-danger text-light" label={row.clientId} />
        )
    }
    const ActiveConsultantsFormatter = (props) => {

        let row = props.row

        return (

            <Badge color="primary" showZero={true} badgeContent={row.activeConsultants.length} />
        )
    }
    return (
        <div>
            <div className="text-right m-2">
                <Link to={'/console/newclient'}>
                    <Button
                        variant="contained"
                        color="primary"
                    >
                        Add New Client
                    </Button>
                </Link>
            </div>
            <Paper>

                <Grid
                    rows={data}
                    columns={columns}
                >
                    <FilteringState
                    //  defaultFilters={[{ columnName: 'saleDate', value: '2016-02' }]}
                    />
                    <SortingState
                    /*  defaultSorting={[
                        { columnName: 'product', direction: 'asc' },
                        { columnName: 'saleDate', direction: 'asc' },
                      ]}*/
                    />

                    <SelectionState />

                    <GroupingState
                    //   defaultGrouping={[{ columnName: 'product' }]}
                    //  defaultExpandedGroups={['EnviroCare Max']}
                    />
                    <PagingState />

                    <IntegratedGrouping />
                    <IntegratedFiltering />
                    <IntegratedSorting />
                    <IntegratedPaging />
                    <IntegratedSelection />
                    <Table
                    //columnExtensions={tableColumnExtensions}
                    />
                    <ClientIdProvider for={ClientId} />

                    <ActiveConsultantsProvider for={ActiveConsultants} />

                    <DragDropProvider />

                    <Table />


                    <TableHeaderRow showSortingControls={true} />
                    <TableFilterRow showFilterSelector={true} />
                    <PagingPanel pageSizes={pageSizes} />

                    <TableGroupRow />
                    <Toolbar />
                    <GroupingPanel showSortingControls={true} />
                </Grid>
            </Paper>
        </div>
    );
};
