import React, {useContext} from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core'
import { DataContext } from '../../../contexts/data'
import EditDetails from '../EditDetails'

export default function Presentaion(params) {
    
    const [state] = useContext(DataContext)
    console.log(state.company_config)

    const useStyles = makeStyles(theme => ({
        root: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
            flexGrow: 1
        },
        paper: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
        },
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item  sm={4} xs={12}>
                    <div style={{height:'350px'}} className="custom-card">
                        <div className='text-center mt-5' >
                            <img alt='' src={state.company_config.images.companylogo} height='50%' width='60%' />
                        </div>
                    </div>
                </Grid>
                <Grid item  sm={8} xs={12}>
                    <div className="custom-card">
                        <EditDetails />
                        <h3 ><u>Company Details:</u></h3>
                        <br />
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <p>Client</p>
                                <p>Web-URL</p>
                                <p>E-VerifyNumber</p>
                                <p>EIN-Number</p>
                            </div>
                            <div style={{ marginLeft: 20 }}>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                            </div>
                            <div style={{ marginLeft: 40 }}>
                                <p>{state.company_config.companyname}</p>
                                <p>{state.company_config.company_url}</p>
                                <p>{state.company_config.contactdetails.eVerifyNo}</p>
                                <p>{state.company_config.contactdetails.einNo}</p>
                            </div>
                        </div>
                        <br />
                        <h3 ><u>Contact Details:</u></h3>
                        <br />
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <p>Address</p>
                                <p>Phone</p>
                                <p>Mail-Id</p>
                                <p>AccountsMail-Id</p>
                                <p>HRMail-ID</p>
                                <p>Water mark</p>
                            </div>
                            <div style={{ marginLeft: 20 }}>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                            </div>
                            <div style={{ marginLeft: 40 }}>
                                <p>{state.company_config.contactdetails.address}</p>
                                <p>{state.company_config.contactdetails.phoneno}</p>
                                <p>{state.company_config.contactdetails.mailId}</p>
                                <p>{state.company_config.contactdetails.accountsMailId}</p>
                                <p>{state.company_config.contactdetails.hrMailId}</p>
                                <p><img src={state.company_config.images.watermark} height="70px" /></p>
                            </div>
                        </div>
                        <br />
                        <h3 ><u>Invoices Details:</u></h3>
                        <br />
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <p>InvoicePrefix</p>
                                <p>Seperator</p>
                                <p> Begin from </p>
                                <p> Payable To </p>
                                <p> Invoice AutoSend  </p>
                                <p>Invoice AutoGenerate  </p>
                               
                            </div>
                            <div style={{ marginLeft: 20 }}>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                               
                            </div>
                            <div style={{ marginLeft: 40 }}>
                                <p>{state.company_config.invoiceDetails.invoiceNumberFormat.invoicePrefix}</p>
                                <p>{state.company_config.invoiceDetails.invoiceNumberFormat.seperator}</p>
                                <p>{state.company_config.invoiceDetails.invoiceNumberFormat.beginFrom}</p>
                                <p>{state.company_config.invoiceDetails.payableTo}</p>
                                 <p>{state.company_config.invoiceDetails.invoiceAutoSend ? "Yes" : "No" }</p>
                                 <p>{state.company_config.invoiceDetails.invoiceAutoGenerate ? "Yes" : "No"}</p>
                               
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}