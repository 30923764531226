import React from 'react';
import InvoicesSort from "../components/TimeSheets/Invoices/InvoicesSort";

function InvoicesListPage(props) {
    return (
        <div>
            <InvoicesSort listAll={true}/>
        </div>
    );
}

export default InvoicesListPage;