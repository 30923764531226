import React, { useContext } from 'react';
import MaterialTable from 'material-table';
import {DataContext} from '../../../../contexts/data'
import { configuration } from '../../../../configuration'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { Link } from 'react-router-dom'
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        width: 'auto'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function Presentation(props) {
    const [state] =useContext(DataContext)
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const projectId = props.projectId
    const project =state.projectList.filter( item => item.id === projectId)[0]
    
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
       

    };
   
       const columns = [
            { title: 'Label', field: 'label' },
            { title: 'Color', field: 'color',
          render : rowData => <span className="p-2 rounded" style={{backgroundColor:`${rowData.color}`}}>
                            {
                                
                                rowData.color
                            }
              </span>
        },

        ]
        
  let labels =project.labels
  let data =[]
  labels && labels.forEach(e => {
    data.push({
      label: e.name,
      color: e.colorCode,
      id:e.id,
     
    })
  })
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState('md');
  const {updateLabel,createNewLabel,deleteLabel} = props
    return (
        <div>
         
            <MaterialTable 
                title="Labels"
                columns={columns}
                data={data}
                editable={{
                    
                    onRowAdd: (newData) => { 
                        console.log(newData)
                      return  new Promise((resolve) => {
                            setTimeout(() => {
                              {
                               createNewLabel(newData)
                              }
                              resolve()
                            }, 1000)
                          })
                    },
                      
                      
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          {
                            updateLabel(newData, oldData)
                          }
                          resolve()
                        }, 1000)
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          {
                           deleteLabel(oldData)
                          }
                          resolve()
                        }, 1000)
                      }),
                  }}
                
            />
            
              
            </div>
  );
}
