const configuration = {
	"client":"FLAIR",
	"web_url":"https://dev.flairtechno.com/",
	"owner":"Flair",
	"flair_logo":"https://firebasestorage.googleapis.com/v0/b/flair-d7b59.appspot.com/o/companycustoms%2FFlairTechno.png?alt=media&token=f5a0e3b1-0dab-4d41-89f5-e27296429f9b",
	"logo":"https://firebasestorage.googleapis.com/v0/b/flair-d7b59.appspot.com/o/companycustoms%2Flogo.png?alt=media&token=e1a7be52-c134-4978-9503-7d49f50561d6",
	"PDF_header":"https://firebasestorage.googleapis.com/v0/b/flair-d7b59.appspot.com/o/companycustoms%2FPDF_header.png.jpg?alt=media&token=adb60ffc-5e49-49f2-9f86-d793e9e69701",
	"PDF_footer":"https://firebasestorage.googleapis.com/v0/b/flair-d7b59.appspot.com/o/companycustoms%2FPDF_footer.png.jpg?alt=media&token=6054e5aa-f1f3-4e60-bd53-a1bfb0ac5abb",
	"bg_color":"",
	"REST_api":"http://localhost:5001/flair-dev-ad836/us-central1/api",
	"empcode":"",
	"dateformat":{
		year:"numeric",
		month:"2-digit",
		day:"2-digit"
	},
	"contactdetails":{
		address:"1565 Woodington Circle, Suite #203, Lawrenceville, GA - 30044.",
		phoneno:"+1 513 488 4748",
		mailId:"contact@flairtechno.com",
		eVerifyNo:'E0000123',
		einNo:'EIN2344' 
	},
	"timeformat":{
		hour: 'numeric', minute: 'numeric', second: 'numeric',
		hour12: true,
		timeZone: 'America/New_York',
	},
	"date-code":"en-US",
	"email":"no-reply@flairtechno.com",
	"letterTypes":['Offer Letter', 'Experience Letter', 'Relieving Letter', 'Hand Book Signature', 'Travel Document Content', 'H1B Document', 'Payroll Issue', 'I-983'],
	"draggableLabels":['TodayDate', 'UniversityName', 'UniversityAddress', 'FirstName', 'LastName', 'CompanyName', 'JobTitle', 'EffectiveDate', 'CompanyEverifyNumber', 'CompanyEIN', 'CompanyAddress', 'HRMailId', 'AuthorizedPersonSignature','AuthorizedName', 'AuthorizedPersonDesignation' ]
}


module.exports = {configuration}