import React, { useContext } from 'react'
import ClientCard from '../clientComponents/ClientCard'
import ClientTabs from '../clientComponents/ClientTabs'
import { DataContext } from '../../../../../contexts/data'
function Presentation(props) {
    const { clientId } = props
    const [state] = useContext(DataContext)
    const client = state.client_list.filter(item => item.clientId === clientId)[0]
    console.log(client)
    if(Object.keys(client).length)
        return (
            <div>
                <ClientCard client={client} />
                <ClientTabs client={client} />
            </div>
        )
    return <h1 className="text-center" >No client found</h1>    
}

export default Presentation
