import React from 'react';
import Wiki from '../components/Wiki/Index';
import { Breadcrumbs } from '@material-ui/core'
import { Link } from 'react-router-dom'

export default function WikiPage() {
    return (
        <div>
            <div>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to='/'>Home</Link>
                    <Link to="/console/wiki">Wiki</Link>
                </Breadcrumbs>
            </div>
            <div>
                <Wiki />
            </div>
        </div>
    );
}