import React, { PureComponent } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import { NEW_PROJECT } from '../../../contexts/types'

class Container extends PureComponent {
    state = {
        name:"",
        status:"",
        startdate:"",
        enddate:"",
        projectId:"",
        employees:[],
        isCreated : false,
        useLabels: false,
        useTimeline: false
    }

    static contextType = DataContext

    componentDidMount = () => {
        this.clearValues()
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
        console.log(e.target.name,e.target.value)
    }

    handleCheck = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        })
    }

    handleDateChange = (key, value) => {
        console.log(value)
        if(value === null)
            this.setState({
                [key]:''
            })
        else if(!isNaN(Date.parse(value))) 
            this.setState({
                [key]:value.toISOString()
            })
    }

    handleEmployees = (value) => {
        this.setState({employees:value})
    }

    handleCreate = (e) => {
        e.preventDefault()
        const dispatch = this.context[1]
        const { name, status, startdate, enddate, employees, projectId, useLabels, useTimeline } = this.state
        dispatch({
            type:NEW_PROJECT,
            payload:{
                newProject:{
                    title:name,
                    status,
                    startdate,
                    enddate,
                    useLabels,
                    useTimeline,
                    cid:projectId,
                    Users:employees,
                },
                isProjectCreated : this.isProjectCreated
            }
        })

        this.clearValues()
    }

    clearValues = () => {
        this.setState({
            name:"",
            status:"",
            startdate:"",
            enddate:"",
            projectId:"",
            useLabels: false,
            useTimeline: false,
            employees:[],
            isCreated : false
        })
    }

    isProjectCreated = (bool) => {
        
    }

    componentWillUnmount = () =>{
        this.setState({
            name:"",
            status:"",
            startdate:"",
            enddate:"",
            projectId:"",
            useLabels: false,
            employees:[],
            isCreated : false
        })
    }

    

    render() {
        const newProject = {
            name : this.state.name,
            status : this.state.status,
            startdate : this.state.startdate,
            enddate : this.state.enddate,
            useLabels: this.state.useLabels,
            useTimeline: this.state.useTimeline,
            projectId : this.state.projectId
        }

        
        let errCount = 0
        Object.entries(newProject).forEach(([key, value]) => {
            if((key === "startdate" &&  isNaN(Date.parse(value))) || (key === "enddate" &&  isNaN(Date.parse(value))))
                errCount++;
            else if((key === "projectId" && value.length < 3) || (key === "projectId" && value.length > 10))  
                errCount++  
            else if(typeof(value) === "string" && value.trim() === "")
                errCount++;    
            console.log(key, value)    
        })
        console.log(errCount)
        return (
            <div>
                <Presentation
                    errCount={errCount}
                    handleChange = {this.handleChange}
                    handleDateChange={this.handleDateChange}
                    handleEmployees = {this.handleEmployees}
                    handleCreate={this.handleCreate}
                    newProject={newProject}
                    clearValues = {this.clearValues}
                    handleCheck={this.handleCheck}
                />
            </div>
        )
    }
}

export default Container
