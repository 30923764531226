import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import {
  UPDATE_WIKI_PAGE,
  SET_WIKI_CATEGORY,
  SET_WIKI_PAGES,
} from '../../../contexts/types'
import { CircularProgress } from '@material-ui/core'
import Axios from 'axios'
import { Redirect } from 'react-router-dom'
import Validations from '../../generalcomponents/validation'

class Container extends Component {
  constructor() {
    super()
    this.validate = new Validations()
    this.state = {
      content: '',
      title: '',
      category: '',
      helperText: '',
      CategorieNames: [],
      articleLoad: false,
      categoryLoad: false,
      redirect: false,
      attachment: [],
      isUploading: false,
      progress: 0,
    }
  }

  static contextType = DataContext

  getNameById = (id) => {
    const [state] = this.context
    for (let index = 0; index < state.wikiCategories.length; index++) {
      if (state.wikiCategories[index].id === id)
        return state.wikiCategories[index].name
    }
    return 'Archived'
  }

  getIdByName = (name) => {
    const [state] = this.context
    for (let index = 0; index < state.wikiCategories.length; index++) {
      if (state.wikiCategories[index].name === name)
        return state.wikiCategories[index].id
    }
  }

  getArticle = () => {
    const [state] = this.context
    let categories = state.wikiCategories.map((ele) => {
      return ele.name
    })
    state.wikiPages.forEach((ele) => {
      if (ele.id === this.props.id) {
        console.log(ele)
        this.setState({
          content: ele.articleContent,
          title: ele.title,
          category: this.getNameById(ele.topicName),
          attachment: ele.hasOwnProperty('attachments') ? ele.attachments : [],
          articleLoad: true,
        })
        if (!categories.includes(this.getNameById(ele.topicName))) {
          this.setState({ category: '' })
        }
      }
    })
  }

  getCategory = () => {
    const [state] = this.context
    this.setState({
      CategorieNames: state.wikiCategories,
      categoryLoad: true,
    })
  }

  checkTitle = () => {
    const [state] = this.context
  }

  componentDidMount = async () => {
    const [state, dispatch] = this.context
    if (
      !state.hasOwnProperty('wikiCategories') &&
      !state.hasOwnProperty('wikiPages')
    ) {
      let response = await Axios.get('/getPagesInWiki')
      dispatch({
        type: SET_WIKI_PAGES,
        payload: response.data.response,
      })
      let res = await Axios.get('wiki/getCategories')
      dispatch({
        type: SET_WIKI_CATEGORY,
        payload: res.data.response,
      })
      this.getCategory()
      this.getArticle()
      this.checkTitle()
    } else {
      this.getCategory()
      this.getArticle()
      this.checkTitle()
    }
  }

  handleContent = (data) => {
    this.setState({
      content: data,
    })
  }

  wikiUpdatePageCallBack = async () => {
    const dispatch = this.context[1]
    let response = await Axios.get('/getPagesInWiki')
    dispatch({
      type: SET_WIKI_PAGES,
      payload: response.data.response,
    })
    this.setState({ redirect: true })
  }

  handleUpdate = () => {
    const dispatch = this.context[1]
    const { content, title, category, attachment } = this.state
    const payload = {
      wikiId: this.props.id,
      topicName: this.getIdByName(category),
      title: title.trim(),
      articleContent: content,
      attachments: attachment,
    }
    dispatch({
      type: UPDATE_WIKI_PAGE,
      wikiUpdatePageCallBack: this.wikiUpdatePageCallBack,
      payload: payload,
    })
  }

  handleAutoComplete = (e, v) => {
    this.setState({ [e]: v })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
    const [state] = this.context
    let titles = state.wikiPages.map((ele) => {
      return ele.title.replace(/\s/g, '').toLowerCase()
    })
    if (e.target.name === 'title') {
      let value = e.target.value
      if (titles.includes(value.replace(/\s/g, '').toLowerCase())) {
        let helperText = value.trim() ? 'Title already exists' : ''
        this.setState({ helperText })
      } else {
        this.setState({ helperText: '' })
      }
    }
  }

  handleChange2 = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      this.setState(() => ({ file }))
      this.fileUpload1(file, this.state.documentType)
    }
  }

  fileUpload1 = async (file, type) => {
    const fileName = file.name
    const filePath = `Wiki/Documents/${fileName}`
    this.setState({ isUploading: true })
    try {
      const url = await this.validate.uploadToStorage(
        file,
        filePath,
        fileName,
        'file'
      )
      this.setState((prevState) => ({
        attachment: prevState.attachment.concat({ name: file.name, url: url }),
      }))
      this.setState({ isUploading: false })
    } catch (err) {
      this.setState({ isUploading: false })
      console.log(err)
    }
  }

  handleDeleteAttachment = (index) => {
    const { attachment } = this.state
    let updated_array = []
    updated_array = attachment
    updated_array.splice(index, 1)
    this.setState({
      attachment: updated_array,
    })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/console/wiki'} />
    }
    if (this.state.articleLoad && this.state.categoryLoad) {
      return (
        <div>
          <Presentation
            {...this.state}
            handleContent={this.handleContent}
            handleChange={this.handleChange}
            handleUpdate={this.handleUpdate}
            handleAutoComplete={this.handleAutoComplete}
            handleDeleteAttachment={this.handleDeleteAttachment}
            handleChange2={this.handleChange2}
          />
        </div>
      )
    } else {
      return (
        <div style={{ marginTop: '15%', alignContent: 'center' }}>
          <div className='text-center'>
            <CircularProgress color='primary' />
          </div>
        </div>
      )
    }
  }
}

export default Container
