import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import { DataContext } from '../../../../../../src/contexts/data'
import MaterialTable, { MTableToolbar }  from 'material-table';
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        width: 'auto',
        background: 'linear-gradient(45deg, #280071 10%, #c42053 90%)',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Presentation(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [state] = useContext(DataContext)
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        clearValues();

    };
    const validate = {
        emailToName: (uid) => {
            try {
                return state.names.filter(item => item.uid === uid)[0].name
            } catch (error) {
                console.log(error)
                return uid
            }
        }
    }
    const projectLabels = state.projectList.filter(item => item.id === state.project.id)[0].labels

    const diff = dayjs()
    const getDueBy = (enddate, status) => {
        console.log(new Date() - new Date(enddate))
        if (status === 'Completed' || status === 'Closed')
            return false
        return new Date() - new Date(enddate) >= 0 ? true : false
        }

        const getDueByDays = (startDate, endDate, status) => {
        console.log(new Date() - new Date(endDate))
        { 
            if( getDueBy(endDate, status) )
                return diff.from(endDate, true)
                
            return "---"
        }
    }

    const customStyles = {
        employeeExist:{
          color : '#4183c4',
          textTransform : 'capitalize'
        },
        employeeNonExist : {
          color : '#626466',
          textTransform : 'capitalize',
        }
      }

    const { handleClick, handleChange, listOfTasksToBeLinked, clearValues } = props
    let subTaskIds = [...new Set(state.taskList.filter(task => task.category === "subtask").map(subtask => subtask.taskId))]
    let parentTasks = state.taskList.filter(task => task.category === "task" && state.task.id !== task.id)
   console.log(parentTasks.length)
    const columns = [
        {
            title: 'Select', field: 'select', width: '2%',
            render: ({ checking, id}) => <Checkbox checked={checking} name='wiki' onChange={(e) => handleChange(e, id)} />
        },
        {
            title: 'Task ID', field: 'cid', defaultSort: 'desc', width: '10%',
        },
        {
            title: 'Labels', field: 'labels', width: '10%',
            render: rowData => {
                return rowData.labels.map(element => <span><Chip size="small" style={{ backgroundColor: `${element.colorCode}`, color: "white", marginBottom: '2px' }} label={element.name} /><br/></span>)
            }
        },
        {
            title: 'Title', field: 'title', width: '38%',
        }, 
        {
            title: 'Due by', field: 'dueBy', defaultSort: 'desc', width: '5%',
        }, 
        {
            title: 'Priority', field: 'priority', defaultSort: 'desc', width: '5%',
        },
        {
            title: 'Created by', field: 'createdByName', width: '15%',
        },
        {
            title: 'Assignee', field: 'assignee', width: '25%', 
            render: rowData => {
                return rowData.assignee.map(employee => <span><Link to={"/console/employeelist/"+employee} ><Button type="a" style={state.projectList.filter(project => project.id === state.project.id)[0].Users.map(user => user.uid).includes(employee) ? customStyles.employeeExist : customStyles.employeeNonExist} size="small">{validate.emailToName(employee)}</Button></Link><br/></span>)}
        },
    ]
    let data = []
    parentTasks.length && parentTasks.map(task => {
        data.push({
            title: task.title,
            id: task.id,
            startDate: task.startDate,
            endDate: task.endDate,
            labels:  projectLabels.filter(item => task.hasOwnProperty('labels') && task.labels.includes(item.id)),
            dueBy: getDueByDays(task.startDate, task.endDate, task.status),
            cid: state.project.cid + " - " + task.cid,
            assignee: validate.emailToName(task.assignee),
            priority: task.priority,
            createdByName: validate.emailToName(task.createdBy),
            createdBy: task.createdBy,
        })
    })

    return (
        <div>
            <Button variant="contained" color="secondary" onClick={handleClickOpen} >
                Link tasks
      </Button>
            <Dialog fullScreen disableEscapeKeyDown disableBackdropClick open={open} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} >
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                    <div>
                        <MaterialTable
                            title=""
                            data={data}
                            columns={columns}
                            options={{
                                paginationType: "stepped",
                                pageSize:10
                            }}
                            components={{
                                Toolbar: props => (
                                    <div>
                                        <MTableToolbar {...props} />
                                        <div style={{marginTop:'-50px', marginBottom:'10px', marginLeft:'8px'}}>
                                            <Button variant="contained" color="secondary"
                                                    onClick={(e) => {
                                                        handleClick(e)
                                                        return handleClose()
                                                    }} 
                                            >
                                                Link
                                            </Button>
                                        </div>
                                    </div>
                                ),
                                }}
                        />
                    </div>
            </Dialog>
        </div>
    )
}