import React, { PureComponent } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../contexts/data'
import {
  CREATE_PAYMENTS_SETTINGS,
  UPDATE_PLACEMENT_SETTINGS,
  PLACEMENT_SETTINGS_ACTIONS,
} from '../../../../../contexts/types'
import firebase from '../../../../../firebase_config'

class Container extends PureComponent {
  state = {
    value: 0,
    billingType: '',
    billingRate: '',
    OTBillRatePerHr: '',
    purchaseOrder: '',
    payType: '',
    percentage: '',
    employeePayRate: '',
    OTPayRatePerHr: '',
    effectiveDate: '',
    effectiveUntilDate: '',
    fixedPay: '',
    profile: {},
    id: '',
    fillableSections: [],
    showOT: true,
    createdAt: '',
    updatedAt: [],
    payments: [],
    update: false,
    index: '',
    reqDate: '',
  }

  static contextType = DataContext

  handleChange = (key, value) => {
    if (key === 'OTBillRatePerHr') {
      this.setState({ OTPayRatePerHr: value })
    }
    this.setState({
      [key]: value,
    })
  }

  handleData = (rowData) => {
    const {
      billingType,
      billingRate,
      OTBillRatePerHr,
      OTpayRate,
      purchaseOrderNumber,
      payType,
      fixedPay,
      percentage,
      effectiveDate,
      effectiveUntilDate,
      employeePayRate,
      index,
    } = rowData
    console.log(index)
    this.setState({
      billingType: billingType.toString(),
      billingRate: billingRate.toString(),
      OTBillRatePerHr: OTBillRatePerHr.toString(),
      purchaseOrder: purchaseOrderNumber.toString(),
      payType: payType.toString(),
      percentage: percentage !== '-' ? percentage.toString() : '',
      employeePayRate:
        employeePayRate !== '-' ? employeePayRate.toString() : '',
      OTPayRatePerHr: OTpayRate !== '-' ? OTpayRate.toString() : '',
      effectiveDate: new Date(effectiveDate).toISOString(),
      effectiveUntilDate: new Date(effectiveUntilDate).toISOString(),
      fixedPay: fixedPay.toString(),
      index: index,
    })
    this.handleTab('', 0)
  }

  handleTab = (_, v) => {
    this.setState({ value: v })
  }

  refreshingData = () => {
    const payments = this.state.payments.sort(
      (a, b) => new Date(a.effectiveDate) - new Date(b.effectiveDate)
    )
    let temp = new Date(payments[payments.length - 1].effectiveUntil)
    temp.setDate(temp.getDate() + 1)
    this.setState({
      reqDate: temp.toISOString(),
      effectiveDate: temp.toISOString(),
    })
  }

  componentWillReceiveProps = (nextProps) => {
    const { req_placement, profile } = nextProps
    const {
      id,
      startDate,
      endDate,
      fillableSections,
      createdAt,
    } = req_placement
    this.setState({
      profile,
      id,
      fillableSections,
      createdAt,
    })
    if (id.length) {
      const documentRef = firebase.firestore().collection('Placements').doc(id)
      documentRef
        .collection('Payments')
        .get()
        .then((snap) => {
          let placementData = []
          placementData = snap.docs.map((doc) => doc.data())
          if (placementData.length) {
            let data = placementData[0].hasOwnProperty('data')
              ? placementData[0].data
              : []
            data = data.sort((a, b) => {
              console.log(new Date(a.effectiveDate).toISOString())
              return new Date(a.effectiveDate) - new Date(b.effectiveDate)
            })
            console.log(data)
            this.setState({
              payments: data,
              updatedAt: placementData[0].updatedAt,
            })
          }
          const payments = this.state.payments
          console.log(payments)
          if (payments.length) {
            let temp = new Date(payments[payments.length - 1].effectiveUntil)
            temp.setDate(temp.getDate() + 1)
            this.setState({
              reqDate: temp.toISOString(),
              effectiveDate: temp.toISOString(),
            })
          } else {
            this.setState({
              effectiveDate: startDate,
              effectiveUntilDate: endDate,
              reqDate: new Date('1970-01-01T00:00:01.898Z').toISOString(),
            })
          }
        })
      documentRef
        .collection('Invoices')
        .get()
        .then((snap) => {
          const data = snap.docs.map((doc) => doc.data())
          if (data.length) {
            this.setState({
              showOT: data[0].OT,
            })
          }
        })
    }
  }

  handleUpdate = () => {
    const dispatch = this.context[1]
    const {
      billingType,
      billingRate,
      OTBillRatePerHr,
      purchaseOrder,
      payType,
      employeePayRate,
      OTPayRatePerHr,
      effectiveDate,
      effectiveUntilDate,
      percentage,
      id,
      payments,
      fixedPay,
      index,
    } = this.state
    let data = payments
    const payloadData = {
      OTbillingRate: isNaN(parseInt(OTBillRatePerHr))
        ? 0
        : parseInt(OTBillRatePerHr),
      OTpayRate: isNaN(parseInt(OTPayRatePerHr)) ? 0 : parseInt(OTPayRatePerHr),
      billingRate: isNaN(parseInt(billingRate)) ? 0 : parseInt(billingRate),
      billingType: billingType,
      effectiveDate: effectiveDate,
      effectiveUntil: effectiveUntilDate,
      employeePayRate: isNaN(parseFloat(employeePayRate))
        ? 0
        : parseFloat(employeePayRate),
      payType: payType,
      fixedPay: isNaN(parseInt(fixedPay)) ? 0 : parseInt(fixedPay),
      purchaseOrderNumber: isNaN(parseInt(purchaseOrder))
        ? 0
        : parseInt(purchaseOrder),
      percentage: isNaN(parseInt(percentage)) ? 0 : parseInt(percentage),
    }
    data[index] = payloadData
    if (index !== payments.length - 1) {
      let temp = data[index + 1]
      let date = new Date(payloadData.effectiveUntil)
      console.log(date.toISOString())
      date.setDate(date.getDate() + 1)
      let updatedData = { ...temp, effectiveDate: date.toISOString() }
      console.log(date.toISOString())
      data[index + 1] = updatedData
    }
    if (index !== 0) {
      let temp = data[index - 1]
      let date = new Date(payloadData.effectiveDate)
      console.log(date.toISOString())
      date.setDate(date.getDate() - 1)
      let updatedData = { ...temp, effectiveUntil: date.toISOString() }
      console.log(date.toISOString())
      data[index - 1] = updatedData
    }
    this.setState({ payments: data })
    const payload = {
      data,
      sectionName: 'payments',
      placementID: id,
    }
    console.log(payload)
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      payload: payload,
      actionType: 'update',
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
    })
  }

  handleCreate = () => {
    const dispatch = this.context[1]
    const {
      billingType,
      billingRate,
      OTBillRatePerHr,
      purchaseOrder,
      payType,
      employeePayRate,
      OTPayRatePerHr,
      reqDate,
      effectiveDate,
      effectiveUntilDate,
      percentage,
      id,
      payments,
      fixedPay,
    } = this.state
    let data = payments
    const payloadData = {
      OTbillingRate: isNaN(parseInt(OTBillRatePerHr))
        ? 0
        : parseInt(OTBillRatePerHr),
      OTpayRate: isNaN(parseInt(OTPayRatePerHr)) ? 0 : parseInt(OTPayRatePerHr),
      billingRate: isNaN(parseInt(billingRate)) ? 0 : parseInt(billingRate),
      billingType: billingType,
      effectiveDate: payments.length !== 0 ? reqDate : effectiveDate,
      effectiveUntil: effectiveUntilDate,
      employeePayRate: isNaN(parseFloat(employeePayRate))
        ? 0
        : parseFloat(employeePayRate),
      payType: payType,
      fixedPay: isNaN(parseInt(fixedPay)) ? 0 : parseInt(fixedPay),
      purchaseOrderNumber: isNaN(parseInt(purchaseOrder))
        ? 0
        : parseInt(purchaseOrder),
      percentage: isNaN(parseInt(percentage)) ? 0 : parseInt(percentage),
    }
    data = [...data, payloadData]
    this.setState({ payments: data })
    const payload = {
      data,
      sectionName: 'payments',
      placementID: id,
    }
    console.log(payload)
    dispatch({
      type: PLACEMENT_SETTINGS_ACTIONS,
      payload: payload,
      actionType: 'new',
      placementSettingsActionsWiki: this.placementSettingsActionsWiki,
    })
  }

  placementSettingsActionsWiki = () => {
    this.clearValues()
    this.refreshingData()
  }

  handleSave = (type) => {
    if (type === 'create') this.handleCreate()
    else if (type === 'update') this.handleUpdate()
    else console.log('Error')
  }

  handleDateChange = (key, value) => {
    if (!isNaN(Date.parse(value)))
      this.setState({
        [key]: value.toISOString(),
      })
  }

  handleCancel = () => {
    this.setState({ update: false })
    this.clearValues()
  }

  clearValues = () => {
    this.setState({
      billingType: '',
      billingRate: '',
      OTBillRatePerHr: '',
      purchaseOrder: '',
      payType: '',
      percentage: '',
      employeePayRate: '',
      OTPayRatePerHr: '',
      effectiveDate: '',
      effectiveUntilDate: '',
      fixedPay: '',
    })
  }

  render() {
    return (
      <div>
        <Presentation
          {...this.state}
          handleChange={this.handleChange}
          handleDateChange={this.handleDateChange}
          handleSave={this.handleSave}
          handleTab={this.handleTab}
          handleData={this.handleData}
          handleCancel={this.handleCancel}
        />
      </div>
    )
  }
}

export default Container
