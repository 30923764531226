import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {FaRegHandPointRight} from 'react-icons/fa'
import { List } from 'semantic-ui-react'
import {configuration} from '../../../../configuration'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    width: '100%',
    // flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  list:{
      width: '100%'
  }
}));

export default function Presentation(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { letters, onSelect } = props
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const letterTypes = configuration.letterTypes.map((letter, index) => {
      return {
          name : letter,
          id : 'panel'+index,
          type : letter.replace(/ /g,'').toLowerCase()
      }
  })



  return (
    <div className={classes.root}>
        {
            letterTypes.map(paper => {
                return(
                    <ExpansionPanel  expanded={expanded === paper.id} onChange={handleChange(paper.id)}>
                        <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography className={classes.heading}>{paper.name} </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div className={classes.list}>
                                {
                                    letters.filter(letter => letter.type === paper.type).map(item => {
                                        return(
                                            <List selection onClick={() => onSelect(item.id)} verticalAlign='middle'>
                                            <List.Item className="d-flex" >
                                                <FaRegHandPointRight size={22} />
                                                <List.Content >
                                                    <List.Header className="ml-4 mt-1" >{item.name}</List.Header>
                                                </List.Content>
                                            </List.Item>
                                        </List>
                                        )
                                    })
                                }
                                <Typography style={{opacity:0}} >
                                    Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat. Aliquam eget
                                    maximus est, id dignissim quam.
                                </Typography>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )
                
            })
        }
    </div>
  );
}
