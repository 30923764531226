import React from 'react'
import PlacementsList from '../components/TimeSheets/PlacementsList'
function PlacementsListPage() {
    return (
        <div>
            <PlacementsList listAll={true} />
        </div>
    )
}

export default PlacementsListPage
