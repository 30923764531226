import React from 'react'
import TaskHandler from '../components/Project/Task/TaskHandler'

function TaskHandlePage(props) {
    return (
        <div>
                <TaskHandler
                    projectId={props.match.params.projectId}
                    taskId={props.match.params.taskId}
                />
        </div>
    )
}

export default TaskHandlePage
