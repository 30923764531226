import React, { useContext } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import { IconButton, Tooltip, ButtonGroup } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EditLocation from "../EditLocation"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

function Presentation(props) {
    const { clientId, locations, isLoading, onDeleteLocation } = props
    const columns = [
        { title:'City', field: 'locationscity'},
        { title:'State', field: 'locationsstate_name'},
        { title:'Country', field: 'locationscountry'},
        { title:'Zip', field: 'locationszip'},
        { title: 'Actions', field: '', 
            render: (row) => <div className="d-flex" >
                        <EditLocation btnContent={<EditIcon />} clientId={props.client.id} location={row}  edit={true} />
                        <IconButton onClick={() => onDeleteLocation(row.id)} ><DeleteIcon /></IconButton>
                    </div>  
        }   
    ]
    let data = []
    if(locations.length) data = locations.map(location => {
        return{
            locationsline1: location.locationsline1,
            locationsline2: location.locationsline2,
            locationscity: location.locationscity,
            locationsstate_name: location.locationsstate_name,
            locationscountry: location.locationscountry,
            locationszip: location.locationszip,
            id: location.id
        }
    })
        return (
            <div>
                <MaterialTable 
                    isLoading={isLoading}
                    data={data}
                    columns={columns}
                    title=""
                    components={{
                        Toolbar: tableProps => (
                          <div>
                            <MTableToolbar {...tableProps} />
                            <div style={{marginTop:'-38px',marginLeft:'8px',marginBottom: '10px'}}>
                                <Tooltip title="Add contact" >
                                    <ButtonGroup variant="contained" color="primary" aria-label="outlined primary button group">
                                        <EditLocation location={emptyValues} btnContent={<AddCircleOutlineIcon />} clientId={props.client.id} edit={false} />
                                    </ButtonGroup>
                                </Tooltip>
                            </div>
                          </div>
                        ),
                    }}
                />
            </div>
        )
}

export default Presentation

const emptyValues = {
    locationsline1: "",
    locationsline2: "",
    locationscity: "",
    locationsstate_name: "",
    locationscountry: "",
    locationszip: "",
}
