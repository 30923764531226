import React from 'react'
import ExpensesList from "../components/TimeSheets/Expenses/ExpensesList"

function ExpensesListPage() {
    return (
        <div>
            <ExpensesList listAll={true} />
        </div>
    )
}

export default ExpensesListPage
