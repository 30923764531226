import React from 'react'
import LetterTemplates from '../components/Templates/LetterTemplates/Index'
function LetterTemplatePage() {
    return (
        <div>
            <LetterTemplates />
        </div>
    )
}

export default LetterTemplatePage
