import React, { useContext } from 'react'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { DataContext } from '../../../contexts/data'
import { configuration } from '../../../configuration'
import { Avatar, Tooltip, makeStyles } from '@material-ui/core'
import { deepOrange, deepPurple, lime, green } from '@material-ui/core/colors'

export default function Presentation(props) {
  const [state] = useContext(DataContext)
  const { article } = props
  const validate = {
    emailToName: (uid) => {
      try {
        return state.names.filter((item) => item.uid === uid)[0].name
      } catch (error) {
        console.log(error)
        return uid
      }
    },
    getImage: (commentBy) => {
      let url = ''
      try {
        state.names.forEach((name) => {
          if (name.uid === commentBy) url = name.photoURL
        })
      } catch (error) {
        url = ''
      }

      return url
    },
  }

  const useStyles = makeStyles((theme) => ({
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    lime: {
      color: theme.palette.getContrastText(lime[500]),
      backgroundColor: lime[500],
    },
    green: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
    },
  }))

  const classes = useStyles()

  const formatter = (date) => {
    let final = ''
    try {
      final = Intl.DateTimeFormat(
        configuration['date-code'],
        configuration.dateformat
      ).format(new Date(date))
    } catch (error) {
      console.log(error)
      final = date
    }
    return final
  }
  const columns = [
    {
      title: 'Article',
      field: 'article',
      width: '40%',
      render: (rowData) => (
        <div style={{ display: 'flex' }}>
          {/* <span>
                    {
                        
                    }
                    <br />
                    {
                        <Chip size="small" style={{ textTransform: "capitalize", backgroundColor: "#1cacd7", color: "white" }} label={rowData.topicName} />
                    }
                </span> */}
          <div className='d-flex'>
            <div>
              <Tooltip title={validate.emailToName(rowData.createdBy)}>
                <Avatar src={validate.getImage(rowData.createdBy)} />
              </Tooltip>
            </div>
            <div className='ml-4'>
              <Link to={'/console/wiki/' + rowData.id}>
                <Button
                  type='a'
                  style={{ textTransform: 'capitalize', color: '#4183c4' }}
                  size='small'
                >
                  {rowData.article}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Updated On',
      field: 'updatedOn',
      render: (rowData) => formatter(rowData.updatedOn),
    },
    {
      title: 'Updated By',
      field: 'updatedBy',
      render: (rowData) => validate.emailToName(rowData.updatedBy),
    },
    {
      title: 'Created On',
      field: 'createdOn',
      render: (rowData) => formatter(rowData.createdOn),
    },
    {
      title: 'Created By',
      field: 'createdBy',
      render: (rowData) => validate.emailToName(rowData.createdBy),
    },
  ]

  let data = []
  article &&
    article.forEach((element) => {
      data.push({
        id: element.id,
        article: element.title,
        firstLetter: element.createdBy[0].toUpperCase(),
        topicName: element.topicName,
        updatedOn: element.updatedAt,
        updatedBy: element.updatedBy,
        createdOn: element.createdAt,
        createdBy: element.createdBy,
      })
    })

  return (
    <div>
      <MaterialTable
        title=''
        columns={columns}
        data={data}
        options={{
          paginationType: 'stepped',
          pageSize: 10,
          rowStyle: {
            backgroundColor: '#fafafa',
          },
        }}
      />
    </div>
  )
}
