import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../../../contexts/data'
import { CLIENT_CONTACT_ACTIONS } from '../../../../../../../contexts/types'
export class Container extends Component {

    state = {
        representativeName: "",
        jobTitle: "",
        contactType: "",
        email: "",
        gender: "",
        homePhone: "",
        workPhone: "",
        mobile: "",
        line1: "",
        line2: "",
        city: "",
        state_name: "",
        country: "",
        zip: "",
    }
    static contextType = DataContext
    componentDidMount = () => {
        if(this.props.edit)
            Object.entries(this.props.contact).forEach(([key, value]) => {
                this.setState({
                    [key] : value
                })
            })    
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    handleUpdate = () => {
        const dispatch = this.context[1]
        dispatch({
            type: CLIENT_CONTACT_ACTIONS,
            payload:{
                ...this.state,
                contactId: this.props.contact.id,
                clientId: this.props.clientId
            },
            actionType: 'edit'
        })
    }

    handleAdd = () => {
        const dispatch = this.context[1]
        dispatch({
            type: CLIENT_CONTACT_ACTIONS,
            payload:{
                ...this.state,
                clientId: this.props.clientId
            },
            actionType: 'new'
        })
    }

    clearValues = () => {
        this.setState({
            representativeName: "",
            jobTitle: "",
            contactType: "",
            email: "",
            gender: "",
            homePhone: "",
            workPhone: "",
            mobile: "",
            line1: "",
            line2: "",
            city: "",
            state_name: "",
            country: "",
            zip: ""
        })
    }
    render() {
        console.log(this.props)
        return (
            <div>
                <Presentation
                    {...this.props} 
                    {...this.state}
                    handleUpdate={this.handleUpdate}
                    handleChange={this.handleChange}
                    handleAdd={this.handleAdd}
                />
            </div>
        )
    }
}

export default Container
