import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InviteThroughExcel from '../InviteThroughExcel'
import { FiExternalLink } from 'react-icons/fi'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MenuItem } from '@material-ui/core';

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
    const { heading, text, template, btnText, handleClick, isSending, firstname, lastname, branch, dob, email, phonenumber } = props
  const handleClickOpen = () => {
    setOpen(true);
    if(template)
        props.handleLabelsSave()
  };

  const closeAlert = () => {
    setOpen(false);
    handleClick()
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{marginTop:"10px"}} >
     
      {
        !isSending ? 
        <Button variant="contained" fullWidth={template} color="primary" onClick={handleClickOpen}>
          {btnText}
        </Button>
        :
        <Button variant="outlined" color="primary" fullWidth={template}  disabled>
          ...
        </Button>
      }
      <Dialog scroll="paper" fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"><span>{heading}</span></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>
              <span>{text}</span>
              

            </div>
          </DialogContentText>
          <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                  margin="dense"
                  label="First name"
                  type="text"
                  name="firstname"
                  value={firstname}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                  margin="dense"
                  label="Last name"
                  type="text"
                  name="lastname"
                  value={lastname}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                  margin="dense"
                  label="Phone"
                  type="number"
                  name="phonenumber"
                  value={phonenumber}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                          margin="normal"
                          label="Date of birth"
                          format="MM/dd/yyyy"
                          invalidDateMessage=""
                          disableFuture={true}
                          onChange={(date) => props.handleChange("dob", date)}
                          value={dob ? dob : null}
                          KeyboardButtonProps={{
                              'aria-label': 'change date',
                          }}
                      />
                  </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={props.handleChange}
                  autoFocus
                  margin="dense"
                  onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                  label="Email Address"
                  value={email}
                  required
                  name="email"
                  type="email"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <TextField  
                  select
                  label="Branch"
                  name="branch"
                  onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                  value={branch}
                  fullWidth
                >
                  <MenuItem value="">
                      <em>None</em>
                  </MenuItem>
                  <MenuItem value="ATL">ATL</MenuItem>
                  <MenuItem value="NJ">NJ</MenuItem>
                  <MenuItem value="NC">NC</MenuItem>
                  <MenuItem value="FL">FL</MenuItem>
                  <MenuItem value="DAL">DAL</MenuItem>
                  <MenuItem value="AUS">AUS</MenuItem>
                  <MenuItem value="SA">SA</MenuItem>
                  <MenuItem value="VA">VA</MenuItem>
                  <MenuItem value="STL">STL</MenuItem>
                  <MenuItem value="MN">MN</MenuItem>
                  <MenuItem value="CA-N">CA-N</MenuItem>
                  <MenuItem value="CA">CA</MenuItem>    
                  <MenuItem value="SFO">SFO</MenuItem>
                  <MenuItem value="OH">OH</MenuItem>
                  <MenuItem value="GVRM-IND">GVRM-IND</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <h5>You can invite multiple number of employees by following the sample excel sheet we have provided:</h5><a style={{textDecoration:'underline'}} target="_blank" href="https://firebasestorage.googleapis.com/v0/b/flair-d7b59.appspot.com/o/companycustoms%2FMultiple%20Invites.xlsx?alt=media&token=b15ba2cf-0cd5-475c-b09d-00a6cc74e1ef">Download sample format<FiExternalLink size={14} /> </a>
              </Grid>
          </Grid>
          
        </DialogContent>
        <DialogActions>
          <span className='mr-auto ml-2 d-flex' >
            <InviteThroughExcel />
          </span>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={closeAlert} color="primary">
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
