import React from 'react';
import ShowArticle from '../components/Wiki/ShowArticle';
import { Breadcrumbs } from '@material-ui/core'
import { Link } from 'react-router-dom'


export default function WikiPage(props) {
    return (
        <div>
            <div>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to='/'>Home</Link>
                    <Link to="/console/wiki">Wiki</Link>
                    <Link to={'/console/wiki/' + props.match.params.id}>Article</Link>
                </Breadcrumbs>
            </div>
            <div>
                <ShowArticle
                    {...props.match.params}
                    isHistory={false}
                />
            </div>
        </div>
    );
}