import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
import { UPDATE_PROFILE } from '../../../../contexts/types'
export class Container extends Component {
    state = {
        profile:{},
        personal:{},
        branch:"",
        employeestatus:"",
        jobtitle:"",
        reportingmanager:"",
        department:"",
        dateofjoining:"",
        category:"",
        isSupervisor:false,
    }

    static contextType = DataContext

    componentDidMount = () => {
        this.setState({profile:this.props.profile})
        this.setState({
            personal:this.props.profile.personal,
        })
        Object.entries(this.props.profile.personal).forEach(([key, value]) => {
            this.setState({
                [key]:value
            })
        })
    }

    handleChange = (e) => {
        console.log(e.target.name, e.target.checked)
        if(e.target.name === "isSupervisor")
            this.setState({
                [e.target.name] : e.target.checked
            })
        else    
            this.setState({
                [e.target.name] : e.target.value
            })
    }



    handleDone = (e) => {
        const dispatch = this.context[1]
        const personal = {
            branch:this.state.branch,
            employeestatus:this.state.employeestatus,
            jobtitle:this.state.jobtitle,
            reportingmanager:this.state.reportingmanager,
            department:this.state.department,
            dateofjoining:this.state.dateofjoining,
            category:this.state.category,
            isSupervisor:this.state.isSupervisor
        }
        console.log(personal)
        this.setState({
            personal
        })
        
        let finalData = {}
        finalData['personal'] = {
            ...this.state.profile.personal,
            ...personal
        }
        dispatch({
            type : UPDATE_PROFILE,
            payload : {
                email:this.props.email,
                updateValue : finalData
            }
        })
        // this.props.handleUpdateProfile({
        //     category:"manager",
        //     employee:false,
        // }, {
        //     ...this.state.profile.personal,
        //     ...this.state.profile.mailingaddress,
        //     ...personal,
        // })
    }

    handleCancel = () => {
        Object.entries(this.props.profile.personal).forEach(([key, value]) => {
            this.setState({
                [key]:value
            })
        })
        Object.entries(this.props.profile.mailingaddress).forEach(([key, value]) => {
            this.setState({
                [key]:value
            })
        })
    }

    handleDateChange = (key, value) => {
        if(!isNaN(Date.parse(value))) 
        this.setState({
            [key]:new Date(value).toISOString()
        })
    }

    render() {
        const info = {
            branch:this.state.branch,
            employeestatus:this.state.employeestatus,
            jobtitle:this.state.jobtitle,
            reportingmanager:this.state.reportingmanager,
            department:this.state.department,
            dateofjoining:this.state.dateofjoining,
            category:this.state.category,
            isSupervisor:this.state.isSupervisor
        }
        return (
            <div>
                <Presentation 
                    info = {info}
                    {...this.state}
                    profile={this.state.profile}
                    isEditing={this.props.isEditing}
                    handleChange={this.handleChange}
                    handleDone={this.handleDone}
                    handleCancel={this.handleCancel}
                    handleDateChange={this.handleDateChange}
                />
            </div>
        )
    }
}

export default Container
