import React, { useContext } from 'react'
import AllEmployees from '../components/Employee/AllEmployees'
import { DataContext } from '../contexts/data'
function EmployeeListPage(props) {
    const [ state ] = useContext(DataContext)
    console.log(props)
    if((state.access_modules.includes('console-customization') && state.hasOwnProperty("employees_collection")) || (state.access_modules.includes('employees-manager') && state.hasOwnProperty("employees_collection")))
        return (
            <div>
                <AllEmployees />
            </div>
        )
    return <h3>Loading ...</h3>    
}

export default EmployeeListPage
