import React, { useContext } from 'react'
//import EmployeeProfile from '../components/Employee/EmployeeProfile'
import MenuBar from '../components/Employee/MenuBar'
import { DataContext } from '../contexts/data'
const EmployeeProfilePage = (props) => {
    const [state] = useContext(DataContext)
    return (
        <div>
            {
                state.hasOwnProperty('employees_collection') ? 
                    <MenuBar 
                        id = {props.match.params.id}
                    />
                    :
                    <p className="text-center" >Loading employee profile ...</p>
                        
            }
        </div>
    )
}

export default EmployeeProfilePage
