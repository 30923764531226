import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import { UPDATE_PROFILE } from '../../../contexts/types'
import Validations from '../../generalcomponents/validation'
class Container extends Component {

    state={
        employementhistoryData:[],
        clientname:"",
        address:"",
        workingEmailid:"",
        vendorName:"",
        vendorPhone:"",
        vendorEmail:"",
        fromDate:"",
        toDate:"",
        editSection:[]
    }

    static contextType = DataContext

    componentDidMount=()=>{
        let { employementhistoryData } = this.props
        console.log(employementhistoryData)
        if(employementhistoryData)
        for(let i=0;i<employementhistoryData.length;i++){
            if(employementhistoryData.length === 0)
                continue
            employementhistoryData[i]['id']=i+1
        }
        this.setState({
            employementhistoryData
        })
        this.props.handleUpdateProfile("employment",employementhistoryData)
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.employementhistoryData !== this.props.employementhistoryData){
            this.setState({
                employementhistoryData:nextProps.employementhistoryData
            })
            this.props.handleUpdateProfile("employment",nextProps.employementhistoryData)
        }
    }

    handleChange = (key, value) => {
        const validate = new Validations()
        if(key === "workingEmailid" || key === "vendorEmail")
            this.setState({
                [key]:value.toLowerCase()
            })
        else if(key === "dob" && !isNaN(Date.parse(value)))
            this.setState({
                [key]:value.toISOString()
            })
        else if(key !== "dob")
            this.setState({
                [key]:validate.nameFormatterToUpperCase(value)
            })
    }
   


    handleAdd = (e) =>{
            e.preventDefault()
            const dispatch = this.context[1]
            let data = this.state.employementhistoryData
            const appendData={
                client:this.state.clientname,
                clientaddress:this.state.address,
                from:this.state.fromDate,
                to:!isNaN(Date.parse(this.state.fromDate)) ? (!isNaN(Date.parse(this.state.toDate)) ? this.state.toDate : '') : '' ,
                vendoremail:this.state.vendorEmail,
                vendorphone:this.state.vendorPhone,
                vendorname:this.state.vendorName,
                yourworkingmailid:this.state.workingEmailid,
                id:data.length+1
            }
            data = [...data,appendData]
            let finalData = {}
            finalData['employmenthistory'] = data
            dispatch({
                type : UPDATE_PROFILE,
                payload : {
                    email:this.props.email,
                    updateValue : finalData
                }
            })
            // this.props.handleUpdateProfile("employment",data)
            this.setState({
                employementhistoryData:data,
                clientname:"",
                address:"",
                workingEmailid:"",
                vendorName:"",
                vendorPhone:"",
                vendorEmail:"",
                fromDate:"",
                toDate:"",
            })
    }

    handleDelete = (index) =>{
        const dispatch = this.context[1]
        let arr = this.state.employementhistoryData
        arr.splice(index,1)
        let finalData = {}
        finalData['employmenthistory'] = arr
        dispatch({
            type : UPDATE_PROFILE,
            payload : {
                email:this.props.email,
                updateValue : finalData
            }
        })
        // this.props.handleUpdateProfile("employment",arr)
        this.setState({employementhistoryData:arr})
    }
    handleDateChange = (key, value) => {
            this.setState({
                [key]:value
            })
    }

    handleEdit = (item) =>{
        this.setState({editSection:item})
        this.setState({
            clientname:item.client,
            address:item.clientaddress,
            workingEmailid:item.yourworkingmailid,
            vendorName:item.vendorname,
            vendorPhone:item.vendorphone,
            vendorEmail:item.vendoremail,
            fromDate:item.from,
            toDate:item.to,
        })
    }

    handleExisting = (index,item) =>{
        const dispatch = this.context[1]
        let arr = this.state.employementhistoryData
        let changedItem={
            client:this.state.clientname  ,
            clientaddress:this.state.address ,
            from:this.state.fromDate ,
            to:this.state.toDate,
            vendoremail:this.state.vendorEmail  ,
            vendorphone:this.state.vendorPhone ,
            vendorname:this.state.vendorName  ,
            yourworkingmailid:this.state.workingEmailid ,
            id:item.id
        }
        console.log(changedItem)
        arr[index] = changedItem    
        let finalData = {}
        finalData['employmenthistory'] = arr
        dispatch({
            type : UPDATE_PROFILE,
            payload : {
                email:this.props.email,
                updateValue : finalData
            }
        })
        // this.props.handleUpdateProfile("employment",arr)
        this.setState({
            employementhistoryData:arr,
            clientname:"",
            address:"",
            workingEmailid:"",
            vendorName:"",
            vendorPhone:"",
            vendorEmail:"",
            fromDate:"",
            toDate:"",
        })
    }

    clearValues=()=>{
        this.setState({
            clientname:"",
            address:"",
            workingEmailid:"",
            vendorName:"",
            vendorPhone:"",
            vendorEmail:"",
            fromDate:"",
            toDate:"",
        })
    }
    
    render() {
        console.log(this.state)
        return (
            <div>
                {
                    this.state.employementhistoryData ? 
                    <Presentation
                        {...this.state}
                        handleAdd={this.handleAdd}
                        handleChange={this.handleChange}
                        handleDelete={this.handleDelete}
                        handleEdit={this.handleEdit}
                        handleExisting={this.handleExisting}
                        isEditing={this.props.isEditing}
                        clearValues={this.clearValues}
                        handleDateChange={this.handleDateChange}
                    />
                    :
                    <p>Loading...</p>
                }
                
            </div>
        )
    }
}

export default Container
