import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import App from './App';
import DataContextProvider from './contexts/data'
import * as serviceWorker from './serviceWorker';

const THEME = createMuiTheme({
  typography: {
  //  "fontFamily": `-apple-system,BlinkMacSystemFont,"Roboto", "Helvetica", "Arial", sans-serif`,
   "fontSize": 15,
   "fontWeight":400
  }
})

function ReactAPP(){
  return(
    <MuiThemeProvider theme={THEME}>
      <DataContextProvider>
        <App />
      </DataContextProvider>
    </MuiThemeProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ReactAPP/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
