import React, { Component } from 'react';
import Presentation from "./Presentation";
import { PAYMENT_ACTIONS } from "../../../../contexts/types";
import { DataContext } from '../../../../contexts/data'
import { Payment } from '@material-ui/icons';
import { Redirect } from "react-router-dom";
import Validations from '../../../generalcomponents/validation'

class Container extends Component {
    /*
    paymentDetails = [
        {
            clientId: '',
            payment: '',
        }
    ]
    */
    constructor(props) {
        super(props)
        this.validate = new Validations()
    }
    state = {
        date: new Date().toISOString(),
        refNum: "",
        paymentMethod: "",
        paymentAmount: [],
        referenceNumber: '',
        paymentDetails: [],
        paymentOpenBalance: '',
        paymentDiscounts: '',
        invoicedPayments: '',
        clientId: "",
        payment_Doc: [],
        isUploading: false,
        rowData: [],
        payable: [],
        tableData: [],
        list: [],
        redirect: false,
        paymentFilterList: [],
        discount: 0
    }
    static contextType = DataContext
    handleDateChange = (key, value) => {
        if (value === null)
            this.setState({
                [key]: ''
            })
        else if (!isNaN(Date.parse(value)))
            this.setState({
                [key]: value.toISOString()
            })
    }
    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleChange2 = e => {
        if (e.target.files[0]) {
            const file = e.target.files[0];
            this.setState(() => ({ file }));
            this.fileUpload1(file)

        }
    }

    fileUpload1 = (file) => {
        const fileName = [this.state.refNum, '_PD_', this.state.paymentMethod, '_', new Date().toISOString(), '.', file.name.split('.')[1]].join('')
        const filePath = `Payments/${this.state.date}/${fileName}`
        this.setState({ isUploading: true })
        return this.validate.uploadToStorage(file, filePath, fileName, 'file')
            .then(url => {
                this.setState({ payment_Doc: url })
                this.setState({ isUploading: false })
            })
            .catch(err => {
                this.setState({ isUploading: false })
                console.log(err)
            })
    }

    clearState = () => {
        this.setState({
            date: new Date().toISOString(),
            refNum: "",
            paymentMethod: "",
            referenceNumber: '',
            paymentOpenBalance: '',
            paymentDiscounts: '',
            paymentDocument: '',
            rowData: [],
            list: []
        })
    }
    handleCancel = () => {
        this.clearState()
    }
    handleDiscount = (type, value, amount) => {
        let data = 0;
        type === 'byValue'
            ? data =
            !isNaN(parseInt(value))
                ? parseInt(value).toFixed(2)
                : 0.0

            : type === 'byPercentage'
                ? data =
                (amount * (value / 100)).toFixed(2)

                : data = 0
        return data;
    }
    handleSave = (e) => {
        const dispatch = this.context[1]
        e.preventDefault()
        const { date, refNum, paymentMethod, tableData, clientId, payment_Doc, discount } = this.state
        const paymentTables = tableData.filter(item => item.isChecked === true)
        let paymentBalance = paymentTables.map(item => item.subTotal)
        let receivedAmount = paymentTables.map(item => item.receivedAmount)
        let invoiceNumber = paymentTables.map(item => item.invoiceNumber)

        let pay = paymentTables.map(item => item.paymentBalance)
        let paymentDiscounts = paymentTables.map(item => item.paymentDiscount)
        let paymentDetails = []
        for (let index = 0; index < paymentTables.length; index++) {
            paymentDetails.push({
                invoiceNumber: invoiceNumber[index],
                paymentAmount: parseFloat(pay[index]) ,
                paymentTotalBalance: paymentBalance[index],
                paymentOpenBalance: paymentBalance[index] -
                    pay[index] -
                    this.handleDiscount(paymentDiscounts[index].type,
                    parseFloat(paymentDiscounts[index].value), paymentBalance[index]),
                paymentDate: date,
                paymentDiscounts: {
                    name: paymentDiscounts[index].name,
                    type: paymentDiscounts[index].type,
                    value: parseFloat(paymentDiscounts[index].value),

                }
            }
            )

        }
        let payload = {
            paymentDetails: paymentDetails,
            paymentDate: date,
            paymentType: paymentMethod,
            referenceNumber: refNum,
            paymentDocument: payment_Doc,
            clientId: clientId,

        }
         dispatch({
             type: PAYMENT_ACTIONS,
             payload: payload,
             actionType: "new",
             paymentActionsCallBack: this.paymentActionsCallBack()
         })

    }

    paymentActionsCallBack = () => {
        this.setState({
            redirect: true
        })
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to="/console/invoiceslist" />
        }
        return (
            <div>
                <Presentation
                    {...this.props}
                    {...this.state}
                    handleChange={this.handleChange}
                    handleDateChange={this.handleDateChange}
                    handleChange2={this.handleChange2}
                    handleSave={this.handleSave}
                    handleCancel={this.handleCancel}
                />
            </div>
        );
    }
}

export default Container;