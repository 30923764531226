import React, {useContext} from 'react'
import Form from '../Form'
import {DataContext} from '../../../../contexts/data'
function Presentation() {
    const [state] = useContext(DataContext)
    return (
        <div>
            <Form formData={state.invitation_template} />
        </div>
    )
}

export default Presentation
