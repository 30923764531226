import React from 'react';
import ViewClient from "../components/TimeSheets/Clients/ViewClient/Index";

const ViewClientPage = (props) => {
    return (
        <div>
            <ViewClient
                clientId={props.match.params.clientId}
            />
        </div>
    );
}

export default ViewClientPage;