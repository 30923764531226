import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import {FaHandHolding} from 'react-icons/fa'
import {MdAnnouncement, MdTextFormat ,MdEmail} from 'react-icons/md'
import {IoMdPersonAdd} from 'react-icons/io'

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
    marginTop:'100px'
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));



export default function SpeedDials(props) {
  const classes = useStyles();
  const { onInviteAgain, selected } = props
 
  const [open, setOpen] = React.useState(false);


  const actions = [
    { icon: <FaHandHolding size={22} />, name: 'Hold timesheets' },
    { icon: <MdAnnouncement size={22} />, name: 'Send announcement' },
    { icon: <MdTextFormat size={22} />, name: 'Send text message' },
    { icon: <MdEmail  size={22}/>, name: 'Send email' },
    { icon: <IoMdPersonAdd onClick={() => onInviteAgain(selected)}  size={22} />, name: 'Re-Invite' },
  ];

  const handleClose = (action) => {
      console.log(action)
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div  className={classes.root}>
      <div className={classes.exampleWrapper}>
        <SpeedDial
          ariaLabel="SpeedDial example"
          className={classes.speedDial}
          hidden={selected.length  ? false : true}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction="left"
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipPlacement="bottom"
              onClick={() => handleClose(action.name)}
            />
          ))}
        </SpeedDial>
      </div>
    </div>
  );
}
