import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'
import {DataContext} from '../../../contexts/data'
import { textField, phoneField, emailField, selectField, addressFields, fromDateFields, toDateFields, alphaNumFields, zipField, dateField, geoField } from '../../generalcomponents/formFields'

  
function Presentation(props) {
    const { handleChange, data, geoState } = props
    const [ state ] = useContext(DataContext)
    let formData = data.formData
    return (
        <div style={{paddingTop:'30px',paddingBottom:'30px',display:'flex',justifyContent:'space-between'}}>
            <div></div>
            <Grid xs={11} sm={12} >
                <Paper  style={{padding:'15px'}} >
                    {
                        formData.map(portion => {
                            return(
                                <div>
                                    <h3><u>{portion.heading}</u></h3>
                                    <Grid container spacing={3}>
                                        {
                                            portion['fields'].map(item => {
                                                if(item.isExist){
                                                    if(item.type === "text")
                                                        return textField(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section)
                                                    else if(item.type === "email")
                                                        return emailField(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section)
                                                    else if(item.type === "number")
                                                        return phoneField(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section)
                                                    else if(item.type === "zip")
                                                        return zipField(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section)
                                                    else if(item.type === "select")
                                                        return selectField(data[portion['section']], item.label, item.name, item.required, item.values, handleChange, portion.section)
                                                    else if(item.type === "address")
                                                        return addressFields(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section) 
                                                    else if(item.type === "fromdate")
                                                        return fromDateFields(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section, item.maxDate)
                                                    else if(item.type === "todate")
                                                        return toDateFields(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section, item.minDate)
                                                    else if(item.type === "date")
                                                        return dateField(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section)
                                                    else if(item.type === "alphanum")
                                                        return alphaNumFields(data[portion['section']], item.label, item.name, item.required, handleChange, portion.section)
                                                    else if(item.type === "geo"){
                                                        if(portion.section === "mailingaddress" && item.name === "country"){
                                                            const countries = state.hasOwnProperty('geo_data') ? state.geo_data.countries : []
                                                            return geoField(data[portion['section']], item.label, item.name, item.required, countries, geoState, handleChange, portion.section)
                                                        }
                                                        return geoField(data[portion['section']], item.label, item.name, item.required, item.values, handleChange, handleChange, portion.section)
                                                    }
                                                }
                                                return null
                                            })
                                        }
                                    </Grid>
                                    <br/><br/>
                                </div>
                                
                            )
                            
                        })
                    }
                </Paper>
                </Grid>
                <div></div>
        </div>
    )
}

export default Presentation
