import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../contexts/data'
import { NEW_TASK, NEW_SUB_TASK } from '../../../../../contexts/types'
class Container extends Component {
    state = {
        name:"",
        type:"",
        status:"",
        startdate:"",
        enddate:"",
        priority:"",
        labels:[],
        assignee:[],
        description:"",
        projectAssignee : [],
        isCreated : false,
        setReminder : false,
        reminderDate : ""
    }

    static contextType = DataContext

    handleChange = (key, value) => {
        console.log(value)
        if(key === "setReminder" && value === true){
            this.setState({
                status : "Closed",
                setReminder : true
            })
        }
        else if(key === "setReminder" && value === false){
            this.setState({
                status : "",
                setReminder : false
            })
        }
        else if(key === "reminderDate" && !isNaN(Date.parse(value))){
            this.setState({
                reminderDate : new Date(value).toISOString()
            })
        }
        else if(key === "reminderDate" && isNaN(Date.parse(value))){
            this.setState({
                reminderDate : ""
            })
        }
        else if(key !== "reminderDate")
            this.setState({
                [key]:value
            })
    }

    componentDidMount = () => {
        const [ state ] = this.context
        this.setState({projectAssignee : state.project.Users.map(user => user.uid)})
    }

    handleDateChange = (key, value) => {
        if(!isNaN(Date.parse(value))) 
        this.setState({
            [key]:value.toISOString()
        })
    }

    handleCreate = (e) => {
        e.preventDefault()
        const { name, type, status, startdate, enddate, priority, assignee, description,labels, setReminder, reminderDate } = this.state
        const [ state, dispatch ] = this.context
        if(this.props.category === "task"){
            dispatch({
                type:NEW_TASK,
                payload:{
                    data : {
                        title:name,
                        type,
                        status,
                        startdate,
                        enddate,
                        priority,
                        labels,
                        assignee,
                        description,
                        setReminder : setReminder,
                        reminderDate : reminderDate,
                        createdBy:state.employee.email,
                        projectId:this.props.projectId,
                        setReminder: false,
                        reminderDate: ''
                    },
                    isCreated : this.isCreated
                    
                }
            })
        }
        if(this.props.category === "subtask"){
            dispatch({
                type:NEW_SUB_TASK,
                payload:{
                    data : {
                        title:name,
                        type,
                        status,
                        startdate,
                        enddate,
                        priority,
                        labels,
                        assignee,
                        description,
                        createdBy:state.employee.email,
                        projectId:this.props.projectId,
                        taskId:this.props.taskId,
                        setReminder: false,
                        reminderDate: ''
                    },
                    isCreated : this.isCreated
                    }
            })
        }

        this.clearValues()
    }

    isCreated = (bool) => {
        if(bool)
        this.setState({isCreated : true})
    }

    handleDropdown = (key,value) => {
        this.setState({[key] : value})
    }

    componentWillUnmount = () => {
        this.clearValues()
    }


    clearValues = () => {
        this.setState({
            name:"",
            type:"",
            status:"",
            startdate:"",
            enddate:"",
            priority:"",
            labels:[],
            assignee:[],
            description:"",
            projectAssignee : [],
            isCreated : false
        })
    }

    render() {
        const newTask = {
            name : this.state.name,
            type : this.state.type,
            status : this.state.status,
            startdate : this.state.startdate,
            enddate : this.state.enddate,
            priority : this.state.priority,
            assignee : this.state.assignee,
            setReminder: this.state.setReminder,
            reminderDate: this.state.reminderDate
        }

        let errCount = 0
        Object.entries(newTask).forEach(([key, value]) => {
            if((key === "startdate" && isNaN(Date.parse(value)))|| (key === "enddate" && isNaN(Date.parse(value))))
                errCount++;
            else if(key === "assignee" && value.length === 0)
                errCount++;  
            else if(key === "reminderDate" && this.state.setReminder && isNaN(Date.parse(value)))  
                errCount++;   
            else if(typeof(value) === "string" && key !== "reminderDate" && value.trim() === "")
                errCount++;
            else if(key === "reminderDate" && this.state.setReminder && isNaN(Date.parse(value)))  
                errCount++;  
            console.log(key, value)         
        })
        console.log(errCount)
        const [ state ] = this.context
        const id = state.project.id
        const employees = state.projectList.filter(project => project.id === id)[0].Users
        console.log(employees)
        return (
            <div>
                {
                    state.employee.role === 'admin' || state.employee.role === 'manager' || employees.filter(user => user.uid === state.employee.email)[0].create ? 
                        <Presentation
                            {...this.state}
                            errCount = {errCount}
                            text={this.props.text}
                            handleChange = {this.handleChange}
                            handleDateChange = {this.handleDateChange}
                            handleCreate = {this.handleCreate}
                            handleDropdown = {this.handleDropdown}
                            clearValues = {this.clearValues}

                        />
                    :
                        null
                }
                
            </div>
        )
    }
}

export default Container
