import React, { Component } from 'react'
import Presentation from './Presentation'
import { OFFICER_REJECT_LETTER } from '../../../../contexts/types'
import { DataContext } from '../../../../contexts/data'
export class Container extends Component {
    state = {
        description : ''
    }

    static contextType = DataContext

    handleChange = (key, value) => {
        this.setState({
            [key] : value
        })
    }

    handleReject = () => {
        const dispatch = this.context[1]
        dispatch({
            type : OFFICER_REJECT_LETTER,
            payload : {
                offerLetterId : this.props.id,
                description : this.state.description
            }
        })
    }
    render() {
        return (
            <div>
                <Presentation 
                    {...this.state}
                    handleChange={this.handleChange}
                    handleReject={this.handleReject}
                />
            </div>
        )
    }
}

export default Container
