import React from 'react'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'
import { configuration } from '../../../configuration'

export default function Presentation(props) {
  let projectList = []
  console.log(props)
  projectList = props.projectList
  const columns = [
    {
      title: 'Client name',
      field: 'clientname',
      render: (rowData) => (
        <Link style={{ color: 'blue' }} to={'/console/projects/' + rowData.id}>
          {rowData.clientname}
        </Link>
      ),
      width: 'auto',
    },
    { title: 'Start date', field: 'startdate', width: 'auto' },
    { title: 'End date', field: 'enddate', width: 'auto' },
    { title: 'Project ID', field: 'cid', width: 'auto' },
    { title: 'Status', field: 'status', width: 'auto' },
  ]

  let data = []

  projectList.length &&
    projectList.forEach((project) => {
      data.push({
        clientname: project.title,
        id: project.id,
        cid: project.cid,
        startdate:
          project.startdate === ''
            ? project.startdate.toString()
            : Intl.DateTimeFormat(
                configuration['date-code'],
                configuration.dateformat
              ).format(new Date(project.startdate)),
        enddate:
          project.enddate === ''
            ? project.enddate.toString()
            : Intl.DateTimeFormat(
                configuration['date-code'],
                configuration.dateformat
              ).format(new Date(project.enddate)),
        status: project.status,
      })
    })

  return (
    <MaterialTable
      title=''
      columns={columns}
      data={data}
      options={{
        selection: true,
        exportButton: true,
        paginationType: 'stepped',
        pageSize: 20,
      }}
    />
  )
}
