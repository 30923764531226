import React, { Component } from 'react'
import Presentation from './Presentation'
import { Redirect } from "react-router-dom"
import { DataContext } from "../../../../../contexts/data"
import { EXPENSE_ACTIONS } from '../../../../../contexts/types'
import Validations from '../../../../generalcomponents/validation'
import CustomSpinner from '../../../../generalcomponents/circularSpinner'
import { Placement } from '../../../../../contexts/shared/Timesheets/Placements'

class Container extends Component {
  constructor(){
    super()
    this.state = {
      redirect: false,
      spentDate: '',
      departureDate: '',
      returnDate: '',
      placementID: '',
      description: '',
      expenseDoc: '',
      expenseType: '',
      amount: '',
      vendor: '',
      receipt: '',
      additionalDetails: '',
      attachmentDetails: {
        publicURL: '',
        sourcePath: ''
      },
      clientId: '',
      empCode: '',
      isUploading: false,
      expenseSettings: []
    }

    this.validate = new Validations()
  }

  static contextType = DataContext


  componentDidMount = () => {
    const expenseId = this.props.match.params.expenseId
    if(expenseId !== "expense"){
      const expense = this.loadExpenseData(expenseId)
      const { 
        spentDate,
        departureDate,
        returnDate,
        placementID,
        description,
        expenseType,
        attachmentDetails,
        amount,
        vendor,
        receipt,
        additionalDetails,
        empCode,
        clientId
       } = expense
      this.setState({
        spentDate,
        departureDate,
        returnDate,
        placementID,
        description,
        expenseType,
        attachmentDetails,
        amount,
        vendor,
        receipt,
        additionalDetails,
        empCode,
        clientId,
        expenseDoc: additionalDetails.publicURL
      })
    }
  }


  loadExpenseData = (expenseId) => {
    const [state] = this.context
    return state.expenses_list.filter(item => item.id === expenseId)[0]
  }

  expenseActionsCallback = () => {
    this.setState({
      redirect: true
    })
  }

  handleAdd = () => {
    const { 
      spentDate,
      departureDate,
      returnDate,
      placementID,
      description,
      expenseType,
      amount,
      vendor,
      receipt,
      additionalDetails,
      empCode,
      clientId,
      attachmentDetails
     } = this.state
    const dispatch = this.context[1]
    dispatch({
      type: EXPENSE_ACTIONS,
      payload: {
        spentDate,
        departureDate,
        returnDate,
        placementID,
        description,
        expenseType,
        attachmentDetails,
        amount,
        vendor,
        receipt,
        additionalDetails,
        empCode,
        clientId
      },
      expenseId: 'new',
      actionType: 'new',
      expenseActionsCallback: this.expenseActionsCallback
    })
  }


  handleUpdate = () => {
    const { 
      spentDate,
      departureDate,
      returnDate,
      placementID,
      description,
      expenseType,
      amount,
      vendor,
      receipt,
      additionalDetails,
      empCode,
      attachmentDetails
     } = this.state
    const dispatch = this.context[1]
    dispatch({
      type: EXPENSE_ACTIONS,
      payload: {
        spentDate,
        departureDate,
        returnDate,
        placementID,
        description,
        expenseType,
        attachmentDetails,
        amount,
        vendor,
        receipt,
        additionalDetails,
        empCode,
      },
      expenseId: this.props.match.params.expenseId,
      actionType: 'update',
      expenseActionsCallback: this.expenseActionsCallback
    })
  }


  handleChange = (key, value) => {
    if(key === "expenseDoc"){
      const [state] = this.context
      const employeeCompanyID = state.employee.companyID
      const fileName = [this.state.expenseType.toUpperCase(), '_', new Date().toISOString(), '.', value.name.split('.')[1]].join('')
      const filePath = `Expenses/${this.state.placementID}/${fileName}`
      const attachmentDetails = this.state.attachmentDetails
      attachmentDetails['sourcePath'] = filePath
      this.setState({isUploading: true, attachmentDetails: attachmentDetails})
      return this.validate.uploadToStorage(value, filePath, fileName, 'file')
      .then(url => {
          attachmentDetails['publicURL'] = url
          this.setState({ expenseDoc : url, isUploading:false, attachmentDetails: attachmentDetails})
      })
      .catch(err => {
          this.setState({isUploading:false})
          console.log(err)
      })
    }
    this.setState({
      [key]: value
    })
  }

  handleCheckExpenseApproval = async() => {
    const [state] = this.context
    const list = state.expenses_list
    const placementIds = [...new Set(list.map(item => item.placementID))]
    const promises = []
    placementIds.forEach(async(placementID) => {
        const id = state.placements_list.filter(item => item.placementID === placementID)[0].id
        const placementObj = new Placement(id)
        const promise =  placementObj.getSettings("ExpenseDetails")
        promises.push(promise)
    })

    return Promise.all(promises).then((details) => {
        console.log(details)
        this.setState({
            expenseSettings: details.map((detail, index) => {
                return{ 
                    approvalBy:  detail.companyIDs,
                    placementID: placementIds[index]
                }
            })
        })
        return 
    })
    
}

  render() {
    const [state] = this.context
    const { actionType } = this.props.match.params
    if(this.state.redirect)
      return <Redirect to="/console/expenses/list" />
    if(state.hasOwnProperty("placements_list") && state.hasOwnProperty("client_list"))  
    return (
      <div>
        <Presentation 
          {...this.state}
          actionType={actionType}
          handleChange={this.handleChange}
          expenseActionsCallback={this.expenseActionsCallback}
          handleAdd={this.handleAdd}
          handleUpdate={this.handleUpdate}
        />
      </div>
    )
    return <CustomSpinner />
  }
}

export default Container
