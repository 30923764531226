import React, { useContext } from 'react'
import AdminpanelPage from './pages/AdminpanelPage'
import ConsolePage from './pages/ConsolePage'
import ProfilePage from './pages/ProfilePage'
import ProjectListPage from './pages/ProjectListPage'
import ProjectHandlePage from './pages/ProjectHandlePage'
import EmployeeListPage from './pages/EmployeeListPage'
import TaskHandlePage from './pages/TaskHandlePage'
import EmployeeProfilePage from './pages/EmployeeProfilePage'
import InviteEmployeePage from './pages/InviteEmployeePage'
import CreateTemplatePage from './pages/CreateTemplatePage'
import SubtaskPage from './pages/SubtaskPage'
import LetterTemplatePage from './pages/LetterTemplatePage'
import UnAuthorized from './components/generalcomponents/unAuth'
import { DataContext } from './contexts/data'
import { Route, Switch } from 'react-router-dom'
import HistoryPage from './pages/HistoryPage'
import MyRequestsPage from './pages/MyRequestsPage'
import LetterRequestsPage from './pages/LetterRequestsPage'
import WikiPage from './pages/WikiPage'
import CreateArticlePage from './pages/CreateArticlePage'
import ShowArticlePage from './pages/ShowArticlePage'
import ArticleHistoryPage from './pages/ArticleHistoryPage'
import EditArticlePage from './pages/EditArticlePage'
import WikiMorePage from './pages/WikiMorePage'
import RevertChangesPage from './pages/RevertChangesPage'
import CompanyDetailsPage from './pages/CompanyDetailsPage'
import OrgChartPage from './pages/OrgChartPage'
import AuthorizedSignaturePage from './pages/AuthorizedSignaturePage'
import ModuleAccessPage from './pages/ModuleAccessPage'
import WikiSettingsPage from './pages/WikiSettingsPage'
import ChangeTempPasswordPage from './pages/ChangeTempPasswordPage'
import comingSoon from './components/generalcomponents/comingSoon'
import LabelsPage from './pages/LabelsPage'
import PlacementsListPage from './pages/PlacementsListPage'
import ClientsListPage from './pages/ClientListPage'
import ClientFormPage from './pages/ClientFormPage'
import ViewClientPage from './pages/ViewClientPage'
import EmployeeTimesheetPage from './pages/EmployeeTimesheetPage'
import TimesheetsListPage from './pages/TimesheetsListPage'
import PlacementFormPage from './pages/PlacementFormPage'
import CreateInvoicePage from './pages/CreateInvoicePage'
import InvoicesListPage from './pages/InvoicesListPage'
import PlacementEditPage from './pages/PlacementEditPage'
import CreateExpensePage from './pages/CreateExpensePage'
import ExpensesListPage from "./pages/ExpensesListPage"
import DeductionsListPage from "./pages/DeductionsListPage";
import PayrollsPage from './pages/PayrollsPage'
import EmailTemplatesPage from "./pages/EmailTemplatesPage";
function Main() {
  const [state] = useContext(DataContext)

  const modules = [
    {
      path: '/console/wiki',
      component: WikiPage,
      moduleName: 'wiki',
    },
    {
      path: '/console/wiki/:search/create',
      component: CreateArticlePage,
      moduleName: 'wiki',
    },
    {
      path: '/console/wiki/more',
      component: WikiMorePage,
      moduleName: 'wiki',
    },
    {
      path: '/console/wiki/settings',
      component: WikiSettingsPage,
      moduleName: 'wiki',
    },
    {
      path: '/console/wiki/:id/history',
      component: ArticleHistoryPage,
      moduleName: 'wiki',
    },
    {
      path: '/console/wiki/:id',
      component: ShowArticlePage,
      moduleName: 'wiki',
    },
    {
      path: '/console/wiki/:id/edit',
      component: EditArticlePage,
      moduleName: 'wiki',
    },
    {
      path: '/console/wiki/:id/:index',
      component: RevertChangesPage,
      moduleName: 'wiki',
    },
    {
      path: '/console/expenses/submission/:expenseId/:actionType',
      component: CreateExpensePage,
      moduleName: 'timesheets',
    },
    {
      path: '/console/timesheets/submission/:placementID/:timesheetId',
      component: EmployeeTimesheetPage,
      moduleName: 'timesheets',
    },
    {
      path: '/console/placements',
      component: PlacementsListPage,
      moduleName: 'timesheets-manager',
    },
    {
      path: '/console/placements/:id/:docId',
      component: PlacementEditPage,
      moduleName: 'timesheets-manager',
    },
    {
      path: '/console/clientslist',
      component: ClientsListPage,
      moduleName: 'timesheets-manager',
    },
    {
      path : '/console/timesheetslist',
      component : TimesheetsListPage,
      moduleName : 'timesheets'
    },
    {
      path: '/console/invoiceslist',
      component: InvoicesListPage,
      moduleName: 'timesheets',
    },
    {
      path: '/console/deductionslist',
      component: DeductionsListPage,
      moduleName: 'accounts-manager',
    },
    {
      path: '/console/newclient',
      component: ClientFormPage,
      moduleName: 'timesheets-manager',
    },
    {
      path: '/console/clientslist/:clientId',
      component: ViewClientPage,
      moduleName: 'timesheets-manager',
    },
    {
      path: '/console/newplacement/:id',
      component: PlacementFormPage,
      moduleName: 'timesheets-manager',
    },
    {
      path : '/console/invoiceslist/:invoiceId',
      component : CreateInvoicePage,
      moduleName : 'timesheets-manager'
    },
    {
      path : '/console/expenses/list',
      component : ExpensesListPage,
      moduleName : 'timesheets'
    },
    {
      path: '/console/immigration',
      component: comingSoon,
      moduleName: 'immigration',
    },
    {
      path: '/console/createtemplate',
      component: CreateTemplatePage,
      moduleName: 'console-customization',
    },
    {
      path: '/console/projects',
      component: ProjectListPage,
      moduleName: 'task-management',
    },
    {
      path: '/console/projects/:projectId',
      component: ProjectHandlePage,
      moduleName: 'task-management',
    },
    {
      path: '/console/projects/:projectId/labels',
      component: LabelsPage,
      moduleName: 'task-management',
    },
    {
      path: '/console/projects/:projectId/:taskId',
      component: TaskHandlePage,
      moduleName: 'task-management',
    },
    {
      path: '/console/projects/:projectId/:taskId/:subtaskId',
      component: SubtaskPage,
      moduleName: 'task-management',
    },
    {
      path: '/console/myrequests',
      component: MyRequestsPage,
      moduleName: 'employee-self-services',
    },
    {
      path: '/console/letterrequests',
      component: LetterRequestsPage,
      moduleName: 'employee-self-services-manager',
    },
    {
      path: '/console/discussions',
      component: comingSoon,
      moduleName: 'discussions',
    },
    {
      path: '/console/employeelist',
      component: EmployeeListPage,
      moduleName: 'employees-manager',
    },
    {
      path: '/console/employeelist/:id',
      component: EmployeeProfilePage,
      moduleName: 'employees-manager',
    },
    {
      path: '/console',
      component: ConsolePage,
      moduleName: 'console-customization',
    },
    {
      path: '/console/inviteemployee',
      component: InviteEmployeePage,
      moduleName: 'console-customization',
    },
    {
      path: '/console/templates/lettertemplates',
      component: LetterTemplatePage,
      moduleName: 'console-customization',
    },
    {
      path: '/console/templates/emailtemplates',
      component: EmailTemplatesPage,
      moduleName: 'console-customization',
    },
    {
      path: '/console/companydetails',
      component: CompanyDetailsPage,
      moduleName: 'console-customization',
    },
    {
      path: '/console/authorizedsignatures',
      component: AuthorizedSignaturePage,
      moduleName: 'console-customization',
    },
    {
      path: '/console/moduleaccess',
      component: ModuleAccessPage,
      moduleName: 'console-customization',
    },
    {
      path:'/console/payrolls',
      component:PayrollsPage,
      moduleName:'payrolls'
    }
  ]

  return (
    <div>
      <Switch>
        {modules.map(({ path, component, moduleName }) => {
          if (
            state.access_modules.includes(moduleName) ||
            state.access_modules.includes('console-customization')
          )
            return <Route exact path={path} component={component} />
          return <Route exact path={path} component={UnAuthorized} />
        })}
        <Route exact path='/console/orgchart/:id' component={OrgChartPage} />
        <Route exact path='/console/history' component={HistoryPage} />
      </Switch>

      {state.employee.role === 'admin' ? (
        <Switch>
          <Route exact path='/' component={AdminpanelPage} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path='/console/profile' component={ProfilePage} />
          <Route exact path='/' component={ProfilePage} />
        </Switch>
      )}
    </div>
  )
}

export default Main
