import React from 'react'

function Presentation() {
    return (
        <div>
            <h2 className="text-center" >Welcome admin!!!</h2>
        </div>
    )
}

export default Presentation
