import React, { useState, useContext, useEffect } from 'react'
import { DataContext } from '../../../../../contexts/data'
import { Card, Typography, Button, CardActions, CardContent, makeStyles, TextField, MenuItem, Select, FormControl, InputLabel, Input, Paper } from '@material-ui/core'
import { Skeleton } from "@material-ui/lab"
import { FcHome } from "react-icons/fc"
import { RiBuildingLine } from "react-icons/ri"
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { addDays } from "date-fns";
import NumberFormat from 'react-number-format';


const useStyles = makeStyles({
    card: {
        width: '100%',
    }
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function NumberFormatCustom(props) {
    
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            decimalScale={2}
            format="##"
            allowNegative={false}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isNumericString
        />
    );
}

function Presentation(props) {
    const { clientId, empCode, invoiceDate, invoiceDueDate, handleChange, external_address, external_name, placementID, isPOnumberReq, invoiceBy } = props
    const [client, setClient] = React.useState({})
    const [placement, setPlacement] = React.useState({})
    const [netTerms, setNetTerms] = React.useState(0)
    const [state] = React.useContext(DataContext)
    const classes = useStyles()
    const companyInfo = state.company_config
    useEffect(() => {
        setClient(state.client_list.filter(client => client.clientId === clientId)[0])
        setPlacement(state.placements_list.filter(placement => placement.empCode === empCode)[0])
        if (placementID) {
            const req_placement = state.placements_list.filter(placement => placement.placementID === placementID)[0]
            console.log(req_placement)
            handleChange("netTerms", req_placement.netTerms)
            handleChange("invoiceDate", new Date().toISOString())
            handleChange("invoiceDueDate", addDays(new Date(), req_placement.netTerms))
            setNetTerms(req_placement.netTerms)
        }
    }, [clientId, placementID])
    console.log(placementID, netTerms)
    var date = new Date()
    return (
        <Paper className="p-2 mt-1" >
            <div className="" >
                <div className="d-flex">
                    <Card style={{background:"linear-gradient(135deg, #fffcfc, #eaeced 20%, #fffcfc)"}} className={classes.card}>
                        <CardContent>
                            <div className="d-flex" >
                                <div>
                                    <Typography color="textSecondary" gutterBottom>
                                        <FcHome size={80} />  
                                    </Typography>
                                </div>
                                <div>
                                {
                                    state.hasOwnProperty('company_config') ?
                                    <>
                                        <Typography color="textSecondary" gutterBottom>
                                            From address
                                        </Typography>
                                        <Typography variant="h4" >
                                            {companyInfo.companyname}
                                        </Typography>
                                        <Typography>
                                            {companyInfo.contactdetails.address}
                                        </Typography>
                                    </>
                                    :
                                    <div>
                                        <Skeleton />
                                        <Skeleton animation={false} />
                                        <Skeleton animation="wave" />
                                    </div>
                                }    
                                </div>
                            </div>
                            
                            
                        </CardContent>
                    </Card>

                    <Card style={{background:"linear-gradient(135deg, #fffcfc, #eaeced 20%, #fffcfc)"}} className={classes.card}>
                        <CardContent>
                            <div className="d-flex">
                                <div>
                                    <RiBuildingLine size={80} />
                                </div>
                                {
                                    (external_name || external_address) || (typeof (client) === "object" && Object.keys(client).length) ?
                                    <div className="ml-2">
                                        <Typography color="textSecondary" gutterBottom>
                                            Billing address
                                        </Typography>
                                        <Typography variant="h4" >
                                            {invoiceBy === "external"  ? external_name : client.businessDisplayName}
                                        </Typography>
                                        <Typography>
                                            {
                                                invoiceBy === "external" ?
                                                    external_address
                                                    : [client.invoiceLocation.line1, client.invoiceLocation.line2, client.invoiceLocation.city, client.invoiceLocation.state, client.invoiceLocation.zipCode].filter(item => item !== "").join(", ")
                                            }
                                        </Typography>
                                    </div>
                                    :
                                    <div className="w-100" >
                                        <Skeleton />
                                        <Skeleton animation={false} />
                                        <Skeleton animation="wave" />
                                    </div>
                                }
                                
                            </div>
                        </CardContent>
                    </Card>

                </div>
            </div>
            <div className="" >
                <div className="row" >
                    <div className="col-sm mt-2" >
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            label="Net Terms"
                            name="netTerms"
                            value={netTerms}
                            onChange={(e) => {
                                if (e.target.value) {
                                    handleChange("netTerms", e.target.value)
                                    return setNetTerms(e.target.value)
                                }
                            }}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                    </div>
                    {
                        isPOnumberReq ? 
                        <div className="col-sm mt-2" >
                            <TextField
                                fullWidth
                                size="small"
                                onChange={(e) => handleChange("POnumber", e.target.value)}
                                label="PO Number"
                                variant="outlined"
                            />
                        </div> : null
                    }
                    
                    <div className="col-sm" >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                required
                                fullWidth
                                margin="normal"
                                label="Invoice date"
                                value={invoiceDate === "" ? null : invoiceDate}
                                onChange={(date) => {
                                    if (!isNaN(Date.parse(date))) handleChange("invoiceDate", date)
                                }}
                                format="MM/dd/yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-sm" >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                required
                                fullWidth
                                margin="normal"
                                minDate={invoiceDate}
                                value={invoiceDueDate === "" ? null : invoiceDueDate}
                                onChange={(date) => {
                                    if (!isNaN(Date.parse(date))) handleChange("invoiceDueDate", date)
                                }}
                                label="Due Date"
                                format="MM/dd/yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>

                </div>
            </div>
        </Paper>
    )
}

export default Presentation
