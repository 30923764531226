import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
import CircularSpinner from '../../../generalcomponents/circularSpinner'

class Container extends Component {
  state = {}

  static contextType = DataContext

  render() {
    const [state] = this.context
    if (
      state.hasOwnProperty('invoices_list') &&
      state.hasOwnProperty('placements_list') &&
      state.hasOwnProperty('timesheets_list')
    )
      return (
        <div>
          <Presentation {...this.props} />
        </div>
      )
    return <CircularSpinner />
  }
}

export default Container
