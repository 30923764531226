import React, { useContext, useState, useEffect } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import Validations from '../../generalcomponents/validation'
import {
  Avatar,
  TablePagination,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  Chip,
  Badge,
  AppBar,
  Typography,
  Box,
  Button,
  Fab,
  ButtonGroup,
} from '@material-ui/core'
import { DataContext } from '../../../contexts/data'
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import HighlightOff from '@material-ui/icons/HighlightOff'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import PropTypes from 'prop-types'
import SweetAlert from 'react-bootstrap-sweetalert'
import parser from 'html-react-parser'
import { calcTotalTime } from '../Invoices/CreateInvoice/ServiceSummary/Presentation'

import SunEditor from 'suneditor-react'
import TimeSheetsTable from "./TimeSheetsTable";
function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  }
}

function Presentation(props) {
  const {
    placements,
    isLoading,
    approveTimesheet,
    rejectTimesheet,
    listAll,
    description,
    handleChange,
    timesheetSettings,
    empID
  } = props
  const [state] = useContext(DataContext)
  const [value, setValue] = React.useState(0)
  const validate = new Validations()
  const [alert, setAlert] = useState(null)
  const [type, setType] = useState('')
  const [id, setId] = useState('')
  const [placementDocID, setPlacementDocID] = useState('')
  const handleTab = (event, newValue) => {
    setValue(newValue)
  }

  const hideAlert = () => {
    handleChange('description', '')
    setAlert(null)
  }

  useEffect(() => {
    if (alert !== null) {
      showAlert(type, id, placementDocID)
    }
  }, [description])

  console.log(timesheetSettings)

  const checkApprovalAccess = (placementID) => {
    if (state.employee.role === 'admin') return true
    try {
      return (
        timesheetSettings.filter((item) => {
          return item.placementID === 'PC-' + placementID.toString()
        })[0].approvalBy === state.employee.email
      )
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const showAlert = (type, id, placementDocID) => {
    console.log(description)
    setAlert(
      <div>
        <SweetAlert
          closeOnClickOutside={false}
          allowEscape={false}
          warning
          showCancel={false}
          showConfirm={false}
          title='Are you sure you want to approve this timesheet?'
          onConfirm={() => {
            if (type === 'reject') rejectTimesheet(id, placementDocID)
            else approveTimesheet(id, placementDocID)
          }}
          onCancel={hideAlert}
        >
          <SunEditor
            hide={type !== 'reject'}
            placeholder={
              type === 'reject' ? 'Description*' : 'Description(optional)'
            }
            value={description}
            onChange={(data) => handleChange('description', data)}
            setOptions={{
              buttonList: [
                [
                  'undo',
                  'redo',
                  'formatBlock',
                  'bold',
                  'underline',
                  'italic',
                  'strike',
                  'subscript',
                  'superscript',
                  'fontColor',
                  'hiliteColor',
                  'removeFormat',
                  'outdent',
                  'indent',
                  'align',
                  'horizontalRule',
                  'list',
                  'lineHeight',
                  'table',
                  'link',
                ],
              ],
            }}
          />
          <br />
          <div>
            <div>
              <Button onClick={hideAlert} variant='contained' color='default'>
                cancel
              </Button>
              {'  '}
              <Button
                onClick={() => {
                  setAlert(null)
                  type === 'reject'
                    ? rejectTimesheet(id, placementDocID)
                    : approveTimesheet(id, placementDocID)
                }}
                disabled={type === 'reject' ? description === '' : false}
                variant='contained'
                color='primary'
              >
                {type === 'reject' ? 'Reject' : 'Approve'}
              </Button>
            </div>
          </div>
        </SweetAlert>
      </div>
    )
  }

  const columns = [
    {
      title: 'Employee',
      field: 'empCode',
      render: (rowData) => (
        <div className='d-flex'>
          <Avatar src={rowData.imageURL} />
          <div className='d-flex flex-column'>
            <span>
              {' '}
              <Link to={'/console/employeelist/' + rowData.empCode}>
                {rowData.empCode}
              </Link>{' '}
            </span>
            <span>{rowData.email} </span>
          </div>
        </div>
      ),
    },
    {
      title: 'Client ID',
      field: 'clientId',
      render: (rowData) => (
        <Link to={'/console/clientslist/' + rowData.clientId.toString()}>
          <Chip
            className='bg-dark text-light w-75 c-pointer'
            label={rowData.clientId.toString()}
          />
        </Link>
      ),
    },
    {
      title: 'Duration',
      field: 'duration',
      render: (rowData) => (
        <div>
          <Link
            to={
              '/console/timesheets/submission/' +
              rowData.placementDocID +
              '/' +
              rowData.id
            }
          >
            <Tooltip title={rowData.startDate + '-' + rowData.endDate}>
              <Chip
                className={
                  rowData.isRejected
                    ? 'bg-danger text-white w-100'
                    : rowData.isApproved
                    ? 'bg-success text-white w-100'
                    : 'w-100'
                }
                label={rowData.startDate + '-' + rowData.endDate}
              />
            </Tooltip>
          </Link>
        </div>
      ),
    },
    { title: 'Hrs', field: 'hrs' },
    { title: 'Attached File', field: 'attachment' },
    { title: 'Comment', field: 'comment' },
    {
      title: 'Actions',
      field: 'actions',
      render: (rowData) => {
        if (rowData.isApproved)
          return (
            <div>
              {rowData.empCode === state.employee.companyID ? null : checkApprovalAccess(rowData.placementID) ? (
                <IconButton
                  onClick={() => {
                    setType('reject')
                    setId(rowData.id)
                    setPlacementDocID(rowData.placementDocID)
                    showAlert('reject', rowData.id, rowData.placementDocID)
                  }}
                  color='secondary'
                >
                  <HighlightOff />
                </IconButton>
              ) : null}
            </div>
          )
        else if (rowData.isRejected)
          return (
            <div>
              {rowData.empCode === state.employee.companyID ? (
                <Link
                  to={
                    '/console/timesheets/submission/' +
                    rowData.placementDocID +
                    '/' +
                    rowData.id
                  }
                >
                  <IconButton color='default'>
                    <EditIcon />
                  </IconButton>
                </Link>
              ) : checkApprovalAccess(rowData.placementID) ? (
                <IconButton
                  onClick={() =>
                    showAlert('approve', rowData.id, rowData.placementDocID)
                  }
                  color='primary'
                >
                  <CheckCircleOutline />
                </IconButton>
              ) : null}
            </div>
          )
        else if (!rowData.isApproved && !rowData.isRejected)
          return (
            <div>
              {rowData.empCode === state.employee.companyID ? (
                <Link
                  to={
                    '/console/timesheets/submission/' +
                    rowData.placementDocID +
                    '/' +
                    rowData.id
                  }
                >
                  <IconButton color='default'>
                    <EditIcon />
                  </IconButton>
                </Link>
              ) : checkApprovalAccess(rowData.placementID) ? (
                <>
                  <IconButton
                    onClick={() =>
                      showAlert('approve', rowData.id, rowData.placementDocID)
                    }
                    color='primary'
                  >
                    <CheckCircleOutline />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setType('reject')
                      setId(rowData.id)
                      setPlacementDocID(rowData.placementDocID)
                      showAlert('reject', rowData.id, rowData.placementDocID)
                    }}
                    color='secondary'
                  >
                    <HighlightOff />
                  </IconButton>
                </>
              ) : null}
            </div>
          )
      },
    },
  ]

  const formatAndSumHours = (timesheet) => {
    // const standard = details.standardTime
    // const OT = details.OTtime
    const standardTime = calcTotalTime(timesheet.workdetails.standardTime)
    const OTtime = calcTotalTime(timesheet.workdetails.OTtime)
    let [hours, minutes] = standardTime.split(':').map((item) => Number(item))
    hours += parseInt(OTtime.split(':')[0], 10)
    minutes += parseInt(OTtime.split(':')[1], 10)
    hours = parseInt(hours + minutes / 60, 10)
    minutes = minutes % 60
    minutes =
      minutes.toString().length === 2
        ? minutes
        : '0'.repeat(2 - minutes.toString().length) + minutes.toString()
    return hours + ':' + minutes
  }

  let filteredData = []
  if (listAll) filteredData = state.timesheets_list
  else
    filteredData = state.timesheets_list.filter(
      (item) =>
        item.empCode ===
        state.names.filter(
          (item) => item.uid === empID || item.companyID === empID
        )[0].companyID
    )

  const data = filteredData.map((timesheet) => {
    return {
      empCode: timesheet.empCode,
      imageURL: validate.getImage(timesheet.empCode),
      email: state.names.filter(
        (item) => item.companyID === timesheet.empCode
      )[0].uid,
      placementID: parseInt(timesheet.placementID.slice(3)),
      clientId: timesheet.clientId,
      startDate: validate.dateFormatter(timesheet.startDate),
      endDate: validate.dateFormatter(timesheet.endDate),
      createdAt: validate.dateFormatter(timesheet.createdAt),
      timesheetDocURL: timesheet.timesheetDocURL,
      isApproved: timesheet.isApproved,
      isRejected: timesheet.isRejected,
      hrs: formatAndSumHours(timesheet),
      id: timesheet.id,
      comment: timesheet.isRejected
        ? parser(timesheet.rejectDetails.description)
        : '',
      placementDocID: state.placements_list.filter(
        (item) => item.placementID === timesheet.placementID
      )[0].id,
    }
  })


  const CustomTable = (props) => {
    return (
      <MaterialTable
        title=''
        data={props.info}
        columns={columns}
        options={{
          paginationType: 'stepped',
          pageSize: 10,
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div
                style={{
                  marginTop: '-38px',
                  marginLeft: '8px',
                  marginBottom: '10px',
                }}
              >
                <Tooltip title='New submission'>
                  <ButtonGroup
                    variant='contained'
                    color='primary'
                    aria-label='outlined primary button group'
                  >
                    <Link to='/console/timesheets/submission/new/timesheet'>
                      <Button color='primary' variant='contained'>
                        New Timesheet
                      </Button>
                    </Link>
                  </ButtonGroup>
                </Tooltip>
                <Tooltip title='New Expense'>
                  <ButtonGroup
                    variant='contained'
                    color='primary'
                    aria-label='outlined primary button group'
                  >
                    <Link to='/console/expenses/submission/expense/new'>
                      <Button color='primary' variant='contained'>
                        New Expense
                      </Button>
                    </Link>
                  </ButtonGroup>
                </Tooltip>
              </div>
            </div>
          ),
        }}
      />
    )
  }

  const checkOverdue = (startDate, endDate) => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const diff = start - end
    return diff
  }

  return (
    <div>
      <div>{alert}</div>
      <AppBar position='static' color='default'>
        <Tabs
          value={value}
          onChange={handleTab}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          <Tab
            label={
              <Badge
                color='primary'
                children='Submitted'
                badgeContent={
                  data.filter((item) => !item.isApproved && !item.isRejected)
                    .length
                }
              />
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Badge
                color='primary'
                children='Approved'
                badgeContent={data.filter((item) => item.isApproved).length}
              />
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Badge color='error' children='Defaulters' badgeContent={0} />
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <Badge
                color='error'
                children='Rejected'
                badgeContent={data.filter((item) => item.isRejected).length}
              />
            }
            {...a11yProps(3)}
          />
          {(state.access_modules.includes('timesheets-manager') && listAll) ||
          (state.access_modules.includes('console-customization') &&
            listAll) ? (
            <Tab
              label={
                <Badge
                  color='error'
                  children='My Timesheets'
                  badgeContent={
                    data.filter(
                      (item) => item.empCode === state.employee.companyID
                    ).length
                  }
                />
              }
              {...a11yProps(4)}
            />
          ) : null}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TimeSheetsTable
          info={data.filter((item) => !item.isApproved && !item.isRejected)}
          list={props}
          handleChange={handleChange}
          rejectTimesheet={rejectTimesheet}
          approveTimesheet={approveTimesheet}
          description={description}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TimeSheetsTable info={data.filter((item) => item.isApproved)}
        list={props}
        handleChange={handleChange}
        rejectTimesheet={rejectTimesheet}
        approveTimesheet={approveTimesheet}
        description={description} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* <CustomTable info={data.filter(item => )} /> */}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TimeSheetsTable info={data.filter((item) => item.isRejected)} 
        list={props}
        handleChange={handleChange}
        rejectTimesheet={rejectTimesheet}
        approveTimesheet={approveTimesheet}
        description={description}/>
      </TabPanel>
      {(state.access_modules.includes('timesheets-manager') && listAll) ||
      (state.access_modules.includes('console-customization') && listAll) ? (
        <TabPanel value={value} index={4}>
          <TimeSheetsTable
            info={data.filter(
              (item) => item.empCode === state.employee.companyID
            )}
            list={props}
            handleChange={handleChange}
            rejectTimesheet={rejectTimesheet}
            approveTimesheet={approveTimesheet}
            description={description}
          />

        </TabPanel>
      ) : null}
    </div>
  )
}

export default Presentation
