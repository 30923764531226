import React, { useContext, useState } from 'react'
import { DataContext } from '../../contexts/data'
import firebase, {storage} from '../../firebase_config'
import { configuration } from '../../configuration'
import { calcTotalAmount, calcTotalTime } from "../TimeSheets/Invoices/CreateInvoice/ServiceSummary/Presentation";

export default class Validations {
  // for converting email to name
  emailToName = (id) => {
    const [state] = useContext(DataContext)
    try {
      return state.names.filter(item => item.uid === id || item.companyID === id)[0].name  
    } catch (error) {
      return id
    }  
  }

  checkSupervisor = (uid) => {
    const [state] = useContext(DataContext)
    try {
      return state.names.filter(item => item.uid === uid)[0].isSupervisor ? true : false 
    } catch (error) {
      return false
    } 
  }

  checkDesignation = (uid) => {
    const [state] = useContext(DataContext)
    try {
      if(state.names.filter(item => item.uid === uid)[0].designation === "User")
        return "Employee"
      return state.names.filter(item => item.uid === uid)[0].designation 
    } catch (error) {
      return ""
    } 
  }

  getGender = (id) => {
    const [state] = useContext(DataContext)
    try {
      return state.names.filter(item => item.uid === id || item.companyID === id)[0].gender
    } catch (error) {
      return ""
    } 
  }

  // for checking valid name or not
  checkName = (name) => {
    const exp = /^[a-zA-Z \.]{1,}$/
    return exp.test(name.trim())
  }

  checkNumber = (number) => {
    const exp = /\D+/g
    const expForChar = /^\d+$/
    if(!expForChar.test(number.trim()))
      return false
    let clearNum = number.replace(exp, "")
    return clearNum.length === 10 
  }

  getEmployeeCollection = (uid) => {
    const [state] = useContext(DataContext)
    try {
        return state.employees_collection.filter(item => item.useremail === uid)[0]
    } catch (error) {
      return {}
    } 
  }

  checkZip = (number) => {
    const exp = /\D+/g
    const expForChar = /^\d+$/
    if(!expForChar.test(number.trim()))
      return false
    let clearNum = number.replace(exp, "")
    return clearNum.length > 4 && clearNum.length < 9
  }

  checkFloatNumber = (number) => {
    const exp = /\D+/g 
    const expForChar = /[\d.][\d.]/
    if(!expForChar.test(number.trim()))
      return false
    let clearNum = number.replace(exp, "")
    return clearNum.length < 5
  }

  checkDateDiff = (startdate, enddate) => {
    return new Date(enddate) - new Date(startdate) >= 0 ? true : false 
  }

  checkEmail = (email) => {
    const exp = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    try {
      return exp.test(email.toLowerCase().trim())
    } catch (error) {
      return false
    }
  }

  checkWebsite = (website) => {
    const exp = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/
    try {
      return exp.test(website.toLowerCase().trim())
    } catch(error) {
      return false
    }
  }

  nameFormatterToUpperCase = (name) => {
    try {
      return name[0].toUpperCase() + name.substring(1).toLowerCase()
    } catch (error) {
      return ""
    }
  }



  dateFormatter = (date) => {
      let final = ""
      try {
          final = Intl.DateTimeFormat(configuration["date-code"],configuration.dateformat).format(new Date(date))
      } catch (error) {
          final = date
      }
      return final
  }

  dateWithNoTime = (date) => {
    let final = ""
    try {
          const year= new Date(date).getFullYear()
          const month= new Date(date).getMonth()
          const day= new Date(date).getDate()
        final = new Date(year, month, day)
    } catch (error) {
        final = date
    }
    return final
  }
  
  dateAndTimeFormatter = (timestamp) => {
    let final = ""
      try {
          final = Intl.DateTimeFormat(configuration["date-code"],{...configuration.dateformat,...configuration.timeformat}).format(new Date(timestamp))
      } catch (error) {
          final = timestamp
      }
      return final
  }

  getImage = (id) => {
    const [state] = useContext(DataContext)
    let url = ""
    try {
      state.names.forEach(name => {
        if(name.uid === id || name.companyID === id)
          url = name.photoURL
      })
    } catch (error) {
        url = ""
    }

    return url
  }

  uploadToStorage = (file, filePath, fileName, type, storageListener) => {
    const sizeValidate = {
      'profilePic' : {
        size : 5000000,   // 5 MB
        exp : '5MB'
      },
      'file' : {
        size : 10000000,  // 10 MB
        exp : '10MB'
      },
    }
    return new Promise((resolve, reject) => {
      if(file.size > sizeValidate[type].size){
        reject('invalid size')
        alert("File size should be less than "+sizeValidate[type].exp)
      }
      else{   
        const storageRef = storage.ref(filePath)
        storageRef.put(file).on('state_changed', 
          (snapshot) => {
            const progress1 = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log(progress1)
          }, 
          (error) => {
            console.log('upload failed')
            reject(error)
          }, 
          () => {
              storageRef.getDownloadURL().then(url => {
                console.log('uploaded')
                resolve(url)
            })
          })
      }
    })
  }

  getPaymentsInfo = (placementID, timesheets, discountDetails) => {
    const [state] = useContext(DataContext)
    const [paymentsInfo, setPaymentsInfo] = useState([])
    const placement = state.placements_list.filter(item => item.placementID === placementID)[0]
    const docId = placement.id
    firebase.firestore().collection("Placements").doc(docId).collection("Payments").limit(1).get().then(snap => {
      const data = snap.docs[0].data()
      setPaymentsInfo(data)
    })
    return paymentsInfo
  }

  getTotalAmount = (placementID, timesheets, discountDetails) => {
    const data = this.getPaymentsInfo(placementID, timesheets, discountDetails)
    let totalAmount = 0
    timesheets.forEach(timesheet => {
      totalAmount += calcTotalAmount(calcTotalTime(timesheet.workdetails.standardTime), data.employeePayRate)
      totalAmount += calcTotalAmount(calcTotalTime(timesheet.workdetails.OTtime), data.OTpayRate)
    })
    return totalAmount
  }

  getDiscount = (placementID, timesheets, discountDetails) => {
    const totalAmount = this.getTotalAmount(placementID, timesheets, discountDetails)
    let discount = 0
    discountDetails["type"] === "byValue"
        ? discount =  !isNaN(parseInt(discountDetails["value"])) ? parseInt(discountDetails["value"]).toFixed(2) : 0.00
        : discountDetails["type"] === "byPercentage"
          ? discount = (totalAmount * (discountDetails["value"] / 100)).toFixed(2)
          : discount = 0
    return discount
  }

  getInvoiceAmount = (placementID, timesheets, discountDetails) => {
    const totalAmount = this.getTotalAmount(placementID, timesheets, discountDetails)
    const discount = this.getDiscount(placementID, timesheets, discountDetails)

    return (totalAmount - discount)
  }


}

