import React, { Component } from 'react';
import Presentation from "./Presentation";
import { PAYMENT_ACTIONS } from "../../../../contexts/types";
import { DataContext } from '../../../../contexts/data'
import { Payment } from '@material-ui/icons';

class Container extends Component {

    state = {
        discount: 0,
        discountDetails:{
            name:"",
            type:"",
            value:0
        }
    }
    handleEvent = (key, value) => {
        this.setState({
            [key]: value

        })
    }
    handleDiscount = (type, value, amount) => {
        let data = 0;
        type === 'byValue'
            ? data =
            !isNaN(parseInt(value))
                ? parseInt(value).toFixed(2)
                : 0.0

            : type === 'byPercentage'
                ? data =
                (amount * (value / 100)).toFixed(2)

                : data = 0
        return data;
    }
    handleSave = () => {
        const { data, invoicesList, rowData, paymentHandleChange, handleChange } = this.props
        let value = this.handleDiscount(this.state.discountDetails["type"],this.state.discountDetails["value"],rowData.subTotal)
        let list = JSON.stringify(invoicesList)
        list = JSON.parse(list)
        list[rowData.index]["paymentDiscount"] = this.state.discountDetails
        list[rowData.index]["paymentDiscountAmount"] =value
        handleChange("invoicesList", list)
        let row = JSON.stringify(rowData)
        row = JSON.parse(row)
        row["paymentDiscount"] = this.state.discountDetails
        data[rowData.index] = row
        console.log(list)
        paymentHandleChange("tableData", data)

    }
    handleCancel = () => {
        const { data, invoicesList, rowData, paymentHandleChange, handleChange } = this.props
        let list = JSON.stringify(invoicesList)
        list = JSON.parse(list)
        list[rowData.index]["paymentDiscount"] = rowData.paymentDiscount
        console.log(list)
        handleChange("invoicesList", list)
        let row = JSON.stringify(rowData)
        row = JSON.parse(row)
        row["paymentDiscount"] = rowData.paymentDiscount
        data[rowData.index] = row
        console.log(list)
        paymentHandleChange("tableData", data)

    };

    render() {
        return (

            <div>
                <Presentation
                    {...this.props}
                    {...this.state}
                    handleEvent={this.handleEvent}
                    handleSave={this.handleSave}
                    handleCancel={this.handleCancel}
                    handleDiscount={this.handleDiscount}
                />

            </div>
        );
    }
}

export default Container;