import React, { useContext } from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File
import {
  TextField,
  Paper,
  Button,
  Typography,
  makeStyles,
  Grid,
} from '@material-ui/core'
import { DataContext } from '../../../../contexts/data'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  layout: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
}))

export default function Presentation(props) {
  const {
    handleContent,
    handleChange,
    handleCreate,
    cancelUpdate,
    handleUpdate,
    name,
    handleDelete,
    helperText,
    content,
  } = props
  const [state] = useContext(DataContext)
  const isUpdating =
    state.hasOwnProperty('email_content') && state.email_content.updating
  const classes = useStyles()
  return (
    <div>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component='h1' variant='h4' align='center'>
            {!isUpdating ? 'New Template' : 'Edit Template'}
          </Typography>
          <React.Fragment>
            <Typography variant='h6' gutterBottom></Typography>
            <form onSubmit={isUpdating ? handleUpdate : handleCreate}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label='Enter template name'
                    onChange={handleChange}
                    size='small'
                    value={name}
                    name='name'
                    fullWidth
                    required
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={12}>
                  <SunEditor
                    onChange={handleContent}
                    setContents={content}
                    setOptions={{
                      buttonList: [
                        [
                          'undo',
                          'redo',
                          'font',
                          'fontSize',
                          'formatBlock',
                          'paragraphStyle',
                          'blockquote',
                          'bold',
                          'underline',
                          'italic',
                          'strike',
                          'subscript',
                          'superscript',
                          'fontColor',
                          'hiliteColor',
                          'textStyle',
                          'removeFormat',
                          'outdent',
                          'indent',
                          'align',
                          'horizontalRule',
                          'list',
                          'lineHeight',
                          'table',
                          'link',
                          'image',
                          'video',
                          'fullScreen',
                          'showBlocks',
                          'codeView',
                          'preview',
                          'print',
                          'save',
                          'template',
                        ],
                      ],
                      minHeight: 200
                    }}
                  />
                </Grid>
                {/* <TextField
            select
            label='Choose Type'
            style={{ width: 250 }}
            value={type}
            name='type'
            required
            onChange={handleChange}
          >
            <MenuItem value=''>None</MenuItem>
            {configuration.letterTypes.map((option) => (
              <MenuItem
                key={option.replace(/ /g, '').toLowerCase()}
                value={option.replace(/ /g, '').toLowerCase()}
              >
                {option}
              </MenuItem>
            ))}
          </TextField> */}
              </Grid>
              {/* {helperText.length ? (
                <Typography color='error'>
                  Check these labels with reference labels
                </Typography>
              ) : null}
              <div>
                {helperText.map((item) => (
                  <div>
                    <Typography color='error'>
                      <li>{item}</li>
                    </Typography>
                  </div>
                ))}
                </div> */}
            </form>
            <br />
            <div className='text-center mt-4'>
              {isUpdating ? (
                <span>
                  {helperText.length ? (
                    <Button
                      variant='contained'
                      className='flairbtn'
                      disabled
                      type='button'
                    >
                      Update letter
                    </Button>
                  ) : (
                    <Button
                      variant='contained'
                      className='flairbtn'
                      type='submit'
                      onClick={handleUpdate}
                    >
                      Update letter
                    </Button>
                  )}{' '}
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={cancelUpdate}
                  >
                    Cancel
                  </Button>
                </span>
              ) : helperText.length ? (
                <Button
                  variant='contained'
                  type='button'
                  color='primary'
                  disabled
                >
                  Create new template
                </Button>
              ) : (
                <Button
                  variant='contained'
                  type='submit'
                  color='primary'
                  onClick={handleCreate}
                >
                  Create new template
                </Button>
              )}
            </div>
            {isUpdating ? (
              <div className='text-right'>
                <Tooltip title='Delete this template'>
                  <Button
                    variant='contained'
                    color='secondary'
                    type='button'
                    onClick={handleDelete}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </div>
            ) : null}
          </React.Fragment>
        </Paper>
      </main>
    </div>
  )
}
