import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';



function Presentation(props) {
    const {handleConfirmPass,handleSetNewPass,handleSetPass,helperEight, newPass, confirmPass}=props
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
   
    const handleClose = () => {
      setOpen(false)
    };
    const handlechangepass=()=>{
      handleSetNewPass();
        handleClose()
    }
    
    return (
        <div>
                <div className='p-4 rounded shadow bg-white' >
                  <div className="m-2">
                        <TextField fullWidth type="password" onChange={handleSetPass} label="New password"  />
                  </div><br></br>
                  <div className="m-2">
                      <TextField fullWidth type="password" label="Confirm new password" onChange={handleConfirmPass}/>
                  </div><br/>
                  {newPass !== confirmPass ?<span className="helperText text-danger">Passwords doesn't match</span>:null} 
                  <br></br>
                  <div className="m-2">
                  <h6 style={{fontSize:"small"}}>Make sure it's {helperEight?<span className="text-danger"> at least 8 characters </span>:<span className="text-success"> at least 8 characters</span>}</h6>
                      {newPass.length>7 && newPass===confirmPass ? <Button color="primary" variant="contained" onClick={handlechangepass}>Change Password</Button> : <Button variant="contained" color="primary" disabled>Change password</Button>  } {' '}
                      {/* <Button onClick={handleClose} variant="contained" color="primary">Cancel</Button> */}
                  </div>
                </div>  
            </div>
        
    )
}

export default Presentation