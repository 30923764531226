import React, { Component } from 'react'
import Presentation from './Presentation'
class Container extends Component {
    render() {
        return (
            <div>
                <Presentation
                    taskList = {this.props.taskList}
                    type = {this.props.type}
                />
            </div>
        )
    }
}

export default Container
