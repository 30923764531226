import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../contexts/data'
import CustomSpinner from '../../../../generalcomponents/circularSpinner'
export class Container extends Component {
    static contextType = DataContext
    render() {
        const [state] = this.context
        if(state.hasOwnProperty("client_list"))
            return (
                <div>
                    <Presentation 
                        {...this.props}
                    />
                </div>
            )
        return <CustomSpinner />    
    }
}

export default Container
