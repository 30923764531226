import React, { Component } from 'react'
import Presentation from './Presentation'
import { EMPLOYEE_REQUEST_LETTER } from '../../../../contexts/types'
import { DataContext } from '../../../../contexts/data'

export class Container extends Component {

    static contextType = DataContext

    handleRequest = (type, description ,req_doc, univDetails) => {
        const dispatch = this.context[1]
        dispatch({
            type : EMPLOYEE_REQUEST_LETTER,
            payload : {
                DocType : type,
                description : description,
                attachment:{
                    publicURL : req_doc,
                    sourcePath : ''
                },
                additionalDetails : {
                    universityDetails : univDetails
                }
            }
        })

    }
    render() {
        return (
            <div>
                <Presentation 
                    {...this.props}
                    handleRequest={this.handleRequest}
                />
            </div>
        )
    }
}

export default Container
