import React, { Component } from 'react';
import Presentation from "./Presentation";
import { DataContext } from '../../../../../contexts/data'
import { TEMPORARY_NEW_CLIENT_DATA, SET_COUNTRY_API } from '../../../../../contexts/types'
import Axios from 'axios'

class Container extends Component {
    state = {
        businessName: '',
        contactNumber: '',
        website: '',
        jobTerminationNotice: '',
        category: '',
        businessDisplayName: '',
        email: '',
        federalId: '',
        netTerms: '',
        fax: '',
        line1: '',
        line2: '',
        country: '',
        state_name: '',
        city: '',
        zipCode: '',
        states: []
    }

    static contextType = DataContext

    handleAdd = (e) => {
        const dispatch = this.context[1]
        const { businessName, contactNumber, website, jobTerminationNotice, category, businessDisplayName, email, federalId, netTerms, fax, line1, line2, country, state_name, city, zipCode } = this.state
        dispatch({
            type: TEMPORARY_NEW_CLIENT_DATA,
            payload: {
                key: 'businessInformation',
                businessInformation: {
                    businessName: businessName,
                    contactNumber: contactNumber,
                    website: website,
                    jobTerminationNotice: jobTerminationNotice,
                    category: category,
                    businessDisplayName: businessDisplayName,
                    email: email,
                    federalId: federalId,
                    netTerms: netTerms,
                    fax: fax,
                    permanentPlacement: {
                        netTerms: '',
                        commissionType: '',
                        commission: ''
                    },
                    invoiceLocation: {
                        line1: line1,
                        line2: line2,
                        city: city,
                        state: state_name,
                        country: country,
                        zipCode: zipCode
                    },
                }
            },
        })
        const { handleChange } = this.props
        handleChange(e, 1)
    }

    componentDidMount = async () => {
        const [state, dispatch] = this.context
        dispatch({
            type: SET_COUNTRY_API,
            payload: dispatch
        })
        if (state.hasOwnProperty('temporary_client_data') && state.temporary_client_data.businessInformation) {
            Object.entries(state.temporary_client_data.businessInformation).forEach(([key, value]) => {
                if(key === 'invoiceLocation') {
                    Object.entries(state.temporary_client_data.businessInformation.invoiceLocation).forEach(([key, value]) => {
                        if(key === 'state') {
                            this.setState({ state_name: value})
                        }
                        this.setState({
                            [key]: value
                        })
                    })
                } else {
                    this.setState({
                        [key]: value
                    })
                }
            })

        }
    }

    geoState = async (code) => {
        let states = await Axios.post("/loadstates", { countrycode: code })
        this.setState({ states: states.data })
    }

    handleCountryAndState = (name, value) => {
        this.setState({
            [name]: value.name
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {
        const [state] = this.context
        if (state.hasOwnProperty('temporary_client_data') && state.temporary_client_data.businessInformation) {
            if(Object.keys(state.temporary_client_data.businessInformation).length) {
                return (
                    <div>
                        <Presentation
                            {...this.state}
                            {...this.props}
                            handleChange={this.handleChange}
                            handleAdd={this.handleAdd}
                            geoState={this.geoState}
                            handleCountryAndState={this.handleCountryAndState}
                        />
                    </div>
                );
            }
            return <p>Loading....</p>
        }
        return (
            <div>
                <Presentation
                    {...this.state}
                    {...this.props}
                    handleChange={this.handleChange}
                    handleAdd={this.handleAdd}
                    geoState={this.geoState}
                    handleCountryAndState={this.handleCountryAndState}
                />
            </div>
        );
    }
}

export default Container;