import React, { useContext } from 'react'
import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Button,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core'
import IOSSwitch from '../../../../generalcomponents/iosSwitch'
import Validations from '../../../../generalcomponents/validation'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DataContext } from '../../../../../contexts/data'
import NumberFormat from 'react-number-format'

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}))

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalScale={2}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      isNumericString
    />
  )
}

const Presentation = (props) => {
  const [state] = useContext(DataContext)
  const classes = useStyles()
  let countryList = state.hasOwnProperty('geo_data')
    ? state.geo_data.countries
    : []
  const validate = new Validations()
  const {
    states,
    geoState,
    handleCountryAndState,
    handleChecked,
    amendmentRequired,
    workLocationType,
    workEmail,
    workPhoneNumber,
    phoneExtension,
    addressLine1,
    addressLine2,
    city,
    state_name,
    country,
    zipCode,
    handleChange,
    handleSave,
    fillableSections,
  } = props

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              select
              label='Work Location Type'
              size='small'
              value={workLocationType}
              fullWidth
              required
              onChange={(event) =>
                handleChange('workLocationType', event.target.value)
              }
            >
              {workLocationTypes.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              label='Work Email'
              size='small'
              fullWidth
              helperText={
                workEmail.length
                  ? validate.checkEmail(workEmail)
                    ? ''
                    : 'Enter valid work email'
                  : ''
              }
              value={workEmail}
              onChange={(event) =>
                handleChange('workEmail', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <TextField
                  variant='outlined'
                  label='Work Phone'
                  size='small'
                  fullWidth
                  helperText={
                    workPhoneNumber.length
                      ? validate.checkNumber(workPhoneNumber)
                        ? ''
                        : 'Enter valid work phone number'
                      : ''
                  }
                  value={workPhoneNumber}
                  onChange={(event) =>
                    handleChange('workPhoneNumber', event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant='outlined'
                  label='Ext.'
                  size='small'
                  fullWidth
                  value={phoneExtension}
                  onChange={(event) =>
                    handleChange('phoneExtension', event.target.value)
                  }
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              label='Address Line 1'
              size='small'
              fullWidth
              required
              value={addressLine1}
              onChange={(event) =>
                handleChange('addressLine1', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              label='Address Line 2'
              size='small'
              fullWidth
              // required
              value={addressLine2}
              onChange={(event) =>
                handleChange('addressLine2', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              label='City'
              size='small'
              fullWidth
              required
              value={city}
              onChange={(event) => handleChange('city', event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id='country-select-demo'
              fullWidth
              options={countryList}
              classes={{
                option: classes.option,
              }}
              //inputValue={country ? country : null}
              onChange={(_, value) => {
                if (value) {
                  handleCountryAndState('country', value)
                  return geoState(value.iso2)
                }
              }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <React.Fragment>
                  <span>{countryToFlag(option.iso2)}</span>
                  {option.name} ({option.iso2}) +{option.phone_code}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Country *'
                  variant='outlined'
                  size='small'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <p className='text-success'>Selected country:{country} </p>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id='country-select-demo'
              fullWidth
              options={states}
              classes={{
                option: classes.option,
              }}
              onChange={(_, value) => {
                if (value) {
                  handleCountryAndState('state_name', value)
                }
              }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <React.Fragment>{option.name}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='State *'
                  variant='outlined'
                  size='small'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <p className='text-success'>Selected state:{state_name} </p>
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              label='Zip Code'
              size='small'
              fullWidth
              required
              value={zipCode}
              helperText={
                zipCode.length
                  ? validate.checkZip(zipCode)
                    ? ''
                    : 'Enter valid zip code'
                  : ''
              }
              onChange={(event) => handleChange('zipCode', event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch />}
                name='amendmentRequired'
                checked={amendmentRequired}
                onChange={handleChecked}
                label='Amendment Required'
              />
            </FormGroup>
          </Grid>
        </Grid>
        <br />
        {fillableSections.includes('worklocation') ? (
          workLocationType.trim().length &&
          addressLine1.trim().length &&
          city.trim().length &&
          state_name.trim().length &&
          country.trim().length &&
          validate.checkZip(zipCode) ? (
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={() => handleSave('create')}
            >
              Done
            </Button>
          ) : (
            <Button type='button' variant='contained' color='primary' disabled>
              Done
            </Button>
          )
        ) : workLocationType.trim().length &&
          addressLine1.trim().length &&
          city.trim().length &&
          state_name.trim().length &&
          country.trim().length &&
          validate.checkZip(zipCode) ? (
          <Button
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() => handleSave('update')}
          >
            Update
          </Button>
        ) : (
          <Button type='button' variant='contained' color='primary' disabled>
            Update
          </Button>
        )}
      </form>
    </div>
  )
}

export default Presentation

const workLocationTypes = ['Onsite', 'Remote']
