import React from 'react'
import CreateTemplate from '../components/InvitationTemplate/FormBuilder/Index'
function CreateTemplatePage() {
    return (
        <div>
            <CreateTemplate />
        </div>
    )
}

export default CreateTemplatePage
