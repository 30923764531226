import React, { useState, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import {
    DataTypeProvider,
    TreeDataState, SortingState, SelectionState, FilteringState, PagingState,
    CustomTreeData, IntegratedFiltering, IntegratedPaging, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table, TableHeaderRow, TableFilterRow, TableTreeColumn,
    PagingPanel, TableColumnResizing, Toolbar, TableColumnVisibility, ColumnChooser,
} from '@devexpress/dx-react-grid-material-ui';
import { DataContext } from '../../../../contexts/data'
import Validation from '../../../generalcomponents/validation'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import { configuration } from '../../../../configuration'
import NewTask from '../../Task/taskcomponents/NewTask'
import FilterListIcon from '@material-ui/icons/FilterList';
import {Tooltip, Typography,withStyles,} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { differenceInDays } from "date-fns"

import { FaFilter } from "react-icons/fa";
const TaskIdFormatter = ({ row }) => (
    <Chip size="small" label={`${row.projectTaskId}`} />
);
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip)
const formatter = (date) => {
    let final = ""
    try {
        final = Intl.DateTimeFormat(configuration["date-code"], configuration.dateformat).format(new Date(date))
    } catch (error) {
        console.log(error)
        final = date
    }
    return final
}


const customStyles = {
    employeeExist: {
        color: '#4183c4',
        textTransform: 'capitalize'
    },
    employeeNonExist: {
        color: '#626466',
        textTransform: 'capitalize',
    }
}

const TitleFormatter = ({ row }) => (
    <div style={{ display: "flex" }} >
        <span>
            {
                row.category === "task" ?
                <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography
                      className='text-center'
                      color='inherit'
                    >
                      {row.title}
                    </Typography>
                  </React.Fragment>
                }
              >
                <Link
                 to={"/console/projects/" + row.projectId + "/" + row.id}
                  style={{ marginLeft: '4px' }}
                >
                  {row.title.trim().length > 35 ? (
                    <Typography variant='body1'>
                      {row.title.trim().substring(0, 35) + '...'}
                      <br />
                    </Typography>
                  ) : (
                    <Typography variant='body1'>
                      {row.title.trim()}
                      <br />
                    </Typography>
                  )}
                </Link>
              </HtmlTooltip>
                    :
                    <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography
                          className='text-center'
                          color='inherit'
                        >
                          {row.title}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <Link
                    to={"/console/projects/" + row.projectId + "/" + row.taskId + "/" + row.id}
                      style={{ marginLeft: '4px' }}
                    >
                      {row.title.trim().length > 35 ? (
                        <Typography variant='body1'>
                          {row.title.trim().substring(0, 35) + '...'}
                          <br />
                        </Typography>
                      ) : (
                        <Typography variant='body1'>
                          {row.title.trim()}
                          <br />
                        </Typography>
                      )}
                    </Link>
                  </HtmlTooltip>
            }
            <p style={{ fontSize: "14px" }}>
                {
                    row.type === "Task" ?
                        <Chip size="small" style={{ backgroundColor: "#17a2b8", color: "white" }} label="Task" />
                        :
                        <Chip size="small" style={{ backgroundColor: "#db2828", color: "white" }} label="Bug" />
                }
                &nbsp;&nbsp;&nbsp;
                {
                    row.status === "Open"
                        ?
                        <Chip variant="outlined" size="small" style={{ borderColor: "#21ba45", fontWeight: "bold", color: "#21ba45" }} label="Open" />
                        :
                        row.status === "InProgress"
                            ?
                            <Chip variant="outlined" size="small" style={{ borderColor: "#f78a14", fontWeight: "bold", color: "#f78a14" }} label="InProgress" />
                            :
                            <Chip variant="outlined" size="small" label="Closed" />
                }
                &nbsp;&nbsp;&nbsp;
                {formatter(row.startdate)}
                &nbsp;&nbsp;&nbsp;
                {formatter(row.enddate)}
            </p>
        </span>
    </div>
)
const LabelsFormatter = ({ row }) => (
    row.labels.map(element => <span>
        <Chip size="small" style={{ backgroundColor: `${element.colorCode}`, color: "white", marginBottom: '2px' }} label={element.name} />
        <br />
    </span>)
)


export default function Presentation(props) {

    const [state] = useContext(DataContext)

const [open,setOpen] = React.useState(false)
const handleClickOpen = () => {
  
    setOpen(!open)
   
    
}
    let type = props.type
    let taskList = props.taskList
    let labels = state.project.useLabels
    const validate = {
        emailToName: (uid) => {
            try {
                return state.names.filter(item => item.uid === uid)[0].name
            } catch (error) {
                console.log(error)
                return uid
            }
        }
    }
console.log(open)
    const Validate = new Validation()

    const columns = [
        {
            name: 'projectTaskId', title: <span>
                Task Id  &nbsp; 
               
        
          <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
        
     
            </span>, getCellValue: row => row.projectTaskId
        },
        {
            name: 'labels', title: <span>
            Labels  &nbsp; 
            <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
        </span>, getCellValue: row => row.labels.map(e => e.name)
        },
        {
            name: 'title', title: <span>
            Title  &nbsp; 
            <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
        </span>, getCellValue: row => row.title
        },
        {
            name: 'createdByName', title: <span>
            Created By  &nbsp; 
            <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
        </span>
        },
        {
            name: 'dueby', title: <span>
            Due By  &nbsp; 
            <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
        </span>
        },
        {
            name: 'priority', title: <span>
            Priority  &nbsp; 
            <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
        </span>
        },
        {
            name: 'assignee', title: <span>
            Assignee  &nbsp; 
            <FaFilter   size={15} onClick={handleClickOpen}  title="Filter" />
        </span>, getCellValue: row => (row.assignee)
        }
    ]
    let rows = []
    dayjs.extend(relativeTime)

    const diff = dayjs()
    const AssigneeFormatter = ({ row }) => (
        row.assignee.map(employee =>
            <span>
                <Link to={"/console/employeelist/" + employee} >
                    <Button type="a" style={state.projectList.filter(project => project.id === state.project.id)[0].Users.map(user => user.uid).includes(employee) ? customStyles.employeeExist : customStyles.employeeNonExist} size="small">
                        {validate.emailToName(employee)}
                    </Button>
                </Link>
                <br />
            </span>
        )

    )
    const getChildRows = (row, rows) => {

        if (type === "SubTask" ) {
            const childRows = rows.filter(r => r.taskId === (row ? row.id :  r.taskId));
            return childRows.length ? childRows : null;
        }
       
        else {
            const childRows = rows.filter(r => r.taskId === (row ? row.id : ""));
            return childRows.length ? childRows : null;
        }


    };
    const getDueBy = (enddate, status) => {
        console.log(new Date() - new Date(enddate))
        if (status === 'Completed' || status === 'Closed')
            return false
        return new Date().setHours(0, 0, 0, 0) - new Date(enddate).setHours(0, 0, 0, 0) > 0 ? true : false
    }

    const formatDueBy = (enddate, status) => {
        if(getDueBy(enddate, status)){
            const days = differenceInDays(new Date().setHours(0, 0, 0, 0), new Date(enddate).setHours(0, 0, 0, 0))
            if(days === 1)
                return days.toString() + " day"
            return days.toString() + " days"
        }
        return '---'
    }
    const [pageSizes] = useState([5, 10, 20]);
    const projectLabels = state.projectList.filter(item => item.id === state.project.id)[0].labels
    taskList.length && taskList.forEach(task => {
        rows.push({
            title: task.title,
            id: task.id,
            cid: task.cid,
            startdate: task.startdate,
            enddate: task.enddate,
            status: task.status,
            assignee: task.assignee,
            createdByName: validate.emailToName(task.createdBy),
            type: task.type,
            labels: projectLabels.filter(item => task.hasOwnProperty('labels') && task.labels.includes(item.id)),
            createdBy: task.createdBy,
            projectTaskId: state.project.cid + " - " + task.cid,
            projectId: state.project.id,
            projectList: state.projectList,
            category: task.category,
            priority: task.priority,

            taskId: task.category === "subtask" ? task.taskId : "",
            dueby: formatDueBy(task.enddate, task.status)

        })
    })

    console.log(rows)
    const [defaultColumnWidths] = useState([
        { columnName: 'projectTaskId', width: 175 },
        { columnName: 'labels', width: 150 },
        { columnName: 'title', width: 300 },
        { columnName: 'createdByName', width: 200 },
        { columnName: 'dueby', width: 110 },
        { columnName: 'priority', width: 120 },
        { columnName: 'assignee', width: 125 },
    ]);
    const [defaultHiddenColumnNames] = useState(["hii"]);
    const [tableColumnExtensions] = useState([
        { columnName: 'Completion' },
    ]);
    const [employeeColumns] = useState(['Assigned_Employee_ID']);
    const [TaskIdColumns] = useState(["projectTaskId"]);
    const [TitleColumns] = useState(["title"])
    const [AssigneeColumns] = useState(["assignee"])
    const [LabelsColumns] = useState(["labels"])

    return (
        
        <Paper>
           
          
           <NewTask projectId={state.project.id} text="New task" category="task" />
          
           
            <Grid
                marginTop="20px"
                rows={rows}
                columns={columns}
                
            >
{  open ? <FilteringState 
                /> : ''}
                <TreeDataState />

                <SortingState />
                <SelectionState />
                <PagingState
                    defaultCurrentPage={0}
                    defaultPageSize={pageSizes[1]}

                />

                <CustomTreeData
                
                    getChildRows={getChildRows}
                />
                <DataTypeProvider
                    for={TaskIdColumns}
                    formatterComponent={TaskIdFormatter}
                />
                <DataTypeProvider
                    for={TitleColumns}
                    formatterComponent={TitleFormatter}
                />
                <DataTypeProvider
                    for={AssigneeColumns}
                    formatterComponent={AssigneeFormatter}
                />
                <DataTypeProvider
                    for={LabelsColumns}
                    formatterComponent={LabelsFormatter}
                />
                 { open ? <IntegratedFiltering 
               /> : ''}
                <IntegratedSelection />
                <IntegratedSorting />
                <IntegratedPaging />

                <Table
                    columnExtensions={tableColumnExtensions}
                />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableColumnResizing
                    defaultColumnWidths={defaultColumnWidths}
                />
                <TableHeaderRow
                    showSortingControls
                />
              
               
              {
              open ?
              <TableFilterRow
              showFilterSelector={true}
            //   iconComponent={<FilterListIcon/>} 
            /> :""
              }
            
              
                
                <TableTreeColumn
                    for="projectTaskId"
                />

                <Toolbar />

                <PagingPanel
                    pageSizes={pageSizes}

                />
            </Grid>
        </Paper>
    );
};
