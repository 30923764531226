import React, { Component } from 'react'
import Presentation from './Presentation'
import { UPDATE_PROFILE } from '../../../contexts/types'
import { DataContext } from '../../../contexts/data'
import Alert from '@material-ui/lab/Alert';

class Container extends Component {
    state = {
        profile : {},
        emergencycontactData : [],
        employementhistoryData : [],
        workauthData : [],
        educationhistory:[],
        personal:{},
        mailingaddress:{},
        isEmployeeFound : false,
        isEmployeeInactive : false
    }
    static contextType = DataContext

    componentDidMount = () => {
        const [ state] = this.context
        const id =this.props.id
        let profile = {}
        let emergencycontactData = []
        let employementhistoryData = []
        let workauthData = []
        let educationhistory = []
        let personal = {}
        let mailingaddress = {}
        profile = state.employees_collection.filter(employee => {
            return employee.companyID === id || employee.useremail === id
        })[0]
        if(profile){
            this.setState({isEmployeeFound:true})
            if(profile.status === "Inactive")
                this.setState({isEmployeeInactive:true})
            else   { 
                this.setState({isEmployeeInactive:false})
                emergencycontactData = profile.emergencycontact
                employementhistoryData = profile.employmenthistory
                workauthData = profile.workauth
                personal = profile.personal
                mailingaddress = profile.mailingaddress
                console.log(profile)
                educationhistory = profile.hasOwnProperty('educationhistory') ? profile.educationhistory : []
                this.setState({
                    profile,
                    emergencycontactData,
                    employementhistoryData,
                    workauthData,
                    educationhistory,
                    personal,
                    mailingaddress
                })
            }
        }
        else
            this.setState({isEmployeeFound:false})
    }

    handleUpdateProfile = (key,data) =>{
        if(key === "emergency")
            this.setState({emergencycontactData:data})
        if(key === "employment")
            this.setState({employementhistoryData:data})
        if(key === "workauth")
            this.setState({workauthData:data})
        if(key === "educationhistory")
            this.setState({educationhistory:data}) 
        else if(key.category === "employee" && key.employee === true){
                console.log(data)
                const personal={
                    firstname:data.firstname,
                    lastname:data.lastname,
                    middlename:data.middlename,
                    emailid:data.emailid,
                    phonenumber:data.phonenumber,
                    gender:data.gender,
                    maritalstatus:data.maritalstatus,
                }
                const mailingaddress = {
                    city : data.city,
                    country : data.country,
                    line1 : data.line1,
                    line2 : data.line2,
                    state : data.state,
                    zip : data.zip
                } 
                console.log(personal, mailingaddress)
                this.setState({
                    personal:{
                        ...this.state.personal,
                        ...personal
                    },
                    mailingaddress
                })
            }
            else if(key.category === "manager" && key.employee === false){
                console.log(data)
                const personal={
                    branch:data.branch,
                    employeestatus:data.employeestatus,
                    jobtitle:data.jobtitle,
                    reportingmanager:data.reportingmanager,
                    department:data.department,
                    dateofjoining:data.dateofjoining,
                    category:data.category,
                    isSupervisor:data.isSupervisor
                }
                this.setState({
                    personal:{
                        ...this.state.personal,
                        ...personal
                    },
                })
            }
    
            console.log(data)        
        } 
    handleUpdate = () => {
        this.setState({isEditing:false})
        this.setState({isUpdating:true})
        console.log(this.state.emergencycontactData,this.state.employementhistoryData,this.state.workauthData)
        
       
        const dispatch = this.context[1]
        dispatch({
            type:UPDATE_PROFILE,
            payload:{
                uid:localStorage.getItem("ownerUID"),
                email:this.state.profile.useremail,
                emergencycontact:this.state.emergencycontactData,
                employmenthistory:this.state.employementhistoryData,
                workauth:this.state.workauthData,
                educationhistory:this.state.educationhistory,
                personal:this.state.personal,
                mailingaddress:this.state.mailingaddress
            }   
        })

    }

    componentWillUnmount = () => {
        this.setState({
            isEmployeeFound : false,
            isEmployeeInactive : false
        })
    }

  
    render() {
        const [ state] = this.context
        if(!this.state.isEmployeeFound)
            return <Alert className="text-center" variant="filled" severity="error">No employee found</Alert>
        if(this.state.isEmployeeFound && this.state.isEmployeeInactive)
            return <Alert className="text-center" variant="filled" severity="warning">Invited {this.props.id}, but still not registered.</Alert>    
        return (
            <div>{
                state.hasOwnProperty("employees_collection") && state.hasOwnProperty("names") && Object.keys(this.state.profile).length ? 
                    <Presentation 
                        { ... this.state}
                        id = {this.props.id}
                        handleUpdate = {this.handleUpdate}
                        handleUpdateProfile = {this.handleUpdateProfile}
                    />
                    :
                    null
                }
                
            </div>
        )
    }
}

export default Container
