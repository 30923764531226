import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'

class Container extends Component {
    state = {
        selected : [],
        originalList : []
    }

    static contextType = DataContext
    componentDidMount = () => {
        const [state] = this.context
        this.setState({originalList : state.employees_collection.map(employee => employee.useremail)})
    }

    handleSelect = (employee) => {
        if(this.state.selected.includes(employee)){
            const index = this.state.selected.indexOf(employee)
            let newList = this.state.selected
            newList.splice(index, 1)
            this.setState({selected:newList})
        }
        else{
            let append = [...this.state.selected, employee]
            this.setState({selected:append})
        }
    }

    handleSelectAll= (e, employeelist) =>{
        let allMails=[]
        employeelist.forEach((item)=>{
            if(this.state.originalList.includes(item))
                allMails.push(item)
        })
        if(e.target.checked){
            this.setState({selected:allMails})
        }
        else{
            this.setState({selected:[]})
        }
    }
    render() {
        return (
            <div>
                <Presentation 
                    {...this.state}
                    handleSelect = {this.handleSelect}
                    handleSelectAll={this.handleSelectAll}
                />
            </div>
        )
    }
}

export default Container
