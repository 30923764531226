import React from 'react'
import { withRouter } from 'react-router-dom'
import {Icon,Label} from 'semantic-ui-react'
import {Form,FormGroup,Input,Progress, Button} from 'reactstrap'
import Prompt from '../../generalcomponents/snackBars'
import {render} from 'react-dom'
import axios from 'axios'
import {storage} from '../../../firebase_config';
import { configuration } from '../../../configuration'
import Template from '../Template'
class UserForm extends React.Component{
    state={
        registering:false,
        currID:"",
        veridicID:"",
        avatar: "",
        upload:false,
        isUploading: false,
        isUploading1:false,
        progress1:0,
        progress: 0,
        avatarURL: null,
        passMatch1:"",
        passMatch2:"",
        password:"",
        setPassword:"",
        confirmPassword:"",
        passwordLength:0,
        documentURL:null,
        image:null,
        file:null,
        personalinformation:{},
        mailingaddress:{},
        emergencycontact:{},
        employementhistory:{},
        workauthorization:{},
        personalOk:false,
        mailingOk:false,
        emergencyOk:false,
        workOk:false
    }

    handleStateSet = (name, value) => {
        this.setState({
            [name] : value
        })
        if(name === "personalinformation"){
            let personalError = 0
            Object.entries(value).forEach(([k, v]) => {
                if(k !== "middlename" && v === ""){
                    personalError++
                }
            })
            if(personalError === 0)
                this.setState({personalOk:true})
            else
                this.setState({personalOk:false})
        }
        else if(name === "mailingaddress"){
            let mailingError = 0
            Object.entries(value).forEach(([k, v]) => {
                if(k !== "line2" && v === ""){
                    mailingError++
                }
            })
            if(mailingError === 0)
                this.setState({mailingOk:true})
            else
                this.setState({mailingOk:false})    
        }
        else if(name === "emergencycontact"){
            let emergencyError = 0
            Object.entries(value).forEach(([k, v]) => {
                if(k !== "emailid" && v === ""){
                    emergencyError++
                }
            })
            if(emergencyError === 0)
                this.setState({emergencyOk:true})
            else
                this.setState({emergencyOk:false})         
        }
        else if(name === "workauthorization"){
            let workError = 0
            Object.entries(value).forEach(([k, v]) => {
                if(v === ""){
                    workError++
                }
            })
            if(workError === 0)
                this.setState({workOk:true})
            else
                this.setState({workOk:false})    
        }


    }   
    
    handleChangePass=(e)=>{
        this.setState({
            [e.target.id]:e.target.value
        })
    }
    Register=(e)=>{
        e.preventDefault();
        this.setState({registering:true})

        let profileData={
            Role:"User",
            imageURL:this.state.avatarURL,
            employeestatus:"bench",
            workauth:[{...this.state.workauthorization,work_doc:this.state.documentURL}],
            employmenthistory:[this.state.employementhistory],
            emergencycontact:[this.state.emergencycontact],
            mailingaddress:this.state.mailingaddress,
            personal:this.state.personalinformation,
            educationhistory:[]
        }

        axios.post(`${configuration.REST_api}/validateinvitetoken`,{
            email:this.props.email,
            password:this.state.setPassword,
            ActionBy:this.props.email,
            profileData:profileData,
            PhotoURL:this.state.avatarURL,
            token:this.props.token
        })
        .then(res=>{
            alert("You have been successfully registered!")
            this.props.history.push("/login")
        })
        .catch(err=>{
            this.setState({registering:false})
            alert("Failed to register!")
            console.log(err)
        })
     // end of register function 
    }
    
    handleChange1 = e => {
      if (e.target.files[0]) {
        const image = e.target.files[0];
        this.setState(() => ({image}));
        this.ImgUpload(image)
      }
      else{

      }
    }

    ImgUpload = (image) =>{
      var size=5000000
             if(image.type==='image/png' || image.type==='image/jpeg' || image.type==='image/jpg'){               
          if(image.size>size){
            render(
                    <Prompt open={true} content="Image size should be less than 5MB" severity="error" />,
                    document.getElementById('notifications-box')
                )  
        this.setState({image:null})
        }
  else{   
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on('state_changed', 
      (snapshot) => {
        // progrss function ....
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        this.setState({progress});
      }, 
      (error) => {
           // error function ....
        console.log(error);
      }, 
    () => {
        // complete function ....
        storage.ref('images').child(image.name).getDownloadURL().then(url => {
            this.setState({url});
            this.setState({ avatarURL: url,upload:true}); 
            this.setState({image:null})
            this.setState({progress:1})
        })
    });
  }
    }
    else {
        render(
            <Prompt open={true} content="You Are Allowed To Choose (jpeg,png,jpg) Images" severity="error" />,
            document.getElementById('notifications-box')
        )  
      this.setState({image:null}) 
    }}
    handleChange = e => {
      if (e.target.files[0]) {
        const image = e.target.files[0];
        this.setState(() => ({image}));
        this.fileUpload1(image)
      }
      else{

      }
    }

    fileUpload1 = (file) =>{
      var size=10000000
          if(file.size>size){
            render(
                <Prompt open={true} content="File size should be less than 10MB" severity="error" />,
                document.getElementById('notifications-box')
            )  
      this.setState({file:null})
      }
  else{   
      const uploadTask = storage.ref(`files/${file.name}`).put(file);
      uploadTask.on('state_changed', 
      (snapshot) => {
        // progrss function ....
        const progress1 = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        this.setState({progress1});
      }, 
      (error) => {
           // error function ....
        console.log(error);
      }, 
    () => {
        // complete function ....
        storage.ref('files').child(file.name).getDownloadURL().then(url => {
            this.setState({url});
            this.setState({ documentURL:url,upload:true});
            this.setState({file:null})
            this.setState({progress1:100})
        })
    });
  }
    }
   
    render(){
        console.log(this.state)
        return(
             <Form className="widthsetter p-4 bg-light m-5 rounded shadow ml-auto mr-auto" onSubmit={this.Register}>
                 <div className="text-center">
                 {this.state.isUploading?<Progress animated striped color="success" className="" value={this.state.progress} />:<p></p>}
                 <div>
                 {this.state.avatarURL ? <img height="150" alt="" className="rounded" src={this.state.avatarURL} />:null}
                 </div>
                    <label class="fileContainer"><p>Profile pic<span className="req-field">*</span></p>
                    <input type="file" accept=".jpg, .jpeg, .png" size="60" onChange={this.handleChange1}/></label>
                
                
                 </div>
                <Template registeringEmail={this.props.email} handleStateSet={this.handleStateSet} />
                                
                <label><p>Work authorization document:<span className="req-field">*</span></p>{this.state.progress1===100?<Icon name="checkmark" color="green"/>:null}    
                    <input type="file" size="60" required="required" onChange={this.handleChange}/></label>

                <FormGroup>
                    <label for="setPassword">Set Password</label>
                    <Input autoComplete={false} type="password" onChange={this.handleChangePass} name="password" id="setPassword" placeholder="" />
                </FormGroup>
                <FormGroup>
                    <label for="confirmPassword">Confirm Password</label>
                    <Input autoComplete={false} type="password" name="confirmPassword" onChange={this.handleChangePass} id="confirmPassword" placeholder="" />
                    <br/>
                    {this.state.setPassword===this.state.confirmPassword&&this.state.setPassword!==""?<Label><Icon name="checkmark" color="green"/>Passwords must match</Label>:<Label>Passwords must match</Label>}
                   {this.state.setPassword.length<8?<Label>Min 8 characters needed</Label>:<Label><Icon name="checkmark" color="green"/>Min 8 characters needed</Label>}
                </FormGroup>
                <br/>
                 
                {this.state.registering?<Button type="button" className="btn btn-primary w-100" disabled>Registering...</Button>:(this.state.setPassword.length>7&&this.state.setPassword===this.state.confirmPassword&&this.state.personalOk&&this.state.mailingOk&&this.state.emergencyOk&&this.state.workOk&&this.state.avatarURL&&this.state.documentURL? <Button className="btn btn-success w-100"  type="submit">Register</Button>:<Button className="btn btn-primary w-100" type="button" disabled>Register</Button>) }
                <br/> <br/>
                {this.state.personalOk&&this.state.mailingOk&&this.state.workOk&&this.state.avatarURL&&this.state.documentURL&&this.state.emergencyOk?"":<label>Some of the fields are missing in the following sections-- </label>}<br/>
                {this.state.personalOk?"":<Label color='red'>Personal Information</Label>}
                {this.state.mailingOk?"":<Label color='red'>Mailing Address</Label>}
                {this.state.workOk?"":<Label color='red'>Work Authorization</Label>}
                {this.state.avatarURL?"":<Label color='red'>Profie Pic</Label>}
                 {this.state.documentURL?"":<Label color='red'>Document</Label>} 
                 {this.state.emergencyOk?"":<Label color='red'>Emergency Contact</Label>}
             </Form>
        )
    }
    
}

export default withRouter(UserForm)
