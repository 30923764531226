import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
import { OFFICER_ISSUE_LETTER, GET_SELECTED_LETTER_CONTENT } from '../../../../contexts/types'
export class Container extends Component {
    state = {
        letter : '',
        today_date : '',
        signature : '',
        description : '',
        gettingContent : false,
        letterContent : ''
    }
    
    static contextType = DataContext


    handleDateChange = (key, value) => {
        if(!isNaN(Date.parse(value))) 
        this.setState({
            [key]:new Date(value).toISOString()
        })
    }

    handleChange = (key, value) => {
        this.setState({
            [key] : value
        })
    }

    handleGrant = async(e) => {
        e.preventDefault()
        const { letter, today_date, signature, description } = this.state
        const [state, dispatch] = this.context
        let pdfDetails = {
            universityname : this.props.reqData.additionalDetails.universityDetails.schoolname,
            universityaddress : this.props.reqData.additionalDetails.universityDetails.address
        }

        const authorisedPersonName = state.employees_collection.filter(employee => employee.useremail === signature)[0].personal
        dispatch({
            type : OFFICER_ISSUE_LETTER,
            payload : {
                offerLetterId : letter,
                letterIssueDate : today_date,
                authorisedSignature : this.props.signatures.filter(sign => sign.uid === signature)[0].signature.publicURL,
                authorisedPerson : [authorisedPersonName.firstname, authorisedPersonName.middlename, authorisedPersonName.lastname].join(' '),
                authorisedPersonDesignation : state.employees_collection.filter(employee => employee.useremail === signature)[0].Role,
                authorisedPersonUID : signature,
                employeeUID : this.props.reqData.uid,
                requestId : this.props.reqData.id,
                description : description,
                pdfDetails : pdfDetails
            }
        })

        this.clearValues()
    }

    getSelectedLetterContent = async() => {
        if(this.state.letter !== ''){
            this.setState({gettingContent : true})
            const [state, dispatch] = this.context
            const { letter, today_date, signature } = this.state
            let pdfDetails = {
                universityname : this.props.reqData.additionalDetails.universityDetails.schoolname,
                universityaddress : this.props.reqData.additionalDetails.universityDetails.address,
            }

            const authorisedPersonName = state.employees_collection.filter(employee => employee.useremail === signature)[0].personal
            dispatch({
                type : GET_SELECTED_LETTER_CONTENT,
                payload : {
                    offerLetterId : letter,
                    letterIssueDate : today_date,
                    authorisedSignature : this.props.signatures.filter(sign => sign.uid === signature)[0].signature.publicURL,
                    authorisedPerson : [authorisedPersonName.firstname, authorisedPersonName.middlename, authorisedPersonName.lastname].join(' '),
                    authorisedPersonDesignation : state.employees_collection.filter(employee => employee.useremail === signature)[0].Role,
                    pdfDetails : pdfDetails,
                    employeeUID : this.props.reqData.uid,
                },
                response : this.response
            })
        }
    }

    response = (bool, content) => {
        this.setState({gettingContent : false})
        if(bool){
            this.setState({letterContent : content})
        }
    }

    clearValues = () => {
        this.setState({
            letter : '',
            today_date : '',
            signature : '',
            description : '',
            gettingContent : false,
            letterContent : ''
        })
    }
    render() {
        const info = {
            letter : this.state.letter,
            today_date : this.state.today_date,
            signature : this.state.signature,
            description : this.state.description
        }
        let allValuesFilled = 0
        Object.entries(info).forEach(([key, value]) => {
            if(key === 'today_date' && isNaN(Date.parse(value)))
                allValuesFilled ++
            else if(value === '')
                allValuesFilled ++
        })
        console.log(allValuesFilled)
        return (
            <div>
                <Presentation 
                    {...this.props}
                    letterContent={this.state.letterContent}
                    gettingContent={this.state.gettingContent}
                    handleChange = {this.handleChange}
                    handleDateChange = {this.handleDateChange}
                    handleGrant = {this.handleGrant}
                    clearValues = {this.clearValues}
                    allValuesFilled={allValuesFilled}
                    getSelectedLetterContent={this.getSelectedLetterContent}
                    info = {info}
                />
            </div>
        )
    }
}

export default Container
