import React from 'react';
import History from '../components/Wiki/History';
import { Breadcrumbs } from '@material-ui/core'
import { Link } from 'react-router-dom'

export default function ArticleHistory(props) {
    return(
        <div>
            <div>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to='/'>Home</Link>
                    <Link to="/console/wiki">Wiki</Link>
                    <Link to={'/console/wiki/' + props.match.params.id + '/history'}>History</Link>
                </Breadcrumbs>
            </div>
            <div>
            <History
                {...props.match.params}
            />
        </div>
        </div>
    )
}