import React from 'react'
import SideDrawer from '../../../../../../generalcomponents/sideDrawer'
import EditIcon from '@material-ui/icons/Edit';
import { TextField, MenuItem, makeStyles, Button, Drawer, IconButton } from '@material-ui/core';
import { FiEdit3 } from 'react-icons/fi'
import { FcBusinessContact } from 'react-icons/fc'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataContext } from '../../../../../../../contexts/data';


const useStyles = makeStyles({
    setDrawer:{
        width: '380px'
    }
});

function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}


function Presentation(props) {
    const { handleCountryAndState, geoState, gender, representativeName, jobTitle, email, mobile, workPhone, homePhone, line1, line2, country, state_name, city, zip, handleChange, handleSave, states, handleUpdate, handleAdd } = props
    const classes = useStyles()
    const [state] =  React.useContext(DataContext)
    const [drawerState, setState] = React.useState({
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...drawerState, [anchor]: open });
      };
    let countryList = state.hasOwnProperty('geo_data') ? state.geo_data.countries : []

    return (
        <div>
            <IconButton onClick={toggleDrawer("right", true)}>{props.btnContent} </IconButton>
            <Drawer anchor={"right"} open={drawerState["right"]} onClose={toggleDrawer("right", false)}>
                <div className="m-3" >
                    <div className="text-center" >
                        {
                            props.edit ? 
                                <>
                                    <FiEdit3 fontSize="50px" />
                                    <h3><u>Edit Contact</u></h3>
                                </>
                            :
                                <>
                                    <FcBusinessContact fontSize="80px" />    
                                    <h3><u>Add Contact</u></h3>
                                </>
                        }
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="representativeName"
                                onChange={handleChange}
                                value={representativeName}
                                variant="outlined"
                                size="small"
                                label="Representative name"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="jobTitle"
                                onChange={handleChange}
                                value={jobTitle}
                                variant="outlined"
                                size="small"
                                label="Job title"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                select
                                name="gender"
                                value={gender}
                                onChange={handleChange}
                                variant="outlined"
                                size="small"
                                label="Gender"
                                fullWidth
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                                <MenuItem value="others">Others</MenuItem>
                            </TextField>
                        </div>
                        <div className="col-sm-6 mt-2 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                value={mobile}
                                onChange={handleChange}
                                name="mobile"
                                variant="outlined"
                                size="small"
                                label="Mobile"
                            />
                        </div>
                        <div className="col-sm-6 mt-2 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="workPhone"
                                value={workPhone}
                                onChange={handleChange}
                                variant="outlined"
                                size="small"
                                label="Work Phone"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                name="homePhone"
                                fullWidth
                                value={homePhone}
                                onChange={handleChange}
                                variant="outlined"
                                size="small"
                                label="Home Phone"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="email"
                                onChange={handleChange}
                                value={email}
                                variant="outlined"
                                size="small"
                                label="Email"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                required
                                fullWidth
                                name="line1"
                                value={line1}
                                onChange={handleChange}
                                variant="outlined"
                                size="small"
                                label="Address Line 1"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                name="line2"
                                fullWidth
                                value={line2}
                                onChange={handleChange}
                                variant="outlined"
                                size="small"
                                label="Address Line 2"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <Autocomplete
                                id="country-select-demo"
                                fullWidth
                                options={countryList}

                                //inputValue={country ? country : null}
                                onChange={(event, value) => {
                                    if (value) {
                                        handleCountryAndState('country', value)
                                        return geoState(value.iso2)
                                    }

                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        <span>{countryToFlag(option.iso2)}</span>
                                        {option.name} ({option.iso2}) +{option.phone_code}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Country *"
                                        variant="outlined"
                                        size="small"

                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <p className="text-success" >Selected country:{country} </p>
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <Autocomplete
                                id="country-select-demo"
                                fullWidth
                                options={states}

                                onChange={(event, value) => {
                                    if (value) {
                                        handleCountryAndState('state_name', value)
                                        return geoState(value.iso2)
                                    }

                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="State *"
                                        variant="outlined"
                                        size="small"

                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <p className="text-success" >Selected country:{state_name} </p>
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                name="city"
                                value={city}
                                onChange={handleChange}
                                required
                                fullWidth
                                variant="outlined"
                                size="small"
                                label="City"
                            />
                        </div>
                        <div className="col-sm-6 mt-2" >
                            <TextField 
                                name="zip"
                                value={zip}
                                onChange={handleChange}
                                required
                                fullWidth
                                variant="outlined"
                                size="small"
                                label="Zip"
                            />
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <Button
                            variant="contained"
                            color={props.edit ? "secondary" : "primary"}
                            onClick={props.edit ? handleUpdate : handleAdd}
                        >
                            {props.edit ? "Update" : "Add"}
                        </Button>                 
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default Presentation
