import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { FaRegHandPointRight } from 'react-icons/fa'
import { List } from 'semantic-ui-react'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    width: '100%',
    // flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  list: {
    width: '100%',
  },
}))

export default function Presentation(props) {
  const classes = useStyles()
  const { emails, onSelect } = props

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        {emails
          .map((item) => {
            return (
              <List
                selection
                onClick={() => onSelect(item.id)}
                verticalAlign='middle'
              >
                <List.Item className='d-flex'>
                  <FaRegHandPointRight size={22} />
                  <List.Content>
                    <List.Header className='ml-4 mt-1'>{item.name}</List.Header>
                  </List.Content>
                </List.Item>
              </List>
            )
          })}
        <Typography style={{ opacity: 0 }}>
          Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
          Aliquam eget maximus est, id dignissim quam.
        </Typography>
      </div>
    </div>
  )
}
