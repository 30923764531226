import React, { useContext } from "react";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import { DataContext } from "../../contexts/data";
import { configuration } from "../../configuration";
export default function Presentation(props) {
  const { history, isLoading } = props;
  let historyList = history;
  const [state ] = useContext(DataContext)
  // if(state.hasOwnProperty("history"))
  //   historyList = state.history
  // else
  //   historyList = []
  const formatter = (date) =>
    Intl.DateTimeFormat(configuration["date-code"], {
      ...configuration.dateformat,
      ...configuration.timeformat,
    }).format(new Date(date));
  const validate = {
    emailToName: (uid) => {
      try {
        return state.names.filter((item) => item.uid === uid)[0].name;
      } catch (error) {
        console.log(error);
        return uid;
      }
    },
    getGender: (id) => {
      try {
        return state.names.filter(item => item.uid === id || item.companyID === id)[0].gender
      } catch (error) {
        return ""
      } 
    }
  };

  const columns = [
    {
      title: "Action by",
      field: "actionbyName",
      render: (rowData) => (
        <Link to={"/console/employeelist/" + rowData.actionby}>
          {validate.emailToName(rowData.actionby)}
        </Link>
      ),
    },
    { title: "Timestamp", field: "timestamp" },
    {
      title: "History",
      field: "history",
      render: (rowData) => formatSubject(rowData.type, rowData.subject, rowData.ActionBy),
    },
  ];

  let data = [];

  function formatSubject(type, subject, ActionBy) {
    switch (type) {
      case "demoteuser":
        return (
          <span>
            Demoted{" "}
            <Link to={"/console/employeelist/" + subject}>
              {validate.emailToName(subject)}
            </Link>{" "}
            to user.
          </span>
        );

      case "enableuser":
        return (
          <span>
            <Link to={"/console/employeelist/" + subject}>
              {validate.emailToName(subject)}
            </Link>{" "}
            is activated.
          </span>
        );

      case "promoteuser":
        return (
          <span>
            Promoted{" "}
            <Link to={"/console/employeelist/" + validate.emailToName(subject)}>
              {validate.emailToName(subject)}
            </Link>{" "}
            to manager.
          </span>
        );

      case "disableuser":
        return (
          <span>
            <Link to={"/console/employeelist/" + subject}>{subject}</Link> is
            suspended.
          </span>
        );

      case "createProject":
        return (
          <span>
            Created project{" "}
            <Link to={"/console/projects/" + subject.pid}>{subject.title}</Link>
            .
          </span>
        );

      case "createTask":
        return (
          <span>
            Added task{" "}
            <span to={"/console/projects/" + subject.pid + "/" + subject.tid}>
              {subject.title}
            </span>
            .
          </span>
        );

      case "updateTask":
        return (
          <span>
            Updated task{" "}
            <span to={"/console/projects/" + subject.pid + "/" + subject.tid}>
              {subject.title}
            </span>
            .
          </span>
        );

      case "inviteUser":
        return (
          <span>
            Invited{" "}
            <Link to={"/console/employeelist/" + subject}>{subject}</Link>.
          </span>
        );

      case "userRegister":
        return (
          <span>
            Employee{" "}
            <Link to={"/console/employeelist/" + subject}>{subject}</Link>{" "}
            registered.
          </span>
        );

      case "newComment":
        return (
          <span>
            {" "}
            <span to={"/console/projects/" + subject.pid + "/" + subject.tid}>
              {subject.title}
            </span>{" "}
            .
          </span>
        );

      case "updateComment":
        return (
          <span>
            {" "}
            <span to={"/console/projects/" + subject.pid + "/" + subject.tid}>
              {subject.title}
            </span>{" "}
            .
          </span>
        );

      case "deleteComment":
        return (
          <span>
            {" "}
            <span to={"/console/projects/" + subject.pid + "/" + subject.tid}>
              {subject.title}
            </span>{" "}
            .
          </span>
        );

      case "inviteAgain":
        return (
          <span>
            Invited again{" "}
            <Link to={"/console/employeelist/" + subject}>{subject}</Link> .
          </span>
        );

      case "createSubTask":
        return (
          <span>
            Added Subtask{" "}
            <Link
              to={
                "/console/projects/" +
                subject.pid +
                "/" +
                subject.tid +
                "/" +
                subject.subtid
              }
            >
              {subject.title}
            </Link>
            .
          </span>
        );

      case "deleteUser":
        return (
          <span>
            Removed{" "}
            <Link to={"/console/employeelist/" + subject}>
              {validate.emailToName(subject)}
            </Link>
            .
          </span>
        );

      case "updateProfile":
        try {
          return (
            <span>
              Updated{" "}
              <Link to={"/console/employeelist/" + subject.email}>
                {validate.emailToName(subject.email)}
              </Link>{" "}
              {"'s " + subject.section.map((item) => item)} .
            </span>
          );
        } catch (error) {
          return <span>Updated profile {subject}.</span>;
        }

      case "addMemberToProject":
        return (
          <span>
            Added{" "}
            <Link to={"/console/employeelist/" + subject.uid}>
              {validate.emailToName(subject.uid)}
            </Link>{" "}
            to <Link to={"/console/projects/" + subject.pid}>Project</Link>.
          </span>
        );

      case "deleteProjectMember":
        return (
          <span>
            Removed{" "}
            <Link to={"/console/employeelist/" + subject.uid}>
              {validate.emailToName(subject.uid)}
            </Link>{" "}
            from <Link to={"/console/projects/" + subject.pid}>Project</Link>.
          </span>
        );

      case "updateAccessLevels":
        return (
          <span>
            Updated{" "}
            <Link to={"/console/employeelist/" + subject.uid}>
              {validate.emailToName(subject.uid)}
            </Link>{" "}
            <Link to={"/console/projects/" + subject.pid}>Project</Link>{" "}
            permissions.
          </span>
        );

      case "updateProject":
        return (
          <span>
            Updated project:{" "}
            <Link to={"/console/projects/" + subject.pid}>{subject.title}</Link>
            .
          </span>
        );

      case "employeeRequestLetter":
        return <span>Requested {subject}.</span>;

      case "officerRejectsLetter":
        return (
          <span>
            Rejected{" "}
            <Link to={"/console/employeelist/" + subject.ActionOn}>
              {subject.ActionOn}
            </Link>{" "}
            {subject.docType} letter.
          </span>
        );

      case "officerIssuesLetter":
        return (
          <span>
            Issued{" "}
            <Link to={"/console/employeelist/" + subject.ActionOn}>
              {subject.ActionOn}
            </Link>{" "}
            {subject.docType} letter.
          </span>
        );

      case "addAuthorisedSignature":
        return (
          <span>
            <Link to={"/console/employeelist/" + subject}>
              {validate.emailToName(subject)}
            </Link>
            's signature added.
          </span>
        );

      case "deletedAuthorisedSignature":
        return (
          <span>
            <Link to={"/console/employeelist/" + subject}>
              {validate.emailToName(subject)}
            </Link>
            's signature removed.
          </span>
        );

      case "addLetterTemplate":
        return (
          <span>
            Created new letter template:<b>{subject.name}</b> in {subject.type}{" "}
          </span>
        );

      case "updateLetterTemplate":
        return (
          <span>
            Updated letter template:<b>{subject.name}</b> in {subject.type}{" "}
          </span>
        );

      case "deleteLetterTemplate":
        return (
          <span>
            Deleted letter template:<b>{subject.name}</b> from {subject.type}{" "}
          </span>
        );

      case "newCategoryInWiki": return <span>New wiki category added: <b>{subject.categoryName} </b>.</span>

      case "deleteCategoryInWiki": return <span>A wiki category deleted: <b>{subject.categoryName} </b>.</span>

      case "updateCategoryInWiki": return <span>A wiki category updated: <b>{subject.categoryName}</b>.</span>

      case "newPageInWiki":return <span>New wiki page created: <Link to={`/console/wiki/${subject.wikiArticleId}`} >{subject.title} </Link>.</span>  

      case "updatePageInWiki": return <span>Wiki page updated: <Link to={`/console/wiki/${subject.wikiArticleId}`} >{subject.title} </Link>.</span>

      case "deletePageInWiki": return <span>Wiki page deleted: <Link to={`/console/wiki/${subject.wikiArticleId}`} >{subject.title} </Link>.</span>

      case "voteForWikiArticle": return <span>Voted for an article:  <Link to={`/console/wiki/${subject.wikiArticleId}`} >{subject.title} </Link>.</span>

      case "downVoteForWikiArticle": return <span>Devoted for an article:  <Link to={`/console/wiki/${subject.wikiArticleId}`} >{subject.title} </Link>.</span>

      case "followWikiArticle": return <span>Started following the article <Link to={`/console/wiki/${subject.wikiArticleId}`} >{subject.title} </Link>.</span>

      case "unFollowWikiArticle": return <span>Unfollowed the article: <Link to={`/console/wiki/${subject.wikiArticleId}`} >{subject.title} </Link></span>

      case "newArticleComment": return <span>Commented on the article: <Link to={`/console/wiki/${subject.wikiArticleId}`} >{subject.hasOwnProperty("title") ? subject.title : "article"} </Link>.</span>

      case "updateArticleComment": return <span>Updated comment on the article: <Link to={`/console/wiki/${subject.wikiArticleId}`} >{subject.hasOwnProperty("title") ? subject.title : "article"}</Link>.</span>

      case "deleteArticleComment": return <span>Deleted comment on the article: <Link to={`/console/wiki/${subject.wikiArticleId}`} >{subject.hasOwnProperty("title") ? subject.title : "article"}</Link>.</span>
      
      default:
        return <span>-----------------</span>;
    }
  }

  function formatSearch(type, subject, ActionBy) {
    switch (type) {
      case "demoteuser":
        return "Demoted " + subject + " to user.";

      case "enableuser":
        return subject + " is activated.";

      case "promoteuser":
        return "Promoted " + subject + " to manager.";

      case "disableuser":
        return subject + " is suspended.";

      case "createProject":
        return "Created project " + subject.title + ".";

      case "createTask":
        return "Added task " + subject.title + ".";

      case "updateTask":
        return "Updated task " + subject.title + ".";

      case "inviteUser":
        return "Invited " + subject + ".";

      case "userRegister":
        return "Employee " + subject + " registered.";

      case "newComment":
        return subject.title;

      case "updateComment":
        return subject.title;

      case "deleteComment":
        return subject.title;

      case "inviteAgain":
        return "Invited again " + subject + ".";

      case "employeeRequestLetter":
        return "Requested " + subject + ".";

      case "officerRejectsLetter":
        return (
          "Rejected " + subject.ActionOn + " " + subject.docType + " letter."
        );

      case "officerIssuesLetter":
        return (
          "Issued " + subject.ActionOn + " " + subject.docType + " letter."
        );

      case "createSubTask":
        return "Added Subtask " + subject.title + ".";

      case "deleteUser":
        return "Removed " + subject + ".";

      case "updateProfile":
        return "Updated profile " + subject + ".";

      case "addMemberToProject":
        return "Added " + subject.uid + " to project.";

      case "deleteProjectMember":
        return "Removed " + subject.uid + " from  Project.";

      case "updateAccessLevels":
        return "Updated " + subject.uid + " Project permissions.";

      case "updateProject":
        return "Updated project: " + subject.pid + ".";

      case "addAuthorisedSignature":
        return subject + "'s signature added.";

      case "deletedAuthorisedSignature":
        return subject + "'s signature removed.";

      case "addLetterTemplate":
        return (
          "Created new letter template:" + subject.name + " in " + subject.type
        );

      case "updateLetterTemplate":
        return (
          "Updated letter template:" + subject.name + " in " + subject.type
        );

      case "deleteLetterTemplate":
        return (
          "Deleted letter template:" + subject.name + " from " + subject.type
        );

      case "newCategoryInWiki": return `New wiki category added: ${subject.categoryName}.</span>`

      case "deleteCategoryInWiki": return `A wiki category deleted: ${subject.categoryName}.`

      case "updateCategoryInWiki": return `A wiki category updated: ${subject.categoryName}.`

      case "newPageInWiki":return `New wiki page created: ${subject.title}.`  

      case "updatePageInWiki": return `Wiki page updated: ${subject.title}.`

      case "deletePageInWiki": return `Wiki page deleted: ${subject.title}.`

      case "voteForWikiArticle": return `Voted for an article: ${subject.title}.`

      case "downVoteForWikiArticle": return `Devoted for an article: ${subject.title}.`

      case "followWikiArticle": return `Started following the article: ${subject.title}.`

      case "unFollowWikiArticle": return `Unfollowed the article: ${subject.title}`

      case "newArticleComment": return `Commented on the article: ${subject.hasOwnProperty("title") ? subject.title : "article"}.`

      case "updateArticleComment": return `Updated comment on the article: ${subject.hasOwnProperty("title") ? subject.title : "article"}.`

      case "deleteArticleComment": return `Deleted comment on the article: ${subject.hasOwnProperty("title") ? subject.title : "article"}.`

      default:
        return <span>-----------------</span>;
    }
  }

  historyList.forEach((history) => {
    data.push({
      actionby: history.ActionBy,
      actionbyName: validate.emailToName(history.ActionBy),
      timestamp:
        history.createdAt === ""
          ? history.createdAt
          : formatter(history.createdAt),
      history: formatSearch(history.type, history.subject),
      type: history.type,
      subject: history.subject,
    });
  });

  return (
    <MaterialTable
      title="History"
      columns={columns}
      isLoading={isLoading}
      data={data}
      options={{
        paginationType: "stepped",
        pageSize: 20,
      }}
    />
  );
}
