import React, { Component } from 'react'
import Presentation from './Presentation'
//import firebase from '../../../../firebase_config'

class Container extends Component {
    state = {
        value: 1
    }

    handleChange = (e, v) => {
        this.setState({ value: v })
    }


    render() {
        return (
            <div>
                <Presentation
                    {...this.state}
                    {...this.props}
                    handleChange={this.handleChange}
                />
            </div>
        )
    }
}

export default Container
