import React, { Component } from 'react';
import { DataContext } from '../../contexts/data';
import { SET_COUNTRY_API } from '../../contexts/types';
import Axios from 'axios';
import AutoComplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format'


const useStyles = theme => ({
    input: {
        display: 'none',
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
})

class CountryAndState extends Component {

    /* props to be passed:
        handleChange --> should be same as provided here
        state_name , country, spacing --> spacing between Grids */

    constructor(props) {
        super(props)
        this.state = {
            states: [],
            countryList: [],
            country: '',
            state_name: ''
        }
    }

    static contextType = DataContext
    
    countryToFlag = (isoCode) => {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                .toUpperCase()
                .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    }

    componentDidMount = () => {
        const [state, dispatch] = this.context
        const { state_name, country } = this.props
        console.log(this.props)
        this.setState({ state_name, country })
        dispatch({
            type: SET_COUNTRY_API,
            payload: dispatch
        })
    }

    handleChane = (name, value) => {
        this.setState({
            [name]: value.name
        })
    }

    geoState = async (code) => {
        let states = await Axios.post("/loadstates", { countrycode: code })
        this.setState({ states: states.data })
    }

    render() {
        const { states, country, state_name } = this.state
        const { handleChange, classes, spacing } = this.props
        const [state] = this.context
        const countryList = state.hasOwnProperty('geo_data') ? state.geo_data.countries : []
        return (
            <div>
                <Grid container spacing={spacing}>
                    <Grid item xs={6}>
                        <AutoComplete
                            id="country-select-demo"
                            fullWidth
                            options={countryList}
                            classes={{
                                option: classes.option,
                            }}
                            onChange={(event, value) => {
                                if (value) {
                                    this.handleChane('country', value)
                                    handleChange('country', value)
                                    return this.geoState(value.iso2)
                                }

                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <span>{this.countryToFlag(option.iso2)}</span>
                                    {option.name} ({option.iso2}) +{option.phone_code}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Country *"
                                    variant="outlined"
                                    size="small"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <p className="text-success" >Selected country:{country} </p>
                    </Grid>
                    <Grid item xs={6}>
                        <AutoComplete
                            id="country-select-demo"
                            fullWidth
                            options={states}
                            classes={{
                                option: classes.option,
                            }}
                            onChange={(event, value) => {
                                if (value) {
                                    handleChange('state_name', value)
                                    this.handleChane('state_name', value)
                                }

                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderOption={(option) => (
                                <React.Fragment>
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="State *"
                                    variant="outlined"
                                    size="small"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <p className="text-success" >Selected state:{state_name} </p>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

CountryAndState.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(CountryAndState)


function PhoneNumberFormat(props) {
    const { inputRef, onChange, ...other } = props;
    console.log(props)
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            decimalScale={2}
            format="##########"
            allowNegative={false}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.formattedValue,
                    },
                });
            }}
            // isNumericString
        />
    );
}

export class MobileCountryCode extends React.Component{
    // this is a exported but not default import
    constructor(props) {
        super(props)
        /* 
            props to be passed:
            handleChange(key, value)
            phoneLabel:"ex: Mobile number"
            below are other component state values:
                country: "",
                phonenumber:"",

        */
        this.state = {
            componentCountry: props.country.split("+")[0],
        }
    }

    static contextType = DataContext

    countryToFlag = (isoCode) => {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                .toUpperCase()
                .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    }

    componentDidMount = () => {
        const [state, dispatch] = this.context
        dispatch({
            type: SET_COUNTRY_API,
            payload: dispatch
        })
    }

    handleLocalChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }
    render(){
        const { componentCountry } = this.state
        const { handleChange, phoneLabel, phonenumber } = this.props
        const [state] = this.context
        const countryList = state.hasOwnProperty('geo_data') ? state.geo_data.countries : []

        

        return (
            <Grid className="d-flex" item xs={6}>
                <div>
                    <AutoComplete
                        style={{width:'70px'}}
                        options={countryList}
                        onChange={(event, value) => {   
                            if (value) {
                                console.log(value)
                                this.handleLocalChange('componentCountry',  value.iso2)
                                handleChange('country', value.iso2+ "+" +value.phone_code)
                            }
                        }}
                        freeSolo
                        value={countryList.length ? countryList.filter(item => item.iso2 === componentCountry)[0] : null}
                        getOptionLabel={(option) => option.iso2 + "+" + option.phone_code}
                        disableClearable
                        renderOption={(option) => (
                            <React.Fragment>
                                ({option.iso2}) +{option.phone_code}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Code"
                                variant="outlined"
                                size="small"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </div>
                <div>
                    <TextField 
                        type="text"
                        onChange={(e) => handleChange("phonenumber", e.target.value)}
                        value={phonenumber}
                        label={phoneLabel}
                        InputProps={{inputComponent: PhoneNumberFormat}}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </div>
            </Grid>
        )
    }
}
