import React from 'react';
import EditArticle from '../components/Wiki/Edit';
import { Breadcrumbs } from '@material-ui/core'
import { Link } from 'react-router-dom'

export default function EditArticlePage(props) {
    return (
        <div>
            <div>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to='/'>Home</Link>
                    <Link to="/console/wiki">Wiki</Link>
                    <Link to={'/console/wiki/'+props.match.params.id+'/edit'}>Edit</Link>
                </Breadcrumbs>
            </div>
            <div>
                <EditArticle
                    {...props.match.params}
                />
            </div>
        </div>
    )
}