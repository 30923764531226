import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../contexts/data'

class Container extends Component {
    static contextType = DataContext
    render() {
        const [state] = this.context
        if(state.hasOwnProperty('taskList') && state.taskList.length )
            return (
                <Presentation 
                    {...this.props}
                />
            )
        return <p>Loading viewer ...</p>
    }
}

export default Container
