import React, { Component } from 'react'
import Presentation from "./Presentation"
import { DataContext } from '../../../../../../../contexts/data'
import { CLIENT_UPDATE_ACTIONS } from '../../../../../../../contexts/types'
export class Container extends Component {
    constructor(props){
        super(props)
        const { line1, line2, city, state, zipCode, country } = props.invoiceLocation
        this.state={
            line1,
            line2,
            city,
            state_name:state,
            zipCode,
            country
        }
    }

    static contextType = DataContext

    onUpdate = () => {
        const { line1, line2, city, state_name, zipCode, country } = this.state
        const dispatch = this.context[1]
        dispatch({
            type: CLIENT_UPDATE_ACTIONS,
            payload: {
                invoiceLocation: {
                    line1,
                    line2,
                    city,
                    state:state_name,
                    zipCode,
                    country
                },
                id: this.props.id
            } 
        })
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }
    render() {
        return (
            <div>
                <Presentation 
                    {...this.state}
                    handleChange={this.handleChange}
                    onUpdate={this.onUpdate}
                />
            </div>
        )
    }
}

export default Container
