import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TasksSort from '../TasksSort'
import { DataContext } from '../../../../contexts/data'
import NewTask from '../taskcomponents/NewTask'
import Badge from '@material-ui/core/Badge';
import Validation from '../../../generalcomponents/validation'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    paper: {
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      width:'100%',
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    new : {
      backgroundColor:"black",
      color:"white"
    }
  }));
function Presentation(props) {
  const [ state ] = useContext(DataContext)
  const [ filterBy, setFilter ] = useState("")
  const classes = useStyles();
  let projectMembers = state.project.Users.map(user => user.uid)  
  let allAssignees = []
  let temp = []
  if(state.hasOwnProperty("taskList")){
      state.taskList.forEach(task => {
          temp.push(...task.assignee)
      })    
      temp = [ ...new Set(temp)]
      if(state.employee.role === 'user')
          projectMembers = temp
      projectMembers.forEach(member => {
          let count = 0
          state.taskList.forEach(task => {
            if(task.assignee.includes(member)){
                count ++
            }
          })
          allAssignees.push({
            uid : member,
            count:count
          })
      })
    }
    const Validate = new Validation()
    console.log(filterBy)
    return (
        <div>
            <main className={classes.layout}>
              <Grid alignItems="stretch" container spacing={3}>
                
                <Grid item xs={12} >
                      <TasksSort filterBy={filterBy}/>
                </Grid>
              </Grid>
            </main>
        </div>
    )
}

export default Presentation
