import React, { useContext, useEffect } from 'react'
import { DataContext } from '../../../../../contexts/data'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from "@material-ui/lab"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';


function Presentation(props) {
    const { placementID } = props
    const [state] = useContext(DataContext)
    const employee = state.employee_collection
    const placement = state.placements_list.filter(item => item.placementID === placementID)[0]
    const client = state.client_list.filter(item => item.clientId === placement.clientId)[0]
    console.log(employee, placement, client)
    
    const displayDetails = [
        {
            key: 'Client ID',
            value: client.clientId
        },
        {
            key: 'Placement ID',
            value: placementID
        },
        {
            key: 'Employee ID',
            value: placement.empCode
        },
        {
            key: 'Expense Approver',
            value: ''
        }
    ]
    return (
        <div className="col-sm-6" >
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                >
                    {
                        placementID !== "" ? 
                            <Typography >{ employee.personal.hasOwnProperty("jobtitle") ? <h2>{employee.personal.jobtitle} </h2> : "" + " - " + client.businessDisplayName }</Typography>
                        :
                            <Skeleton animation="wave" />
                    }
                    
                </AccordionSummary>
                <AccordionDetails className="bg-light" >
                    {
                        placementID !== "" ? 
                            <div>
                                <table>
                                    <tbody>
                                        {
                                           displayDetails.map(item => {
                                               return(
                                                   <tr>
                                                       <td><h4 style={{fontWeight: 500}} >{item.key} </h4> </td>
                                                       <td>  : {item.value}</td>
                                                   </tr>
                                               )
                                           }) 
                                        }
                                    </tbody>
                                </table>
                            </div>
                        :
                            <div>
                                <Skeleton />
                                <Skeleton animation={false} />
                                <Skeleton animation="wave" />
                            </div>
                    }


                    
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default Presentation
