import React, {useContext, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import { makeStyles } from '@material-ui/core/styles';
import {  Collapse } from 'reactstrap'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import EditIcon from "@material-ui/icons/Edit";
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';  
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Fab from '@material-ui/core/Fab'; 
import DateFnsUtils from '@date-io/date-fns';
import { DataContext } from '../../../../contexts/data'
import Validations from '../../../generalcomponents/validation'
import {configuration} from '../../../../configuration'

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
    large: {
      position:"absolute",
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin:"1.1em",
    },
    box:{
      borderRadius:"100px",
      height:100,
      width:100,
      margin:"auto",
      marginTop:"4px"
    },
    table: {
      backgroundColor:"#fafafa"
    },
  }));
  

function Presentation(props) {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const { profile, info, isEditing, handleChange, personal, mailingaddress, handleDone, handleCancel, geoState, states,handleDateChange } = props
    const [ state ] = useContext(DataContext)
    const handleClickOpen = () => {
        setIsOpen(!isOpen)
    }
    const formatter = (date) => {
        console.log(date)  
        let final = ""
        try {
            final = Intl.DateTimeFormat(configuration["date-code"],configuration.dateformat).format(new Date(date))
        } catch (error) {
            console.log(error)
            final = date
        }
        return final
        }

    if(!isEditing){
        if(isOpen)
            setIsOpen(!isOpen)   
    }
    const validate = new Validations()
    return (
           
            <div className="user-emergencycontact mt-3">
                <TableContainer className={classes.table}  component={Paper}>
                    <Table size="small" aria-label="simple table">
                    <TableBody>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Employee ID</TableCell>
                                    <TableCell align="left">Email</TableCell>
                                    <TableCell align="left">Phone</TableCell>
                                    <TableCell align="left">Address</TableCell>
                                    <TableCell align="left">Date of birth</TableCell>
                                    <TableCell align="left">Gender</TableCell>
                                    <TableCell  align="left">Marital status</TableCell>
                                    {
                                        (isEditing && state.employee.email === profile.useremail) || (isEditing && state.employee.role === "admin") ?
                                        <TableCell align="right" >
                                            <Fab 
                                                variant="contained" 
                                                className="flairbtn" 
                                                size="small"
                                                onClick={handleClickOpen}
                                            >
                                            <EditIcon/>
                                            </Fab>
                                        </TableCell>
                                        :
                                        null
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">{personal.firstname + " " + personal.middlename + " " + personal.lastname}</TableCell>
                                    <TableCell align="left">{profile.companyID}</TableCell>
                                    <TableCell align="left">{personal.emailid}</TableCell>
                                    <TableCell align="left">{personal.phonenumber}</TableCell>
                                    <TableCell align="left">{mailingaddress.line1 + ", " +( mailingaddress.line2 ?  mailingaddress.line2 + ", " : "")  + mailingaddress.city + ", " + mailingaddress.state + ", " + mailingaddress.country + " - " + mailingaddress.zip}</TableCell>
                                    <TableCell align="left">{formatter(personal.dob)} </TableCell>
                                    <TableCell align="left">{personal.gender} </TableCell>
                                    <TableCell align="left">{personal.maritalstatus} </TableCell>
                                </TableRow>
                            </TableBody>
                    </TableBody>
                    </Table>
                </TableContainer>
                <div>
                    <div style={{marginTop:'10px'}}>
                        <Collapse isOpen={isOpen} >
                            <Paper style={{padding:'15px'}} >
                            <form onSubmit={(e) => {
                                e.preventDefault()
                                if(validate.checkName(info.firstname)&&validate.checkName(info.lastname)&&validate.checkNumber(info.phonenumber)&&info.gender !==""&&info.maritalstatus!==""&&info.line1!==""&&validate.checkName(info.city)&&validate.checkName(info.state)&&info.country !== ""&&validate.checkZip(info.zip)){
                                    setIsOpen(!isOpen)
                                    handleDone()
                                }
                            }} >
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                        label="First name"
                                        name="firstname"
                                        id="outlined-size-small"
                                        helperText={info.firstname.length?validate.checkName(info.firstname) ? "" : "Enter valid name" : ""}
                                        value={info.firstname}
                                        required
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Middle name"
                                            name="middlename"
                                            helperText={info.middlename.length?validate.checkName(info.middlename) ? "" : "Enter valid name" : ""}
                                            id="outlined-size-small"
                                            value={info.middlename}
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Last name"
                                            name="lastname"
                                            id="outlined-size-small"
                                            required
                                            helperText={validate.checkName(info.lastname) ? "" : "Enter valid name"}
                                            value={info.lastname}
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Email"
                                            name="emailid"
                                            required
                                            disabled
                                            readOnly={true}
                                            helperText={info.emailid.length ? validate.checkEmail(info.emailid) ? "" : "Enter valid email id":""}
                                            id="outlined-size-small"
                                            value={info.emailid}
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Phone number"
                                            name="phonenumber"
                                            type="number"
                                            required
                                            id="outlined-size-small"
                                            helperText={info.phonenumber.length ? validate.checkNumber(info.phonenumber) ? "" : "Enter valid phone number" : ""}
                                            value={info.phonenumber}
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) => handleChange("phonenumber", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl required variant="outlined" >
                                            <InputLabel required id="demo-simple-select-outlined-label">Gender</InputLabel>
                                            <Select
                                                fullWidth
                                                required
                                                value={info.gender}
                                                label="Gender"
                                                name="gender"
                                                onChange={(e) => handleChange("gender", e.target.value)}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="Male">Male</MenuItem>
                                                <MenuItem value="Female">Female</MenuItem>
                                                <MenuItem value="Others">Others</MenuItem>
                                            </Select>
                                            <FormHelperText>{info.gender === "" ? "Select gender": ""} </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <FormControl required variant="outlined" >
                                        <InputLabel required id="demo-simple-select-outlined-label">Marital status</InputLabel>
                                        <Select
                                            required
                                            fullWidth
                                            value={info.maritalstatus}
                                            label="Marital status"
                                            name="maritalstatus"
                                            onChange={(e) => handleChange("maritalstatus", e.target.value)}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="Married">Married</MenuItem>
                                            <MenuItem value="Unmarried">Unmarried</MenuItem>
                                        </Select>
                                        <FormHelperText>{info.maritalstatus === "" ? "Select maritial status": ""} </FormHelperText>
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            label="Line1"
                                            name="line1"
                                            helperText={info.line1.length ? info.line1 ? "" : "Enter line1": ""}
                                            id="outlined-size-small"
                                            value={info.line1}
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) => handleChange("line1", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Line2"
                                            name="line2"
                                            id="outlined-size-small"
                                            value={info.line2}
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) => handleChange("line2", e.target.value.trim())}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                        style={{width:'80%'}}
                                        required
                                        id="tags-outlined"
                                        options={state.hasOwnProperty('geo_data') ? state.geo_data.countries : []}
                                        getOptionLabel={option => option.name}
                                        onChange={(e,v) => {
                                            if(v){
                                            handleChange("country", v.name)   
                                            return geoState(v.iso2) 
                                            }
                                        }}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                            size="small"
                                            {...params}
                                            label="Select country"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                            />
                                        )}
                                        />
                                        <p className="text-success" >Selected country:{info.country} </p>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Autocomplete
                                        style={{width:'80%'}}
                                        required
                                        id="tags-outlined"
                                        options={states}
                                        getOptionLabel={option => option.name}
                                        onChange={(e,v) => {
                                            if(v){
                                                return handleChange("state", v.name)  
                                            }
                                        }}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                            size="small"
                                            {...params}
                                            label="Select state"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                            />
                                        )}
                                        />
                                        <p className="text-success" >Selected state:{info.state} </p>
                                    </Grid>
                                    <Grid item xs={6}  >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            required
                                            margin="normal"
                                            id="date-picker-birthdate"
                                            label="Date of birth"
                                            format="MM/dd/yyyy"
                                            maxDate={new Date().toISOString()}
                                            helperText={ isNaN(Date.parse(info.dob)) ? 'Select date of birth' : '' }
                                            value={info.dob === "" ?null:info.dob }
                                            onChange={(date) => props.handleChange("dob",date)}
                                            KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>    
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="City"
                                            name="city"
                                            required
                                            helperText={validate.checkName(info.city) ? "" : "Enter city"}
                                            id="outlined-size-small"
                                            value={info.city}
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) => handleChange("city", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Zip"
                                            name="zip"
                                            required
                                            id="outlined-size-small"
                                            helperText={info.zip.length?validate.checkZip(info.zip) ? "" : "Enter valid zip code":""}
                                            value={info.zip}
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) => handleChange("zip", e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <br/>
                                <div className="text-center" >
                                        <Button variant="contained" type="submit"  color="primary">
                                            Save
                                        </Button>
                                    
                                    {' '}
                                    <Button variant="contained" onClick={() => {
                                        setIsOpen(!isOpen)
                                        handleCancel()
                                    }} color="default">
                                        Cancel
                                    </Button>
                                </div>
                                
                            </form>    
                            </Paper>
                        </Collapse>
                    </div>
                </div>
                </div>
    )
}

export default Presentation