import React from "react";
import {
  Table,
  Paper,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Typography,
} from "@material-ui/core";
import Validations from "../../../../generalcomponents/validation";
import { OpenInNew, Edit } from "@material-ui/icons";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import EditTimesheet from "../EditTimesheet";
import { isWithinInterval, addDays } from "date-fns"

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

function Presentation(props) {
  const { timesheets, placementID, paymentsInfo, handleChange, selectedExpenses } = props;
  const validate = new Validations();
  console.log(paymentsInfo)
    return (
      <div>
        {
          timesheets.length ?
          <>
          <h2>
          <u>Standard Time</u>
        </h2>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No</StyledTableCell>
                <StyledTableCell>Service Summary</StyledTableCell>
                <StyledTableCell>Activity</StyledTableCell>
                <StyledTableCell>Quantity(Hrs/Days)</StyledTableCell>
                <StyledTableCell>Attachment</StyledTableCell>
                <StyledTableCell>Rate</StyledTableCell>
                <StyledTableCell>Amount</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {timesheets.map((timesheet, index) => {
                return (
                  <StyledTableRow>
                    <StyledTableCell>{index + 1} </StyledTableCell>
                    <StyledTableCell>
                      {validate.emailToName(timesheet.empCode) +
                        "-" +
                        timesheet.empCode}{" "}
                    </StyledTableCell>
                    <StyledTableCell>
                      {"Timesheet (" +
                        validate.dateFormatter(timesheet.startDate) +
                        " - " +
                        validate.dateFormatter(timesheet.endDate) +
                        ")"}{" "}
                    </StyledTableCell>
                    <StyledTableCell>
                      {calcTotalTime(timesheet.workdetails.standardTime)}{" "}
                    </StyledTableCell>
                    <StyledTableCell>
                      {timesheet.timesheetDocURL.length ? (
                        <a target="_blank" href={timesheet.timesheetDocURL}>
                          <OpenInNew fontSize="small" />{" "}
                        </a>
                      ) : (
                        "No attachment"
                      )}{" "}
                    </StyledTableCell>
                    <StyledTableCell>
                      {"$" + getPayRate(timesheet, paymentsInfo.data, "standardTime") }{" "}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {"$" + calcAmount(timesheet, paymentsInfo.data, "standardTime")
                       }{" "}
                    </StyledTableCell>
                    <StyledTableCell>
                      <EditTimesheet
                        timesheet={timesheet}
                        timesheets={timesheets}
                        placementID={placementID}
                        handleChange={handleChange}
                      />{" "}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}

              <TableRow>
                <TableCell colSpan={5} />
                <TableCell>
                  <h4>Subtotal</h4>
                </TableCell>
                <TableCell align="right">
                  <h4>
                    ${timesheets.reduce(
                      (total, timesheet) =>
                        total +
                        calcAmount(timesheet, paymentsInfo.data, "standardTime"),
                      0
                    )}
                    {" "}
                  </h4>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {timesheets.filter((item) =>
          item.workdetails.OTtime.some((time) => time !== "00:00")
        ).length ? (
          <>
              <h2>
                <u>OT Time</u>
              </h2>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>S.No</StyledTableCell>
                      <StyledTableCell>Service Summary</StyledTableCell>
                      <StyledTableCell>Activity</StyledTableCell>
                      <StyledTableCell>Quantity(Hrs/Days)</StyledTableCell>
                      <StyledTableCell>Rate</StyledTableCell>
                      <StyledTableCell>Amount</StyledTableCell>
                      <StyledTableCell>Attachment</StyledTableCell>
                      <StyledTableCell>Actions</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {timesheets
                      .filter((item) =>
                        item.workdetails.OTtime.some((time) => time !== "00:00")
                      )
                      .map((timesheet, index) => {
                        return (
                          <StyledTableRow>
                            <StyledTableCell>{index + 1} </StyledTableCell>
                            <StyledTableCell>
                              {validate.emailToName(timesheet.empCode) +
                                "-" +
                                timesheet.empCode}{" "}
                            </StyledTableCell>
                            <StyledTableCell>
                              {"Timesheet (" +
                                validate.dateFormatter(timesheet.startDate) +
                                " - " +
                                validate.dateFormatter(timesheet.endDate) +
                                ")"}{" "}
                            </StyledTableCell>
                            <StyledTableCell>
                              {calcTotalTime(timesheet.workdetails.OTtime)}{" "}
                            </StyledTableCell>
                            <StyledTableCell>
                              {timesheet.timesheetDocURL.length ? (
                                <a
                                  target="_blank"
                                  href={timesheet.timesheetDocURL}
                                >
                                  <OpenInNew fontSize="small" />{" "}
                                </a>
                              ) : (
                                "No attachment"
                              )}{" "}
                            </StyledTableCell>
                            <StyledTableCell>
                              {"$" + getPayRate(timesheet, paymentsInfo.data, "OTtime")}{" "}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {"$" + calcAmount(timesheet, paymentsInfo.data, "OTtime") 
                              }{" "}
                            </StyledTableCell>
                            <StyledTableCell>
                              <EditTimesheet
                                timesheet={timesheet}
                                timesheets={timesheets}
                                placementID={placementID}
                                handleChange={handleChange}
                              />{" "}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}

                    <TableRow>
                      <TableCell colSpan={5} />
                      <TableCell>
                        <h4>Subtotal</h4>
                      </TableCell>
                      <TableCell align="right">
                        <h4>
                          ${timesheets
                            .filter((item) =>
                              item.workdetails.OTtime.some(
                                (time) => time !== "00:00"
                              )
                            )
                            .reduce(
                              (total, timesheet) =>
                                total +
                                  calcAmount(timesheet, paymentsInfo.data, "OTtime"),
                              0
                            )}
                          {" "}
                        </h4>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : null}
          </>: null
        }
        

        {
          selectedExpenses.length ? 
          <>
            <h2>
              <u>Expenses</u>
            </h2>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>S.No</StyledTableCell>
                    <StyledTableCell>Service Summary</StyledTableCell>
                    <StyledTableCell>Activity</StyledTableCell>
                    <StyledTableCell>Attachment</StyledTableCell>
                    <StyledTableCell>Amount</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {selectedExpenses
                    .map((expense, index) => {
                      return (
                        <StyledTableRow>
                          <StyledTableCell>{index + 1} </StyledTableCell>
                          <StyledTableCell>
                            {validate.emailToName(expense.empCode) +
                              "-" +
                              expense.empCode}{" "}
                          </StyledTableCell>
                          <StyledTableCell>
                            {`${expense.expenseType}(${validate.dateFormatter(expense.departureDate)} - ${validate.dateFormatter(expense.returnDate)})`}{" "}
                          </StyledTableCell>
                          <StyledTableCell>
                            {expense.attachmentDetails.publicURL !== "" ? <a rel='noopener noreferrer' target="_blank" href={expense.attachmentDetails.publicURL} > {expense.attachmentDetails.sourcePath.slice(expense.attachmentDetails.sourcePath.lastIndexOf("/") + 1)} </a> : "---"}{" "}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {"$" + expense.amount}{" "}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}

                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell align="right" >
                      <h4>Subtotal</h4>
                    </TableCell>
                    <TableCell align="right">
                      <h4>
                        ${selectedExpenses
                          .reduce(
                            (total, expense) =>
                              total +
                                Number(expense.amount),
                            0
                          )}
                        {" "}
                      </h4>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>:
          null
        }
      </div>
    );
  return null;
}

export default Presentation;

export function calcTotalTime(arr) {
  const hours = [],
    minutes = [];
  arr.forEach((item) => {
    const [h, m] = item.split(":");
    hours.push(parseInt(h));
    minutes.push(parseInt(m));
  });
  let totalHours = hours.reduce((a, b) => a + b);
  let totalMinutes = minutes.reduce((a, b) => a + b);
  totalHours = parseInt(totalHours + totalMinutes / 60);
  let remainingMinutes = totalMinutes % 60;
  console.log(totalHours +
    ":" +
    "0".repeat(2 - remainingMinutes.toString().length) +
    remainingMinutes.toString())
  return (
    totalHours +
    ":" +
    "0".repeat(2 - remainingMinutes.toString().length) +
    remainingMinutes.toString()
  );
}

export function calcTotalAmount(workingTime, payRateAmount) {
  console.log(workingTime)
  const [h, m] = workingTime.split(":");
  return Math.floor(h * payRateAmount + (payRateAmount * m) / 60);
}


/* 
  Check whether the timesheet dates exists in the range
  1) If exists, then calcTotalTime
  2) If one date in one range and another date in another range --> slice them 1-2 then 2-3 and then calc total time
*/

export function calcAmount(timesheet, payRateDetails, workType){
  const start = new Date(timesheet.startDate).setHours(0, 0, 0, 0), end = new Date(timesheet.endDate).setHours(0, 0, 0, 0)
  console.log(payRateDetails, workType)
  for(let i = 0; i < payRateDetails.length; i++){
    // check both dates in range or not
    const payRate = payRateDetails[i]
    const checkStartRange = isWithinInterval(start, {
      start: new Date(payRate.effectiveDate).setHours(0, 0, 0, 0),
      end: new Date(payRate.effectiveUntil).setHours(0, 0, 0, 0)
    })

    const checkEndRange = isWithinInterval(end, {
      start: new Date(payRate.effectiveDate).setHours(0, 0, 0, 0),
      end: new Date(payRate.effectiveUntil).setHours(0, 0, 0, 0)
    })

    if(checkStartRange && checkEndRange){
      return calcTotalAmount(calcTotalTime(timesheet.workdetails[workType]), workType === "standardTime" ? payRate.billingRate: payRate.OTbillingRate)
    }
    else if(checkStartRange || checkEndRange){
      let totalAmount = 0
      for(let i = 0; i < timesheet.workdetails[workType].length; i++){
        const date = addDays(start, i)
        totalAmount += IterateThroughEveryPaymentRate(date, payRateDetails, timesheet.workdetails[workType][i], workType)
      }
      return totalAmount
    }else{
      return calcTotalAmount(calcTotalTime(timesheet.workdetails[workType]), workType === "standardTime" ? payRateDetails.reverse()[0].billingRate: payRateDetails.reverse()[0].OTbillingRate)
    }
  }
}


function getPayRate(timesheet, payRateDetails, workType){
  const start = new Date(timesheet.startDate).setHours(0, 0, 0, 0), end = new Date(timesheet.endDate).setHours(0, 0, 0, 0)
  for(let i = 0; i < payRateDetails.length; i++){
    // check both dates in range or not
    const payRate = payRateDetails[i]
    const checkStartRange = isWithinInterval(start, {
      start: new Date(payRate.effectiveDate).setHours(0, 0, 0, 0),
      end: new Date(payRate.effectiveUntil).setHours(0, 0, 0, 0)
    })

    const checkEndRange = isWithinInterval(end, {
      start: new Date(payRate.effectiveDate).setHours(0, 0, 0, 0),
      end: new Date(payRate.effectiveUntil).setHours(0, 0, 0, 0)
    })

    if(checkStartRange && checkEndRange){
      return workType === "standardTime" ? payRate.billingRate: payRate.OTbillingRate
    }
    else if(checkStartRange || checkEndRange){
      return workType === "standardTime" ? payRate.billingRate: payRate.OTbillingRate + ", " + workType === "standardTime" ? payRateDetails[i+1].billingRate: payRateDetails[i+1].OTbillingRate 
    }else if(checkEndRange){
      return workType === "standardTime" ? payRate.billingRate: payRate.OTbillingRate + ", " + workType === "standardTime" ? payRateDetails[i-1].billingRate: payRateDetails[i-1].OTbillingRate 
    }else{
      return workType === "standardTime" ? payRateDetails.reverse()[0].billingRate: payRateDetails.reverse()[0].OTbillingRate
    }
  }

}


function IterateThroughEveryPaymentRate(date, payRateDetails, workingTime, workType){
  for(let i = 0; i < payRateDetails.length; i++){
    const start = new Date(payRateDetails[i].effectiveDate).setHours(0, 0, 0, 0), end = new Date(payRateDetails[i].effectiveUntil).setHours(0, 0, 0, 0)
    const checkRange = isWithinInterval(date, {
      start: start,
      end: end
    })
    if(checkRange){
      const [h, m] = workingTime.split(":");
      return Math.floor(h * payRateDetails[i][workType === "standardTime" ? "billingRate" : "OTbillingRate"] + (payRateDetails[i][workType === "standardTime" ? "billingRate" : "OTbillingRate"] * m) / 60);
    }
  }
}
