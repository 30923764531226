import React, { Component } from 'react'
import Presentation from "./Presentation"
import { calcTotalTime, calcTotalAmount, calcAmount } from "../ServiceSummary/Presentation"
import { DataContext } from '../../../../../contexts/data'
import firebase from "../../../../../firebase_config"
export class Container extends Component {

    state = {
        paymentsInfo: {}
    }

    static contextType = DataContext

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { placementID, timesheets, discountDetails, subTotal, selectedExpenses } = nextProps
        const [state] = this.context
        if(placementID !== "" && placementID !== this.props.placementID){
            const placement = state.placements_list.filter(item => item.placementID === placementID)[0]
            const docId = placement.id
            firebase.firestore().collection("Placements").doc(docId).collection("Payments").limit(1).get().then(snap => {
                const data = snap.docs[0].data()
                this.setState({paymentsInfo:data})
                console.log(data)
                this.props.handleChange("paymentsInfo", data)
                this.props.handleChange("subTotal", this.calculateSubTotal(timesheets, data) + selectedExpenses.reduce((initial, item) => initial + Number(item.amount), 0))
            })
        }
        if(JSON.stringify(timesheets) !== JSON.stringify(this.props.timesheets)  && Object.keys(this.state.paymentsInfo).length){
            this.props.handleChange("subTotal", this.calculateSubTotal(timesheets, this.state.paymentsInfo) + selectedExpenses.reduce((initial, item) => initial + Number(item.amount), 0))
        }
    }

    calculateSubTotal = (timesheets, paymentsInfo) => {
        let totalAmount = 0
        if(Array.isArray(timesheets))
            timesheets.forEach(timesheet => {
                // totalAmount += calcTotalAmount(calcTotalTime(timesheet.workdetails.standardTime), paymentsInfo, [timesheet.startDate, timesheet.endDate])
                // totalAmount += calcTotalAmount(calcTotalTime(timesheet.workdetails.OTtime), paymentsInfo, [timesheet.startDate, timesheet.endDate])
                totalAmount += calcAmount(timesheet, paymentsInfo.data, "standardTime")
                totalAmount += calcAmount(timesheet, paymentsInfo.data, "OTtime")
            })
        console.log(totalAmount,  paymentsInfo)
        return totalAmount
    }


    render() {
        return (
            <div>
                <Presentation 
                    {...this.props}
                />
            </div>
        )
    }
}

export default Container
