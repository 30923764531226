import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../contexts/data'
import { eachDayOfInterval, isWithinInterval, differenceInCalendarDays, addDays } from "date-fns"
import firebase from "firebase"
import CustomSpinner from "../../../../generalcomponents/circularSpinner"
export class Container extends Component {
    static contextType = DataContext
    constructor(props){
        super(props)
        console.log(props)
        this.state={
            timesheets:props.timesheets,
            paymentsInfo: {}
        }
    }

    componentWillReceiveProps = (nextProps) => {
        console.log(this.props)
        const [state] = this.context
        const selectedTimesheets = nextProps.timesheets
        this.setState({timesheets: selectedTimesheets})
        const { empCode, clientId, placementID, startDate, endDate, handleChange } = nextProps
        console.log(startDate, endDate)
        if(placementID !== "" && placementID !== this.props.placementID){
            const placement = state.placements_list.filter(item => item.placementID === placementID)[0]
            const docId = placement.id
            firebase.firestore().collection("Placements").doc(docId).collection("Payments").limit(1).get().then(snap => {
                const data = snap.docs[0].data()
                this.setState({paymentsInfo:data})
                handleChange("paymentsInfo", data)
            })
        }
        const checkUpdate =  empCode !== this.props.empCode || clientId !== this.props.clientId || placementID !== placementID || startDate !== this.props.startDate || endDate !== this.props.endDate
        if(![empCode, clientId, placementID, startDate, endDate].includes("") && checkUpdate && false){
            const invoiceStart = {
                day: new Date(startDate).getDate(),
                month: new Date(startDate).getMonth(),
                year: new Date(startDate).getFullYear()
            }

            const invoiceEnd = {
                day: new Date(endDate).getDate(),
                month: new Date(endDate).getMonth(),
                year: new Date(endDate).getFullYear()
            }

            const invoiceStartDate = new Date(startDate).setHours(0, 0, 0, 0)
            const invoiceEndDate = new Date(endDate).setHours(0, 0, 0, 0)

            // creating seperate array so that it's values does not get changed with the operation
            const timesheetsArr = JSON.stringify(state.timesheets_list)
            const timesheets = JSON.parse(timesheetsArr).filter(item => {
                const timesheetStart = {
                    day: new Date(item.startDate).getDate(),
                    month: new Date(item.startDate).getMonth(),
                    year: new Date(item.startDate).getFullYear()
                }

                const timesheetEnd = {
                    day: new Date(item.endDate).getDate(),
                    month: new Date(item.endDate).getMonth(),
                    year: new Date(item.endDate).getFullYear()
                }
                const timesheetStartDate = new Date(item.startDate).setHours(0, 0, 0, 0)
                const timesheetEndDate = new Date(item.endDate).setHours(0, 0, 0, 0)
                const checkStartRange = isWithinInterval(timesheetStartDate, {
                    start: invoiceStartDate,
                    end: invoiceEndDate
                })
                const checkEndRange = isWithinInterval(timesheetEndDate, {
                    start: invoiceStartDate,
                    end: invoiceEndDate
                })
                return item.empCode === empCode && item.clientId === clientId && item.placementID === placementID && item.isApproved && !item.isInvoiced && (checkStartRange || checkEndRange)        
            }).map(item => {
                const timesheetStart = {
                    day: new Date(item.startDate).getDate(),
                    month: new Date(item.startDate).getMonth(),
                    year: new Date(item.startDate).getFullYear()
                }

                const timesheetEnd = {
                    day: new Date(item.endDate).getDate(),
                    month: new Date(item.endDate).getMonth(),
                    year: new Date(item.endDate).getFullYear()
                }

                const timesheetStartDate = new Date(item.startDate).setHours(0, 0, 0, 0)
                const timesheetEndDate = new Date(item.endDate).setHours(0, 0, 0, 0)
                const checkStartRange = timesheetStartDate >= invoiceStartDate && timesheetStartDate <= invoiceEndDate
                const checkEndRange = timesheetEndDate >= invoiceStartDate && timesheetEndDate <= invoiceEndDate
                
                if(checkStartRange && checkEndRange){
                    return item
                }else if(checkStartRange){
                    const daysToEnd = differenceInCalendarDays(invoiceEndDate, timesheetStartDate)
                    console.log(daysToEnd)
                    item['endDate'] = addDays(new Date(timesheetStartDate), daysToEnd).toISOString()
                    item['workdetails']['standardTime'] = item['workdetails']['standardTime'].filter((v, i) => i <= daysToEnd)
                    item['workdetails']['OTtime'] = item['workdetails']['OTtime'].filter((v, i) => i <= daysToEnd)
                }else if(checkEndRange){
                    const daysToStart = differenceInCalendarDays(timesheetEndDate, invoiceStartDate)
                    console.log(daysToStart)
                    item['startDate'] = addDays(new Date(timesheetEndDate), -daysToStart).toISOString()
                    item['workdetails']['standardTime'] = item['workdetails']['standardTime'].reverse().filter((v, i) => i <= daysToStart).reverse()
                    item['workdetails']['OTtime'] = item['workdetails']['OTtime'].reverse().filter((v, i) => i <= daysToStart).reverse()
                }
                return item
            }).sort((a, b) => a.startDate - b.startDate)
            this.setState({timesheets})
            console.log(timesheets)
            handleChange("timesheets", timesheets)
            if(placementID !== ""){
                const placement = state.placements_list.filter(item => item.placementID === placementID)[0]
                const docId = placement.id
                firebase.firestore().collection("Placements").doc(docId).collection("Payments").limit(1).get().then(snap => {
                    const data = snap.docs[0].data()
                    this.setState({paymentsInfo:data})
                })
            }
        }else if(checkUpdate && false){
            this.setState({
                timesheets:[],
                paymentsInfo:{}
            })
            handleChange("timesheets", [])
        }
    }


    // shouldComponentUpdate = (nextProps) => {
    //     const { empCode, clientId, placementID, startDate, endDate } = nextProps
    //     return 
    
    // }


    render() {
        if(Object.keys(this.state.paymentsInfo).length)
            return (
                <div>
                    <Presentation 
                        {...this.state}
                        handleChange={this.props.handleChange}
                        placementID={this.props.placementID}
                        selectedExpenses={this.props.selectedExpenses}
                    />
                </div>
            )
        return <div className="h2 text-center mt-5" >Please wait a moment...</div>    
    }
}

export default Container
