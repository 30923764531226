import React from 'react'
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  makeStyles,
  Button,
  Tabs,
  Tab,
  Box,
  IconButton,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Validations from '../../../../generalcomponents/validation'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import MaterialTable from 'material-table'
import EditIcon from '@material-ui/icons/Edit'
import CurrencyFormat from 'react-currency-format'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    width: '100%',
  },
  select: {
    minWidth: '100%',
  },
}))

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalScale={2}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      isNumericString
    />
  )
}

function PercentageFormatCustom(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values
        return formattedValue === '' || floatValue <= 100
      }}
      decimalScale={2}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      isNumericString
    />
  )
}

function CustomCurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      prefix='$'
    />
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const Presentation = (props) => {
  const classes = useStyles()

  const {
    handleChange,
    handleTab,
    billingType,
    billingRate,
    OTBillRatePerHr,
    purchaseOrder,
    payType,
    percentage,
    OTPayRatePerHr,
    handleDateChange,
    handleSave,
    value,
    effectiveDate,
    effectiveUntilDate,
    update,
    handleData,
    showOT,
    createdAt,
    updatedAt,
    fixedPay,
    payments,
    reqDate,
    handleCancel,
  } = props

  const [employeePayRate, setEmployeePayRate] = React.useState(
    props.employeePayRate
  )

  React.useEffect(() => {
    setEmployeePayRate((parseInt(billingRate) * parseInt(percentage)) / 100)
    handleChange('employeePayRate', employeePayRate.toString())
  })

  const handleEffectiveDateChange = (date) => {
    handleDateChange('effectiveDate', date)
  }

  const handleEffectiveUntilDateChange = (date) => {
    handleDateChange('effectiveUntilDate', date)
  }

  const validate = new Validations()

  const columns = [
    {
      title: 'Effective Date',
      field: 'effectiveDate',
      render: (rowData) => <span>{rowData.effectiveDate}</span>,
    },
    {
      title: 'Effective Until',
      field: 'effectiveUntilDate',
      render: (rowData) => <span>{rowData.effectiveUntilDate}</span>,
    },
    {
      title: 'Billing Type',
      field: 'billingType',
      render: (rowData) => <span>{rowData.billingType}</span>,
    },
    {
      title: 'Billing Rate',
      field: 'billingRate',
      render: (rowData) => <span>{rowData.billingRate}</span>,
    },
    {
      title: 'OT Billing Rate',
      field: 'OTBillRatePerHr',
      render: (rowData) => <span>{rowData.OTBillRatePerHr}</span>,
    },
    {
      title: 'PO Number',
      field: 'purchaseOrderNumber',
      render: (rowData) => <span>{rowData.purchaseOrderNumber}</span>,
    },
    {
      title: 'Pay Type',
      field: 'payType',
      render: (rowData) => <span>{rowData.payType}</span>,
    },
    {
      title: 'Fixed Pay',
      field: 'fixedPay',
      render: (rowData) => (
        <span>
          <CurrencyFormat
            value={rowData.fixedPay}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => value}
          />
        </span>
      ),
    },
    {
      title: 'Percentage',
      field: 'percentage',
      render: (rowData) => <span>{rowData.percentage}</span>,
    },
    {
      title: 'Emp. Pay Rate',
      field: 'employeePayRate',
      render: (rowData) => <span>{rowData.employeePayRate}</span>,
    },
    {
      title: 'OT Pay Rate',
      field: 'OTpayRate',
      render: (rowData) => <span>{rowData.OTpayRate}</span>,
    },
    {
      title: 'Created At',
      field: 'createdAt',
      render: (rowData) => <span>{rowData.createdAt}</span>,
    },
    {
      title: 'Updated On',
      field: 'updatedOn',
      render: (rowData) => <span>{rowData.updatedOn}</span>,
    },
    {
      title: 'Actions',
      field: 'actions',
      render: (rowData) => (
        <span>
          <IconButton
            onClick={(e) => {
              e.preventDefault()
              handleChange('update', true)
              handleData(rowData)
            }}
          >
            <EditIcon />
          </IconButton>
        </span>
      ),
    },
  ]

  let data = []
  payments &&
    payments.forEach((payment, index) => {
      data.push({
        createdAt: validate.dateFormatter(createdAt),
        billingType: payment.billingType,
        billingRate: payment.billingRate,
        OTBillRatePerHr: payment.OTbillingRate,
        OTpayRate: payment.OTpayRate,
        purchaseOrderNumber: payment.purchaseOrderNumber,
        payType: payment.payType,
        fixedPay: payment.payType === 'Hourly' ? '-' : payment.fixedPay,
        percentage: payment.payType === 'Hourly' ? payment.percentage : '-',
        effectiveDate: validate.dateFormatter(payment.effectiveDate),
        effectiveUntilDate: validate.dateFormatter(payment.effectiveUntil),
        employeePayRate:
          payment.payType === 'Hourly' ? payment.employeePayRate : '-',
        updatedOn: validate.dateFormatter(updatedAt[index]),
        index: index,
      })
    })
  return (
    <div className={classes.root}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <Tabs value={value} onChange={handleTab} variant='scrollable'>
          <Tab label='Billing Rate' />
          <Tab label='Pay Rate' />
          <Tab label='History' />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container spacing={1}>
            <Grid item xs={3} className={classes.select}>
              <TextField
                select
                label='Billing Type'
                size='small'
                value={billingType}
                fullWidth
                required
                onChange={(event) =>
                  handleChange('billingType', event.target.value)
                }
              >
                {billingTypes.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant='outlined'
                label='Billing Rate'
                size='small'
                fullWidth
                required
                value={billingRate}
                onChange={(event) =>
                  handleChange('billingRate', event.target.value)
                }
                InputProps={{
                  inputComponent: CustomCurrencyFormat,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant='outlined'
                label='OT Billing Rate'
                size='small'
                fullWidth
                value={OTBillRatePerHr}
                onChange={(event) =>
                  handleChange('OTBillRatePerHr', event.target.value)
                }
                InputProps={{
                  inputComponent: CustomCurrencyFormat,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size='small'
                variant='outlined'
                label='PO Number'
                fullWidth
                value={purchaseOrder}
                onChange={(event) =>
                  handleChange('purchaseOrder', event.target.value)
                }
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={1}>
            <Grid item className={classes.select}>
              <TextField
                select
                label='Pay Type'
                fullWidth
                required
                value={payType}
                onChange={(event) =>
                  handleChange('payType', event.target.value)
                }
              >
                {payTypes.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {payType === 'Hourly' ? (
              <Grid item xs={3}>
                <TextField
                  label='Percentage'
                  required
                  size='small'
                  variant='outlined'
                  fullWidth
                  value={percentage ? percentage : ''}
                  onChange={(event) =>
                    handleChange('percentage', event.target.value)
                  }
                  InputProps={{
                    inputComponent: PercentageFormatCustom,
                  }}
                />
              </Grid>
            ) : null}
            {payType === 'Fixed Pay' ? (
              <Grid item xs={3}>
                <TextField
                  size='small'
                  variant='outlined'
                  required
                  label='Fixed pay'
                  fullWidth
                  value={fixedPay}
                  onChange={(event) =>
                    handleChange('fixedPay', event.target.value)
                  }
                  InputProps={{
                    inputComponent: CustomCurrencyFormat,
                  }}
                />
              </Grid>
            ) : null}
            {payType === 'Hourly' ? (
              <>
                <Grid item xs={3}>
                  <TextField
                    size='small'
                    variant='outlined'
                    label='Employee Pay Rate'
                    fullWidth
                    required
                    value={isNaN(employeePayRate) ? '' : employeePayRate}
                    onChange={(event) =>
                      handleChange('employeePayRate', event.target.value)
                    }
                    InputProps={{
                      inputComponent: CustomCurrencyFormat,
                      readOnly: true,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    size='small'
                    variant='outlined'
                    label='OT Pay Rate'
                    fullWidth
                    value={OTPayRatePerHr}
                    inputProps={{
                      readOnly: Boolean(!showOT),
                      disabled: Boolean(!showOT),
                    }}
                    onChange={(event) =>
                      handleChange('OTPayRatePerHr', event.target.value)
                    }
                    InputProps={{
                      inputComponent: CustomCurrencyFormat,
                    }}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className='d-flex'>
                  <KeyboardDatePicker
                    required
                    minDate={
                      update
                        ? new Date('1970-01-01T00:00:01.898Z').toISOString()
                        : reqDate
                    }
                    size='small'
                    style={{ marginTop: '3px', marginRight: '10px' }}
                    id='date-picker-effectiveDate'
                    label='Start date'
                    format='MM/dd/yyyy'
                    value={effectiveDate ? effectiveDate : null}
                    onChange={handleEffectiveDateChange}
                    name='effectiveDate'
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <div></div>
                  <KeyboardDatePicker
                    required
                    minDate={effectiveDate}
                    //maxDate={state.project.enddate}
                    id='date-picker-effectiveUntilDate'
                    label='End date'
                    format='MM/dd/yyyy'
                    value={effectiveUntilDate ? effectiveUntilDate : null}
                    onChange={handleEffectiveUntilDateChange}
                    name='effectiveUntilDate'
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MaterialTable
            title='Payments'
            columns={columns}
            data={data.sort((a, b) => a.index < b.index)}
            options={{
              paginationType: 'stepped',
              pageSize: 5,
              grouping: true,
              columnsButton: true,
              sorting: true,
            }}
          />
        </TabPanel>
        <br />
        {!update ? (
          validate.checkDateDiff(effectiveDate, effectiveUntilDate) &&
          billingType &&
          billingRate.trim().length &&
          payType &&
          (percentage.trim().length || fixedPay.trim().length) ? (
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={(e) => handleSave('create')}
            >
              Done
            </Button>
          ) : (
            <Button type='button' variant='contained' color='primary' disabled>
              Done
            </Button>
          )
        ) : validate.checkDateDiff(effectiveDate, effectiveUntilDate) &&
          billingType &&
          billingRate.trim().length &&
          payType &&
          (percentage.trim().length || fixedPay.trim().length) ? (
          <div>
            <Button
              type='submit'
              variant='contained'
              color='secondary'
              onClick={(e) => handleSave('update')}
            >
              Update
            </Button>
            {' '}
            <Button
              type='button'
              variant='contained'
              color='inherit'
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div>
            <Button type='button' variant='contained' color='primary' disabled>
              Update
            </Button>
            {' '}
            <Button
              type='button'
              variant='contained'
              color='inherit'
              onclick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
      </form>
    </div>
  )
}

export default Presentation

const billingTypes = [
  'Per hour',
  'Per day',
  'Per week',
  'Per month',
  'Per annum',
]
const payTypes = ['Hourly', 'Fixed Pay']
