import React from 'react'
import { Table, TableBody, TableRow, TableCell, TableContainer, TableHead, Paper, TextField } from '@material-ui/core'
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Validations from '../../../../generalcomponents/validation';
import NumberFormat from 'react-number-format'

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const HolidayStyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: "black",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

const dayFormat = (index) => {
    switch (index) {
        case 0: return "Sunday"
        case 1: return "Monday"
        case 2: return "Tuesday"
        case 3: return "Wednesday"
        case 4: return "Thursday"
        case 5: return "Friday"
        case 6: return "Saturday"
        default: return ""
    }
}  

const getRange = (range) => {
    switch (range) {
        case 0: return "DAILY"
        case 1: return "WEEKLY"
        case 2: return "BI-WEEKLY"
        case 3: return "SEMI-MONTHLY"
        case 4 : return "MONTHLY"
        default: return ""
    }
}

function formatTime(time){
  const { formattedValue, value } = time
  const check = (val, max) => {
    if(parseInt(value) > 2400 || (val.length === max.length && parseInt(val) > parseInt(max))){
      return false
    }
    return true
  }
  const hours = check(formattedValue.substring(0, 2), '24')
  const minutes = check(formattedValue.substring(3, 5), '59')
  return hours && minutes
}


function timeEntryFormat(props) {
  const { inputRef, onChange, ...other } = props;
  console.log(props)
  return (
      <NumberFormat
          {...other}
          getInputRef={inputRef}
          decimalScale={2}
          format="##:##"
          isAllowed={formatTime}
          mask={['h', 'h', 'm', 'm']}
          placeholder="hh:mm"
          allowNegative={false}
          onValueChange={(values) => {
              onChange({
                  target: {
                      name: props.name,
                      value: values.formattedValue,
                  },
              });
          }}
          // isNumericString
      />
  );
}


function Presentation(props) {
    const classes = useStyles();
    const { cycle, startDay, standardTimeArr, OTArr, handleTime, placementStartDate, projectEndDate } = props
    const validate = new Validations()
    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                    <TableRow style={{background: "linear-gradient(45deg, #280071 10%, #c42053 90%)",}} >
                        <StyledTableCell ><div className="d-flex flex-column" ><span>{getRange(cycle)}</span><span>{ validate.dateFormatter(standardTimeArr[0].date) + "-" + validate.dateFormatter(standardTimeArr[standardTimeArr.length - 1].date) }</span></div> </StyledTableCell>
                        {
                            standardTimeArr.map((item, index) => {
                                if(new Date(item.date).getDay() === 0 || new Date(item.date).getDay() === 6 || validate.dateWithNoTime(item.date) < validate.dateWithNoTime(placementStartDate))
                                    return <HolidayStyledTableCell key={index} align="right"><div className="d-flex flex-column" ><span>{validate.dateFormatter(item.date)}</span><span>{dayFormat(new Date(item.date).getDay())}</span></div></HolidayStyledTableCell>
                                return <StyledTableCell key={index} align="right"><div className="d-flex flex-column" ><span>{validate.dateFormatter(item.date)}</span><span>{dayFormat(new Date(item.date).getDay())}</span></div></StyledTableCell>
                            })
                        }

                    </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Total hrs/day
                            </StyledTableCell>
                            {
                                standardTimeArr.map((item, index) => {
                                    return <StyledTableCell key={index} align="right">{new Date(item.date).getDay() === 0 || new Date(item.date).getDay() === 6 || validate.dateWithNoTime(item.date) < validate.dateWithNoTime(placementStartDate) ? "00:00" : "08:00"} </StyledTableCell>
                                })
                            }
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Standard time
                            </StyledTableCell>
                            {
                                standardTimeArr.map((item, index) => {
                                    return <StyledTableCell key={index} align="right"><TextField  InputProps={{inputComponent: timeEntryFormat}} disabled={validate.dateWithNoTime(item.date) < validate.dateWithNoTime(placementStartDate) || validate.dateWithNoTime(item.date) > validate.dateWithNoTime(projectEndDate) ? true : false} onChange={(e) => handleTime("standard", index, e.target.value)} type="text" style={textFieldStyles} variant="standard" value={item.value} /></StyledTableCell>
                                })
                            }
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                OT
                            </StyledTableCell>
                            {
                                OTArr.map((item, index) => {
                                    return <StyledTableCell key={index} align="right"><TextField InputProps={{inputComponent: timeEntryFormat}} disabled={validate.dateWithNoTime(item.date) < validate.dateWithNoTime(placementStartDate) || validate.dateWithNoTime(item.date) > validate.dateWithNoTime(projectEndDate)  ? true : false} onChange={(e) => handleTime("ot", index, e.target.value)} type="text" style={textFieldStyles} variant="standard" value={item.value} /></StyledTableCell>
                                })
                            }
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default Presentation



const textFieldStyles = {
    width : '47px'
}
