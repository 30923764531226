import React, { useContext, useState, useEffect } from 'react';
import {
    Tabs,
    Tab,
    Box,
    Typography
} from '@material-ui/core'
import PropTypes from 'prop-types';
import BusinessInformation from "../BusinessInformation";
import Contacts from "../Contacts/Index";
import Locations from "../Locations/Index";
import { DataContext } from '../../../../../contexts/data';
// import Accounts from "../Accounts";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const Presentation = (props) => {
    const { value, handleChange } = props
    const [state] = useContext(DataContext)
    const [locationFlag, setLocationFlag] = useState(true)
    const [contactFlag, setContactFlag] = useState(true)
    useEffect(() => {
        if(state.hasOwnProperty('temporary_client_data') && state.temporary_client_data.businessInformation) {
            setContactFlag(false)
        }
        if(state.hasOwnProperty('temporary_client_data') && state.temporary_client_data.contacts) {
            setLocationFlag(false)
        }
        //
    })
    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
            >
                <Tab label="Business Information"/>
                <Tab disabled={contactFlag} label="Contacts"/>
                <Tab disabled={locationFlag} label="Locations"/>
                {/* <Tab label="Accounts"/> */}
            </Tabs>
            <TabPanel value={value} index={0}>
                <BusinessInformation {...props} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Contacts {...props} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Locations {...props} />
            </TabPanel>
            {/* <TabPanel value={value} index={3}>
                <Accounts {...props}/>
            </TabPanel> */}
        </div>
    );
}
 
export default Presentation;