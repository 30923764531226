import React, { Component } from 'react';
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'

class Container extends Component {

    state = {
        personal: {},
        profile: {},
    }
    static contextType = DataContext

    componentDidMount = () => {
        const [state] = this.context
        const id = this.props.id
        let profile = {}
        profile = state.employees_collection.filter(employee => {
            return employee.companyID === id || employee.useremail === id
        })[0]
        this.setState({ personal: profile.personal, profile: profile})
    }
    
    render() {
        
        return (
            <Presentation
                {...this.state}
            />
        );
    }
}

export default Container;