import React, { Component } from 'react'
import Presentation from './Presentation'
import firebase from '../../../../firebase_config'
import CustomSpinner from '../../../generalcomponents/circularSpinner'
export class Container extends Component {
    state = {
        clients : [],
        isLoading : true
    }
    componentDidMount = () => {
        firebase.firestore().collection("ClientDetails").where("isExist", "==", true).onSnapshot(snap => {
            const clients = snap.docs.map(doc => doc.data())
            this.setState({ clients, isLoading : false })
            console.log(clients)
        })
    }
    render() {
            return (
                <div>
                    <Presentation 
                        { ...this.state }
                    />
                </div>
            )

    }
}

export default Container
