import React, { useContext } from 'react';
import AddNewPlacement from "../components/TimeSheets/Placements/AddNewPlacement/Index";
import CircularSpinner from '../components/generalcomponents/circularSpinner';
import { DataContext } from '../contexts/data';

const PlacementFormPage = (props) => {
    const [state] = useContext(DataContext)
    if(state.hasOwnProperty('placements_list'))
        return (
            <div>
                <AddNewPlacement
                    id = {props.match.params.id}
                    isEdit={false}
                />
            </div>
        );
    return <CircularSpinner/>
}
 
export default PlacementFormPage;