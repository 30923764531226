import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data';
import Axios from 'axios'
import firebase from '../../../../firebase_config'

import CustomSpinner from '../../../generalcomponents/circularSpinner'
class Container extends Component {
    state = {
        docId: '',
        companyID: '',
        placements: [],
        isLoading: true
    }

    static contextType = DataContext

    handleDocId = (id) => {
        console.log(id)
        this.setState({ docId: id })
    }


    componentDidMount = () => {
        const { id } = this.props
        const [state] = this.context
        const profile = state.employees_collection.filter(profile => profile.useremail  === id || profile.companyID === id )[0]
        this.setState({
            companyID: profile.companyID
        })
        firebase.firestore().collection("Placements").onSnapshot(snap => {
            //this.setState({ placements : snap.docs.map(doc => doc.data()), isLoading : false })
            let totalPlacements = snap.docs.map(doc => doc.data())
            let placements = totalPlacements.filter(item => { return item.empCode === profile.companyID })
            this.setState({ placements, isLoading: false })
        })
    }


    handleFinish = () => {
        Axios.post('/completeTimesheetsProcess', {placementID: this.state.docId}).then(res => console.log(res))
    }

    render() {
        return (
            <div>
                <Presentation
                    {...this.props}
                    {...this.state}
                    handleDocId={this.handleDocId}
                    handleChange={this.handleChange}
                    handleFinish={this.handleFinish}
                />
            </div>
        )
    }
}

export default Container
