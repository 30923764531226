import React, { useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import {
    Column,
    FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedPaging, IntegratedSelection, IntegratedSorting,
    PagingState, SelectionState, SortingState, DataTypeProvider, DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import {
    DragDropProvider,
    Grid, GroupingPanel, PagingPanel,
    Table, TableFilterRow, TableGroupRow,
    TableHeaderRow, TableSelection, Toolbar, TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import {IoMdPersonAdd} from 'react-icons/io'

import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from '../../../contexts/data'

import Chip from '@material-ui/core/Chip';

import Checkbox from '@material-ui/core/Checkbox';

import { Link } from 'react-router-dom'
import { GoMailRead } from 'react-icons/go'
import { MdDelete } from 'react-icons/md'
import Button from '@material-ui/core/Button';
import InviteEmployee from '../InviteEmployee/InviteDialog'
import ResetPasswordDialog from '../ResetPasswordDialog'


//const sales = generateRows({ columnValues: globalSalesValues, length: 1000 });
const availableFilterOperations = [
    'equal', 'notEqual',
    'greaterThan', 'greaterThanOrEqual',
    'lessThan', 'lessThanOrEqual', 'contains'
];

const styles = makeStyles(({
    currency: {
        fontWeight: "bold"
    },
    numericInput: {
        fontSize: '14px',
        width: '100%',
    },
}));

const getInputValue = (value) =>
    (value === undefined ? '' : value.toLocaleString());

const getColor = (amount) => {
    if (amount < 3000) {
        return '#F44336';
    }
    if (amount < 5000) {
        return '#FFC107';
    }
    if (amount < 8000) {
        return '#FF5722';
    }
    return '#009688';
};

const CurrencyEditor = (
    ({ onValueChange, classes, value }) => {
        const handleChange = (event) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                onValueChange(undefined);
                return;
            }
            onValueChange(parseInt(targetValue, 10));
        };
        return (
            <Input
                type='number'

                fullWidth={true}
                value={getInputValue(value)}
                inputProps={{
                    min: 0,
                    placeholder: 'Filter...',
                }}
                onChange={handleChange}
            />
        );
    }
);

const CurrencyFormatter = (
    ({ value, classes }
    ) => {
        if (value === "Manager")
            return <Chip style={{ width: "75%" }} label="Manager" />
        return <Chip style={{ width: "75%" }} label="User" />
    });
const StatusFromatter = (
    (props) => {
        console.log(props)
        const value = props.value
        if (value === "Active")
            return <Chip style={{ width: "75%", backgroundColor: "#21ba45", color: "white" }} label="Active" />
        else if (value === "Suspended")
            return <Chip style={{ width: "75%", backgroundColor: "#db2828", color: "white" }} label="Suspended" />
        return <Chip style={{ width: "75%", backgroundColor: "orange", color: "white" }} label="Inactive" />
    }
)
const EmployeeidFormatter = (
    ({ value }) => {
        console.log(value)
        return <Link style={{ color: "blue" }} to={"/console/employeelist/" + value} >{value}</Link>
    }

)

const CurrencyTypeProvider =
    (props) => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            {...props}
        />
    );
const StatusProvider = (props) => (
    <DataTypeProvider
        formatterComponent={StatusFromatter}
        {...props}
    />
)
const EmployeeProvider = (props) => (
    <DataTypeProvider
        formatterComponent={EmployeeidFormatter}
        {...props}
    />
)


export default function Presentation(props) {
    const { employeelist, handleSelect, selected, handleSelectAll, onInviteAgain, onDelete } = props
    const [state] = useContext(DataContext)

    const columns = [
        { name: 'name', title: 'Name' },
        { name: 'email', title: 'Email' },
        { name: 'phone', title: 'Phone' },
        { name: 'branch', title: 'Branch' },
        { title: 'Employee ID', name: 'employeeid', defaultSort: 'asc', },

        { title: 'Reporting manager', name: 'reportingmanager' },
        { title: 'Employee status', name: 'employeestatus' },
        { title: 'User type', name: 'usertype', },
        { title: 'Job title', name: 'jobtitle' },
        { title: 'Employee type', name: 'employeetype' },
        { title: 'Department', name: 'department' },
        { title: 'Status', name: 'status' },
        { title: <Checkbox onChange={(e) => handleSelectAll(e, employeelist.map(employee => employee.useremail))} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />, name: 'status1' },
        { title: '  ', name: 'delete1'}
    ]
    const [defaultHiddenColumnNames] = React.useState(['project']);

    const validate = {
        emailToName: (uid) => {
            try {
                return state.names.filter(item => item.uid === uid)[0].name
            } catch (error) {
                console.log(error)
                return uid
            }
        }
    }
    let rows = []
    employeelist.length && employeelist.forEach(employee => {
        if (employee.status !== "Inactive")
            rows.push({
                name: [employee.personal.firstname, employee.personal.middlename, employee.personal.lastname].join(' '),
                email: employee.useremail,
                phone: employee.personal.phonenumber,
                branch: employee.personal.branch,
                employeeid: employee.companyID,
                project: employee.project,
                reportingmanager: validate.emailToName(employee.personal.reportingmanager),
                employeestatus: employee.personal.employeestatus,
                usertype: employee.Role,
                jobtitle: employee.personal.jobtitle,
                employeetype: employee.personal.hasOwnProperty('category') ? employee.personal.category : '',
                department: employee.personal.department,
                status: employee.status,
                status1: employee.status,
                delete1: employee.status

            })
        else
            rows.push({
                name: employee.hasOwnProperty('personal') ? [employee.personal.firstname, employee.personal.lastname].join(' ') : '',
                email: employee.useremail,
                delete1: employee.status,
                status: employee.status,
                status1: employee.status,
                phone: employee.hasOwnProperty('personal') ? employee.personal.phonenumber : '',
                branch: employee.hasOwnProperty('personal') ? employee.personal.branch : '',
                dob: employee.hasOwnProperty('personal') ? employee.personal.dob : ''
            })
    })

    const [pageSizes] = React.useState([5, 10, 15]);
    const [delete1] = React.useState(['delete1']);
    const [userType] = React.useState(['usertype']);
    const [status] = React.useState(['status']);
    const [employeeid] = React.useState(['employeeid'])
    const [status1] = React.useState(['status1'])

    const PasswordProvider = (props) => (
        <DataTypeProvider
            formatterComponent={PasswordFormatter}
            editorComponent={CurrencyEditor}
            availableFilterOperations={availableFilterOperations}
            {...props}
        />
    )
    const DeleteProvider = (props) => (
        <DataTypeProvider
            formatterComponent={DeleteFormatter}

            {...props}
        />
    )

    const DeleteFormatter = (props) => {
        const Status = props.value
        let mail = props.row.email
        console.log(props)
        return (
            <span key={mail} className="d-flex" style={{ marginTop: '8px' }}>
                {Status === 'Inactive' ?
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            if (window.confirm("Are you sure you want to remove " + mail + "?"))
                                onDelete(mail)
                        }}>
                        <MdDelete className="c-pointer" size={18} />
                    </Button> : null}
            </span>
        )
    }

    const PasswordFormatter = (
        (props) => {
            const Status = props.value
            let mail = props.row.email
            // let Status = state.names.filter(e => e.uid === value)[0].status
            return (
                <span className="d-flex">
                    <Checkbox onChange={() => handleSelect(mail)} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} checked={selected.includes(mail)} />

                    {
                        Status === "Inactive" ?
                            <span key={mail} className="d-flex" style={{ marginTop: '8px' }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => onInviteAgain([mail])}>
                                    <GoMailRead className="c-pointer" size={18} />
                                </Button>



                            </span>
                            :
                            Status === "Active" ?
                                <span className="d-flex" style={{ marginTop: '8px' }}>
                                    <ResetPasswordDialog email={mail} />



                                </span>
                                :
                                Status === "Suspended" ?
                                    <span className="d-flex" style={{ marginTop: '8px' }}>

                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                if (window.confirm("Are you sure you want to remove " + mail + "?"))
                                                    onDelete(mail)
                                            }}>
                                            <MdDelete className="c-pointer" size={18} />
                                        </Button>
                                    </span>
                                    :
                                    null
                    }
                </span>
            )
        }
    )
    return (
        <Paper>
             <div >
                        <InviteEmployee template={false} heading="Invite new employee" text="Enter the email address of the new employee"  btnText={<span><IoMdPersonAdd style={{marginRight:'6px'}} size={18} />Invite new employee</span>} />
                    </div>
            <Grid
                rows={rows}
                columns={columns}
            >
                <FilteringState
                //  defaultFilters={[{ columnName: 'saleDate', value: '2016-02' }]}
                />
                <SortingState
                /*  defaultSorting={[
                    { columnName: 'product', direction: 'asc' },
                    { columnName: 'saleDate', direction: 'asc' },
                  ]}*/
                />

                <SelectionState />

                <GroupingState
                //   defaultGrouping={[{ columnName: 'product' }]}
                //  defaultExpandedGroups={['EnviroCare Max']}
                />
                <PagingState />

                <IntegratedGrouping />
                <IntegratedFiltering />
                <IntegratedSorting />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                //columnExtensions={tableColumnExtensions}
                />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <DeleteProvider for={delete1} />
                <CurrencyTypeProvider for={userType} />
                <StatusProvider for={status} />
                <EmployeeProvider for={employeeid} />
                <PasswordProvider for={status1} />
                <DragDropProvider />

                <Table />


                <TableHeaderRow showSortingControls={true} />
                <TableFilterRow showFilterSelector={true} />
                <PagingPanel pageSizes={pageSizes} />

                <TableGroupRow />
                <Toolbar />
                <GroupingPanel showSortingControls={true} />
            </Grid>
        </Paper>
    );
};
