import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../contexts/data'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import firebase from "../../../../../firebase_config"
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Badge } from "@material-ui/core"
export class Container extends Component {
    state = {
        cycle: '',
        startDate: '',
        endDate: '',
        invoiceFreq: null,
        invoiceFreqStartDate: '',
        placementEndDate: '',
        placementStartDate: '',
    }
    static contextType = DataContext
    componentWillReceiveProps = (nextProps) => {
        const { empCode, clientId, placementID } = nextProps
        const [state] = this.context
        if(empCode !== "" && clientId !== ""){
            const timesheets = state.timesheets_list.filter(item => item.empCode === empCode && item.clientId === clientId)
            const placementInfo = state.placements_list.filter(item => item.placementID === placementID)[0]
            this.setState({
                placementStartDate: placementInfo.startDate,
                placementEndDate: placementInfo.endDate,
            })
            firebase.firestore().collection("Placements").doc(placementInfo.id).collection("Timesheets").get().then(snap => {
                const timesheetSettings = snap.docs[0].data()
                this.setState({
                    cycle: timesheetSettings.cycle,
                    startDay: timesheetSettings.startDay
                })
            })

            firebase.firestore().collection("Placements").doc(placementInfo.id).collection("Invoices").get().then(invoiceSnap => {
                const invoiceSettings = invoiceSnap.docs[0].data()
                this.setState({
                    invoiceFreq: invoiceSettings.frequency,
                    invoiceFreqStartDate: invoiceSettings.frequencyStartDate
                })
            })
        }
    }


    render() {
        const { empCode, clientId } = this.props
        const { invoiceFreq, invoiceFreqStartDate, placementEndDate, placementStartDate } = this.state
        if(empCode !== "" && clientId !== "" && ![invoiceFreq, invoiceFreqStartDate, placementEndDate, placementStartDate].includes(""))
            return (
                <div>
                    <Presentation 
                        {...this.state}
                        {...this.props}
                    />
                </div>
            )
        return <div className="w-100">
                <hr/>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Timesheets <Badge badgeContent={0} /></Typography>
                    </ExpansionPanelSummary>
                </ExpansionPanel>
                </div>    
    }
}

export default Container
