import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TaskViewer from '../taskcomponents/TaskViewer'
import { DataContext } from '../../../../contexts/data'
import SubtasksTable from '../taskcomponents/SubtasksTable'
import NewTask from '../taskcomponents/NewTask'
import Comments from '../taskcomponents/Comments'
import Alert from '@material-ui/lab/Alert';
import Validations from '../../../generalcomponents/validation' 
import TaskTimeLine from '../taskcomponents/Timeline'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Presentation(props) {
  const classes = useStyles();
  const [ state ] = useContext(DataContext)
  const validate = new Validations()
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // getting real time task
  let task ={}
  if(Object.keys(state.task).length){
   const taskId = props.taskId
   task = state.taskList.filter(task => task.id === taskId)[0]
  }

  // getting project access real time
  let employee = {}
  const id = state.project.id
  if(state.employee.role === 'user'){
    const employees = state.projectList.filter(project => project.id === id)[0].Users
    employee = employees.filter(user => user.uid === state.employee.email)[0]
  }
  let access = Object.keys(employee).length

  //  checking supervisor count
  let supervisorCount = 0
  try {
    const employees = state.projectList.filter(project => project.id === id)[0].Users
    employees.forEach(member => {
      if(validate.checkSupervisor(member.uid))
          supervisorCount++
    })
  } catch (error) {
  }

  if(supervisorCount > 1)
    return <Alert className="text-center" variant="filled" severity="warning">This project contain multiple Supervisors, Please contact Admin/Manager.</Alert>
  
  if(state.employee.role === 'admin' || state.employee.role === 'manager' || (access  && employee.create )|| (access  && employee.update) || (access  && employee.read)  )  
      return (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card className={classes.root}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TaskViewer 
                        taskId = {props.taskId}
                        projectId = {props.projectId}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                  {
                      task.category  === 'task' 
                      ?
                          <div>
                            <NewTask projectId={state.project.id} taskId={state.task.id} category="subtask" text="New subtask" />  
                            <Grid item xs={12}>
                              <SubtasksTable 
                                taskId = {props.taskId}
                                projectId = {props.projectId}
                              />
                            </Grid>
                          </div>
                      :
                      null
                   }
                  </Grid>
                  <AppBar position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      
                      <Tab label={<Badge color="primary" children="Comments"  />} />
                      <Tab label={<Badge color="primary" children="Timeline" />}  />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0}>
                    <Grid item xs={12} >
                      <Card className={classes.root}>
                          <CardContent>
                            <h2>Comments:</h2>
                            <Comments 
                            comments={props.comments} 
                            taskId = {props.taskId}
                            projectId = {props.projectId}
                            />
                          </CardContent>
                      </Card>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                      <Grid item xs={12} >
                        <TaskTimeLine />
                      </Grid>
                  </TabPanel>
                </CardContent>
              </Card>
            </Grid>
        </Grid>
        {/* <TaskPDF comments={props.comments} /> */}
        </div>
        
      );

  return <Alert className="text-center" variant="filled" severity="error">You are unauthorized to view this content</Alert>
}
