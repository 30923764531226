import React from 'react';
import CurrencyFormat from 'react-currency-format';
import TextField from "@material-ui/core/TextField";
function Presentation(props) {
    const { amount,subTotal,isReceivedAmount,isOpenBalance,discount} = props
    console.log(discount,amount)
   if (isOpenBalance && !isReceivedAmount) {
    return (
        <div>
            <CurrencyFormat
                value={(parseFloat(subTotal) - parseFloat(amount)).toFixed(2) - parseFloat(discount).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                renderText={value => value}
            />
        </div>
    );
}
else {
    return (
        <div>
        <CurrencyFormat
            value={(parseFloat(amount)).toFixed(2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            renderText={value => value}
        />
    </div>
    )
}
    

}

export default Presentation;