import React, { PureComponent } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import { SET_TASKS } from '../../../contexts/types'
import firebase from '../../../firebase_config'
class Container extends PureComponent {
    state = {
        projectId:"",
        project:{}
    }
    static contextType = DataContext
    componentWillReceiveProps = (nextProps) => {
        if(nextProps.projectId !== this.state.projectId)
            this.setState({projectId:nextProps.projectId})
    }

  

    componentDidMount = () => {
        const projectId = this.props.projectId
        this.setState({projectId})
        const dispatch = this.context[1]
        
        console.log("iam mounted")
        firebase.firestore().collection("Projects/"+projectId+"/Tasks")
        .where("isExist","==",true)
        .onSnapshot(snap => {
            let data = snap.docs.map(doc => doc.data())
            dispatch({
                type:SET_TASKS,
                payload:data
            })    
        })  
    }

    handleComponentUpdate = () => {
        console.log("got it")
        this.setState({
            update : 'component'
        })
    }

    shouldComponentUpdate(nextProps){
        console.log("mount project")
        const projectId = this.props.projectId === nextProps.projectId
        return projectId
    }

  
    render() {
        const [ state ] = this.context
        console.log("iam rendered")
        return (
            <div>
                {
                    state.hasOwnProperty("projectList")  ?
                    <Presentation 
                    projectId={this.props.projectId}
                    handleComponentUpdate = {this.handleComponentUpdate}
                    {...this.state}
                /> 
                :
                <p style={{textAlign:"center"}} >Loading ...</p>
                }
               
            </div>
        )
    }
}

export default Container
