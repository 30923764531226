import React, { PureComponent } from 'react'
import Presentation from './Presentation'
import firebase from '../../../../../firebase_config'
import { DataContext } from '../../../../../contexts/data'
import {
  calcTotalAmount,
  calcTotalTime,
} from '../../CreateInvoice/ServiceSummary/Presentation'

class Container extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      totalAmount: [],
      discount: [],
      grandTotal: [],
      show: false,
      amount: 0,
      invoicesList: props.invoicesList && props.invoicesList.map(invoice => {
        return {
          ...invoice,
          isChecked: false,
          paymentBalance: 0,
          paymentDiscount: {
            name: "",
            type: "",
            value: 0
          },
          paymentDiscountAmount: 0
        }
      }),

    }

  }

  static contextType = DataContext


  componentDidMount = async () => {
    const { invoicesList } = this.props
    // let placementIDs =
    //   invoicesList && invoicesList.map((invoice) => invoice.placementID)
    // if (Array.isArray(placementIDs) && placementIDs.length) {
    //   const paymentsInfo = await this.getData([...new Set(placementIDs)])
    //   const invoicesInfo = await this.getInvoices([...new Set(placementIDs)])
    //   if (invoicesList.length) {
    //     invoicesList.forEach((invoice) => {
    //       this.getDetails(
    //         paymentsInfo,
    //         invoicesInfo,
    //         invoice.placementID,
    //         invoice.timesheets,
    //         invoice.discountDetails
    //       )
    //     })
    //   }
    // }
  }
  handleDiscount = (type, value, amount) => {
    let data = 0;
    type === 'byValue'
      ? data =
      !isNaN(parseInt(value))
        ? parseInt(value).toFixed(2)
        : 0.0

      : type === 'byPercentage'
        ? data =
        (amount * (value / 100)).toFixed(2)

        : data = 0
    return data;
    console.log(data)
  }
  openBalance = (invoiceNumber, paymentFilterList) => {
    let amount = 0;
    let discount = 0;

    if (paymentFilterList !== undefined) {
      const list = paymentFilterList.map(item => item.paymentDetails.filter(v => v.invoiceNumber === invoiceNumber))
      for (let index = 0; index < list.length; index++) {
        let amountfinal = list[index].map(item => item.paymentAmount)
        for (let index = 0; index < amountfinal.length; index++) {
          amount = parseFloat(amount) + parseFloat(amountfinal[index] === null ? 0 : amountfinal[index])
        }
      }
      for (let index = 0; index < list.length; index++) {
        let amountfinal1 = list[index].map(item => item.paymentDiscounts)
        let totalempount = list[index].map(item => item.paymentTotalBalance)
        for (let index = 0; index < amountfinal1.length; index++) {
          discount = parseFloat(discount) + parseFloat(this.handleDiscount(amountfinal1[index].type, amountfinal1[index].value, totalempount))
        }
      }

      return parseFloat(amount.toFixed(2)) + parseFloat(discount.toFixed(2))
    }
    else {
      return parseFloat(amount.toFixed(2)) + parseFloat(discount.toFixed(2))
    }

  }
  latestPayment = (invoiceNumber, paymentFilterList) => {
    let date = new Date(), dateFinal = []
    if (paymentFilterList !== undefined) {
      let list = []
      paymentFilterList && paymentFilterList.forEach((paymentDoc) => {
        list.push(paymentDoc.paymentDetails.filter((details) => details.invoiceNumber === invoiceNumber)[0])
      })

      for (let index = 0; index < list.length; index++) {
        if (list[index]) {
          if (typeof (list[index]) === 'object') {

            list[index].hasOwnProperty('paymentDate') && dateFinal.push(list[index].paymentDate)
          }
        }
      }
      let reqDate = dateFinal.length && dateFinal.sort((a, b) => new Date(b) - new Date(a))[0]
      return reqDate === 0 ? "--" : reqDate
    }

    else {
      return "--"
    }

  }
  invoicedDiscount = (discountDetails, subTotal) => {
    let discount = 0
    for (let index = 0; index < discountDetails.length; index++) {
      discount = parseFloat(discount) + parseFloat(this.handleDiscount(discountDetails[index].type, discountDetails[index].value, subTotal))
    }
    return parseFloat(discount)
  }
  paymentAfterDiscount = (subTotal, discountDetails) => {
    let totalPay = 0;
    let discount = 0;
    for (let index = 0; index < discountDetails.length; index++) {
      discount = parseFloat(discount) + parseFloat(this.handleDiscount(discountDetails[index].type, discountDetails[index].value, subTotal))
    }
    return parseFloat(subTotal) - parseFloat(discount)
  }

  handleCheckBox = (e, rowData) => {
    let invoiceList = JSON.stringify(this.state.invoicesList)
    invoiceList = JSON.parse(invoiceList)
    let amount = this.state.amount
    let reqInvoice = invoiceList[rowData.index]
    if (e.target.checked) {
      reqInvoice.isChecked = !reqInvoice.isChecked
      reqInvoice.paymentBalance =
        (this.paymentAfterDiscount(reqInvoice.subTotal, reqInvoice.discountDetails) - parseFloat(this.openBalance(reqInvoice.invoiceNumber, this.props.paymentFilterList))).toFixed(2)
    } else reqInvoice['isChecked'] = !reqInvoice.isChecked

    invoiceList[rowData.index] = reqInvoice
    console.log(reqInvoice)
    this.props.paymentHandleChange("tableData", invoiceList)
    this.setState({
      invoicesList: invoiceList,
      amount: amount,
    })
  }

  handleChange = (key, value) => {
    if (key === 'invoicesList' || key === "amount" | key === "paymentDiscountAmount") {
      this.setState({
        [key]: value,
      })
    } else {
      this.setState((prevState) => ({
        [key]: prevState[key].concat(value),
      }))
    }
  }

  render() {
    return (
      <div>
        <Presentation
          isPayment={this.props.isPayment}
          {...this.state}
          paymentFilterList={this.props.paymentFilterList}
          handleChange={this.handleChange}
          handleCheckBox={this.handleCheckBox}
          paymentHandleChange={this.props.paymentHandleChange}
          openBalance={this.openBalance}
          latestPayment={this.latestPayment}
          paymentAfterDiscount={this.paymentAfterDiscount}
          invoicedDiscount={this.invoicedDiscount}
        />
      </div>
    )
  }
}

export default Container
