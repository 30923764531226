import React, { Component } from 'react';
import Presentation from './Presentation';
import firebase from '../../../../../firebase_config'
import { DataContext } from '../../../../../contexts/data'

export default class Container extends Component {

    state = {
        taskLabels: [],
    }

    static contextType = DataContext

    attachLabelsToTask = () => {
        const [state] = this.context
        firebase.firestore().collection('Projects').doc(state.project.id).collection('Tasks').doc(state.task.id).set({
            labels: this.state.taskLabels.map(item => item.id)
        }, { merge: true })
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
        this.attachLabelsToTask()
    }
    render() {
        if (true)
            return <Presentation {...this.props} handleChange={this.handleChange} {...this.state} />
        return <div>Loading Labels...</div>
    }
}