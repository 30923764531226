import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../contexts/data'
import { SET_WIKI_HISTORY, SET_WIKI_CATEGORY } from '../../../contexts/types'
import { CircularProgress } from '@material-ui/core'
import Axios from 'axios'

class Container extends Component {
  state = {
    data: [],
    isVisible: false,
  }

  static contextType = DataContext

  sortHistory = () => {
    const [state] = this.context
    state.wikiHistory.forEach((ele) => {
      if (this.props.id === ele.id) {
        this.setState({
          data: ele,
          isVisible: true,
        })
      }
    })
    // this.setState({data: History[1]})
  }

  componentDidMount = async () => {
    const [state, dispatch] = this.context
    if (
      !state.hasOwnProperty('wikiHistory') ||
      !state.hasOwnProperty('wikiCategories')
    ) {
      let result = await Axios.get('/getWikiPagesInHistory')
      dispatch({
        type: SET_WIKI_HISTORY,
        payload: result.data.response,
      })
      this.sortHistory()
      let res = await Axios.get('/wiki/getCategories')
      dispatch({
        type: SET_WIKI_CATEGORY,
        wikiAddCategoryCallBack: this.wikiAddCategoryCallBack,
        payload: res.data.response,
      })
    } else {
      this.sortHistory()
    }
  }

  handleUpdate = (props) => {
    console.log(props)
  }

  render() {
    const [state] = this.context
    if (this.state.isVisible && state.hasOwnProperty('wikiCategories')) {
      return <Presentation {...this.state} />
    } else {
      return (
        <div style={{ marginTop: '15%', alignContent: 'center' }}>
          <div className='text-center'>
            <CircularProgress color='primary' />
          </div>
        </div>
      )
    }
  }
}

export default Container
