import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../contexts/data'
import { SIGN_OUT, CHECK_AUTH, SET_COMPANY_CONFIG, SET_MODULES } from '../../contexts/types'
import firebase from '../../firebase_config'
import { configuration } from '../../configuration'
import Axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import ChangeTempPasswordPage from '../../pages/ChangeTempPasswordPage'

class Container extends Component {

    static contextType = DataContext

    onSignOut = () => {
        const dispatch = this.context[1]
        dispatch({
            type:SIGN_OUT,
            payload:null
        })
    }

    componentDidMount = () => {
        const dispatch = this.context[1]
        firebase.auth().onAuthStateChanged(user => {
            if(user) {
                console.log(user)
                localStorage.setItem("ownerUID",user.uid)
                localStorage.setItem('email', user.uid)
                user.getIdTokenResult().then(tokenResult => {
                    localStorage.setItem("role",tokenResult.claims.role)
                    console.log(tokenResult.token)
                    Axios.defaults.headers.common['Authorization'] = user.uid+' '+tokenResult.token
                    Axios.defaults.baseURL=configuration.REST_api
                    this.getMyModules(user.uid)
                    // to set authentication
                    dispatch({
                        type:CHECK_AUTH,
                        payload:{
                            isLoggedIn:true,
                            employee:{
                                email:user.uid,
                                role:tokenResult.claims.role,
                                companyID : user.displayName
                            }
                        }
                    })
                })
                
            }
            else {
                dispatch({
                    type:CHECK_AUTH,
                    payload:false
                })
            }
        })

        firebase.firestore().collection("CompanyCustomization").doc("companydetails").onSnapshot(snap => {
            dispatch({
                type : SET_COMPANY_CONFIG,
                payload : snap.data()
            })
        })

    }


    getMyModules = async(email) => {
        const dispatch = this.context[1]
        const response = await Axios.get(`/mymodules/${email}`)
        const result = response.data
        dispatch({
            type : SET_MODULES,
            payload : result.response
        })
    }

    render() {
        const [state] = this.context

        const loaderStyles = {
            marginTop : '350px'
        }
        return (
            <div>
                {
                    state.hasOwnProperty('access_modules') && Array.isArray(state.access_modules) ? 
                        state.access_modules.includes('password-changed') ?
                            <div style={passwordStyles} >
                                <ChangeTempPasswordPage />
                            </div>
                            : 
                            <Presentation
                                onSignOut={this.onSignOut}
                            />
                        :
                        <div className='text-center' style={loaderStyles} >
                            <CircularProgress />
                        </div>
                }
            </div>
        )
    }


}

export default Container

const passwordStyles = {
    width : '400px',
    marginTop : '100px',
    marginLeft : 'auto',
    marginRight : 'auto'
}
