import React from 'react'
import { TextField, Checkbox, FormControlLabel, MenuItem } from "@material-ui/core"
import SunEditor from 'suneditor-react';

function Presentation(props) {
    const { handleChange, notesDetails, invoiceSettings } = props
    console.log(invoiceSettings)
    return (
        <div className="row justify-content-around" >
            <TextField
                label="Notes"
                multiline
                rows={4}
                className="m-2 col-sm-5"
                variant="outlined"
                value={notesDetails['notes']}
                onChange={(e) => {
                    notesDetails['notes'] = e.target.value;
                    handleChange("notesDetails", notesDetails);
                }}
            />
            <TextField
                label="Statement Memo"
                multiline
                rows={4}
                className="m-2 col-sm-5"
                variant="outlined"
                value={notesDetails['statementsMemo']}
                onChange={(e) => {
                    notesDetails['statementsMemo'] = e.target.value;
                    handleChange("notesDetails", notesDetails);
                }}
            />
            <FormControlLabel
                control={<Checkbox />}
                className="m-2 col-sm-5"
                value={notesDetails['includeInvoicePDF']}
                onChange={(e) => {
                    notesDetails['includeInvoicePDF'] = e.target.checked;
                    handleChange("notesDetails", notesDetails);
                }}
                label="Include notes in invoice PDF"
            />
            <TextField
                select
                label="Invoice category"
                className="m-2 col-sm-5"
                value={notesDetails['invoiceCategory']}
                onChange={(e) => {
                    notesDetails['invoiceCategory'] = e.target.value;
                    handleChange("notesDetails", notesDetails);
                }}
                variant="outlined"
            >
                <MenuItem value="" >None</MenuItem>
            </TextField>
            <TextField
                hidden={!invoiceSettings.attachFlairTimesheets}
                label="Attachment"
                type="file"
                className="m-2 col-sm-5"
                InputLabelProps={{
                    shrink: true,
                  }}
                rows={4}
                value={notesDetails['attachment']}
                onChange={(e) => {
                    notesDetails['attachment'] = e.target.value;
                    handleChange("notesDetails", notesDetails);
                }}
                variant="outlined"
            />
            <TextField
                label="Payable to"
                multiline
                className="m-2 col-sm-5"
                value={notesDetails['payableTo']}
                onChange={(e) => {
                    notesDetails['payableTo'] = e.target.value;
                    handleChange("notesDetails", notesDetails);
                }}
                rows={4}
                variant="outlined"
            />
            <div className="m-2 col-12" >
                <h3><u>Additional Info</u></h3>
                <SunEditor 
                    placeholder="Type the info here"
                    value={notesDetails['additionalInfo']}
                    onChange={(data) => {
                        notesDetails['additionalInfo'] = data
                        handleChange("notesDetails", notesDetails);
                    }}
                    setOptions={{
                        "buttonList": [
                            [
                                "undo",
                                "redo",
                                "font",
                                "fontSize",
                                "formatBlock",
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                                "fontColor",
                                "hiliteColor",
                                "removeFormat",
                                "outdent",
                                "indent",
                                "align",
                                "horizontalRule",
                                "list",
                                "lineHeight",
                                "table",
                                "link",
                                "image",
                                "video",
                                "showBlocks",
                                "codeView"
                            ]
                        ],
                        mode:'Balloon-always'

                    }}
                />
            </div>
            

        </div>
    )
}

export default Presentation
