import React from 'react'
import Deductions from '../components/TimeSheets/Deductions'

function DeductionsListPage() {
  return (
    <div>
      <Deductions listAll={true} id={''} />
    </div>
  )
}

export default DeductionsListPage
