/* eslint-disable no-use-before-define */
import React, { useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from '../../../contexts/data'
import validation from '../../generalcomponents/validation'
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles(theme => ({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  root: {
    width: 400,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}
));

export default function Presentation(props) {
  const classes = useStyles();
  const [state] = useContext(DataContext)
  const { handleEmployees, inProject, projectMembers } = props
  let activeEmployees = []
  if (state.hasOwnProperty("activeEmployees"))
    activeEmployees = state.activeEmployees.map(employee => {
      return {
        email: employee.useremail,
        isSupervisor: employee.personal.isSupervisor ? "(Supervisor)" : ""
      }
    })
  else
    activeEmployees = []

  if (inProject) {
    activeEmployees = activeEmployees.filter(employee => !projectMembers.includes(employee.email))
  }


  const validate = new validation()
  return (
    <div className={classes.root} >
      <Autocomplete
        multiple
        options={activeEmployees}
        renderOption={(option) => {
          return <span>{validate.emailToName(option.email)} {' '} {validate.checkSupervisor(option.email) ? <Chip style={{ backgroundColor: '#f78a14', fontWeight: 'bold', color: '#fff' }} size="small" label="SUPERVISOR" /> : null}</span>
        }}
        getOptionLabel={option => validate.emailToName(option.email)}
        filterSelectedOptions
       // onChange={(e, v) => handleEmployees(v.map(item => item.email))}
        renderInput={params => (
          <TextField
            fullWidth
            {...params}
            label="Add employees"
          />
        )}
      />
    
      <br />
    </div>

  );
}
