import React from 'react'
import TimesheetTable from "../../../EmployeeTimesheet/Table/Index"
import { Button,IconButton, Drawer } from "@material-ui/core"
import { Edit } from "@material-ui/icons"

function Presentation(props) {
    const { startDate, endDate, standardTimeArr, OTArr, cycle, handleTime, handleSave } = props
    const [drawerState, setState] = React.useState(false);
    
      const toggleDrawer = (event, open) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState(open);
      };
    return (
        <div>
            <IconButton onClick={(e) => toggleDrawer(e, true)}><Edit/> </IconButton>
            <Drawer anchor={"bottom"} open={drawerState} onClose={(e) => toggleDrawer(e, false)}>
                <div className="mb-4" >
                {
                    standardTimeArr.length ?
                    <>
                    <TimesheetTable 
                        {...props}
                        handleTime={handleTime}
                    />
                    <div className="text-center mt-1" >
                        <Button onClick={(e) => {
                            handleSave()
                            toggleDrawer(e, false)
                        }} variant="contained" color="secondary" >Save changes</Button>
                    </div>
                    </>
                        :
                        <h2 className="text-center" >Loading...</h2>
                }
                </div>
            </Drawer>
        </div>
    )
}

export default Presentation
