import React, { useContext } from 'react'
import { TextField, MenuItem, Button, Grid, makeStyles, Chip } from '@material-ui/core'
import SweetAlert from "react-bootstrap-sweetalert";
import { GoFileSymlinkFile } from 'react-icons/go'
import PublishIcon from '@material-ui/icons/Publish';
import Icon from '@material-ui/core/Icon';
import RangePicker from '../RangePicker'
import TimesheetTable from '../Table/Index'
import TimesheetImage from '../../../../assets/Online-Timesheet.png'
import { DataContext } from '../../../../contexts/data'
import Validations from '../../../generalcomponents/validation';

function Presentation(props) {
    const { sheetDataIntoArr, placements, handleChange, placementID, timesheetId, handleSubmit, startDate, endDate, standardTimeArr, OTArr, handleChange2, timesheetDocURL, isUploading, attachMandatory, startDay, cycle, placementStartDate, handleTime, handleTableChange, placementParam, timesheetParam, handleUpdate, projectEndDate, cycleDates } = props
    const [value, onChange] = React.useState([new Date(), new Date()]);
    const [alert,setAlert] = React.useState(null);
    const [state] = useContext(DataContext)
    const hideAlert = () => {
        setAlert(null);
    }
    const validate = new Validations()

    const useStyles = makeStyles(theme => ({
        
        timesheetImageStyle :{
            backgroundImage: `url(${TimesheetImage})`,
            backgroundSize : 'cover',
            backgroundRepeat: 'no-repeat'
        }
    }))
    const classes = useStyles()

    const successAlert = (type) => {
        setAlert(
        <SweetAlert
            warning
            showCancel
            style={{ display: "block" }}
            title="Are you sure?"
            onConfirm={() =>{
                if(type === "submit")
                    handleSubmit()
                else handleUpdate()    
                hideAlert()
            }}
            onCancel={() => hideAlert()}  
            confirmBtnText={"Submit"} 
            cancelBtnText={"Cancel"}
        >
            You want to {type} the timesheet
        </SweetAlert>
        );
    }

    let timesheetInfo = {}
    if(timesheetId !== "timesheet")
        timesheetInfo = state.timesheets_list.filter(timesheet => timesheet.id === timesheetId)[0]
   
    return (
        <div className="custom-card" style={{backgroundColor: '#f0f7fd'}} >
            <div className="row" >
                <div className="col-sm" >
                    <div className="mt-2 mb-2"  >
                        <TextField
                            select
                            id="emp-timesheet-index-lettertype"
                            label="Select placement"
                            required
                            variant="outlined"
                            size="small"
                            name="placementID"
                            value={placementID}
                            style={{ width: '40%' }}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                        >
                            <MenuItem value="" >None</MenuItem>
                            {
                                placements.map(item => <MenuItem value={item.id}>{item.placementID} </MenuItem>)
                            }
                        </TextField>
                    </div>
                    <div className="mt-2 mb-2">
                        <br/>
                        <input
                            className='d-none'
                            id="contained-button-file-ehfile"
                            multiple
                            type="file"
                            onChange={handleChange2}
                        />
                        {
                            attachMandatory ?
                                <div>
                                    <label htmlFor="contained-button-file-ehfile">
                                        <Button variant="contained" color="primary" component="span">
                                            Upload Document
                                        </Button>
                                    </label>
                                    <p>{timesheetDocURL !== "" ? <a target="_blank" rel="noopener noreferrer" href={timesheetDocURL} ><GoFileSymlinkFile size={22} /></a> : <p>No file choosen</p>} </p>
                                    {
                                    isUploading ? <p>Uploading please wait...</p> : ""
                                    }
                                </div> 
                                :
                                null
                        }
                        
                    </div>
                    <div  className="mt-2 mb-2" >
                    {
                        placementParam === "new" && timesheetParam === "timesheet" ? 
                            null
                            :
                            <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Status</td>
                                            <td>: { timesheetInfo.isApproved ? <Chip label="Approved" className="bg-success text-light" /> : ( timesheetInfo.isRejected ? <Chip label="Rejected" className="bg-danger text-light" /> : <Chip label="Under Review" /> ) }</td>
                                        </tr>
                                        <tr>
                                            <td>Approved by</td>
                                            <td>: { timesheetInfo.isApproved ? (validate.emailToName(timesheetInfo.approvalDetails.approvedBy)): '---' }</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                    }
                    </div>
                </div>
                <div className="col-sm" >
                    <RangePicker 
                        handleChange={handleChange}
                        cycle={cycle}
                        startDay={startDay} 
                        placementStartDate={placementStartDate}
                        handleTableChange={handleTableChange}
                        projectEndDate={projectEndDate}
                        cycleDates={cycleDates}
                    />
                </div>
                <div className="col-sm w-100 h-100" >
                    <img src={TimesheetImage} className="img-fluid" /> 
                </div>
            </div>
            <div className="mt-2" >
                {
                    standardTimeArr.length ? 
                        <TimesheetTable 
                            cycle={cycle}
                            startDay={startDay} 
                            standardTimeArr = {standardTimeArr}
                            OTArr = {OTArr}
                            handleTime={handleTime}
                            placementStartDate={placementStartDate}
                            projectEndDate={projectEndDate}
                        />
                :
                    null
                }
            </div> 
            <br/>
            
            <div className="text-center" >
                {
                    placementParam === "new" && timesheetParam === "timesheet" ? 
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => successAlert("submit")}
                            disabled={[attachMandatory ? timesheetDocURL : 'empty', placementID].includes("")}
                            endIcon={<Icon>send</Icon>}
                        >
                            Submit
                        </Button>
                        :
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            hidden={timesheetInfo.empCode !== state.employee.companyID || timesheetInfo.isApproved} 
                            onClick={() => successAlert("update")}
                            endIcon={<PublishIcon />}
                        >
                            Update
                        </Button>
                }
                
            </div>
            {alert}
        </div>
    )
}

export default Presentation

