import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

function Presentation(props) {
    const { projectEndDate, handleChange, onUpdate, placementID, invoiceSettings } = props
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Other Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {
                        placementID !== "" ? 
                            <>
                                <div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            required
                                            fullWidth
                                            margin="normal"
                                            label="Project End Date"
                                            value={projectEndDate === "" ? null : projectEndDate}
                                            onChange={(date) => {
                                                if (!isNaN(Date.parse(date))) handleChange("projectEndDate", date)
                                            }}
                                            format="MM/dd/yyyy"
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <br />
                                <div>
                                       
                                    <span>
                                        {
                                               typeof(invoiceSettings) === "object" && invoiceSettings.OT ? 
                                                    <CheckIcon className="text-success" /> 
                                                :
                                                    <CloseIcon className="text-danger" />
                                        }
                                   &nbsp;&nbsp; Invoice OT </span>
                                </div>
                                <div className="text-center" >
                                    <Button 
                                        size="small"
                                        variant="contained"
                                        color="secondary"
                                        onClick={onUpdate}
                                    >
                                        Update
                                    </Button>
                                </div>
                            </>
                            :
                            null
                    }
                    
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

export default Presentation
