import React from 'react'
import Adminpanel from '../components/Adminpanel'
function AdminpanelPage() {
    return (
        <div>
            <Adminpanel />
        </div>
    )
}

export default AdminpanelPage
