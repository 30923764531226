import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../contexts/data'
import CustomSpinner from '../../../generalcomponents/circularSpinner'
import { MANAGER_TIMESHEET_ACTIONS, SET_CUSTOM_KEY, EXPENSE_ACTIONS } from '../../../../contexts/types'
import firebase from '../../../../firebase_config'
import { Placement } from '../../../../contexts/shared/Timesheets/Placements'
export class Container extends Component {
    state = {
        placements : [],
        isLoading : true,
        description: '',
        expenseSettings: []
    }
    static contextType = DataContext

    componentDidMount = () => {
        // this.handleCheckExpenseApproval()
    }

    expenseActionsCallback = (type) => {
        console.log(type)
    }

    approveExpense = (expenseId) => {
        const dispatch = this.context[1]
        dispatch({
            type : EXPENSE_ACTIONS,
            payload : {
                expenseId,
                // placementID
            },
            expenseId: expenseId,
            actionType : 'approve',
            expenseActionsCallback: this.expenseActionsCallback
        })
    }


    rejectExpense = (expenseId) => {
        const dispatch = this.context[1]
        dispatch({
            type : EXPENSE_ACTIONS,
            payload : {
                expenseId,
                description: this.state.description,
                // placementID
            },
            expenseId: expenseId,
            actionType : 'reject',
            expenseActionsCallback: this.expenseActionsCallback
        })
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleCheckExpenseApproval = async() => {
        const [state] = this.context
        const list = state.expenses_list
        const placementIds = [...new Set(list.map(item => item.placementID))]
        const promises = []
        placementIds.forEach(async(placementID) => {
            const id = state.placements_list.filter(item => item.placementID === placementID)[0].id
            const placementObj = new Placement(id)
            const promise =  placementObj.getSettings("ExpenseDetails")
            promises.push(promise)
        })
    
        return Promise.all(promises).then((details) => {
            console.log(details)
            this.setState({
                expenseSettings: details.map((detail, index) => {
                    return{ 
                        approvalBy:  detail.companyIDs,
                        placementID: placementIds[index]
                    }
                })
            })
            return 
        })
        
    }

    render() {
        const [state] = this.context
        if(state.hasOwnProperty("expenses_list"))
            return (
                <div>
                    <Presentation 
                        {...this.state}
                        {...this.props}
                        employeeID={this.props.id}
                        handleChange={this.handleChange}
                        approveExpense = {this.approveExpense}
                        rejectExpense = {this.rejectExpense}
                    />
                </div>
            )
        return <CustomSpinner />
    }
}

export default Container
