import React, { useContext, useState, useEffect } from "react";
import { TextField, MenuItem, Chip, TextareaAutosize, Paper } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DataContext } from "../../../../../contexts/data";
import Validations from "../../../../generalcomponents/validation";
import { CalcGrandTotal } from "../Discount/Presentation"

function Presentation(props) {
  const { handleChange, subTotal, discountDetails, info, invoiceId } = props;
  const [state] = useContext(DataContext);
  const validate = new Validations();
  const [clientId, setClientId] = useState(info.clientId);
  const [placements, setPlacements] = useState([]);
  const clients = state.client_list;
  // const placements = state.placements
  useEffect(() => {
    setPlacements(
      state.placements_list.filter(
        (placement) => placement.clientId === info.clientId && placement.fillableSections.length === 0
      )
    );
  }, [info.clientId, info.placementID]);
  console.log(subTotal)

  return (
    <div>
      <Paper className="row ml-auto mr-auto justify-content-between p-2">
        <div className="row col-9">
          <div className="col-lg m-1">
            <TextField
              select
              label="Create Invoice By"
              value={info.invoiceBy}
              fullWidth
              onChange={(e) => {
                if (e.target.value) {
                  return handleChange("invoiceBy", e.target.value)
                }
              }}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="client">By Client</MenuItem>
              <MenuItem value="external">By External</MenuItem>
            </TextField>
          </div>
          {info.invoiceBy === 'client' ?
            <>
              <div className="col-lg m-1">
                {
                  invoiceId === "newinvoice" ? 
                  <Autocomplete
                  options={clients}
                  value={info.clientId !== "" ? clients.filter(item => item.clientId === info.clientId)[0] : ""}
                  getOptionLabel={(option) => option.businessDisplayName}
                  style={{ width: 200 }}
                  onChange={(e, v) => {
                    if (v) {
                      handleChange("clientId", v.clientId);
                      return setClientId(v.clientId);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} label="Select Client" />
                  )}
                />
                :
                  <TextField
                      style={{ width: 200 }}
                      select
                      value={info.clientId}
                      title="Select Client"
                  >
                    {
                      clients.filter(item => item.clientId === info.clientId).map(option => {
                        return <MenuItem value={option.clientId} >{option.businessDisplayName} </MenuItem>
                      })
                    }
                  </TextField>
                }
                
              </div>
              <div className="col-lg m-1">
                {
                  invoiceId === "newinvoice" ? 
                  <Autocomplete
                    options={placements}
                    value={info.placementID !== "" ? placements.filter(item => item.placementID === info.placementID)[0] : ""}
                    renderOption={(option) => {
                      return (
                        <span>
                          {
                            state.names.filter(
                              (item) => item.companyID === option.empCode
                            )[0].name
                          }{" "}
                          <Chip
                            style={{
                              backgroundColor: "#f78a14",
                              fontWeight: "bold",
                              color: "#fff",
                            }}
                            size="small"
                            label={option.placementID}
                          />
                        </span>
                      );
                    }}
                    getOptionLabel={(option) =>
                      state.names.filter(
                        (item) => item.companyID === option.empCode
                      )[0].name
                    }
                    style={{ width: 200 }}
                    onChange={(e, v) => {
                      if (v) {
                        handleChange("placementID", v.placementID);
                        handleChange("empCode", v.empCode);
                        // return setClientId(v.empCode);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} label="Select Employee" />
                    )}
                  />
                  :
                  <TextField
                      style={{ width: 200 }}
                      select
                      value={info.placementID}
                      title="Select Employee"
                  >
                    {
                      placements.filter(item => item.placementID === info.placementID).map(option => {
                        return <MenuItem value={option.placementID} ><span>
                        {
                          state.names.filter(
                            (item) => item.companyID === option.empCode
                          )[0].name
                        }{" "}
                        <Chip
                          style={{
                            backgroundColor: "#f78a14",
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                          size="small"
                          label={option.placementID}
                        />
                      </span></MenuItem>
                      })
                    }
                  </TextField>
                }
                
              </div>
            </> : null
          }
          {info.invoiceBy === 'external' ?
            <>
              <div className="col-lg m-1">
                <TextField
                  label="Name"
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={(e) => handleChange('external_name', e.target.value)}
                />
              </div>
              <div className="col-lg m-1">
                <TextareaAutosize
                  placeholder="Address"
                  style={{ width: '100%' }}
                  rowsMin={5}
                  onChange={(e) => handleChange('external_address', e.target.value)}
                />
              </div>
            </> : null
          }
        </div>
        <div className="col-3">
          <h5 className="text-right" style={{ fontWeight: 500 }}>
            Invoice Amount
          </h5>
          <h1 className="text-right" style={{ fontWeight: 700 }}>
            ${isNaN(CalcGrandTotal(discountDetails, subTotal)) ? "0.00" : CalcGrandTotal(discountDetails, subTotal)}
          </h1>
        </div>
      </Paper>
    </div>
  );
}

export default Presentation;
