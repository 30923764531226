import React, { useContext } from 'react'
import OrgChart from '../components/Employee/OrgChart'
import { DataContext } from '../contexts/data'
const OrgChartPage = (props) => {
    const [state] = useContext(DataContext)
    return (
        <div>
            {
                state.hasOwnProperty('employees_collection') ? 
                    <OrgChart 
                        id={props.match.params.id}
                    />
                    :
                    <p className="text-center" >Loading OrgChart ...</p>
                        
            }
        </div>
    )
}

export default OrgChartPage