import React, { useContext } from 'react'
import TimesheetsList from '../components/TimeSheets/TimesheetsList'
import { DataContext } from "../contexts/data"
import CustomSpinner from "../components/generalcomponents/circularSpinner"

function TimesheetsListPage() {
    const [state] = useContext(DataContext)
    return (
        <div>
            {
                state.hasOwnProperty("client_list") &&
                state.hasOwnProperty("placements_list") &&
                state.hasOwnProperty("timesheets_list") 
                ?
                    <TimesheetsList listAll={true} />
                :
                    <CustomSpinner/>
            }
        </div>
    )
}

export default TimesheetsListPage
