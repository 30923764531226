import React, { Component } from 'react'
import Presentation from './Presentation'
import Prompt from '../../generalcomponents/snackBars'
import { render } from 'react-dom'
import { RESET_PASSWORD } from '../../../contexts/types'
import { DataContext } from '../../../contexts/data'
class Container extends Component {
    state={
        email:"",
        pass:"",
        signing:false,
        loading:false,
        isPasswordShown: false
      }

      static contextType = DataContext
      togglePasswordVisibility=()=>{
        this.setState({ isPasswordShown: !this.state.isPasswordShown });
      }

          handleChange = (e) => {
            console.log(e.target.value)
          this.setState({
              email:e.target.value
          })
        console.log(this.state.email)
        }

      handleResetPass=(e)=>{
        const dispatch = this.context[1]
        const expression = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const email = this.state.email
        let emailCheck = expression.test(email) 
        if(!emailCheck)
          return  render (
                        <Prompt open={true} content="Invalid email format" severity="error" />,
                        document.getElementById('notifications-box')
                    )
        dispatch({
          type : RESET_PASSWORD,
          payload : email
        })  

      }
       
  
    render() {
        return (
            <div>
                <Presentation
                 handleResetPass={this.handleResetPass}
                handleChange={this.handleChange}
                login={this.login}
                signing={this.state.signing}
                showPassword={this.togglePasswordVisibility}
                isPasswordShown={this.state.isPasswordShown}
                {...this.state}
                />
            </div>
        )
    }
}

export default Container