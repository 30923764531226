import React from 'react'
import History from '../components/History'
function HistoryPage() {
    return (
        <div>
            <History />
        </div>
    )
}

export default HistoryPage
