import React, { Component } from 'react'
import Presentation from './Presentation'
import { DataContext } from '../../../../../../../contexts/data'
import CustomSpinner from '../../../../../../generalcomponents/circularSpinner'
import firebase from '../../../../../../../firebase_config'
import { CLIENT_LOCATION_ACTIONS } from '../../../../../../../contexts/types'
export class Container extends Component {
    static contextType = DataContext
    state = {
        isLoading : true,
        locations: []
    }
    componentDidMount = () => {
        firebase.firestore().collection("ClientDetails").doc(this.props.client.id).collection("Locations").where("isExist", "==", true).onSnapshot(snap => {
            this.setState({
                locations: snap.docs.map(doc => doc.data()),
                isLoading: false
            })
        })
    }

    onDeleteLocation = (id) => {
        const dispatch = this.context[1]
        dispatch({
            type: CLIENT_LOCATION_ACTIONS,
            payload: {
                locationId: id,
                clientId: this.props.client.id
            },
            actionType: 'delete'
        })
    }
    render() {
        return (
            <div>
                <Presentation 
                    {...this.props}
                    {...this.state}
                    onDeleteLocation={this.onDeleteLocation}
                />
            </div>
        )
    }
}

export default Container
