import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FolderIcon from '@material-ui/icons/Folder';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import EmployeeProfile from '../EmployeeProfile'
import Placements from '../../TimeSheets/Placements/Index'
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { DataContext } from '../../../contexts/data'
import { GiTakeMyMoney } from 'react-icons/gi'
import Invoices from "../../TimeSheets/Invoices/InvoicesSort"
import TimesheetsList from '../../TimeSheets/TimesheetsList'
import ExpensesList from "../../TimeSheets/Expenses/ExpensesList"
import { GiPayMoney } from "react-icons/gi"
import Deductions from '../../TimeSheets/Deductions';
import PayrollList from '../../Payrolls/PayrollList'
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}



export default function Presentation(props) {
    const { value, handleChange, id } = props
    const [state] = React.useContext(DataContext)

    const consolePrivilege = state.access_modules.includes('console-customization')
    const timesheetPrivilege = state.access_modules.includes('timesheets')
    const timesheetManagerPrivilege = state.access_modules.includes('timesheets-manager')
    const accountsPrivilege = state.access_modules.includes('accounts') || state.access_modules.includes('accounts-manager')
    console.log("consolePrivilege", consolePrivilege, "timesheetPrivilege", timesheetPrivilege, "timesheetManagerPrivilege", timesheetManagerPrivilege, "accountsPrivilege", accountsPrivilege)
        return (
            <div>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="icon label tabs example"
                >
                    <Tab icon={<FavoriteIcon />} label="Dashboard" />
                    <Tab icon={<PersonPinIcon />} label="General" />
                    <Tab icon={<BusinessCenterIcon />} hidden={!(timesheetManagerPrivilege || consolePrivilege)} label="Placements" />
                    <Tab icon={<AccessAlarmIcon />} hidden={!(timesheetPrivilege || consolePrivilege)} label="Timesheets" />
                    <Tab icon={<GiPayMoney size={30} />} hidden={!(timesheetPrivilege || consolePrivilege)} label="Expenses" />
                    <Tab icon={<ReceiptIcon />} hidden={!(timesheetManagerPrivilege || consolePrivilege)} label="Invoices" />
                    <Tab icon={<FolderIcon />}  label="Documents" />
                    <Tab icon={<GiTakeMyMoney size={30} />} hidden={!(accountsPrivilege || consolePrivilege)} label="Deductions" />
                    <Tab icon={<AttachMoneyIcon />} hidden={!(accountsPrivilege || consolePrivilege)} label="Payroll" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <EmployeeProfile id={id} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <EmployeeProfile id={id} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Placements id={id}/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <TimesheetsList listAll={false} id={id}  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <ExpensesList listAll={false} id={id}  />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <Invoices id={id} listAll={false}/>
                </TabPanel>
                <TabPanel value={value} index={6}>
                    
                </TabPanel>
                <TabPanel value={value} index={7}>
                <Deductions listAll={false} id={id}/>
                </TabPanel>
                <TabPanel value={value} index={8}>
                    <PayrollList id={id} listAll={false} />
                </TabPanel>
            </div>
        );
}