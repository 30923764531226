import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import PlacementsList from '../../PlacementsList'

export default function Presentation(props) {
    const { id, companyID } = props

    return (
        <div>
            <div className="text-right m-2">
                <Link to={'/console/newplacement/' + id}>
                    <Button
                        variant="contained"
                        color="primary"
                    >
                        Add New Placement
                    </Button>
                </Link>
            </div>
                <PlacementsList listAll={false} companyID={companyID} />
        </div>
    );
}
