import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ForgotPassword from '../AuthComponents/ForgotPassword'
import { DataContext } from '../../contexts/data'
import { configuration } from '../../configuration'
import CopyrightIcon from '@material-ui/icons/Copyright';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
        flairtechno.com
       {' '} 
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    // backgroundImage: `url(${configuration.flair_logo})`,
    // backgroundRepeat: 'no-repeat',
    background: 'linear-gradient(45deg, #280071 10%, #c42053 90%)',
    // backgroundSize: 'cover',   
    // backgroundPosition: 'center',
    // backgroundSize:'400px'
  },
  logoGradient:{
    width: "100%",
    marginTop: '25%',
    color: '#fff'
  },
  logoCopyright:{
    color: '#fff',
    fontSize: '14px',
    textAlign: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo:{
    // backgroundImage: `url(${configuration.logo})`,
    height:'140px',
    marginRight:'50px',
    marginBottom:'50px'
    
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: '#fff',
    background: '#280071', //10%, #c42053 90%)',
    '&:hover': {
      background: '#c42053',
    },
  },
}));



export default function Presentation(props) {

  const classes = useStyles();
  const { handleChange, onLogin, showOverlay } = props
  const [state] = React.useContext(DataContext)
  const LoadOverlay = () => {
    return(
      <div className="loading_overlay">
          <span className="overlay_comp text-center"><img src={configuration.flair_logo} height="100" /><br/></span>
      </div>
    )
  }
  if(!showOverlay)
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} >
          <img src={configuration.logo} alt="Company Logo" className={classes.logoGradient} />
          <p className={classes.logoCopyright}><CopyrightIcon /> 2019 FLAIR - All rights reserved</p>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}>
        <div className={classes.paper}>
       <div >
        <img src={configuration.logo}  alt="Company Logo" className="img-fluid"/>
       </div>
          <form className={classes.form} onSubmit={onLogin} >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={handleChange}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit} >
              Sign In
            </Button>
            <ForgotPassword />
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );

  return <LoadOverlay />
}

