import React from 'react';
import ClientForm from "../components/TimeSheets/Clients/ClientForm/Index";

const ClientFormPage = (props) => {
    return (
        <div>
            <ClientForm
                create={true}
            />
        </div>
    );
}

export default ClientFormPage;