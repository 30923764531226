import React from 'react'
import Template from '../components/InvitationTemplate/PostForm'
function EmployeeRegisterPage(props) {
    return (
        <div>
            <Template 
                email={props.match.params.email}
                token={props.match.params.token}
            />
        </div>
    )
}

export default EmployeeRegisterPage
