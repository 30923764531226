import React, { useContext } from 'react'
import { Typography, Box, Tab, Tabs, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { DataContext } from '../../../../contexts/data'
import AllTemplates from '../AllTemplates'
import CreateTemplate from '../CreateTemplate'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'visible',
  },
  grid: {
    padding: theme.spacing(10),
  },
}))

function Presentation(props) {
  const { emails, handleChange, value } = props
  const [state] = useContext(DataContext)
  const isUpdating =
    state.hasOwnProperty('email_content') && state.email_content.updating
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {isUpdating ? null : (
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={value}
          onChange={handleChange}
          aria-label='Vertical tabs example'
          className={classes.tabs}
        >
          <Tab id='email_template_panel_0' label='Templates' />
          <Tab id='email_template_panel_1' label='Create Template' />
        </Tabs>
      )}
      <TabPanel value={value} index={0}>
        <AllTemplates emails={emails} status={true} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CreateTemplate emails={emails} />
      </TabPanel>
    </div>
  )
}

export default Presentation
