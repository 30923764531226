import React, { PureComponent } from 'react';
import Presentation from './Presentation';
import firebase from '../../../../firebase_config'
import { DataContext } from '../../../../contexts/data'
import { OFFICER_REJECT_LETTER } from '../../../../contexts/types'
import Triggers from '../../../../firebase_triggers'

class Container extends PureComponent {
    state = {
        requests : [],
        letters : [],
        signatures : []
    }

    static contextType = DataContext

    handleReject = (id, reqId) => {
        if(!window.confirm(`Are you sure you want to reject the request:${reqId}?`))
            return null
        const dispatch = this.context[1]
        dispatch({
            type : OFFICER_REJECT_LETTER,
            payload : {
                offerLetterId : id
            }
        })
    }

    render() {
        const [state] = this.context
        console.log(state)
        return (
        state.hasOwnProperty('requestedLetters') ?

            <Presentation
                {...this.state}
                myReq = {this.props.myReq}
                handleClick={this.handleClick}
                handleClose={this.handleClose}
                handleReject={this.handleReject}
            />
            :
            <p>Loading requests</p>
        );
    }
}

export default Container;