import React, { Component } from 'react'
import Presentation from './Presentation'
import {storage} from '../../../firebase_config'
import { DataContext } from '../../../contexts/data'
import { UPDATE_PROFILE } from '../../../contexts/types'
import Validations from '../../generalcomponents/validation'
class Container extends Component {

    constructor(props){
        super(props)
        this.validate = new Validations()
    }

    state = {
        educationHistoryData : [],
        educationlevel:"",
        schoolname : "",
        field:"",
        gpaPer:"",
        startdate:"",
        enddate:"",
        address:"",
        document:"",
        editSection:[],
        isUploading:false
    }

    static contextType = DataContext

    clearValues=()=>{
        this.setState({
            educationlevel:"",
            schoolname : "",
            field:"",
            gpaPer:"",
            startdate:"",
            enddate:"",
            address:"",
            document:""
        })
    }

    componentDidMount=()=>{
        let { educationHistoryData } = this.props
        console.log(educationHistoryData)
        if(educationHistoryData)
        for(let i=0;i<educationHistoryData.length;i++){
            if(educationHistoryData.length === 0)
                continue
            educationHistoryData[i]['id']=i+1
        }
        this.setState({
            educationHistoryData
        })
        this.props.handleUpdateProfile("educationhistory",educationHistoryData)
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.educationHistoryData !== this.props.educationHistoryData){
            this.setState({
                educationHistoryData:nextProps.educationHistoryData
            })
            this.props.handleUpdateProfile("educationhistory",nextProps.educationHistoryData)
        }
    }

    handleChange = (e) => {
        if(e.target.name === "gpaPer")
            this.setState({
                [e.target.name]:e.target.value <= 100 ? e.target.value : ''
            })
        else    
            this.setState({
                [e.target.name]:e.target.value
            })
        console.log(e.target.value)
    }

    handleAdd = (e) =>{
        e.preventDefault()
        const dispatch = this.context[1]
        let data = this.state.educationHistoryData
        const appendData={
            educationlevel:this.state.educationlevel,
            schoolname:this.state.schoolname,
            field:this.state.field,
            gpaPer:this.state.gpaPer,
            startdate:this.state.startdate,
            enddate:this.state.enddate,
            address:this.state.address,
            document:this.state.document,
            id:data.length+1
        }
        data = [...data,appendData]
        let finalData = {}
        finalData['educationhistory'] = data
        dispatch({
            type : UPDATE_PROFILE,
            payload : {
                email:this.props.email,
                updateValue : finalData
            }
        })
        // this.props.handleUpdateProfile("educationhistory",data)
        this.setState({
            educationHistoryData:data,
            educationlevel:"",
            schoolname : "",
            field:"",
            gpaPer:"",
            startdate:"",
            enddate:"",
            address:"",
            document:""
        })
        
    }

    handleDelete = (index) =>{
        const dispatch = this.context[1]
        let arr = this.state.educationHistoryData
        arr.splice(index,1)
        let finalData = {}
        finalData['educationhistory'] = arr
        dispatch({
            type : UPDATE_PROFILE,
            payload : {
                email:this.props.email,
                updateValue : finalData
            }
        })
        // this.props.handleUpdateProfile("educationhistory",arr)
        this.setState({educationHistoryData:arr})
    }

    handleEdit = (item) =>{
        console.log(item)
        this.setState({editSection:item})
        this.setState({
            educationlevel:item.educationlevel,
            schoolname:item.schoolname,
            field:item.field,
            gpaPer:item.gpaPer,
            startdate:item.startdate,
            enddate:item.enddate,
            address:item.address,
            document:item.document,
        })
    }

    handleExisting = (index,item) =>{
        const dispatch = this.context[1]
        let arr = this.state.educationHistoryData
        let changedItem={
            educationlevel:this.state.educationlevel ,
            schoolname:this.state.schoolname,
            field:this.state.field,
            gpaPer:this.state.gpaPer,
            startdate:this.state.startdate,
            enddate:this.state.enddate,
            address:this.state.address,
            document:this.state.document,
        }
        arr[index] = changedItem    
        let finalData = {}
        finalData['educationhistory'] = arr
        dispatch({
            type : UPDATE_PROFILE,
            payload : {
                email:this.props.email,
                updateValue : finalData
            }
        })
        // this.props.handleUpdateProfile("educationhistory",arr)
        this.setState({
            educationHistoryData:arr,
            educationlevel:"",
            schoolname : "",
            field:"",
            gpaPer:"",
            startdate:"",
            enddate:"",
            address:"",
            document:""
        })
    }


    handleDateChange = (key, value) => {
        if(!isNaN(Date.parse(value))) 
        this.setState({
            [key]:new Date(value).toISOString()
        })
    }
    
    handleChange2 = e => {
        console.log(e.target.files[0])
      if (e.target.files[0]) {
        const file = e.target.files[0];
        this.fileUpload1(file)
      }
    }

    fileUpload1 = (file) =>{
        const level = {
            'Bachelors' : 'B',
            'Masters' : 'M',
            'PHD' : 'PHD'
        }
        const employeeCompanyID = this.props.profile.companyID
        const fileName = [employeeCompanyID, '_EDUH_', level[this.state.educationlevel], '_', new Date().toISOString(), '.', file.name.split('.')[1]].join('')
        const filePath = `EmployeeInfo/${employeeCompanyID}/EducationHistory/${fileName}`
        this.setState({isUploading:true})
        return this.validate.uploadToStorage(file, filePath, fileName, 'file')
        .then(url => {
            this.setState({ document : url })
            this.setState({isUploading:false})
        })
        .catch(err => {
            this.setState({isUploading:false})
            console.log(err)
        })
    }
    render() {
        return (
            <div>
                <Presentation
                    {...this.state}
                    handleAdd={this.handleAdd}
                    handleChange={this.handleChange}
                    onOpenModal={this.onOpenModal}
                    onCloseModal={this.onCloseModal}
                    handleDelete={this.handleDelete}
                    handleEdit={this.handleEdit}
                    handleExisting={this.handleExisting}
                    isEditing={this.props.isEditing}
                    clearValues={this.clearValues}
                    handleChange2={this.handleChange2}
                    handleDateChange={this.handleDateChange}
                    upload={this.state.upload}
                />
            </div>
        )
    }
}

export default Container
