import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import General from '../General'
import ClientDetails from '../Client/Index'
import Payments from '../Payments'
import Invoices from '../Invoices'
import Documents from '../Documents'
import RecruitmentDetails from '../RecruitmentDetails'
import WorkLocation from '../WorkLocation'
//import ReportingManager from '../ReportingManager'
import TimeSheetDetails from '../TimeSheetDetails'
import ExpenseDetails from '../ExpenseDetails'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DoneIcon from '@material-ui/icons/Done'
import Button from '@material-ui/core/Button'
import { DataContext } from '../../../../../contexts/data'

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(4),
  },
}))

export default function Presentation(props) {
  const classes = useStyles()
  const [state] = useContext(DataContext)
  const [expanded, setExpanded] = useState(false)
  const { handleFinish, index, isEdit, id, docId, responseData, hideOT } = props

  let req_placement = {
    billableClient: '',
    clientId: '',
    createdAt: '',
    createdBy: '',
    description: '',
    draft: false,
    empCode: '',
    endDate: '',
    fillableSections: [
      'payments',
      'documents',
      'worklocation',
      'timesheets',
      'timesheets',
      'client-details',
      'invoices',
      'recruitment-details',
      'expense-details',
    ],
    id: '',
    isExist: false,
    placementID: '',
    projectEndDate: '',
    startDate: '',
    status: '',
    updatedAt: [],
    updatedBy: [],
    netTerms: '',
    jobTitle: '',
  }
  const profile = state.employees_collection.filter(
    (profile) => profile.useremail === id || profile.companyID === id
  )[0]
  let placement = {}
  if (isEdit) {
    placement = state.placements_list.filter((item) => item.id === docId)[0]
  } else {
    if (!docId) {
      placement = state.placements_list.filter(
        (item) => item.empCode === id && item.draft
      )[0]
    } else {
      placement = state.placements_list.filter((item) => item.id === docId)[0]
    }
  }
  req_placement = placement ? placement : req_placement
  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <div>
      <Grid container className={classes.table}>
        <Grid item xs={12} className='p-2'>
          <Typography variant='body1'>
            <h3 style={{ textDecoration: 'underline' }}>Note:</h3>Placement can
            be initiated with general tab and no other tab will be opened until
            general tab gets filled
          </Typography>
        </Grid>
        <Grid item xs={6} className='p-2'>
          <Grid item xs={12} className='mb-2'>
            <ExpansionPanel
              expanded={expanded === 'panel0'}
              onChange={handleChange('panel0')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {req_placement.id ? (
                  <Typography
                    variant='h5'
                    className='text-center'
                    style={{ color: 'green' }}
                  >
                    {' '}
                    <DoneIcon color='inherit' /> General
                  </Typography>
                ) : (
                  <Typography variant='h5' className='text-center'>
                    General
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <General req_placement={req_placement} profile={profile} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} className='mb-2'>
            <ExpansionPanel
              expanded={
                (req_placement.id ? true : false) && expanded === 'panel2'
              }
              onChange={handleChange('panel2')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {req_placement.hasOwnProperty('fillableSections') &&
                !req_placement.fillableSections.includes('documents') ? (
                  <Typography
                    variant='h5'
                    className='text-center'
                    style={{ color: 'green' }}
                  >
                    {' '}
                    <DoneIcon color='inherit' /> Documents
                  </Typography>
                ) : (
                  <Typography variant='h5' className='text-center'>
                    Documents
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ width: '100%'}}>
                <Documents req_placement={req_placement} profile={profile} />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} className='mb-2'>
            <ExpansionPanel
              expanded={
                (req_placement.id ? true : false) && expanded === 'panel3'
              }
              onChange={handleChange('panel3')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {req_placement.hasOwnProperty('fillableSections') &&
                !req_placement.fillableSections.includes('worklocation') ? (
                  <Typography
                    variant='h5'
                    className='text-center'
                    style={{ color: 'green' }}
                  >
                    {' '}
                    <DoneIcon color='inherit' /> Work Location
                  </Typography>
                ) : (
                  <Typography variant='h5' className='text-center'>
                    Work Location
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <WorkLocation req_placement={req_placement} profile={profile} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanel
              expanded={
                (req_placement.id ? true : false) && expanded === 'panel4'
              }
              onChange={handleChange('panel4')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {req_placement.hasOwnProperty('fillableSections') &&
                !req_placement.fillableSections.includes('timesheets') ? (
                  <Typography
                    variant='h5'
                    className='text-center'
                    style={{ color: 'green' }}
                  >
                    {' '}
                    <DoneIcon color='inherit' /> TimeSheet Details
                  </Typography>
                ) : (
                  <Typography variant='h5' className='text-center'>
                    TimeSheet Details
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <TimeSheetDetails
                  req_placement={req_placement}
                  profile={profile}
                  handleChange={props.handleChange}
                  modules={responseData}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <Grid item xs={6} className='p-2'>
          <Grid item xs={12} className='mb-2'>
            <ExpansionPanel
              expanded={
                (req_placement.id ? true : false) && expanded === 'panel5'
              }
              onChange={handleChange('panel5')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {req_placement.hasOwnProperty('fillableSections') &&
                !req_placement.fillableSections.includes('client-details') ? (
                  <Typography
                    variant='h5'
                    className='text-center'
                    style={{ color: 'green' }}
                  >
                    {' '}
                    <DoneIcon color='inherit' /> Client Details
                  </Typography>
                ) : (
                  <Typography variant='h5' className='text-center'>
                    Client Details
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <ClientDetails
                  req_placement={req_placement}
                  profile={profile}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} className='mb-2'>
            <ExpansionPanel
              expanded={
                (req_placement.id ? true : false) && expanded === 'panel6'
              }
              onChange={handleChange('panel6')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {req_placement.hasOwnProperty('fillableSections') &&
                !req_placement.fillableSections.includes('invoices') ? (
                  <Typography
                    variant='h5'
                    className='text-center'
                    style={{ color: 'green' }}
                  >
                    {' '}
                    <DoneIcon color='inherit' /> Invoices
                  </Typography>
                ) : (
                  <Typography variant='h5' className='text-center'>
                    Invoices
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Invoices
                  req_placement={req_placement}
                  profile={profile}
                  index={index}
                  handleOT={props.handleChange}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} className='mb-2'>
            <ExpansionPanel
              expanded={
                (req_placement.id ? true : false) && expanded === 'panel7'
              }
              onChange={handleChange('panel7')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {req_placement.hasOwnProperty('fillableSections') &&
                !req_placement.fillableSections.includes(
                  'recruitment-details'
                ) ? (
                  <Typography
                    variant='h5'
                    className='text-center'
                    style={{ color: 'green' }}
                  >
                    {' '}
                    <DoneIcon color='inherit' /> Recruitment Details
                  </Typography>
                ) : (
                  <Typography variant='h5' className='text-center'>
                    Recruitment Details
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <RecruitmentDetails
                  req_placement={req_placement}
                  profile={profile}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanel
              expanded={
                (req_placement.id ? true : false) && expanded === 'panel8'
              }
              onChange={handleChange('panel8')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {req_placement.hasOwnProperty('fillableSections') &&
                !req_placement.fillableSections.includes('expense-details') ? (
                  <Typography
                    variant='h5'
                    className='text-center'
                    style={{ color: 'green' }}
                  >
                    {' '}
                    <DoneIcon color='inherit' /> Expense Details
                  </Typography>
                ) : (
                  <Typography variant='h5' className='text-center'>
                    Expense Details
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <ExpenseDetails
                  req_placement={req_placement}
                  profile={profile}
                  modules={responseData}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <Grid item xs={12} className='p-2'>
          <ExpansionPanel
            expanded={
              (req_placement.id ? true : false) && expanded === 'panel1'
            }
            onChange={handleChange('panel1')}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              {req_placement.hasOwnProperty('fillableSections') &&
              !req_placement.fillableSections.includes('payments') ? (
                <Typography
                  variant='h5'
                  className='text-center'
                  style={{ color: 'green' }}
                >
                  {' '}
                  <DoneIcon color='inherit' /> Payments
                </Typography>
              ) : (
                <Typography variant='h5' className='text-center'>
                  Payments
                </Typography>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%'}}>
                <Payments
                  req_placement={req_placement}
                  profile={profile}
                  hideOT={hideOT}
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
      <br />
      <div className='text-center'>
        {req_placement.draft ? (
          <Button
            onClick={(e) => {
              e.preventDefault()
              handleFinish(req_placement.id)
            }}
            variant='contained'
            disabled={
              req_placement.hasOwnProperty('fillableSections') &&
              !req_placement.fillableSections.length
                ? false
                : true
            }
            color='primary'
          >
            Finish
          </Button>
        ) : (
          <Button
            onClick={(e) => props.handleChange('redirect', true)}
            variant='contained'
            color='secondary'
          >
            Close
          </Button>
        )}
      </div>
    </div>
  )
}
