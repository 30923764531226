import React from 'react';
import CreateArticle from '../components/Wiki/CreateArticle'
import { Breadcrumbs } from '@material-ui/core'
import { Link } from 'react-router-dom'

export default function CreateArticlePage(props) {
    return (
        <div>
            <div>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to='/'>Home</Link>
                    <Link to="/console/wiki">Wiki</Link>
                    <Link to={'/console/wiki/' + props.match.params.search + '/create'}>Create</Link>
                </Breadcrumbs>
            </div>
            <div>
                <CreateArticle
                    {...props.match.params}
                />
            </div>
        </div>
    );
}