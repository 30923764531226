import React, { Component } from 'react';
import Presentation from "./Presentation";

class Container extends Component {
    state = {
        value: 0
    }

    handleChange = (e, v) => {
        this.setState({ value: v })
    }
    render() {
        return (
            <div>
                <Presentation
                    {...this.state}
                    {...this.props}
                    handleChange={this.handleChange}
                />
            </div>
        );
    }
}

export default Container;